import React, {forwardRef} from 'react';

import {Link} from '@styled-icons/material-rounded';
import {
  useLinkToolbarButton,
  useLinkToolbarButtonState,
} from '@udecode/plate-link';
import MarkToolbarButton from '../MarkToolbarButton';

export const LinkToolbarButton = forwardRef((rest, ref) => {
  const state = useLinkToolbarButtonState();
  const {props} = useLinkToolbarButton(state);

  return (
    <MarkToolbarButton ref={ref} tooltip="Link" {...props} {...rest}>
      <Link />
    </MarkToolbarButton>
  );
});
