import * as Data from './data';
import * as General from './general';
import * as Misc from './misc';
import * as Tags from './tags';
import * as Pushes from './pushes';

export const generalSelector = General;
export const miscSelector = Misc;
export const tagsSelector = Tags;
export const dataSelector = Data;

export const pushesSelector = Pushes;
