import React, {forwardRef} from 'react';

import {PlateElement} from '@udecode/plate-common';

export const HeadingElement = forwardRef(
  ({children, isFirstBlock, variant = 'h1', ...props}, ref) => {
    const {editor, element} = props;

    const Element = variant;

    return (
      <PlateElement
        asChild
        isFirstBlock={element === editor.children[0]}
        ref={ref}
        variant={variant}
        {...props}>
        <Element>{children}</Element>
      </PlateElement>
    );
  }
);
