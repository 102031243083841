import randomstring from 'randomstring';
import {ATTRIBUTES} from './select-attribute';
import {OPTIONS} from './select-operand';

export class Attribute {
  constructor(type, values, op, eventId) {
    this.uid = randomstring.generate(8);
    this.type = type.startsWith('SEGMENTIO_FIELDS-')
      ? 'SEGMENTIO_FIELDS'
      : type.startsWith('SEGMENTIO_EVENTS-')
      ? 'SEGMENTIO_EVENTS'
      : type.startsWith('TRACKED_EVENTS-')
      ? 'TRACKED_EVENTS'
      : type;
    this.property = null;
    this.name = type.startsWith('SEGMENTIO_')
      ? type.substring(17)
      : type.startsWith('TRACKED_EVENTS-')
      ? type.substring(15)
      : null;
    this.eventId = type.startsWith('TRACKED_EVENTS-') ? eventId : null;
    this.op = op || this.getDefaultOperand(type);
    this.values = values || this.getDefaultValues(type);
    this.customType = null;
  }

  getDefaultOperand(type) {
    switch (type) {
      case ATTRIBUTES.ATTR_ACTIVE.value: {
        return OPTIONS.OP_IS_ACTIVE.value;
      }
      default: {
        return OPTIONS.OP_IS.value;
      }
    }
  }
  getDefaultValues(type) {
    switch (type) {
      case ATTRIBUTES.ATTR_ACTIVE.value: {
        return [''];
      }
      case ATTRIBUTES.ATTR_LAST_ACTIVITY.value: {
        return [new Date()];
      }
      case ATTRIBUTES.ATTR_CREATED.value: {
        return [new Date()];
      }
      default: {
        return [''];
      }
    }
  }
}
