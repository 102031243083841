import Button from 'components/Button';
import {func, string} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  type: string.isRequired,
  onSelectElementClick: func.isRequired,
};

const defaultProps = {};

const TargetNotSelectedWarning = ({type, onSelectElementClick}) => {
  return (
    <div className="target-not-selected">
      <div className="icon-wrapper">
        <i className="icon-exclamation-triangle-o" />
      </div>
      Your {type} is not linked to an element
      <Button iconLeft="icon-target" onClick={onSelectElementClick}>
        select element
      </Button>
    </div>
  );
};

TargetNotSelectedWarning.propTypes = propTypes;
TargetNotSelectedWarning.defaultProps = defaultProps;

export default TargetNotSelectedWarning;
