import classNames from 'classnames';
import {hasFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_URL,
} from 'services/event';
import {F_EXTRA_DISABLE_FILLED_INPUT_TRIGGERS} from 'services/project';
import {EventConditionsEditorContext} from '../..';
import './_Styles.scss';

const Option = ({className, icon, title, subtitle, onClick}) => {
  return (
    <div className={classNames('option', className)} onClick={onClick}>
      <div className="icon-wrapper">{icon}</div>
      <div className="content">
        <div className="title body-3 n-800">{title}</div>
        <div className="subtitle body-4 n-700">{subtitle}</div>
      </div>
    </div>
  );
};

const EventConditionsEmptyState = () => {
  const {handleAddCondition, logic, compact} = useContext(
    EventConditionsEditorContext
  );

  const project = useSelector((state) => generalSelector.getProject(state));

  const uid = logic.uid;

  return (
    <div className="event-conditions-empty-state">
      <Option
        className="element"
        icon={<i className="isax isax-mouse-circle" />}
        title={`+ ${compact ? 'Element' : 'Add element condition'}`}
        subtitle="Element is present, clicked or hovered"
        onClick={() => {
          handleAddCondition(EVENT_CONDITION_TYPE_ELEMENT, uid);
        }}
      />
      {hasFlag(F_EXTRA_DISABLE_FILLED_INPUT_TRIGGERS, project.extraFlags) ===
        false && (
        <Option
          className="input"
          icon={<i className="isax isax-keyboard" />}
          title={`+ ${compact ? 'Input' : 'Add input condition'}`}
          subtitle="Input is being filled"
          onClick={() => {
            handleAddCondition(EVENT_CONDITION_TYPE_INPUT, uid);
          }}
        />
      )}
      <Option
        className="url"
        icon={<i className="isax isax-receipt-search" />}
        title={`+ ${compact ? 'Page' : 'Add page condition'}`}
        subtitle="User opens a specific page URL"
        onClick={() => {
          handleAddCondition(EVENT_CONDITION_TYPE_URL, uid);
        }}
      />
    </div>
  );
};

export default EventConditionsEmptyState;
