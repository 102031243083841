import InputEditor from 'components/MarkdownEditor/InputEditor';
import RadioGroup from 'components/RadioGroup';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {func, object} from 'prop-types';
import React, {useEffect, useState} from 'react';
import {F_STEP_TRIGGER_ACTION_OPEN_IN_NEW_TAB} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  action: object.isRequired,
  setAction: func.isRequired,
  setPreventClose: func,
};

const defaultProps = {
  setPreventClose: () => {},
};

const SettingsNavigateTo = ({
  action,
  setAction,
  setPreventClose = () => {},
}) => {
  const {flags} = action;

  const [isEditing, setIsEditing] = useState(false);
  const [markdownEditorKey, setMarkdownEditorKey] = useState(null);

  const hasOpenInNewTab = hasFlag(
    F_STEP_TRIGGER_ACTION_OPEN_IN_NEW_TAB,
    action.flags
  );

  const {value, rawValue} = action || {};

  useEffect(() => {
    if (isEditing === false) {
      setMarkdownEditorKey(JSON.stringify(rawValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawValue]);

  useEffect(() => {
    setPreventClose(isEditing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  return (
    <div className="settings-navigate-to">
      <InputEditor
        key={markdownEditorKey || ''}
        value={value || ''}
        rawValue={rawValue}
        onChange={({value, rawValue}) =>
          setAction({...action, value, rawValue})
        }
        onFocus={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
        placeholder="/path/to/navigate"
      />
      <RadioGroup
        options={[
          {label: 'Same tab', value: false},
          {label: 'New tab', value: true},
        ]}
        value={hasOpenInNewTab}
        onSelect={(value) => {
          setAction({
            ...action,
            flags: value
              ? addFlag(F_STEP_TRIGGER_ACTION_OPEN_IN_NEW_TAB, flags)
              : removeFlag(F_STEP_TRIGGER_ACTION_OPEN_IN_NEW_TAB, flags),
          });
        }}
      />
    </div>
  );
};

SettingsNavigateTo.propTypes = propTypes;
SettingsNavigateTo.defaultProps = defaultProps;

export default SettingsNavigateTo;
