import {createPluginFactory} from '@udecode/plate';
import {
  getEditorString,
  getPointBefore,
  getRange,
} from '@udecode/plate-common/server';
import VariableElement from 'components/MarkdownEditor/elements/VariableElement';

export const ELEMENT_VARIABLE = 'variable';

export const withTriggerCombobox = (
  editor,
  {
    options: {
      createComboboxInput,
      trigger,
      triggerPreviousCharPattern,
      triggerQuery,
    },
    type,
  }
) => {
  const {insertText} = editor;

  const matchesTrigger = (text) => {
    if (trigger instanceof RegExp) {
      return trigger.test(text);
    }
    if (Array.isArray(trigger)) {
      return trigger.includes(text);
    }

    return text === trigger;
  };

  editor.insertText = (text) => {
    if (
      !editor.selection ||
      !matchesTrigger(text) ||
      (triggerQuery && !triggerQuery(editor))
    ) {
      return insertText(text);
    }

    // Make sure an input is created at the beginning of line or after a whitespace
    const previousChar = getEditorString(
      editor,
      getRange(
        editor,
        editor.selection,
        getPointBefore(editor, editor.selection)
      )
    );

    const matchesPreviousCharPattern =
      triggerPreviousCharPattern?.test(previousChar);

    if (matchesPreviousCharPattern) {
      const inputNode = createComboboxInput
        ? createComboboxInput(text)
        : {children: [{text: ''}], type};

      return editor.insertNode(inputNode);
    }

    return insertText(text);
  };

  return editor;
};

export const createVariablePlugin = createPluginFactory({
  isElement: true,
  isInline: true,
  isMarkableVoid: true,
  isVoid: true,
  key: ELEMENT_VARIABLE,
  component: VariableElement,
  withOverrides: withTriggerCombobox,
});
