import {BuilderContext} from 'contextes/builder';
import {useContext} from 'react';
import './_Styles.scss';
import {WidgetContentToggler} from './components/ContentToggler';

const Navbar = () => {
  const {project, setProject} = useContext(BuilderContext);

  return (
    <div className="block-settings changelog-navbar">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <WidgetContentToggler
            contentFlags={project.widgetContentFlags}
            sectionOrder={project.widgetSectionOrder || 'feed;roadmap;feedback'}
            onChange={(widgetContentFlags) => {
              setProject({
                ...project,
                widgetContentFlags,
              });
            }}
            onSectionOrderChange={(widgetSectionOrder) => {
              setProject({
                ...project,
                widgetSectionOrder,
              });
            }}
          />
        </div>
      </div>
      {/* <Divider />
      <div className="section">
        <div className="section-title">Text</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Title font family</div>
            <div className="section-item-content">
              <SelectGroup
                options={fontFamiliesOptions}
                value={fontFamiliesOptions.find(
                  (o) => o.value === style.titleFontFamily
                )}
                onChange={(option) => {
                  // updateStyle({
                  //   ...style,
                  //   titleFontFamily: option.value,
                  // })
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Title color</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Text color"
                value={style.titleFontColor}
                onChange={(value) => {
                  // updateStyle({
                  //   ...style,
                  //   titleFontColor: value,
                  // })
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Navbar;
