import Button from 'components/Button';
import {
  toastDanger,
  toastInfo,
  toastSuccess,
  toastWarning,
} from 'components/Toaster';
import React from 'react';
import './_Styles.scss';

export default function DevToaster() {
  return (
    <div className="_dev-toaster">
      {/* Basic */}
      <h2>Basic</h2>
      <div className="toaster-wrapper">
        <Button
          onClick={() => {
            toastInfo('An info toast!', {
              autoClose: false,
            });
          }}>
          Info toast
        </Button>
        <Button
          onClick={() => {
            toastSuccess('A success toast!');
          }}>
          Success toast
        </Button>
        <Button
          onClick={() => {
            toastDanger('A danger toast!');
          }}>
          Danger toast
        </Button>
        <Button
          onClick={() => {
            toastWarning('A warning toast!');
          }}>
          Warning toast
        </Button>
      </div>

      {/* Positioning */}
      <h2>Positioning</h2>
      <div className="toaster-wrapper">
        <Button
          onClick={() => {
            toastInfo('A top left toast!', {position: 'top-left'});
          }}>
          Top left
        </Button>
        <Button
          onClick={() => {
            toastInfo('A top center toast!', {position: 'top-center'});
          }}>
          Top center
        </Button>
        <Button
          onClick={() => {
            toastInfo('A top right toast!', {position: 'top-right'});
          }}>
          Top right
        </Button>
        <Button
          onClick={() => {
            toastInfo('A bottom left toast!', {position: 'bottom-left'});
          }}>
          Bottom left
        </Button>
        <Button
          onClick={() => {
            toastInfo('A bottom center toast!', {position: 'bottom-center'});
          }}>
          Bottom center
        </Button>
        <Button
          onClick={() => {
            toastInfo('A bottom right toast!', {position: 'bottom-right'});
          }}>
          Bottom right
        </Button>
      </div>

      {/* Advanced */}
      <h2>Advanced</h2>
      <div className="toaster-wrapper">
        <Button
          onClick={() => {
            toastInfo(['Title', 'This is a second line!'], {
              autoClose: false,
              toastId: '1',
              actions: [
                {
                  text: 'Contact support',
                  props: {
                    onClick: () => toastSuccess('Clicked!'),
                    iconLeft: 'icon-chat',
                  },
                },
                {
                  text: 'Close',
                  shouldCloseToast: true,
                  props: {
                    onClick: () => toastSuccess('Clicked!'),
                  },
                },
              ],
            });
          }}>
          Toast with title and description (info)
        </Button>
        <Button
          onClick={() => {
            toastSuccess(['Title', 'This is a second line!'], {
              autoClose: false,
              toastId: '2',
            });
          }}>
          Toast with title and description (success)
        </Button>
        <Button
          onClick={() => {
            toastDanger(['Title', 'This is a second line!'], {
              autoClose: false,
              toastId: '3',
              actions: [
                {
                  text: 'Contact support',
                  props: {
                    onClick: () => toastSuccess('Clicked!'),
                    iconLeft: 'icon-chat',
                  },
                },
                // {
                //   text: 'Close',
                //   shouldCloseToast: true,
                //   props: {
                //     onClick: () => toastSuccess('Clicked!'),
                //   },
                // },
              ],
            });
          }}>
          Toast with title and description (error)
        </Button>
        <Button
          onClick={() => {
            toastWarning(['Title', 'This is a second line!'], {
              autoClose: false,
              toastId: '4',
            });
          }}>
          Toast with title and description (warning)
        </Button>
        <Button
          onClick={() => {
            toastInfo('Lorem ipsum dolor sit amet ectetur adipisicing elit.', {
              autoClose: false,
            });
          }}>
          Auto close disabled
        </Button>
        <Button
          onClick={() => {
            toastInfo('Lorem ipsum dolor sit amet ectetur adipisicing elit.', {
              actions: [
                {
                  text: 'View',
                  props: {
                    onClick: () => toastSuccess('Clicked!'),
                  },
                },
              ],
            });
          }}>
          With action
        </Button>
        <Button
          onClick={() => {
            toastInfo('Lorem ipsum dolor sit amet ectetur adipisicing elit.', {
              actions: [
                {
                  text: 'Back',
                  props: {
                    onClick: () => toastSuccess('Clicked!'),
                    iconLeft: 'icon-chevron-left',
                  },
                },
              ],
            });
          }}>
          With advanced action button
        </Button>
      </div>
    </div>
  );
}
