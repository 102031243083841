import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {default as Input, default as InputGroup} from 'components/Input';
import DefaultLoader from 'components/Loader';
import Select, {Creatable} from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {hasFlag} from 'helpers/bitwise';
import {isActiveOnDomain, isActiveOnPath} from 'helpers/utils';
import {bool, func, object} from 'prop-types';
import {useState} from 'react';
import {
  ClearIndicator,
  Control,
  extractDomainOrRegex,
  MultiValueLabel,
  MultiValueRemove,
} from 'scenes/Settings/scenes/Environments/components/ModalEditEnvironment';
import {feedbackService, openaiService} from 'services';
import {F_BOOST_SLOT_TOUR} from 'services/evolution';
import {FEEDBACK_TYPE_AI_GENERATED_RULES} from 'services/feedback';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';
import './_styles.scss';

const operatorOptions = [
  {label: 'Starts with', value: 'STARTS_WITH'},
  {label: "Doesn't start with", value: 'DOESNT_START_WITH'},
  {label: 'Ends with', value: 'ENDS_WITH'},
  {label: "Doesn't end with", value: 'DOESNT_END_WITH'},
  {label: 'Contains', value: 'CONTAINS'},
  {label: "Doesn't contain", value: 'DOESNT_CONTAIN'},
  {label: 'Equals', value: 'EQUALS'},
  {label: "Doesn't equal", value: 'DOESNT_EQUAL'},
  {label: 'Matches regex', value: 'MATCHES_REGEX'},
];

const globalOperatorOptions = [
  {label: 'Should match one of the path rules', value: 'OR'},
  {label: 'Should match all the path rules', value: 'AND'},
];

const propTypes = {
  evolution: object.isRequired,
  setEvolution: func.isRequired,
  small: bool,
};

const defaultProps = {
  small: false,
};

const logger = new Swaler('PathSelector');

export const PathSelector = ({evolution, setEvolution, small}) => {
  return (
    <div
      className={classnames('builder-audience-path-selector', {
        small: small === true,
      })}>
      <Rules
        paths={evolution.boostedPaths}
        onPathsChange={(paths) => {
          setEvolution({...evolution, boostedPaths: paths});
        }}
        pathOperator={evolution.boostedPathOperator}
        onPathOperatorChange={(operator) => {
          setEvolution({...evolution, boostedPathOperator: operator});
        }}
        small={small}
      />
      <Domains evolution={evolution} setEvolution={setEvolution} />
      {(evolution.boostedPaths.length > 0 ||
        evolution.boostedDomainFilter != null) && (
        <UrlTester
          paths={evolution.boostedPaths}
          domainFilter={evolution.boostedDomainFilter}
          pathOperator={evolution.boostedPathOperator}
          isTour={hasFlag(evolution.boostedSlot, F_BOOST_SLOT_TOUR)}
        />
      )}
    </div>
  );
};

PathSelector.propTypes = propTypes;
PathSelector.defaultProps = defaultProps;

export const Rules = ({
  paths = [],
  onPathsChange,
  pathOperator,
  onPathOperatorChange,
  small = false,
}) => {
  const [hasAiSettingsOpen, setHasAiSettingsOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [askFeedbackData, setAskFeedbackData] = useState(null);
  const [hasGeneratedRules, setHasGeneratedRules] = useState(false);

  const generateRules = async () => {
    setIsGenerating(true);

    try {
      const response = await openaiService.generatePathRules({text: prompt});

      onPathOperatorChange(response?.pathMatchRules);
      onPathsChange(response?.rules?.map((r) => ({uid: uuidv4(), ...r})));

      toastSuccess([
        'Rules added successfully',
        'URL conditions are now set. You can edit the conditions manually again anytime.',
      ]);
      setHasAiSettingsOpen(false);
      setPrompt('');
      setAskFeedbackData({
        prompt,
        response,
      });
      setHasGeneratedRules(true);
    } catch (err) {
      logger.error('Generating rules failed with error ', err);
      toastDanger([
        'Failed to add the rules',
        'Please check your prompt and try again',
      ]);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFeedback = async (isPositive) => {
    try {
      feedbackService.createFeedback({
        type: FEEDBACK_TYPE_AI_GENERATED_RULES,
        data: {
          prompt: askFeedbackData.prompt,
          response: askFeedbackData.response,
          isPositive: isPositive,
        },
      });
    } catch (err) {
      logger.error('Feedback failed with error ', err);
    } finally {
      setAskFeedbackData(null);
    }
  };

  const generateRulesExplanation = async () => {
    setIsGenerating(true);

    try {
      const response = await openaiService.generatePathRulesExplanation({
        rules: paths.filter(
          (p) =>
            (p.explanation == null || p.example == null) &&
            !!p.path &&
            p.operator != null
        ),
      });

      onPathsChange(
        paths.map((path) => {
          const rule = response?.find((r) => r.uid === path.uid);
          if (rule != null) {
            return {
              ...path,
              explanation: rule.explanation,
              example: rule.example,
            };
          }
          return path;
        })
      );
    } catch (err) {
      logger.error('Generating rules explanation failed with error ', err);
    }
  };

  return (
    <div className="rules-wrapper">
      <div className="rules-title body-3 n-700">Rules</div>

      {paths.length > 0 && (
        <div className="path-list">
          {paths.length >= 2 && (
            <Select
              className="global-operator-select"
              options={globalOperatorOptions}
              placeholder="Select an operator"
              closeMenuOnSelect
              onChange={(option) => {
                onPathOperatorChange(option?.value);
              }}
              value={globalOperatorOptions?.find(
                (o) => pathOperator === o.value
              )}
            />
          )}

          <div className="path-list-wrapper">
            {paths.map((path, index) => (
              <>
                <BoostedPath
                  small={small}
                  boostedPath={path}
                  onFetchExplanation={generateRulesExplanation}
                  updatePath={(update) => {
                    onPathsChange(
                      paths.map((p) => {
                        if (p.uid === path.uid) {
                          return {
                            ...p,
                            ...update,
                            explanation: null,
                            example: null,
                          };
                        }
                        return p;
                      })
                    );
                  }}
                  deletePath={() =>
                    onPathsChange(paths.filter((p) => p.uid !== path.uid))
                  }
                />
                {index < paths.length - 1 && <Divider />}
              </>
            ))}
          </div>
        </div>
      )}

      {hasAiSettingsOpen === true ? (
        <div className="ai-settings">
          <div className="ai-input-group-wrapper">
            <InputGroup
              className="ai-settings-input"
              labelTextLeft={<i className="isax isax-magicpen5 p-500" />}
              placeholder="Show on https://example.com/profile or https://example.com/dashboard/settings/"
              value={prompt}
              onChange={({target}) => setPrompt(target.value)}
            />
            <div className="ai-input-helper body-4 n-700">
              Describe the URL conditions, including any useful details
            </div>
          </div>
          <div className="ai-settings-buttons">
            <Button
              thin
              primary
              className="btn-add-path"
              loading={isGenerating}
              onClick={() => {
                generateRules();
              }}>
              Generate
            </Button>
            <Button
              thin
              className="btn-add-path"
              onClick={() => setHasAiSettingsOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="buttons-wrapper">
          <Button
            thin
            className="btn-add-path"
            onClick={() => {
              onPathsChange([
                ...paths,
                {
                  uid: uuidv4(),
                  path: '',
                  operator: 'STARTS_WITH',
                },
              ]);
            }}
            iconLeft="isax isax-add-circle">
            Add rule manually
          </Button>
          <Button
            thin
            className="btn-add-path"
            onClick={() => setHasAiSettingsOpen(true)}
            iconLeft="isax isax-magicpen5 p-500">
            {hasGeneratedRules === true
              ? 'Regenerate rules with AI'
              : 'Generate rules with AI'}
          </Button>

          {askFeedbackData != null && (
            <div className="feedback-wrapper">
              <div className="feedback-title body-3 n-600">
                How accurate is this AI-generated rule?
              </div>
              <div className="feedback-btns">
                <i
                  className="isax isax-like-15 n-500"
                  onClick={() => handleFeedback(true)}
                />
                <i
                  className="isax isax-dislike5 n-500"
                  onClick={() => handleFeedback(false)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const Domains = ({evolution, setEvolution}) => {
  const [domainsInputValue, setDomainsInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (!domainsInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        const newDomain = extractDomainOrRegex(domainsInputValue);
        setEvolution({
          ...evolution,
          boostedDomainFilter: evolution.boostedDomainFilter
            ? evolution.boostedDomainFilter + ';' + newDomain
            : newDomain,
        });
        setDomainsInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const domainsArr = evolution.boostedDomainFilter
    ?.split(';')
    .filter((d) => d)
    .map((domain) => ({
      label: domain,
      value: domain,
    }));

  return (
    <div className="domains-wrapper">
      <div className="domains-title body-3 n-700">Domains</div>
      {evolution.boostedDomainFilter != null ? (
        <>
          <Creatable
            className="domains-creatable"
            components={{
              DropdownIndicator: null,
              MultiValueRemove,
              MultiValueLabel,
              Control,
              ClearIndicator,
            }}
            inputValue={domainsInputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue, triggeredAction) => {
              if (triggeredAction.action === 'clear') {
                setEvolution({
                  ...evolution,
                  boostedDomainFilter: null,
                });
                return;
              }
              const newDomains = newValue
                .map((option) => option.value)
                .join(';');
              setEvolution({
                ...evolution,
                boostedDomainFilter: newDomains,
              });
            }}
            onInputChange={(newValue) => setDomainsInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Insert domains separated by space"
            value={domainsArr}
          />
          <div className="filter-domain-info-wrapper">
            <p>
              <i className="icon-info-circle-o"></i>Tips
            </p>
            <p className="filter-domain-info">
              - A domain :<pre>foo.com</pre>
            </p>
            <p className="filter-domain-info">
              - List of domains :<pre>foo.com;bar.com</pre> (separate domains
              with a space or by pressing Enter)
            </p>
            <p className="filter-domain-info">
              - Regex :<pre>\.bar.com$</pre> (match all subdomains ending with{' '}
              <pre>bar.com</pre>)
            </p>
          </div>
        </>
      ) : (
        <Button
          thin
          className="btn-add-domain"
          onClick={() => {
            setEvolution({
              ...evolution,
              boostedDomainFilter: '',
            });
          }}
          iconLeft="isax isax-add-circle">
          Add domain
        </Button>
      )}
    </div>
  );
};

export const UrlTester = ({
  paths,
  domainFilter,
  pathOperator,
  isTour = false,
}) => {
  const [testUrl, setTestUrl] = useState('');

  let testUrlText = null;
  let code = null;

  if (testUrl) {
    if (!(paths?.length > 0) && !(domainFilter?.length > 0)) {
      testUrlText = 'No path rules added yet';
      code = 'NO_RULES';
    } else {
      let pathname = null;
      let host = null;

      try {
        const url = new URL(testUrl);
        host = url.host;
        pathname = url.href.replace(url.origin, '');
      } catch (e) {
        testUrlText =
          'Invalid URL, enter a valid URL including protocol please';
        code = 'INVALID_URL';
      }

      if (code == null) {
        const activeOnDomain =
          domainFilter != null
            ? isActiveOnDomain(domainFilter, host)
            : {isActive: true};
        const isActOnDomain = activeOnDomain.isActive;
        const isActive = isActiveOnPath(pathOperator, paths, pathname);

        if (isActive === true && isActOnDomain === true) {
          testUrlText = `Your experience will be shown on the given URL`;
          code = 'URL_MATCH';
        } else {
          if (activeOnDomain.error != null) {
            testUrlText = `An error occurred: ${activeOnDomain.error.message}`;
          } else {
            testUrlText = `Your experience will not be shown on the given URL`;
          }
          code = 'URL_NO_MATCH';
        }
      }
    }
  }

  return (
    <div
      className={classnames('url-tester-wrapper', {
        'invalid-url': ['INVALID_URL', 'NO_RULES'].includes(code),
        'url-match': code === 'URL_MATCH',
        'url-no-match': code === 'URL_NO_MATCH',
      })}>
      <div className="url-tester-title body-3 n-700">Test your URL</div>
      <div className="url-tester">
        <Input
          iconLeft="isax isax-link-2"
          className="url-tester-input"
          name="value"
          type="string"
          placeholder="www.xyz.com"
          value={testUrl}
          onChange={(e) => {
            setTestUrl(e.target.value);
          }}
        />
      </div>
      <div className="url-tester-info"></div>

      <div
        className={classnames('url-tester-text body-4', {
          'invalid-url': ['INVALID_URL', 'NO_RULES'].includes(code),
          'url-match': code === 'URL_MATCH',
          'url-no-match': code === 'URL_NO_MATCH',
        })}>
        {testUrlText || 'Input a URL to test if it matches the rules you set'}
      </div>
    </div>
  );
};

export const BoostedPath = ({
  boostedPath,
  updatePath,
  deletePath,
  onFetchExplanation = () => {},
  small = false,
}) => {
  const {path, operator, explanation, example} = boostedPath;

  const handleTooltipOpen = () => {
    if (explanation == null || example == null) {
      onFetchExplanation();
    }
  };

  return (
    <div className="builder-audience-boosted-path">
      <Select
        className="operator-select"
        options={operatorOptions}
        placeholder="Select an operator"
        closeMenuOnSelect
        onChange={(option) => {
          updatePath({operator: option?.value});
        }}
        value={operatorOptions?.find((o) => operator === o.value)}
      />
      <Input
        required
        className="path-input"
        name="value"
        type="string"
        placeholder={
          ['STARTS_WITH', 'DOESNT_START_WITH'].includes(operator)
            ? '/path-start or ?path-start or #path-start'
            : 'path-content'
        }
        value={path || ''}
        onChange={(e) => {
          updatePath({path: e.target.value});
        }}
      />
      <div className="actions-wrapper">
        {!!path && operator != null && (
          <Tooltip
            className="ai-explanation-tooltip"
            position="left center"
            offsetY={8}
            onOpen={handleTooltipOpen}
            trigger={
              <div>
                <i className="isax isax-magicpen5 p-500" />
              </div>
            }>
            <div className="header p-500 subtitle-4">
              <i className="isax isax-magicpen5" />
              AI Explanation
            </div>
            {explanation == null || example == null ? (
              <div className="loader-wrapper">
                <DefaultLoader width="24px" />
              </div>
            ) : (
              <div className="content body-3 n-800">
                {explanation}
                <br />
                <br />
                Example URL:
                <br />
                {example}
              </div>
            )}
          </Tooltip>
        )}

        <Button
          className="action-btn"
          onClick={deletePath}
          iconLeft="isax isax-trash r-400"
          iconOnly
        />
      </div>
    </div>
  );
};
