import BtnCopy from 'components/ButtonCopy';
import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useRef} from 'react';
import ReactSyntaxHighlighter from 'react-syntax-highlighter';
import {tomorrowNightEighties} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {fontFamiliesOptions} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Title/utils';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import IconPicker, {
  HINT_ICON_BUILT_IN,
  triggerIconsList,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/IconPicker';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';
import {
  F_PAGE_FEED,
  TRIGGER_BADGE,
  TRIGGER_CUSTOM,
  TRIGGER_DEFAULT,
  TRIGGER_NONE,
  TRIGGER_SIDE_BADGE,
  defaultChangelogStyle,
} from 'services/project';
import EmojiBell from 'shared/front/components/Misc/EmojiBell';
import './_Styles.scss';

export const triggerOptions = [
  {
    label: (
      <>
        <i className="isax isax-eye-slash" />
        Disable
      </>
    ),
    value: TRIGGER_NONE,
  },
  {
    label: (
      <>
        <i className="icon-float-trigger" />
        Float
      </>
    ),
    value: TRIGGER_DEFAULT,
  },
  {
    label: (
      <>
        <div className="trigger-pulsating">1</div> Pulsating
      </>
    ),
    value: TRIGGER_BADGE,
  },
  {
    label: (
      <>
        <i className="icon-side-badge" />
        Side badge
      </>
    ),
    value: TRIGGER_SIDE_BADGE,
  },
  {
    label: <>Custom</>,
    value: TRIGGER_CUSTOM,
  },
];

const Trigger = () => {
  const {
    changelogTheme: theme,
    setChangelogTheme: setTheme,
    project,
    setProject,
  } = useContext(BuilderContext);

  const iconWrapperRef = useRef(null);

  const style = theme?.trigger || {
    ...defaultChangelogStyle.trigger,
    backgroundColor: project.widgetThemeColor,
  };

  const updateStyle = (updatedStyle) => {
    const newTheme = {
      ...theme,
      trigger: {
        ...style,
        ...updatedStyle,
      },
    };
    setTheme(newTheme);
  };

  const {fileTriggerIcon} = project;
  const selectedIcon = triggerIconsList.find(
    (i) => i.value === style.iconName || null
  );

  useEffect(() => {
    const svg = iconWrapperRef.current?.querySelector('svg');
    if (svg) {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.style.fill = 'black';
      });
    }
  }, [style.iconColor, selectedIcon, iconWrapperRef.current]);

  return (
    <div className="block-settings changelog-trigger">
      <div className="section">
        <div className="section-title">General</div>
        <div className="section-content">
          <div className="section-item change-trigger">
            <div className="section-item-title">Type</div>
            <div className="section-item-content">
              <SelectGroup
                isSearchable={false}
                classNamePrefix="changelog-trigger-select"
                options={triggerOptions}
                value={triggerOptions.find(
                  (o) => o.value === project.triggerMode
                )}
                onChange={(option) => {
                  setProject({
                    ...project,
                    triggerMode: option.value,
                  });
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          {[TRIGGER_DEFAULT, TRIGGER_SIDE_BADGE].includes(
            project.triggerMode
          ) && (
            <div className="section-item trigger-title">
              <div className="section-item-title">Title</div>
              <div className="section-item-content">
                <TextArea
                  value={project.widgetTriggerMessage}
                  placeholder={
                    hasFlag(F_PAGE_FEED, project.widgetContentFlags) === true
                      ? `What's new on ${project.name}?`
                      : `Help us improve ${project.name}`
                  }
                  onChange={({target}) => {
                    setProject({
                      ...project,
                      widgetTriggerMessage: target.value,
                    });
                  }}
                  className="title-content-input trigger-title-content-input"
                />
              </div>
            </div>
          )}
          {[TRIGGER_BADGE].includes(project.triggerMode) && (
            <div className="section-item code-snippet">
              <div className="copy-row">
                <div className="title">
                  Trigger snippet
                  <Tooltip
                    offsetY={10}
                    dark
                    trigger={<i className="icon-info-circle-o" />}>
                    Paste this snippet in your app to display the trigger
                  </Tooltip>
                </div>
                <BtnCopy
                  text="Copy"
                  textToCopy={`<div class="jimo-widget">What's new?</div>`}
                />
              </div>
              <div className="code-row">
                <ReactSyntaxHighlighter
                  language="javascript"
                  style={tomorrowNightEighties}>
                  {`<div class="jimo-widget">What's new?</div>`}
                </ReactSyntaxHighlighter>
              </div>
            </div>
          )}
          {project.triggerMode === TRIGGER_CUSTOM && (
            <div
              className="section-item custom-trigger subtitle-4 b-400"
              onClick={() => {
                window.open(
                  'https://help.usejimo.com/help-center/v/using-jimo/changelog/changelog/positionning-trigger#custom',
                  '_blank',
                  'noopener noreferrer'
                );
              }}>
              How to set up a Custom Trigger
              <i className="isax isax-export-3" />
            </div>
          )}
        </div>
      </div>
      {[TRIGGER_DEFAULT, TRIGGER_SIDE_BADGE].includes(project.triggerMode) && (
        <>
          <Divider />
          <div className="section">
            <div className="section-title">Style</div>
            <div className="section-content">
              <div className="section-item">
                <div className="section-item-title">Shadow</div>
                <div className="section-item-content shadow-picker">
                  <PopupSetting
                    trigger={
                      <ClickableInput
                        value={style.shadow?.color}
                        leftLabel={
                          <span
                            className="preview-shadow-color"
                            style={{
                              background: style.shadow?.color || '#FFFFFF',
                            }}
                          />
                        }
                      />
                    }
                    title="Shadow"
                    content={
                      <Shadow
                        value={style.shadow}
                        onChange={(value) => {
                          updateStyle({shadow: value});
                        }}
                      />
                    }
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Radius</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={style.borderRadius}
                    min={0}
                    max={50}
                    onChange={(value) => {
                      updateStyle({borderRadius: value});
                    }}
                    radius
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Background</div>
                <div className="section-item-content">
                  <ColorPickerInput
                    title="Background color"
                    value={style.backgroundColor}
                    onChange={(v) => {
                      updateStyle({
                        backgroundColor: v,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="section">
            <div className="section-title">Text</div>
            <div className="section-content">
              <div className="section-item">
                <div className="section-item-title">Color</div>
                <div className="section-item-content">
                  <ColorPickerInput
                    title="Color"
                    value={style.fontColor}
                    onChange={(value) => {
                      updateStyle({
                        ...style,
                        fontColor: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Font family</div>
                <div className="section-item-content">
                  <SelectGroup
                    options={fontFamiliesOptions}
                    value={fontFamiliesOptions.find(
                      (o) => o.value === style.fontFamily
                    )}
                    onChange={(option) => {
                      updateStyle({
                        ...style,
                        fontFamily: option.value,
                      });
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
            </div>
          </div>
          {TRIGGER_DEFAULT === project.triggerMode && (
            <>
              <Divider />
              <div className="section">
                <div className="section-title">Icon</div>
                <div className="section-content">
                  <div className="section-item">
                    <div className="section-item-title">Color</div>
                    <div className="section-item-content">
                      <ColorPickerInput
                        title="Color"
                        value={style.iconColor}
                        onChange={(value) => {
                          updateStyle({
                            ...style,
                            iconColor: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="section-item item-icon-picker">
                    <div className="section-item-title">Icon</div>
                    <div className="section-item-content">
                      <PopupSetting
                        className="picker-icon-popup"
                        trigger={
                          <ClickableInput
                            value={
                              style.iconSource === HINT_ICON_BUILT_IN
                                ? selectedIcon?.label
                                : style.iconUrl != null
                                ? 'Custom Icon'
                                : null
                            }
                            leftLabel={
                              <span
                                className="preview-icon"
                                ref={iconWrapperRef}>
                                {style.iconSource === HINT_ICON_BUILT_IN ? (
                                  selectedIcon?.icon
                                ) : style.iconUrl ? (
                                  <img src={style.iconUrl} alt="" />
                                ) : (
                                  <EmojiBell />
                                )}
                              </span>
                            }
                            inputProps={{
                              placeholder: 'Select an icon',
                            }}
                          />
                        }
                        title="Trigger icons"
                        content={
                          <IconPicker
                            style={style}
                            file={fileTriggerIcon}
                            type="trigger"
                            onChange={({style: updatedStyle, file}) => {
                              updateStyle({
                                ...style,
                                ...updatedStyle,
                              });
                              setProject({
                                ...project,
                                fileTriggerIcon: file,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Trigger;
