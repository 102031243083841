import classnames from 'classnames';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {tagService} from 'services';
import {Swaler} from 'swaler';
import Button from '../Button';

const logger = new Swaler('TagButton');

const TagButton = (props) => {
  const handleUnassignTag = async (tagId) => {
    const {request, onUnassignTag} = props;

    try {
      await tagService.unAssignTag({tagId, requestId: request.uid});

      onUnassignTag(tagId);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Unassigning tag failed with error ${code}`);
      return toastDanger([title, message], {actions});
    }
  };

  const {
    tag,
    active,
    interactive = true,
    dropdown,
    showCount,
    onClick = () => {},
    onUnassignTag = null,
    ...rest
  } = props;
  const className = classnames('btn-tag', rest.className ?? '', {
    'is-active': active === true,
    'is-interactive': interactive,
    'is-dropdown': dropdown,
    'has-color': tag.color != null,
  });
  const count =
    tag.requests != null
      ? tag.requests.length
      : tag.evolutions != null
      ? tag.evolutions.length
      : null;

  return (
    <div
      className={classnames('tag-button-wrapper', {'is-dropdown': dropdown})}>
      <Button
        type="button"
        light
        onClick={() => onClick(tag.uid)}
        {...rest}
        className={className}>
        {tag.color != null && (
          <div
            className="bg-color"
            style={{
              backgroundColor: tag.color,
            }}
          />
        )}
        <div
          className="tag-content-wrapper"
          style={tag.color != null ? {color: tag.color} : undefined}>
          {onUnassignTag != null && (
            <i
              className="icon-close"
              onClick={() => handleUnassignTag(tag.uid)}
            />
          )}
          {tag.name}{' '}
          {showCount === true && (
            <span className="count">{count != null ? count : 0}</span>
          )}
        </div>
      </Button>
      {dropdown === true && <i className="icon-chevron-bottom" />}
    </div>
  );
};
export default TagButton;
