import AcrossTabs from 'across-tabs';
import {useEffect, useRef} from 'react';

const useAcrossTabs = ({onHandshakeCallback, onChildCommunication}) => {
  const refParent = useRef();
  const childTabId = useRef();

  useEffect(() => {
    const config = {
      onHandshakeCallback: onHandshakeCallback,
      onChildCommunication: onChildCommunication,
    };

    const parent = new AcrossTabs.Parent(config);
    refParent.current = parent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openNewTab = (url) => {
    const childTab = refParent.current?.openNewTab({
      url,
      windowName: 'AcrossTab',
    });
    childTabId.current = childTab.id;
  };

  return {
    openNewTab,
    refParent,
    childTabId,
  };
};

export default useAcrossTabs;
