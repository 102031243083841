import InputGroup from 'components/Input';
import {func, number} from 'prop-types';
import {useEffect} from 'react';
import RadioGroup from '../../../../../../../components/RadioGroup';
import './_Styles.scss';

const zIndexOptions = [
  {label: 'Auto', value: 'auto'},
  {label: 'Custom', value: 'custom'},
];

const propTypes = {
  value: number,
  onChange: func,
};

const defaultProps = {
  value: null,
  onChange: () => {},
};

const ZIndex = ({value, onChange, setIsOpen}) => {
  useEffect(() => {
    setIsOpen(true);

    return () => setIsOpen(false);
  }, []);

  const type = value !== null ? 'custom' : 'auto';

  return (
    <>
      <div className="section-item">
        <RadioGroup
          value={type}
          options={zIndexOptions}
          onSelect={(value) => {
            if (value === 'auto') {
              onChange(null);
            } else {
              onChange(0);
            }
          }}
        />
      </div>
      {type === 'custom' && (
        <div className="section-item z-index-picker">
          <InputGroup
            value={value || 0}
            type="number"
            labelTextLeft={
              <div className="icon-wrapper">
                <i className="icon-duplicate" />
              </div>
            }
            min={-1}
            max={2147483647}
            onChange={(e) => onChange(parseInt(e.target.value, 10))}
          />
        </div>
      )}
    </>
  );
};

ZIndex.propTypes = propTypes;
ZIndex.defaultProps = defaultProps;

export default ZIndex;
