import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {isTrying} from 'services/project';

export const useTrialRemaining = () => {
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const result = {
    daysRemaining: 0,
    daysTotal: 0,
    percentage: 0,
  };

  if (isTrying() === false) {
    return result;
  }

  result.daysTotal =
    Math.abs(
      dayjs(subscription.trialStartedAt).diff(
        subscription.stripeCurrentPeriodEnd * 1000,
        'days'
      )
    ) + 1;
  result.daysRemaining =
    Math.abs(dayjs().diff(subscription.stripeCurrentPeriodEnd * 1000, 'days')) +
    1;

  result.percentage =
    result.daysRemaining === 0
      ? 0
      : Math.round((result.daysRemaining / result.daysTotal) * 100);
  return result;
};
