import classnames from 'classnames';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import {useContext} from 'react';
import './_styles.scss';

export const ModalPlanCancelDone = () => {
  const {setModalPlanCancelDone} = useContext(GlobalContext);

  const handleRequestClose = () => {
    setModalPlanCancelDone(false);
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleRequestClose}
      className={classnames('modal-plan-cancel-done')}>
      <div className="modal-main">
        <div className="cancel-title title-3 n-800">
          Plan successfully cancelled
        </div>
        <div className="cancel-description body-3 n-700">
          Thank you for your feedback. We’re really sad to see you go. Let us
          know if there’s anything we can help with at{' '}
          <b>support@usejimo.com</b>
        </div>
        <Button primary onClick={handleRequestClose}>
          Okay
        </Button>
      </div>
    </Modal>
  );
};
