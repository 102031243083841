import React, {useContext, useState} from 'react';
import './_Styles.scss';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {F_OPTION_IGNORE_RATE_LIMITING, isActive} from 'services/evolution';
import {ModalConfirm} from 'components/Modal';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {rateLimitUnitOptions} from 'scenes/Settings/scenes/PokeControl';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import classNames from 'classnames';
import {arePokeMatchingUrls} from './utils';
import {AudienceContext} from 'scenes/PokeAudience';
import Tooltip from 'components/Tooltip';

const PokePriorityRow = ({poke, selected = false}) => {
  return (
    <div className={classNames('poke-priority-row', {selected: selected})}>
      <div className="poke-priority-number">{poke.priority || 0}</div>
      <div className="poke-name">{poke.title}</div>
      <div className="poke-matching-rules"></div>
    </div>
  );
};

const Priority = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const {data: livePokes} = useQuery({
    queryKey: ['livePokes', evolution?.uid],
    queryFn: () =>
      evolutionService.getEvolutions({
        where: [isActive],
        onlyPokes: true,
        relations: ['boostedPaths'],
      }),
    refetchOnWindowFocus: false,
  });

  const [showModalIgnoreRateLimiting, setShowModalIgnoreRateLimiting] =
    useState(false);

  const handleIgnoreRateLimiting = () => {
    setEvolution({
      ...evolution,
      optionsFlags: addFlag(
        F_OPTION_IGNORE_RATE_LIMITING,
        evolution.optionsFlags
      ),
    });
    setShowModalIgnoreRateLimiting(false);
  };

  const hasPokeControl =
    hasFlag(F_OPTION_IGNORE_RATE_LIMITING, evolution.optionsFlags) !== true;

  const filteredLivePokes = livePokes
    ?.filter((poke) => {
      return arePokeMatchingUrls(evolution, poke);
    })
    .filter((poke) => poke.uid !== evolution.uid);
  const livePokesWithCurrent = [...(filteredLivePokes || []), evolution];
  livePokesWithCurrent?.sort((a, b) => (b.priority || 0) - (a.priority || 0));

  return (
    <div className="priority-wrapper">
      <div className="priority-activate-wrapper">
        <div className="poke-control-priority">
          <div
            className="ignore-poke-control"
            onClick={() => {
              if (hasPokeControl === true) {
                setShowModalIgnoreRateLimiting(true);
              } else {
                setEvolution({
                  ...evolution,
                  optionsFlags: removeFlag(
                    F_OPTION_IGNORE_RATE_LIMITING,
                    evolution.optionsFlags
                  ),
                });
              }
            }}>
            {hasPokeControl === true && <i className="icon-checkbox" />}
            {hasPokeControl === false && <i className="icon-checkbox-o" />}
            Rate limiting
          </div>
          {hasPokeControl === true && (
            <div
              className={classNames('priority-input-wrapper', {
                'is-ignoring-poke-control': hasPokeControl !== true,
              })}>
              <InputGroup
                className="priority-input"
                onChange={({target}) =>
                  setEvolution({
                    ...evolution,
                    priority: target.value,
                  })
                }
                value={evolution.priority || 0}
                type="number"
                max={999}
                min={0}
              />
              Priority
            </div>
          )}
        </div>
        <Tooltip
          className="priority-tooltip"
          offsetY={4}
          trigger={
            <i className="priority-tooltip-trigger icon-hotspot-shape-2" />
          }>
          <span>
            Priority allows you to define a priority order between live Pokes
            when there is a conflict. For example, a Poke with a priority of 10
            will be displayed before a Poke with a priority of 9
          </span>
          <span>
            Rate limiting: Set the maximum number of in-app experiences a
            specific user can see within a specific time range. It helps you
            maintain a great experience for your end-users and prevent them from
            being overwhelmed by too many experiences.
          </span>
        </Tooltip>
      </div>
      {hasPokeControl === true && (
        <div className="priority-list-wrapper">
          Here is how your experience priority compares to your other live
          experiences. Experiences with higher priority will be shown first.
          {livePokesWithCurrent?.map((poke) => {
            return (
              <PokePriorityRow
                poke={poke}
                selected={poke.uid === evolution.uid}
              />
            );
          })}
        </div>
      )}

      <ModalConfirm
        className="modal-confirm-ignore-rate-limiting"
        confirmText="Yes, ignore it"
        onConfirm={handleIgnoreRateLimiting}
        onCancel={() => setShowModalIgnoreRateLimiting(false)}
        isOpen={showModalIgnoreRateLimiting}
        onRequestClose={() => setShowModalIgnoreRateLimiting(false)}>
        <div className="content">
          <div className="info">
            You are about to exclude this experience from your rate limiting
            settings.
          </div>
          <div className="rate-limiting-settings">
            Your rate limiting settings is set to:
            <div className="rate-limit-group">
              Display
              <InputGroup
                value={project.rateLimitPokeAmount}
                type="number"
                disabled
              />
              experience(s) every
              <InputGroup
                value={project.rateLimitValue}
                type="number"
                disabled
              />
              <SelectGroup
                value={rateLimitUnitOptions.find(
                  (o) => o.value === project.rateLimitUnit
                )}
                options={rateLimitUnitOptions}
                isDisabled
              />
            </div>
            It helps you maintain a great experience for your end-users.
          </div>
        </div>
      </ModalConfirm>
    </div>
  );
};

export default Priority;
