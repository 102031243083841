import Button from 'components/Button';
import BtnCopy from 'components/ButtonCopy';
import {Modal} from 'components/Modal';
import React, {useContext} from 'react';
import {AudienceContext} from 'scenes/PokeAudience';
import './_Styles.scss';

const ManualTriggerModal = ({isOpen, onRequestClose, ...rest}) => {
  const {evolution} = useContext(AudienceContext);

  return (
    <Modal
      className="modal-manual-trigger"
      title={
        <>
          <i className="icon-code" />
          On manual trigger
        </>
      }
      footer={
        <Button primary onClick={onRequestClose}>
          Close
        </Button>
      }
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <div className="fade-in-right">
        <div className="content">
          <div>
            Copy the code below and paste it wherever you want to trigger this
            experience.
          </div>
          <div className="code-snippet-wrapper trigger-settings-wrapper">
            <div className="btn-copy-wrapper">
              <BtnCopy
                textToCopy={`window.jimo.push(['do', 'boosted:trigger', [{ evolutionId : "${evolution.uid}" }]])`}
                text="copy"
                rounded={false}
                thin
                muted
              />
            </div>
            <pre>
              {`window.jimo.push(['do', 'boosted:trigger', [{ evolutionId : "${evolution.uid}" }]])`}
            </pre>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManualTriggerModal;
