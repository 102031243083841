import {dataActions} from 'actions';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useFetchSubscriptionPlansAndAddons');

export const useFetchSubscriptionPlansAndAddons = () => {
  const dispatch = useDispatch();

  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));
  const setSubscriptionAddons = (addons) =>
    dispatch(dataActions.setSubscriptionAddons(addons));

  const [isFetching, setIsFetching] = useState(true);

  async function fetch() {
    try {
      const [plans, addons] = await Promise.all([
        subscriptionService.getSubscriptionPlans(),
        subscriptionService.getSubscriptionAddons(),
      ]);

      setSubscriptionPlans(plans);
      setSubscriptionAddons(addons);
      setIsFetching(false);
    } catch (err) {
      return logger.error('Fetching plans failed with error ', err);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return {
    isFetching,
  };
};
