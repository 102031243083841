import React from 'react';
import './_Styles.scss';
import {array} from 'prop-types';
import {useHistory} from 'react-router-dom';
import {ROUTE_TRACKER_REPORT} from 'router/routes.const';
import {generateDatesArray} from 'scenes/Poke/component/Statistics';
import dayjs from 'dayjs';
import PreviewStackedLineChart from './components/PreviewStackedLineChart';
import {useQuery} from 'react-query';
import {trackerService} from 'services';
import DefaultLoader from 'components/Loader';
import SuccessTrackerEmptyState from '../SuccessTrackerEmptyState';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import TrackerPreviewActivation from './components/TrackerPreviewActivation';

const TrackerPreviewFeatureUsage = ({tracker}) => {
  const {analyticsDaily = [], analyticsWeekly = []} = tracker;

  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const datesArr = generateDatesArray(startDate, endDate).filter((d) =>
    dayjs(d).isBefore(dayjs().endOf('day'))
  );

  const datesArrWithAnalytics = datesArr.map((cur) => {
    const date = cur.format('YYYY-MM-DD');
    const timestamp = cur.unix();

    const foundData = analyticsDaily?.filter((d) => {
      return dayjs(d.createdAt).isSame(cur, 'day');
    });
    let count = 0;

    if (foundData?.length > 0) {
      count = foundData.reduce(
        (acc, cur) => (acc += parseInt(cur.uniqueCount, 10)),
        0
      );
    }

    return {
      date,
      timestamp,
      count,
    };
  });

  const lastWeek = dayjs().subtract(7, 'day');

  const currentWeekCount = analyticsWeekly
    .filter((d) => {
      return dayjs(d.createdAt).isAfter(lastWeek);
    })
    .reduce((acc, cur) => (acc += parseInt(cur.uniqueCount, 10)), 0);
  const lastWeekCount = analyticsWeekly
    .filter((d) => {
      return dayjs(d.createdAt).isAfter(lastWeek) !== true;
    })
    .reduce((acc, cur) => (acc += parseInt(cur.uniqueCount, 10)), 0);

  return (
    <>
      <div className="tracker-info">
        <div className="tracker-name">{tracker?.name || 'Tracker Name'}</div>
        <div className="tracker-metric">
          {currentWeekCount}
          {lastWeekCount > 0 && currentWeekCount > 0 ? (
            <>
              {currentWeekCount >= lastWeekCount ? (
                <span className="tracker-metric-subtext body-4 g-400">
                  <i className="isax isax-arrow-up-15" />+
                  {Math.round(
                    ((currentWeekCount - lastWeekCount) / lastWeekCount) * 100
                  )}
                  % vs last week
                </span>
              ) : (
                <span className="tracker-metric-subtext body-4 r-300">
                  <i className="isax isax-arrow-down-5" />-
                  {Math.round(
                    ((lastWeekCount - currentWeekCount) / lastWeekCount) * 100
                  )}
                  % vs last week
                </span>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="tracker-preview">
        <PreviewStackedLineChart data={datesArrWithAnalytics} />
      </div>
    </>
  );
};

const Tracker = ({tracker}) => {
  const history = useHistory();

  const isActivationTracker = tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER;
  const isFeatureUsageTracker = tracker.type === TRACKER_TYPE_FEATURE_USAGE;

  return (
    <div
      className="tracker"
      onClick={() => history.push(ROUTE_TRACKER_REPORT(tracker.uid))}>
      <div className="open-label">Open</div>
      {isActivationTracker === true && (
        <TrackerPreviewActivation tracker={tracker} />
      )}
      {isFeatureUsageTracker === true && (
        <TrackerPreviewFeatureUsage tracker={tracker} />
      )}
    </div>
  );
};

const propTypes = {
  trackers: array,
};

const defaultProps = {
  trackers: [],
};

const Trackers = ({setShowCreateModal, setDefaultTrackerType}) => {
  const {isLoading, data: trackers} = useQuery({
    queryKey: 'trackers',
    queryFn: () => trackerService.getTrackers(),
  });

  // remove goal trackers for now
  const filteredTrackers = trackers?.filter((tracker) => {
    return [
      TRACKER_TYPE_ACTIVATION_TRACKER,
      TRACKER_TYPE_FEATURE_USAGE,
    ].includes(tracker.type);
  });

  return (
    <>
      {!(filteredTrackers?.length > 0) && isLoading !== true ? (
        <SuccessTrackerEmptyState
          onTrackerTypeClick={(trackerType) => {
            setDefaultTrackerType(trackerType);
            setShowCreateModal(true);
          }}
        />
      ) : isLoading === true ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : (
        <div className="trackers-wrapper">
          <div className="trackers">
            {filteredTrackers.map((tracker) => (
              <Tracker key={tracker.uid} tracker={tracker} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

Trackers.propTypes = propTypes;
Trackers.defaultProps = defaultProps;

export default Trackers;
