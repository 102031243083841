import classnames from 'classnames';
import Button from 'components/Button';
import {func, object, any} from 'prop-types';
import {stepsService} from 'services';
import './_styles.scss';

const propTypes = {
  step: object.isRequired,
  value: any,
  onVote: func.isRequired,
  btnStyle: object,
};

const defaultProps = {
  value: null,
  btnStyle: {},
};

const Vote = ({step, value, onVote, btnStyle}) => {
  let isSubmitDisabled = false;

  // eslint-disable-next-line default-case
  switch (step.type) {
    case stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT: {
      if (value?.length <= 0) {
        isSubmitDisabled = true;
      }
      break;
    }
    case stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT: {
      if (value == null) {
        isSubmitDisabled = true;
      }
      break;
    }
    case stepsService.STEP_TYPE_TEXT_LONG: {
      if (!value) {
        isSubmitDisabled = true;
      }
      break;
    }
  }

  return (
    <div className="vote-wrapper">
      {step?.responsesCount > 0 && (
        <div className="number-vote">{step?.responsesCount} votes</div>
      )}
      <Button
        rounded
        style={btnStyle}
        className={classnames('action-btn', {
          disabled: isSubmitDisabled,
        })}
        onClick={onVote}
        disabled={isSubmitDisabled}>
        {[
          stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
          stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
        ].includes(step?.type)
          ? 'Vote'
          : 'Submit'}
      </Button>
    </div>
  );
};

Vote.propTypes = propTypes;
Vote.defaultProps = defaultProps;

export default Vote;
