import {EVENT_VIEW_PUSH_INSIGHTS} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import CustomSlider from 'components/CustomSlider';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import Label from 'components/Label';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';
import {hasUsernameGenerated} from 'helpers/utils';
import {htmlToText} from 'html-to-text';
import {object} from 'prop-types';
import {useEffect, useState} from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import {stepsService} from 'services';
import {BLOCK_TYPE_BODY, BLOCK_TYPE_TITLE} from 'services/steps';
import {
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import {
  SLIDER_TYPE_SMILEY,
  parseSliderValue,
} from 'shared/front/components/Poke/components/BlockSlider';
import {
  BLOCK_OPINION,
  BLOCK_SLIDER,
} from 'shared/front/components/Poke/constants/blocks';
import {HiddenStepTooltip} from '../Overview';
import './_Styles.scss';
import FloatingReportFilters from './components/FloatingReportFilters';
import ResponsesDrawer from './components/ResponsesDrawer';

const detractorValues = ['0', '1', '2', '3', '4', '5', '6'];
const passiveValues = ['7', '8'];
const promoterValues = ['9', '10'];

const CATEGORY_PROMOTER = 'promoter';
const CATEGORY_PASSIVE = 'passive';
const CATEGORY_DETRACTOR = 'detractor';

const Slider = ({step, setAverage}) => {
  const {filteredResponses: responses, blocks} = step;
  const blockSlider = blocks?.find((b) => b.type === BLOCK_SLIDER);
  const {type, customEmoji, labelLeft, labelRight} = parseSliderValue(
    blockSlider.value
  );
  const nonEmptyResponses = responses.filter(
    (r) => r.value != null && r.value.length > 0
  );
  const mean =
    nonEmptyResponses.length > 0
      ? nonEmptyResponses.reduce(
          (acc, cur) => acc + parseInt(cur.value, 10),
          0
        ) / nonEmptyResponses.length
      : 0;

  useEffect(() => {
    setAverage(mean);
  }, [mean]);

  if (nonEmptyResponses.length === 0) {
    return <div className="no-responses">Nobody has responded to this yet</div>;
  }

  return (
    <div className="slider-wrapper">
      <CustomSlider
        handle={type === SLIDER_TYPE_SMILEY ? null : customEmoji}
        value={mean}
        showPercentage
      />
      {labelLeft && labelRight && (
        <div className="labels">
          <div className="label">{labelLeft}</div>
          <div className="label">{labelRight}</div>
        </div>
      )}
    </div>
  );
};

const LegacySlider = ({step}) => {
  const {filteredResponses: responses} = step;
  const mean =
    responses.length > 0
      ? responses.reduce((acc, cur) => acc + parseInt(cur.value, 10), 0) /
        responses.length
      : 0;

  return (
    <div className="slider-wrapper">
      <CustomSlider value={mean} showPercentage />
    </div>
  );
};

const MultipleChoice = ({
  step,
  filters,
  setFilters,
  showJimers,
  responsesCount,
}) => {
  const {options = []} = step;
  options.forEach((o) => {
    o.filteredResponses = o.responses.filter(
      (r) =>
        r != null && step.filteredResponses.map((f) => f.uid).includes(r.uid)
    );
  });
  const totalAnswers = responsesCount;
  options.sort((a, b) => a.responses.length - b.responses.length);

  return (
    <>
      {options
        .sort((a, b) => a.indexOrder - b.indexOrder)
        .map((option) => {
          const percentage =
            Math.round(
              (option.filteredResponses.length * 100) / totalAnswers
            ) || 0;

          const isActiveFilter =
            filters[step.uid]?.includes(option.uid) === true;

          const handleFilter = () => {
            if (isActiveFilter === true) {
              if (filters[step.uid].length > 1) {
                setFilters({
                  [step.uid]: filters[step.uid].filter((v) => v !== option.uid),
                });
              } else {
                setFilters({});
              }
            } else {
              if (filters[step.uid] != null) {
                setFilters({
                  [step.uid]: [...filters[step.uid], option.uid],
                });
              } else {
                setFilters({[step.uid]: [option.uid]});
              }
            }
          };

          const handleViewJimers = async () => {
            showJimers({responses: step.filteredResponses, value: option.uid});
          };

          return (
            <div className="option-bar-wrapper">
              <div className="option-content">
                <div className="option-info">
                  <div className="percentage">{percentage} %</div>
                  <div className="label">{option.content}</div>
                </div>
                <div className="count-wrapper">
                  <i className="icon-users" />
                  <div className="response-number">
                    {option.filteredResponses.length}
                  </div>

                  <div className="actions">
                    <Button
                      primary
                      className="view-btn"
                      iconOnly
                      iconLeft="icon-eye"
                      onClick={handleViewJimers}>
                      View responses
                    </Button>
                    <Button
                      primary
                      className="filter-btn"
                      iconOnly
                      iconLeft="icon-filter"
                      onClick={handleFilter}>
                      {isActiveFilter ? 'Remove filter' : 'Filter by response'}
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className={classnames('option-bar', {active: isActiveFilter})}
                style={{
                  backgroundImage: `linear-gradient(to right, ${
                    isActiveFilter ? '#85B1FF' : '#85B1FF'
                  } ${percentage}%, ${
                    isActiveFilter ? '#ffffff' : 'rgba(0, 0, 0, 0.05)'
                  } ${percentage}%, ${
                    isActiveFilter ? '#ffffff' : 'rgba(0, 0, 0, 0.05)'
                  } ${100 - percentage}%)`,
                }}></div>
            </div>
          );
        })}
    </>
  );
};

const OpinionScale = ({step, filters, setFilters, showJimers, setAverage}) => {
  const {filteredResponses: responses, blocks} = step;
  const blockOpinion = blocks?.find((b) => b.type === BLOCK_OPINION);
  const opinionValue = parseOpinionValue(blockOpinion?.value || '');

  const nonEmptyResponses = responses.filter(
    (r) => r.value != null && r.value.length > 0
  );
  const totalAnswers = nonEmptyResponses.length;

  let opinions = [];

  if (['emoji', 'smiley'].includes(opinionValue.type)) {
    opinions = JSON.parse(
      JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
    );
  } else if (opinionValue.type === 'star') {
    opinions = new Array(opinionValue.scale).fill(0).map((_, i) => {
      return {
        value: (i + 1).toString(),
        label: new Array(i + 1)
          .fill(0)
          .map(() => <i className="icon-star" style={{color: '#FFC107'}} />),
      };
    });
  }

  // use index average as value might not follow the scale
  const average =
    totalAnswers > 0
      ? Math.round(
          (nonEmptyResponses.reduce((acc, cur) => {
            const valueIndex = opinions.findIndex((o) => o.value === cur.value);
            acc = acc + valueIndex;
            return acc;
          }, 0) *
            10) /
            totalAnswers
        ) / 10
      : 0;

  useEffect(() => {
    const closestValue = opinions[Math.round(average)];
    setAverage(closestValue.label);
  }, [average]);

  return (
    <div className="opinion-items-wrapper">
      {opinions.map((opinion) => {
        const numberResponses = nonEmptyResponses.filter(
          (r) => r.value === opinion.value
        ).length;
        const percentage =
          Math.round((numberResponses * 100) / totalAnswers) || 0;

        const isActiveFilter =
          filters[step.uid]?.includes(opinion.value) === true;

        const handleFilter = () => {
          if (isActiveFilter === true) {
            if (filters[step.uid].length > 1) {
              setFilters({
                [step.uid]: filters[step.uid].filter(
                  (v) => v !== opinion.value
                ),
              });
            } else {
              setFilters({});
            }
          } else {
            if (filters[step.uid] != null) {
              setFilters({
                [step.uid]: [...filters[step.uid], opinion.value],
              });
            } else {
              setFilters({[step.uid]: [opinion.value]});
            }
          }
        };

        const handleViewJimers = async () => {
          showJimers({responses: step.filteredResponses, value: opinion.value});
        };

        return (
          <div className="opinion-item-wrapper">
            <div className="content">
              <div className="percentage">{percentage}%</div>
              <div className="count">
                <i className="icon-users" />
                <div className="response-count">{numberResponses}</div>
              </div>
            </div>
            <div
              className={classnames('opinion-bar', {
                active: isActiveFilter,
              })}
              style={{
                backgroundImage: `linear-gradient(to top, ${
                  isActiveFilter ? '#85B1FF' : '#85B1FF'
                } ${percentage}%, ${
                  isActiveFilter ? '#ffffff' : 'rgba(0, 0, 0, 0.05)'
                } ${percentage}%, ${
                  isActiveFilter ? '#ffffff' : 'rgba(0, 0, 0, 0.05)'
                } ${100 - percentage}%)`,
              }}>
              <div className="actions">
                <Button
                  primary
                  className="view-btn"
                  iconOnly
                  iconLeft="icon-eye"
                  onClick={handleViewJimers}>
                  View responses
                </Button>
                <Button
                  primary
                  className="filter-btn"
                  iconOnly
                  iconLeft="icon-filter"
                  onClick={handleFilter}>
                  {isActiveFilter ? 'Remove filter' : 'Filter by response'}
                </Button>
              </div>
            </div>
            {['emoji', 'smiley'].includes(opinionValue.type) === true && (
              <div className="opinion-label">{opinion.label}</div>
            )}
            {opinionValue.type === 'star' && (
              <div className="opinion-label star">{opinion.label}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const OpenQuestion = ({step, filters, setFilters, showJimers}) => {
  const {filteredResponses: responses} = step;

  const responsesToDisplay = responses.filter((r) => r.value);

  if (responsesToDisplay.length === 0) {
    return <div className="no-responses">Nobody has responded to this yet</div>;
  }

  return (
    <div className="responses-wrapper">
      {responsesToDisplay
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3)
        .map((r) => {
          const isActiveFilter = filters[step.uid]?.includes(r.uid) === true;

          const handleFilter = () => {
            if (isActiveFilter === true) {
              if (filters[step.uid].length > 1) {
                setFilters({
                  [step.uid]: filters[step.uid].filter((v) => v !== r.uid),
                });
              } else {
                setFilters({});
              }
            } else {
              if (filters[step.uid] != null) {
                setFilters({
                  [step.uid]: [...filters[step.uid], r.uid],
                });
              } else {
                setFilters({[step.uid]: [r.uid]});
              }
            }
          };

          const jimer = r.jimer;
          const username =
            jimer?.externalUsername != null &&
            jimer?.externalUsername.length !== 0
              ? jimer?.externalUsername
              : jimer?.username;
          const isAnonymous = hasUsernameGenerated(username);

          return (
            <div
              key={r.uid}
              className={classnames('response', {active: isActiveFilter})}>
              <div className="content">
                <div className="value">“{r.value}“</div>
                <div className="info">
                  <div className="info-content">
                    <div className="user-info">
                      <i className="icon-users" />
                      <div className="username">
                        {isAnonymous === true ? 'Anonymous' : username}
                      </div>
                      {isAnonymous === true && (
                        <div className="anonymous-username">{username}</div>
                      )}
                      {jimer?.externalEmail && (
                        <div className="email-info">
                          &bull; {jimer?.externalEmail}
                        </div>
                      )}
                    </div>
                    <div className="time-info">
                      <i className="icon-calendar" />
                      <div className="time">
                        {dayjs(r.createdAt).format('DD MMM YYYY, HH:mm')}
                      </div>
                    </div>
                  </div>
                  <div className="actions">
                    <Button
                      primary
                      className="filter-btn"
                      iconOnly
                      iconLeft="icon-filter"
                      onClick={handleFilter}>
                      {isActiveFilter ? 'Remove filter' : 'Filter by response'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const Nps = ({step, filters, setFilters, showJimers, setAverage}) => {
  const {filteredResponses, responses: allResponses} = step;
  const responses =
    filters[step.uid] != null ? allResponses : filteredResponses;

  const groupedNps = responses.reduce(
    (acc, cur) => {
      if (detractorValues.includes(cur.value)) {
        acc.detractor = acc.detractor + 1;
        acc.total = acc.total + 1;
      }
      if (passiveValues.includes(cur.value)) {
        acc.passive = acc.passive + 1;
        acc.total = acc.total + 1;
      }
      if (promoterValues.includes(cur.value)) {
        acc.promoter = acc.promoter + 1;
        acc.total = acc.total + 1;
      }
      if (!!cur.value) {
        acc.sumValue = acc.sumValue + parseInt(cur.value, 10);
      }
      return acc;
    },
    {detractor: 0, passive: 0, promoter: 0, total: 0, sumValue: 0}
  );

  const categories = [CATEGORY_PROMOTER, CATEGORY_PASSIVE, CATEGORY_DETRACTOR];

  const average =
    groupedNps.total > 0
      ? Math.round((groupedNps.sumValue * 10) / groupedNps.total) / 10
      : 0;

  // use correct nps metric value calculation
  const npsValue =
    groupedNps.total > 0
      ? Math.round(
          ((groupedNps.promoter - groupedNps.detractor) * 100) /
            groupedNps.total
        )
      : 0;

  useEffect(() => {
    setAverage(average);
  }, [average]);

  return (
    <div className="nps-group">
      <div className="chart-wrapper">
        <PieChart
          animate
          data={
            responses?.length > 0
              ? [
                  {value: groupedNps.promoter, color: '#98D28F'},
                  {value: groupedNps.passive, color: '#85B1FF'},
                  {value: groupedNps.detractor, color: '#FA7575'},
                ]
              : [{value: 1, color: '#E5E5E5'}]
          }
          lineWidth={15}
        />
        <div className="nps-metric-wrapper">
          <div className="label">
            Total score
            <Tooltip
              className="nps-metric-tooltip"
              trigger={<i className="icon-question-circle" />}>
              The Net Promoter Score (NPS) is calculated by subtracting the
              percentage of detractors (those who rate 0-6) from the percentage
              of promoters (those who rate 9-10) to gauge overall customer
              satisfaction and loyalty.
            </Tooltip>
          </div>
          <div className="value">{npsValue}</div>
        </div>
      </div>
      <div className="group-list">
        {categories.map((category) => {
          let valuesToCheck;
          let label;
          if (category === CATEGORY_PROMOTER) {
            valuesToCheck = promoterValues;
            label = 'Promoters';
          } else if (category === CATEGORY_PASSIVE) {
            valuesToCheck = passiveValues;
            label = 'Passive';
          } else if (category === CATEGORY_DETRACTOR) {
            valuesToCheck = detractorValues;
            label = 'Detractors';
          }
          const isActiveFilter =
            filters[step.uid]?.includes(valuesToCheck[0]) === true;

          const handleViewJimers = async () => {
            showJimers({responses: step.filteredResponses, value: category});
          };
          const handleFilter = () => {
            if (isActiveFilter === true) {
              if (filters[step.uid].length > valuesToCheck.length) {
                setFilters({
                  [step.uid]: filters[step.uid].filter(
                    (v) => valuesToCheck.includes(v) === false
                  ),
                });
              } else {
                setFilters({});
              }
            } else {
              if (filters[step.uid] != null) {
                setFilters({
                  [step.uid]: [...filters[step.uid], ...valuesToCheck],
                });
              } else {
                setFilters({[step.uid]: valuesToCheck});
              }
            }
          };

          const count = groupedNps[category];
          const percentage =
            groupedNps.total > 0
              ? parseInt((count * 100) / groupedNps.total, 10)
              : 0;

          return (
            <div
              className={classnames('nps-category-wrapper', {
                active: isActiveFilter,
              })}>
              <div className={classnames('nps-category', category)}>
                <div className="nps-category-content">
                  <div className="label-wrapper">
                    <div className="category-dot"></div>
                    <div className="label">{label}</div>
                  </div>
                  <div className="category-subtitle">
                    Respond with a score of {valuesToCheck[0]} to{' '}
                    {valuesToCheck[valuesToCheck.length - 1]}
                  </div>
                </div>
                <div className="nps-category-stats">
                  <div className="percentage">{percentage}%</div>
                  <div className="count">
                    <i className="icon-users" />
                    {count}
                  </div>
                </div>
              </div>
              <div className="actions">
                <Button
                  primary
                  className="view-btn"
                  iconOnly
                  iconLeft="icon-eye"
                  onClick={handleViewJimers}>
                  View responses
                </Button>
                <Button
                  primary
                  className="filter-btn"
                  iconOnly
                  iconLeft="icon-filter"
                  onClick={handleFilter}>
                  {isActiveFilter ? 'Remove filter' : 'Filter by response'}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SurveyStep = ({
  index,
  step,
  isNested,
  filters,
  setFilters,
  className,
  children,
  parentStepIndex,
}) => {
  const [drawerData, setDrawerData] = useState(null);
  const [average, setAverage] = useState(0);

  let {name, type} = step;
  let icon, content, question, typeName, typeClassName;

  const nonEmptyResponses = step.filteredResponses.filter(
    (r) =>
      (r.value != null && r.value.length > 0) || r.selectedOptions?.length > 0
  );
  const responsesCount = nonEmptyResponses.length;

  if (type === stepsService.STEP_TYPE_OPINION_SCALE) {
    typeName = 'Opinion scale';
    icon = <i className="icon-vote-opinion" />;
    if (step.blocks?.length > 0) {
      content = (
        <OpinionScale
          step={step}
          filters={filters}
          setFilters={setFilters}
          showJimers={setDrawerData}
          setAverage={setAverage}
        />
      );
    } else {
      content = <></>;
    }
    typeClassName = 'opinion-scale';
  } else if (
    type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT ||
    type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
  ) {
    typeName = 'Multiple choice';
    icon = <i className="icon-vote-multi-choice" />;
    content = (
      <MultipleChoice
        step={step}
        filters={filters}
        setFilters={setFilters}
        showJimers={setDrawerData}
        responsesCount={responsesCount}
      />
    );
    typeClassName = 'multiple-choice';
  } else if (type === stepsService.STEP_TYPE_TEXT_LONG) {
    typeName = 'Open question';
    icon = <i className="icon-vote-open-question" />;
    content = (
      <OpenQuestion
        step={step}
        filters={filters}
        setFilters={setFilters}
        showJimers={setDrawerData}
      />
    );
    typeClassName = 'text-long';
  } else if (type === stepsService.STEP_TYPE_SLIDER) {
    typeName = 'Slider';
    icon = <i className="icon-vote-slider" />;
    if (step.blocks?.length > 0) {
      content = <Slider step={step} setAverage={setAverage} />;
    } else {
      content = <LegacySlider step={step} />;
    }
    typeClassName = 'slider';
  } else if (type === stepsService.STEP_TYPE_NPS) {
    typeName = 'Nps';
    icon = <i className="icon-vote-nps" />;
    content = (
      <Nps
        step={step}
        filters={filters}
        setFilters={setFilters}
        showJimers={setDrawerData}
        setAverage={setAverage}
      />
    );
    typeClassName = 'nps';
  } else if (type === stepsService.STEP_TYPE_CONCEPT_TEST) {
    typeName = 'Concept test';
    icon = <i className="icon-filter-preview" />;
    typeClassName = 'concept-test';
    content = step.additionalInformation;
  } else {
    return <></>;
  }

  className = classnames(className, typeClassName);

  name =
    name ||
    `Step ${parentStepIndex ? `${parentStepIndex + 1}.` : ''}${index + 1}`;
  const titleBlock = step.blocks?.find((b) => b.type === BLOCK_TYPE_TITLE);
  const bodyBlock = step.blocks?.find((b) => b.type === BLOCK_TYPE_BODY);
  if (titleBlock?.value) {
    const [text] = titleBlock.value.split('|-|');
    if (text) {
      question = htmlToText(text);
    }
  } else if (bodyBlock?.value) {
    question = htmlToText(bodyBlock.value || '');
  }

  let stepHeader = (
    <div className={classnames('infos', typeClassName)}>
      <div className="icon-wrapper">{icon}</div>
      <div className="content">
        <div className="title">
          {name} &bull; {typeName}{' '}
          {step.removed === true ? (
            <HiddenStepTooltip
              step={step}
              trigger={
                <div className="label-hidden-wrapper">
                  <Label
                    size="small"
                    type="neutral"
                    primary
                    iconLeft="isax isax-eye-slash"
                    className="label-hidden">
                    Hidden since{' '}
                    {dayjs(step.updateData?.date).format('DD MMM YYYY')}
                  </Label>
                </div>
              }
            />
          ) : ['added', 'reordered'].includes(step.updateData?.operation) ? (
            <div className="label-hidden-wrapper">
              <Label
                size="small"
                type="neutral"
                primary
                className="label-hidden">
                {step.updateData.operation === 'added'
                  ? 'Added'
                  : step.updateData.operation === 'reordered'
                  ? 'Reordered'
                  : ''}
              </Label>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="question">{question}</div>
      </div>
    </div>
  );

  return (
    <div
      className={classnames('survey-step', className, {'is-nested': isNested})}>
      <div className="step-header-wrapper">
        {stepHeader}
        <div className="step-stats">
          {[
            stepsService.STEP_TYPE_NPS,
            stepsService.STEP_TYPE_SLIDER,
            stepsService.STEP_TYPE_OPINION_SCALE,
          ].includes(step.type) && (
            <div
              className="step-responses-average"
              onClick={() =>
                setDrawerData({
                  responses: step.filteredResponses,
                  value: null,
                })
              }>
              <div className="label">Average</div>
              <div className="count">
                {step.type === stepsService.STEP_TYPE_SLIDER
                  ? `${Math.round(average / 10)}%`
                  : average}
              </div>
            </div>
          )}
          {step.type !== stepsService.STEP_TYPE_CONCEPT_TEST && (
            <div
              className="step-responses-count"
              onClick={() =>
                setDrawerData({
                  responses: step.filteredResponses,
                  value: null,
                })
              }>
              <div className="label">Responded</div>
              <div className="count">{responsesCount}</div>
            </div>
          )}
        </div>
      </div>
      {content && <div className="results">{content}</div>}
      {children != null && (
        <div className="nested-surveys-wrapper">{children}</div>
      )}
      {children == null && (
        <Button
          className="view-responses-btn"
          iconRight="icon-chevron-right"
          onClick={() =>
            setDrawerData({
              responses: step.filteredResponses,
              value: null,
            })
          }>
          View responses
        </Button>
      )}
      {drawerData != null && (
        <ResponsesDrawer
          isOpen={drawerData != null}
          onRequestClose={() => setDrawerData(null)}
          responses={drawerData?.responses}
          selectedValue={drawerData?.value}
          step={step}
          stepHeader={stepHeader}
        />
      )}
    </div>
  );
};

const Report = ({evolution, reportRef}) => {
  let {steps, surveys} = evolution;

  const [filters, setFilters] = useState({});

  useEffect(() => {
    amplitude.getInstance().logEvent(EVENT_VIEW_PUSH_INSIGHTS);
  }, []);

  if (surveys == null || surveys.length === 0) {
    return (
      <EmptyStateBlock
        img={EmptyStateImgs.EmptyResponses}
        title="No responses yet"
        description="As your Poke gets some responses, you'll find them right here."
      />
    );
  }

  if (steps?.length > 0) {
    steps.sort((a, b) => a.indexOrder - b.indexOrder);
    steps.forEach((s) => (s.filteredResponses = []));
    const filteredSurveys = surveys.reduce((acc, cur) => {
      const {responses} = cur;

      for (let key of Object.keys(filters)) {
        const step = steps.find((s) => s.uid === key);

        if (step == null) {
          return acc;
        }

        const response = responses.find((r) => r.step?.uid === key);

        if (response == null) {
          return acc;
        }

        if (
          step.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ||
          step.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT
        ) {
          if (
            response.selectedOptions
              .map((s) => s.uid)
              .some((s) => filters[key].includes(s)) === false
          ) {
            return acc;
          }
        }

        if (step.type === stepsService.STEP_TYPE_OPINION_SCALE) {
          if (filters[key].includes(response.value) === false) {
            return acc;
          }
        }

        if (step.type === stepsService.STEP_TYPE_NPS) {
          if (filters[key].includes(response.value) === false) {
            return acc;
          }
        }

        if (step.type === stepsService.STEP_TYPE_TEXT_LONG) {
          if (filters[key].includes(response.uid) === false) {
            return acc;
          }
        }
      }
      acc.push(cur);

      steps.forEach((s) => {
        const r = responses.find((r) => r.step?.uid === s.uid);
        if (r != null) {
          const responseWithJimer = s.responses.find(
            (response) => response.uid === r.uid
          );
          if (responseWithJimer != null) {
            s.filteredResponses.push({
              ...responseWithJimer,
              jimer: cur.jimer,
              surveyId: cur.uid,
            });
          }
        }
      });
      return acc;
    }, []);

    const prototypesSteps = steps.filter((s) => s.fromPrototypeStep != null);

    return (
      <div className="report" ref={reportRef}>
        {steps
          .filter((s) => s.fromPrototypeStep == null)
          .map((step, index) => {
            const nestedSteps = prototypesSteps.filter(
              (ps) => ps.fromPrototypeStep.uid === step.uid
            );

            return (
              <SurveyStep
                key={step.uid}
                className={classnames({
                  'has-nested-surveys': nestedSteps.length > 0,
                })}
                index={index}
                step={step}
                surveys={filteredSurveys}
                filters={filters}
                setFilters={setFilters}>
                {nestedSteps.length > 0
                  ? nestedSteps.map((ns, nsIndex) => (
                      <SurveyStep
                        key={ns.uid}
                        step={ns}
                        index={nsIndex}
                        surveys={filteredSurveys}
                        filters={filters}
                        setFilters={setFilters}
                        isNested
                        parentStepIndex={index}
                      />
                    ))
                  : null}
              </SurveyStep>
            );
          })}
        {Object.keys(filters).length > 0 && (
          <FloatingReportFilters
            filters={filters}
            resetFilters={() => setFilters({})}
            steps={steps}
          />
        )}
      </div>
    );
  }
};

const propTypes = {
  evolution: object.isRequired,
};

const defaultProps = {};

Report.propTypes = propTypes;
Report.defaultProps = defaultProps;

export default Report;
