import React from 'react';
import './_Styles.scss';
import TrackerBlock from '../TrackerBlock';
import CompletionRateChart from './components/CompletionRateChart';

const propTypes = {};

const defaultProps = {};

const CompletionRate = () => {
  return (
    <TrackerBlock
      className="tracker-milestone-overview"
      header={
        <>
          <div className="title">
            <i className="isax isax-chart-21" />
            Completion rate
          </div>
        </>
      }>
      <div className="tracker-steps-activity-wrapper">
        <CompletionRateChart />
      </div>
    </TrackerBlock>
  );
};

CompletionRate.propTypes = propTypes;
CompletionRate.defaultProps = defaultProps;

export default React.memo(CompletionRate);
