import Card from 'components/Card';
import React from 'react';
import './_Styles.scss';

export default function DevCard() {
  return (
    <div className="_dev-card">
      <div className="wrapper">
        <Card>
          <h2>A simple card!</h2>
          <br />
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Praesentium
          unde minima laudantium id voluptatem, libero ullam temporibus
          perspiciatis? Quibusdam a vel distinctio harum laborum facere dolorum!
          Facere natus perferendis fugit.
        </Card>
        <Card interactive>
          <h2>An interactive card!</h2>
          <br />
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. At, ducimus
          alias soluta nihil explicabo veniam, atque sit ab necessitatibus
          obcaecati distinctio rerum exercitationem culpa odio. Doloribus,
          facilis? Quisquam, rerum ea?
        </Card>
        <Card interactive active>
          <h2>An interactive and active card!</h2>
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque hic eos
          nemo provident dolorem architecto aliquid, fugit vero, maxime, atque a
          impedit. Quos, laboriosam accusantium? Est mollitia et quisquam nobis.
        </Card>
      </div>
    </div>
  );
}
