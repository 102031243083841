import {dataActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import {default as DefaultLoader, default as Loader} from 'components/Loader';
import {ModalConfirm} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {PermissionsPeople} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {array, bool, func} from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_USERS_SEGMENT_WITH_ID} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {segmentService} from 'services';
import {SEGMENT_ICONS} from 'services/segment';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('Users/ListSegments');

const propTypes = {
  segments: array,
  isLoading: bool,
  refetchSegments: func,
};

const defaultProps = {
  segments: [],
  isLoading: false,
  refetchSegments: () => {},
};

const ListSegments = ({segments, isLoading, refetchSegments}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const setSegments = (segments) => dispatch(dataActions.setSegments(segments));

  const [deleting, setDeleting] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);

  const handleDelete = async (segmentId) => {
    setDeleting(segmentId);
    try {
      await segmentService.deleteSegment(segmentId);

      setSegments(segments.filter((s) => s.uid !== segmentId));
      setShowDeleteConfirm(null);
      await refetchSegments();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting segment failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setDeleting(null);
    }
  };

  const classNames = classnames('users-list-segments', {
    'is-loading': isLoading,
  });

  const canDeleteSegment = hasPermissions(PermissionsPeople.SEGMENT_DELETE);

  if (isLoading) {
    return (
      <div className={classNames}>
        <DefaultLoader />
      </div>
    );
  }

  return (
    <>
      <div className={classNames}>
        <div className="top">
          <div></div>
          <div>Name</div>
          <div>Audience</div>
          {/* <div>Total live pokes</div> */}
          <div>Created</div>
        </div>
        {isLoading === true && (
          <div className="loader-wrapper">
            <Loader width={24}></Loader>
          </div>
        )}
        {isLoading === false && (
          <div className="list">
            {segments
              .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
              .map((segment) => {
                return (
                  <div
                    key={segment.uid}
                    className="card-segment"
                    onClick={() => {
                      history.push(ROUTE_USERS_SEGMENT_WITH_ID(segment.uid));
                    }}>
                    <div className="segment-icon-wrapper">
                      {!segment.icon ||
                      SEGMENT_ICONS.map((i) => i.value).includes(
                        segment.icon
                      ) ? null : (
                        <div className="segment-icon">{segment.icon}</div>
                      )}
                    </div>
                    <div className="segment-name">
                      <span className="name">{segment.name}</span>
                      <span className="description">{segment.description}</span>
                    </div>
                    <div className="segment-audience">
                      {segment.loading === true ? (
                        <Tooltip
                          trigger={
                            <div className="segment-populating body-3">
                              <DefaultLoader width="8px" />
                              Populating
                            </div>
                          }>
                          Depending on the number of jimers found, <br /> this
                          take can a few minutes to finish.
                        </Tooltip>
                      ) : (
                        <span>{segment.size}</span>
                      )}
                    </div>
                    {/* <div className="segment-live-pushes">
                    <span>3</span>
                  </div> */}
                    <div className="segment-last-edited">
                      {dayjs(segment.createdAt).fromNow()}
                    </div>
                    {canDeleteSegment === true && (
                      <div className="segment-actions">
                        <Button
                          iconOnly
                          thin
                          rounded={false}
                          danger
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowDeleteConfirm(segment.uid);
                          }}
                          loading={deleting === segment.uid}
                          disabled={
                            deleting != null && deleting !== segment.uid
                          }>
                          <i className="icon-trash"></i>{' '}
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <ModalConfirm
        isOpen={showDeleteConfirm != null}
        isConfirming={deleting === true}
        onConfirm={() => {
          handleDelete(showDeleteConfirm);
        }}
        onCancel={() => {
          setShowDeleteConfirm(null);
        }}></ModalConfirm>
    </>
  );
};

ListSegments.propTypes = propTypes;
ListSegments.defaultProps = defaultProps;

export default ListSegments;
