import dayjs from 'dayjs';
import {object} from 'prop-types';
import React, {useState} from 'react';
import {TrackerPreviewTooltip} from '../PreviewStackedLineChart';
import './_Styles.scss';

const propTypes = {
  tracker: object.isRequired,
};

const defaultProps = {};

const TrackerPreviewActivation = ({tracker}) => {
  const {steps} = tracker;

  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  const startDate = dayjs().subtract(7, 'day');

  steps.sort((a, b) => a.indexOrder - b.indexOrder);
  steps.forEach((step) => {
    step.currentWeekCount = step.analyticsDaily.reduce((acc, cur) => {
      if (dayjs(cur.createdAt).isAfter(startDate) !== true) {
        return acc;
      }
      acc += parseInt(cur.count, 10);
      return acc;
    }, 0);
    step.lastWeekCount = step.analyticsDaily.reduce((acc, cur) => {
      if (dayjs(cur.createdAt).isAfter(startDate)) {
        return acc;
      }
      acc += parseInt(cur.count, 10);
      return acc;
    }, 0);
  });

  const firstStep = steps[0];
  const lastStep = steps[steps.length - 1];

  const currentFirstStepCount = firstStep?.currentWeekCount || 0;
  const currentLastStepCount = lastStep?.currentWeekCount || 0;
  const lastWeekFirstStepCount = firstStep?.lastWeekCount || 0;
  const lastWeekLastStepCount = lastStep?.lastWeekCount || 0;

  const currentConversionRate =
    currentFirstStepCount > 0
      ? Math.round((currentLastStepCount * 100) / currentFirstStepCount)
      : 0;
  const lastWeekConversionRate =
    lastWeekFirstStepCount > 0
      ? Math.round((lastWeekLastStepCount * 100) / lastWeekFirstStepCount)
      : 0;

  const denominator = Math.max(
    ...steps.map((step) => step?.currentWeekCount || 0)
  );

  return (
    <>
      {tooltipPosition != null && tooltipData != null && (
        <div
          style={{
            zIndex: '1',
            position: 'fixed',
            left: tooltipPosition.x + 10,
            top: tooltipPosition.y,
          }}>
          <TrackerPreviewTooltip
            title={`${parseInt(tooltipData.percentage, 10)}% converted to ${
              tooltipData.step.name
            }`}
            content={
              <>
                <div
                  className="color-indication"
                  style={{
                    background: '#1260eb',
                    opacity: 0.6,
                  }}
                />
                <div className="data">
                  <div className="group-value">
                    {tooltipData?.count} user{tooltipData?.count > 1 ? 's' : ''}
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}
      <div className="tracker-info">
        <div className="tracker-name">{tracker?.name || 'Tracker Name'}</div>
        <div className="tracker-metric">
          {currentConversionRate}%
          {lastWeekConversionRate > 0 || currentConversionRate > 0 ? (
            <>
              {currentConversionRate >= lastWeekConversionRate ? (
                <span className="tracker-metric-subtext body-4 g-400">
                  <i className="isax isax-arrow-up-15" />+
                  {Math.round(currentConversionRate - lastWeekConversionRate)}%
                  vs last week
                </span>
              ) : (
                <span className="tracker-metric-subtext body-4 r-300">
                  <i className="isax isax-arrow-down-5" />-
                  {Math.round(lastWeekConversionRate - currentConversionRate)}%
                  vs last week
                </span>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="tracker-preview">
        {steps.map((step, index) => {
          const conversionBarWidth =
            denominator > 0 ? (step?.currentWeekCount / denominator) * 100 : 0;

          return (
            <div className="tracker-preview-step" key={index}>
              <div className="tracker-preview-step-name body-2">
                <div className="index-wrapper">{index + 1}</div>{' '}
                <span>{step.name}</span>
              </div>
              <div
                className="tracker-preview-step-conversion"
                onMouseMove={(e) => {
                  setTooltipPosition({x: e.clientX, y: e.clientY});
                }}
                onMouseEnter={(e) => {
                  setTooltipData({
                    step: step,
                    percentage: conversionBarWidth,
                    count: step.currentWeekCount,
                  });
                }}
                onMouseLeave={() => {
                  setTooltipPosition(null);
                  setTooltipData(null);
                }}>
                <div
                  className="tracker-preview-step-conversion-bar"
                  style={{width: `${conversionBarWidth}%`}}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

TrackerPreviewActivation.propTypes = propTypes;
TrackerPreviewActivation.defaultProps = defaultProps;

export default TrackerPreviewActivation;
