import Select from 'components/Select';
import React from 'react';
import './_Styles.scss';

const options1 = [
  {value: 'foo', label: 'Foo'},
  {value: 'bar', label: 'Bar'},
];

export default class DevSelect extends React.Component {
  constructor() {
    super();
    this.state = {
      selectValue1: null,
    };
  }

  handleSelectChange = (attr, value) => this.setState({[attr]: value});

  render() {
    const {selectValue1} = this.state;

    return (
      <div className="_dev-select">
        <Select
          options={options1}
          value={selectValue1}
          onChange={(value) =>
            this.handleSelectChange('selectValue1', value)
          }></Select>
      </div>
    );
  }
}
