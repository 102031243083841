// Types
export const DATA_SET_REQUESTS = 'DATA_SET_REQUESTS';
export const DATA_UPT_REQUEST = 'DATA_UPT_REQUEST';
export const DATA_SET_SUBSCRIPTION_PLANS = 'DATA_SET_SUBSCRIPTION_PLANS';
export const DATA_SET_SUBSCRIPTION_ADDONS = 'DATA_SET_SUBSCRIPTION_ADDONS';
export const DATA_SET_SEGMENTS = 'DATA_SET_SEGMENTS';
export const DATA_SET_CUSTOM_ATTRIBUTES = 'DATA_SET_CUSTOM_ATTRIBUTES';
export const DATA_SET_CUSTOM_ROLES = 'DATA_SET_CUSTOM_ROLES';
export const DATA_SET_BUILT_IN_ROLES = 'DATA_SET_BUILT_IN_ROLES';
export const DATA_UPT_SEGMENT = 'DATA_UPT_SEGMENT';

// Methods
export const setRequests = (requests) => ({
  type: DATA_SET_REQUESTS,
  requests,
});
export const setSubscriptionPlans = (plans) => ({
  type: DATA_SET_SUBSCRIPTION_PLANS,
  plans,
});
export const setSubscriptionAddons = (addons) => ({
  type: DATA_SET_SUBSCRIPTION_ADDONS,
  addons,
});
export const uptRequest = (requestId, data) => ({
  type: DATA_UPT_REQUEST,
  requestId,
  data,
});
export const setSegments = (segments) => ({
  type: DATA_SET_SEGMENTS,
  segments,
});
export const setCustomAttributes = (attributes) => ({
  type: DATA_SET_CUSTOM_ATTRIBUTES,
  attributes,
});
export const setCustomRoles = (roles) => ({
  type: DATA_SET_CUSTOM_ROLES,
  roles,
});
export const setBuiltInRoles = (roles) => ({
  type: DATA_SET_BUILT_IN_ROLES,
  roles,
});
export const uptSegment = (segmentId, data) => ({
  type: DATA_UPT_SEGMENT,
  segmentId,
  data,
});
