import React from 'react';
import {string, node, any} from 'prop-types';
import './_Styles.scss';
import classNames from 'classnames';
import Divider from 'components/Divider';

const propTypes = {
  header: any,
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  header: null,
  className: '',
};

const TrackerBlock = ({className, header, children}) => {
  return (
    <div className={classNames('tracker-block', className)}>
      {header != null && (
        <>
          <div className="block-header">{header}</div>
          <Divider />
        </>
      )}
      <div className="block-content">{children}</div>
    </div>
  );
};

TrackerBlock.propTypes = propTypes;
TrackerBlock.defaultProps = defaultProps;
export default TrackerBlock;
