/**
 * Send a message to the Undercity parent
 */
export function sendUndercityMessage(message) {
  sendMessage({
    ...message,
    recipient: 'undercity',
  });
}

export function sendStormwindMessage(message) {
  sendMessage({
    ...message,
    recipient: 'stormwind',
  });
}

const sendMessage = (message) => {
  window.top.postMessage({...message, fromJimo: true}, '*');
};

/**
 * Data sent in each communication will be structured as a Message
 *
 * @class Message
 */
export class Message {
  constructor(action, data) {
    this.action = action;
    this.data = data;
  }
}
