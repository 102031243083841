import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {bool, func, object, string} from 'prop-types';
import {useEffect, useState} from 'react';
import isURL from 'validator/lib/isURL';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  onEdit: func.isRequired,
  poke: object,
  currentUrl: string,
};

const defaultProps = {
  poke: null,
  currentUrl: '',
};

const ModalEditUrl = ({
  isOpen,
  onRequestClose,
  onEdit,
  poke,
  currentUrl,
  ...rest
}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(poke.boostedActiveUrl || '');
  }, [poke]);

  const closeModal = () => {
    setUrl('');
    onRequestClose();
  };

  const handleEdit = () => {
    onEdit(url);
  };

  return (
    <Modal
      className="modal-edit-poke-url"
      title={'Edit experience'}
      isOpen={isOpen}
      {...rest}
      onRequestClose={closeModal}>
      <div className="fade-in-right">
        <div className="inputs-wrapper">
          <div className="warning-label">
            <i className="icon-exclamation-triangle-o" />
            Current URL does not match the build URL
          </div>
          <div className="section-wrapper">
            <div className="section-title">Poke name</div>
            <Input
              autoComplete="off"
              disabled
              name="pokeName"
              value={poke.title}
            />
          </div>
          <div className="section-wrapper">
            <div className="section-title">Build URL</div>
            <div className="url-input-wrapper">
              <Input
                autoComplete="off"
                placeholder="https://"
                name="targetUrl"
                value={url}
                onChange={({target}) => setUrl(target.value || '')}
              />
              <div
                className="switch-url-btn"
                onClick={() => setUrl(currentUrl)}>
                Switch to current url
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <Button muted className="cancel-btn" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            primary
            onClick={handleEdit}
            disabled={isURL(url || '') !== true}>
            {currentUrl !== url ? 'Navigate' : 'Edit'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalEditUrl.propTypes = propTypes;
ModalEditUrl.defaultProps = defaultProps;

export default ModalEditUrl;
