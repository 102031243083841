import AcrossTabs from 'across-tabs';
import {default as classNames, default as classnames} from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess, toastWarning} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {bool, func, string} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {buildUrlService, evolutionService} from 'services';
import {EVOLUTION_CONTEXT_PORTAL} from 'services/evolution';
import {Swaler} from 'swaler';
import './_styles.scss';

const propTypes = {
  querySelector: string,
  onQuerySelectorChange: func,
  onSelectElement: func,
  large: bool,
};

const defaultProps = {
  querySelector: null,
  onQuerySelectorChange: () => {},
  onSelectElement: null,
  large: false,
};

const logger = new Swaler('ElementPicker');

export const ElementPickerModal = ({
  isOpen,
  onRequestClose,
  evolution,
  stepId,
  setEvolution,
  onQuerySelectorChange,
}) => {
  const [targetUrl, setTargetUrl] = useState('');
  const [urlInputFocused, setUrlInputFocused] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const targetUrlRef = useRef();
  const refParent = useRef();
  const childTabId = useRef();
  const stepIdRef = useRef();

  const {data: buildUrls} = useQuery({
    queryKey: 'build-urls',
    queryFn: buildUrlService.getBuildUrls,
  });

  useEffect(() => {
    const config = {
      onHandshakeCallback: () => {
        refParent.current?.broadCastTo(childTabId.current, {
          type: 'SET_MODE',
          data: {
            mode: 'TARGET_ELEMENT',
            pokeId: evolution.uid,
            stepId: stepIdRef.current,
          },
        });
      },
      onChildCommunication: (message) => {
        if (message?.type === 'TARGET_ELEMENT_VALIDATED') {
          if (
            evolution.context === EVOLUTION_CONTEXT_PORTAL ||
            setEvolution == null
          ) {
            onQuerySelectorChange(message?.evolution?.boostedQueryselector);
            refParent?.current.closeAllTabs();
            onRequestClose();
          } else {
            setEvolution(message.evolution);
            refParent?.current.closeAllTabs();
            onRequestClose();
          }
        }
      },
    };

    const parent = new AcrossTabs.Parent(config);
    refParent.current = parent;
  }, []);

  useEffect(() => {
    targetUrlRef.current = targetUrl;
  }, [targetUrl]);

  useEffect(() => {
    stepIdRef.current = stepId;
  }, [stepId]);

  useEffect(() => {
    if (isInvalidUrl === true) {
      try {
        new URL(targetUrl);
        setIsInvalidUrl(false);
      } catch (err) {}
    }
  }, [targetUrl]);

  const savePoke = async () => {
    setIsSaving(true);
    let success = false;
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
      toastSuccess('Changes saved!', {toastId: 'changes-saved'});
      success = true;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }

    return success;
  };

  const handleSubmit = async () => {
    if (targetUrl?.length > 0) {
      try {
        new URL(targetUrl);
      } catch (err) {
        setIsInvalidUrl(true);
        return;
      }
    }

    if (targetUrl) {
      const success = await savePoke();
      if (success === true) {
        const childTab = refParent?.current.openNewTab({
          url: targetUrl,
          windowName: 'AcrossTab',
          // windowFeatures:
          //   'toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no',
        });
        childTabId.current = childTab.id;
      }
    } else {
      toastWarning(['Invalid target url!']);
    }
  };

  return (
    <Modal
      className="target-url-modal"
      title="Target URL"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="target-url-modal-overlay"
      footer={
        <>
          <Button muted className="back-btn" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            primary
            onClick={handleSubmit}
            disabled={!targetUrl}
            loading={isSaving}>
            Enter builder
          </Button>
        </>
      }>
      <div className="modal-content">
        <div className="poke-url-wrapper">
          <div className="input-group-wrapper">
            <Input
              legend="URL"
              autoComplete="off"
              className={classnames('poke-url', {
                'is-invalid': isInvalidUrl,
              })}
              placeholder="https://"
              name="targetUrl"
              value={targetUrl}
              iconRight={
                isInvalidUrl ? 'icon-exclamation-circle' : 'icon-expand'
              }
              onChange={({target}) => setTargetUrl(target.value)}
              onFocus={() => setUrlInputFocused(true)}
            />
            {isInvalidUrl && (
              <div className="body-4 r-400">
                Please enter a valid URL, including the protocol (e.g.,
                “https://”).
              </div>
            )}
          </div>
          {buildUrls?.length > 0 && (
            <Dropdown
              className={classNames('dropdown-build-url-suggestions', {
                'not-focused': urlInputFocused !== true,
              })}
              open={urlInputFocused === true}
              onClose={() => setUrlInputFocused(false)}
              offsetX={5}
              offsetY={-8}>
              {buildUrls
                ?.filter((e) => !!e.url)
                ?.map((e) => {
                  return (
                    <>
                      <div
                        className="url-suggestion"
                        onClick={() => {
                          setTargetUrl(e.url);
                          setUrlInputFocused(false);
                        }}>
                        {e.url}
                      </div>
                    </>
                  );
                })}
            </Dropdown>
          )}
        </div>
      </div>
    </Modal>
  );
};

const ElementPicker = ({
  pokeToPreview,
  querySelector,
  onQuerySelectorChange,
  onSelectElement,
  large,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [showModalUrlTarget, setShowModalUrlTarget] = useState(false);
  const [isEditQuerySelectorMode, setIsEditQuerySelectorMode] = useState(false);
  const [previousQuerySelector, setPreviousQuerySelector] = useState(null);

  const actionsWrapperRef = useRef();

  const handleSelectClick = () => {
    if (project.snippetInstalledAt != null) {
      if (onSelectElement !== null) {
        onSelectElement();
      } else {
        setShowModalUrlTarget(true);
      }
    } else {
      toastWarning(
        "You can't pin your boosted to a target element if the snippet has not been installed."
      );
    }
  };

  return (
    <div className="element-picker">
      {querySelector == null ? (
        <Button
          className={classnames('btn-select-query-selector', {
            'has-target': querySelector != null,
          })}
          iconLeft="icon-target"
          onClick={handleSelectClick}>
          Target an in-app element
        </Button>
      ) : (
        <div className="input-query-selector-wrapper">
          <Input
            className={classnames('input-change-query-selector', {
              'edit-mode': isEditQuerySelectorMode === true,
            })}
            value={querySelector}
            disabled={isEditQuerySelectorMode !== true}
            onChange={({target}) => onQuerySelectorChange(target.value)}
            style={{
              ...(large === true
                ? {paddingRight: actionsWrapperRef.current?.offsetWidth + 16}
                : {}),
            }}
          />
          <div
            className={classnames('actions-wrapper', {
              'is-large': large === true,
            })}
            ref={actionsWrapperRef}>
            {isEditQuerySelectorMode === true ? (
              <>
                <Button
                  className="cancel-btn"
                  iconLeft="icon-close"
                  onClick={() => {
                    onQuerySelectorChange(previousQuerySelector);
                    setIsEditQuerySelectorMode(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  className="validate-btn"
                  iconLeft="icon-tick"
                  onClick={() => setIsEditQuerySelectorMode(false)}>
                  Validate
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="edit-btn"
                  iconLeft="icon-edit-outline"
                  onClick={() => {
                    setPreviousQuerySelector(querySelector);
                    setIsEditQuerySelectorMode(true);
                  }}>
                  Edit
                </Button>
                <Button
                  className="reselect-btn"
                  iconLeft="icon-target"
                  onClick={handleSelectClick}>
                  Reselect
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {showModalUrlTarget && (
        <ElementPickerModal
          evolution={pokeToPreview}
          isOpen={showModalUrlTarget}
          onRequestClose={() => setShowModalUrlTarget(false)}
          onQuerySelectorChange={onQuerySelectorChange}
        />
      )}
    </div>
  );
};

ElementPicker.propTypes = propTypes;
ElementPicker.defaultProps = defaultProps;

export default ElementPicker;
