import classNames from 'classnames';
import Alert from 'components/Alert';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import SegmentAttributesEditor from 'components/SegmentAttributesEditor';
import {
  buildLogic,
  isSegmentationValid,
} from 'components/SegmentAttributesEditor/utils';
import {errorHelpers} from 'helpers';
import {bool, func} from 'prop-types';
import {useContext, useEffect, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useQuery} from 'react-query';
import {TrackerBuilderContext} from 'scenes/TrackerBuilder';
import {jimerService, segmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const defaultProps = {};

const JIMERS_PER_PAGE = 1;

const logger = new Swaler('TrackerAudience');

const TrackerAudience = () => {
  const {tracker, setTracker, setIsEditingAudience} = useContext(
    TrackerBuilderContext
  );

  const [page, setPage] = useState(0);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [lookupData, setLookupData] = useState(
    tracker?.onTheFlySegment != null
      ? {
          attributes: tracker?.onTheFlySegment?.attributes,
          logic: tracker?.onTheFlySegment?.logic,
        }
      : null
  );
  const [isOpen, setIsOpen] = useState(false);

  const {data: segments = []} = useQuery({
    queryKey: 'segments',
    queryFn: segmentService.getSegments,
  });

  const fetchJimers = async (pageParam = page) => {
    setIsLoading(true);

    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await jimerService.getJimers(
        {
          ...(lookupData?.attributes?.length > 0 && lookupData?.logic != null
            ? {attributes: lookupData.attributes, logic: lookupData.logic}
            : {segmentIds: tracker?.segments?.map((s) => s.uid)}),
          take: JIMERS_PER_PAGE,
          skip: 0,
        },
        {}
      );

      setJimers((prev) => ({
        ...res,
        data: [...prev.data, ...res.data],
      }));
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching values failed with error ', code);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setJimers({
      data: [],
      skip: 0,
      take: JIMERS_PER_PAGE,
      total: 0,
    });
    fetchJimers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracker?.segments, lookupData?.attributes, lookupData?.logic]);

  const onTheFlySegment = tracker?.onTheFlySegment ?? {
    temporary: true,
    attributes: [],
    logic: buildLogic(),
  };

  const handleDeleteOnTheFlySegment = async () => {
    if (onTheFlySegment.uid != null) {
      await segmentService.deleteSegment(onTheFlySegment.uid);
    }
    setLookupData(null);
    setTracker({...tracker, onTheFlySegment: null});
  };

  const handleApply = () => {
    setLookupData({
      attributes: JSON.parse(JSON.stringify(onTheFlySegment?.attributes)),
      logic: JSON.parse(JSON.stringify(onTheFlySegment?.logic)),
    });
  };

  const hasChanges = () =>
    onTheFlySegment?.attributes?.length > 0 &&
    (JSON.stringify(lookupData?.attributes) !==
      JSON.stringify(onTheFlySegment?.attributes) ||
      JSON.stringify(lookupData?.logic) !==
        JSON.stringify(onTheFlySegment?.logic));

  const isSaveDisabled = hasChanges() === true;

  useEffect(() => {
    setIsEditingAudience(isSaveDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveDisabled]);

  const {isValid} = isSegmentationValid(
    onTheFlySegment?.attributes,
    onTheFlySegment?.logic
  );

  return (
    <div className={classNames('tracker-audience', {'is-open': isOpen})}>
      <div
        className="tracker-audience-header"
        onClick={() => setIsOpen(!isOpen)}>
        <div className="left-side">
          <div className="icon-wrapper">
            <i className="icon-users" />
          </div>
          <div className="title-wrapper">
            <div className="title">Tracker audience</div>
            <div className="optional-tag">Optional</div>
          </div>
        </div>
        <div className="right-side">
          <div className="users-count">
            {tracker?.segments?.length > 0 ||
            onTheFlySegment?.attributes?.length > 0 ? (
              <>
                {jimers.total} user{jimers.total > 1 ? 's' : ''}
              </>
            ) : (
              <>
                All users ({jimers.total} user{jimers.total > 1 ? 's' : ''})
              </>
            )}
          </div>
          <i className="icon-chevron-bottom" />
        </div>
      </div>

      <AnimateHeight
        className="collapsable-content-wrapper"
        height={isOpen ? 'auto' : 0}>
        <div className="content-wrapper">
          <div className="section-wrapper">
            <div className="section-title">Select an existing segment</div>
            {onTheFlySegment?.attributes?.length > 0 && (
              <Alert warning>
                If you want to use existing segments, you have to remove all
                attributes used in your segment on the fly.
              </Alert>
            )}

            <div className="list-segment">
              <Button
                className={classNames('item-segment', {
                  selected:
                    tracker.segments?.length === 0 &&
                    onTheFlySegment?.attributes?.length === 0,
                })}
                rounded={false}
                iconLeft="icon-duplicate"
                disabled={onTheFlySegment?.attributes?.length > 0}
                onClick={() => {
                  setTracker({
                    ...tracker,
                    segments: [],
                  });
                }}>
                All users
              </Button>
              {segments?.map((s) => {
                const isSelected = tracker.segments
                  ?.map((es) => es.uid)
                  .includes(s.uid);

                return (
                  <Button
                    key={s.uid}
                    className={classNames('item-segment', {
                      selected: isSelected === true,
                    })}
                    rounded={false}
                    iconLeft="icon-duplicate"
                    disabled={onTheFlySegment?.attributes?.length > 0}
                    onClick={() => {
                      setTracker({
                        ...tracker,
                        segments:
                          isSelected === true
                            ? tracker.segments.filter(
                                (seg) => s.uid !== seg.uid
                              )
                            : [...tracker.segments, s],
                      });
                    }}>
                    {s.name}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="section-wrapper">
            <div className="section-title">Or create one on the fly</div>
            <SegmentAttributesEditor
              dropdownProps={{
                offsetY: 6,
                className: 'in-builder',
                segmentOnTheFlyDisabled: tracker.segments?.length > 0,
              }}
              hideDefaultActions={true}
              onSelectExistingSegment={(s) =>
                setTracker({
                  ...tracker,
                  segments: tracker.segments.concat(s),
                })
              }
              onRemoveOnTheFly={
                onTheFlySegment?.attributes?.length > 0
                  ? handleDeleteOnTheFlySegment
                  : null
              }
              attributes={onTheFlySegment?.attributes}
              setAttributes={(attributes) => {
                setTracker((tracker) => ({
                  ...tracker,
                  onTheFlySegment:
                    attributes?.length === 0
                      ? null
                      : {
                          ...tracker.onTheFlySegment,
                          attributes,
                        },
                }));
                if (attributes?.length === 0) {
                  setLookupData(null);
                }
              }}
              logic={onTheFlySegment?.logic}
              setLogic={(logic) => {
                setTracker((tracker) => ({
                  ...tracker,
                  onTheFlySegment:
                    logic == null
                      ? null
                      : {
                          ...tracker.onTheFlySegment,
                          logic,
                        },
                }));
              }}
            />
          </div>
          <div
            className={classNames('selected-users-wrapper', {
              'requires-lookup': hasChanges(),
            })}>
            {isLoading === true ? (
              <div className="loader-wrapper">
                <DefaultLoader width={24} />
              </div>
            ) : (
              <>
                <div className="selected-users">
                  <div className="avatar-wrapper">
                    {
                      // display as many avatars as jimers total or 4 if superior
                      [...Array(Math.min(jimers.total, 4))].map((_, i) => (
                        <Avatar key={i} jimer={{uid: `${i}`}} />
                      ))
                    }
                  </div>
                  <div className="selected-users-count">
                    {tracker?.segments?.length > 0 ||
                    onTheFlySegment?.attributes?.length > 0 ? (
                      <>
                        {jimers.total} user{jimers.total > 1 ? 's' : ''}{' '}
                        selected
                      </>
                    ) : (
                      <>
                        All users ({jimers.total} user
                        {jimers.total > 1 ? 's' : ''})
                      </>
                    )}
                  </div>
                </div>
                <div className="selected-users-actions">
                  <div
                    className="cancel-btn"
                    onClick={() => {
                      setTracker((tracker) => ({
                        ...tracker,
                        onTheFlySegment: {
                          ...tracker.onTheFlySegment,
                          attributes: lookupData?.attributes,
                          logic: lookupData?.logic,
                        },
                      }));
                    }}>
                    Cancel
                  </div>
                  <div
                    className={classNames('apply-btn', {
                      disabled: !isValid,
                    })}
                    onClick={() => {
                      if (isValid) {
                        handleApply();
                      }
                    }}>
                    Apply
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

TrackerAudience.propTypes = propTypes;
TrackerAudience.defaultProps = defaultProps;

export default TrackerAudience;
