import {EVENT_DELETE_EVOLUTION} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Modal, ModalConfirm} from 'components/Modal';
import ModalTagManager from 'components/ModalTagManager';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {PermissionsPost} from 'constants/permissions';
import {errorHelpers} from 'helpers';
import {addFlag, removeFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {array, func} from 'prop-types';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {ROUTE_FEED_EVOLUTION} from 'router/routes.const';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import {evolutionService, segmentService, tagService} from 'services';
import {
  EVOLUTION_STATE_DRAFT,
  EVOLUTION_STATE_EXPIRED,
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_SCHEDULED,
  F_OPTION_BUILDER_PRIORITIZE_IN_APP,
  F_OPTION_PORTAL_DISPLAY_FEED,
  F_OPTION_PORTAL_DISPLAY_FEEDBACK,
  F_OPTION_PORTAL_DISPLAY_ROADMAP,
  F_OPTION_SHOW_ON_PORTAL,
} from 'services/evolution';
import {TAG_CONTEXT_EVOLUTION} from 'services/tag';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Evolution from './components/Evolution';

const logger = new Swaler('EvolutionList');

const propTypes = {
  evolutions: array.isRequired,
  setSelectedEvolution: func.isRequired,
};

const EvolutionList = ({evolutions, onDelete, view, setView, isLoading}) => {
  const history = useHistory();

  const [showModalTagManager, setShowModalTagManager] = useState(false);
  const [evolutionToDelete, setEvolutionToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const {data: segments = []} = useQuery({
    queryKey: ['segments'],
    queryFn: () => segmentService.getSegments(),
    refetchOnWindowFocus: false,
  });

  // Fetch tags
  const {data: tags = []} = useQuery({
    queryKey: ['tags', 'evolutionContext'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_EVOLUTION]}),
    refetchOnWindowFocus: false,
  });

  const handleShowHideModalTagManager = (
    showModalTagManagerParam = !showModalTagManager
  ) => {
    return setShowModalTagManager(showModalTagManagerParam);
  };

  const handleRemoveFromPortal = async () => {
    setIsDeleting(true);
    try {
      await evolutionService.updateEvolution(evolutionToDelete.uid, {
        optionsFlags: removeFlag(
          [
            F_OPTION_SHOW_ON_PORTAL,
            F_OPTION_PORTAL_DISPLAY_FEED,
            F_OPTION_PORTAL_DISPLAY_ROADMAP,
            F_OPTION_PORTAL_DISPLAY_FEEDBACK,
          ],
          addFlag(
            F_OPTION_BUILDER_PRIORITIZE_IN_APP,
            evolutionToDelete.optionsFlags
          )
        ),
      });
      toastSuccess('Experience deleted 👍', {toastId: 'poke-deleted'});
      onDelete();
      setEvolutionToDelete(null);
      setIsDeleting(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting experience failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      setIsDeleting(false);
      setEvolutionToDelete(null);
    }
  };
  const handleDeleteEvolution = async () => {
    setIsDeleting(true);
    try {
      amplitude.getInstance().logEvent(EVENT_DELETE_EVOLUTION, {
        from: 'EvolutionList',
      });
      await evolutionService.deleteEvolution(evolutionToDelete.uid);
      toastSuccess('Experience deleted 👍', {toastId: 'poke-deleted'});
      setEvolutionToDelete(null);
      setIsDeleting(false);
      onDelete();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting evolution failed with error ', code);
      toastDanger([title, message], {
        actions,
      });
      setIsDeleting(false);
      setEvolutionToDelete(null);
    }
  };

  const {states, viewSegments, viewTags} = view;

  const handleUpdateStates = (state) => {
    if (states.includes(state)) {
      setView({
        ...view,
        states: states.filter((s) => s !== state),
      });
    } else {
      setView({
        ...view,
        states: [...states, state],
      });
    }
  };

  const handleUpdateSegments = (segment) => {
    if (viewSegments.findIndex((vs) => vs.uid === segment.uid) > -1) {
      setView({
        ...view,
        viewSegments: viewSegments.filter((vs) => vs.uid !== segment.uid),
      });
    } else {
      setView({
        ...view,
        viewSegments: [...viewSegments, segment],
      });
    }
  };

  const handleUpdateTags = (tag) => {
    if (viewTags.findIndex((vt) => vt.uid === tag.uid) > -1) {
      setView({
        ...view,
        viewTags: viewTags.filter((vt) => vt.uid !== tag.uid),
      });
    } else {
      setView({
        ...view,
        viewTags: [...viewTags, tag],
      });
    }
  };

  const canCreateAnnouncement = hasPermissions(PermissionsPost.CREATE_EDIT);

  return (
    <div className="evolution-list">
      <div className={classnames('scroll-wrapper')}>
        <div className="evolution-list-header">
          <div className="filter-wrapper">
            <Dropdown
              className="view-setting view-status"
              position="bottom left"
              triggerClassName={classnames({
                'is-set': states != null && states?.length > 0,
              })}
              repositionOnResize={false}
              trigger={
                <Button
                  thin
                  iconLeft="icon-tick-round-o"
                  iconRight="icon-chevron-bottom">
                  {states == null || states?.length === 0
                    ? 'Status'
                    : states?.length === 1
                    ? `${states[0]?.toLowerCase()}`
                    : `${states[0]?.toLowerCase()} +${states?.length - 1}`}
                </Button>
              }>
              <div className="setting-list">
                <div
                  className={classnames('setting-item', {
                    'is-set': states?.includes(EVOLUTION_STATE_LIVE),
                  })}
                  onClick={() => handleUpdateStates(EVOLUTION_STATE_LIVE)}>
                  {states?.includes(EVOLUTION_STATE_LIVE) === true ? (
                    <i className="icon-checkbox"></i>
                  ) : (
                    <i className="icon-checkbox-o"></i>
                  )}
                  Live
                </div>
                <div
                  className={classnames('setting-item', {
                    'is-set': states?.includes(EVOLUTION_STATE_SCHEDULED),
                  })}
                  onClick={() => handleUpdateStates(EVOLUTION_STATE_SCHEDULED)}>
                  {states?.includes(EVOLUTION_STATE_SCHEDULED) === true ? (
                    <i className="icon-checkbox"></i>
                  ) : (
                    <i className="icon-checkbox-o"></i>
                  )}
                  Scheduled
                </div>
                <div
                  className={classnames('setting-item', {
                    'is-set': states?.includes(EVOLUTION_STATE_EXPIRED),
                  })}
                  onClick={() => handleUpdateStates(EVOLUTION_STATE_EXPIRED)}>
                  {states?.includes(EVOLUTION_STATE_EXPIRED) === true ? (
                    <i className="icon-checkbox"></i>
                  ) : (
                    <i className="icon-checkbox-o"></i>
                  )}{' '}
                  Expired
                </div>
                <div
                  className={classnames('setting-item', {
                    'is-set': states?.includes(EVOLUTION_STATE_DRAFT),
                  })}
                  onClick={() => handleUpdateStates(EVOLUTION_STATE_DRAFT)}>
                  {states?.includes(EVOLUTION_STATE_DRAFT) === true ? (
                    <i className="icon-checkbox"></i>
                  ) : (
                    <i className="icon-checkbox-o"></i>
                  )}{' '}
                  Draft
                </div>
              </div>
            </Dropdown>
            <Dropdown
              className="view-setting view-status"
              position="bottom left"
              triggerClassName={classnames({
                'is-set': viewSegments != null && viewSegments?.length > 0,
              })}
              repositionOnResize={false}
              trigger={
                <Button
                  thin
                  iconLeft="icon-users"
                  iconRight="icon-chevron-bottom">
                  {viewSegments == null || viewSegments?.length === 0
                    ? 'Segments'
                    : viewSegments?.length === 1
                    ? `${viewSegments[0]?.name?.toLowerCase()}`
                    : `${viewSegments[0]?.name?.toLowerCase()} +${
                        viewSegments?.length - 1
                      }`}
                </Button>
              }>
              <div className="setting-list">
                {segments.length === 0 && (
                  <div className="list-empty-state">No segment created yet</div>
                )}
                {segments.map((s) => {
                  return (
                    <div
                      className={classnames('setting-item', {
                        'is-set':
                          viewSegments?.findIndex((vs) => vs.uid === s.uid) >
                          -1,
                      })}
                      onClick={() => handleUpdateSegments(s)}>
                      {viewSegments?.findIndex((vs) => vs.uid === s.uid) >
                      -1 ? (
                        <i className="icon-checkbox"></i>
                      ) : (
                        <i className="icon-checkbox-o"></i>
                      )}{' '}
                      {s.name}
                    </div>
                  );
                })}
              </div>
            </Dropdown>
            <Dropdown
              className="view-setting view-tags"
              position="bottom left"
              triggerClassName={classnames({
                'is-set': viewTags != null && viewTags?.length > 0,
              })}
              repositionOnResize={false}
              trigger={
                <Button
                  thin
                  iconLeft="icon-pin"
                  iconRight="icon-chevron-bottom">
                  {viewTags == null || viewTags?.length === 0
                    ? 'Tags'
                    : viewTags?.length === 1
                    ? `${viewTags[0]?.name?.toLowerCase()}`
                    : `${viewTags[0]?.name?.toLowerCase()} +${
                        viewTags?.length - 1
                      }`}
                </Button>
              }>
              <div className="setting-list">
                {tags.length === 0 && (
                  <div className="list-empty-state">No tags created yet</div>
                )}
                {tags.map((t) => {
                  return (
                    <div
                      className={classnames('setting-item', {
                        'is-set':
                          viewTags?.findIndex((vt) => vt.uid === t.uid) > -1,
                      })}
                      style={{
                        border: `1px solid ${t.color}`,
                        backgroundColor: `${t.color}20`,
                        color: t.color,
                      }}
                      onClick={() => handleUpdateTags(t)}>
                      {viewTags?.findIndex((vt) => vt.uid === t.uid) > -1 ? (
                        <i className="icon-checkbox"></i>
                      ) : (
                        <i className="icon-checkbox-o"></i>
                      )}{' '}
                      {t.name}
                    </div>
                  );
                })}
              </div>
            </Dropdown>
          </div>
        </div>
        {evolutions.length === 0 && isLoading !== true ? (
          <div className="empty-state-wrapper">
            <EmptyStateBlock
              img={EmptyStateImgs.EmptyResults}
              title="No results found"
              description="Refine your filters to find what you're looking for"
            />
          </div>
        ) : (
          <div
            className={classnames('list-wrapper', {
              'no-padding-top': canCreateAnnouncement !== true,
            })}>
            <div className="list">
              {evolutions
                .sort((a, b) => {
                  return (
                    new Date(b.lastStepChangeAt).getTime() -
                    new Date(a.lastStepChangeAt).getTime()
                  );
                })
                .map((e) => (
                  <Evolution
                    key={e.uid}
                    evolution={e}
                    onClick={() => {
                      if (hasPermissions(PermissionsPost.ANALYTICS)) {
                        history.push(ROUTE_FEED_EVOLUTION(e.uid));
                      }
                    }}
                    onDelete={() => setEvolutionToDelete(e)}
                  />
                ))}
            </div>
          </div>
        )}
      </div>

      <ModalTagManager
        isOpen={showModalTagManager}
        context={TAG_CONTEXT_EVOLUTION}
        enableAdd
        enableEditColorPicker
        enableEditTagPreview
        onRequestClose={() => handleShowHideModalTagManager(false)}
      />
      <Modal
        large
        className="modal-optional-delete"
        isOpen={evolutionToDelete != null && evolutionToDelete.boostFlags > 0}
        onRequestClose={() => setEvolutionToDelete(null)}>
        <div className="content">
          This post is linked to an experience in your app. Do you want to only
          delete the post in the changelog or also the experience associated
          with it ?
        </div>
        <div className="action-btns">
          <Button muted>Cancel</Button>
          <div className="delete-btns">
            <Button primary onClick={handleRemoveFromPortal}>
              Post only
            </Button>
            <Button primary onClick={handleDeleteEvolution}>
              Post and experience
            </Button>
          </div>
        </div>
      </Modal>
      <ModalConfirm
        isOpen={evolutionToDelete != null && evolutionToDelete.boostFlags === 0}
        isConfirming={isDeleting}
        onConfirm={handleDeleteEvolution}
        onCancel={() => setEvolutionToDelete(null)}
        onRequestClose={() => setEvolutionToDelete(null)}
      />
    </div>
  );
};

EvolutionList.propTypes = propTypes;

export default EvolutionList;
