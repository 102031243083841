import classnames from 'classnames';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {requestService} from 'services';
import './_Styles.scss';
import RequestDetails from './components/RequestDetails';
import RequestList from './components/RequestList';

const Requests = ({className}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {
    data: requests,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['requests'],
    queryFn: () =>
      requestService.getRequests({
        relations: ['author', 'linkedEvolution', 'tags'],
      }),
  });

  const classNames = classnames('s-requests', {
    'is-loading': isLoading,
    'has-not-installed': project.hasSnippetInstalled === false,
    'has-empty-state': requests?.length === 0,
  });

  if (isLoading) {
    return (
      <div className={classNames}>
        {' '}
        <DefaultLoader />
      </div>
    );
  }

  return (
    <div className={`${classNames} ${className}`}>
      {requests.length === 0 && (
        <EmptyStateBlock
          img={EmptyStateImgs.EmptyRequests}
          title="No Feedback or Requests Yet"
          description="Once you receive any feedback or requests, they will appear here"
        />
      )}
      {requests.length !== 0 && (
        <div className="main">
          <div className="requests-wrapper">
            <div className="request-list-wrapper">
              <RequestList requests={requests} refetchRequests={refetch} />
            </div>
            <div className="request-details-wrapper">
              <RequestDetails refetchParent={refetch} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Requests;
