import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';

export const usePlanUsage = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  return {
    mau: {
      exceeded: project.mau >= project.thresholdJimers,
      current: project.mau,
      max: project.thresholdJimers,
      percentage: Math.round((project.mau / project.thresholdJimers) * 100),
    },
  };
};
