import classnames from 'classnames';
import {Modal} from 'components/Modal';
import {bool, func} from 'prop-types';
import {useHistory} from 'react-router-dom';
import './_Styles.scss';
import PickTrackerType from './components/PickTrackerType';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import {ROUTE_BUILDER_TRACKER} from 'router/routes.const';

export const MODE_FORM = 'FORM';
export const MODE_INSTALLATION = 'INSTALLATION';
export const MODE_PENDING = 'PENDING';
export const MODE_PICK_TRACKER_TYPE = 'PICK_TRACKER_TYPE';
export const MODE_EDIT = 'EDIT';

export const hasQueryParams = (url) => {
  return url.includes('?');
};

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const defaultProps = {
  onRequestClose: () => {},
};

const ModalCreateTracker = ({isOpen, onRequestClose, ...rest}) => {
  const history = useHistory();

  return (
    <Modal
      className={classnames('modal-create-tracker')}
      title="New tracker"
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <div className={classnames('content-wrapper fade-in-right')}>
        <PickTrackerType
          onTrackerTypeClick={(trackerType) => {
            if (trackerType === TRACKER_TYPE_ACTIVATION_TRACKER) {
              history.push(ROUTE_BUILDER_TRACKER('new-activation-tracker'));
            } else if (trackerType === TRACKER_TYPE_FEATURE_USAGE) {
              history.push(ROUTE_BUILDER_TRACKER('new-feature-usage-tracker'));
            }
          }}
        />
      </div>
    </Modal>
  );
};

ModalCreateTracker.propTypes = propTypes;
ModalCreateTracker.defaultProps = defaultProps;

export default ModalCreateTracker;
