import React from 'react';

import './_Styles.scss';
import Button from 'components/Button';
import ModalCreateTracker from '../ModalCreateTracker';
import {hasPermissions} from 'helpers/permission';
import {
  PermissionsEvent,
  PermissionsSuccessTracker,
} from 'constants/permissions';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_EVENTS_WITH_ID} from 'router/routes.const';

const SuccessTrackerHeader = ({
  defaultTrackerType,
  showCreateModal,
  setShowCreateModal,
}) => {
  const history = useHistory();

  const canCreateTracker = hasPermissions(
    PermissionsSuccessTracker.CREATE_EDIT
  );
  const canCreateEvent = hasPermissions(PermissionsEvent.CREATE_EDIT);

  return (
    <div className="success-tracker-header">
      <div className="title-3">Success Tracker</div>
      <div className="actions-wrapper">
        {canCreateEvent && (
          <Button
            className="new-event-btn"
            iconLeft="icon-plus"
            onClick={() => history.push(ROUTE_EVENTS_WITH_ID('new'))}>
            Add event
          </Button>
        )}
        {canCreateTracker && (
          <div className="btns-wrapper">
            <Button
              reverted
              primary
              className="new-tracker-btn"
              iconLeft="icon-plus"
              onClick={() => {
                setShowCreateModal(true);
              }}>
              New tracker
            </Button>
          </div>
        )}
      </div>

      {showCreateModal === true && (
        <ModalCreateTracker
          isOpen={showCreateModal}
          onRequestClose={() => setShowCreateModal(false)}
          defaultTrackerType={defaultTrackerType}
        />
      )}
    </div>
  );
};

export default SuccessTrackerHeader;
