import classnames from 'classnames';
import DefaultLoader from 'components/Loader';
import {Menu, MenuItem} from 'components/Menu';
import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './_Styles.scss';

const CLASS_INPUT_OPTION_ITEMS = 'option-items';
const CLASS_INPUT_OPTION_ITEM = 'input-option-item';
const CLASS_INPUT_OPTION_EMPTY = 'input-option-empty';

const InputGroup = forwardRef((props, innerRef) => {
  const {
    className,

    legend,
    legendClassName,

    helper,
    additional,
    trailing,

    iconLeft,
    iconRight,
    iconLeftProps = null,
    iconRightProps = null,

    labelTextLeft,
    labelTextRight,

    danger = false,
    warning = false,
    success = false,
    muted = false,
    small = false,
    loading = false,

    onChange = () => {},
    onPressEnter,

    option = false,
    optionItems = [],
    optionValue = null,
    optionPlaceholder = 'Option',
    onOptionSelected = () => {},

    autocomplete = false,
    autocompleteItems = [], // [ { id: string, content: string, value: object } ]
    onAutocompleteSelected = () => {},
    ...rest
  } = props;

  // Option
  const [showOptions, setShowOptions] = useState(false);
  const [mouseOnOptions, setMouseOnOptions] = useState(false);
  const [offsetOptions, setOffsetOptions] = useState(0);
  const [minWidthOptions, setMinWidthOptions] = useState(0);

  // Autocomplete
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [mouseOnAutocomplete, setMouseOnAutocomplete] = useState(false);
  const [offsetAutocomplete, setOffsetAutocomplete] = useState(0);

  const refInput = useRef(null);
  const refOption = useRef(null);

  useEffect(() => {
    if (refInput.current != null) {
      setOffsetAutocomplete(refInput.current.clientHeight);
    }
  }, [refInput.current]);
  useEffect(() => {
    if (refOption.current != null) {
      setOffsetOptions(refOption.current.clientHeight);
      setMinWidthOptions(refOption.current.clientWidth);
    }
  }, [refOption.current]);

  useEffect(() => {
    if (innerRef != null) innerRef.current = refInput.current;
  }, [refInput.current]);

  // Allow us to close options menu if it's open and a click outside is detected
  useEffect(() => {
    if (option === true) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (option === true) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, []);

  const handleClickOutside = (e) => {
    if (
      [
        CLASS_INPUT_OPTION_ITEMS,
        CLASS_INPUT_OPTION_ITEM,
        CLASS_INPUT_OPTION_EMPTY,
      ].some((className) => e.target.className.includes(className)) === false
    ) {
      setShowOptions(false);
    }
  };

  const handleKeyDown = (e) => {
    const {onPressEnter = null} = props;

    if (onPressEnter != null) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        onPressEnter();
      }
    }
  };

  const commonCSSClasses = {
    small,
    disabled: props.disabled,
    danger,
    warning,
    success,
    muted,
  };

  return (
    <div className={classnames('input-group', className, commonCSSClasses)}>
      {/* Legend */}
      {legend != null && (
        <div
          className={classnames(
            'input-legend',
            legendClassName != null ? legendClassName : ' body-3',
            commonCSSClasses
          )}>
          {legend}
        </div>
      )}

      {/* Main */}
      <div className={classnames('input-main-wrapper', commonCSSClasses)}>
        {/* Label left */}
        {labelTextLeft && (
          <div className={classnames('label label-left', commonCSSClasses)}>
            {labelTextLeft}
          </div>
        )}

        {/* Icon left */}
        {iconLeft && (
          <div
            {...(iconLeftProps ?? {})}
            className={classnames('icon-left-wrapper', commonCSSClasses, {
              'is-interactive': iconLeftProps != null,
            })}>
            <i className={iconLeft}></i>
          </div>
        )}

        {/* Input */}
        <input
          ref={refInput}
          className={classnames('input', {
            ...commonCSSClasses,
            'body-2': small === false,
            'body-3': small === true,
          })}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          {...rest}
          onFocus={(e) => {
            if (autocomplete === true) setShowAutocomplete(true);
            if (rest.onFocus != null) rest.onFocus(e);
          }}
          onBlur={(e) => {
            if (autocomplete === true && mouseOnAutocomplete === false)
              setShowAutocomplete(false);
            if (rest.onBlur != null) rest.onBlur(e);
          }}
        />

        {/* Loader */}
        {loading === true && (
          <div className="input-loader">
            <DefaultLoader width={small === true ? 12 : 16} />
          </div>
        )}

        {/* Trailing */}
        {trailing != null && (
          <div
            className={classnames('input-trailing n-700', commonCSSClasses, {
              'body-3': small === false,
              'body-4': small === true,
            })}>
            {trailing}
          </div>
        )}

        {/* Icon right */}
        {iconRight != null && (
          <div
            {...(iconRightProps ?? {})}
            className={classnames('icon-right-wrapper', commonCSSClasses, {
              'is-interactive': iconRightProps != null,
            })}>
            <i className={iconRight}></i>
          </div>
        )}

        {/* Label right */}
        {labelTextRight != null && (
          <div className={classnames('label label-right', commonCSSClasses)}>
            {labelTextRight}
          </div>
        )}

        {/* Additional */}
        {additional != null && (
          <div
            className={classnames('input-additional', commonCSSClasses, {
              'body-2': small === false,
              'body-3': small === true,
            })}>
            <div className="additional-overflow-wrapper">{additional}</div>
          </div>
        )}

        {/* Option */}
        {option === true && (
          <div
            ref={refOption}
            className={classnames('input-option', commonCSSClasses, {
              'body-3': small === false,
              'body-4': small === true,
              'm-l-6': additional == null,
            })}
            onClick={() => {
              if (props.disabled == null || props.disabled === false)
                setShowOptions(!showOptions);
            }}>
            {optionValue != null
              ? optionItems.find((o) => o.value === optionValue)?.content
              : optionPlaceholder}
            <i className="icon-chevron-bottom"></i>
            {showOptions === true && optionItems.length > 0 && (
              <div
                className={classnames('option-items sw-2')}
                onMouseEnter={() => setMouseOnOptions(true)}
                onMouseLeave={() => setMouseOnOptions(false)}
                style={{
                  top: `${offsetOptions + 6}px`,
                  minWidth: `${minWidthOptions}px`,
                }}>
                {optionItems.length > 0 &&
                  optionItems.map((item) => (
                    <div
                      className={classnames('input-option-item body-3', {
                        selected: item.value === optionValue,
                        small,
                      })}
                      item={item.id}
                      onClick={(e) => {
                        onOptionSelected(item.value);
                        setShowOptions(false);
                        e.stopPropagation();
                      }}>
                      {item.content}
                    </div>
                  ))}
                {optionItems.length === 0 && (
                  <div className="input-option-empty" interactive={false}>
                    No results
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Autocomplete */}
        {autocomplete === true &&
          showAutocomplete === true &&
          autocompleteItems.length > 0 && (
            <Menu
              className="autocomplete-results"
              onMouseEnter={() => setMouseOnAutocomplete(true)}
              onMouseLeave={() => setMouseOnAutocomplete(false)}
              style={{top: `${offsetAutocomplete + 6}px`}}>
              {autocompleteItems.length > 0 &&
                autocompleteItems.map((item) => (
                  <MenuItem
                    item={item.id}
                    onClick={() => {
                      onAutocompleteSelected(item.value);
                      setShowAutocomplete(false);
                    }}>
                    {item.content}
                  </MenuItem>
                ))}
              {autocompleteItems.length === 0 && (
                <MenuItem className="no-results" interactive={false}>
                  No results
                </MenuItem>
              )}
            </Menu>
          )}
      </div>

      {/* Helper*/}
      {helper != null && (
        <div className={classnames('input-helper body-4', commonCSSClasses)}>
          {helper}
        </div>
      )}
    </div>
  );
});

export default InputGroup;
