import Input from 'components/Input';
import Select from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {STEP_CONDITION_ACTION_TYPE_SNOOZE} from 'services/steps';
import './_Styles.scss';

const durationOptions = [
  // {value: 'second', content: 'Second'}, // used for testing only
  {value: 'hour', content: 'Hour'},
  {value: 'day', content: 'Day'},
  {value: 'week', content: 'Week'},
];

const completionOptionsMap = {
  1: '1 time',
  2: '2 times',
  3: '3 times',
  4: '4 times',
  5: '5 times',
};

const completionOptions = [
  {value: '1', label: '1 time'},
  {value: '2', label: '2 times'},
  {value: '3', label: '3 times'},
  {value: '4', label: '4 times'},
  {value: '5', label: '5 times'},
];

const parseSnoozeValue = (value) => {
  if (value == null) {
    return {
      durationUnit: durationOptions[0].value,
      durationValue: 0,
      completion: completionOptions[0].value,
    };
  }
  const [durationUnit, durationValue, completion] = value.split(';');

  return {
    durationUnit,
    durationValue,
    completion,
  };
};

export const parseSnoozeSettingsToText = (action) => {
  if (action.value == null) return '';
  const {durationUnit, durationValue, completion} = parseSnoozeValue(
    action.value
  );
  return `For ${durationValue} ${durationUnit}${
    durationValue > 1 ? 's' : ''
  }, ${completionOptionsMap[completion]}`;
};

export const getSharedSnooze = (selectedStep) => {
  const action = selectedStep?.blocks
    .map((block) =>
      block?.actions?.find(
        (_action) => _action.type === STEP_CONDITION_ACTION_TYPE_SNOOZE
      )
    )
    .find((x) => x);
  const triggerAction = selectedStep?.triggers
    ?.map((t) =>
      t.actions.find((ta) => ta.type === STEP_CONDITION_ACTION_TYPE_SNOOZE)
    )
    .find((x) => x);

  return action || triggerAction;
};

const SettingsSnooze = ({action, setAction}) => {
  const {evolution} = useContext(BuilderContext);

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;

  const {
    durationUnit = durationOptions[0].value,
    durationValue = 0,
    completion = completionOptions[0].value,
  } = parseSnoozeValue(action.value);

  return (
    <div className="settings-snooze">
      <div className="subtitle">
        <i className="isax isax-main-component5" />
        <div className="body-4 n-700">For every</div>
        <div className="action-group subtitle-4 n-800">
          <div className="icon-wrapper">
            <i className="isax isax-pause-circle" />
          </div>
          Snooze {isTour ? 'experience' : 'survey'}
        </div>
      </div>
      <div className="field-group">
        <div className="field">
          <text className="body-3">Snooze for</text>
          <Input
            small
            type="number"
            min={0}
            option
            optionValue={durationUnit}
            optionItems={durationOptions}
            onOptionSelected={(selectedDurationUnitValue) =>
              setAction({
                ...action,
                value: `${selectedDurationUnitValue};${durationValue};${completion}`,
                evolution,
              })
            }
            onChange={({target}) =>
              setAction({
                ...action,
                value: `${durationUnit};${parseInt(
                  target.value,
                  10
                )};${completion}`,
                evolution,
              })
            }
            value={durationValue}
          />
        </div>
        <div className="field">
          <text className="body-3">Up to</text>
          <Select
            isSearchable={false}
            small
            options={completionOptions}
            value={completionOptions.find((o) => o.value === completion)}
            onChange={(selectedCompletion) =>
              setAction({
                ...action,
                value: `${durationUnit};${durationValue};${selectedCompletion.value}`,
                evolution,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsSnooze;
