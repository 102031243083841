import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import TextArea from 'components/TextArea';
import {toastSuccess} from 'components/Toaster';
import React, {useEffect, useRef, useState} from 'react';
import {requestService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const ModalAddOrEditRequest = ({
  onClose,
  onAddSuccess,
  onEditSuccess,
  requestToEdit,
  className,
  editMode = false,
  isOpen,
  ...rest
}) => {
  const logger = new Swaler('ModalAddOrEditRequest');

  const [inputRequestTitle, setInputRequestTitle] = useState('');
  const [inputRequestContent, setInputRequestContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);

  const refForm = useRef();

  useEffect(() => {
    setErrorSubmit(null);
    if (editMode === true) {
      setInputRequestTitle(requestToEdit.title);
      setInputRequestContent(requestToEdit.content);
    } else {
      setInputRequestTitle('');
      setInputRequestContent('');
    }
  }, [isOpen, editMode]);

  const handleRequestClose = () => {
    if (isSubmitting === false) {
      onClose();
    }
  };

  const handleSubmitAddRequest = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorSubmit(null);
    try {
      const createdRequest = await requestService.createRequest({
        title: inputRequestTitle,
        content: inputRequestContent,
      });

      setIsSubmitting(false);
      setInputRequestTitle('');
      setInputRequestContent('');
      onAddSuccess(createdRequest);
      toastSuccess('Request created 👏', {toastId: 'request-created'});
    } catch (err) {
      logger.error(`Create request failed with error`, err);
      setIsSubmitting(false);
      setErrorSubmit(err);
    }
  };

  const handleSubmitEditRequest = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorSubmit(null);
    try {
      const editedRequest = await requestService.updateRequest(
        requestToEdit.uid,
        {
          title: inputRequestTitle,
          content: inputRequestContent,
        }
      );

      setIsSubmitting(false);
      setInputRequestTitle('');
      setInputRequestContent('');
      onEditSuccess(editedRequest);
      toastSuccess('Request updated 👌', {toastId: 'request-updated'});
    } catch (err) {
      logger.error(`Create request failed with error`, err);
      setIsSubmitting(false);
      setErrorSubmit(err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      small
      title={editMode === true ? 'Edit request' : 'Add user requests'}
      {...rest}
      className={`${className} modal-add-edit-request`}
      onRequestClose={handleRequestClose}>
      {errorSubmit != null && <Alert danger>{errorSubmit.message}</Alert>}
      <form
        ref={refForm}
        onSubmit={
          editMode === true ? handleSubmitEditRequest : handleSubmitAddRequest
        }>
        <Input
          placeholder="Request title"
          name="inputRequestTitle"
          value={inputRequestTitle}
          required
          onChange={({target}) => setInputRequestTitle(target.value)}
        />
        <TextArea
          placeholder="Feedback description"
          name="inputRequestContent"
          value={inputRequestContent}
          required
          onChange={({target}) => setInputRequestContent(target.value)}
        />
        <div className="actions">
          <Button
            type="button"
            muted
            onClick={handleRequestClose}
            disabled={isSubmitting}>
            Close
          </Button>
          <Button type="submit" primary loading={isSubmitting}>
            {editMode === true ? 'Save' : 'Submit request'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalAddOrEditRequest;
