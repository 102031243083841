import {TAGS_ADD, TAGS_SET, TAGS_SET_FILTER, TAGS_UPT} from 'actions/tags';

const defaultTagsState = {
  tags: [],
  filter: [],
};

const tagsReducer = (state = defaultTagsState, action) => {
  switch (action.type) {
    case TAGS_SET: {
      return {
        ...state,
        tags: action.tags,
      };
    }
    case TAGS_ADD: {
      return {
        ...state,
        tags: state.tags.concat(action.tag),
      };
    }
    case TAGS_UPT: {
      return {
        ...state,
        tags: state.tags.map((t) =>
          t.uid === action.tagId ? {...t, ...action.data} : t
        ),
      };
    }
    case TAGS_SET_FILTER: {
      return {
        ...state,
        filter: action.tags,
      };
    }
    default: {
      return state;
    }
  }
};

export default tagsReducer;
