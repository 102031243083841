import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

function Alert({
  className,
  icon,
  title,
  children,
  info = false,
  success = false,
  warning = false,
  danger = false,
}) {
  const getClassName = () => {
    return classnames('alert', className, {
      info,
      success,
      warning,
      danger,
    });
  };

  return (
    <div className={getClassName()}>
      {icon != null && (
        <div className="icon-wrapper">
          <i className={icon}></i>
        </div>
      )}
      <div className="alert-content-wrapper">
        {title != null && <div className="alert-title subtitle-4">{title}</div>}
        {children != null && (
          <div className="alert-body body-4 n-700">{children}</div>
        )}
      </div>
    </div>
  );
}

export default Alert;
