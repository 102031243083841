import React, {useEffect, useState} from 'react';
import './_Styles.scss';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import classNames from 'classnames';
import dayjs from 'dayjs';
import DatePicker from 'components/DatePicker';

const TIME_FRAME_ALL_TIME = 'ALL_TIME';
const TIME_FRAME_LAST_WEEK = 'LAST_WEEK';
const TIME_FRAME_LAST_MONTH = 'LAST_MONTH';
const TIME_FRAME_CUSTOM_VALUE = 'CUSTOM_VALUE';
const TIME_FRAME_CUSTOM = 'CUSTOM';

const timeFrameOptions = [
  {label: 'All time', value: TIME_FRAME_ALL_TIME},
  {label: 'Last week', value: TIME_FRAME_LAST_WEEK},
  {label: 'Last month', value: TIME_FRAME_LAST_MONTH},
  {label: '', value: TIME_FRAME_CUSTOM_VALUE},
  {label: 'Custom', value: TIME_FRAME_CUSTOM},
];

const DateSelectionDropdown = ({
  minDate,
  startDate,
  endDate,
  setTimeRange,
  defaultTimeFrame,
  setTimeFrame: setTimeFrameProp = () => {},
  defaultCustomDate,
  setCustomDate = () => {},
}) => {
  const [timeFrame, setTimeFrame] = useState(
    defaultTimeFrame || TIME_FRAME_ALL_TIME
  );
  const [isSettingCustomDate, setIsSettingCustomDate] = useState(false);
  const [lastCustomDate, setLastCustomDate] = useState(
    defaultCustomDate || null
  );

  const selectedTimeFrame = timeFrameOptions.find((o) => o.value === timeFrame);
  const selectedTimeFrameLabel =
    [TIME_FRAME_CUSTOM, TIME_FRAME_CUSTOM_VALUE].includes(
      selectedTimeFrame?.value
    ) === true
      ? `${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format(
          'MMM DD, YYYY'
        )}`
      : selectedTimeFrame?.label;

  const [lastCustomStartDate, lastCustomEndDate] = lastCustomDate ?? [];

  useEffect(() => {
    setTimeFrameProp(timeFrame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame]);

  useEffect(() => {
    setCustomDate(lastCustomDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCustomDate]);

  return (
    <div className="date-selection-dropdown-wrapper">
      {isSettingCustomDate === true ? (
        <CustomDatePicker
          minDate={minDate}
          startDate={startDate}
          endDate={endDate}
          setTimeRange={(value) => {
            setTimeRange(value);
            setLastCustomDate(value);
          }}
          onClose={() => {
            setIsSettingCustomDate(false);
          }}
          customInput={
            <Button
              thin
              iconLeft="icon-calendar"
              iconRight="icon-chevron-bottom">
              {selectedTimeFrameLabel}
            </Button>
          }
        />
      ) : (
        <Dropdown
          className="date-selection"
          position="bottom right"
          repositionOnResize={false}
          contentStyle={{zIndex: 1002}}
          trigger={
            <Button
              thin
              iconLeft="icon-calendar"
              iconRight="icon-chevron-bottom">
              {selectedTimeFrameLabel}
            </Button>
          }>
          <div className="date-list">
            {timeFrameOptions
              .filter((o) => {
                if (lastCustomDate == null) {
                  return o.value !== TIME_FRAME_CUSTOM_VALUE;
                }
                return true;
              })
              .map((option) => {
                return (
                  <div
                    className={classNames('date-item', {
                      'is-set': timeFrame === option.value,
                    })}
                    onClick={() => {
                      setTimeFrame(option.value);
                      if (option.value === TIME_FRAME_CUSTOM) {
                        setTimeFrame(TIME_FRAME_CUSTOM_VALUE);
                        setIsSettingCustomDate(true);
                      } else if (option.value === TIME_FRAME_ALL_TIME) {
                        setTimeRange([
                          dayjs(minDate).subtract(1, 'day').toDate(),
                          dayjs().toDate(),
                        ]);
                      } else if (option.value === TIME_FRAME_LAST_WEEK) {
                        setTimeRange([
                          dayjs(minDate).subtract(1, 'day').toDate() >
                          dayjs().subtract(7, 'day').toDate()
                            ? dayjs(minDate).subtract(1, 'day').toDate()
                            : dayjs().subtract(7, 'day').toDate(),
                          dayjs().toDate(),
                        ]);
                      } else if (option.value === TIME_FRAME_LAST_MONTH) {
                        setTimeRange([
                          dayjs(minDate).subtract(1, 'day').toDate() >
                          dayjs().subtract(1, 'month').toDate()
                            ? dayjs(minDate).subtract(1, 'day').toDate()
                            : dayjs().subtract(1, 'month').toDate(),
                          dayjs().toDate(),
                        ]);
                      } else if (option.value === TIME_FRAME_CUSTOM_VALUE) {
                        setTimeRange(lastCustomDate);
                      }
                    }}>
                    {option.value === TIME_FRAME_CUSTOM_VALUE
                      ? `${dayjs(lastCustomStartDate).format(
                          'MMM DD, YYYY'
                        )} - ${dayjs(lastCustomEndDate).format('MMM DD, YYYY')}`
                      : option.label}
                  </div>
                );
              })}
          </div>
        </Dropdown>
      )}
    </div>
  );
};

const CustomDatePicker = ({
  minDate,
  startDate,
  endDate,
  setTimeRange,
  customInput,
  onClose,
}) => {
  const [newTimeRange, setNewTimeRange] = useState(null);

  return (
    <DatePicker
      startOpen
      dateFormat="MMM dd, yyyy"
      selectsRange={true}
      startDate={newTimeRange != null ? newTimeRange[0] : startDate}
      endDate={newTimeRange != null ? newTimeRange[1] : endDate}
      minDate={dayjs(minDate).subtract(1, 'day').toDate()}
      maxDate={dayjs().toDate()}
      monthsShown={2}
      onChange={(date) => {
        setNewTimeRange(date);
      }}
      onBlur={() => {
        onClose();
      }}
      onCalendarClose={() => {
        if (newTimeRange?.[0] != null && newTimeRange?.[1] != null) {
          setTimeRange(newTimeRange);
        }
        onClose();
      }}
      customInput={customInput}
      popperPlacement="bottom-end"
    />
  );
};

export default DateSelectionDropdown;
