import classNames from 'classnames';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import {bool, func, object} from 'prop-types';
import React, {useLayoutEffect, useRef} from 'react';
import './_Styles.scss';

const propTypes = {
  querySelectorManual: object,
  onChange: func,
  onSwitchToAuto: func,
  withText: bool,
  withMultiple: bool,
  withElementsCount: bool,
};

const defaultProps = {
  querySelectorManual: {},
  onChange: () => {},
  onSwitchToAuto: () => {},
  withText: false,
  withMultiple: false,
  withElementsCount: false,
};

const ManualSelectorEditor = ({
  querySelectorManual,
  onChange,
  onSwitchToAuto,
  withText,
  withMultiple,
  withElementsCount,
}) => {
  const {elementText, cssSelector, matchElement, count} = querySelectorManual;

  const textAreaRef = useRef(null);

  function adjustHeight() {
    textAreaRef.current.style.height = 'inherit';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }

  useLayoutEffect(adjustHeight, []);

  return (
    <div className="manual-selector-editor">
      {withElementsCount && (
        <>
          <div
            className={classNames('element-count', {
              multiple: count > 1,
              empty: count === 0,
              perfect: count === 1,
            })}>
            <i className="icon-layers" />
            <span className="body-3">
              {count} element{count > 1 ? 's' : ''} found
            </span>
          </div>
          <Divider />
        </>
      )}
      {withText && (
        <div className="element">
          <div className="label body-3">
            Element text
            <Tooltip
              className="tooltip-manual-selector"
              dark
              offsetY={8}
              trigger={<i className="icon-question-circle n-700" />}
              contentStyle={{zIndex: 10000}}>
              Elements that match with the text you write here will be selected.
            </Tooltip>
          </div>
          <div className="content">
            <InputGroup
              type="text"
              placeholder="Enter element text"
              value={elementText}
              onChange={(e) => onChange({elementText: e.target.value})}
            />
          </div>
        </div>
      )}
      <div className="element">
        <div className="label body-3">
          CSS selector
          <Tooltip
            className="tooltip-manual-selector"
            dark
            offsetY={8}
            trigger={<i className="icon-question-circle n-700" />}
            contentStyle={{zIndex: 10000}}>
            If text is generic or absent, use this CSS selector. If both are
            filled, Jimo will match elements using both criteria.
          </Tooltip>
        </div>
        <div className="content">
          <TextArea
            innerRef={textAreaRef}
            placeholder="Enter element css selector"
            value={cssSelector}
            onChange={(e) => {
              adjustHeight();
              onChange({cssSelector: e.target.value});
            }}
          />
        </div>
      </div>
      {withMultiple && (
        <div className="element">
          <div className="label body-3">If multiple matches, select</div>
          <div className="content">
            <RadioGroup
              options={[
                {label: 'First element', value: 'first'},
                {label: 'All elements', value: 'all'},
              ]}
              value={matchElement}
              onSelect={(value) => {
                onChange({matchElement: value});
              }}
            />
          </div>
        </div>
      )}
      <div className="switch-to-auto b-400" onClick={onSwitchToAuto}>
        <i className="icon-target" />
        <span>Switch to auto selection</span>
      </div>
    </div>
  );
};

ManualSelectorEditor.propTypes = propTypes;
ManualSelectorEditor.defaultProps = defaultProps;

export default ManualSelectorEditor;
