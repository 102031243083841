import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {EventConditionOperand} from 'services/event';
import {EventConditionsEditorContext} from '../../../..';
import {ElementSelector} from '../ElementSelector';
import './_Styles.scss';

const inputOperandOptions = [
  {label: 'Is', value: EventConditionOperand.IS},
  {label: 'Is not', value: EventConditionOperand.NIS},
  {label: 'Contains', value: EventConditionOperand.CONTAINS},
  {label: 'Does not contain', value: EventConditionOperand.NCONTAINS},
  {label: 'Starts with', value: EventConditionOperand.STARTS_WITH},
  {label: 'Ends with', value: EventConditionOperand.ENDS_WITH},
  {
    label: 'Matches regular expression',
    value: EventConditionOperand.MATCHES_REGEX,
  },
  {
    label: 'Does not match regular expression',
    value: EventConditionOperand.NMATCHES_REGEX,
  },
  {label: 'Is empty', value: EventConditionOperand.IS_EMPTY},
  {label: 'Is not empty', value: EventConditionOperand.NIS_EMPTY},
];

const SettingsInput = ({condition, setCondition, setPreventClose}) => {
  const {onSelectElement} = useContext(EventConditionsEditorContext);

  const {operand, value} = condition;

  return (
    <div className="settings-input">
      <ElementSelector
        data={condition}
        onChange={(data) =>
          setCondition({
            ...condition,
            ...data,
          })
        }
        isInput
        withMultiple
        onModalOpen={(isOpen) => setPreventClose(isOpen)}
        onSelectElement={
          onSelectElement != null
            ? () =>
                onSelectElement(condition.uid, {
                  onlyInput: true,
                  type: 'condition-element',
                })
            : null
        }
      />
      <Divider />
      <div className="section-item">
        <SelectGroup
          isSearchable={false}
          menuPlacement="auto"
          options={inputOperandOptions}
          value={inputOperandOptions.find((o) => o.value === operand)}
          onChange={(option) => {
            setCondition({
              ...condition,
              operand: option.value,
            });
          }}
        />
      </div>
      {[
        EventConditionOperand.IS_EMPTY,
        EventConditionOperand.NIS_EMPTY,
      ].includes(operand) !== true && (
        <div className="section-item">
          <InputGroup
            value={value}
            onChange={({target}) => {
              setCondition({
                ...condition,
                value: target.value,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsInput;
