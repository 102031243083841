import classnames from 'classnames';
import {bool, any, func, string} from 'prop-types';

import './_Styles.scss';

const propTypes = {
  title: string.isRequired,
  description: string,
  icon: any,
  className: string,
  onClick: func,
  onDeleteClick: func,
  selected: bool,
  active: bool,
  iconBackgroundColor: string,
  muted: bool,
  disabled: bool,
  comingSoon: bool,
  beta: bool,
  draggable: bool,
};
const defaultProps = {
  description: null,
  icon: null,
  className: '',
  onClick: () => {},
  onDeleteClick: null,
  selected: false,
  active: false,
  iconBackgroundColor: null,
  muted: false,
  disabled: false,
  comingSoon: false,
  beta: false,
  draggable: false,
};

const Block = ({
  title,
  description,
  icon,
  children,
  className,
  onClick,
  onDeleteClick,
  selected,
  active,
  iconBackgroundColor,
  muted,
  disabled,
  comingSoon,
  beta,
  draggable,
}) => {
  const handleClick = (e) => {
    if (comingSoon === true || disabled === true) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div
      className={classnames(`block ${className}`, {
        selected: selected === true,
        'has-no-title': !title,
        'is-disabled': disabled,
        'is-coming-soon': comingSoon,
        'is-beta': beta,
        'has-children': children != null,
        muted,
        active,
      })}
      onClick={handleClick}>
      <div className="block-content">
        {draggable === true && <i className="icon-menu-vertical"></i>}
        <div
          className="icon-wrapper"
          style={
            iconBackgroundColor != null
              ? {backgroundColor: iconBackgroundColor}
              : null
          }>
          {icon}
        </div>
        <div className="content">
          <div className="title">{title || 'Unnamed step'}</div>
          {description != null && (
            <div className="description">{description}</div>
          )}
        </div>
        {onDeleteClick != null && (
          <div
            className="close-btn-wrapper"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteClick();
            }}>
            <i className={`icon-close`} />
          </div>
        )}
      </div>
      {children != null && children}
    </div>
  );
};

Block.propTypes = propTypes;
Block.defaultProps = defaultProps;

export default Block;
