import {useFormInputProps} from '@udecode/plate-common';
import {flip, offset} from '@udecode/plate-floating';
import {
  FloatingLinkUrlInput,
  LinkOpenButton,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
} from '@udecode/plate-link';
import React from 'react';
import './_Styles.scss';

import classNames from 'classnames';
import Divider from 'components/Divider';

const floatingOptions = {
  middleware: [
    offset(12),
    flip({
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
      padding: 12,
    }),
  ],
  placement: 'bottom-start',
};
export function LinkFloatingToolbar({state}) {
  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const {
    hidden,
    props: insertProps,
    ref: insertRef,
    textInputProps,
  } = useFloatingLinkInsert(insertState);

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const {
    editButtonProps,
    props: editProps,
    ref: editRef,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState);
  const inputProps = useFormInputProps({
    preventDefaultOnEnterKeydown: true,
  });

  if (hidden) return null;

  const input = (
    <div className="input-wrapper" {...inputProps}>
      <div className="item">
        <div className="icon-wrapper">
          <i className="isax isax-link-2" />
        </div>
        <FloatingLinkUrlInput className={''} placeholder="Paste link" />
      </div>
      <Divider />
      <div className="item">
        <div className="icon-wrapper">
          <i className="isax isax-textalign-left4" />
        </div>
        <input
          className={''}
          placeholder="Text to display"
          {...textInputProps}
        />
      </div>
    </div>
  );

  const editContent = editState.isEditing ? (
    input
  ) : (
    <div className="edit-wrapper">
      <div className="item">
        <button className={''} type="button" {...editButtonProps}>
          Edit link
        </button>
      </div>
      <Divider vertical />
      <div className="item">
        <LinkOpenButton className={''}>Open link</LinkOpenButton>
      </div>
      <Divider vertical />
      <div className="item">
        <button className={''} type="button" {...unlinkButtonProps}>
          Unlink
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div
        className="link-floating-toolbar-input-wrapper"
        ref={insertRef}
        {...insertProps}>
        {input}
      </div>

      <div
        className={classNames('link-floating-toolbar-edit-wrapper', {
          'is-editing': editState.isEditing,
        })}
        ref={editRef}
        {...editProps}>
        {editContent}
      </div>
    </>
  );
}
