export const EVOLUTION_STEP_LIVE_FOR_VOTE = 'STEP_LIVE_FOR_VOTE';
export const EVOLUTION_STEP_PROTOTYPE = 'STEP_PROTOTYPE';
export const EVOLUTION_STEP_RELEASE = 'STEP_RELEASE';
export const EVOLUTION_SURVEY = 'SURVEY';
export const EVOLUTION_STEPS = [
  EVOLUTION_STEP_LIVE_FOR_VOTE,
  EVOLUTION_STEP_PROTOTYPE,
  EVOLUTION_STEP_RELEASE,
];

export const VOTE_YEAH = 2;
export const VOTE_IDK = 1;
export const VOTE_NAH = 0;
export const VOTE_CONGRATS = 100;
