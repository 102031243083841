import InputGroup from 'components/Input';
import {func, string} from 'prop-types';
import React from 'react';
import ColorPickerInput from '../../items/ColorPickerInput';

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
};

const Shadow = ({value, onChange, setCloseOnDocumentClick}) => {
  const {color, x, y, blur} = value ?? {};

  return (
    <>
      <div className="section-item">
        <div className="section-item-title">Shadow</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Shadow color"
            value={color}
            onChange={(v) => onChange({...value, color: v})}
            setParentCloseOnDocumentClick={setCloseOnDocumentClick}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">X</div>
        <div className="section-item-content">
          <InputGroup
            value={x}
            type="number"
            min={-100}
            max={100}
            onChange={(e) => onChange({...value, x: e.target.value})}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Y</div>
        <div className="section-item-content">
          <InputGroup
            value={y}
            type="number"
            min={-100}
            max={100}
            onChange={(e) => onChange({...value, y: e.target.value})}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Blur</div>
        <div className="section-item-content">
          <InputGroup
            value={blur}
            type="number"
            min={0}
            max={100}
            onChange={(e) => onChange({...value, blur: e.target.value})}
          />
        </div>
      </div>
    </>
  );
};

Shadow.propTypes = propTypes;
Shadow.defaultProps = defaultProps;

export default Shadow;
