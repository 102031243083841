import classnames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import EvolutionList from 'components/EvolutionList';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_POST_BUILDER_WRITER} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_DRAFT,
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  F_OPTION_PORTAL_DISPLAY_FEED,
} from 'services/evolution';
import {F_EXTRA_HAS_PUBLISHED_CHANGELOG} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ReleaseEmptyState from './components/ReleaseEmptyState';

const logger = new Swaler('Posts');

const Posts = ({className, refresh, hasRefreshed}) => {
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));

  const [view, setView] = useState({
    states: [EVOLUTION_STATE_LIVE, EVOLUTION_STATE_PAUSED], // We consider paused as live as there is currently no paused state for posts
    viewTags: [],
    viewSegments: [],
  });
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const {
    data: evolutions = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'evolutions',
      'feed',
      view.states,
      view.viewTags,
      view.viewSegments,
    ],
    queryFn: async () => {
      try {
        const evolutions = await evolutionService.getEvolutions({
          states: [
            ...view.states,
            ...(view.states.includes(EVOLUTION_STATE_LIVE)
              ? [EVOLUTION_STATE_PAUSED]
              : []), // We consider paused as live as there is currently no paused state for posts
          ],
          // Handle draft a little different
          ...(view.states == null ||
          view.states.length === 0 ||
          view.states.includes(EVOLUTION_STATE_DRAFT)
            ? {}
            : {removeDrafts: true}),
          relations: [
            'tags',
            'count.comments',
            'count.votes',
            'steps',
            'analytics',
            'segments',
          ],
          onlyForMode: F_OPTION_PORTAL_DISPLAY_FEED,
        });

        return evolutions;
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Fetch evolutions failed with error ', code);
        toastDanger([title, message], {actions});
      }
    },
    cacheTime: 0,
    onSuccess: (posts) => {
      window.jimo.push([
        'set',
        'user:attributes',
        [
          {
            countPublishedPosts: posts.filter((p) => p.isDraft === false)
              .length,
          },
        ],
      ]);
      setHasLoadedOnce(true);
    },
  });

  useEffect(() => {
    if (refresh === true) {
      refetch();
      hasRefreshed();
    }
  }, [refresh]);

  const classNames = classnames('s-posts', {
    'is-loading': isLoading && hasLoadedOnce === false,
  });

  const hasAlreadyPublishedChangelog = hasFlag(
    F_EXTRA_HAS_PUBLISHED_CHANGELOG,
    project.extraFlags
  );

  if (isLoading && hasLoadedOnce === false) {
    return (
      <div className={`${classNames}`}>
        <DefaultLoader />
      </div>
    );
  }

  const filteredEvolutions = evolutions.filter((e) => {
    if (view.viewSegments.length > 0) {
      return (
        e.segments.filter((s) =>
          view.viewSegments.map((se) => se.uid).includes(s.uid)
        ).length > 0
      );
    }
    if (view.viewTags.length > 0) {
      return (
        e.tags.filter((t) =>
          view.viewTags.map((tag) => tag.uid).includes(t.uid)
        ).length > 0
      );
    }
    return true;
  });

  return (
    <div className={`${classNames} ${className}`}>
      <div className="main-row">
        {hasAlreadyPublishedChangelog === false ? (
          <div className="all-empty-state-wrapper">
            <ReleaseEmptyState />
          </div>
        ) : evolutions.length === 0 &&
          view.states.length === 0 &&
          view.viewTags.length === 0 &&
          view.viewSegments.length === 0 &&
          isLoading !== true ? (
          <div className="empty-state-wrapper">
            <EmptyStateBlock
              img={EmptyStateImgs.EmptyPosts}
              title="No Posts Yet"
              description="Create and publish your Changelog posts to start activating your Changelog."
            />
            <Button
              reverted
              primary
              onClick={() => history.push(ROUTE_POST_BUILDER_WRITER('new'))}>
              Create first post
            </Button>
          </div>
        ) : (
          <div className="content-wrapper">
            <div className="evolution-list-wrapper">
              <EvolutionList
                evolutions={filteredEvolutions}
                onDelete={refetch}
                view={view}
                setView={setView}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Posts;
