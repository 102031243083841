import React, {useContext} from 'react';
import {TrackerContext} from 'scenes/Tracker';
import './_Styles.scss';
import {useQuery} from 'react-query';
import {trackerAnalyticsService} from 'services';
import dayjs from 'dayjs';
import {Swaler} from 'swaler';
import {StatBlock} from '../FeatureUsageStats';

const logger = new Swaler('ActivationStats');

const ActivationStats = () => {
  const {tracker} = useContext(TrackerContext);

  const startDate = dayjs().subtract(1, 'week').toDate();
  const endDate = dayjs().toDate();

  const {steps = []} = tracker || {};

  const {data: analyticsDaily} = useQuery({
    queryKey: ['analyticsDaily', 'tracker', tracker.uid],
    queryFn: async () => {
      const analytics = await trackerAnalyticsService.getAnalyticsDaily({
        trackerId: tracker.uid,
        startDate,
        endDate,
      });
      return analytics;
    },
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  const stepsWithAnalytics = analyticsDaily?.reduce((acc, cur) => {
    const index = acc.map((step) => step.uid).indexOf(cur.trackerStepId);

    if (acc[index].count == null) {
      acc[index].count = parseInt(cur.count, 10);
    } else {
      acc[index].count = acc[index].count + parseInt(cur.count, 10);
    }

    return acc;
  }, JSON.parse(JSON.stringify(steps || [])));

  stepsWithAnalytics?.sort((a, b) => a.indexOrder - b.indexOrder);

  const firstStep = stepsWithAnalytics?.[0];
  const lastStep = stepsWithAnalytics?.[stepsWithAnalytics?.length - 1];

  const {count: firstStepCurrentCount = 0, analytics: firstStepAnalytics = {}} =
    firstStep || {};
  const {count: lastStepCurrentCount = 0, analytics: lastStepAnalytics = {}} =
    lastStep || {};
  const {count: firstStepCount = 0} = firstStepAnalytics || {};
  const {count: lastStepCount = 0} = lastStepAnalytics || {};

  const conversionRate =
    firstStepCount > 0 ? Math.round((lastStepCount * 100) / firstStepCount) : 0;
  const currentConversionRate =
    firstStepCurrentCount > 0
      ? Math.round((lastStepCurrentCount * 100) / firstStepCurrentCount)
      : 0;

  return (
    <div className="feature-usage-stats">
      <StatBlock
        icon={<i className="isax isax-profile-2user" />}
        title="Total users"
        value={firstStepCount}
        additional={
          firstStepCurrentCount > 0
            ? `+ ${firstStepCurrentCount} in the last 7 days`
            : ''
        }
      />
      <StatBlock
        icon={<i className="isax isax-activity" />}
        title="Conversion rate"
        value={`${conversionRate}%`}
        additional={
          currentConversionRate > 0
            ? `${currentConversionRate}% in the last 7 days`
            : ''
        }
      />
    </div>
  );
};

export default ActivationStats;
