import {Elements} from '@stripe/react-stripe-js';
import {dataActions, generalActions} from 'actions';
import amplitude from 'amplitude-js';
import MobileOverlay from 'components/MobileOverlay';
import {ModalAddonCancel} from 'components/ModalAddonCancel';
import {
  ModalAddonCheckout,
  ModalMauAddonCheckout,
} from 'components/ModalAddonCheckout';
import {ModalAddonPurchased} from 'components/ModalAddonPurchased';
import {ModalFeedbackCancelPlan} from 'components/ModalFeedbackCancelPlan';
import {ModalPlanCancelDone} from 'components/ModalPlanCancelDone';
import {ModalPlanDowngrade} from 'components/ModalPlanDowngrade';
import {ModalPlanPreview} from 'components/ModalPlanPreview';
import {ModalPlanUpgrade} from 'components/ModalPlanUpgrade';
import {ModalSubscriptionCheckout} from 'components/ModalSubscriptionCheckout';
import {ModalWelcomePlan} from 'components/ModalWelcomePlan';
import {setBearerToken} from 'conf/axios';
import {Environment} from 'conf/env';
import {stripePromised} from 'conf/stripe';
import {GlobalContext} from 'contextes/Global';
import 'flag-icons/css/flag-icons.css';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {JimoNavigation, useJimoIdentify} from 'helpers/jimoOnJimo';
import {useIsExtensionInstalled} from 'helpers/utils';
import {posthog} from 'posthog-js';
import {useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {ErrorBoundary} from 'react-error-boundary';
import gtm from 'react-gtm-module';
import {QueryClient, QueryClientProvider} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter as Router, useHistory} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css';
import ChangelogBuilder from 'scenes/ChangelogBuilder';
import EmbeddedElementSelectorBuilder from 'scenes/EmbeddedElementSelectorBuilder';
import EmbeddedPokeBuilder from 'scenes/EmbeddedPokeBuilder';
import {CommonEB} from 'scenes/Error';
import ExtensionBuilder from 'scenes/ExtensionBuilder';
import {GetStarted} from 'scenes/GetStarted';
import {GetStartedInstall} from 'scenes/GetStarted/scenes/Install';
import {GetStartedTry} from 'scenes/GetStarted/scenes/Try';
import {OnboardingDetailsJoin} from 'scenes/Onboarding/scenes/Details/join';
import {OnboardingDetailsStep11} from 'scenes/Onboarding/scenes/Details/step-1-1';
import {OnboardingDetailsStep12} from 'scenes/Onboarding/scenes/Details/step-1-2';
import {OnboardingDetailsStep21} from 'scenes/Onboarding/scenes/Details/step-2-1';
import {OnboardingDetailsStep22} from 'scenes/Onboarding/scenes/Details/step-2-2';
import {OnboardingDetailsStep23} from 'scenes/Onboarding/scenes/Details/step-2-3';
import {OnboardingDetailsStep30} from 'scenes/Onboarding/scenes/Details/step-3-0';
import {OnboardingDetailsStep31} from 'scenes/Onboarding/scenes/Details/step-3-1';
import ForgotPass from 'scenes/Onboarding/scenes/ForgotPass';
import LoginWithCode from 'scenes/Onboarding/scenes/LoginWithCode';
import OnboardingLogout from 'scenes/Onboarding/scenes/Logout';
import {OnboardingProject} from 'scenes/Onboarding/scenes/Project';
import {OnboardingRegisterEmailSent} from 'scenes/Onboarding/scenes/Register/email-sent';
import {OnboardingRegisterSetupPassword} from 'scenes/Onboarding/scenes/Register/setup-password';
import {OnboardingWaiting} from 'scenes/Onboarding/scenes/Waiting';
import PokeAudience from 'scenes/PokeAudience';
import PokeBuilder from 'scenes/PokeBuilder';
import Portal from 'scenes/Portal';
import {PortalPost} from 'scenes/Portal/scenes/Post';
import PostAudience from 'scenes/PostAudience';
import PostBuilder from 'scenes/PostBuilder';
import {Pushes} from 'scenes/Pushes/with_context';
import IntercomRedirect from 'scenes/Settings/components/IntercomRedirect';
import SettingsBilling from 'scenes/Settings/scenes/Billing';
import Environments from 'scenes/Settings/scenes/Environments';
import SettingsGeneral from 'scenes/Settings/scenes/General';
import Identification from 'scenes/Settings/scenes/Installation/scenes/Identification';
import Installation from 'scenes/Settings/scenes/Installation/scenes/Install';
import SettingsIntegrations from 'scenes/Settings/scenes/Integrations';
import CrispSettings from 'scenes/Settings/scenes/Integrations/Crisp/scenes/CrispSettings';
import {IntegrationCioSettings} from 'scenes/Settings/scenes/Integrations/Customerio/scenes/Settings';
import HubspotSettings from 'scenes/Settings/scenes/Integrations/Hubspot/components/HubspotSettings';
import IntercomSettings from 'scenes/Settings/scenes/Integrations/Intercom/scenes/IntercomSettings';
import SegmentioSettings from 'scenes/Settings/scenes/Integrations/Segment/scenes/SegmentSettings';
import SlackSettings from 'scenes/Settings/scenes/Integrations/Slack/scenes/SlackSettings';
import ZapierSettings from 'scenes/Settings/scenes/Integrations/Zapier/scenes/ZapierSettings';
import SettingsNotification from 'scenes/Settings/scenes/Notification';
import SettingsPlan from 'scenes/Settings/scenes/Plan';
import Control from 'scenes/Settings/scenes/PokeControl';
import SettingsProfile from 'scenes/Settings/scenes/Profile/index';
import Subscription from 'scenes/Settings/scenes/Subscription';
import SettingsTeam from 'scenes/Settings/scenes/Team';
import Themes from 'scenes/Settings/scenes/Themes';
import {SettingsTheme} from 'scenes/Settings/scenes/Themes/theme';
import SuccessTracker from 'scenes/SuccessTracker';
import Event from 'scenes/SuccessTracker/components/Event';
import Switch from 'scenes/Switch';
import Templates from 'scenes/Templates';
import Tracker from 'scenes/Tracker';
import TrackerBuilder from 'scenes/TrackerBuilder';
import Users from 'scenes/Users';
import {ImportCSV} from 'scenes/Users/scenes/Import/CSV';
import Segment from 'scenes/Users/scenes/Segment';
import {generalSelector} from 'selectors';
import {
  authService,
  meService,
  rolesService,
  segmentService,
  subscriptionService,
} from 'services';
import {
  F_EXTRA_FIRST_POKE_ADOPTION,
  F_EXTRA_FIRST_POKE_DISCOVERY,
  F_EXTRA_FIRST_POST_PORTAL,
  isEligibleToTrial,
  isPaying,
  isTrying,
} from 'services/project';
import {
  ADDON_MAU,
  F_IS_TRIALING,
  hasSaasMantraPlan,
} from 'services/subscription';
import {nativeFontFamilies} from 'shared/front/components/Poke/utils/fonts';
import {Swaler} from 'swaler';
import './_Tokens.scss';
import {
  EmptyLayout,
  OnboardingLayout,
  PokeBuilderLayout,
  PostBuilderLayout,
  SettingsLayout,
  TemplatesLayout,
} from './router/Layouts';
import {PrivateRoute, PublicOnlyRoute, PublicRoute} from './router/Routes';
import {
  ROUTE_BANNERS,
  ROUTE_BANNER_SETTINGS_WITH_ID,
  ROUTE_BANNER_WITH_ID,
  ROUTE_BOOSTED_WITH_ID,
  ROUTE_BUILDER_TRACKER,
  ROUTE_CHANGELOG_BUILDER,
  ROUTE_CHANGELOG_BUILDER_WITH_ID,
  ROUTE_CHECKLISTS,
  ROUTE_CHECKLIST_SETTINGS_WITH_ID,
  ROUTE_CHECKLIST_WITH_ID,
  ROUTE_DEV,
  ROUTE_DRAFT,
  ROUTE_DRAFT_WITH_ID,
  ROUTE_EVENTS,
  ROUTE_EVENTS_WITH_ID,
  ROUTE_FEED,
  ROUTE_FEED_EVOLUTION,
  ROUTE_GET_STARTED,
  ROUTE_GET_STARTED_INSTALL,
  ROUTE_GET_STARTED_TRY,
  ROUTE_HINTS,
  ROUTE_HINT_SETTINGS_WITH_ID,
  ROUTE_HINT_WITH_ID,
  ROUTE_HOME,
  ROUTE_IDEAS,
  ROUTE_JOIN,
  ROUTE_LIGHTWEIGHT_ELEMENT_SELECTOR_BUILDER,
  ROUTE_LIGHTWEIGHT_EXTENSION_BUILDER,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID,
  ROUTE_LOGIN,
  ROUTE_LOGIN_WITH_CODE,
  ROUTE_LOGOUT,
  ROUTE_ONBOARDING_DETAILS_JOIN,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
  ROUTE_ONBOARDING_DETAILS_STEP_1_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
  ROUTE_ONBOARDING_DETAILS_STEP_2_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_3,
  ROUTE_ONBOARDING_DETAILS_STEP_3_1,
  ROUTE_ONBOARDING_DETAILS_STEP_ONBOARDING,
  ROUTE_ONBOARDING_PROJECT,
  ROUTE_ONBOARDING_WAITING,
  ROUTE_POKES,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POST_BUILDER_AUDIENCE,
  ROUTE_POST_BUILDER_WRITER,
  ROUTE_RECOVERY_PASSWORD,
  ROUTE_REDIRECT_INTERCOM,
  ROUTE_REGISTER,
  ROUTE_REGISTER_EMAIL_SENT,
  ROUTE_REGISTER_ONBOARDED,
  ROUTE_REGISTER_SETUP_PASSWORD,
  ROUTE_ROADMAP,
  ROUTE_ROADMAP_EVOLUTION,
  ROUTE_SEGMENTS,
  ROUTE_SETTINGS_BILLING,
  ROUTE_SETTINGS_ENVIRONMENTS,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_INSTALLATION,
  ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_CIO,
  ROUTE_SETTINGS_INTEGRATIONS_CRISP,
  ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT,
  ROUTE_SETTINGS_INTEGRATIONS_INTERCOM,
  ROUTE_SETTINGS_INTEGRATIONS_SEGMENT,
  ROUTE_SETTINGS_INTEGRATIONS_SLACK,
  ROUTE_SETTINGS_INTEGRATIONS_TYPEFORM,
  ROUTE_SETTINGS_INTEGRATIONS_ZAPIER,
  ROUTE_SETTINGS_NOTIFICATION,
  ROUTE_SETTINGS_PLAN,
  ROUTE_SETTINGS_POKE_CONTROL,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_SUBSCRIPTION_COUPON,
  ROUTE_SETTINGS_TEAM,
  ROUTE_SETTINGS_THEMES,
  ROUTE_SETTINGS_THEMES_ID,
  ROUTE_SUCCESS_TRACKER,
  ROUTE_SURVEYS,
  ROUTE_SURVEY_SETTINGS_WITH_ID,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_SWITCH_PROJECT,
  ROUTE_TEMPLATES,
  ROUTE_TOURS,
  ROUTE_TOUR_SETTINGS_WITH_ID,
  ROUTE_TOUR_WITH_ID,
  ROUTE_TRACKER_REPORT,
  ROUTE_USERS,
  ROUTE_USERS_IMPORTS,
  ROUTE_USERS_IMPORT_CSV,
  ROUTE_USERS_IMPORT_CSV_WITH_ID,
  ROUTE_USERS_SEGMENT_WITH_ID,
} from './router/routes.const';
import Loading from './scenes/Loading';
import OnboardingJoin from './scenes/Onboarding/scenes/Join';
import {OnboardingLogin} from './scenes/Onboarding/scenes/Login/index';
import {OnboardingRegister} from './scenes/Onboarding/scenes/Register/index';
import _dev from './scenes/_dev';

// initialize Amplitude
amplitude.getInstance().init(Environment.AMPLITUDE_KEY, null, {
  apiEndpoint: `${Environment.API_URL.substring(
    Environment.API_URL.indexOf('//') + 2
  )}/amplitude-proxy`,
});
// google tag manager
if (Environment.NODE_ENV === 'production') {
  gtm.initialize({
    gtmId: 'GTM-WPBNHPL',
  });
}

const App = () => {
  const logger = new Swaler('App');

  const dispatch = useDispatch();
  const {identify, pushAttributes} = useJimoIdentify();

  const general = useSelector((state) => state.general);

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const setUser = (user) => dispatch(generalActions.setUser(user));
  const setProject = (project) => dispatch(generalActions.setProject(project));
  const setProjects = (projects) =>
    dispatch(generalActions.setProjects(projects));
  const uptProjectSubscription = (subscription) =>
    dispatch(generalActions.uptProjectSubscription(subscription));
  const setSegments = (segments) => dispatch(dataActions.setSegments(segments));
  const setCustomRoles = (roles) => dispatch(dataActions.setCustomRoles(roles));
  const setBuiltInRoles = (roles) =>
    dispatch(dataActions.setBuiltInRoles(roles));
  const setCustomAttributes = (attributes) =>
    dispatch(dataActions.setCustomAttributes(attributes));
  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));
  const setSubscriptionAddons = (addons) =>
    dispatch(dataActions.setSubscriptionAddons(addons));

  const [isLoading, setIsLoading] = useState(true);

  // Global Context
  const [modalWelcomePlan, setModalWelcomePlan] = useState(null); // { planId: string, refreshProjectAfterClose : boolean - Use to refresh subscription after checkout }
  const [modalFeedbackCancelPlan, setModalFeedbackCancelPlan] = useState(null); // { onCancel : func, onSubmit: func } - Controlled by useCancelPlan
  const [modalPlanCancelDone, setModalPlanCancelDone] = useState(false);
  const [modalPlanPreview, setModalPlanPreview] = useState(null); // { planId: string, mau: number, title: string, description: string, onClose: func }
  const [modalPlanDowngrade, setModalPlanDowngrade] = useState(null); // { planId: string, onSubmit: func, onCancel: func } - Controlled by useDowngradePlan
  const [modalPlanUpgrade, setModalPlanUpgrade] = useState(null); // { planId: string, onSubmit: func, onCancel: func } - Controlled by useUpgradePlan
  const [modalSubscriptionCheckout, setModalSubscriptionCheckout] =
    useState(null); // { planId: string, modeAnnualBilling: boolean, onCancel: func, onSuccess: func }
  const [modalAddonCheckout, setModalAddonCheckout] = useState(null); // { addonId: string, onCheckout: func }
  const [modalAddonPurchased, setModalAddonPurchased] = useState(null); // { addonId: string }
  const [modalAddonCancel, setModalAddonCancel] = useState(null); // { addonId: string } - Controlled by useCancelAddon

  useEffect(() => {
    const initApp = async () => {
      // Jimo
      window.jimo = [];
      const s = document.createElement('script');

      s.type = 'text/javascript';
      s.async = true;
      s.src = `${Environment.CDN_URL}/jimo-invader.js`;
      window['JIMO_PROJECT_ID'] = Environment.JIMO_PROJECT_ID;
      window['JIMO_PREVENT_LOAD_IN_IFRAME'] = true;
      window['JIMO_MANUAL_INIT'] = true;
      document.getElementsByTagName('head')[0].appendChild(s);

      // Crisp
      if (Environment.CRISP_PROJECT_ID != null) {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = Environment.CRISP_PROJECT_ID;
        (function () {
          var d = document;
          var s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';
          s.async = 1;
          d.getElementsByTagName('head')[0].appendChild(s);
        })();
      }

      try {
        await initializeAuth();
        await initializeProject();
      } catch (err) {
        logger.error(`Could not initialize app!`, err);
        throw new Error(err);
      }
      setIsLoading(false);
    };

    initApp();
  }, []);

  const {isInstalled} = useIsExtensionInstalled();

  /**
   * Initialize authentication by :
   * - Checking if a token exists
   * - Checking if the token is expired
   * - Set token in Axios configuration
   * - Retrieve the user associated with the token
   * - Store the user in Redux
   *
   * @memberof App
   */
  const initializeAuth = async () => {
    let user = null;

    if (authService.hasJwtToken() === false) {
      return authService.signOut();
    }
    if (authService.hasExpiredJwtToken() === true) {
      return authService.signOut();
    }
    setBearerToken(authService.getJwtToken());
    try {
      user = await meService.getMe();
    } catch (err) {
      authService.signOut();
      return Promise.reject(err);
    }
    setUser(user);
    if (Environment.NODE_ENV === 'production') {
      posthog.identify(user.uid);
    }
    if (window.$crisp) {
      window.$crisp.push([
        'set',
        'session:data',
        [
          [
            ['user-id', user.uid],
            ['user-username', user.username],
            ['from', 'app'],
            ['dalaran-version', Environment.APP_VERSION],
          ],
        ],
      ]);
    }
    identify(user);
  };
  const initializeProject = async () => {
    let projects = null;
    let project = null;

    if (authService.isAuthenticated() === false) {
      return null;
    }
    try {
      projects = await meService.getMyProjects();
    } catch (err) {
      logger.error(`Fetch my projects failed with error ${err.message}`);
      throw new Error(err);
    }
    if (projects.length === 0) {
      setProjects([]);
      setProject(null);
      return console.warn("You have no project, let's create one!");
    }
    project = projects.find((p) => p.uid === general.project);
    project = project == null ? projects[0] : project;
    setProjects(projects);
    setProject(project.uid);
    if (window.$crisp) {
      window.$crisp.push([
        'set',
        'session:data',
        [
          [
            ['project-id', project.uid],
            ['project-name', project.name],
            [
              'project-mau-limit-reached',
              project.mau >= project.thresholdJimers,
            ],
            [
              'project-mau-limit-detail',
              `${project.mau}/${project.thresholdJimers}`,
            ],
            ['plan', project?.subscription?.plan ?? 'unknown'],
          ],
        ],
      ]);
    }
    if (project.subscription == null) {
      try {
        const subscription = await subscriptionService.initSubscription();

        uptProjectSubscription(subscription);
        project.subscription = subscription;
      } catch (err) {
        logger.error('Failed to init subscription with error ', err);
      }
    }
    await initializeData(project);
  };
  const initializeData = async (project) => {
    const segments = await segmentService.getSegments();
    const customAttributes = await segmentService.getSegmentCustomAttributes();
    const plans = await subscriptionService.getSubscriptionPlans();
    const addons = await subscriptionService.getSubscriptionAddons();
    const customRoles = await rolesService.getCustomRoles(project);
    const builtInRoles = await rolesService.getBuiltInRoles();

    setSubscriptionPlans(plans);
    setSubscriptionAddons(addons);
    setSegments(segments);
    setCustomAttributes(customAttributes);
    setCustomRoles(customRoles);
    setBuiltInRoles(builtInRoles);
    if (Environment.NODE_ENV === 'production' && project != null) {
      if (project != null && project.subscription != null) {
        pushAttributes({
          plan: project.subscription.plan,
          isTrialing: hasFlag(F_IS_TRIALING, project.subscription.extraFlags),
          isSaasMantra: hasSaasMantraPlan(project),
          hasReachedMau: project.mau >= project.thresholdJimers,
          hasWhiteLabeling: project.whiteLabeling,
          isPaying: isPaying(project.subscription),
          hasTrialEnded:
            isEligibleToTrial() === false && isTrying(project.subscription),
          hasInstalled: project.snippetInstalledAt != null,
        });
      }
    }
  };

  const fontFamilySet = new Set();

  // TODO update this optionValue on dalaran

  const addFontFamily = (rawFontFamily = '') => {
    const fontFamilies = rawFontFamily.split(',');
    const fontFamily = fontFamilies[0].split(' ').join('+');
    if (
      fontFamilySet.has(fontFamily) === false &&
      nativeFontFamilies.includes(rawFontFamily) === false
    ) {
      try {
        addStylesheetURL(
          `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;500;600;700`
        );
        fontFamilySet.add(fontFamily);
      } catch (e) {
        console.error(e);
      }
    }
  };

  function addStylesheetURL(url) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const queryClient = new QueryClient();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <GlobalContext.Provider
      value={{
        modalWelcomePlan,
        setModalWelcomePlan,

        modalFeedbackCancelPlan,
        setModalFeedbackCancelPlan,

        modalPlanCancelDone,
        setModalPlanCancelDone,

        modalPlanPreview,
        setModalPlanPreview,

        modalPlanDowngrade,
        setModalPlanDowngrade,

        modalPlanUpgrade,
        setModalPlanUpgrade,

        modalSubscriptionCheckout,
        setModalSubscriptionCheckout,

        modalAddonCheckout,
        setModalAddonCheckout,

        modalAddonPurchased,
        setModalAddonPurchased,

        modalAddonCancel,
        setModalAddonCancel,

        addFontFamily,
      }}>
      <ErrorBoundary FallbackComponent={CommonEB}>
        <Router>
          <JimoNavigation />
          <QueryClientProvider client={queryClient}>
            <div className="app-route-wrapper">
              <PublicOnlyRoute
                exact
                component={OnboardingLogin}
                path={ROUTE_LOGIN}
                layout={OnboardingLayout}
                title="Sign in"
                bodyOpts={{hasOnboardingBgColor: true}}></PublicOnlyRoute>

              {/* Change to public only */}
              <PublicRoute
                exact
                component={OnboardingRegister}
                path={[ROUTE_REGISTER, ROUTE_REGISTER_ONBOARDED]}
                title="Register"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicOnlyRoute
                exact
                component={OnboardingRegisterEmailSent}
                path={ROUTE_REGISTER_EMAIL_SENT}
                title="Register"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicOnlyRoute>
              <PublicRoute
                exact
                component={OnboardingRegisterSetupPassword}
                path={ROUTE_REGISTER_SETUP_PASSWORD}
                title="Register"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              {/* Change to public only */}

              {/* Onboarding */}
              <PublicRoute
                exact
                component={OnboardingDetailsStep11}
                path={ROUTE_ONBOARDING_DETAILS_STEP_1_1}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingDetailsStep12}
                path={ROUTE_ONBOARDING_DETAILS_STEP_1_2}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingDetailsJoin}
                path={ROUTE_ONBOARDING_DETAILS_JOIN}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingDetailsStep21}
                path={ROUTE_ONBOARDING_DETAILS_STEP_2_1}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingDetailsStep22}
                path={ROUTE_ONBOARDING_DETAILS_STEP_2_2}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingDetailsStep23}
                path={ROUTE_ONBOARDING_DETAILS_STEP_2_3}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingDetailsStep30}
                path={ROUTE_ONBOARDING_DETAILS_STEP_ONBOARDING}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}
              />
              <PublicRoute
                exact
                component={OnboardingDetailsStep31}
                path={ROUTE_ONBOARDING_DETAILS_STEP_3_1}
                title="Tell us about you"
                layout={OnboardingLayout}
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicOnlyRoute
                exact
                component={LoginWithCode}
                path={ROUTE_LOGIN_WITH_CODE}
                layout={EmptyLayout}
                title="Sign in with code"
                bodyOpts={{hasOnboardingBgColor: true}}></PublicOnlyRoute>
              <PublicOnlyRoute
                exact
                component={ForgotPass}
                layout={EmptyLayout}
                path={ROUTE_RECOVERY_PASSWORD}
                title="Forgot pass"
                bodyOpts={{hasOnboardingBgColor: true}}></PublicOnlyRoute>
              <PublicRoute
                exact
                component={OnboardingJoin}
                path={ROUTE_JOIN(':invitationCode')}
                layout={EmptyLayout}
                title="Join"
                bodyOpts={{hasOnboardingBgColor: true}}></PublicRoute>
              <PublicRoute
                exact
                component={OnboardingWaiting}
                path={ROUTE_ONBOARDING_WAITING}
                layout={EmptyLayout}
                title="Access requested!"
                bodyOpts={{hasOnboardingBgColor: true}}
                checkWaiting={false}></PublicRoute>
              <PrivateRoute
                exact
                component={OnboardingProject}
                path={ROUTE_ONBOARDING_PROJECT}
                layout={EmptyLayout}
                title="New project"
                checkHasOneProject={false}
                bodyOpts={{hasOnboardingBgColor: true}}
              />
              <PublicRoute
                exact
                component={OnboardingLogout}
                path={ROUTE_LOGOUT}
                layout={EmptyLayout}
                title="See you soon"></PublicRoute>

              {/* Home */}
              <PrivateRoute
                exact
                component={function Home() {
                  const history = useHistory();

                  const project = useSelector((state) =>
                    generalSelector.getProject(state)
                  );
                  const gsTryDone = [
                    hasFlags(
                      [
                        F_EXTRA_FIRST_POKE_ADOPTION,
                        F_EXTRA_FIRST_POKE_DISCOVERY,
                        F_EXTRA_FIRST_POST_PORTAL,
                      ],
                      project?.extraFlags,
                      true
                    ),
                    isInstalled === true,
                  ];
                  const gsInstallDone = [project.snippetInstalledAt != null];
                  const getStartedPercentage = Math.round(
                    ([
                      gsTryDone.some((t) => t === true),
                      gsInstallDone.every((t) => t === true),
                    ].filter((t) => t === true).length /
                      2) *
                      100
                  );
                  // Redirect to Get Started when accessing home while trial not started yet
                  if (window.location.pathname === '/') {
                    if (
                      hasFlag(F_IS_TRIALING, project.subscription.extraFlags) ||
                      getStartedPercentage < 100
                    ) {
                      history.push(ROUTE_GET_STARTED);
                    } else {
                      history.push(ROUTE_TOURS);
                    }
                  }
                  return <></>;
                }}
                path={ROUTE_HOME}
                title="Home"></PrivateRoute>
              {/* Get Started */}
              <PrivateRoute
                exact
                component={GetStarted}
                path={ROUTE_GET_STARTED}
                title="Get started"
              />
              <PrivateRoute
                exact
                component={GetStartedTry}
                path={ROUTE_GET_STARTED_TRY}
                title="Try"
              />
              <PrivateRoute
                exact
                component={GetStartedInstall}
                path={ROUTE_GET_STARTED_INSTALL}
                title="Install"
              />

              <PrivateRoute
                exact
                component={Portal}
                path={[
                  ROUTE_FEED,
                  ROUTE_ROADMAP,
                  ROUTE_DRAFT,
                  ROUTE_IDEAS,
                  ROUTE_DRAFT_WITH_ID(':requestId'),
                ]}
                title="Changelog"
              />
              <PrivateRoute
                exact
                component={PortalPost}
                path={[
                  ROUTE_FEED_EVOLUTION(':evolutionId'),
                  ROUTE_ROADMAP_EVOLUTION(':evolutionId'),
                  ROUTE_BOOSTED_WITH_ID(':evolutionId'),
                ]}
                title="Post"
              />
              <PrivateRoute
                exact
                component={Pushes}
                path={[
                  ROUTE_POKES,
                  ROUTE_TOURS,
                  ROUTE_SURVEYS,
                  ROUTE_BANNERS,
                  ROUTE_HINTS,
                  ROUTE_CHECKLISTS,
                  ROUTE_TOUR_WITH_ID(':evolutionId'),
                  ROUTE_SURVEY_WITH_ID(':evolutionId'),
                  ROUTE_BANNER_WITH_ID(':evolutionId'),
                  ROUTE_HINT_WITH_ID(':evolutionId'),
                  ROUTE_CHECKLIST_WITH_ID(':evolutionId'),
                ]}
                title="Experiences"
              />
              <PrivateRoute
                exact
                component={EmbeddedElementSelectorBuilder}
                layout={EmptyLayout}
                path={[ROUTE_LIGHTWEIGHT_ELEMENT_SELECTOR_BUILDER]}
                title="Lightweight Element Selector Builder"
              />
              <PrivateRoute
                exact
                component={EmbeddedPokeBuilder}
                layout={PokeBuilderLayout}
                path={[
                  ROUTE_LIGHTWEIGHT_POKE_BUILDER,
                  ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID(':evolutionId'),
                ]}
                title="Lightweight Poke Builder"
              />
              <PrivateRoute
                exact
                component={ExtensionBuilder}
                layout={EmptyLayout}
                path={[ROUTE_LIGHTWEIGHT_EXTENSION_BUILDER]}
                title="Lightweight Poke Builder"
              />
              <PrivateRoute
                exact
                component={ChangelogBuilder}
                path={[
                  ROUTE_CHANGELOG_BUILDER,
                  ROUTE_CHANGELOG_BUILDER_WITH_ID(),
                ]}
                title="Setup changelog"
                layout={PostBuilderLayout}
              />
              <PrivateRoute
                exact
                component={PostBuilder}
                path={ROUTE_POST_BUILDER_WRITER()}
                title="New push"
                layout={PostBuilderLayout}
              />
              <PrivateRoute
                exact
                component={PokeAudience}
                path={[
                  ROUTE_TOUR_SETTINGS_WITH_ID(':evolutionId'),
                  ROUTE_SURVEY_SETTINGS_WITH_ID(':evolutionId'),
                  ROUTE_BANNER_SETTINGS_WITH_ID(':evolutionId'),
                  ROUTE_HINT_SETTINGS_WITH_ID(':evolutionId'),
                  ROUTE_CHECKLIST_SETTINGS_WITH_ID(':evolutionId'),
                ]}
                title="Setup audience"
              />
              <PrivateRoute
                exact
                component={PokeBuilder}
                path={[ROUTE_POKE_BUILDER_FROM_TYPE()]}
                title="Edit experience"
                layout={PokeBuilderLayout}
              />

              <PrivateRoute
                exact
                component={PostAudience}
                path={ROUTE_POST_BUILDER_AUDIENCE()}
                title="Setup audience"
              />
              <PublicRoute
                exact
                component={Switch}
                path={ROUTE_SWITCH_PROJECT(':projectId')}
                title="Switching to another project"
                layout={EmptyLayout}></PublicRoute>
              <PrivateRoute
                exact
                path={[ROUTE_USERS, ROUTE_SEGMENTS, ROUTE_USERS_IMPORTS]}
                component={Users}
                title="Users"
              />
              <PrivateRoute
                exact
                path={[
                  ROUTE_USERS_IMPORT_CSV,
                  ROUTE_USERS_IMPORT_CSV_WITH_ID(),
                ]}
                component={ImportCSV}
                title="Import CSV"
              />
              <PrivateRoute
                exact
                path={ROUTE_USERS_SEGMENT_WITH_ID()}
                component={Segment}
                title="Segment"
              />

              <PrivateRoute
                exact
                component={SettingsGeneral}
                path={ROUTE_SETTINGS_GENERAL}
                title="General"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={Themes}
                path={ROUTE_SETTINGS_THEMES}
                title="Customize your appearance"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SettingsTheme}
                path={ROUTE_SETTINGS_THEMES_ID()}
                title="Theme"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={Installation}
                path={[ROUTE_SETTINGS_INSTALLATION]}
                title="Install Jimo"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={Identification}
                path={[ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION]}
                title="Setup identification"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SettingsIntegrations}
                path={[
                  ROUTE_SETTINGS_INTEGRATIONS,
                  ROUTE_SETTINGS_INTEGRATIONS_TYPEFORM,
                ]}
                title="Integrations"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={HubspotSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT]}
                title="Hubspot integration"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SegmentioSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_SEGMENT]}
                title="Segmentio integration"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={IntercomSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_INTERCOM]}
                title="Intercom integration"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={ZapierSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_ZAPIER]}
                title="Zapier integration"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SlackSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_SLACK]}
                title="Slack integration"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={CrispSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_CRISP]}
                title="Crisp integration"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={IntegrationCioSettings}
                path={[ROUTE_SETTINGS_INTEGRATIONS_CIO]}
                title="Customerio Integration Settings"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SettingsNotification}
                path={ROUTE_SETTINGS_NOTIFICATION}
                title="Notifications"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}></PrivateRoute>
              <PrivateRoute
                exact
                component={SettingsTeam}
                path={ROUTE_SETTINGS_TEAM}
                title="Team"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}></PrivateRoute>
              <PrivateRoute
                exact
                component={SettingsProfile}
                path={ROUTE_SETTINGS_PROFILE}
                title="Set your profile"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}></PrivateRoute>
              <PrivateRoute
                exact
                component={Subscription}
                path={[ROUTE_SETTINGS_SUBSCRIPTION_COUPON]}
                title="Plan &amp; Billing"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SettingsPlan}
                path={[ROUTE_SETTINGS_PLAN]}
                title="Plan"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SettingsBilling}
                path={[ROUTE_SETTINGS_BILLING]}
                title="Billing"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={Control}
                path={ROUTE_SETTINGS_POKE_CONTROL}
                title="Control"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={Environments}
                path={ROUTE_SETTINGS_ENVIRONMENTS}
                title="Environments"
                layout={SettingsLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={SuccessTracker}
                path={[ROUTE_SUCCESS_TRACKER, ROUTE_EVENTS]}
                title="Success Tracker"
              />
              <PrivateRoute
                exact
                component={Tracker}
                path={[ROUTE_TRACKER_REPORT()]}
                title="Tracker Report"
              />
              <PrivateRoute
                exact
                component={TrackerBuilder}
                path={[ROUTE_BUILDER_TRACKER()]}
                title="Tracker Builder"
              />
              <PrivateRoute
                exact
                component={Event}
                path={[ROUTE_EVENTS_WITH_ID()]}
                title="Event"
              />
              <PrivateRoute
                exact
                component={Templates}
                path={ROUTE_TEMPLATES}
                title="Templates"
                layout={TemplatesLayout}
                bodyOpts={{hasSettingsBgColor: true}}
              />
              <PrivateRoute
                exact
                component={IntercomRedirect}
                path={ROUTE_REDIRECT_INTERCOM}
                title=""
              />

              {/* Only for local env */}
              <PublicRoute
                exact
                component={_dev}
                path={ROUTE_DEV}
                layout={EmptyLayout}
                title="_dev"
              />

              {/* <PrivateRoute
              exact
              component={function Home() {
                const history = useHistory();

                useEffect(() => {
                  history.push(ROUTE_HOME);
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, []);
                return <></>;
              }}
              path="*"
              title="Home"
            /> */}
            </div>
          </QueryClientProvider>
        </Router>
        <MobileOverlay />

        {/* Modals */}
        {modalWelcomePlan != null && <ModalWelcomePlan />}
        {modalFeedbackCancelPlan != null && <ModalFeedbackCancelPlan />}
        {modalPlanCancelDone === true && <ModalPlanCancelDone />}
        {modalPlanDowngrade != null && <ModalPlanDowngrade />}
        {modalPlanUpgrade != null && <ModalPlanUpgrade />}
        {modalPlanPreview != null && <ModalPlanPreview />}
        {modalSubscriptionCheckout != null && (
          <Elements stripe={stripePromised}>
            <ModalSubscriptionCheckout />
          </Elements>
        )}
        {modalAddonCheckout != null &&
          (modalAddonCheckout.addonId === ADDON_MAU ? (
            <ModalMauAddonCheckout />
          ) : (
            <ModalAddonCheckout />
          ))}
        {modalAddonPurchased != null && <ModalAddonPurchased />}
        {modalAddonCancel != null && <ModalAddonCancel />}
      </ErrorBoundary>
    </GlobalContext.Provider>
  );
};

export default App;
