import {
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_PRIMARY_CTA,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import {getDefaultBlockFromType} from '../BlockManager/utils';

export const presets = [
  {
    uid: '2fdcb876-de5e-40fe-9143-8904042e50bc',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4329481,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T02:48:27.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:39:37.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '0;0;3',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '48629a9a-e1a5-4ff7-808a-108901fdc7d0',
    boostFlags: 128,
    steps: [
      {
        uid: '1652874b-099b-4e8a-9f2c-a7d8dac583cb',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Introduce a feature',
        description: 'Highlight new features effortlessly',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T02:48:27.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '0087fd8c-e85a-4218-ab86-c7a4e1f47007',
            type: 'BODY',
            value: '<p>Simple information about the feature</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about the feature',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T02:48:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '723cc220-3c0b-4596-a1ea-6505ac74634e',
            type: 'TITLE',
            value: 'Introducing Tags! ✨|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T02:50:04.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'fe5b00d3-abae-44c1-8be6-47b4c426d37d',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'label',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T02:48:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '33659204-7051-4a87-acde-3b0017e9a973',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4329481,
    lastStepChangeAt: '2024-03-19T03:34:39.000Z',
    createdAt: '2024-03-19T03:32:56.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:39:37.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '3;3;0',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '48629a9a-e1a5-4ff7-808a-108901fdc7d0',
    boostFlags: 128,
    steps: [
      {
        uid: '8f48c3b8-955b-4610-803c-1e35a8da3e36',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Feature status description',
        description: 'Clearly explain feature statuses to users',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:32:56.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '06785aed-004e-4284-97b7-139fd2b1ce74',
            type: 'BODY',
            value:
              '<p>A one-liner about the current status or results visible on the page.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'A one-liner about the current status or results visible on the page.',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:32:57.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '0d2d1e22-9fc7-450a-9a94-8a94dc7b4707',
            type: 'TITLE',
            value: 'Hidden element information|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T03:32:57.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '627bb117-e949-4ac6-bde2-4c341bea0335',
            type: 'SECONDARY_CTA',
            value: 'View docs;none;url-open;https://yourdocumentation.com',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 16,
              fontColor: '#000000',
              fontFamily: 'Inter',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#e8ebf8ff',
            },
            createdAt: '2024-03-19T03:32:57.000Z',
            removed: true,
            file: null,
            actions: [
              {
                uid: uuidv4(),
                type: STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
                value: 'https://yourdocumentation.com',
                flags: 1,
              },
            ],
          },
          {
            uid: 'ed4895ea-de4c-4ec7-95f2-5e46d1aaadd4',
            type: 'HINT',
            value: 'Hidden element',
            rawValue: null,
            flags: 0,
            style: {
              type: 'label',
              color: '#3f3b3bff',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#e8e8e8ff',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:32:57.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '49c6b37e-ffd1-4962-ae8e-3bd1a15a0b7b',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4329481,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T02:53:46.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:39:37.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '2;2;1',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '48629a9a-e1a5-4ff7-808a-108901fdc7d0',
    boostFlags: 128,
    steps: [
      {
        uid: '994ece6a-6627-40a4-b790-2ded3cb16736',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Simple question and answer',
        description: 'Engage users with straightforward Q&A prompts',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T02:53:46.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '20bf9a30-5ed3-4173-a94c-59b18cae570f',
            type: 'SECONDARY_CTA',
            value: 'View docs;none;url-open;https://yourdocumentation.com',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 16,
              fontColor: '#000000',
              fontFamily: 'Inter',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#e8ebf8ff',
            },
            createdAt: '2024-03-19T02:55:38.000Z',
            removed: false,
            file: null,
            actions: [
              {
                uid: uuidv4(),
                type: STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
                value: 'https://yourdocumentation.com',
                flags: 1,
              },
            ],
          },
          {
            uid: 'abcec8c0-911a-400c-83fe-f9fbf9b7ffdc',
            type: 'TITLE',
            value: 'How can I create an item?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T02:53:50.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'd360723d-ae0e-4934-bc68-4815e2e0c708',
            type: 'HINT',
            value: 'How?',
            rawValue: null,
            flags: 0,
            style: {
              type: 'label',
              color: '#3f3b3bff',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#e8e8e8ff',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T02:53:50.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'de93252b-8952-439e-9f9b-5847e66943c1',
            type: 'BODY',
            value:
              '<p>Simple information about the feature that answers the question</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about the feature that answers the question',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T02:53:50.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '66e7668e-bb5b-476d-9e89-1572b36b982f',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4329481,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T02:51:13.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:39:37.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '1;1;2',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '48629a9a-e1a5-4ff7-808a-108901fdc7d0',
    boostFlags: 128,
    steps: [
      {
        uid: 'e29b15cc-d23d-451d-9cbb-12f72d905062',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Introduce a feature with media',
        description: 'Showcase your new features with captivating visuals',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T02:51:13.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '37857dae-276c-4878-a854-d4c10c6e0ad2',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'label',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T02:51:15.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '3953212f-e465-4926-9409-8cbcb9fa7cf9',
            type: 'BODY',
            value: '<p>Simple information about the feature</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about the feature',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T02:51:15.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '84baa555-7d39-4c99-a5b2-c87987b19b33',
            type: 'MEDIA',
            value:
              'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Gd67g2FIHaX6.png;logo;fill',
            rawValue: null,
            flags: 0,
            style: {
              height: 160,
              padding: 0,
              position: 'top',
              borderRadius: 12,
            },
            createdAt: '2024-03-19T02:51:18.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'bab61cd8-427f-4c51-9fdd-811627c228bf',
            type: 'TITLE',
            value: 'Introducing Changelog! ✨|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T02:51:15.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '0c20346b-30b9-402a-9909-d6d3dfe32d2b',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: null,
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#071331ff',
        secondaryColor: null,
      },
      hintOffset: 4,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T03:16:30.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:59:03.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '4;4;0',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '8ed67e37-ae84-4b36-82a9-99559c936cac',
    boostFlags: 128,
    steps: [
      {
        uid: '51af35e7-9870-4c1d-9949-b7fcf5373b76',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Dark mode tooltip with title',
        description:
          'Customize tooltips for light background with titles for clarity',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:16:30.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '8752441e-ed9d-4946-8a21-52fe9254883d',
            type: 'BODY',
            value: '<p>Simple information about an element</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about an element',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#b7b7b7ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'b7ab3079-434a-42d0-9792-e8b3c22507d7',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              0: 'N',
              1: 'e',
              2: 'w',
              type: 'icon',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 20,
              iconName: 'help-outline',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 16,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'f78f4bed-3ef9-4d97-804b-2ee8d68ca964',
            type: 'TITLE',
            value: 'Pro Tip 🔥|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#ffffffff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '60471dc6-792c-4e64-ac9d-5cb9d4165175',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 4,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-18T09:33:08.000Z',
    createdAt: '2024-03-19T03:16:29.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:59:03.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: '',
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: 'https://templates.usejimo.com/',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '0;0;4',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '8ed67e37-ae84-4b36-82a9-99559c936cac',
    boostFlags: 128,
    steps: [
      {
        uid: 'ae02fd73-d620-4962-8de4-bd480030a779',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Simple tooltip',
        description: 'Inform users with a simple tooltip',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:16:30.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: 'ba47af39-73a0-440a-b7f2-43f3aa8c46cb',
            type: 'BODY',
            value: '<p>Simple information about an element</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about an element',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#4a4747ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'd5793223-f859-4b7e-8b87-6011b426e4e3',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'icon',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 20,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 16,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'a35362d3-6d72-48eb-bc37-856f79a2c4ef',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: null,
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#071331ff',
        secondaryColor: null,
      },
      hintOffset: 4,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T03:16:30.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:59:03.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '3;3;1',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '8ed67e37-ae84-4b36-82a9-99559c936cac',
    boostFlags: 128,
    steps: [
      {
        uid: 'cc9b537b-411c-4faa-8c01-4a71a71838f5',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Dark mode simple tooltip',
        description:
          'Optimize tooltips for light background with a simple design',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:16:30.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '61b4fe72-9608-4210-8201-5bbd4108c773',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'icon',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 20,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 16,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '95d201fb-45a8-4e90-a0bf-db477ceade8e',
            type: 'BODY',
            value: '<p>Simple information about an element</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about an element',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#b7b7b7ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'd313c6c6-3bb4-4f60-87f8-1c91805b17f0',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 4,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-18T10:55:02.000Z',
    createdAt: '2024-03-19T03:16:30.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:59:03.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: '',
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '2;2;2',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '8ed67e37-ae84-4b36-82a9-99559c936cac',
    boostFlags: 128,
    steps: [
      {
        uid: '9544686d-d0cf-48a2-a406-735af2baa0f2',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Tooltip with media',
        description: 'Enhance user experience with media-rich tooltips',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:16:30.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '0d18d263-1421-4cfe-baa1-3374c27ba6fd',
            type: 'MEDIA',
            value:
              'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-cKJYdEsBxyi9.png;logo;fill',
            rawValue: null,
            flags: 0,
            style: {
              height: 135,
              padding: 0,
              position: 'top',
              borderRadius: 8,
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '80051665-f98b-447c-9ed1-4126d6ca3b93',
            type: 'TITLE',
            value: 'Your congratulations words ✨|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'a8b0bdf0-e7ae-4c9f-86da-1bf2521ac591',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              0: 'N',
              1: 'e',
              2: 'w',
              type: 'icon',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 24,
              iconName: 'new-badge',
              iconColor: '#0b7af4ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 16,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'e08bb2bf-f9af-4e55-8c8f-3aa3ac66436d',
            type: 'BODY',
            value: '<p>Simple explanation about the title</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple explanation about the title',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'e1b7c097-efdc-42e8-a4c3-7032ae95b533',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 4,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-18T10:55:02.000Z',
    createdAt: '2024-03-19T03:16:30.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:59:03.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: '',
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '1;1;3',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '8ed67e37-ae84-4b36-82a9-99559c936cac',
    boostFlags: 128,
    steps: [
      {
        uid: '0f3e2c88-5bd6-46f9-8b70-c50a16820c82',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Tooltip with title',
        description: 'Provide context with a tooltip featuring a title',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:16:30.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '210636f6-d770-4570-9b35-f986b8b91b2d',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              0: 'N',
              1: 'e',
              2: 'w',
              type: 'icon',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 20,
              iconName: 'help-outline',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 16,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '513d8110-73f4-4ca6-8000-c689d709b52f',
            type: 'BODY',
            value: '<p>Simple information about an element</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about an element',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'e3f71cf3-dbf7-48e6-b87e-e2a8c21e469a',
            type: 'TITLE',
            value: 'Pro Tip 🔥|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T03:16:30.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '1bdc7358-737c-4f55-8003-35a3d51a37d7',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-19T03:45:45.000Z',
    createdAt: '2024-03-19T03:44:55.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:49:34.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '2;2;0',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '971156a9-d995-4e7f-aa43-f22f6e1214ad',
    boostFlags: 128,
    steps: [
      {
        uid: '5f7d15ab-54f3-4fbb-9a90-dcd78d1c9855',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Simple tooltip',
        description:
          'Provide quick guidance over the element with a simple tooltip',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:44:55.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: 'afff0c03-8482-4a68-9f37-7894b52b69a8',
            type: 'BODY',
            value: '<p>Simple information about the element</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about the element',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:44:55.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'e254a064-c372-469e-8710-dea1ccd41111',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'hidden',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:44:55.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'e3b3f980-56c9-45a4-bb0d-7c1ad78ece11',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T03:36:16.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:49:34.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '1;1;1',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '971156a9-d995-4e7f-aa43-f22f6e1214ad',
    boostFlags: 128,
    steps: [
      {
        uid: '31a9c82c-d17b-4558-9c7b-44ec1ffe9947',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Tooltip with media',
        description: 'Enhance tooltips with media for richer explanations',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:36:16.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '75b44c10-5b2f-47de-a271-82da08264b98',
            type: 'MEDIA',
            value:
              'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Gd67g2FIHaX6.png;logo;fill',
            rawValue: null,
            flags: 0,
            style: {
              height: 160,
              padding: 0,
              position: 'top',
              borderRadius: 12,
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'a8884aa9-801f-46ec-acfe-9ed107c63945',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'hidden',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'f76516dc-574a-469a-9f36-cc2fcf76326e',
            type: 'BODY',
            value: '<p>Simple information about the feature</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Simple information about the feature',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'fc341196-0deb-4783-a14e-19f18cb54d99',
            type: 'TITLE',
            value: 'Simple Information|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'f26dff90-9981-44ae-813d-23ca582f237a',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 4591625,
    lastStepChangeAt: '2024-03-19T03:15:57.000Z',
    createdAt: '2024-03-19T03:36:16.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-03-19T03:49:34.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 8,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;22;default',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '0;0;2',
    context: null,
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '971156a9-d995-4e7f-aa43-f22f6e1214ad',
    boostFlags: 128,
    steps: [
      {
        uid: '4fb5f5c2-80c0-489e-a7ac-c74b8af018bd',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            color: null,
            opacity: 0,
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Nudge to upgrade',
        description:
          'Encourage users to upgrade with a subtle nudge over the element',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-03-19T03:36:16.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        endSurvey: false,
        blocks: [
          {
            uid: '216c6f2f-a10b-4b9e-b192-58e09d1f0fda',
            type: 'TITLE',
            value: 'Get access to this feature 🔐|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 18,
              fontColor: '#000000',
              fontFamily: 'Inter',
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '22515fda-14a3-4c9c-a176-3ca412e5a061',
            type: 'HINT',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              type: 'hidden',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: 0,
              offsetY: 0,
              padding: 8,
              fontSize: 16,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: true,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#FF4646',
              horizontalAlign: 'right',
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: '74521561-213a-411a-af74-e6d62515d257',
            type: 'USER',
            value:
              'John Doe;CPO @Jimo;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-Kn8kBdjC3SMs.jpg;null',
            rawValue: null,
            flags: 0,
            style: {
              borderRadius: 6,
            },
            createdAt: '2024-03-19T03:45:21.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
          {
            uid: 'e701d755-5362-465c-9bca-b963d68e9feb',
            type: 'BODY',
            value:
              '<p>Information that the referred feature isn&apos;t part of user&apos;s plan</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: "Information that the referred feature isn't part of user's plan",
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-03-19T03:36:16.000Z',
            removed: false,
            updateData: null,
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '23959add-717d-47d7-9d92-1a5d0ba57169',
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#00000000',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 2097161,
    lastStepChangeAt: '2024-01-05T11:07:04.000Z',
    createdAt: '2024-01-05T11:04:02.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-01-05T11:42:33.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 0,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;24;default;',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '3;9;0',
    context: null,
    type: 'TOOLTIP',
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '5de32201-2d04-4832-84f8-93f68494cf6c',
    boostFlags: 32,
    steps: [
      {
        uid: '5219f94c-7f3e-4b59-a942-d74b368d0f80',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'With button',
        description:
          'Offer additional context and direct users towards an action',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T11:04:02.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            uid: '0be0f8b8-18e2-4c3b-b72d-6a88b5d0652f',
            type: 'TITLE',
            value: 'Customize your workspace|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T11:04:02.000Z',
            file: null,
          },
          {
            uid: '81a027d7-7103-454d-b7c2-b2c0d61868e8',
            type: 'BODY',
            value:
              '<p>Customize this page freely according to your team needs</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Customize this page freely according to your team needs',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T11:04:02.000Z',
            file: null,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: 'd6e98f42-d509-473e-93bb-ca035e22d741',
            type: 'PRIMARY_CTA',
            value: 'Got it;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T11:04:02.000Z',
            file: null,
          },
        ],
      },
      {
        uid: '63d07549-b2b7-4f98-ba53-7d9f812b59f1',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'With label',
        description: 'Enhance user understanding with labeled tooltip',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T11:05:53.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            uid: '1b4fc9e9-a5a3-4de3-af23-a3955bfa3821',
            type: 'LABEL',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 8,
              fontSize: 14,
              fontColor: '#ff0000ff',
              fontFamily: 'Montserrat',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#da31311a',
            },
            createdAt: '2024-01-05T11:06:18.000Z',
            file: null,
          },
          {
            uid: '1be70b92-cbdb-4874-9b74-d1fffd10f160',
            type: 'TITLE',
            value: 'Customize your workspace|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T11:05:54.000Z',
            file: null,
          },
          {
            uid: '8aacb031-b2ae-496a-a76c-619b25d926dc',
            type: 'BODY',
            value:
              '<p>Customize this page freely according to your team needs</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Customize this page freely according to your team needs',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T11:05:54.000Z',
            file: null,
          },
        ],
      },
      {
        uid: 'd910b976-22a3-4868-8a52-562bb5911a41',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'With media',
        description: 'Provide detailed information with media content tooltip',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T11:04:52.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            uid: '05888a0a-8092-420a-ae06-52e2800d70b2',
            type: 'TITLE',
            value: 'Customize your workspace|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T11:04:52.000Z',
            file: null,
          },
          {
            uid: '3be92903-3b2c-4e59-9ff4-eb931fdbd657',
            type: 'MEDIA',
            value:
              'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-JRvIow1Xlr39.png;logo;fill',
            rawValue: null,
            flags: 0,
            style: {padding: 20, position: 'top', borderRadius: 16},
            createdAt: '2024-01-05T11:05:16.000Z',
            file: null,
          },
          {
            uid: 'dc5d6916-f265-45fb-8cf9-27e0e793d7d3',
            type: 'BODY',
            value:
              '<p>Customize this page freely according to your team needs</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Customize this page freely according to your team needs',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T11:04:52.000Z',
            file: null,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: 'fc5da333-143a-4a21-826f-22c07db39de4',
            type: 'PRIMARY_CTA',
            value: 'Got it;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T11:04:52.000Z',
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '48a0dc6d-8981-4802-836f-f02dae1716e2',
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#00000000',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 2097161,
    lastStepChangeAt: '2024-01-05T11:07:04.000Z',
    createdAt: '2024-01-05T10:54:11.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-01-05T11:42:33.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 64,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;24;default;',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '1;3;6',
    context: null,
    type: 'SNIPPET',
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '5de32201-2d04-4832-84f8-93f68494cf6c',
    boostFlags: 4,
    steps: [
      {
        uid: '0959bde6-f8ce-44dd-aa00-da5edf613a3d',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Nudge users for a tour',
        description: 'Encourage users with a subtle tour nudge',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T10:59:26.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '31ffe3b4-9f33-4fc5-9d90-1d93f85f1b9c',
            type: 'PRIMARY_CTA',
            value: 'Take me there;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T10:59:27.000Z',
            file: null,
          },
          {
            uid: '5d892a7e-cf3e-44dc-973d-9bc75fe2bf16',
            type: 'BODY',
            value:
              '<p>Let me take you to a tour for utilizing our app better</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Let me take you to a tour for utilizing our app better',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T10:59:27.000Z',
            file: null,
          },
          {
            uid: 'db8ac2fb-08c1-438f-b5b3-ff4eaa5edce5',
            type: 'MEDIA',
            value: 'image;;logo;fill',
            rawValue: null,
            flags: 0,
            style: {padding: 20, position: 'top', borderRadius: 16},
            createdAt: '2024-01-05T11:00:16.000Z',
            file: null,
          },
          {
            uid: 'f5c8c0e7-9e22-4d35-81d8-975351e27817',
            type: 'TITLE',
            value: 'Ready to master productivity using our app?|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T10:59:27.000Z',
            file: null,
          },
        ],
      },
      {
        uid: '5e651e74-bada-4a34-8170-a1da65df0ace',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Greet with personal touch',
        description: 'Welcome users with a personalized touch from your team',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T10:54:11.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            uid: '61a146dd-8248-4844-9b0b-eb4823efbdf9',
            type: 'BODY',
            value:
              '<p>I&#x2019;d like to take you through our app and how to best use our product. You will get the knowledge of how can you maximize utilizing our product.</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'I’d like to take you through our app and how to best use our product. You will get the knowledge of how can you maximize utilizing our product.',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T10:54:11.000Z',
            file: null,
          },
          {
            uid: '7e8ef839-e1cb-4009-b414-0384f0058d82',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;null',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8},
            createdAt: '2024-01-05T10:54:24.000Z',
            file: null,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '7ff41b0c-4acd-45a5-a905-4895d07c5566',
            type: 'PRIMARY_CTA',
            value: "Let's go!;step-next;none;",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T10:54:11.000Z',
            file: null,
          },
          {
            uid: '8fefab78-16b2-4480-b31f-89b9509ebffc',
            type: 'TITLE',
            value: "Hey, I'm John Doe, CPO at Lance 👋|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T10:54:11.000Z',
            file: null,
          },
        ],
      },
      {
        uid: '97249ee1-ed03-4c11-bfd7-f554adda2c65',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Welcome with media',
        description: 'Engage users from the start with a media to welcome them',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T10:57:37.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '3a8a7adc-dd7d-4394-9de7-ef63c5a75ba1',
            type: 'PRIMARY_CTA',
            value: 'Got it;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T10:57:37.000Z',
            file: null,
          },
          {
            uid: '8d9658ab-e82d-491c-bf9f-be4cb667503a',
            type: 'TITLE',
            value: 'Welcome to Company Name! 🎉|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T10:57:37.000Z',
            file: null,
          },
          {
            uid: 'a03e9823-132b-4ddb-93a2-cfc0acde8485',
            type: 'BODY',
            value:
              '<p>We&#x2019;re kind people who make great products for our users</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'We’re kind people who make great products for our users',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T10:57:37.000Z',
            file: null,
          },
          {
            uid: 'd9e8f5ed-ef9a-4af6-8983-ab734cca269b',
            type: 'MEDIA',
            value: 'image;;logo;fill',
            rawValue: null,
            flags: 0,
            style: {padding: 20, position: 'top', borderRadius: 16},
            createdAt: '2024-01-05T10:59:20.000Z',
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'acfe3e73-f39c-445e-9e55-32375c67354e',
    style: {
      width: null,
      height: null,
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 264201,
    lastStepChangeAt: '2024-01-04T08:16:42.000Z',
    createdAt: '2024-01-04T08:16:21.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-01-05T11:42:33.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 64,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;24;default;',
    boostedSize: null,
    boostedAnimations: 'fade-in;fade-out',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '0;0;9',
    context: null,
    type: 'MODAL',
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '5de32201-2d04-4832-84f8-93f68494cf6c',
    boostFlags: 2,
    steps: [
      {
        uid: '0444cba3-1afb-45f7-8d89-47770459846c',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Welcome with video',
        description: 'Delight users with a warm video welcome from your team',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T08:22:59.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '44a40f3e-cb01-4646-a2c7-5554f40cc44b',
            type: 'PRIMARY_CTA',
            value: 'Call to action;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-04T08:22:59.000Z',
            file: null,
          },
          {
            uid: '57ee6755-4688-4359-985d-6df8ce8aa309',
            type: 'BODY',
            value: '<p>Give your viewers engaging context on your app</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Give your viewers engaging context on your app',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'center',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-04T08:22:59.000Z',
            file: null,
          },
          {
            uid: 'a0681106-6de9-4e3d-b611-c3a88bdab480',
            type: 'MEDIA',
            value: 'video;https://www.youtube.com/watch?v=1kYXkKqsCaA;',
            rawValue: null,
            flags: 0,
            style: {
              height: 350,
              padding: 20,
              position: 'top',
              borderRadius: 16,
            },
            createdAt: '2024-01-04T08:23:21.000Z',
            file: null,
          },
          {
            uid: 'fed2b13b-cea0-4a23-8a3d-3160c76d884c',
            type: 'TITLE',
            value: 'Your warm welcome|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'center',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T08:22:59.000Z',
            file: null,
          },
        ],
      },
      {
        uid: '3c77d772-4db7-44ab-8cc0-45febbe50b90',
        style: {
          width: null,
          height: 628,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Welcome with image',
        description:
          'Make a memorable first impression with an image welcome in a modal',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-04T08:16:21.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '56a6f793-2f13-4e87-9e99-3d00c602230e',
            type: 'PRIMARY_CTA',
            value: 'Call to action;step-next;url-open;https://usejimo.com',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-04T08:16:21.000Z',
            file: null,
            actions: [
              {
                uid: uuidv4(),
                type: STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
                value: 'next-step',
              },
              {
                uid: uuidv4(),
                type: STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
                value: 'https://usejimo.com',
                flags: 1,
              },
            ],
          },
          {
            uid: '906d06a0-0586-4438-96b1-e1ac87f42c47',
            type: 'MEDIA',
            value: 'image;;logo;fill',
            rawValue: null,
            flags: 0,
            style: {
              height: 320,
              padding: 20,
              position: 'top',
              borderRadius: 16,
            },
            createdAt: '2024-01-04T08:21:05.000Z',
            file: null,
          },
          {
            uid: 'c799fbf6-c0ac-453f-b7e0-29f22da85b40',
            type: 'BODY',
            value: '<p>Give your viewers engaging context on your app</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Give your viewers engaging context on your app',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-04T08:16:21.000Z',
            file: null,
          },
          {
            uid: 'fd15d16d-d10a-400b-a8f1-94b6c93a258d',
            type: 'TITLE',
            value: 'Your warm welcome|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-04T08:16:21.000Z',
            file: null,
          },
        ],
      },
      {
        uid: 'a4837aee-8d6e-4863-ba82-d64a51bf9837',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'Welcome with personal touch',
        description: 'Connect personally with users through a modal welcome',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T10:52:19.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          {
            uid: '550bea79-a550-4aa7-928b-b28174ae3b6d',
            type: 'TITLE',
            value: "Hi! Let's welcome new users using personal touch|-|none|-|",
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T10:52:20.000Z',
            file: null,
          },
          {
            uid: '7636bbea-e2d4-478d-ae83-47c8e07be0d3',
            type: 'USER',
            value:
              'John Doe;CPO @Lance;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-TG8OhuVr0GfI.jpg;null',
            rawValue: null,
            flags: 0,
            style: {borderRadius: 8},
            createdAt: '2024-01-05T10:53:13.000Z',
            file: null,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '9ae8cb14-57be-4fce-a752-3f57095a4dce',
            type: 'PRIMARY_CTA',
            value: 'Continue;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T10:52:20.000Z',
            file: null,
          },
          {
            uid: 'b3b394fb-372d-4621-bf7b-6cbb182437c3',
            type: 'BODY',
            value: '<p>Greet your users like you met them face to face</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Greet your users like you met them face to face',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T10:52:20.000Z',
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: 'd4c38f12-cee3-4765-87a1-60848a3c7081',
    style: {
      margin: 0,
      shadow: {x: '0', y: '0', blur: '0', color: '#316eda00'},
      overlay: '#0000004d',
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#4688ffff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      animationOut: 'fade',
      borderRadius: 16,
      withElementsAnimations: true,
    },
    title: 'New poke',
    description: null,
    content: null,
    rawContent: null,
    step: null,
    optionsFlags: 2097161,
    lastStepChangeAt: '2024-01-05T11:07:04.000Z',
    createdAt: '2024-01-05T11:00:42.000Z',
    typeformFormId: null,
    mazeUrl: null,
    ctaLabel: null,
    ctaColor: null,
    ctaBackgroundColor: null,
    ctaBorderRadius: null,
    ctaUrl: null,
    analyticViewsCount: 0,
    analyticClicksCount: 0,
    boostedAt: '2024-01-05T11:42:33.000Z',
    expiresAt: null,
    isDraft: true,
    boostedUrl: null,
    boostedDelay: 0,
    boostedQueryselector: null,
    boostedPositionFlags: 64,
    boostedPositionOffsets: '0;0',
    boostedTextsColors: '#071331;#071331',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedContentFontSize: '14',
    boostedTitleFontSize: '18',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedPathOperator: 'OR',
    boostedDomainFilter: null,
    boostedDotStyle: '#1260EB;24;default;pulse',
    boostedSize: null,
    boostedAnimations: 'slide;slide;left;left',
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    icon: 'DEFAULT',
    triggerType: 'DEFAULT',
    state: 'LIVE',
    sectionIndexOrder: 0,
    recurrencyType: 'SINGLE_TIME',
    recurrencyValue: 1,
    tourStepInfo: '2;6;3',
    context: null,
    type: 'HOTSPOT',
    deviceFlags: 1,
    priority: 0,
    boostedById: null,
    isBoostOfId: null,
    themeId: null,
    parentTourId: '5de32201-2d04-4832-84f8-93f68494cf6c',
    boostFlags: 8,
    steps: [
      {
        uid: '130d5e36-e3bf-4dbf-b179-c344955a2f01',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'With label',
        description: 'Highlight key areas with labeled content',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 2,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T11:03:20.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            uid: '8078025a-3f48-4f74-b502-f711a5c49491',
            type: 'TITLE',
            value: 'Customize your workspace|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T11:03:20.000Z',
            file: null,
          },
          {
            uid: '81666241-e1d7-49ed-8960-0244bd311411',
            type: 'LABEL',
            value: 'New',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 8,
              fontSize: 14,
              fontColor: '#ff0000ff',
              fontFamily: 'Montserrat',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#da31311a',
            },
            createdAt: '2024-01-05T11:03:41.000Z',
            file: null,
          },
          {
            uid: 'dca4d1ff-f04f-48d1-940f-121df0f8c645',
            type: 'BODY',
            value:
              '<p>Customize this page freely according to your team needs</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Customize this page freely according to your team needs',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T11:03:20.000Z',
            file: null,
          },
        ],
      },
      {
        uid: '4ee5d7ea-4a97-4a7b-8e76-b5f2d2ee9398',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'With media',
        description: 'Spotlight important details with media content',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 1,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T11:02:17.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            uid: '1cdda109-6a0f-4800-8703-3bd948c686d2',
            type: 'TITLE',
            value: 'Customize your workspace|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T11:02:17.000Z',
            file: null,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '1dfe2bf7-ce25-4476-9779-73e80c885f80',
            type: 'PRIMARY_CTA',
            value: 'Got it;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T11:02:17.000Z',
            file: null,
          },
          {
            uid: '3b7800cb-80f4-4afc-8899-3639f573664a',
            type: 'BODY',
            value:
              '<p>Customize this page freely according to your team needs</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Customize this page freely according to your team needs',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T11:02:17.000Z',
            file: null,
          },
          {
            uid: '91e6a852-ebf8-46ca-bb0a-203b50ba87a0',
            type: 'MEDIA',
            value:
              'image;https://s3.eu-west-3.amazonaws.com/jimo.stage.projects-files-public/eeafde6c6e185c61d0b0e4b0102bca8c-JRvIow1Xlr39.png;logo;fill',
            rawValue: null,
            flags: 0,
            style: {padding: 20, position: 'top', borderRadius: 16},
            createdAt: '2024-01-05T11:02:50.000Z',
            file: null,
          },
        ],
      },
      {
        uid: '62c2098b-7430-45d8-b23d-e1aa032cf5e3',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {x: 0, y: 0, blur: 0, color: null, opacity: 0},
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          animationOut: 'fade',
          borderRadius: 0,
          withElementsAnimations: true,
        },
        name: 'With button',
        description: 'Grab attention and direct users towards an action',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-01-05T11:00:42.000Z',
        mazeUrl: null,
        stepFlags: 0,
        endSurvey: false,
        blocks: [
          getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
            uid: '071a2e88-c37d-40d5-a51f-6742d5a41c56',
            type: 'PRIMARY_CTA',
            value: 'Got it;step-next;none;',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              padding: 12,
              fontSize: 15,
              fontColor: '#ffffffff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderColor: null,
              borderRadius: 24,
              primaryColor: '#0038ffff',
            },
            createdAt: '2024-01-05T11:00:42.000Z',
            file: null,
          },
          {
            uid: '7fc83eca-6e15-419b-88c4-92b924ff49e5',
            type: 'BODY',
            value:
              '<p>Customize this page freely according to your team needs</p>',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Customize this page freely according to your team needs',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 16,
              fontColor: '#545454ff',
              fontFamily: 'Inter',
            },
            createdAt: '2024-01-05T11:00:42.000Z',
            file: null,
          },
          {
            uid: '9ce2f61a-cfc2-44bb-93a6-6c9cd518a8e0',
            type: 'TITLE',
            value: 'Customize your workspace|-|none|-|',
            rawValue: null,
            flags: 0,
            style: {
              align: 'left',
              fontSize: 24,
              fontColor: '#000000ff',
              fontFamily: 'Montserrat',
              fontWeight: '600',
            },
            createdAt: '2024-01-05T11:00:42.000Z',
            file: null,
          },
        ],
      },
    ],
  },
  {
    uid: '496652ca-e3ac-45e5-ac24-47e4d9a91fd2',
    boostFlags: 128,
    boostedDelay: 0,
    boostedAnimations: 'slide;slide;left;left',
    boostedQueryselector:
      '.w-full:nth-child(1) > .relative > .flex-auto > .flex .text-white',
    boostedPositionFlags: 1024,
    boostedPositionOffsets: '0;0',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedTitleFontSize: '18',
    boostedContentFontSize: '14',
    boostedPaths: [],
    boostedPathOperator: 'OR',
    boostedDotStyle: '#1260EB;22;default',
    boostedActiveUrl: 'https://horizon.examples.usejimo.com/admin/dashboard',
    boostedActiveOperator: 'EVERYWHERE',
    boostedSize: null,
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    optionsFlags: 4329481,
    tourStepInfo: '0;0;2',
    style: {
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      borderColor: null,
      'animation-in': null,
      animationOut: 'fade',
      borderRadius: 16,
      pointerColor: '#0066ffff',
      'animation-out': 'slide-up',
      targetBorderColor: '#0066ffff',
      withElementsAnimations: true,
    },
    context: null,
    steps: [
      {
        uid: '0c365c38-7101-4414-a616-e10396f7e007',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 8,
            blur: 12,
            color: '#0000000f',
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          borderColor: '#0000000f',
          animationOut: 'fade',
          borderRadius: 12,
          backgroundColor: '#FFFFFF',
          withElementsAnimations: true,
        },
        name: 'Basic Button',
        description:
          'Basic button to direct users to a specific page or trigger actions',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-09-23T15:34:45.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-09-23T15:40:47.709Z',
          operation: 'reordered',
        },
        endSurvey: false,
        texts: [],
        thumbnails: [],
        options: [],
        goTo: null,
        prototypes: [],
        blocks: [
          {
            uid: '1cd94ad1-8f8b-487a-b0a7-0e439f06ab06',
            type: 'BODY',
            value: 'Your text here',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Your text here',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-09-23T15:34:46.000Z',
            removed: false,
            updateData: null,
            parentBlockId: null,
            options: [],
            translations: [],
            file: null,
            actions: [],
          },
          {
            uid: 'aa02df1b-676f-468b-a8bb-b152398170d4',
            type: 'HINT',
            value: 'Button',
            rawValue: null,
            flags: 0,
            style: {
              type: 'button',
              color: '#FFFFFF',
              iconUrl: null,
              offsetX: -72,
              offsetY: 24,
              padding: 12,
              fontSize: 20,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: false,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#466cffff',
              horizontalAlign: 'right',
            },
            createdAt: '2024-09-23T15:34:46.000Z',
            removed: false,
            updateData: null,
            parentBlockId: null,
            options: [],
            translations: [],
            file: null,
            actions: [
              {
                uid: '1dc795e8-c40f-41c6-a3e4-a783d8374c4c',
                type: 'LAUNCH_EXPERIENCE',
                value: '51fd90da-f6af-4a54-a433-a1e1b87c4bea',
                rawValue: null,
                flags: 0,
                createdAt: '2024-09-23T15:58:02.000Z',
                evolutionId: null,
                stepId: null,
                stepTriggerId: null,
                blockId: null,
              },
            ],
          },
        ],
        triggers: [],
      },
    ],
    parentTourId: 'a4b0f806-fbea-4b25-bcc8-45f9eeb41839',
    querySelectorManual: null,
  },
  {
    uid: '75ca976d-e102-481f-8dc4-9dbade07052e',
    boostFlags: 128,
    boostedDelay: 0,
    boostedAnimations: 'slide;slide;left;left',
    boostedQueryselector:
      '.w-full:nth-child(3) > .relative > .flex-auto > .flex .font-semibold',
    boostedPositionFlags: 1024,
    boostedPositionOffsets: '0;0',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedTitleFontSize: '18',
    boostedContentFontSize: '14',
    boostedPaths: [],
    boostedPathOperator: 'OR',
    boostedDotStyle: '#1260EB;22;default',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedSize: null,
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    optionsFlags: 4329481,
    tourStepInfo: '2;2;0',
    style: {
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      borderColor: null,
      'animation-in': null,
      animationOut: 'fade',
      borderRadius: 16,
      pointerColor: '#0066ffff',
      'animation-out': 'slide-up',
      targetBorderColor: '#0066ffff',
      withElementsAnimations: true,
    },
    context: null,
    steps: [
      {
        uid: '0263ae57-a07f-467a-8eab-dfc6f654ebb5',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 8,
            blur: 12,
            color: '#0000000f',
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          borderColor: '#0000000f',
          animationOut: 'fade',
          borderRadius: 12,
          backgroundColor: '#FFFFFF',
          withElementsAnimations: true,
        },
        name: 'Link',
        description:
          'Link like button to direct users to a specific page or trigger actions',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-09-23T15:40:20.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-09-23T15:40:47.720Z',
          operation: 'modified',
        },
        endSurvey: false,
        texts: [],
        thumbnails: [],
        options: [],
        goTo: null,
        prototypes: [],
        blocks: [
          {
            uid: '580f18bd-7bab-4b56-85c2-07567a416ee6',
            type: 'HINT',
            value: 'Button',
            rawValue: null,
            flags: 0,
            style: {
              type: 'button',
              color: '#000000ff',
              iconUrl: null,
              offsetX: -72,
              offsetY: 24,
              padding: 12,
              fontSize: 20,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: false,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: null,
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#466cff00',
              horizontalAlign: 'right',
            },
            createdAt: '2024-09-23T15:40:23.000Z',
            removed: false,
            updateData: null,
            parentBlockId: null,
            options: [],
            translations: [],
            file: null,
            actions: [
              {
                uid: '445a9c99-f01e-4cdd-b944-fc683b79977b',
                type: 'NAVIGATE_TO',
                value: '/admin',
                rawValue: [
                  {
                    data: {},
                    type: 'paragraph',
                    object: 'block',
                    children: [
                      {
                        text: '/admin',
                        object: 'text',
                      },
                    ],
                  },
                ],
                flags: 1,
                createdAt: '2024-09-23T15:58:02.000Z',
                evolutionId: null,
                stepId: null,
                stepTriggerId: null,
                blockId: null,
              },
            ],
          },
          {
            uid: 'bd1f746d-dd96-41ae-aef0-febc5628ee39',
            type: 'BODY',
            value: 'Your text here',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Your text here',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-09-23T15:40:23.000Z',
            removed: false,
            updateData: null,
            parentBlockId: null,
            options: [],
            translations: [],
            file: null,
            actions: [],
          },
        ],
        triggers: [],
      },
    ],
    parentTourId: 'a4b0f806-fbea-4b25-bcc8-45f9eeb41839',
    querySelectorManual: null,
  },
  {
    uid: 'd887d9ea-4603-42cf-8aee-8489a84e9327',
    boostFlags: 128,
    boostedDelay: 0,
    boostedAnimations: 'slide;slide;left;left',
    boostedQueryselector:
      '.flex > .w-full:nth-child(2) > .relative > .flex-auto > .text-sm',
    boostedPositionFlags: 1024,
    boostedPositionOffsets: '0;0',
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedRoundness: '8',
    boostedTitleFontSize: '18',
    boostedContentFontSize: '14',
    boostedPaths: [],
    boostedPathOperator: 'OR',
    boostedDotStyle: '#1260EB;22;default',
    boostedActiveUrl: '',
    boostedActiveOperator: 'EVERYWHERE',
    boostedSize: null,
    boostedLightbox: 'SOFT',
    boostedZIndex: null,
    optionsFlags: 4329481,
    tourStepInfo: '1;1;1',
    style: {
      margin: 0,
      shadow: {
        x: '1',
        y: '2',
        blur: '12',
        color: '#00000026',
        opacity: 0,
      },
      overlay: null,
      background: {
        type: 'color',
        animated: false,
        primaryColor: '#FFFFFF',
        secondaryColor: null,
      },
      hintOffset: 8,
      animationIn: 'fade',
      borderColor: null,
      'animation-in': null,
      animationOut: 'fade',
      borderRadius: 16,
      pointerColor: '#0066ffff',
      'animation-out': 'slide-up',
      targetBorderColor: '#0066ffff',
      withElementsAnimations: true,
    },
    context: null,
    steps: [
      {
        uid: 'ba2e769c-23a1-47cc-967f-7b4bffeaddda',
        style: {
          width: null,
          height: null,
          margin: 0,
          shadow: {
            x: 0,
            y: 8,
            blur: 12,
            color: '#0000000f',
          },
          overlay: null,
          background: {
            type: 'color',
            animated: false,
            primaryColor: '#FFFFFF',
            secondaryColor: null,
          },
          animationIn: 'fade',
          borderColor: '#0000000f',
          animationOut: 'fade',
          borderRadius: 12,
          backgroundColor: '#FFFFFF',
          withElementsAnimations: true,
        },
        name: 'Outlined Button',
        description:
          'Outlined button to direct users to a specific page or trigger actions',
        question: null,
        additionalInformation: null,
        type: null,
        mdContent: null,
        mdRawContent: null,
        indexOrder: 0,
        ctaLabel: null,
        ctaColor: null,
        ctaBackgroundColor: null,
        ctaBorderRadius: null,
        ctaUrl: null,
        ctaAction: null,
        ctaFontSize: null,
        interactiveLink: null,
        conceptThumbnailUrl: null,
        createdAt: '2024-09-23T15:37:31.000Z',
        mazeUrl: null,
        stepFlags: 0,
        removed: false,
        updateData: {
          date: '2024-09-23T15:40:47.682Z',
          operation: 'modified',
        },
        endSurvey: false,
        texts: [],
        thumbnails: [],
        options: [],
        goTo: null,
        prototypes: [],
        blocks: [
          {
            uid: '36a2d28a-3e1a-4aba-932f-3a809b250902',
            type: 'HINT',
            value: 'Button',
            rawValue: null,
            flags: 0,
            style: {
              type: 'button',
              color: '#000000ff',
              iconUrl: null,
              offsetX: -72,
              offsetY: 24,
              padding: 12,
              fontSize: 20,
              iconName: 'help',
              iconColor: '#000000ff',
              pulsating: false,
              fontFamily: 'Inter',
              fontWeight: '500',
              iconSource: 'built-in',
              borderColor: '#00000040',
              borderRadius: 12,
              verticalAlign: 'top',
              backgroundColor: '#466cff00',
              horizontalAlign: 'right',
            },
            createdAt: '2024-09-23T15:37:41.000Z',
            removed: false,
            updateData: null,
            parentBlockId: null,
            options: [],
            translations: [],
            file: null,
            actions: [
              {
                uid: 'c717f5ac-050d-4cde-8773-6defd00befd9',
                type: 'OPEN_POST',
                value: '91de11bd-469e-4aa6-8195-1abbdc0bdb48',
                rawValue: null,
                flags: 0,
                createdAt: '2024-09-23T15:58:01.000Z',
                evolutionId: null,
                stepId: null,
                stepTriggerId: null,
                blockId: null,
              },
            ],
          },
          {
            uid: 'e99db900-cdd2-4199-a125-3fc376602a99',
            type: 'BODY',
            value: 'Your text here',
            rawValue: [
              {
                data: {},
                type: 'paragraph',
                object: 'block',
                children: [
                  {
                    text: 'Your text here',
                    object: 'text',
                  },
                ],
              },
            ],
            flags: 0,
            style: {
              align: 'left',
              fontSize: 15,
              fontColor: '#6b6767ff',
              fontFamily: 'Inter',
              fontWeight: '400',
            },
            createdAt: '2024-09-23T15:37:41.000Z',
            removed: false,
            updateData: null,
            parentBlockId: null,
            options: [],
            translations: [],
            file: null,
            actions: [],
          },
        ],
        triggers: [],
      },
    ],
    parentTourId: 'a4b0f806-fbea-4b25-bcc8-45f9eeb41839',
    querySelectorManual: null,
  },
];
