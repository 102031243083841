import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

export const useJimoIdentify = ({forceIsIdentified = false} = {}) => {
  const [user, setUser] = useState(null);
  const [attributes, setAttributes] = useState({});
  const [isIdentified, setIsIdentified] = useState(forceIsIdentified);

  useEffect(() => {
    if (
      user == null ||
      window.jimo == null ||
      Array.isArray(window.jimo) === false ||
      window.jimoInit == null
    ) {
      return;
    }
    window.jimo.push([
      'do',
      'identify',
      [
        user.uid,
        () => {
          setIsIdentified(true);
          window.jimo.push(['set', 'user:email', [user.email]]);
          window.jimo.push([
            'set',
            'user:name',
            [`${user.firstName} ${user.lastName}`],
          ]);
          window.jimo.push([
            'set',
            'user:attributes',
            [{createdAt: user.createdAt, from: 'app'}],
          ]);
        },
      ],
    ]);
    window.jimoInit();
  }, [user, window.jimo, window.jimoInit]);

  useEffect(() => {
    if (isIdentified === false) return;

    window.jimo.push(['set', 'user:attributes', [attributes]]);
  }, [attributes, isIdentified]);

  return {
    identify(user) {
      setUser(user);
    },
    pushAttributes(newAttributes = {}) {
      setAttributes({
        ...attributes,
        ...newAttributes,
      });
    },
  };
};

export const JimoNavigation = () => {
  const history = useHistory();

  useEffect(() => {
    window['JIMO_CUSTOM_OPTIONS'] = {
      navigate: (url) => {
        history.push(url);
      },
    };
  }, [history]);

  return <></>;
};
