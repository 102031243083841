import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import Tabs from 'components/Tabs';
import Timeline from 'components/Timeline';
import {PermissionsPeople} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {hasUsernameGenerated} from 'helpers/utils';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {getJimer, getJimerActivity} from 'services/jimer';
import UserTagSelector from '../UserTagSelector';
import './_Styles.scss';
import Attributes from './components/Attributes';
import SurveyResponses from './components/SurveyResponses';
import JimerBanner from './imgs/jimer-profile-background.svg';

export const TAB_USER_DATA = 'User Data';
export const TAB_ACTIVITY = 'Activity';
export const TAB_SURVEY_RESPONSE = 'Survey responses';

const ModalJimerProfile = ({isOpen, onRequestClose, jimerId}) => {
  return (
    <Modal
      large
      className="modal-jimer-profile fade-in-right"
      animationOnOpen="fade-in"
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <JimerProfile jimerId={jimerId} />
    </Modal>
  );
};

export const JimerProfile = ({
  jimerId,
  onBack = null,
  defaultTab,
  defaultSurveyId,
}) => {
  const [tab, setTab] = useState(defaultTab || TAB_USER_DATA);

  const {data: jimer, isLoading: isLoadingJimer} = useQuery({
    queryKey: ['jimer', jimerId],
    queryFn: () =>
      getJimer(jimerId, {
        relations: ['analytics', 'segments', 'attributes', 'tags'],
      }),
    enabled: !!jimerId,
    refetchOnWindowFocus: false,
  });

  const {data: jimerActivity, isLoading: isLoadingJimerActivity} = useQuery({
    queryKey: ['jimerActivity', jimerId],
    queryFn: () => getJimerActivity(jimerId),
    enabled: !!jimerId,
    refetchOnWindowFocus: false,
  });

  if (jimerId == null) {
    return <></>;
  }

  const username =
    hasUsernameGenerated(jimer?.username) && jimer?.externalUsername
      ? jimer?.externalUsername
      : jimer?.username;

  const isAnonymous =
    hasUsernameGenerated(username) === true &&
    !jimer.identifyToken &&
    !jimer.externalUid;

  if (isLoadingJimer) {
    return (
      <div className="jimer-profile-wrapper">
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      </div>
    );
  }

  const createJimerItem = {
    type: 'CREATE_JIMER',
    createdAt: jimer?.createdAt,
  };

  return (
    <div className="jimer-profile-wrapper">
      <div className="jimer-profile">
        <div className="jimer-banner">
          <img
            className="jimer-banner-img"
            src={JimerBanner}
            alt="jimer banner"
          />
          <Avatar jimer={jimer} />
        </div>
        <div className="jimer-header">
          {onBack != null && (
            <Button
              className="back-btn"
              iconOnly
              iconLeft="icon-chevron-left"
              onClick={onBack}
            />
          )}

          <div className="jimer-details">
            <div className="username-wrapper">
              <div className="username">
                {isAnonymous ? (
                  'Anonymous'
                ) : !!username ? (
                  username
                ) : (
                  <em>username</em>
                )}
              </div>
              {isAnonymous === true ? (
                <div className="anonymous-name">{username}</div>
              ) : jimer.identifyToken || jimer.externalUid ? (
                <div className="anonymous-name">
                  {jimer.identifyToken || jimer.externalUid}
                </div>
              ) : null}
            </div>
          </div>

          <div className="jimer-tags-wrapper">
            <UserTagSelector
              jimer={jimer}
              maxTags={5}
              readOnly={hasPermissions(PermissionsPeople.USER_EDIT) === false}
            />
          </div>
        </div>
        <>
          <Tabs key={tab} defaultTab={tab} onTabChange={setTab}>
            <div label={TAB_USER_DATA} />
            <div label={TAB_ACTIVITY} />
            <div label={TAB_SURVEY_RESPONSE} />
          </Tabs>
          {tab === TAB_USER_DATA ? (
            <Attributes jimer={jimer} />
          ) : tab === TAB_ACTIVITY ? (
            <>
              {isLoadingJimerActivity ? (
                <DefaultLoader />
              ) : (
                <Timeline
                  jimer={jimer}
                  items={[...jimerActivity, createJimerItem]}
                />
              )}
            </>
          ) : tab === TAB_SURVEY_RESPONSE ? (
            <SurveyResponses jimer={jimer} defaultSurveyId={defaultSurveyId} />
          ) : (
            <></>
          )}
        </>
      </div>
    </div>
  );
};

export default ModalJimerProfile;
