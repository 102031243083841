import {SlateTransformer} from '@accordproject/markdown-slate';
import {createSingleLinePlugin} from '@udecode/plate-break';
import {createPlugins, Plate, PlateContent} from '@udecode/plate-common';
import {createParagraphPlugin} from '@udecode/plate-paragraph';
import {Element, Text} from 'domhandler';
import {any, bool, func, string} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {CUSTOM_ATTRIBUTE_SOURCE_JIMO} from 'services/segment';
import {Transforms} from 'slate';
import {payloadSlateToDomConfig, slateToHtml} from 'slate-serializers';
import {v4 as uuidv4} from 'uuid';
import VariableConfigModal from '../components/VariableConfigModal';
import VariableElement from '../elements/VariableElement';
import {
  createVariablePlugin,
  ELEMENT_VARIABLE,
} from '../plugins/VariablePlugin';
import {MarkdownEditorContext, useMyPlateEditorRef} from '../utils';
import './_Styles.scss';

const plugins = createPlugins(
  [createParagraphPlugin(), createVariablePlugin(), createSingleLinePlugin()],
  {
    components: {
      [ELEMENT_VARIABLE]: VariableElement,
    },
  }
);

const extractContent = (html) => {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

const config = {
  ...payloadSlateToDomConfig,
  elementTransforms: {
    ...payloadSlateToDomConfig.elementTransforms,
    variable: ({node, children = []}) => {
      // Ensure node.variable is a string
      const variableText =
        typeof node.attribute === 'string' ? node.attribute || '' : '';

      const source =
        node.attributeSource != null
          ? node.attributeSource !== CUSTOM_ATTRIBUTE_SOURCE_JIMO
            ? `${node.attributeSource.toLowerCase()}.`
            : ''
          : '';

      const variableElement = new Element('span', {}, [
        new Text(`{{${source}${variableText}|${node.fallbackValue || ''}}}`),
      ]);

      return variableElement;
    },
  },
};

const VariableButton = () => {
  const editor = useMyPlateEditorRef();

  const insertVariable = () => {
    const variable = {
      uid: uuidv4(),
      type: ELEMENT_VARIABLE,
      children: [{text: ''}],
      attribute: null,
    };
    Transforms.insertNodes(editor, variable);
  };

  return (
    <div
      className="variable-icon-wrapper"
      onClick={(event) => {
        event.preventDefault();
        insertVariable();
      }}>
      <i className="isax isax-user-cirlce-add n-600" />
    </div>
  );
};

const propTypes = {
  value: string,
  rawValue: any,
  onChange: func,
  disabled: bool,
  placeholder: string,
  onFocus: func,
  onBlur: func,
};

const defaultProps = {
  value: '',
  rawValue: null,
  onChange: () => {},
  disabled: false,
  placeholder: '',
  onFocus: () => {},
  onBlur: () => {},
};

const InputEditor = ({
  value,
  rawValue,
  onChange,
  disabled,
  placeholder,
  onFocus = () => {},
  onBlur = () => {},
}) => {
  const [currentVariable, setCurrentVariable] = useState(null);
  const [newVariable, setNewVariable] = useState(null);

  const previousValueRef = useRef(rawValue);

  useEffect(() => {
    if (rawValue == null) {
      return;
    }

    if (previousValueRef.current == null) {
      previousValueRef.current = rawValue;
      return;
    }

    const previousVariables = previousValueRef.current?.[0]?.children
      ?.filter((node) => node.type === 'variable')
      .map((node) => node.uid);

    const newVariable = rawValue[0].children?.find(
      (node) =>
        node.type === 'variable' && !previousVariables.includes(node.uid)
    );

    if (newVariable) {
      setNewVariable(newVariable);
    }

    previousValueRef.current = rawValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawValue]);

  useEffect(() => {
    if (currentVariable != null) {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariable]);

  const handleChange = (nextValue) => {
    // serialize slate state to an html string
    const html = slateToHtml(nextValue, config);
    // extract the text from the html
    const str = extractContent(html);
    onChange({rawValue: nextValue, value: str});
  };

  let editorValue;

  if (rawValue != null) {
    editorValue = rawValue;
  } else {
    const slateTransformer = new SlateTransformer();
    const slateValue = slateTransformer.fromMarkdown(value);
    editorValue = slateValue.document.children;
  }

  return (
    <MarkdownEditorContext.Provider
      value={{
        currentVariable,
        setCurrentVariable,
        newVariable,
        setNewVariable,
      }}>
      <div className="input-editor">
        <Plate plugins={plugins} value={editorValue} onChange={handleChange}>
          <VariableConfigModal
            trigger={
              <PlateContent
                className="editor-wrapper"
                readOnly={disabled}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            }
          />
          <VariableButton />
        </Plate>
      </div>
    </MarkdownEditorContext.Provider>
  );
};

InputEditor.propTypes = propTypes;
InputEditor.defaultProps = defaultProps;

export default InputEditor;
