import Button from 'components/Button';
import Input from 'components/Input';
import SelectAttribute, {ATTRIBUTES} from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [OPTIONS.OP_IS, OPTIONS.OP_NIS];

export const AttributeName = (props) => {
  const {
    attribute,
    onDelete,
    onChangeType,
    onUpdateOperator,
    onUpdateValue,
    onAddValue,
    onDeleteValue,
    segmentioFields,
    segmentioEvents,
    trackedEvents,
  } = props;

  return (
    <div className="segment-attribute attribute-name">
      <div className="header-row">
        <SelectAttribute
          value={ATTRIBUTES.ATTR_NAME}
          onChange={(value) => onChangeType(value)}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>

      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => onUpdateOperator(op)}
        />
        {attribute.values.map((v, index) => (
          <Input
            key={index}
            placeholder="enter value..."
            labelTextRight={
              attribute.values.length > 1 ? (
                <i
                  className="icon-close"
                  onClick={() => onDeleteValue(index)}></i>
              ) : null
            }
            value={v}
            onChange={({target}) => onUpdateValue(index, target.value)}
            disabled
          />
        ))}
      </div>
    </div>
  );
};
