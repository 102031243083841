import classNames from 'classnames';
import {func, string} from 'prop-types';
import React from 'react';
import './_Styles.scss';

export const successAnimations = [
  {
    name: 'Confettis',
    icon: 'icon-light',
    value: 'confetti',
  },
  {
    name: 'Hearts',
    icon: 'icon-heart-o',
    value: 'heart',
  },
  {
    name: 'Smileys',
    icon: 'icon-emoji',
    value: 'smiley',
  },
  {
    name: 'Check',
    icon: 'icon-tick',
    value: 'check',
  },
];

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: null,
  onChange: () => {},
};

const Animations = ({value, onChange}) => {
  return (
    <div className="animations-success-list">
      {successAnimations.map((animation) => (
        <div
          className={classNames('animation-item', {
            selected: value === animation.value,
          })}
          onClick={() => onChange(animation.value)}>
          <i className={animation.icon} />
          {animation.name}
        </div>
      ))}
    </div>
  );
};

Animations.propTypes = propTypes;
Animations.defaultProps = defaultProps;

export default Animations;
