import React, {useContext, useEffect, useState} from 'react';
import './_Styles.scss';
import Button from 'components/Button';
import Divider from 'components/Divider';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import InputGroup from 'components/Input';
import ModalAddStepEvent from '../ModalAddStepEvent';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import EventConditions from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {EventItem} from '../TrackerBuilderSingleEvent';
import {v4 as uuidv4} from 'uuid';
import {TrackerBuilderContext} from 'scenes/TrackerBuilder';
import {EVENT_CONDITION_TYPE_DELAY} from 'services/event';

export const defaultStep = (props = {}) => {
  return {
    uid: uuidv4(),
    name: 'New step',
    events: [],
    ...props,
  };
};

const TrackerStep = ({index, step, compact}) => {
  const {selectedStepId, setSelectedStepId, setTracker, tracker} = useContext(
    TrackerBuilderContext
  );

  const {events = [], onTheFlyEvent} = step || {};

  const [isAddStepEventModalOpen, setIsAddStepEventModalOpen] = useState(false);
  const [conditions, setConditions] = useState(
    onTheFlyEvent != null ? onTheFlyEvent?.conditions || [] : null
  );
  const [logic, setLogic] = useState(
    onTheFlyEvent != null ? buildLogic(onTheFlyEvent) : null
  );
  const [name, setName] = useState(step.name || '');

  useEffect(() => {
    if (name === step.name) {
      return;
    }

    setTracker((tracker) => {
      const newSteps = tracker.steps.map((s) => {
        if (s.uid === step.uid) {
          return {...s, name: name};
        }
        return s;
      });
      return {...tracker, steps: newSteps};
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (onTheFlyEvent != null) {
      setTracker((tracker) => {
        const newSteps = tracker.steps.map((s) => {
          if (s.uid === step.uid) {
            return {
              ...s,
              onTheFlyEvent: {
                ...s.onTheFlyEvent,
                conditions,
                logic,
              },
            };
          }
          return s;
        });
        return {...tracker, steps: newSteps};
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions, logic]);

  const isSelected = selectedStepId === step.uid;

  const event = events[0];

  const hasEvent = events.length > 0 || onTheFlyEvent != null;

  return (
    <div
      className={classNames('tracker-step', {
        'is-selected': isSelected,
      })}>
      <div
        className="step-header"
        onClick={() => {
          if (isSelected !== true) {
            setSelectedStepId(step.uid);
          } else {
            setSelectedStepId(null);
          }
        }}>
        <div className="step-index">{index + 1}</div>
        <div className="step-name">
          <InputGroup
            className="step-name-input"
            value={name}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {hasEvent === true ? (
          <div className={classNames('step-events-info')}>
            {onTheFlyEvent != null ? (
              'On the fly event'
            ) : (
              <>
                {step.events?.length} event
                {step.events?.length > 1 ? 's' : ''}
              </>
            )}
            <i className="icon-chevron-bottom" />
          </div>
        ) : (
          <div
            className="add-event-trigger"
            onClick={() => {
              setSelectedStepId(step.uid);
              setIsAddStepEventModalOpen(true);
            }}>
            Select event
            <i className="icon-plus-rounded" />
          </div>
        )}
      </div>
      {hasEvent === true && (
        <AnimateHeight
          className="collapsable-content-wrapper"
          height={isSelected ? 'auto' : 0}>
          <Divider />
          {onTheFlyEvent != null ? (
            <div className="on-the-fly-event">
              <EventConditions
                conditions={conditions}
                setConditions={setConditions}
                logic={logic}
                setLogic={setLogic}
                borderless
                compact={compact}
                omitTypes={[EVENT_CONDITION_TYPE_DELAY]}
              />
              <Button
                className="remove-event-btn"
                thin
                iconRight="isax isax-trash"
                onClick={() => {
                  setTracker({
                    ...tracker,
                    steps: tracker.steps.map((s) => {
                      if (s.uid === step.uid) {
                        return {
                          ...s,
                          onTheFlyEvent: null,
                        };
                      }
                      return s;
                    }),
                  });
                  setConditions(null);
                  setLogic(null);
                }}>
                Remove on the fly event
              </Button>
            </div>
          ) : event != null ? (
            <div className="step-events">
              {step.events.map((event) => {
                return (
                  <div className="tracker-event-wrapper">
                    <EventItem
                      event={event}
                      className="selected-event"
                      onDelete={() => {
                        setTracker((tracker) => {
                          const newSteps = tracker.steps.map((s) => {
                            if (s.uid === selectedStepId) {
                              return {
                                ...s,
                                events: s.events.filter(
                                  (_event) => _event.uid !== event.uid
                                ),
                              };
                            }
                            return s;
                          });
                          return {...tracker, steps: newSteps};
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          {hasEvent === true && (
            <>
              <Divider />
              <div
                className="trigger-delete-wrapper"
                onClick={() => {
                  setTracker((tracker) => {
                    const newSteps = tracker.steps.filter(
                      (_step) => _step.uid !== step.uid
                    );
                    return {...tracker, steps: newSteps};
                  });
                }}>
                <div className="icon-wrapper">
                  <i className="isax isax-trash r-400" />
                </div>
                <div className="r-400">Delete step</div>
              </div>
            </>
          )}
        </AnimateHeight>
      )}
      {hasEvent !== true && (
        <>
          <Divider />
          <div
            className="trigger-delete-wrapper"
            onClick={() => {
              setTracker((tracker) => {
                const newSteps = tracker.steps.filter(
                  (_step) => _step.uid !== step.uid
                );
                return {...tracker, steps: newSteps};
              });
            }}>
            <div className="icon-wrapper">
              <i className="isax isax-trash r-400" />
            </div>
            <div className="r-400">Delete step</div>
          </div>
        </>
      )}

      <ModalAddStepEvent
        isOpen={isAddStepEventModalOpen}
        onRequestClose={() => setIsAddStepEventModalOpen(false)}
        onCreateOnTheFlyEvent={() => {
          setTracker((tracker) => ({
            ...tracker,
            steps: tracker.steps.map((s) => {
              if (s.uid === step.uid) {
                return {
                  ...s,

                  onTheFlyEvent: {
                    temporary: true,
                    conditions: [],
                    logic: buildLogic(),
                  },
                  events: [],
                };
              }
              return s;
            }),
          }));
          setConditions([]);
          setLogic(buildLogic());
          setIsAddStepEventModalOpen(false);
          setSelectedStepId(step.uid);
        }}
        onSelectEvent={(event) => {
          setTracker((tracker) => ({
            ...tracker,
            steps: tracker.steps.map((s) => {
              if (s.uid === step.uid) {
                return {
                  ...s,
                  events: [...s.events, event],
                };
              }
              return s;
            }),
          }));
          setIsAddStepEventModalOpen(false);
          setSelectedStepId(step.uid);
        }}
      />
    </div>
  );
};

const TrackerBuilderSteps = ({compact}) => {
  const {tracker, setTracker, setSelectedStepId} = useContext(
    TrackerBuilderContext
  );
  const {steps = []} = tracker;

  const setSteps = (steps) => {
    setTracker({...tracker, steps});
  };

  const handleAddStep = () => {
    const newStep = defaultStep();
    setSteps([...steps, {...newStep, indexOrder: steps?.length || 0}]);
    setSelectedStepId(newStep.uid);
  };

  steps.sort((a, b) => a.indexOrder - b.indexOrder);

  return (
    <div className="tracker-builder-steps-wrapper">
      <div className="tracker-builder-steps-header">
        <div className="tracker-builder-steps-title">Activation tracker</div>
        <div className="tracker-builder-steps-description">
          Track multiple events to gain a comprehensive view of how users adopt
          and benefit from your features. Define key steps and conditions to
          capture detailed engagement data.
        </div>
      </div>
      <div className="tracker-builder-steps">
        <div className="tracker-steps">
          {steps.map((step, index) => {
            return (
              <TrackerStep
                key={index}
                index={index}
                step={step}
                compact={compact}
              />
            );
          })}
          <Button
            className="add-step-btn"
            iconLeft="icon-plus-rounded"
            onClick={handleAddStep}
            disabled={steps.length >= 6}>
            Add step
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TrackerBuilderSteps;
