import React, {forwardRef} from 'react';

import {
  FormatListBulleted,
  FormatListNumbered,
} from '@styled-icons/material-rounded';
import {
  ELEMENT_UL,
  useListToolbarButton,
  useListToolbarButtonState,
} from '@udecode/plate-list';
import MarkToolbarButton from '../MarkToolbarButton';

export const ListToolbarButton = forwardRef(
  ({nodeType = ELEMENT_UL, ...rest}, ref) => {
    const state = useListToolbarButtonState({nodeType});
    const {props} = useListToolbarButton(state);

    return (
      <MarkToolbarButton
        ref={ref}
        tooltip={nodeType === ELEMENT_UL ? 'Bulleted List' : 'Numbered List'}
        {...props}
        {...rest}>
        {nodeType === ELEMENT_UL ? (
          <FormatListBulleted />
        ) : (
          <FormatListNumbered />
        )}
      </MarkToolbarButton>
    );
  }
);
