import classNames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React, {useContext, useEffect} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_CHECKLIST_DISMISS} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import './_Styles.scss';

const ChecklistFooter = ({setFooterHeight}) => {
  const {
    JimoLabel,
    currentStep,
    onDismiss,
    blocks,
    addFontFamily,
    selectedBlock,
    inBuilder,
    onBlockSelected,
    onTaskSelected,
    language,
    isMinimized,
  } = useContext(PokeStateContext);

  const dismissibleBlock = blocks.find(
    (b) => b.type === BLOCK_CHECKLIST_DISMISS
  );

  const {style} = currentStep;
  const {backgroundColor} = style ?? {};

  const {style: dismissStyle, value} = dismissibleBlock ?? {};
  const {fontColor: color, fontFamily, ...restStyle} = dismissStyle ?? {};

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const translation = dismissibleBlock?.translations?.find(
    (t) => t.language === language
  );

  const colorToUSe = backgroundColor;
  const contrastedColor = fontColorContrast(
    colorToUSe != null
      ? colorToUSe.length > 7
        ? colorToUSe.slice(0, colorToUSe.length - 2)
        : colorToUSe
      : '#FFFFFF'
  );

  const handleDismissChecklist = () => {
    onDismiss();
  };

  const isDismissible = dismissibleBlock != null;

  if (isDismissible === false && JimoLabel == null) {
    return null;
  }

  return (
    <div
      className={classNames('checklist-footer', {
        dismissible: isDismissible === true,
      })}
      ref={(ref) => {
        if (ref != null && isMinimized !== true) {
          setFooterHeight(ref.clientHeight);
        }
      }}>
      {JimoLabel != null && (
        <div
          className={classNames('jimo-label-wrapper')}
          style={{
            color: contrastedColor,
          }}>
          <JimoLabel backgroundColor={backgroundColor} />
        </div>
      )}
      {isDismissible === true && (
        <div
          className={classNames('dismiss-checklist', {
            'poke-block-clickable': inBuilder === true,
            selected: selectedBlock === BLOCK_CHECKLIST_DISMISS,
          })}
          style={{
            color: color,
            fontFamily,
            ...restStyle,
          }}
          onClick={(e) => {
            if (inBuilder === true) {
              e.stopPropagation();

              onBlockSelected(BLOCK_CHECKLIST_DISMISS);
              onTaskSelected(null);
              return;
            }

            handleDismissChecklist();
          }}>
          {translation?.value || value}
          <ClickableBlockOverlay />
        </div>
      )}
    </div>
  );
};

export default ChecklistFooter;
