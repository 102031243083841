import CommentList from 'components/CommentList';
import {toastSuccess, toastWarning} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {func, object} from 'prop-types';
import {useEffect} from 'react';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import PokeBlock from 'components/PokeBlock';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';

const logger = new Swaler('Comments');

const propTypes = {
  evolution: object.isRequired,
  setEvolution: func.isRequired,
};

const defaultProps = {};

const Comments = ({evolution, setEvolution}) => {
  useEffect(() => {
    evolutionService.setEvolutionCommentsToRead(evolution.uid).catch((err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Set comments to read failed with error `, code);
      toastWarning([title, message], {
        actions,
      });
    });
  }, []);

  const handleCommentUpdate = (comment, commentReply) => {
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === comment.uid ? {...r, ...comment} : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === comment.uid ? {...c, ...comment} : c);
    }
    toastSuccess('Comment edited!', {toastId: 'comment-edited'});
    setEvolution((prevState) => ({...prevState, comments: updatedComments}));
  };
  const handleCommentDelete = (comment, commentReply) => {
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.filter((r) => r.uid !== comment.uid),
            }
          : c);
    } else {
      updatedComments = evolution.comments.filter(
        (c) /** comment */ => c.uid !== comment.uid
      );
    }

    toastSuccess('Comment deleted!', {toastId: 'comment-deleted'});
    setEvolution((prevState) => ({...prevState, comments: updatedComments}));
  };
  const handleCommentLiked = (like, commentReply) => {
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === like.comment.uid
                  ? {...r, likes: r.likes.concat({uid: like.uid}), myLike: like}
                  : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === like.comment.uid
          ? {...c, likes: c.likes.concat({uid: like.uid}), myLike: like}
          : c);
    }
    setEvolution((prevState) => ({...prevState, comments: updatedComments}));
  };
  const handleCommentUnLiked = (commentId, commentLikeId, commentReply) => {
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === commentId
                  ? {
                      ...r,
                      likes: c.likes.filter((l) => l.uid !== commentLikeId),
                      myLike: null,
                    }
                  : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentId
          ? {
              ...c,
              likes: c.likes.filter((l) => l.uid !== commentLikeId),
              myLike: null,
            }
          : c);
    }
    setEvolution((prevState) => ({...prevState, comments: updatedComments}));
  };
  const handleReplySubmit = (comment, commentReply) => {
    setEvolution((prevState) => ({
      ...prevState,
      comments: prevState.comments.map((c) =>
        c.uid === commentReply.uid
          ? {...c, replies: c.replies.concat(comment)}
          : c
      ),
    }));
  };

  const {comments = []} = evolution;

  return (
    <div className="comment-tab">
      {comments.length > 0 ? (
        <PokeBlock className="comment-list-wrapper" title={<>Comments</>}>
          <CommentList
            evolution={evolution}
            onCommentLiked={handleCommentLiked}
            onCommentUnLiked={handleCommentUnLiked}
            onCommentUpdate={handleCommentUpdate}
            onCommentDelete={handleCommentDelete}
            onReplySubmit={handleReplySubmit}
          />
        </PokeBlock>
      ) : (
        <div className="empty-state-comments">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyComments}
            title="No comments yet"
            description="Encourage your users to share their thoughts by commenting on your post to gain valuable insights."
          />
        </div>
      )}
    </div>
  );
};

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

export default Comments;
