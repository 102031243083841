import RadioGroup from 'components/RadioGroup';
import SelectGroup from 'components/Select';
import {fontFamiliesOptions} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Title/utils';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const ChangelogCtaStyle = ({style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <div className="section-item">
        <div className="section-item-title">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Background"
            value={style.backgroundColor}
            onChange={(value) =>
              updateStyle({...style, backgroundColor: value})
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Border"
            value={style.borderColor}
            onChange={(value) => updateStyle({...style, borderColor: value})}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.borderRadius}
            min={0}
            max={50}
            onChange={(value) => updateStyle({...style, borderRadius: value})}
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Position</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.align}
            options={alignOptions}
            onSelect={(value) =>
              updateStyle({
                ...style,
                align: value,
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Text color"
            value={style.fontColor}
            onChange={(value) =>
              updateStyle({
                ...style,
                fontColor: value,
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                ...style,
                fontFamily: option.value,
              })
            }
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        </div>
      </div>
    </>
  );
};

export default ChangelogCtaStyle;
