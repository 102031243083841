import {Popover, PopoverAnchor, PopoverContent} from '@radix-ui/react-popover';
import {
  isSelectionExpanded,
  PlateElement,
  useEditorSelector,
  useElement,
  useRemoveNodeButton,
  withHOC,
} from '@udecode/plate-common';
import {
  floatingMediaActions,
  parseTwitterUrl,
  parseVideoUrl,
  useFloatingMediaSelectors,
  useMediaState,
} from '@udecode/plate-media';
import {ResizableProvider} from '@udecode/plate-resizable';
import classNames from 'classnames';
import Button from 'components/Button';
import React, {forwardRef, useEffect} from 'react';
import {useReadOnly, useSelected} from 'slate-react';
import './_Styles.scss';

export function MediaPopover({children, pluginKey}) {
  const readOnly = useReadOnly();
  const selected = useSelected();

  const selectionCollapsed = useEditorSelector(
    (editor) => !isSelectionExpanded(editor),
    []
  );
  const isOpen = !readOnly && selected && selectionCollapsed;
  const isEditing = useFloatingMediaSelectors().isEditing();

  useEffect(() => {
    if (!isOpen && isEditing) {
      floatingMediaActions.isEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const element = useElement();
  const {props: buttonProps} = useRemoveNodeButton({element});

  if (readOnly) return <>{children}</>;

  return (
    <Popover modal={false} open={isOpen}>
      <PopoverAnchor>{children}</PopoverAnchor>

      <PopoverContent
        className="w-auto p-1"
        onOpenAutoFocus={(e) => e.preventDefault()}>
        <div className="box-content flex h-9 items-center gap-1">
          <Button {...buttonProps}>Remove</Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export const MediaEmbedElement = withHOC(
  ResizableProvider,
  forwardRef(({children, className, ...props}, ref) => {
    const {embed, isVideo} = useMediaState({
      urlParsers: [parseTwitterUrl, parseVideoUrl],
    });

    return (
      <PlateElement
        className={classNames(className, 'plate-media-embed-element')}
        ref={ref}
        {...props}>
        <figure className="media-wrapper" contentEditable={false}>
          {isVideo ? (
            <div className="video-wrapper">
              <iframe allowFullScreen src={embed?.url} title="embed" />
            </div>
          ) : null}
        </figure>
        {children}
      </PlateElement>
    );
  })
);
