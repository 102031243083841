import classnames from 'classnames';
import {toastInfo} from 'components/Toaster';
import {Environment} from 'conf/env';
import {
  PermissionsPeople,
  PermissionsPoke,
  PermissionsPost,
  PermissionsRequest,
  PermissionsSettings,
} from 'constants/permissions';
import {hasFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import ReactHelmet from 'react-helmet';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {generalSelector} from 'selectors';
import {authService} from 'services';
import {
  F_USER_STATUS_NOT_APPROVED,
  F_USER_STATUS_WAITING_FOR_APPROVAL,
} from 'services/auth';
import {
  ME_F_ONBOARDING_COMPLETED,
  ME_F_ONBOARDING_DONE_STEP_1_1,
  ME_F_ONBOARDING_DONE_STEP_1_2,
  ME_F_ONBOARDING_DONE_STEP_2_1,
  ME_F_ONBOARDING_DONE_STEP_2_2,
  ME_F_ONBOARDING_DONE_STEP_2_3,
  ME_F_ONBOARDING_DONE_STEP_3_1,
  ME_F_STATUS_PASSWORD_NOT_SETUP,
} from 'services/me';
import {BasicLayout} from './Layouts';
import {
  ROUTE_BANNERS,
  ROUTE_BANNER_WITH_ID,
  ROUTE_DRAFT,
  ROUTE_DRAFT_WITH_ID,
  ROUTE_FEED,
  ROUTE_GET_STARTED,
  ROUTE_HINTS,
  ROUTE_HINT_WITH_ID,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
  ROUTE_ONBOARDING_DETAILS_STEP_1_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
  ROUTE_ONBOARDING_DETAILS_STEP_2_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_3,
  ROUTE_ONBOARDING_DETAILS_STEP_3_1,
  ROUTE_ONBOARDING_WAITING,
  ROUTE_POKES,
  ROUTE_REGISTER_SETUP_PASSWORD,
  ROUTE_SETTINGS_BILLING,
  ROUTE_SETTINGS_CHANGELOG,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_INSTALLATION,
  ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_PLAN,
  ROUTE_SETTINGS_POKE_CONTROL,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_SUBSCRIPTION_CARDS,
  ROUTE_SETTINGS_SUBSCRIPTION_COUPON,
  ROUTE_SETTINGS_SUBSCRIPTION_INVOICES,
  ROUTE_SETTINGS_SUBSCRIPTION_PLAN,
  ROUTE_SETTINGS_TEAM,
  ROUTE_SETTINGS_THEMES,
  ROUTE_SETTINGS_THEMES_ID,
  ROUTE_SURVEYS,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_TOURS,
  ROUTE_TOUR_WITH_ID,
  ROUTE_USERS,
} from './routes.const';

const resumeOnboarding = () => {
  const user = generalSelector.getUser();

  if (hasFlag(ME_F_ONBOARDING_DONE_STEP_1_1, user.onboardingFlags) === false) {
    return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_1_1}></Redirect>;
  }
  if (hasFlag(ME_F_ONBOARDING_DONE_STEP_1_2, user.onboardingFlags) === false) {
    return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_1_2}></Redirect>;
  }
  if (hasFlag(ME_F_ONBOARDING_DONE_STEP_2_1, user.onboardingFlags) === false) {
    return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_2_1}></Redirect>;
  }
  if (hasFlag(ME_F_ONBOARDING_DONE_STEP_2_2, user.onboardingFlags) === false) {
    return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_2_2}></Redirect>;
  }
  if (hasFlag(ME_F_ONBOARDING_DONE_STEP_2_3, user.onboardingFlags) === false) {
    return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_2_3}></Redirect>;
  }
  if (hasFlag(ME_F_ONBOARDING_DONE_STEP_3_1, user.onboardingFlags) === false) {
    return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_3_1}></Redirect>;
  }
};

const MyRoute = ({
  component: Component,
  layout: Layout = BasicLayout,
  bodyOpts = {
    hasOnboardingBgColor: false,
    hasSettingsBgColor: false,
  },
  title = '',
  checkAuth = false,
  checkUnAuth = false,
  checkWaiting = true,
  ...rest
}) => {
  const location = useLocation();

  const isExtension =
    location.state?.from === '/lightweight-builder/extension' ||
    location.state?.from?.startsWith('/lightweight-builder/poke');

  return (
    <Route
      {...rest}
      render={(props) => {
        const projects = generalSelector.getProjects();
        const bodyClassName = classnames({
          hasOnboardingBgColor:
            bodyOpts.hasOnboardingBgColor === true && isExtension !== true,
          hasSettingsBgColor: bodyOpts.hasSettingsBgColor === true,
          'transparent-mode': isExtension === true,
        });
        const project = generalSelector.getProject();
        const user = generalSelector.getUser();
        const hasCompletedOnboarding =
          user != null
            ? hasFlag(ME_F_ONBOARDING_COMPLETED, user.onboardingFlags)
            : false;

        if (
          authService.isAuthenticated() === true &&
          hasFlag(F_USER_STATUS_NOT_APPROVED, user.flags) === true &&
          props.match.path !== ROUTE_LOGOUT
        ) {
          toastInfo([
            'Access denied',
            'Unfortunately, we could not approve your access request.',
          ]);
          return <Redirect to={ROUTE_LOGOUT}></Redirect>;
        }
        if (
          authService.isAuthenticated() === true &&
          hasFlag(F_USER_STATUS_WAITING_FOR_APPROVAL, user.flags) === true &&
          checkWaiting === true
        ) {
          return <Redirect to={ROUTE_ONBOARDING_WAITING}></Redirect>;
        }
        if (checkAuth === true && authService.isAuthenticated() === false) {
          return (
            <Redirect
              to={{
                pathname: ROUTE_LOGIN,
                state: {from: location.pathname},
              }}
            />
          );
        }
        if (checkUnAuth === true && authService.isAuthenticated() === true) {
          return <Redirect to={ROUTE_TOURS}></Redirect>;
        }
        if (
          checkAuth === true &&
          authService.isAuthenticated() === true &&
          hasFlag(ME_F_STATUS_PASSWORD_NOT_SETUP, user.flags) === true
        ) {
          return <Redirect to={ROUTE_REGISTER_SETUP_PASSWORD}></Redirect>;
        }
        if (
          checkAuth === true &&
          authService.isAuthenticated() === true &&
          projects.length === 0 &&
          hasCompletedOnboarding === true
        ) {
          return <Redirect to={ROUTE_ONBOARDING_DETAILS_STEP_2_1}></Redirect>;
        }
        if (
          checkAuth === true &&
          authService.isAuthenticated() === true &&
          hasCompletedOnboarding === false
        ) {
          return resumeOnboarding();
        }

        if (props.match.path === ROUTE_POKES) {
          return <Redirect to={ROUTE_TOURS} />;
        }
        // Check permission for accessing features page
        if (
          ([ROUTE_TOURS, ROUTE_SURVEYS, ROUTE_BANNERS, ROUTE_HINTS].includes(
            props.match.path
          ) &&
            hasPermissions(PermissionsPoke.ACCESS) === false) ||
          ([
            ROUTE_TOUR_WITH_ID(),
            ROUTE_SURVEY_WITH_ID(),
            ROUTE_BANNER_WITH_ID(),
            ROUTE_HINT_WITH_ID(),
          ].includes(props.match.path) &&
            hasPermissions(PermissionsPoke.ANALYTICS) === false) ||
          (props.match.path === ROUTE_FEED &&
            hasPermissions(PermissionsPost.ACCESS) === false) ||
          (props.match.path === ROUTE_USERS &&
            hasPermissions(PermissionsPeople.ACCESS) === false) ||
          (props.match.path === ROUTE_SETTINGS_GENERAL &&
            hasPermissions(PermissionsSettings.ACCESS) === false) ||
          ([ROUTE_DRAFT, ROUTE_DRAFT_WITH_ID()].includes(props.match.path) &&
            hasPermissions(PermissionsRequest.ACCESS) === false)
        ) {
          return <Redirect to={ROUTE_GET_STARTED} />;
        }

        // Check permission for accessing setting page
        if (
          (props.match.path === ROUTE_SETTINGS_GENERAL &&
            hasPermissions(PermissionsSettings.GENERAL_ACCESS) === false) ||
          (props.match.path === ROUTE_SETTINGS_CHANGELOG &&
            hasPermissions(PermissionsSettings.CHANGELOG_ACCESS) === false) ||
          (props.match.path === ROUTE_SETTINGS_THEMES &&
            hasPermissions(PermissionsSettings.THEME_ACCESS) === false) ||
          (props.match.path === ROUTE_SETTINGS_THEMES_ID() &&
            hasPermissions(PermissionsSettings.THEME_ACCESS) === false) ||
          ([
            ROUTE_SETTINGS_INSTALLATION,
            ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
          ].includes(props.match.path) &&
            hasPermissions(PermissionsSettings.INSTALLATION_ACCESS) ===
              false) ||
          (props.match.path === ROUTE_SETTINGS_POKE_CONTROL &&
            hasPermissions(PermissionsSettings.POKE_CONTROL_ACCESS) ===
              false) ||
          (props.match.path === ROUTE_SETTINGS_TEAM &&
            hasPermissions(PermissionsSettings.TEAM_ACCESS) === false) ||
          (props.match.path === ROUTE_SETTINGS_INTEGRATIONS &&
            hasPermissions(PermissionsSettings.INTEGRATION_ACCESS) === false) ||
          ([
            ROUTE_SETTINGS_SUBSCRIPTION_PLAN,
            ROUTE_SETTINGS_SUBSCRIPTION_CARDS,
            ROUTE_SETTINGS_SUBSCRIPTION_COUPON,
            ROUTE_SETTINGS_SUBSCRIPTION_INVOICES,
            ROUTE_SETTINGS_PLAN,
            ROUTE_SETTINGS_BILLING,
          ].includes(props.match.path) &&
            hasPermissions(PermissionsSettings.BILLING_ACCESS) === false)
        ) {
          return <Redirect to={ROUTE_SETTINGS_PROFILE}></Redirect>;
        }

        return [
          <ReactHelmet key={1}>
            <title>{title} &middot; Jimo</title>

            <body className={bodyClassName} />
          </ReactHelmet>,
          (Environment.NODE_ENV === 'stage' ||
            Environment.NODE_ENV === 'testing') && (
            <div key={2} className="stage-indicator">
              <span role="img" aria-label="stage">
                {' '}
                🚧{' '}
              </span>
              You are in the stage environment
            </div>
          ),
          <Layout key={3} component={Component} {...props} />,
        ];
      }}
    />
  );
};

export const PublicRoute = (props) => <MyRoute {...props} />;

export const PrivateRoute = (props) => <MyRoute checkAuth {...props}></MyRoute>;

export const PublicOnlyRoute = (props) => (
  <MyRoute checkUnAuth {...props}></MyRoute>
);
