import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import {toastDanger, toastWarning} from 'components/Toaster';
import {crispHelpers, errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {
  ROUTE_LOGOUT,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
} from 'router/routes.const';
import {authService} from 'services';
import {ME_F_STATUS_PASSWORD_NOT_SETUP} from 'services/me';
import {Swaler} from 'swaler';
import './_setup-password.scss';
import logo from './imgs/logo.svg';

const logger = new Swaler('SetupPassword');

export const OnboardingRegisterSetupPassword = ({
  playAnimationOut,
  triggerAnimationOut,
}) => {
  const history = useHistory();

  const location = useLocation();

  const user = useSelector((state) => state.general.user);

  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const code = location?.state?.c || null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      await authService.updatePassword(password, location.state.c);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsSubmitting(false);
      logger.error("couldn't update password, err : ", code);
      toastDanger([title, message], {actions});
    }
    triggerAnimationOut({
      name: 'from-register-to-details',
      duration: 500,
      next: () => {
        history.push(ROUTE_ONBOARDING_DETAILS_STEP_1_1, {
          playDetailsAnimationIn: true,
        });
      },
    });
  };

  useEffect(() => {
    if (code == null) {
      toastWarning([
        'Verification failed',
        "Something went wrong. Do not worry, just use again the link in the verify email we sent you. If the issue persists, let's chat!",
        {
          actions: [
            {
              text: 'Chat with us',
              props: {
                onClick: () =>
                  crispHelpers.startCrispThread(
                    `I'm having issues trying to setup my password during my onboarding. Can you help me ? :-)`
                  ),
                iconLeft: 'icon-chat',
              },
            },
          ],
        },
      ]);
      return history.push(ROUTE_LOGOUT);
    }
    if (
      user != null &&
      hasFlag(ME_F_STATUS_PASSWORD_NOT_SETUP, user.flags) === false
    ) {
      return history.push(ROUTE_ONBOARDING_DETAILS_STEP_1_1);
    }
  }, []);

  const isPasswordSecure = password.length >= 8;

  return (
    <div
      className={classnames('s-onboarding-register-setup', {
        'is-exiting': playAnimationOut,
      })}>
      {user == null ? (
        <DefaultLoader></DefaultLoader>
      ) : (
        <>
          <img className="jimo-logo" src={logo} alt="logo jimo" height={44} />
          <h1>
            Welcome back, <br />
            let's setup your password
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="email-label">Email</div>
            <Input value={user.email} disabled required></Input>
            <div className="password-label">Password</div>
            <Input
              placeholder="type your password..."
              type="password"
              onChange={({target}) => {
                setPassword(target.value);
              }}
              value={password}
              required></Input>
            <div className="password-requirements">
              <div
                className={classnames('requirement-item', {
                  'is-ok': isPasswordSecure,
                })}>
                <i className="icon-tick"></i>8 caracters min
              </div>
            </div>
            <Button
              disabled={isPasswordSecure === false}
              className="btn-sign-up"
              cta
              secondary
              iconRight="icon-chevron-right"
              loading={isSubmitting}>
              Continue
            </Button>
          </form>
        </>
      )}
    </div>
  );
};
