import classnames from 'classnames';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {
  ROUTE_SETTINGS_INSTALLATION,
  ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
} from 'router/routes.const';
import './_Styles.scss';

const Tabs = withRouter((props) => {
  const {items, className, match} = props;
  const isActive = (paths) => [].concat(paths).includes(match.path);
  const classNames = classnames('settings-tabs body-3', className);

  return (
    <div className={classNames}>
      {items.map((i, index) => (
        <Link
          key={index}
          to={{pathname: i.path, state: {doSettingsAnimation: false}}}
          className={classnames('item', {'is-active': isActive(i.path)})}>
          {i.title}
        </Link>
      ))}
    </div>
  );
});

export const InstallationTabs = () => (
  <Tabs
    items={[
      {title: 'Install SDK', path: ROUTE_SETTINGS_INSTALLATION},
      {
        title: 'Identification',
        path: ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION,
      },
    ]}></Tabs>
);
