const {
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_TEXT_LONG,
  STEP_TYPE_NPS,
  STEP_TYPE_SLIDER,
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_SUCCESS,
} = require('services/steps');

export const getNewStepName = (type, steps = []) => {
  let typeName = '';
  if (type === STEP_TYPE_OPINION_SCALE) {
    typeName = 'opinion scale';
  } else if (type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
    typeName = 'multiple choice';
  } else if (type === STEP_TYPE_TEXT_LONG) {
    typeName = 'open question';
  } else if (type === STEP_TYPE_NPS) {
    typeName = 'nps';
  } else if (type === STEP_TYPE_SLIDER) {
    typeName = 'slider';
  } else if (type === STEP_TYPE_CONCEPT_TEST) {
    typeName = 'concept test';
  } else if (type === STEP_TYPE_INTERVIEW) {
    typeName = 'interview';
  } else if (type === STEP_TYPE_SUCCESS) {
    typeName = 'success';
  } else if (type === STEP_TYPE_TEXT_BLOCK) {
    typeName = 'text';
  }

  const typeCount = steps.filter((step) => step.type === type).length;

  const name = `Step ${typeName} ${typeCount + 1}`;
  return name;
};
