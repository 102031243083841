import classnames from 'classnames';
import './_Styles.scss';

export const UserAvatar = (props) => {
  const {
    user,
    jimer,
    children,
    className,
    showBadgeTeam,
    onlyAvatar = false,
    ...rest
  } = props;
  let hasAvatar = false;
  let username = null;
  if (user != null) {
    hasAvatar = user != null && user.avatarUrl != null;
    username = user != null ? user.username : '?';
  } else {
    hasAvatar = jimer != null && jimer.avatarUrl != null;
    username =
      jimer != null &&
      jimer?.externalUsername != null &&
      jimer?.externalUsername.length !== 0
        ? jimer?.externalUsername
        : jimer?.username;
  }
  const classNames = classnames('user-avatar', className, {
    'has-avatar': hasAvatar === true,
  });

  return (
    <div
      style={
        hasAvatar === true ? {backgroundImage: `url(${user.avatarUrl})`} : null
      }
      className={classNames}
      {...rest}>
      {showBadgeTeam === true && (
        <div className="badge-team">
          <i className="icon-tick"></i>
        </div>
      )}
      {hasAvatar === false
        ? username?.length > 0
          ? username[0].toUpperCase()
          : '?'
        : null}
      {children}
    </div>
  );
};
