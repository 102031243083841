import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {crispHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {func} from 'prop-types';
import React, {useContext} from 'react';
import {
  EVOLUTION_TYPE_CHECKLIST,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_TOP_BAR,
} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
} from 'services/steps';
import {CHECKLIST_STEP_TYPE_SUCCESS} from 'shared/front/components/Checklist/utils';
import {BLOCK_DISMISS_CROSS} from 'shared/front/components/Poke/constants/blocks';
import {v4 as uuidv4} from 'uuid';
import './_Styles.scss';
import {
  BLOCKS,
  BLOCK_CATEGORY_INTERACTIONS,
  BLOCK_CATEGORY_MEDIA,
  BLOCK_CATEGORY_TEXT,
  getDefaultBlockFromType,
} from './utils';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const experienceTypes = [
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_USER,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_DISMISS_CROSS,
];

const SectionItem = ({block, selected = false, ...rest}) => {
  const {name, image, imageWidth, imageHeight} = block;
  return (
    <div className={classNames('section-item', {selected: selected})} {...rest}>
      <div className="image-wrapper">
        <img
          src={image}
          alt="img"
          style={{
            ...(imageWidth ? {width: imageWidth} : {}),
            ...(imageHeight ? {height: imageHeight} : {}),
          }}
        />
        <i className="icon-checkbox" />
        <i className="icon-plus-rounded" />
      </div>
      <div className="item-name">{name}</div>
    </div>
  );
};

const BlockManager = ({onClose}) => {
  const {
    evolution,
    controlledEvolution,
    selectedStep: step,
    updateStep,
    selectedBlockType,
    setSelectedBlockType,
    setControlledEvolution,
  } = useContext(BuilderContext);

  const isHint = hasFlag(F_BOOST_SLOT_HINT, controlledEvolution.boostFlags);
  const isChecklist = evolution?.type === EVOLUTION_TYPE_CHECKLIST;
  const isChecklistSuccessStep =
    isChecklist === true && step?.type === CHECKLIST_STEP_TYPE_SUCCESS;

  const addBlock = (type) => {
    const isButton = [
      BLOCK_TYPE_PRIMARY_CTA,
      BLOCK_TYPE_SECONDARY_CTA,
    ].includes(type);
    let defaultValueOverwrite = {};

    if (isButton && isHint) {
      defaultValueOverwrite = {
        value: 'Button;none;none',
      };
    }

    if (isButton && isChecklistSuccessStep) {
      defaultValueOverwrite = {
        value: 'Close;close;',
      };
    }

    if (isButton && step.blocks.some((b) => b.type === type)) {
      updateStep(step.uid, {
        blocks: step.blocks.map((block) => {
          if (block.type === type) {
            return {
              ...block,
              ...defaultValueOverwrite,
              removed: false,
              actions: [
                ...(type === BLOCK_TYPE_PRIMARY_CTA
                  ? [
                      {
                        uid: uuidv4(),
                        type: STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
                        value: 'next-step',
                      },
                    ]
                  : []),
              ],
            };
          }
          return block;
        }),
      });
    } else {
      updateStep(step.uid, {
        blocks: [
          ...(step?.blocks || []),
          {
            ...getDefaultBlockFromType(type, evolution.theme),
            ...defaultValueOverwrite,
          },
        ],
      });
    }
    setSelectedBlockType(type);
    onClose();
  };

  const removeBlock = (type) => {
    if (type === BLOCK_TYPE_DISMISS_CROSS) {
      const removeCrossBlockForStep = (s) => {
        const blocks = [
          ...(s?.blocks?.filter((b) => b.type !== BLOCK_DISMISS_CROSS) || []),
        ];
        return blocks;
      };
      setControlledEvolution({
        ...controlledEvolution,
        steps: controlledEvolution.steps.map((s) => ({
          ...s,
          blocks: removeCrossBlockForStep(s),
        })),
      });
    } else {
      updateStep(step.uid, {
        blocks: step?.blocks.filter((block) => block.type !== type),
      });
      if (selectedBlockType === type) {
        setSelectedBlockType(null);
      }
    }
  };

  if (step == null) {
    return <></>;
  }

  const {blocks = []} = step;

  const isBannerStep = hasFlag(
    F_BOOST_SLOT_TOP_BAR,
    controlledEvolution.boostFlags
  );
  const isHintStep = hasFlag(F_BOOST_SLOT_HINT, controlledEvolution.boostFlags);

  const filteredBlocks = BLOCKS.filter(
    (block) => experienceTypes.includes(block.type) === true
  ).filter((block) => {
    if (isBannerStep === true) {
      return [
        BLOCK_TYPE_TITLE,
        BLOCK_TYPE_BODY,
        BLOCK_TYPE_LABEL,
        BLOCK_TYPE_PRIMARY_CTA,
        BLOCK_TYPE_DISMISS_CROSS,
      ].includes(block.type);
    } else if (isHintStep === true) {
      return [
        BLOCK_TYPE_TITLE,
        BLOCK_TYPE_BODY,
        BLOCK_TYPE_LABEL,
        BLOCK_TYPE_PRIMARY_CTA,
        BLOCK_TYPE_SECONDARY_CTA,
        BLOCK_TYPE_USER,
        BLOCK_TYPE_MEDIA,
        BLOCK_TYPE_DISMISS_CROSS,
      ].includes(block.type);
    } else if (isChecklistSuccessStep === true) {
      return [
        BLOCK_TYPE_TITLE,
        BLOCK_TYPE_BODY,
        BLOCK_TYPE_PRIMARY_CTA,
        BLOCK_TYPE_USER,
        BLOCK_TYPE_MEDIA,
        BLOCK_TYPE_DISMISS_CROSS,
      ].includes(block.type);
    }
    return true;
  });

  const textBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_TEXT
  );
  const mediaBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_MEDIA
  );
  const interactionsBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_INTERACTIONS
  );

  return (
    <div
      className={classNames('block-manager-wrapper', {
        'grid-a-b': isChecklistSuccessStep,
      })}>
      <div className="block-manager">
        <div className="block-manager-header">
          <div className="block-manager-title">Add elements</div>
        </div>
        <Divider />
        <div className="sections">
          {textBlocks.length > 0 && (
            <>
              <div className="section">
                <div className="section-title">Text</div>
                <div className="section-items">
                  {textBlocks.map((block, index) => {
                    const isActive = blocks.find((b) => b.type === block.type);

                    return (
                      <SectionItem
                        key={index}
                        block={block}
                        selected={isActive}
                        onClick={() => {
                          isActive
                            ? removeBlock(block.type)
                            : addBlock(block.type);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider />
            </>
          )}
          {mediaBlocks.length > 0 && (
            <>
              <div className="section">
                <div className="section-title">Media assets</div>
                <div className="section-items">
                  {mediaBlocks.map((block, index) => {
                    const isActive = blocks.find((b) => b.type === block.type);

                    return (
                      <SectionItem
                        key={index}
                        block={block}
                        selected={isActive}
                        onClick={() => {
                          isActive
                            ? removeBlock(block.type)
                            : addBlock(block.type);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider />
            </>
          )}
          {interactionsBlocks.length > 0 && (
            <div className="section">
              <div className="section-title">Interactions</div>
              <div className="section-items">
                {interactionsBlocks.map((block, index) => {
                  const isActive = blocks.find(
                    (b) => b.type === block.type && b.removed !== true
                  );

                  return (
                    <SectionItem
                      key={index}
                      block={block}
                      selected={isActive}
                      onClick={() => {
                        isActive
                          ? removeBlock(block.type)
                          : addBlock(block.type);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="block-request-wrapper">
        <Button
          className="block-request-btn"
          iconLeft="icon-plus-rounded"
          onClick={() =>
            crispHelpers.startCrispThread(
              "Hey, i'd like to request a new type of block!"
            )
          }>
          Request a new block
        </Button>
      </div>
    </div>
  );
};

BlockManager.propTypes = propTypes;
BlockManager.defaultProps = defaultProps;

export default BlockManager;
