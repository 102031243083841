import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_CHECKLIST_TASK_LIST} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import './_Styles.scss';

const GLOBAL_PADDING = 32;

const ChecklistBody = ({children, headerAndFooterHeight}) => {
  const {selectedBlock, inBuilder} = useContext(PokeStateContext);

  const maxHeight = `calc(100vh - ${headerAndFooterHeight + GLOBAL_PADDING}px)`;

  return (
    <div
      className={classNames('checklist-body', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_CHECKLIST_TASK_LIST,
        'in-builder': inBuilder === true,
      })}
      style={{
        ...(inBuilder === true ? {} : {maxHeight: maxHeight}),
      }}>
      {children}
      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistBody;
