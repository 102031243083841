import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  createStepOptionObj,
} from 'services/steps';
import {STEPPER_TYPE_DOT_LINE} from 'shared/front/components/Poke/components/BlockStepper';
import {v4 as uuidv4} from 'uuid';
import Label from './imgs/label.svg';
import Primary from './imgs/primary.svg';
import Profile from './imgs/profile.svg';
import Likes from './imgs/likes.svg';
import Comments from './imgs/comments.svg';

export const POST_BLOCK_CATEGORY_CONTENT = 'CONTENT';
export const POST_BLOCK_CATEGORY_SOCIAL = 'SOCIAL';

export const POST_BLOCK_TYPE_COMMENTS = 'COMMENTS';
export const POST_BLOCK_TYPE_LIKES = 'LIKES';

export const BLOCKS = [
  {
    type: BLOCK_TYPE_LABEL,
    name: 'Label',
    image: Label,
    category: POST_BLOCK_CATEGORY_CONTENT,
  },
  {
    type: BLOCK_TYPE_USER,
    name: 'Profile',
    image: Profile,
    category: POST_BLOCK_CATEGORY_CONTENT,
  },
  {
    type: BLOCK_TYPE_PRIMARY_CTA,
    name: 'CTA',
    image: Primary,
    category: POST_BLOCK_CATEGORY_CONTENT,
  },

  {
    type: POST_BLOCK_TYPE_COMMENTS,
    name: 'Comments',
    image: Comments,
    category: POST_BLOCK_CATEGORY_SOCIAL,
  },
  {
    type: POST_BLOCK_TYPE_LIKES,
    name: 'Likes',
    image: Likes,
    category: POST_BLOCK_CATEGORY_SOCIAL,
  },
];

export const CTA_IN_NEW_TAB = 1;
export const CHOICE_CAN_MULTIPLE = 2;
export const NPS_INTERACTIVE_BACKGROUND = 4;
export const SLIDER_ABOVE_EMOJI_ANIMATION = 8;

/*
{
  fontColor?: string; // title, body, label, primary & secondary cta
  fontSize?: string; // title, body, label, primary & secondary cta, choice
  fontFamily?: string; // title, body, label, primary & secondary cta, choice
  align?: string; // title, body, label, primary & secondary cta, stepper
  position?: string; // media, stepper
  padding?: string; // media, label, primary & secondary cta, choice
  borderRadius?: string; // media, user, choice
  borderColor?: string; // label
  primaryColor?: string; // label, primary & secondary cta, stepper, choice, slider
  secondaryColor?: string; // stepper, slider
  height?: string; // slider
}
*/
export const getDefaultBlockFromType = (type, theme) => {
  const style = theme?.style?.blocksStyle?.[type]?.style;

  if (type === BLOCK_TYPE_TITLE) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_TITLE,
      value: 'Title|-|none|-|',
      style: style || {
        fontColor: '#000000',
        fontSize: 14,
        fontFamily: 'Inter',
        align: 'left', // left|center|right
      },
    };
  } else if (type === BLOCK_TYPE_BODY) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_BODY,
      value: 'Body',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Body',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#000000',
        fontSize: 12,
        fontFamily: 'Inter',
        align: 'left', // left|center|right
      },
    };
  } else if (type === BLOCK_TYPE_LABEL) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_LABEL,
      value: 'New', // name
      style: style || {
        fontColor: '#000000',
        fontFamily: 'Inter',
        fontSize: 12,
        primaryColor: '#FFFFFF',
        borderColor: '#FFFFFF',
        padding: 8,
        borderRadius: 8,
        align: 'left', // left|center|right
      },
    };
  } else if (type === BLOCK_TYPE_MEDIA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_MEDIA,
      value: 'image;;logo;fill', // (image|video);url;alt-text;(fill|fit)
      file: null, // File entity of the media
      style: style || {
        position: 'top', // top|content-in
        padding: 8,
        borderRadius: 8,
      },
    };
  } else if (type === BLOCK_TYPE_USER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_USER,
      value: 'John Doe;CPO @Lance;', // name;role;avatarUrl
      file: null,
      style: style || {
        borderRadius: 6,
      },
    };
  } else if (type === BLOCK_TYPE_STEPPER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_STEPPER,
      style: style || {
        type: STEPPER_TYPE_DOT_LINE, // text|dot|dot-line|line
        position: 'top', // top|bottom
        align: 'left', // left|center|right
        primaryColor: '#3d69ff', // active color
        secondaryColor: '#c3c3c3', // default color
      },
    };
  } else if (type === BLOCK_TYPE_PRIMARY_CTA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_PRIMARY_CTA,
      value: 'continue;step-next;none;', // text;(none|step-next|step-prev|close);(none|url-open|post-open);(url|postUid)
      flags: 0,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 14,
        fontColor: '#000000',
        primaryColor: '#FFFFFF', // default color (hover and active are based on default but play with transparency
        borderColor: '#FFFFFF',
        borderRadius: 8,
        align: 'left', // left|center|right
        padding: 8,
      },
    };
  } else if (type === BLOCK_TYPE_SECONDARY_CTA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_SECONDARY_CTA,
      value: 'back;step-prev;', // text;(step-next|step-prev|url-open|close|post-open);(url|postUid)
      flags: 0,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 14,
        fontColor: '#000000',
        primaryColor: '#FFFFFF', // default color (hover and active are based on default but play with transparency
        borderColor: '#FFFFFF',
        borderRadius: 8,
        align: 'left', // left|center|right
        padding: 8,
      },
    };
  } else if (type === BLOCK_TYPE_ANIMATION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_ANIMATION,
      value: 'confetti',
    };
  } else if (type === BLOCK_TYPE_CHOICE) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHOICE,
      flags: CHOICE_CAN_MULTIPLE,
      options: [
        createStepOptionObj({indexOrder: 0, content: 'Option 1'}),
        createStepOptionObj({indexOrder: 1, content: 'Option 2'}),
      ],
      style: style || {
        fontSize: 14,
        fontFamily: 'Inter',
        padding: 8,
        borderRadius: 8,
        primaryColor: '#000000', // default color also used for the font (hover and active are based on default but play with transparency)
        disposition: 'inline', // inline|block
      },
    };
  } else if (type === BLOCK_TYPE_SLIDER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_SLIDER,
      value: 'emoji;🔥;really bad;really good', // (smiley|emoji);customEmoji;labelLeft;labelRight
      flags: SLIDER_ABOVE_EMOJI_ANIMATION,
      style: style || {
        primaryColor: '#FFFFFF', // Slider tracker color
        secondaryColor: '#000000', // Slider background color
        height: 8,
      },
    };
  } else if (type === BLOCK_TYPE_NPS) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_NPS,
      value: 'feel very bad;feel very good', // labelLeft;labelRight
      flags: NPS_INTERACTIVE_BACKGROUND,
      style: style || {
        padding: 8,
        fontSize: 14,
      },
    };
  } else if (type === BLOCK_TYPE_OPINION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_OPINION,
      value: 'smiley;5;very bad;very good', // (smiley|emoji|custom);numberOfFeelings;labelLeft;labelRight
      style: style || {
        padding: 8,
        fontSize: 20,
      },
    };
  } else if (type === BLOCK_TYPE_OPEN_QUESTION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_OPEN_QUESTION,
      value: 'Enter your answer', // placeholder
      style: style || {
        primaryColor: '#FFFFFF',
        borderRadius: 6,
      },
    };
  } else if (type === BLOCK_TYPE_CONCEPT) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CONCEPT,
      value: 'image;;;', // type;conceptTestUrl;thumbnailUrl;alt-text
      file: null, // File entity of the thumbnail
      style: style || {
        borderRadius: 8,
      },
    };
  } else if (type === BLOCK_TYPE_INTERVIEW) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_INTERVIEW,
      value: 'Book a call;;Not now', // buttonBookText;calendarUrl;buttonCancelText
      style: style || {
        primaryColor: '#FFFFFF', // Background color of the book button
        secondaryColor: '#FFFFFF', // Background color of the cancel button
        fontPrimaryColor: '#000000', // text color of the book button
        fontSecondaryColor: '#000000', // text color of the cancel button
        borderPrimaryColor: '#000000', // border color of the book button
        borderSecondaryColor: '#000000', // border color of the cancel button
        borderRadius: '6px',
      },
    };
  }

  return;
};
