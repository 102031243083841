import classnames from 'classnames';
import Toggle from 'components/Toggle';
import {addFlag, hasFlag, hasFlags, removeFlag} from 'helpers/bitwise';
import useDraggableInPortal from 'hooks/useDraggableInPortal';
import {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
  F_FEEDBACK_REQUEST_BOX,
  F_FEED_SUBSCRIPTION_BOX,
  F_PAGE_FEED,
  F_PAGE_FEEDBACK,
} from 'services/project';
import './_Styles.scss';

const ContentToggler = (props) => {
  const {className, children} = props;
  const classNames = classnames('settings-content-toggler', className);

  return <div className={classNames}>{children}</div>;
};
const CTItem = (props) => {
  const {
    name,
    contentFlags,
    flag,
    className,
    category,
    collapse,
    children,
    onChange,
    disabled,
  } = props;
  const [collapsed, setCollapsed] = useState(true);
  const classNames = classnames('settings-ct-item', className, {
    'is-category': category != null,
  });

  const onCollapse = () => {
    if (collapse === true && hasFlag(flag, contentFlags) === true) {
      return setCollapsed(!collapsed);
    }
  };
  const onToggleChange = (e) => {
    const value = e.target.checked;

    if (collapse === true) {
      if (value === true) {
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
    }
    onChange(flag, e.target.checked);
  };

  return (
    <div className={classNames}>
      <div className="content-wrapper">
        <div className="name-wrapper" onClick={onCollapse}>
          {name}
        </div>
        <div className="toggle-collapse-wrapper">
          <Toggle
            checked={hasFlag(flag, contentFlags)}
            onChange={onToggleChange}
            disabled={disabled}></Toggle>
          {collapse === true && (
            <i
              onClick={onCollapse}
              className={classnames('icon-chevron-bottom', {
                'is-collapsed': collapsed,
                'is-disabled': hasFlag(flag, contentFlags) === false,
              })}></i>
          )}
        </div>
      </div>
      {children != null && (
        <AnimateHeight
          duration={250}
          height={collapsed === true ? 0 : 'auto'}
          className="children-wrapper">
          {children}
        </AnimateHeight>
      )}
    </div>
  );
};

export const PortalContentToggler = (props) => {
  const {contentFlags, onChange, sectionOrder, onSectionOrderChange} = props;
  const sections = sectionOrder.split(';');

  const handleChange = async (flag, value) => {
    const action = value === true ? addFlag : removeFlag;

    onChange(action(flag, contentFlags));
  };

  const onDragStart = ({source, type}) => {};

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: {index: sourceIndex},
      destination: {index: destinationIndex},
    } = result;

    const element = sections.splice(sourceIndex, 1)[0];
    sections.splice(destinationIndex, 0, element);

    onSectionOrderChange(sections.join(';'));
  };

  const renderDraggable = useDraggableInPortal();

  return (
    <ContentToggler className="portal-content-toggler">
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        style={{overflow: 'auto'}}>
        <div>
          <Droppable droppableId="blocks" type="blocks">
            {(dropProvided) => (
              <>
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}>
                  {sections.map((section, index) => {
                    if (section === 'roadmap') {
                      // Roadmap is disabled for now, do not render section settings
                      return <></>;
                    }
                    return (
                      <Draggable
                        key={section}
                        draggableId={section}
                        index={index}>
                        {renderDraggable((dragProvided, snapshot) => (
                          <>
                            <div
                              className={classnames('section-wrapper', {
                                'is-dragging': snapshot.isDragging,
                              })}
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}>
                              <div
                                className="dragger"
                                isDragging={snapshot.isDragging}
                                {...dragProvided.dragHandleProps}>
                                <i className="icon-menu-vertical"></i>
                                <i className="icon-menu-vertical"></i>
                              </div>
                              {section === 'feed' ? (
                                <CTItem
                                  name="Feed"
                                  flag={F_PAGE_FEED}
                                  contentFlags={contentFlags}
                                  category
                                  collapse
                                  onChange={handleChange}
                                  disabled={
                                    hasFlags(
                                      [F_PAGE_FEEDBACK],
                                      contentFlags,
                                      true
                                    ) === false
                                  }>
                                  <CTItem
                                    name="Subscription box"
                                    flag={F_FEED_SUBSCRIPTION_BOX}
                                    contentFlags={contentFlags}
                                    onChange={handleChange}
                                  />
                                </CTItem>
                              ) : (
                                <CTItem
                                  name="Feedback"
                                  flag={F_PAGE_FEEDBACK}
                                  contentFlags={contentFlags}
                                  category
                                  collapse
                                  onChange={handleChange}
                                  disabled={
                                    hasFlags(
                                      [F_PAGE_FEED],
                                      contentFlags,
                                      true
                                    ) === false
                                  }>
                                  <CTItem
                                    name="Request box"
                                    flag={F_FEEDBACK_REQUEST_BOX}
                                    contentFlags={contentFlags}
                                    onChange={handleChange}
                                  />
                                </CTItem>
                              )}
                            </div>
                            {dragProvided.placeholder}
                          </>
                        ))}
                      </Draggable>
                    );
                  })}
                </div>
                {dropProvided.placeholder}
              </>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </ContentToggler>
  );
};
export const WidgetContentToggler = (props) => {
  const {contentFlags, onChange, sectionOrder, onSectionOrderChange} = props;
  const sections = sectionOrder.split(';');

  const handleChange = (flag, value) => {
    const action = value === true ? addFlag : removeFlag;

    if (F_PAGE_FEEDBACK === flag) {
      flag = F_PAGE_FEEDBACK + F_FEEDBACK_REQUEST_BOX;
    }

    onChange(action(flag, contentFlags));
  };

  const onDragStart = ({source, type}) => {};

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: {index: sourceIndex},
      destination: {index: destinationIndex},
    } = result;

    const element = sections.splice(sourceIndex, 1)[0];
    sections.splice(destinationIndex, 0, element);

    onSectionOrderChange(sections.join(';'));
  };

  const renderDraggable = useDraggableInPortal();

  return (
    <ContentToggler className="portal-content-toggler">
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        style={{overflow: 'auto'}}>
        <div>
          <Droppable droppableId="blocks" type="blocks">
            {(dropProvided) => (
              <>
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}>
                  {sections.map((section, index) => {
                    if (section === 'roadmap') {
                      return <></>;
                    }
                    return (
                      <Draggable
                        key={section}
                        draggableId={section}
                        index={index}>
                        {renderDraggable((dragProvided, snapshot) => (
                          <>
                            <div
                              className={classnames('section-wrapper', {
                                'is-dragging': snapshot.isDragging,
                              })}
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}>
                              <div
                                className="dragger"
                                isDragging={snapshot.isDragging}
                                {...dragProvided.dragHandleProps}>
                                <i className="icon-menu-vertical"></i>
                                <i className="icon-menu-vertical"></i>
                              </div>
                              {section === 'feed' ? (
                                <CTItem
                                  name="Feed"
                                  flag={F_PAGE_FEED}
                                  contentFlags={contentFlags}
                                  category
                                  onChange={handleChange}
                                  disabled={
                                    hasFlags(
                                      [F_PAGE_FEEDBACK],
                                      contentFlags,
                                      true
                                    ) === false
                                  }
                                />
                              ) : (
                                <CTItem
                                  name="Request"
                                  flag={F_PAGE_FEEDBACK}
                                  contentFlags={contentFlags}
                                  category
                                  onChange={handleChange}
                                  disabled={
                                    hasFlags(
                                      [F_PAGE_FEED],
                                      contentFlags,
                                      true
                                    ) === false
                                  }
                                />
                              )}
                            </div>
                            {dragProvided.placeholder}
                          </>
                        ))}
                      </Draggable>
                    );
                  })}
                </div>
                {dropProvided.placeholder}
              </>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </ContentToggler>
  );
};
