import {removeTrailingSlash} from 'helpers/evolution';
import {isActiveOnPath} from 'helpers/utils';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_EVERYWHERE,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import {isUrlMatchingNavigation} from 'scenes/PokeBuilder/components/AdoptionStepsManager';

export const arePokeMatchingUrls = (poke1, poke2) => {
  if (poke1 == null || poke2 == null) {
    return false;
  }

  if (
    [poke1.boostedActiveOperator, poke2.boostedActiveOperator].includes(
      ACTIVE_OPERATOR_EVERYWHERE
    )
  ) {
    return true;
  }
  if (poke1.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL) {
    if (poke2.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL) {
      if (
        removeTrailingSlash(poke1.boostedActiveUrl) ===
        removeTrailingSlash(poke2.boostedActiveUrl)
      ) {
        return true;
      } else {
        if (isUrlMatchingNavigation(poke2, poke1.boostedActiveUrl) === true) {
          return true;
        }
      }
    } else if (poke2.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM) {
      if (isUrlMatchingNavigation(poke2, poke1.boostedActiveUrl) === true) {
        return true;
      }
    }
    return false;
  }
  if (poke1.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM) {
    if (poke2.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL) {
      if (isUrlMatchingNavigation(poke1, poke2.boostedActiveUrl) === true) {
        return true;
      }
    }
    if (poke2.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM) {
      return hasCommonMatchingPath(poke1, poke2);
      // return true;
    }
    return false;
  }
  return false;
};

const hasCommonMatchingPath = (poke1, poke2) => {
  const {boostedPathOperator: globalOperator1, boostedPaths: pathRules1 = []} =
    poke1;
  const {boostedPathOperator: globalOperator2, boostedPaths: pathRules2 = []} =
    poke2;

  // Helper function to check if a URL matches the given rules based on the global operator
  const isMatchingPath = (globalOperator, pathRules, currentPath) => {
    // The isActiveOnPath function as described in your previous message
    return isActiveOnPath(globalOperator, pathRules, currentPath);
  };

  // Case 1: Both objects have globalOperator 'OR'
  if (globalOperator1 === 'OR' && globalOperator2 === 'OR') {
    // Check if there is a common URL that matches both objects' rules
    for (const rule1 of pathRules1) {
      for (const rule2 of pathRules2) {
        if (
          isMatchingPath(globalOperator1, [rule1], rule2.path) ||
          isMatchingPath(globalOperator2, [rule2], rule1.path)
        ) {
          return true; // At least one matching URL found
        }
      }
    }
  }

  // Case 2: Both objects have globalOperator 'AND'
  if (globalOperator1 === 'AND' && globalOperator2 === 'AND') {
    // Check if there is a common URL that matches both objects' rules
    for (const rule1 of pathRules1) {
      for (const rule2 of pathRules2) {
        if (
          isMatchingPath(globalOperator1, [rule1], rule2.path) &&
          isMatchingPath(globalOperator2, [rule2], rule1.path)
        ) {
          return true; // At least one matching URL found
        }
      }
    }
  }

  // Case 3: One object has globalOperator 'OR', and the other has globalOperator 'AND'
  if (
    (globalOperator1 === 'OR' && globalOperator2 === 'AND') ||
    (globalOperator1 === 'AND' && globalOperator2 === 'OR')
  ) {
    // Check if there is a common URL that matches both objects' rules
    for (const rule1 of pathRules1) {
      for (const rule2 of pathRules2) {
        if (
          (isMatchingPath(globalOperator1, [rule1], rule2.path) &&
            isMatchingPath(globalOperator2, [rule2], rule1.path)) ||
          (isMatchingPath(globalOperator2, [rule2], rule1.path) &&
            isMatchingPath(globalOperator1, [rule1], rule2.path))
        ) {
          return true; // At least one matching URL found
        }
      }
    }
  }

  return false; // No common matching URL found
};
