import Alert from 'components/Alert';
import React from 'react';

export default class DevAlert extends React.Component {
  render() {
    return (
      <div className="_dev-alert">
        <Alert danger>Hello World!</Alert>
        <Alert info>Hello World!</Alert>
        <Alert success>Hello World!</Alert>
        <Alert warning>Hello World!</Alert>
      </div>
    );
  }
}
