import {dataSelector, generalSelector} from 'selectors';
import {PROJECT_ROLE_CUSTOM} from 'services/project';

/**
 * Let's say we have this array of permissions
 * [ PermissionsSettings.ACCESS, [PermissionsPoke.ACCESS, PermissionsPost.ACCESS] ]
 *
 * It's translated like this : the user must have access to the settings and access to either poke or post
 */
export const hasPermissions = (permissionsToCheck, debug = false) => {
  const projectMember = generalSelector.getProjectMember();
  const builtInRoles = dataSelector.getBuiltInRoles();

  permissionsToCheck = [].concat(permissionsToCheck);

  if (
    projectMember.role === PROJECT_ROLE_CUSTOM &&
    projectMember.customRole == null
  ) {
    console.error(
      `Can't check permissions on member that have a role set to CUSTOM but not custom role available.`
    );
    return false;
  }

  const permissions =
    projectMember.role === PROJECT_ROLE_CUSTOM
      ? projectMember.customRole.permissions
      : builtInRoles.find((r) => r.slug === projectMember.role).permissions;

  if (debug === true) {
    console.debug(permissions, permissionsToCheck);
  }
  return permissionsToCheck.every((permission) =>
    Array.isArray(permission) === true
      ? // Array of permission is considered as "Does the user have at least one of those permission" (OR)
        permission.some((p) => permissions.includes(p))
      : // And single permissions are considered mandatory to have (AND)
        permissions.includes(permission)
  );
};
