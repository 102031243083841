import {uptProject} from 'actions/general';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {errorHelpers} from 'helpers';
import {useDispatch, useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SettingsNotification');

const Notification = () => {
  const dispatch = useDispatch();

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const project = useSelector((state) => generalSelector.getProject(state));

  const handleToggleNotification = async (field, {target}) => {
    try {
      const projectMember = await projectService.updateProjectMemberPreference(
        project.uid,
        {
          [field]: target.checked,
        }
      );

      updateProject({...project, member: projectMember});
      return toastSuccess(
        ['Done!', 'Your notification preferences has been updated!'],
        {autoClose: 2000, toastId: 'changes-saved'}
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        'Unexpected error when updating project member preferences : ',
        code
      );
      return toastDanger([title, message], {actions});
    }
  };

  return (
    <SettingsBody className="s-settings-notifications">
      <div className="title-3">Notifications</div>
      <div className="settings-card card-main">
        <div className="item">
          <div className="left-wrapper">
            <span className="subtitle-3">
              Get email notifications for new Requests
            </span>
            <span className="body-3 n-700">
              Notify me whenever a new Request is published in my Changelog
            </span>
          </div>
          <div className="right-wrapper">
            <Toggle
              checked={project.member.notificationOnRequest}
              onChange={(e) =>
                handleToggleNotification('notificationOnRequest', e)
              }></Toggle>
          </div>
        </div>
        <div className="item">
          <div className="left-wrapper">
            <span className="subtitle-3">
              Get email notifications for new Comments
            </span>
            <span className="body-3 n-700">
              Notify me whenever a new comment is published in my Changelog
            </span>
          </div>
          <div className="right-wrapper">
            <Toggle
              checked={project.member.notificationOnComment}
              onChange={(e) =>
                handleToggleNotification('notificationOnComment', e)
              }></Toggle>
          </div>
        </div>
      </div>
    </SettingsBody>
  );
};

export default Notification;
