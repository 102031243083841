import classNames from 'classnames';
import {bool} from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import Button from './../Button';
import './_Styles.scss';

const propTypes = {
  closable: bool,
};

const defaultProps = {
  closable: true,
};

export const Modal = (props) => {
  const _getClassNames = () => {
    const {
      large,
      small,
      light,
      className,
      animationOnOpen = 'fade-in-top',
    } = props;

    return classNames(
      `modal fast`,
      {
        'is-large': large === true,
        'is-small': small === true,
        'is-light': light === true,
      },
      className,
      animationOnOpen
    );
  };

  const {
    className,
    overlayClassName,
    title = null,
    subtitle = null,
    children,
    footer,
    closable,
    ...rest
  } = props;

  return (
    <ReactModal
      className={_getClassNames()}
      overlayClassName={`modal-overlay animated fadeIn fast ${overlayClassName}`}
      {...rest}
    >
      {title != null && (
        <div className="modal-title subtitle-2">
          {title}
          {closable === true && (
            // TODO: maybe only have one of either onRequestClose and onClose props
            <i className="icon-close n-600" onClick={props.onRequestClose} />
          )}
        </div>
      )}
      {subtitle != null && <div className="modal-subtitle">{subtitle}</div>}
      {children}
      {footer != null && <div className="modal-footer">{footer}</div>}
    </ReactModal>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export const ModalConfirm = ({
  confirmText = 'Yes',
  cancelText = 'No',
  title = 'Are you sure ?',
  onConfirm = () => {},
  onCancel = () => {},
  isConfirming,
  children,
  confirmBtnProps,
  cancelBtnProps,
  className,
  ...rest
}) => {
  return (
    <Modal
      className={classNames('modal-confirm', className)}
      title={title}
      closable={false}
      footer={
        <>
          <Button
            disabled={isConfirming}
            text={cancelText}
            onClick={onCancel}
            {...cancelBtnProps}
          />
          <Button
            text={confirmText}
            onClick={onConfirm}
            primary
            loading={isConfirming}
            {...confirmBtnProps}
          />
        </>
      }
      {...rest}
    >
      {children}
    </Modal>
  );
};

export const ModalConfirmV2 = ({
  confirmText = 'Yes',
  cancelText = 'No',
  title = 'Are you sure ?',
  onConfirm = () => {},
  onCancel = () => {},
  isConfirming,
  children,
  confirmBtnProps,
  cancelBtnProps,
  className,
  ...rest
}) => {
  return (
    <Modal className={classNames('modal-confirm-v2', className)} {...rest}>
      <div className="modal-header subtitle-1">{title}</div>
      <div className="modal-body">{children}</div>
      <div className="modal-actions">
        <Button
          disabled={isConfirming}
          text={cancelText}
          onClick={onCancel}
          {...cancelBtnProps}
        />
        <Button
          text={confirmText}
          onClick={onConfirm}
          primary
          loading={isConfirming}
          {...confirmBtnProps}
        />
      </div>
    </Modal>
  );
};
