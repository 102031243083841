import {default as classNames, default as classnames} from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {default as SelectGroup} from 'components/Select';
import {array, bool, func, object} from 'prop-types';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import {
  BLOCK_TYPE_CHOICE,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {
  OPINION_TYPE_EMOJI,
  OPINION_TYPE_SMILEY,
  OPINION_TYPE_STAR,
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import {BLOCK_OPINION} from 'shared/front/components/Poke/constants/blocks';
import {v4 as uuidv4} from 'uuid';
import './_Styles.scss';

const propTypes = {
  step: object.isRequired,
  options: array,
  steps: array,
  updateStep: func,
  canAddConditions: bool,
  hideNextStep: bool,
};

const defaultProps = {
  options: [],
  steps: [],
  canAddConditions: false,
  hideNextStep: false,
};

const operatorOptions = [
  {label: 'is equal to', value: 'EQUAL_TO'},
  {label: 'is not equal to', value: 'NOT_EQUAL_TO'},
  {label: 'is greater than', value: 'GREATER_THAN'},
  {label: 'is lesser than', value: 'LESSER_THAN'},
  {label: 'contains', value: 'CONTAINS'},
  {label: 'does not contain', value: 'DOES_NOT_CONTAIN'},
];

const conditionOperatorOptions = [
  {label: 'is exactly', value: 'ALL'},
  {label: 'is not', value: 'NOT'},
  {label: 'contains one of', value: 'AT_LEAST_ONE_OF'},
  {label: 'contains none of', value: 'NONE_OF'},
];

const defaultConditionOperator = 'ALL';

const Conditions = ({step, steps, updateStep, canAddConditions}) => {
  const {jumps = [], goTo, blocks = []} = step;
  const blockChoice = blocks?.find((b) => b.type === BLOCK_TYPE_CHOICE);
  const blockOpinion = blocks?.find((b) => b.type === BLOCK_OPINION);
  const {options = []} = blockChoice || {};

  const nestedStepParent = steps.find((s) =>
    s.prototypes?.[0]?.steps.find((s) => s.uid === step.uid)
  );
  const stepsList =
    nestedStepParent != null ? nestedStepParent.prototypes[0].steps : steps;

  const currentStepIndex = stepsList.indexOf(step);

  const stepsOptions = [
    ...stepsList
      .filter((s, index) => index > currentStepIndex + 1)
      .map((s) => ({label: s.name, value: s.uid})),
    ...(nestedStepParent != null
      ? [{label: 'End concept test survey', value: 'end-survey'}]
      : [{label: 'End survey', value: 'end-survey'}]),
  ];

  const conditionOptions =
    options.map((o) => ({label: o.content, value: o.uid})) || [];

  if (currentStepIndex + 1 === stepsList.length) {
    return (
      <div className="conditions">
        <div className="last-step-text">
          You can't add conditions on the last step
        </div>
      </div>
    );
  }

  const opinionValue = parseOpinionValue(blockOpinion?.value || '');

  const defaultCondition = (
    <div className="default-wrapper">
      <div className="subtitle-4 n-800">
        {jumps.length > 0 ? 'Otherwise' : 'Always'}
      </div>
      <div className="section">
        <div className="body-3 n-700">Go to</div>
        <SelectGroup
          isSearchable={false}
          className="condition-select"
          menuPortalTarget={document.body}
          options={[
            {label: stepsList[currentStepIndex + 1]?.name, value: null},
            ...stepsOptions,
          ]}
          placeholder="Select a step"
          onChange={(s) => {
            updateStep({
              ...step,
              ...(s.value === 'end-survey'
                ? {
                    goTo: null,
                    endSurvey: true,
                  }
                : {
                    goTo: s.value,
                    endSurvey: false,
                  }),
            });
          }}
          value={[
            {label: stepsList[currentStepIndex + 1]?.name, value: null},
            ...stepsOptions,
          ].find((o) => {
            if (step.endSurvey === true) {
              return o.value === 'end-survey';
            }
            if (step.goTo == null) {
              return o.value === null;
            }
            return o.value === goTo?.uid || o.value === goTo;
          })}
          isDisabled={false}
          menuPlacement="auto"
        />
      </div>
    </div>
  );

  let opinions = [];
  if (['emoji', 'smiley'].includes(opinionValue.type)) {
    opinions = JSON.parse(
      JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
    );
  } else if (opinionValue.type === 'star') {
    opinions = new Array(opinionValue.scale).fill(0).map((_, i) => {
      return {
        value: `${i + 1}`,
        label: (
          <>
            {new Array(i + 1).fill(0).map(() => (
              <i className="icon-star" style={{color: '#FFC107'}} />
            ))}
          </>
        ),
      };
    });
  }

  return (
    <div className="conditions">
      {jumps.length <= 0 && defaultCondition}
      {canAddConditions === true && (
        <>
          {jumps.map((jump, index) => {
            const isMulti = ['AT_LEAST_ONE_OF', 'NONE_OF'].includes(
              jump.conditionOperator
            );

            return (
              <div className="condition" key={jump.uid}>
                <div className="group-wrapper">
                  <div className="section">
                    <div className="group-title subtitle-4 n-800">If</div>
                    <Button
                      className="delete-condition-btn"
                      iconOnly
                      danger
                      tertiary
                      iconRight="isax isax-trash"
                      onClick={() =>
                        updateStep({
                          ...step,
                          jumps: jumps.filter((j) => j.uid !== jump.uid),
                        })
                      }
                    />
                  </div>

                  <div className="section">
                    <div className="body-3 n-700">Answer</div>
                    {[
                      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
                      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
                    ].includes(step.type) ||
                    (STEP_TYPE_OPINION_SCALE === step.type &&
                      [OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(
                        opinionValue.type
                      )) ? (
                      <div className="condition-operator-wrapper">
                        <SelectGroup
                          isSearchable={false}
                          options={conditionOperatorOptions}
                          onChange={(option) => {
                            updateStep({
                              ...step,
                              jumps: jumps.map((j) => {
                                if (j.uid === jump.uid) {
                                  return {
                                    ...j,
                                    conditionOperator: option.value,
                                    // remove extra values if switching to exact and type is single select
                                    ...(step.type ===
                                      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT &&
                                    j.optionValues?.length > 1
                                      ? {optionValues: [j.optionValues[0]]}
                                      : step.type === STEP_TYPE_OPINION_SCALE &&
                                        (j.value || '').split(';').length > 1
                                      ? {value: j.value.split(';')[0]}
                                      : {}),
                                  };
                                }
                                return j;
                              }),
                            });
                          }}
                          className={classnames('condition-operator-select')}
                          value={conditionOperatorOptions.find(
                            (c) =>
                              c.value ===
                              (jump.conditionOperator ||
                                defaultConditionOperator)
                          )}
                        />
                      </div>
                    ) : [
                        STEP_TYPE_SLIDER,
                        STEP_TYPE_TEXT_LONG,
                        STEP_TYPE_NPS,
                      ].includes(step.type) ||
                      (step.type === STEP_TYPE_OPINION_SCALE &&
                        OPINION_TYPE_STAR === opinionValue?.type) ? (
                      <SelectGroup
                        isSearchable={false}
                        className="condition-operator-select"
                        menuPortalTarget={document.body}
                        options={
                          [
                            STEP_TYPE_SLIDER,
                            STEP_TYPE_NPS,
                            STEP_TYPE_OPINION_SCALE,
                          ].includes(step.type)
                            ? operatorOptions.filter((e) =>
                                [
                                  'EQUAL_TO',
                                  'NOT_EQUAL_TO',
                                  'GREATER_THAN',
                                  'LESSER_THAN',
                                ].includes(e.value)
                              )
                            : operatorOptions.filter((e) =>
                                [
                                  'EQUAL_TO',
                                  'NOT_EQUAL_TO',
                                  'CONTAINS',
                                  'DOES_NOT_CONTAIN',
                                ].includes(e.value)
                              )
                        }
                        placeholder="Select an operator"
                        closeMenuOnSelect
                        onChange={(option) => {
                          updateStep({
                            ...step,
                            jumps: jumps.map((j) => {
                              if (j.uid === jump.uid) {
                                return {
                                  ...j,
                                  operator: option.value,
                                };
                              }
                              return j;
                            }),
                          });
                        }}
                        value={operatorOptions?.find(
                          (c) => jump.operator === c.value
                        )}
                      />
                    ) : null}
                  </div>

                  <div className="section">
                    <div className="body-3 n-700">Condition</div>
                    {[
                      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
                      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
                    ].includes(step.type) && (
                      <SelectGroup
                        isSearchable={false}
                        className="condition-values-select"
                        menuPortalTarget={document.body}
                        options={conditionOptions}
                        placeholder="Select an answer"
                        isMulti={
                          step.type ===
                            STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ||
                          ['AT_LEAST_ONE', 'NONE'].includes(
                            jump.conditionOperator
                          )
                        }
                        closeMenuOnSelect={
                          step.type ===
                            STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT ||
                          ['AT_LEAST_ONE', 'NONE'].includes(
                            jump.conditionOperator
                          )
                        }
                        onChange={(options) => {
                          updateStep({
                            ...step,
                            jumps: jumps.map((j) => {
                              if (j.uid === jump.uid) {
                                return {
                                  ...j,
                                  optionValues:
                                    step.type ===
                                      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ||
                                    ['AT_LEAST_ONE', 'NONE'].includes(
                                      jump.conditionOperator
                                    )
                                      ? options.map((o) => o.value)
                                      : [options.value],
                                };
                              }
                              return j;
                            }),
                          });
                        }}
                        value={conditionOptions?.filter((c) =>
                          jump.optionValues
                            ?.map((v) => v.uid || v)
                            .includes(c.value)
                        )}
                        isDisabled={false}
                      />
                    )}
                    {step.type === STEP_TYPE_OPINION_SCALE && (
                      <Dropdown
                        className="users-responses-dropdown"
                        position="bottom left"
                        triggerClassName={classNames({
                          'is-set': false,
                        })}
                        contentStyle={{zIndex: 1001}}
                        repositionOnResize={false}
                        trigger={
                          <Button
                            className="opinion-scale-dropdown-btn body-4"
                            thin
                            iconRight="icon-chevron-bottom">
                            {isMulti === true ? (
                              (jump.value || '').split(';').filter((n) => n)
                                .length > 0 ? (
                                opinions
                                  .filter((o) =>
                                    (jump.value || '')
                                      .split(';')
                                      .includes(o.value)
                                  )
                                  .map((o, i) => (
                                    <span key={i}>
                                      {i > 0 ? ', ' : ''}
                                      {o.label}
                                    </span>
                                  ))
                              ) : isMulti ? (
                                'Select value(s)'
                              ) : (
                                'Select value'
                              )
                            ) : opinions.find((o) => o.value === jump.value) !=
                              null ? (
                              <span>
                                {
                                  opinions.find((o) => o.value === jump.value)
                                    .label
                                }
                              </span>
                            ) : (
                              'Select value'
                            )}
                          </Button>
                        }>
                        <div className="setting-list">
                          {opinions?.map((option) => {
                            const isSelected = isMulti
                              ? (jump.value || '')
                                  .split(';')
                                  .includes(option.value)
                              : jump.value === option.value;
                            return (
                              <div
                                className={classNames('setting-item', {
                                  'is-set': isSelected,
                                })}
                                onClick={() => {
                                  updateStep({
                                    ...step,
                                    jumps: jumps.map((j) => {
                                      if (j.uid === jump.uid) {
                                        const alreadyHasValue =
                                          isMulti &&
                                          (j.value || '')
                                            .split(';')
                                            .includes(option.value);
                                        let newValue;
                                        if (isMulti) {
                                          if (alreadyHasValue) {
                                            newValue = j.value
                                              .split(';')
                                              .filter((v) => v !== option.value)
                                              .join(';');
                                          } else {
                                            newValue = [
                                              ...(j.value || '').split(';'),
                                              option.value,
                                            ].join(';');
                                          }
                                        } else {
                                          newValue = option.value;
                                        }
                                        return {
                                          ...j,
                                          value: newValue,
                                        };
                                      }
                                      return j;
                                    }),
                                  });
                                }}>
                                {isMulti && (
                                  <>
                                    {isSelected ? (
                                      <i className="icon-checkbox"></i>
                                    ) : (
                                      <i className="icon-checkbox-o"></i>
                                    )}
                                  </>
                                )}
                                <div className="option-info">
                                  <div className="option-label">
                                    {option.label}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Dropdown>
                    )}
                    {[STEP_TYPE_TEXT_LONG, STEP_TYPE_NPS].includes(
                      step.type
                    ) && (
                      <Input
                        required
                        placeholder={
                          step.type === STEP_TYPE_OPINION_SCALE
                            ? 'Stars amount'
                            : step.type === STEP_TYPE_NPS
                            ? 'Number 1-10'
                            : ''
                        }
                        className="value-input"
                        name="value"
                        type={
                          [STEP_TYPE_OPINION_SCALE, STEP_TYPE_NPS].includes(
                            step.type
                          )
                            ? 'number'
                            : 'string'
                        }
                        min={0}
                        max={
                          step.type === STEP_TYPE_OPINION_SCALE
                            ? opinionValue?.scale
                            : step.type === STEP_TYPE_NPS
                            ? 10
                            : 100
                        }
                        value={jump.value || ''}
                        onChange={(e) => {
                          let correctedValue = e.target.value;
                          if (step.type === STEP_TYPE_NPS) {
                            correctedValue =
                              correctedValue > 10
                                ? 10
                                : Math.max(1, correctedValue);
                          }
                          updateStep({
                            ...step,
                            jumps: jumps.map((j) => {
                              if (j.uid === jump.uid) {
                                return {
                                  ...j,
                                  value: correctedValue?.toString(),
                                };
                              }
                              return j;
                            }),
                          });
                        }}
                      />
                    )}
                    {step.type === STEP_TYPE_SLIDER && (
                      <PixelPicker
                        value={jump.value || 0}
                        min={0}
                        max={100}
                        label="%"
                        onChange={(value) => {
                          updateStep({
                            ...step,
                            jumps: jumps.map((j) => {
                              if (j.uid === jump.uid) {
                                return {
                                  ...j,
                                  value: value?.toString(),
                                };
                              }
                              return j;
                            }),
                          });
                        }}
                      />
                    )}
                  </div>

                  <div className="section">
                    <div className="body-3 n-700">Go to</div>

                    <SelectGroup
                      isSearchable={false}
                      className="condition-select"
                      menuPortalTarget={document.body}
                      options={[
                        {
                          label: stepsList[currentStepIndex + 1]?.name,
                          value: stepsList[currentStepIndex + 1]?.uid,
                        },
                        ...stepsOptions,
                      ]}
                      placeholder="Select a step"
                      onChange={(s) => {
                        updateStep({
                          ...step,
                          jumps: jumps.map((j) => {
                            if (j.uid === jump.uid) {
                              return {
                                ...j,
                                ...(s.value === 'end-survey'
                                  ? {
                                      goTo: null,
                                      endSurvey: true,
                                    }
                                  : {
                                      goTo: s.value,
                                      endSurvey: false,
                                    }),
                              };
                            }
                            return j;
                          }),
                        });
                      }}
                      value={
                        [
                          {
                            label: stepsList[currentStepIndex + 1]?.name,
                            value: stepsList[currentStepIndex + 1]?.uid,
                          },
                          ...stepsOptions,
                        ].find((o) => {
                          if (jump.endSurvey === true) {
                            return o.value === 'end-survey';
                          }
                          return (
                            o.value === jump.goTo?.uid || o.value === jump.goTo
                          );
                        }) || null
                      }
                      isDisabled={false}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <Button
            className="add-condition-btn"
            onClick={() => {
              updateStep({
                ...step,
                jumps: [
                  ...jumps,
                  {
                    uid: uuidv4(),
                    conditionOperator: 'ALL',
                    value: null,
                    optionValues: [],
                    goTo: null,
                    endSurvey: false,
                    operator: 'EQUAL_TO',
                  },
                ],
              });
            }}
            iconLeft="icon-plus-rounded">
            add a condition
          </Button>
        </>
      )}
      {jumps.length > 0 && (
        <>
          <Divider />
          {defaultCondition}
        </>
      )}
    </div>
  );
};

Conditions.propTypes = propTypes;
Conditions.defaultProps = defaultProps;

export default Conditions;
