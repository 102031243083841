import classnames from 'classnames';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {useEffect, useRef} from 'react';
import {useQuery} from 'react-query';
import {Link, useHistory} from 'react-router-dom/cjs/react-router-dom';
import {
  ROUTE_USERS_IMPORT_CSV,
  ROUTE_USERS_IMPORT_CSV_WITH_ID,
} from 'router/routes.const';
import {dataImportService} from 'services';
import {
  DATA_IMPORT_STATUS_DONE,
  DATA_IMPORT_STATUS_DRAFT,
  DATA_IMPORT_STATUS_ERROR,
  DATA_IMPORT_STATUS_IN_PROGRESS,
} from 'services/data-import';
import './_Styles.scss';

export default function Imports() {
  const history = useHistory();

  const refInterval = useRef(null);
  const refInitialLoad = useRef(false);
  const refPreviousImports = useRef([]);
  const {
    data: imports,
    isFetching,
    refetch: refetchImports,
  } = useQuery({
    queryKey: 'user-imports',
    queryFn: () => dataImportService.fetchAllImportCsvUsers(),
    onSuccess: (imports) => {
      if (refInitialLoad.current === false) {
        refInitialLoad.current = true;
        refPreviousImports.current = imports;
      }
    },
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (
      imports.length > 0 &&
      imports.filter((i) => i.status === DATA_IMPORT_STATUS_IN_PROGRESS)
        .length > 0 &&
      refInterval.current == null
    ) {
      refInterval.current = setInterval(() => {
        refetchImports();
      }, 5000);
    }
    if (
      refInterval.current != null &&
      (imports.length === 0 ||
        imports.filter((i) => i.status === DATA_IMPORT_STATUS_IN_PROGRESS)
          .length === 0)
    ) {
      clearInterval(refInterval.current);
      refInterval.current = null;
    }

    const importPreviouslyInProgress = refPreviousImports.current.filter(
      (i) => i.status === DATA_IMPORT_STATUS_IN_PROGRESS
    );

    importPreviouslyInProgress.forEach((previousImport) => {
      if (
        imports.find(
          (i) =>
            i.uid === previousImport.uid && i.status === DATA_IMPORT_STATUS_DONE
        ) != null
      ) {
        toastSuccess(
          [
            `Import completed`,
            `Your import ${previousImport.name} has been completed successfully.`,
          ],
          {
            actions: [
              {
                text: 'Go to import',
                props: {
                  onClick: () =>
                    history.push(
                      ROUTE_USERS_IMPORT_CSV_WITH_ID(previousImport.uid)
                    ),
                },
              },
            ],
          }
        );
      }
    });
    refPreviousImports.current = imports;
  }, [imports]);

  return (
    <>
      <div
        className={classnames('imports-wrapper', {
          'is-fetching':
            isFetching === true && refInitialLoad.current === false,
          'is-empty': imports.length === 0,
        })}>
        {isFetching === true && refInitialLoad.current === false ? (
          <DefaultLoader width="22px" />
        ) : (
          <>
            {imports.length > 0 ? (
              <>
                <div className="cols-header">
                  <div className="col-header body-3"></div>
                  <div className="col-header body-3">Creation date</div>
                  <div className="col-header body-3">Name</div>
                  <div className="col-header body-3">Description</div>
                  <div className="col-header body-3">Row detected</div>
                  <div className="col-header body-3">Row processed</div>
                  <div className="col-header body-3">Status</div>
                </div>
                <div className="list-import">
                  {imports.map((item) => {
                    return (
                      <Link
                        to={ROUTE_USERS_IMPORT_CSV_WITH_ID(item.uid)}
                        className="item-import">
                        <div className="import-icon">
                          <i className="isax isax-document-upload"></i>
                        </div>
                        <div className="import-created-at">
                          <div className="body-3">
                            {dayjs(item.createdAt).fromNow()}
                          </div>
                          {item.author != null && (
                            <div className="body-4 n-600">
                              By {item.author?.firstName}
                            </div>
                          )}
                        </div>
                        <div className="import-name">{item.name}</div>
                        <div className="import-description">
                          {item.description}
                        </div>
                        <div className="import-row-imported">
                          {item.config.totalRow}
                        </div>
                        <div className="import-row-proceed">
                          {item.config.totalRowProceed}{' '}
                          {item.status === DATA_IMPORT_STATUS_IN_PROGRESS && (
                            <div className="body-4 n-500">
                              (
                              {item.config.totalRow === 0
                                ? 0
                                : Math.round(
                                    (item.config.totalRowProceed /
                                      item.config.totalRow) *
                                      100
                                  )}
                              %)
                            </div>
                          )}
                        </div>
                        <div className="import-status">
                          {item.status === DATA_IMPORT_STATUS_IN_PROGRESS && (
                            <div className="label label-in-progress body-4">
                              In progress
                            </div>
                          )}
                          {item.status === DATA_IMPORT_STATUS_DONE && (
                            <div className="label label-done body-4">Done</div>
                          )}
                          {item.status === DATA_IMPORT_STATUS_DRAFT && (
                            <div className="label label-draft body-4">
                              Draft
                            </div>
                          )}
                          {item.status === DATA_IMPORT_STATUS_ERROR && (
                            <div className="label label-error body-4">
                              Error
                            </div>
                          )}
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="empty-state-imports">
                <div className="subtitle-1">Import users</div>
                <div className="body-3">
                  Upload a CSV to import users and new custom attributes
                </div>
                <Button
                  iconLeft="isax isax-document-upload"
                  onClick={() => history.push(ROUTE_USERS_IMPORT_CSV)}>
                  Import users
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
