import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import * as Popover from '@radix-ui/react-popover';
import {AddReaction} from '@styled-icons/material-outlined';
import {useEmojiDropdownMenuState} from '@udecode/plate-emoji';
import {useMyPlateEditorRef} from 'components/MarkdownEditor/utils';
import React from 'react';

import MarkToolbarButton from '../MarkToolbarButton';

const EmojiToolbarDropdown = ({children, control, isOpen, setIsOpen}) => {
  return (
    <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Trigger asChild>{control}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className="z-[100]">{children}</Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const EmojiDropdownMenu = ({options, ...props}) => {
  const editor = useMyPlateEditorRef();

  const {emojiPickerState, isOpen, setIsOpen} =
    useEmojiDropdownMenuState(options);

  const handleSelectEmoji = (emoji) => {
    const {selection} = editor;

    if (selection) {
      editor.insertText(emoji.native);
    }
    setIsOpen(false);
  };

  return (
    <EmojiToolbarDropdown
      control={
        <MarkToolbarButton
          isDropdown
          pressed={isOpen}
          tooltip="Emoji"
          {...props}>
          <AddReaction />
        </MarkToolbarButton>
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}>
      <Picker
        {...emojiPickerState}
        data={data}
        onEmojiSelect={handleSelectEmoji}
      />
    </EmojiToolbarDropdown>
  );
};

export default EmojiDropdownMenu;
