import React from 'react';
import './_Styles.scss';

export default function DevIcons() {
  return (
    <div className="_dev-icons">
      <i className="icon-trash"></i>
      <i className="icon-edit"></i>
      <i className="icon-overview"></i>
      <i className="icon-evolution"></i>
      <i className="icon-figma-brands"></i>
      <i className="icon-invision-brands"></i>
      <i className="icon-union"></i>
      <i className="icon-time"></i>
      <i className="icon-thumbs-up"></i>
      <i className="icon-play"></i>
      <i className="icon-startup"></i>
      <i className="icon-filter"></i>
      <i className="icon-goto-rect"></i>
      <i className="icon-goto"></i>
      <i className="icon-configuration"></i>
      <i className="icon-tick"></i>
      <i className="icon-close"></i>
      <i className="icon-info"></i>
      <i className="icon-exclamation"></i>
      <i className="icon-trash"></i>
      <i className="icon-desktop"></i>
      <i className="icon-settings"></i>
      <i className="icon-plan"></i>
      <i className="icon-integrations"></i>
      <i className="icon-ip-blocking"></i>
      <i className="icon-profile"></i>
      <i className="icon-users"></i>
      <i className="icon-projects"></i>
      <i className="icon-edit"></i>
      <i className="icon-brand-chrome"></i>
      <i className="icon-brand-apple"></i>
      <i className="icon-user"></i>
      <i className="icon-play-o"></i>
      <i className="icon-pause"></i>
      <i className="icon-event-input"></i>
      <i className="icon-quotation"></i>
      <i className="icon-event-click"></i>
      <i className="icon-clock"></i>
      <i className="icon-event-naviguate"></i>
      <i className="icon-share"></i>
      <i className="icon-arrow-left"></i>
      <i className="icon-arrow-right"></i>
      <i className="icon-fullscreen"></i>
      <i className="icon-tag"></i>
      <i className="icon-menu-vertical"></i>
      <i className="icon-bell"></i>
      <i className="icon-search"></i>
      <i className="icon-chevron-left"></i>
      <i className="icon-chevron-right"></i>
      <i className="icon-copy"></i>
      <i className="icon-brand-opera"></i>
      <i className="icon-feedback"></i>
      <i className="icon-heatmap"></i>
      <i className="icon-replay"></i>
      <i className="icon-live"></i>
      <i className="icon-overview-old"></i>
      <i className="icon-tablet"></i>
      <i className="icon-mobile"></i>
      <i className="icon-brand-windows"></i>
      <i className="icon-brand-linux"></i>
      <i className="icon-brand-safari"></i>
      <i className="icon-brand-firefox"></i>
      <i className="icon-brand-explorer"></i>
      <i className="icon-brand-edge"></i>
      <i className="icon-brand-android"></i>
      <i className="icon-camera"></i>
      <i className="icon-chevron-bottom"></i>
      <i className="icon-plus"></i>
      <i className="icon-info-circle"></i>
      <i className="icon-tick-circle"></i>
      <i className="icon-exclamation-triangle"></i>
      <i className="icon-exclamation-circle"></i>
      <i className="icon-jimo"></i>
      <i className="icon-link"></i>
      <i className="icon-upload"></i>
      <i className="icon-detect"></i>
    </div>
  );
}
