import axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoints
const EP_ANALYTICS_DAILY_GET = '/tracker/analytics/daily';
const EP_ANALYTICS_WEEKLY_GET = '/tracker/analytics/weekly';
const EP_ANALYTICS_MONTHLY_GET = '/tracker/analytics/monthly';
const EP_LAST_ACTIVITY_GET = '/tracker/analytics/last-activity';

// Methods
export const getAnalyticsDaily = ({startDate, endDate, trackerId}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_DAILY_GET, {
      params: {
        projectId,
        trackerId,
        startDate,
        endDate,
      },
    })
    .then((response) => response.data);
};

export const getAnalyticsWeekly = ({startDate, endDate, trackerId}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_WEEKLY_GET, {
      params: {
        projectId,
        trackerId,
        startDate,
        endDate,
      },
    })
    .then((response) => response.data);
};

export const getAnalyticsMonthly = ({startDate, endDate, trackerId}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_MONTHLY_GET, {
      params: {
        projectId,
        trackerId,
        startDate,
        endDate,
      },
    })
    .then((response) => response.data);
};

export const getLastActivity = (
  {
    trackerId,
    stepId,
    take,
    skip,
    startDate,
    endDate,
    onlyStuckAtStep,
    exportCsv,
  },
  {signal = null} = {}
) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_LAST_ACTIVITY_GET, {
      signal: signal,
      params: {
        projectId,
        trackerId,
        stepId,
        take,
        skip,
        startDate,
        endDate,
        onlyStuckAtStep,
        exportCsv,
      },
    })
    .then((response) => response.data);
};
