import {generalActions} from 'actions';
import classNames from 'classnames';
import {Modal} from 'components/Modal';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag} from 'helpers/bitwise';
import React, {useState} from 'react';
import {PopupModal} from 'react-calendly';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_BANNERS,
  ROUTE_HINTS,
  ROUTE_SURVEYS,
  ROUTE_TOURS,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {meService} from 'services';
import {
  ME_F_ONBOARDING_DISMISSED_EXPERIENCE_BUILDER,
  ME_F_ONBOARDING_DISMISSED_EXPERIENCE_CALL,
  ME_F_ONBOARDING_DISMISSED_EXPERIENCE_TEMPLATES,
} from 'services/me';
import {Swaler} from 'swaler';
import './_Styles.scss';
import BookExpertCall from './images/book-expert-call.svg';
import HowToBuilder from './images/how-to-builder.svg';
import StartWithTemplates from './images/start-with-templates.svg';

const logger = new Swaler('QuickAccess');

const QuickAccessItem = ({className, title, icon, onClick, onDismiss}) => {
  return (
    <div
      className={classNames('quick-access-item', className)}
      onClick={onClick}>
      <div className="icon-wrapper">
        <i className={icon}></i>
      </div>
      <div className="title subtitle-4">{title}</div>
      <div
        className="dismiss-btn"
        onClick={(e) => {
          e.stopPropagation();
          onDismiss();
        }}>
        <i className="icon-close" />
      </div>
    </div>
  );
};

const QuickAccess = ({setShowNewPushModal}) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const user = useSelector((state) => generalSelector.getUser(state));
  const uptUser = (data) => dispatch(generalActions.uptUser(data));

  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const [showModalArcade, setShowModalArcade] = useState(null);

  const handleDismiss = async (flag) => {
    try {
      const onboardingFlags = addFlag(flag, user.onboardingFlags);

      await meService.updateOnboardingFlag(onboardingFlags);
      uptUser({onboardingFlags});
    } catch (err) {
      const {code} = errorHelpers.parseError(err);

      logger.error('Dismissing block failed wuth error ', code);
    }
  };

  const hasDismissedBuilder = hasFlag(
    ME_F_ONBOARDING_DISMISSED_EXPERIENCE_BUILDER,
    user.onboardingFlags
  );
  const hasDismissedTemplates = hasFlag(
    ME_F_ONBOARDING_DISMISSED_EXPERIENCE_TEMPLATES,
    user.onboardingFlags
  );
  const hasDismissedCall = hasFlag(
    ME_F_ONBOARDING_DISMISSED_EXPERIENCE_CALL,
    user.onboardingFlags
  );

  if (
    hasDismissedBuilder === true &&
    hasDismissedTemplates === true &&
    hasDismissedCall === true
  ) {
    return null;
  }

  return (
    <div className="quick-access">
      {hasDismissedBuilder === false && (
        <QuickAccessItem
          className="how-to-builder"
          image={HowToBuilder}
          title="Watch an interactive demo to learn how to use our Builder."
          icon="isax isax-play-circle"
          onClick={() =>
            setShowModalArcade(
              match.path === ROUTE_TOURS
                ? 'https://demo.arcade.software/CEus7jtdZT534XjW5fDK?embed'
                : match.path === ROUTE_SURVEYS
                ? 'https://demo.arcade.software/PNO3xQZGKj1P9E1qZq9D?embed'
                : match.path === ROUTE_BANNERS
                ? 'https://demo.arcade.software/SXXcILnFVpm80WU24FIK?embed'
                : match.path === ROUTE_HINTS
                ? 'https://demo.arcade.software/JxnMTPjH7iADeagiIPiS?embed'
                : ''
            )
          }
          onDismiss={() =>
            handleDismiss(ME_F_ONBOARDING_DISMISSED_EXPERIENCE_BUILDER)
          }
        />
      )}
      {hasDismissedTemplates === false && (
        <QuickAccessItem
          className="start-with-templates"
          image={StartWithTemplates}
          title={`Start building ${
            match.path === ROUTE_TOURS
              ? 'tours'
              : match.path === ROUTE_SURVEYS
              ? 'surveys'
              : match.path === ROUTE_BANNERS
              ? 'banners'
              : match.path === ROUTE_HINTS
              ? 'hints'
              : ''
          }
          with Templates`}
          icon="isax isax-add-circle"
          onClick={() => setShowNewPushModal(true)}
          onDismiss={() =>
            handleDismiss(ME_F_ONBOARDING_DISMISSED_EXPERIENCE_TEMPLATES)
          }
        />
      )}
      {hasDismissedCall === false && (
        <QuickAccessItem
          className="book-expert-call"
          image={BookExpertCall}
          title="Book a call with our experts to review your experiences."
          icon="isax isax-call"
          onClick={() => setShowCalendlyModal(true)}
          onDismiss={() =>
            handleDismiss(ME_F_ONBOARDING_DISMISSED_EXPERIENCE_CALL)
          }
        />
      )}
      <PopupModal
        url={'https://calendly.com/usejimo/demo-and-feedback'}
        onModalClose={() => setShowCalendlyModal(false)}
        open={showCalendlyModal}
        rootElement={document.getElementById('root')}
      />
      <Modal
        isOpen={showModalArcade != null}
        onRequestClose={() => setShowModalArcade(null)}
        className="modal-arcade">
        <iframe
          src={showModalArcade}
          title="Experiences · Jimo"
          frameBorder={0}
          loading="lazy"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
          width={900}
          height={468}
        />
      </Modal>
    </div>
  );
};

export default QuickAccess;
