import axios from 'axios';

// Endpoints
const EP_EVOLUTION_SLACK_CHANNELS = (evolutionId) =>
  `/evolution/${evolutionId}/slack-channels`;

// Methods
export const getSlackChannels = (evolutionId) => {
  return axios
    .get(EP_EVOLUTION_SLACK_CHANNELS(evolutionId))
    .then((response) => response.data);
};

export const updateSlackChannels = (evolutionId, channelIds) => {
  return axios
    .put(EP_EVOLUTION_SLACK_CHANNELS(evolutionId), {channelIds})
    .then((response) => response.data);
};
