import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {removeFlag} from 'helpers/bitwise';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {crispWidgetService} from 'services';
import {F_EXTRA_HAS_CRISP_WIDGET_SETUP} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';

const logger = new Swaler('CrispSettings');

const CrispSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSetupCrisp = async () => {
    setIsLoading(true);
    try {
      await crispWidgetService.setupCrispWidgetIntegration(false);

      updateProject({
        ...project,
        extraFlags: removeFlag(
          F_EXTRA_HAS_CRISP_WIDGET_SETUP,
          project.extraFlags
        ),
      });
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup crisp integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SettingsBody className="crisp-settings">
      <div className="crisp-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Crisp Integration</div>
        </div>
      </div>
      <div className="crisp-settings-content">
        <div className="left-side"></div>
        <div className="right-side">
          <HelpSection
            isDisconnecting={isLoading}
            onDisconnect={handleSetupCrisp}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default CrispSettings;
