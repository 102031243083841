import React from 'react';
import './_Styles.scss';
import {oneOf, bool, node, string} from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: string,
  size: oneOf(['regular', 'small', 'x-small']),
  type: oneOf(['neutral', 'negative', 'alert', 'brand', 'positive']),
  primary: bool,
  secondary: bool,
  iconOnly: bool,
  iconLeft: bool,
  iconRight: bool,
  children: node,
};

const defaultProps = {
  className: '',
  size: 'regular',
  type: 'neutral',
  primary: false,
  secondary: false,
  iconOnly: false,
  iconLeft: false,
  iconRight: false,
  children: null,
};

const Label = ({
  className,
  size,
  type,
  primary,
  secondary,
  iconOnly,
  iconLeft,
  iconRight,
  children,
}) => {
  return (
    <div
      className={classNames('c-label', className, size, type, {
        primary: primary,
        secondary: secondary,
        iconOnly: iconOnly,
        'body-2': size === 'regular',
        'body-3': size === 'small',
        'body-4': size === 'x-small',
      })}>
      {iconLeft && (
        <div className="icon-left">
          <i className={iconLeft} />
        </div>
      )}
      {children}
      {iconRight && (
        <div className="icon-right">
          <i className={iconRight} />
        </div>
      )}
    </div>
  );
};

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;

export default Label;
