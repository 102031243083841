import {SegmentAttributesEditorContext} from 'components/SegmentAttributesEditor';
import SelectGroup from 'components/Select';
import {bool} from 'prop-types';
import React, {useContext} from 'react';
import {AttributeContext} from '../..';
import './_Styles.scss';

const isDate = (v) => new Date(v) instanceof Date && !isNaN(new Date(v));

export const EVENTS_PROPERTIES = {
  HAS_OCCURRED: {
    value: 'JIMO_BASIC_HAS_OCCURRED',
    label: 'Has occurred',
  },
  HAS_NOT_OCCURRED: {
    value: 'JIMO_BASIC_HAS_NOT_OCCURRED',
    label: 'Has not occurred',
  },
  OCCURED_MORE_THAN: {
    value: 'JIMO_BASIC_OCCURRED_MORE_THAN',
    label: 'Has occurred more than',
  },
  OCCURED_LESS_THAN: {
    value: 'JIMO_BASIC_OCCURRED_LESS_THAN',
    label: 'Has occurred less than',
  },
};

const propTypes = {
  onlyBasic: bool,
  event: bool,
};

const defaultProps = {
  onlyBasic: false,
  event: false,
};

const AttributePropertySelect = ({onlyBasic, event: isEvent}) => {
  const {trackedEvents, segmentioEvents, customerioEvents} = useContext(
    SegmentAttributesEditorContext
  );
  const {attribute, updateAttribute} = useContext(AttributeContext);

  const event = [
    ...trackedEvents,
    ...segmentioEvents,
    ...customerioEvents,
  ].find((e) =>
    attribute.eventId != null
      ? e.uid === attribute.eventId
      : e.name === attribute.name
  );

  const handlePropertyChange = (property) => {
    updateAttribute({
      ...attribute,

      ...(isEvent === true
        ? {
            eventOp: null,
            eventValues: [''],
            eventProperty: property.value,
            eventOccurrenceValues: [''],
          }
        : {
            op: null,
            values: [''],
            property: property.value,
            eventOp: null,
            eventValues: [''],
            eventProperty: null,
            eventOccurrenceValues: [''],
          }),
    });
  };

  const propertiesOptions =
    onlyBasic === true
      ? Object.values(EVENTS_PROPERTIES)
      : [
          {label: 'Basic', options: Object.values(EVENTS_PROPERTIES)},
          {
            label: 'Properties',
            options:
              event?.properties?.map((p) => ({label: p.name, value: p.name})) ||
              [],
          },
        ];

  const selectedProperty =
    onlyBasic === true
      ? propertiesOptions.find(
          (p) =>
            p.value ===
            (isEvent === true ? attribute.eventProperty : attribute.property)
        )
      : propertiesOptions
          .map((o) => o.options)
          .flat()
          .find((p) => p.value === attribute.property);

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={propertiesOptions}
      closeMenuOnSelect={true}
      value={selectedProperty}
      placeholder="Select..."
      onChange={handlePropertyChange}
    />
  );
};

AttributePropertySelect.propTypes = propTypes;
AttributePropertySelect.defaultProps = defaultProps;

export default AttributePropertySelect;
