import classnames from 'classnames';
import {useRef} from 'react';
import Popup from 'reactjs-popup';
import './_Styles.scss';

export default function Dropdown(props) {
  const {
    trigger,
    btnOptions,
    children,
    className,
    contentClassName,
    contentAnimation = 'fade-in',
    triggerClassName,
    triggerStyle = {},
    withGlobalBg = false,
    innerRef,
    onOpen = () => {},
    ...rest
  } = props;
  const classNames = classnames('dropdown', className, {
    'dropdown-with-overlay': withGlobalBg === true,
  });
  const contentClassNames = classnames(
    'dropdown-content',
    contentAnimation,
    contentClassName
  );
  const triggerClassNames = classnames('dropdown-trigger', triggerClassName);

  const popupRef = useRef();

  const updatePopupPosition = () => {
    if (popupRef.current) {
      const popupContainer = popupRef.current.parentNode;
      const rect = popupContainer.getBoundingClientRect();
      if (rect.top < 0) {
        popupContainer.style.top = '0px'; // Apply style directly if condition is met
      }
    }
  };

  return [
    <Popup
      key={1}
      position="bottom center"
      closeOnDocumentClick={true}
      trigger={
        <div className={triggerClassNames} style={triggerStyle}>
          {trigger}
        </div>
      }
      arrow={false}
      keepTooltipInside={true}
      className={classNames}
      {...rest}
      onOpen={() => {
        onOpen();
        updatePopupPosition();
      }}
      ref={innerRef}>
      <div className={contentClassNames} ref={popupRef}>
        {children}
      </div>
    </Popup>,
    withGlobalBg === true ? (
      <div className="dropdown-global-background" key={2}></div>
    ) : null,
  ];
}
