import React, {useContext} from 'react';
import {TrackerContext} from 'scenes/Tracker';
import './_Styles.scss';
import {useQuery} from 'react-query';
import {trackerAnalyticsService} from 'services';
import dayjs from 'dayjs';
import {Swaler} from 'swaler';

const logger = new Swaler('FeatureUsageChart');

export const StatBlock = ({icon, title, value, additional}) => {
  return (
    <div className="stat-block">
      <div className="icon-wrapper">{icon}</div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="value-wrapper">
          <div className="value">{value}</div>
          <div className="additional">{additional}</div>
        </div>
      </div>
    </div>
  );
};

const FeatureUsageStats = () => {
  const {tracker} = useContext(TrackerContext);

  const startDate = dayjs().subtract(2, 'week').toDate();
  const endDate = dayjs().toDate();

  const {data: analytics} = useQuery({
    queryKey: ['featureUsageStatsByDate', 'tracker', tracker.uid],
    queryFn: async () => {
      const analytics = await trackerAnalyticsService.getAnalyticsWeekly({
        trackerId: tracker.uid,
        startDate,
        endDate,
      });
      return analytics;
    },
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  const groupedAnalytics = analytics?.reduce(
    (acc, cur) => {
      const {count, unique_count, createdAt} = cur;
      const date = dayjs(parseInt(createdAt, 10));

      if (date.isBefore(dayjs().subtract(1, 'week'))) {
        acc.previous.count += count;
        acc.previous.uniqueCount += unique_count;
      } else {
        acc.current.count += count;
        acc.current.uniqueCount += unique_count;
      }

      return acc;
    },
    {
      current: {count: 0, uniqueCount: 0},
      previous: {count: 0, uniqueCount: 0},
    }
  );

  const {count: currentCount = 0, uniqueCount: currentUniqueCount = 0} =
    groupedAnalytics?.current || {};
  const {count: previousCount = 0, uniqueCount: previousUniqueCount = 0} =
    groupedAnalytics?.previous || {};

  return (
    <div className="feature-usage-stats">
      <StatBlock
        icon={<i className="isax isax-route-square" />}
        title="Overall usage"
        value={currentCount}
        additional={
          previousCount > 0 && currentCount > 0 ? (
            <>
              {currentCount >= previousCount ? (
                <span className="tracker-metric-subtext body-4 g-300">
                  <i className="isax isax-arrow-up-15" />+
                  {Math.round(
                    ((currentCount - previousCount) / previousCount) * 100
                  )}
                  % vs last week
                </span>
              ) : (
                <span className="tracker-metric-subtext body-4 r-300">
                  <i className="isax isax-arrow-down-5" />-
                  {Math.round(
                    ((previousCount - currentCount) / previousCount) * 100
                  )}
                  % vs last week
                </span>
              )}
            </>
          ) : null
        }
      />
      <StatBlock
        icon={<i className="isax isax-profile-2user" />}
        title="Unique users"
        value={currentUniqueCount}
        additional={
          previousUniqueCount > 0 && currentUniqueCount > 0 ? (
            <>
              {currentUniqueCount >= previousUniqueCount ? (
                <span className="tracker-metric-subtext body-4 g-300">
                  <i className="isax isax-arrow-up-15" />+
                  {Math.round(
                    ((currentUniqueCount - previousUniqueCount) /
                      previousUniqueCount) *
                      100
                  )}
                  % vs last week
                </span>
              ) : (
                <span className="tracker-metric-subtext body-4 r-300">
                  <i className="isax isax-arrow-down-5" />-
                  {Math.round(
                    ((previousUniqueCount - currentUniqueCount) /
                      previousUniqueCount) *
                      100
                  )}
                  % vs last week
                </span>
              )}
            </>
          ) : null
        }
      />
    </div>
  );
};

export default FeatureUsageStats;
