import classnames from 'classnames';
import {Modal} from 'components/Modal';
import './_Styles.scss';

const ModalImage = ({isOpen, onRequestClose, children, ...props}) => {
  const classNames = classnames('modal-image-fullscreen');

  return (
    <Modal
      animationClass="fade-in fast"
      className={classNames}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...props}>
      {children}
    </Modal>
  );
};

export default ModalImage;
