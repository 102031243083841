import {useEffect, useState} from 'react';
import './_Styles.scss';

export const LineProgress = (props) => {
  let {lineHeight = 4, baseColor = '#2c3650', value} = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (value > 100) {
      value = 100;
    }
    setProgress(value);
  }, [props.value]);

  return (
    <div className="line-progress" key="1">
      <div
        className="base"
        style={{height: `${lineHeight}px`, backgroundColor: baseColor}}></div>
      <div
        className="tracking"
        style={{width: `${progress}%`, height: `${lineHeight}px`}}></div>
    </div>
  );
};

export const CircularProgress = (props) => {
  const {size = 32, strokeWidth = 4, value} = props;
  const svgSize = size + strokeWidth * 2;
  const radius = size / 2;
  const circumference = radius * 2 * Math.PI;
  const stripeOffset = circumference - (value / 100) * circumference * -1;

  return (
    <svg className="circular-progress" width={svgSize} height={svgSize}>
      <circle
        className="base"
        strokeWidth={strokeWidth}
        fill="transparent"
        cx={svgSize / 2}
        cy={svgSize / 2}
        r={radius}></circle>
      <circle
        className="tracking"
        strokeWidth={strokeWidth}
        fill="transparent"
        cx={svgSize / 2}
        cy={svgSize / 2}
        r={radius}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={stripeOffset}
        stroke-linecap="round"></circle>
    </svg>
  );
};
