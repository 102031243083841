import {toastWarning} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useFetchTaxes');

export const useFetchTaxes = () => {
  const [taxes, setTaxes] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetch({countryCode, zip, plan, modeAnnualBilling}) {
    if (zip.length === 0 || countryCode.length === 0) {
      setTaxes([]);
      return;
    }

    setIsFetching(true);
    try {
      const taxData = await subscriptionService.getTaxData({
        plan,
        modeAnnual: modeAnnualBilling,
        country: countryCode,
        zip: zip,
      });

      setTaxes(taxData);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetch taxes failed with error ', code);
      toastWarning([title, message], {actions, toastId: 'fetch-taxes-failed'});
    } finally {
      setIsFetching(false);
    }
  }

  return {
    taxes,
    isFetching,
    fetch,
  };
};
