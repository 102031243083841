import classNames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import CustomSlider from 'components/CustomSlider';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {hasUsernameGenerated} from 'helpers/utils';
import {htmlToText} from 'html-to-text';
import {string} from 'prop-types';
import React from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {ROUTE_SURVEY_WITH_ID} from 'router/routes.const';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_TITLE,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {getSurvey} from 'services/survey';
import {
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import {
  SLIDER_TYPE_SMILEY,
  parseSliderValue,
} from 'shared/front/components/Poke/components/BlockSlider';
import {
  BLOCK_OPINION,
  BLOCK_SLIDER,
} from 'shared/front/components/Poke/constants/blocks';
import './_Styles.scss';

const propTypes = {
  surveyId: string,
};

const defaultProps = {
  surveyId: null,
};

const SurveyDetails = ({surveyId, onBack}) => {
  const history = useHistory();

  const {data: survey, isLoading: isLoadingSurvey} = useQuery({
    queryKey: ['surveyDetails', surveyId],
    queryFn: () => getSurvey({surveyId}),
    enabled: !!surveyId,
    refetchOnWindowFocus: false,
  });

  if (isLoadingSurvey) {
    return (
      <div className="survey-details">
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      </div>
    );
  }

  const {evolution, jimer, responses} = survey;

  const username =
    hasUsernameGenerated(jimer?.username) && jimer?.externalUsername
      ? jimer?.externalUsername
      : jimer?.username;

  return (
    <div className="survey-details fade-in-right">
      <div className="survey-details-header">
        <Button
          className="back-btn"
          iconOnly
          iconLeft="icon-chevron-left"
          onClick={onBack}
        />
        <div className="icon-wrapper">
          <i className="icon-tour" />
        </div>
        <div className="title">
          <span className="evolution-title">{evolution?.title}</span> /{' '}
          <span className="jimer-username">{username}</span>
        </div>
        <Button
          className="see-all-btn"
          cta
          onClick={() => history.push(ROUTE_SURVEY_WITH_ID(evolution.uid))}>
          See all users answers
        </Button>
      </div>
      <Divider />
      <div className="survey-details-content">
        <div className="survey-trait" />
        {responses
          ?.sort((a, b) => a.step?.indexOrder - b.step?.indexOrder)
          ?.map((response, index) => {
            return <StepResponse key={index} response={response} />;
          })}
      </div>
    </div>
  );
};

SurveyDetails.propTypes = propTypes;
SurveyDetails.defaultProps = defaultProps;

const StepResponse = ({response}) => {
  let content, icon, question, typeName, typeClassName;

  if (response?.step?.type === STEP_TYPE_TEXT_LONG) {
    typeName = 'Open question';
    icon = <i className="icon-vote-open-question" />;
    typeClassName = 'text-long';
    content = (
      <div className="response-text-long">
        <Avatar jimer={response.jimer} />
        <div className="jimer-text">“{response.value}“</div>
      </div>
    );
  } else if (response?.step?.type === STEP_TYPE_NPS) {
    typeName = 'Nps';
    icon = <i className="icon-vote-nps" />;
    typeClassName = 'nps';

    const filteredResponses = response?.step?.responses?.filter((r) => r.value);

    const average =
      Math.round(
        (filteredResponses?.length
          ? filteredResponses?.reduce(
              (acc, curr) => acc + parseInt(curr.value, 10),
              0
            ) / filteredResponses?.length
          : 0) * 10
      ) / 10;

    content = (
      <div className="response-nps">
        <div className="nps-values">
          <div
            className={classNames('nps-value detractor-1', {
              selected: response.value === '0',
            })}>
            0
          </div>
          <div
            className={classNames('nps-value detractor-1', {
              selected: response.value === '1',
            })}>
            1
          </div>
          <div
            className={classNames('nps-value detractor-1', {
              selected: response.value === '2',
            })}>
            2
          </div>
          <div
            className={classNames('nps-value detractor-1', {
              selected: response.value === '3',
            })}>
            3
          </div>
          <div
            className={classNames('nps-value detractor-2', {
              selected: response.value === '4',
            })}>
            4
          </div>
          <div
            className={classNames('nps-value detractor-2', {
              selected: response.value === '5',
            })}>
            5
          </div>
          <div
            className={classNames('nps-value detractor-2', {
              selected: response.value === '6',
            })}>
            6
          </div>
          <div
            className={classNames('nps-value passive', {
              selected: response.value === '7',
            })}>
            7
          </div>
          <div
            className={classNames('nps-value passive', {
              selected: response.value === '8',
            })}>
            8
          </div>
          <div
            className={classNames('nps-value promoter', {
              selected: response.value === '9',
            })}>
            9
          </div>
          <div
            className={classNames('nps-value promoter', {
              selected: response.value === '10',
            })}>
            10
          </div>
        </div>
        <Divider vertical />
        <div className="nps-average-wrapper">
          Avg.
          <div
            className={classNames('nps-average', {
              'detractor-1': response.value <= 3,
              'detractor-2': response.value > 3 && response.value <= 6,
              passive: response.value > 6 && response.value <= 8,
              promoter: response.value > 8,
            })}>
            {average}
          </div>
        </div>
      </div>
    );
  } else if (response?.step?.type === STEP_TYPE_SLIDER) {
    typeName = 'Slider';
    icon = <i className="icon-vote-slider" />;
    typeClassName = 'slider';

    const {blocks = []} = response.step;
    const blockSlider = blocks?.find((b) => b.type === BLOCK_SLIDER);
    const {type, customEmoji} = parseSliderValue(blockSlider.value || '');

    content = (
      <div className="slider-wrapper">
        <CustomSlider
          handle={type === SLIDER_TYPE_SMILEY ? null : customEmoji}
          value={response.value}
          showPercentage
        />
      </div>
    );
  } else if (
    [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(response?.step?.type)
  ) {
    typeName = 'Multiple choice';
    icon = <i className="icon-vote-multi-choice" />;
    typeClassName = 'multiple-choice';

    let {options = [], responses = []} = response.step;

    const choiceBlock = response.step.blocks?.find(
      (b) => b.type === BLOCK_TYPE_CHOICE
    );
    if (choiceBlock != null) {
      options = choiceBlock.options;
    }

    options.forEach((o) => {
      o.responsesCount = responses.filter((r) =>
        r.selectedOptions.map((s) => s.uid).includes(o.uid)
      ).length;
    });
    const totalAnswers = responses.length;

    content = (
      <div className="response-qcm">
        {options
          .sort((a, b) => a.indexOrder - b.indexOrder)
          .map((option) => {
            const percentage =
              Math.round((option.responsesCount * 100) / totalAnswers) || 0;

            const isActiveFilter = response.selectedOptions
              .map((s) => s.uid)
              .includes(option.uid);

            return (
              <div
                className={classNames('option-bar', {active: isActiveFilter})}
                style={{
                  backgroundImage: `linear-gradient(to right, ${
                    isActiveFilter ? 'rgba(18, 96, 235, 0.2)' : '#B6E5FF'
                  } ${percentage}%, rgba(0, 0, 0, 0.05) ${percentage}%, rgba(0, 0, 0, 0.05) ${
                    100 - percentage
                  }%)`,
                }}>
                <div className="label">{option.content}</div>
                <div className="percentage">{percentage} %</div>
                <div className="response-number">{option.responsesCount}</div>
                <i className="icon-users" />
              </div>
            );
          })}
      </div>
    );
  } else if (response?.step?.type === STEP_TYPE_OPINION_SCALE) {
    typeName = 'Opinion scale';
    icon = <i className="icon-vote-opinion" />;
    typeClassName = 'opinion-scale';

    const {responses = [], blocks = []} = response.step;
    const totalAnswers = responses.length;

    const blockOpinion = blocks?.find((b) => b.type === BLOCK_OPINION);
    const opinionValue = parseOpinionValue(blockOpinion?.value || '');

    let opinions = [];
    if (blockOpinion != null) {
      if (['emoji', 'smiley'].includes(opinionValue.type)) {
        opinions = JSON.parse(
          JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
        ).reverse();
      } else if (opinionValue.type === 'star') {
        opinions = new Array(opinionValue.scale)
          .fill(0)
          .map((_, i) => {
            return {
              value: (i + 1).toString(),
              label: new Array(i + 1)
                .fill(0)
                .map(() => (
                  <i className="icon-star" style={{color: '#FFC107'}} />
                )),
            };
          })
          .reverse();
      }
    }

    content = (
      <div className="response-opinion-scale">
        {opinions.map((opinion) => {
          const numberResponses = responses.filter(
            (r) => r.value === opinion.value
          ).length;
          const percentage =
            Math.round((numberResponses * 100) / totalAnswers) || 0;

          const isActiveFilter = response.value === opinion.value;

          return (
            <div className="opinion-item-wrapper">
              {opinion.emoji != null ? (
                opinion.emoji
              ) : (
                <>
                  {['emoji', 'smiley'].includes(opinionValue.type) === true && (
                    <div className="opinion-label">{opinion.label}</div>
                  )}
                </>
              )}
              <div
                className={classNames('opinion-bar', {active: isActiveFilter})}
                style={{
                  backgroundImage: `linear-gradient(to right, ${
                    isActiveFilter ? 'rgba(18, 96, 235, 0.2)' : '#f2dab6'
                  } ${percentage}%, rgba(0, 0, 0, 0.05) ${percentage}%, rgba(0, 0, 0, 0.05) ${
                    100 - percentage
                  }%)`,
                }}>
                {opinionValue.type === 'star' && (
                  <div className="opinion-label">{opinion.label}</div>
                )}
                <div className="content">
                  <div className="percentage">{percentage} %</div>-
                  <div className="count">
                    <div className="response-count">{numberResponses}</div>
                    <i className="icon-users" />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const name = response?.step?.name || `Step ${1}`;
  const titleBlock = response?.step?.blocks?.find(
    (b) => b.type === BLOCK_TYPE_TITLE
  );
  const bodyBlock = response?.step?.blocks?.find(
    (b) => b.type === BLOCK_TYPE_BODY
  );
  if (titleBlock?.value) {
    const [text] = titleBlock.value.split('|-|');
    if (text) {
      question = text;
    }
  } else if (bodyBlock?.value) {
    question = htmlToText(bodyBlock.value || '');
  }

  return (
    <div className="step-response">
      <div className="step-header-wrapper">
        <div className={classNames('infos', typeClassName)}>
          <div className="icon-wrapper">{icon}</div>
          <div className="content">
            <div className="title">
              {name} &bull; {typeName}
            </div>
            <div className="question">{question}</div>
          </div>
        </div>
      </div>
      <div className="step-response-content">{content}</div>
    </div>
  );
};

export default SurveyDetails;
