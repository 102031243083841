import classNames from 'classnames';
import Divider from 'components/Divider';
import {array, bool, func, string} from 'prop-types';
import React from 'react';
import './_Styles.scss';

const propTypes = {
  value: string,
  options: array,
  onSelect: func,
  disabled: bool,
  className: string,
};
const defaultProps = {
  value: null,
  options: [],
  onSelect: () => {},
  disabled: false,
  className: '',
};

const RadioGroup = ({className, value, options, onSelect, disabled}) => {
  return (
    <div className={classNames('radio-group', className, {disabled})}>
      {options.map((option, index) => (
        <>
          <div
            className={classNames('option body-4', {
              selected: option.value === value,
            })}
            onClick={() => {
              if (disabled) return;
              onSelect(option.value);
            }}>
            {option.label}
          </div>
          {index < options.length - 1 && <Divider vertical />}
        </>
      ))}
    </div>
  );
};

RadioGroup.propTypes = propTypes;
RadioGroup.defaultProps = defaultProps;

export default RadioGroup;
