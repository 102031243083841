import Button from 'components/Button';
import Input from 'components/Input';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTE_LOGIN} from 'router/routes.const';
import {authService} from 'services';
import './_Styles.scss';
import logo from './imgs/logo.svg';

class ForgotPass extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isSubmitting: false,
    };
  }

  handleInputChange = ({target}) =>
    this.setState({[target.name]: target.value});
  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({isSubmitting: true});
    try {
      await authService.sendForgotPassword(this.state.email);
      toastSuccess(
        [
          'Check your email box!',
          'We just send you an email, please check your email box!',
        ],
        {toastId: 'email-forgot-password-sent'}
      );
    } catch (err) {
      const {title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {actions});
    }
    this.setState({isSubmitting: false});
  };

  render() {
    const {email, isSubmitting} = this.state;

    return (
      <div className="s-forgot-pass">
        <form className="wrapper" onSubmit={this.handleSubmit}>
          <img className="jimo-logo" src={logo} alt="logo jimo" height={44} />
          <p>
            Please enter your email address, we will send you an email to help
            you connect to your account!
          </p>
          <Input
            placeholder="my@email.com"
            name="email"
            value={email}
            onChange={this.handleInputChange}></Input>
          <div className="actions">
            <Link to={ROUTE_LOGIN}>
              <Button
                className="btn-go-back"
                thin
                light
                iconLeft="icon-chevron-left">
                Go back
              </Button>
            </Link>
            <Button primary loading={isSubmitting}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default ForgotPass;
