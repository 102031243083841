import Input from 'components/Input';
import {forwardRef} from 'react';
import Datepicker from 'react-datepicker';
import SelectAttribute, {ATTRIBUTES} from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [
  OPTIONS.OP_IS,
  OPTIONS.OP_NIS,
  OPTIONS.OP_IS_GREATER,
  OPTIONS.OP_IS_GREATER_OR_EQUAL,
  OPTIONS.OP_IS_LESSER,
  OPTIONS.OP_IS_LESSER_OR_EQUAL,
  OPTIONS.OP_IS_BETWEEN,
  OPTIONS.OP_IS_NBETWEEN,
  OPTIONS.OP_DATE_RELATIVE_DAYS_IN_RANGE,
  OPTIONS.OP_DATE_RELATIVE_DAYS_NIN_RANGE,
];

const string2date = (str) => {
  if (typeof str === 'string') {
    return new Date(str);
  }
  return str;
};

export const AttributeLastActivity = ({
  attribute,
  onDelete,
  onChangeType,
  onUpdate,
  onUpdateValue,
  segmentioFields,
  segmentioEvents,
  trackedEvents,
}) => {
  const DatepickerCustomInput = forwardRef(({value, onClick, index}, ref) => (
    <Input ref={ref} value={value} onClick={onClick} />
  ));
  const pickerProps = [
    OPTIONS.OP_IS_BETWEEN.value,
    OPTIONS.OP_IS_NBETWEEN.value,
  ].includes(attribute.op)
    ? {
        selectsRange: true,
        startDate: attribute.values[0]
          ? string2date(attribute.values[0])
          : null,
        endDate: attribute.values[1] ? string2date(attribute.values[1]) : null,
      }
    : {
        selected: attribute.values[0] ? string2date(attribute.values[0]) : null,
      };
  return (
    <div className="segment-attribute attribute-last-activity">
      <div className="header-row">
        <SelectAttribute
          value={ATTRIBUTES.ATTR_LAST_ACTIVITY}
          onChange={(value) => onChangeType(value)}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => {
            attribute.op = op;
            onUpdate(attribute);
          }}
        />
        {[
          OPTIONS.OP_DATE_RELATIVE_DAYS_IN_RANGE.value,
          OPTIONS.OP_DATE_RELATIVE_DAYS_NIN_RANGE.value,
        ].includes(attribute.op) ? (
          <Input
            className="input-date-number"
            type="number"
            placeholder="7"
            value={attribute.values[0]}
            labelTextRight="days"
            onChange={({target}) => onUpdateValue(0, target.value)}
            disabled
          />
        ) : (
          <Datepicker
            popperPlacement="top"
            {...pickerProps}
            onChange={(date) => {
              if (Array.isArray(date)) {
                onUpdateValue(0, date[0]);
                onUpdateValue(1, date[1]);
              } else {
                onUpdateValue(0, date);
              }
            }}
            customInput={<DatepickerCustomInput index={0} />}
            disabled
          />
        )}
      </div>
    </div>
  );
};
