import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {func, string} from 'prop-types';
import React, {useContext, useEffect, useRef} from 'react';
import {useQuery} from 'react-query';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import {
  ACTION_POST_OPEN,
  ACTION_URL_OPEN,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Action';
import {evolutionService} from 'services';
import {EVOLUTION_STATE_LIVE} from 'services/evolution';
import {BLOCK_TYPE_PRIMARY_CTA} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';

const linkOptions = [
  {label: 'URL', value: ACTION_URL_OPEN},
  {label: 'Launch experience', value: ACTION_POST_OPEN},
];

const propTypes = {
  type: string,
  urlOrPostId: string,
  defaultPokeUrl: string,
  onChange: func,
  setCloseOnDocumentClick: func,
};

const defaultProps = {
  type: null,
  urlOrPostId: '',
  defaultPokeUrl: '',
  onChange: () => {},
  setCloseOnDocumentClick: () => {},
};

const logger = new Swaler('PostBlockCta');

const CtaModal = ({
  type,
  text,
  urlOrPostId,
  defaultPokeUrl,
  onChange,
  setCloseOnDocumentClick,
}) => {
  const {selectedLanguage} = useContext(BuilderContext);

  const heightRef = useRef();

  const {data: pokes, isLoading} = useQuery({
    queryKey: 'posts',
    queryFn: async () => {
      try {
        const posts = await evolutionService.getEvolutions({
          relations: [
            'tags',
            'count.comments',
            'count.votes',
            'steps',
            'analytics',
          ],
          onlyPokes: true,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return posts;
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
        toastDanger([title, message], {actions});
      }
    },
  });

  const height = heightRef.current;

  // hack to trigger dummy window resize event to reposition dropdown
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [height]);

  const postOptions = pokes?.map((post) => ({
    label: post.title,
    value: post.uid,
  }));

  return (
    <div
      className="cta-modal-content"
      ref={(ref) => {
        if (ref != null) {
          heightRef.current = ref.offsetHeight;
        }
      }}>
      <div className="section-title">Text</div>
      <div className="section-item">
        <InputGroup
          className="text-input"
          value={text}
          onChange={({target}) =>
            onChange({type, text: target.value, urlOrPostId, defaultPokeUrl})
          }
          placeholder="Add..."
        />
      </div>
      <Divider />
      <div className="section-title">Action</div>
      <div className="section-item">
        <RadioGroup
          value={type}
          options={linkOptions}
          onSelect={(value) =>
            onChange({type: value, text, urlOrPostId: '', defaultPokeUrl})
          }
          disabled={selectedLanguage != null}
        />
      </div>
      {type === ACTION_URL_OPEN && (
        <div className="section-item">
          <InputGroup
            className="link-input"
            value={urlOrPostId}
            onChange={({target}) =>
              onChange({type, text, urlOrPostId: target.value, defaultPokeUrl})
            }
            labelTextLeft={<i className="isax isax-link-2" />}
            placeholder="Add..."
            disabled={selectedLanguage != null}
          />
        </div>
      )}
      {type === ACTION_POST_OPEN && (
        <>
          <SelectGroup
            className="post-select"
            menuPortalTarget={document.body}
            menuPlacement="top"
            isLoading={isLoading}
            options={postOptions}
            value={postOptions?.find((option) => option.value === urlOrPostId)}
            onChange={(option) => {
              onChange({type, text, urlOrPostId: option.value, defaultPokeUrl});
              setCloseOnDocumentClick(true);
            }}
            onFocus={() => setCloseOnDocumentClick(false)}
            onBlur={() => setCloseOnDocumentClick(true)}
            blurInputOnSelect
            isDisabled={selectedLanguage != null}
          />
          <div className="section-item">
            <InputGroup
              className="link-input"
              value={defaultPokeUrl || ''}
              onChange={({target}) =>
                onChange({
                  type,
                  text,
                  urlOrPostId,
                  defaultPokeUrl: target.value,
                })
              }
              labelTextLeft={<i className="isax isax-link-2" />}
              placeholder="Default experience url"
              disabled={selectedLanguage != null}
            />
          </div>
        </>
      )}
    </div>
  );
};

const PostBlockCta = () => {
  const {evolution, setEvolution, selectedLanguage} =
    useContext(BuilderContext);

  const step = evolution.steps[0] || {};
  const block = step?.blocks?.find((b) => b.type === BLOCK_TYPE_PRIMARY_CTA);
  const {value} = block || {};

  const [text, action, optAction, urlOrPostId, defaultPokeUrl = ''] =
    value.split(';');

  const translation = block?.translations?.find(
    (t) => t.language === selectedLanguage
  );

  const actualText = selectedLanguage != null ? translation?.value : text;

  return (
    <div className="writer-post-block-cta" key={selectedLanguage}>
      <PopupSetting
        className="writer-post-block-cta-dropdown"
        trigger={
          <ClickableInput
            value="CTA"
            leftLabel={
              <div className="icon-wrapper">
                <i className="icon-arrow-right" />
              </div>
            }
            erasable
            onErase={() => {
              setEvolution({
                ...evolution,
                steps: [
                  {
                    ...evolution.steps[0],
                    blocks: evolution.steps[0]?.blocks.filter(
                      (b) => b.type !== BLOCK_TYPE_PRIMARY_CTA
                    ),
                  },
                ],
              });
            }}
          />
        }
        title="CTA"
        content={
          <CtaModal
            type={optAction}
            text={actualText}
            urlOrPostId={urlOrPostId}
            defaultPokeUrl={defaultPokeUrl}
            onChange={({type, text, urlOrPostId, defaultPokeUrl}) => {
              if (selectedLanguage != null) {
                const hasLanguage =
                  block.translations?.find(
                    (t) => t.language === selectedLanguage
                  ) != null;
                setEvolution({
                  ...evolution,
                  steps: [
                    {
                      ...evolution.steps[0],
                      blocks: evolution.steps[0]?.blocks.map((b) => {
                        if (b.type === BLOCK_TYPE_PRIMARY_CTA) {
                          return {
                            ...b,
                            translations: hasLanguage
                              ? b.translations.map((t) => {
                                  if (t.language === selectedLanguage) {
                                    return {
                                      ...t,
                                      value: text,
                                    };
                                  } else {
                                    return t;
                                  }
                                })
                              : [
                                  ...(b?.translations || []),
                                  {
                                    language: selectedLanguage,
                                    value: text,
                                  },
                                ],
                          };
                        }
                        return b;
                      }),
                    },
                  ],
                });
              } else {
                const newValue = `${text};${action};${type};${urlOrPostId};${defaultPokeUrl}`;
                setEvolution({
                  ...evolution,
                  steps: [
                    {
                      ...evolution.steps[0],
                      blocks: evolution.steps[0]?.blocks.map((b) => {
                        if (b.type === BLOCK_TYPE_PRIMARY_CTA) {
                          return {...b, value: newValue};
                        }
                        return b;
                      }),
                    },
                  ],
                });
              }
            }}
          />
        }
        dropdownProps={{
          repositionOnResize: true,
          position: 'top left',
          offsetY: 10,
        }}
      />
    </div>
  );
};

PostBlockCta.propTypes = propTypes;
PostBlockCta.defaultProps = defaultProps;

export default PostBlockCta;
