import Axios from 'axios';

// Endpoint
const EP_INTERCOM_GET_GENERATE_URL = '/intercom/generate-url';
const EP_INTERCOM_POST_EXCHANGE_CODE = '/intercom/exchange-code';
const EP_INTERCOM_DELETE = (intercomId) => `/intercom/${intercomId}`;

// Method
export const generateUrl = (projectId) => {
  return Axios.get(EP_INTERCOM_GET_GENERATE_URL, {
    params: {projectId},
  }).then((response) => response.data);
};

export const exchangeCodeForToken = (projectId, {code, state}) => {
  return Axios.post(
    EP_INTERCOM_POST_EXCHANGE_CODE,
    {
      code,
      state,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};

export const deleteIntercom = (intercomId) =>
  Axios.delete(EP_INTERCOM_DELETE(intercomId)).then(
    (response) => response.data
  );
