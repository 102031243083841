import InputGroup from 'components/Input';

const ChoiceBlockInputs = ({selectedLanguage, block, onChange}) => {
  const {options = []} = block;

  const handleOptionContentChange = (optionId, value) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.options
          ?.find((o) => o.uid === optionId)
          ?.labels?.find((t) => t.language === selectedLanguage) != null;

      onChange({
        options: hasLanguage
          ? options.map((o) => {
              if (o.uid === optionId) {
                o.labels = o.labels.map((t) => {
                  if (t.language === selectedLanguage) {
                    return {
                      ...t,
                      content: value,
                    };
                  } else {
                    return t;
                  }
                });
              }
              return o;
            })
          : options.map((o) => {
              if (o.uid === optionId) {
                o.labels = [
                  ...(o?.labels || []),
                  {language: selectedLanguage, content: value},
                ];
              }
              return o;
            }),
      });
    } else {
      onChange({
        options: options.map((o) =>
          o.uid === optionId ? {...o, content: value} : o
        ),
      });
    }
  };

  return (
    <div className="options">
      {options
        .sort((a, b) => a.indexOrder - b.indexOrder)
        .map((option, index) => {
          const content =
            selectedLanguage != null
              ? option?.labels?.find((l) => l.language === selectedLanguage)
                  ?.content || ''
              : option.content;

          return (
            <div className="choice-wrapper" key={option.uid}>
              <InputGroup
                required
                className="choice-label"
                placeholder={option.content || `Choice ${index + 1}`}
                name="name"
                value={content || ''}
                onChange={(e) =>
                  handleOptionContentChange(option.uid, e.target.value)
                }
              />
            </div>
          );
        })}
    </div>
  );
};

export default ChoiceBlockInputs;
