import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_TOURS} from 'router/routes.const';
import Loading from 'scenes/Loading';
import {generalSelector} from 'selectors';
import {intercomService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('IntercomRedirect');

const IntercomRedirect = () => {
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject());

  useEffect(() => {
    const handleConnectIntercom = async () => {
      try {
        const url = await intercomService.generateUrl(project.uid);
        window.open(url, '_self', 'noopener,noreferrer');
      } catch (err) {
        logger.error(`Failed redirecting to intercom authentication: ${err}`);
        history.push(ROUTE_TOURS);
      }
    };

    handleConnectIntercom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default IntercomRedirect;
