import {uptProject} from 'actions/general';
import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {Modal} from 'components/Modal';
import Tabs from 'components/Tabs';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import Tooltip from 'components/Tooltip';
import {PermissionsPost, PermissionsRequest} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_CHANGELOG_BUILDER,
  ROUTE_DRAFT,
  ROUTE_DRAFT_WITH_ID,
  ROUTE_FEED,
  ROUTE_POST_BUILDER_WRITER,
} from 'router/routes.const';
import Posts from 'scenes/Evolutions';
import Requests from 'scenes/Requests';
import {generalSelector} from 'selectors';
import {commentService, evolutionService, projectService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  F_OPTION_PORTAL_DISPLAY_FEED,
} from 'services/evolution';
import {
  F_EXTRA_HAS_PUBLISHED_CHANGELOG,
  F_EXTRA_PORTAL_WIDGET_ENABLED,
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ChangelogDrawerPreview from './components/ChangelogDrawerPreview';
import RecentComments from './components/RecentComments';

const TAB_FEED = 'Posts';
const TAB_REQUEST = 'Feedback';

const logger = new Swaler('Portal');

const Portal = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const [selectedEvolution, setSelectedEvolution] = useState(null);
  const [refreshChild, setRefreshChild] = useState(true);
  const [showChangelog, setShowChangelog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const prevTabRef = useRef();

  let tab;

  if (routeMatch.path === ROUTE_FEED) {
    tab = TAB_FEED;
  }
  if ([ROUTE_DRAFT, ROUTE_DRAFT_WITH_ID()].includes(routeMatch.path)) {
    tab = TAB_REQUEST;
  }

  const {data: hasAtLeastOnePostLive, isLoading} = useQuery({
    queryKey: ['hasAtLeastOne', 'posts'],
    queryFn: async () => {
      const res = await evolutionService.getEvolutions({
        states: [EVOLUTION_STATE_LIVE, EVOLUTION_STATE_PAUSED], // We consider paused as live as there is currently no paused state for posts
        onlyForMode: F_OPTION_PORTAL_DISPLAY_FEED,
        take: 1,
      });

      return res.total > 0;
    },
    onError: (err) => {
      logger.error('Could not fetch posts, failed with err', err.message);
    },
  });

  const {data: lastComment} = useQuery({
    queryKey: ['lastComment', 'comments'],
    queryFn: async () =>
      commentService.getCommentsForProject({
        limit: 1,
      }),
    onError: (err) => {
      logger.error(
        'Could not fetch last comment, failed with err',
        err.message
      );
    },
  });

  useEffect(() => {
    if (prevTabRef.current !== tab) {
      prevTabRef.current = tab;
      setRefreshChild(true);
    }
  }, [tab]);

  const handleTabChange = (tab) => {
    if (tab === TAB_FEED) {
      history.push(ROUTE_FEED);
    }
    if (tab === TAB_REQUEST) {
      history.push(ROUTE_DRAFT);
    }
  };

  const saveProject = async (project) => {
    setIsSaving(true);
    try {
      const updatedProject = await projectService.updateProject(project.uid, {
        ...project,
      });
      updateProject(updatedProject);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('An error occurred: ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }
  };

  const classNames = classnames('s-portal', {
    'has-not-installed': project.hasSnippetInstalled === false,
    'push-details-open': selectedEvolution != null,
  });

  const contentClassName = `fade-in`;

  const isAdminOrMember = [PROJECT_ROLE_ADMIN, PROJECT_ROLE_MEMBER].includes(
    projectMember.role
  );
  const hasWidgetEnabled = hasFlag(
    F_EXTRA_PORTAL_WIDGET_ENABLED,
    project.extraFlags
  );

  const canCreateAnnouncement = hasPermissions(PermissionsPost.CREATE_EDIT);

  const hasAlreadyPublishedChangelog = hasFlag(
    F_EXTRA_HAS_PUBLISHED_CHANGELOG,
    project.extraFlags
  );

  const hasUnreadComments =
    lastComment != null &&
    dayjs(lastComment[0]?.createdAt).isAfter(
      dayjs(projectMember.lastNotificationOpenedAt)
    );

  return (
    <>
      <div className={classNames}>
        <div className="header">
          <div className="title-3">Changelog</div>
          <div className="actions-wrapper">
            {hasAlreadyPublishedChangelog === false ? (
              <div className="body-3 n-600">Changelog is inactive</div>
            ) : (
              <>
                <div className="changelog-activation body-3">
                  {hasWidgetEnabled === true
                    ? 'Changelog is active'
                    : 'Changelog is inactive'}
                  <Tooltip
                    dark
                    offsetY={8}
                    disabled={
                      ((isLoading === true ||
                        hasAtLeastOnePostLive === false) &&
                        hasWidgetEnabled === false) !== true
                    }
                    trigger={
                      <div style={{display: 'flex'}}>
                        <Toggle
                          disabled={
                            isSaving ||
                            ((isLoading === true ||
                              hasAtLeastOnePostLive === false) &&
                              hasWidgetEnabled === false)
                          }
                          checked={hasWidgetEnabled}
                          onChange={async () => {
                            if (hasWidgetEnabled === true) {
                              setShowWarningModal(true);
                            } else {
                              try {
                                await saveProject({
                                  ...project,
                                  extraFlags: addFlag(
                                    F_EXTRA_PORTAL_WIDGET_ENABLED,
                                    project.extraFlags
                                  ),
                                });
                                toastSuccess(
                                  'Successfully activated Changelog!',
                                  {
                                    toastId: 'changelog-activated',
                                  }
                                );
                              } catch (err) {
                                const {code, title, message, actions} =
                                  errorHelpers.parseError(err);

                                logger.error('An error occurred: ', code);
                                toastDanger([title, message], {actions});
                              }
                            }
                          }}
                        />
                      </div>
                    }>
                    Publish your first post to be able to activate your
                    Changelog
                  </Tooltip>
                </div>
                <Divider vertical />

                <div className="btns-wrapper">
                  <Dropdown
                    offsetY={8}
                    className="recent-comments-dropdown"
                    trigger={
                      <div className="recent-comments-trigger">
                        <Button iconOnly iconLeft="isax isax-message-text" />
                        {hasUnreadComments === true && (
                          <div className="unread-comments-indicator" />
                        )}
                      </div>
                    }>
                    <RecentComments />
                  </Dropdown>
                  <Button
                    className="install-btn"
                    iconLeft="icon-eye"
                    onClick={() => {
                      setShowChangelog(true);
                    }}>
                    View
                  </Button>
                  {isAdminOrMember && (
                    <>
                      <Button
                        className="appearance-btn"
                        iconLeft="icon-settings"
                        onClick={() => history.push(ROUTE_CHANGELOG_BUILDER)}>
                        Customize
                      </Button>
                      {canCreateAnnouncement === true && (
                        <Button
                          reverted
                          primary
                          className="install-btn"
                          iconLeft="icon-plus"
                          onClick={() =>
                            history.push(ROUTE_POST_BUILDER_WRITER('new'))
                          }>
                          New post
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tabs-row">
          <Tabs defaultTab={tab} onTabChange={handleTabChange}>
            {[]
              .concat(
                hasPermissions(PermissionsPost.ACCESS)
                  ? [<div label={TAB_FEED} />]
                  : []
              )
              .concat(
                hasPermissions(PermissionsRequest.ACCESS)
                  ? [<div label={TAB_REQUEST} />]
                  : []
              )}
          </Tabs>
        </div>
        {tab === TAB_FEED && (
          <Posts
            className={contentClassName}
            setSelectedEvolution={setSelectedEvolution}
            refresh={refreshChild}
            hasRefreshed={() => setRefreshChild(false)}
          />
        )}
        {tab === TAB_REQUEST && <Requests className={contentClassName} />}
      </div>

      {showChangelog && (
        <ChangelogDrawerPreview onClose={() => setShowChangelog(false)} />
      )}

      {showWarningModal && (
        <Modal
          className="deactivate-changelog-modal"
          closable={false}
          isOpen={showWarningModal}
          onRequestClose={() => setShowWarningModal(false)}
          title={
            <div className="n-800 title-3">
              Deactivate Changelog & Feedback?
            </div>
          }
          footer={
            <>
              <Button onClick={() => setShowWarningModal(false)}>Cancel</Button>
              <Button
                reverted
                primary
                onClick={async () => {
                  try {
                    await saveProject({
                      ...project,
                      extraFlags: removeFlag(
                        F_EXTRA_PORTAL_WIDGET_ENABLED,
                        project.extraFlags
                      ),
                    });
                    toastSuccess('Successfully deactivated Changelog!', {
                      toastId: 'changelog-deactivated',
                    });
                  } catch (err) {
                    const {code, title, message, actions} =
                      errorHelpers.parseError(err);

                    logger.error('An error occurred: ', code);
                    toastDanger([title, message], {actions});
                  } finally {
                    setShowWarningModal(false);
                  }
                }}>
                Deactivate
              </Button>
            </>
          }>
          <div className="content body-2 n-700">
            Deactivating Changelog & Feedback will remove the Changelog widget
            from your website and stop collecting feedback. You can activate it
            again later without losing posts or requests.
          </div>
        </Modal>
      )}
    </>
  );
};

export default Portal;
