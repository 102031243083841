import axios from 'axios';
import {generalSelector} from 'selectors';
import {
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from './project';

// Endpoint
const EP_BUILT_IN_ROLES_GET_ALL = '/project/built-in-roles';
const EP_CUSTOM_ROLES_GET_ALL = '/project-custom-role';
const EP_CUSTOM_ROLES_CREATE = '/project-custom-role';
const EP_CUSTOM_ROLES_DELETE = (roleId) => `/project-custom-role/${roleId}`;
const EP_CUSTOM_ROLES_UPDATE = (roleId) => `/project-custom-role/${roleId}`;

// Utils
export const getRoleNameBySlug = (slug) => {
  return slug === PROJECT_ROLE_ADMIN
    ? 'Admin'
    : slug === PROJECT_ROLE_MEMBER
    ? 'Contributor'
    : slug === PROJECT_ROLE_EDITOR
    ? 'Editor'
    : 'Viewer';
};

// Methods
export const getBuiltInRoles = () => {
  return axios.get(EP_BUILT_IN_ROLES_GET_ALL).then((response) => response.data);
};
export const getCustomRoles = (project = generalSelector.getProject()) => {
  const projectId = project.uid;

  return axios
    .get(EP_CUSTOM_ROLES_GET_ALL, {params: {projectId}})
    .then((response) => response.data);
};
export const createRole = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {name} = data;

  return axios
    .post(EP_CUSTOM_ROLES_CREATE, {projectId, name})
    .then((response) => response.data);
};
export const deleteRole = (roleId) => {
  return axios
    .delete(EP_CUSTOM_ROLES_DELETE(roleId))
    .then((response) => response.data);
};
export const updateRole = (role) => {
  return axios
    .put(EP_CUSTOM_ROLES_UPDATE(role.uid), role)
    .then((response) => response.data);
};
