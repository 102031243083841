import Button from 'components/Button';
import './_styles.scss';

export const CardCreateTracker = ({onTrackClick}) => {
  return (
    <div className="card-create-tracker">
      <div className="content-wrapper">
        <div className="title-4 b-400">Start tracking this event usage</div>
        <div className="card-description body-2 n-700">
          Start tracking this event usage to get insights on how your users are
          interacting with your product.
        </div>
        <div className="actions-wrapper">
          <Button thin primary onClick={onTrackClick}>
            Track usage
          </Button>
        </div>
      </div>
    </div>
  );
};
