import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {crispHelpers} from 'helpers';
import {func} from 'prop-types';
import React, {useContext} from 'react';
import {
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_LIST,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
} from 'services/steps';
import {
  BLOCK_CATEGORY_INTERACTIONS,
  BLOCK_CATEGORY_MEDIA,
  BLOCK_CATEGORY_TEXT,
  BLOCKS,
  getDefaultBlockFromType,
} from '../BlockManager/utils';
import './_Styles.scss';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const SectionItem = ({block, selected = false, disabled, ...rest}) => {
  const {name, image} = block;
  return (
    <div
      className={classNames('section-item', {
        selected: selected,
        disabled: disabled,
      })}
      {...rest}>
      <div className="image-wrapper">
        <img src={image} alt="img" />
        <i className="icon-checkbox" />
        <i className="icon-plus-rounded" />
      </div>
      <div className="item-name">{name}</div>
    </div>
  );
};

const ChecklistBlockManager = ({onClose}) => {
  const {
    evolution,
    selectedStep: step,
    updateStep,
    selectedBlockType,
    setSelectedBlockType,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  if (step == null) {
    return <></>;
  }

  const {blocks = []} = step;

  const isAddingToChecklist =
    selectedChecklistItemId == null && selectedBlockType == null;
  const isAddingToHeader =
    selectedChecklistItemId == null && selectedBlockType != null;
  const isAddingToTaskListItem = selectedChecklistItemId != null;

  const headerBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );
  const selectedChecklistItemBlock = step?.blocks.find(
    (b) => b.uid === selectedChecklistItemId
  );

  const addBlock = (type) => {
    const existingBlock =
      isAddingToTaskListItem === true
        ? blocks.find((block) => block.type === type)
        : null;

    const newBlock = {
      ...getDefaultBlockFromType(type, evolution.theme),
      ...(existingBlock && {
        style: existingBlock.style,
      }),
      parentBlockId: isAddingToHeader
        ? headerBlock?.uid
        : selectedChecklistItemId,
      ...(type === BLOCK_TYPE_PRIMARY_CTA && {
        value: 'Go',
      }),
      ...(type === BLOCK_TYPE_SECONDARY_CTA && {
        value: 'Skip',
      }),
    };

    updateStep(step.uid, {
      blocks: [...(step?.blocks || []), newBlock],
    });

    setSelectedBlockType(type);
    onClose();
  };

  const removeBlock = (type) => {
    updateStep(step.uid, {
      blocks: step?.blocks.filter((block) => {
        if (isAddingToHeader) {
          return (
            block.type !== type || block.parentBlockId !== headerBlock?.uid
          );
        } else {
          return (
            block.type !== type ||
            (selectedChecklistItemId == null
              ? block.parentBlockId != null
              : block.parentBlockId !== selectedChecklistItemId)
          );
        }
      }),
    });
    if (selectedBlockType === type) {
      setSelectedBlockType(null);
    }
  };

  const filteredBlocks = BLOCKS.filter((block) => {
    if (isAddingToHeader === true) {
      return [
        BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
        BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
        BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
      ].includes(block.type);
    } else if (isAddingToTaskListItem === true) {
      return [
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
      ].includes(block.type);
    } else {
      return [
        BLOCK_TYPE_CHECKLIST_HEADER,
        BLOCK_TYPE_CHECKLIST_TASK_LIST,
        BLOCK_TYPE_CHECKLIST_DISMISS,
      ].includes(block.type);
    }
  });

  const textBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_TEXT
  );
  const mediaBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_MEDIA
  );
  const interactionsBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_INTERACTIONS
  );

  const activeBlocks = isAddingToHeader
    ? blocks.filter((block) => block.parentBlockId === headerBlock?.uid)
    : blocks.filter((block) =>
        selectedChecklistItemId == null
          ? block.parentBlockId == null
          : block.parentBlockId === selectedChecklistItemId
      );

  return (
    <div className="checklist-block-manager-wrapper">
      <div className="block-manager">
        <div className="block-manager-header">
          <div className="block-manager-title">
            Add elements
            {isAddingToChecklist && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper checklist">
                  <i className="isax isax-task-square" />
                </div>{' '}
                Checklist
              </>
            )}
            {isAddingToHeader && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper header">
                  <i className="icon-text" />
                </div>{' '}
                Header
              </>
            )}
            {isAddingToTaskListItem && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper task-item">
                  <i className="isax isax-tick-square" />
                </div>{' '}
                {selectedChecklistItemBlock?.value?.split('|-|')[0]}
              </>
            )}
          </div>
          <i className="icon-close" onClick={onClose} />
        </div>
        <Divider />
        <div className="sections">
          {isAddingToChecklist && (
            <div className="section">
              <div className="section-items">
                {filteredBlocks.map((block, index) => {
                  const isActive = activeBlocks.find(
                    (b) => b.type === block.type
                  );

                  const isDisabled = [
                    BLOCK_TYPE_CHECKLIST_HEADER,
                    BLOCK_TYPE_CHECKLIST_TASK_LIST,
                  ].includes(block.type);

                  return (
                    <SectionItem
                      key={index}
                      block={block}
                      selected={isActive}
                      disabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) {
                          return;
                        }

                        isActive
                          ? removeBlock(block.type)
                          : addBlock(block.type);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {textBlocks.length > 0 && (
            <>
              <div className="section">
                <div className="section-title">Text</div>
                <div className="section-items">
                  {textBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );

                    const isDisabled = [
                      BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
                      BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
                    ].includes(block.type);

                    return (
                      <SectionItem
                        key={index}
                        block={block}
                        selected={isActive}
                        disabled={isDisabled}
                        onClick={() => {
                          if (isDisabled) {
                            return;
                          }

                          isActive
                            ? removeBlock(block.type)
                            : addBlock(block.type);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider />
            </>
          )}
          {mediaBlocks.length > 0 && (
            <>
              <div className="section">
                <div className="section-title">Media assets</div>
                <div className="section-items">
                  {mediaBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );

                    return (
                      <SectionItem
                        key={index}
                        block={block}
                        selected={isActive}
                        onClick={() => {
                          isActive
                            ? removeBlock(block.type)
                            : addBlock(block.type);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider />
            </>
          )}
          {interactionsBlocks.length > 0 && (
            <div className="section">
              <div className="section-title">Interactions</div>
              <div className="section-items">
                {interactionsBlocks.map((block, index) => {
                  const isActive = activeBlocks.find(
                    (b) => b.type === block.type && b.removed !== true
                  );

                  const isDisabled = [
                    BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
                  ].includes(block.type);

                  return (
                    <SectionItem
                      key={index}
                      block={block}
                      selected={isActive}
                      disabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) {
                          return;
                        }

                        isActive
                          ? removeBlock(block.type)
                          : addBlock(block.type);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="block-request-wrapper">
        <Button
          className="block-request-btn"
          iconLeft="icon-plus-rounded"
          onClick={() =>
            crispHelpers.startCrispThread(
              "Hey, i'd like to request a new type of block!"
            )
          }>
          Request a new block
        </Button>
      </div>
    </div>
  );
};

ChecklistBlockManager.propTypes = propTypes;
ChecklistBlockManager.defaultProps = defaultProps;

export default ChecklistBlockManager;
