import classNames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import InputGroup from 'components/Input';
import SegmentIcon from 'components/SegmentIcon';
import {array, func, string} from 'prop-types';
import React, {useState} from 'react';
import {EVENT_SOURCE_SEGMENT} from 'services/event';
import './_Styles.scss';
import JimoGrey from './imgs/jimo_grey.svg';

const EventItem = ({event, className, onSelectEvent = () => {}}) => {
  const isSegment = event?.source === EVENT_SOURCE_SEGMENT;

  return (
    <div
      className={classNames('event-item sw-1', className)}
      onClick={() => {
        onSelectEvent(event);
      }}
      title={event.description}>
      {isSegment ? (
        <div className="segment-icon-wrapper">
          <SegmentIcon />
        </div>
      ) : event.icon ? (
        <div className="event-icon-wrapper">{event.icon}</div>
      ) : null}
      <div className="item-content">
        <div className="event-name body-2 n-800">
          {event.name || 'Unnamed event'}
        </div>
      </div>
    </div>
  );
};

const EventListCategory = ({title, events}) => {
  return (
    <div className="category">
      <div className="category-title body-4 n-500">{title}</div>
      <div className="category-list">{events}</div>
    </div>
  );
};

const propTypes = {
  segmentioEvents: array,
  events: array,
  onCreateOnTheFlyEvent: func,
  onSelectEvent: func,
  stepId: string,
};

const defaultProps = {
  segmentioEvents: null,
  events: [],
  onCreateOnTheFlyEvent: () => {},
  onSelectEvent: () => {},
  stepId: null,
};

const EventsList = ({
  segmentioEvents,
  events,
  onCreateOnTheFlyEvent,
  onSelectEvent,
  stepId,
}) => {
  const [search, setSearch] = useState('');

  const filteredEvents = events
    .filter((event) => {
      return (
        event.name?.toLowerCase().includes(search.toLowerCase()) ||
        event.querySelector?.toLowerCase().includes(search.toLowerCase()) ||
        search === ''
      );
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  const filteredSegmentioEvents = segmentioEvents
    ?.filter((event) => {
      return (
        event.name.toLowerCase().includes(search.toLowerCase()) || search === ''
      );
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="events-list-wrapper">
      <Button
        className="new-event-btn"
        iconRight="isax isax-flash"
        onClick={onCreateOnTheFlyEvent}>
        Create an event on the fly
      </Button>

      <div className="subtitle-3 n-800">Or select an existing event</div>
      {(events.length > 0 || segmentioEvents?.length > 0) !== true ? (
        <div className="empty-state-wrapper">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyResults}
            title="No events found"
            description="Create an event to track user actions"
          />
        </div>
      ) : (
        <>
          <div className="search-wrapper">
            <InputGroup
              className="search-input"
              labelTextLeft={<i className="icon-search"></i>}
              placeholder="Search for an existing event"
              muted
              value={search}
              onChange={({target}) => setSearch(target.value)}
            />
          </div>
          {filteredEvents.length === 0 &&
          filteredSegmentioEvents?.length === 0 ? (
            <div className="no-events">
              <div className="icon-wrapper">
                <img src={JimoGrey} alt="Jimo" />
              </div>
              <div className="content">
                No events found, try another keyword?
              </div>
            </div>
          ) : (
            <div className="events-list">
              <EventListCategory
                title="Jimo events"
                events={
                  <>
                    {filteredEvents.map((event) => (
                      <EventItem
                        event={event}
                        className="element-event"
                        onSelectEvent={onSelectEvent}
                        stepId={stepId}
                      />
                    ))}
                  </>
                }
              />
              {segmentioEvents != null && segmentioEvents?.length > 0 && (
                <EventListCategory
                  title="Segment.io events"
                  events={
                    <>
                      {filteredSegmentioEvents.map((event) => (
                        <EventItem
                          event={event}
                          className="segment-event"
                          onSelectEvent={onSelectEvent}
                          stepId={stepId}
                        />
                      ))}
                    </>
                  }
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

EventsList.propTypes = propTypes;
EventsList.defaultProps = defaultProps;

export default EventsList;
