import {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {ROUTE_LOGIN} from 'router/routes.const';
import {authService} from 'services';

export default function Logout() {
  useEffect(() => {
    authService.signOut();
  }, []);
  return <Redirect to={ROUTE_LOGIN}></Redirect>;
}
