import Input from 'components/Input';
import Select from 'components/Select';
import React, {useState} from 'react';
import './_styles.scss';

export default function DevInput() {
  const [type, setType] = useState('password');
  const [option, setOption] = useState(null);

  return (
    <div className="_dev-input">
      {/* Selects */}
      <Select
        legend="Label"
        options={[
          {label: 'Foo', value: 'Foo'},
          {label: 'Bar', value: 'bar'},
        ]}></Select>
      <Select small isDisabled legend="Label"></Select>
      <div></div>

      {/* Inputs */}
      <Input legend="Label" placeholder="Placeholder"></Input>
      <Input
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"></Input>
      <Input
        warning
        helper="Warning message"
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"></Input>
      <Input
        danger
        helper="Error message"
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"></Input>
      <Input
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"
        loading></Input>
      <Input
        legend="Label"
        disabled
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"></Input>
      <Input
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"
        additional="Additional"></Input>
      <Input
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"
        helper="Helper"></Input>
      <Input
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"
        option
        optionItems={[
          {content: 'Foo', value: 'foo'},
          {content: 'Bar', value: 'bar'},
          {content: 'Another option', value: 'other'},
        ]}
        optionValue={option}
        onOptionSelected={(o) => setOption(o)}
        additional="Additional"></Input>
      <Input
        legend="Label"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"
        option
        optionItems={[
          {content: 'Foo', value: 'foo'},
          {content: 'Bar', value: 'bar'},
          {content: 'Another option', value: 'other'},
        ]}
        optionValue={option}
        onOptionSelected={(o) => setOption(o)}
        trailing="Days"></Input>
      <Input
        type={type}
        legend="Label"
        value="password"
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-eye"
        iconRightProps={{
          onMouseDown: () => setType('text'),
          onMouseUp: () => setType('password'),
        }}></Input>

      {/* Small */}
      <Input
        legend="Label"
        small
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"></Input>
      <Input
        legend="Label"
        small
        placeholder="Placeholder"
        iconLeft="isax isax-sms"
        iconRight="isax isax-sms"></Input>
    </div>
  );
}
