import React, {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {trackerAnalyticsService} from 'services';
import {object} from 'prop-types';
import './_Styles.scss';
import {Swaler} from 'swaler';
import {TrackerContext} from 'scenes/Tracker';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import classNames from 'classnames';
import CompletionRateLegend, {
  trackerStepsColors,
} from '../CompletionRateLegend';
import TrackerListUsersDrawer from 'scenes/Tracker/components/TrackerListUsersDrawer';
import dayjs from 'dayjs';
import JimoGrey from 'components/JimoGrey';
import {generateDatesArray} from 'scenes/Poke/component/Statistics';
import {TIME_FREQUENCY_WEEKLY} from 'scenes/Tracker/components/FeatureUsageChart/components/FrequencySelectionDropdown';

const WeekActivityItem = ({index, week, denominator}) => {
  const {tracker} = useContext(TrackerContext);
  const steps = JSON.parse(JSON.stringify(tracker.steps || []));

  const {steps: stepsData} = week;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const maxCount = Math.max(...stepsData.map((step) => step.count || 0));

  steps.sort((a, b) => a.indexOrder - b.indexOrder);
  steps.map((step) => {
    const stepData = stepsData.find((stepData) => stepData.uid === step.uid);

    const count = stepData?.count || 0;

    step.count = count;
    step.completionPercentage =
      maxCount > 0 ? Math.round((count * 100) / maxCount) : 0;
    step.heightPercentage =
      denominator > 0 ? Math.round((count * 100) / denominator) : 0;

    return step;
  });

  // set start date and end date for the week
  const startDate = dayjs(week.weekLocaleString).startOf('week').toDate();
  const endDate = dayjs(week.weekLocaleString).endOf('week').toDate();

  return (
    <div className="week-activity-item">
      <Tooltip
        offsetX={100}
        offsetY={-100}
        className="tooltip-tracker-week-details"
        position="center center"
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}
        trigger={
          <div
            className={classNames('chart-wrapper', {
              hovered: isTooltipOpen,
              stacked: true,
            })}>
            {steps.map((step) => {
              return (
                <>
                  <div
                    className="chart"
                    style={{
                      height: `${Math.min(100, step.completionPercentage)}%`,
                    }}></div>
                </>
              );
            })}
          </div>
        }>
        <div className="tracker-week-details-content">
          <div className="title">Week of {week.weekLocaleString}</div>
          <div className="groups">
            {steps.map((step, index) => {
              return (
                <div className="group">
                  <div
                    className="color-indication"
                    style={{background: trackerStepsColors[index]}}
                  />
                  <div className="data">
                    <div className="group-pct">
                      {step.completionPercentage}% {step.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            className="see-breakdown-btn"
            primary
            reverted
            thin
            onClick={() => {
              setIsDrawerOpen(true);
            }}>
            See breakdown
          </Button>
        </div>
      </Tooltip>

      {isDrawerOpen === true && (
        <TrackerListUsersDrawer
          isOpen={isDrawerOpen}
          onRequestClose={() => setIsDrawerOpen(false)}
          tracker={tracker}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      <div className="week-label">
        <div className="week-label-text">
          {dayjs(week.weekLocaleString).format('MMM DD')}
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  tracker: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const defaultProps = {
  tracker: null,
};

const logger = new Swaler('CompletionRateChart');

const CompletionRateChart = () => {
  const {tracker} = useContext(TrackerContext);

  const {steps = []} = tracker;

  const [analytics, setAnalytics] = useState([]);

  const {isLoading} = useQuery({
    queryKey: ['completionRate', 'tracker', tracker.uid],
    queryFn: async () => {
      const analytics = await trackerAnalyticsService.getAnalyticsDaily({
        trackerId: tracker.uid,
        startDate: dayjs().subtract(4, 'week').startOf('week').toDate(),
        endDate: dayjs().toDate(),
      });
      setAnalytics(analytics);
    },
    onError: (err) => {
      logger.error(
        'Could not fetch steps analytics, failed with err',
        err.message
      );
    },
    refetchOnWindowFocus: false,
  });

  const datesArr = generateDatesArray(
    dayjs().subtract(4, 'week').startOf('week').toDate(),
    dayjs().toDate(),
    TIME_FREQUENCY_WEEKLY
  ).filter(
    (d) =>
      !d
        .startOf(TIME_FREQUENCY_WEEKLY)
        .isAfter(dayjs().startOf(TIME_FREQUENCY_WEEKLY))
  );

  // group analytics by week and by step in datesArr
  const groupedAnalytics = datesArr.reduce((acc, week) => {
    const weekData = analytics.filter((a) =>
      dayjs(parseInt(a.createdAt, 10)).isSame(week, 'week')
    );

    acc.push({
      weekLocaleString: week.format('YYYY-MM-DD'),
      steps: steps.map((step) => {
        const stepData = weekData.filter((a) => a.trackerStepId === step.uid);

        return {
          uid: step.uid,
          count:
            stepData.length > 0
              ? stepData.reduce((acc, cur) => (acc += cur.count), 0)
              : 0,
        };
      }),
    });

    return acc;
  }, []);

  const denominator = Math.max(
    ...groupedAnalytics?.map((week) =>
      Math.max(...week?.steps.map((step) => step.count || 0))
    )
  );

  groupedAnalytics?.sort(
    (a, b) => new Date(a.weekLocaleString) - new Date(b.weekLocaleString)
  );

  if (isLoading === false && analytics?.length === 0) {
    return (
      <div className="no-completion-data">
        <JimoGrey />
        <div className="no-completion-text">No data yet</div>
      </div>
    );
  }

  return (
    <>
      <div className="tracker-completion-activity">
        {groupedAnalytics?.map((week, index) => {
          return (
            <WeekActivityItem
              index={index}
              week={week}
              denominator={denominator}
            />
          );
        })}
      </div>
      <CompletionRateLegend />
    </>
  );
};

CompletionRateChart.propTypes = propTypes;
CompletionRateChart.defaultProps = defaultProps;

export default CompletionRateChart;
