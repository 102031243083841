import Button from 'components/Button';
import SelectGroup from 'components/Select';
import React from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import SelectAttribute from '../select-attribute';

const logger = new Swaler('JimerActivityAttribute');

export const JimerActivityAttribute = (props) => {
  const {
    attribute,
    onDelete,
    onChangeType,
    onChangeEvolutions,
    segmentioFields,
    segmentioEvents,
    trackedEvents,
  } = props;

  const {data: listEvolutions = [], isLoading: isLoadingListEvolutions} =
    useQuery({
      queryKey: 'listEvolutions',
      queryFn: async () => evolutionService.getEvolutions({removeDrafts: true}),
      refetchOnWindowFocus: false,
      onError: (err) => {
        logger.error(
          'Could not fetch evolutions list, failed with err',
          err.message
        );
      },
    });

  const evolutionOptions = listEvolutions?.map((e) => ({
    label: e.title,
    value: e.uid,
  }));

  return (
    <div className="segment-attribute jimer-activity-attribute">
      <div className="header-row">
        <SelectAttribute
          value={attribute.type}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <SelectGroup
          options={evolutionOptions}
          isMulti
          closeMenuOnSelect={false}
          isLoading={isLoadingListEvolutions}
          value={evolutionOptions.filter((e) =>
            attribute.evolutions?.map((e) => e?.uid || e).includes(e.value)
          )}
          placeholder="Select evolutions..."
          onChange={(evolutions) => {
            onChangeEvolutions(evolutions?.map((e) => e.value));
          }}
          isDisabled
        />
      </div>
    </div>
  );
};
