import {generalActions} from 'actions';
import Button from 'components/Button';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_SETTINGS_INTEGRATIONS_ZAPIER} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {zapierService} from 'services';
import {Swaler} from 'swaler';
import ZapierLogo from '../imgs/zapier.svg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsZapier');

const Zapier = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());
  const {zapier} = project;

  const zapierActive = !!zapier?.apiKey;

  const [isLoading, setIsLoading] = useState(false);

  const handleConnectZapier = async () => {
    setIsLoading(true);

    try {
      const zapier = await zapierService.generateApiKey(project.uid);

      dispatch(generalActions.uptProject({zapier}));
      history.push(ROUTE_SETTINGS_INTEGRATIONS_ZAPIER);
    } catch (err) {
      logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="settings-card integration-zapier">
      <div className="logo-action-wrapper">
        <img className="logo zapier-logo" src={ZapierLogo} alt="zapier-logo" />
        {!zapierActive ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleConnectZapier}>
            Connect
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_ZAPIER)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Zapier
          {zapierActive === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Connected
            </div>
          )}
        </div>
        <div className="body-3">
          Use zapier to connect Jimo to your favorite apps.
        </div>
      </div>
    </div>
  );
};

export default Zapier;
