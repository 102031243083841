import classNames from 'classnames';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {BuilderContext} from 'contextes/builder';
import {bool, func, object} from 'prop-types';
import {useContext, useState} from 'react';
import {EVOLUTION_TYPE_SURVEY} from 'services/evolution';
import {
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SNOOZE,
} from 'services/steps';
import './_Styles.scss';
import SettingsGoToStep from './components/SettingsGoToStep';
import SettingsLaunchExperience from './components/SettingsLaunchExperience';
import SettingsNavigateTo from './components/SettingsNavigateTo';
import SettingsRunJSCode from './components/SettingsRunJSCode';
import SettingsSnooze from './components/SettingsSnooze';

const propTypes = {
  condition: object.isRequired,
  setCondition: func.isRequired,
  isOpen: bool,
  onClose: func,
};

const defaultProps = {
  isOpen: false,
  onClose: () => {},
};

const EditActionDropdown = ({action, setAction, isOpen, onClose, ...rest}) => {
  const [preventClose, setPreventClose] = useState(false);

  let icon = '',
    iconClassName = '',
    title = '',
    content = '';

  const {evolution: evolutionContext} = useContext(BuilderContext);

  const isSurvey = evolutionContext.type === EVOLUTION_TYPE_SURVEY;

  const isGoToStep = action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP;
  const isRunJSCode = action.type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE;
  const isLaunchExperience =
    action.type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE;
  const isNavigateTo = action.type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO;
  const isSnooze = action.type === STEP_CONDITION_ACTION_TYPE_SNOOZE;

  if (isGoToStep) {
    icon = 'isax isax-arrow-right';
    iconClassName = 'go-to-step';
    title = 'Go to step';
    content = <SettingsGoToStep action={action} setAction={setAction} />;
  } else if (isLaunchExperience) {
    icon = 'isax isax-routing';
    iconClassName = 'launch-experience';
    title = 'Launch experience';
    content = (
      <SettingsLaunchExperience action={action} setAction={setAction} />
    );
  } else if (isNavigateTo) {
    icon = 'isax isax-send-2';
    iconClassName = 'navigate-to';
    title = 'Navigate to';
    content = (
      <SettingsNavigateTo
        action={action}
        setAction={setAction}
        setPreventClose={setPreventClose}
      />
    );
  } else if (isRunJSCode) {
    icon = 'isax isax-document-code';
    iconClassName = 'run-js-code';
    title = 'Run JavaScript code';
    content = <SettingsRunJSCode action={action} setAction={setAction} />;
  } else if (isSnooze) {
    icon = 'isax isax-main-component5';
    iconClassName = 'snooze';
    title = isSurvey ? 'Snooze survey' : 'Snooze experience';
    content = <SettingsSnooze action={action} setAction={setAction} />;
  }

  return (
    <Dropdown
      key={action.uid}
      className={classNames('action-settings')}
      position="center right"
      offsetX={0}
      open={isOpen}
      closeOnDocumentClick={isOpen && preventClose !== true}
      onClose={onClose}
      contentStyle={{zIndex: 1002}}
      {...rest}>
      <div className="action-settings-wrapper">
        <div className="action-settings-header">
          <div className="action-settings-title">
            <div className={classNames('icon-wrapper', iconClassName)}>
              <i className={icon} />
            </div>
            {title}
          </div>
          <i className="icon-close" onClick={onClose} />
        </div>
        <Divider />
        <div className="action-settings-items">{content}</div>
      </div>
    </Dropdown>
  );
};

EditActionDropdown.propTypes = propTypes;
EditActionDropdown.defaultProps = defaultProps;

export default EditActionDropdown;
