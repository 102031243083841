import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {isChrome, isOpera, useIsExtensionInstalled} from 'helpers/utils';
import useAcrossTabs from 'hooks/UseAcrossTabs';
import {bool, func, object, string} from 'prop-types';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {MODE_INSTALL} from 'scenes/Onboarding/scenes/Details/step-3-1';
import InstallJimo from 'scenes/Pushes/components/ModalCreatePoke/components/InstallJimo';
import {generalSelector} from 'selectors';
import {buildUrlService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Recording from './imgs/recording.svg';

export const MODE_FORM = 'FORM';
export const MODE_INSTALLATION = 'INSTALLATION';
export const MODE_PENDING = 'PENDING';
export const MODE_PICK_TRACKER_TYPE = 'PICK_TRACKER_TYPE';
export const MODE_EDIT = 'EDIT';

export const hasQueryParams = (url) => {
  return url.includes('?');
};

const isChromeExtensionCompatible = isChrome() === true || isOpera() === true;

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  onElementSelected: func,
  title: string,
  handshakeData: object.isRequired,
};

const logger = new Swaler('ModalOpenInAppBuilder');

const ModalOpenInAppBuilder = ({
  isOpen,
  onRequestClose = () => {},
  onElementSelected = () => {},
  handshakeData,
  title = null,
  ...rest
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [url, setUrl] = useState('');
  const [urlInputFocused, setUrlInputFocused] = useState(false);
  const [mode, setMode] = useState(MODE_EDIT);
  const [isLoading, setIsLoading] = useState(false);
  const [modeInstall, setModeInstall] = useState(
    isChromeExtensionCompatible ? null : MODE_INSTALL
  );
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const isSnippetInstalled = project.snippetInstalledAt != null;
  const {isInstalled: isExtensionInstalled} = useIsExtensionInstalled();

  const hasBuilderAccess =
    isSnippetInstalled === true || isExtensionInstalled === true;

  const {openNewTab, refParent, childTabId} = useAcrossTabs({
    onHandshakeCallback: () => {
      refParent.current?.broadCastTo(childTabId.current, handshakeData);
    },
    onChildCommunication: (message) => {
      if (message?.type === 'TARGET_ELEMENT_SELECTED') {
        refParent.current?.closeAllTabs();
        onElementSelected(message.data);
        closeModal();
      }
      if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
        closeModal();
      }
    },
  });

  useEffect(() => {
    if (isInvalidUrl === true) {
      try {
        new URL(url);
        setIsInvalidUrl(false);
      } catch (err) {}
    }
  }, [url]);

  const handleLaunchBuilder = async () => {
    if (url?.length > 0) {
      try {
        new URL(url);
      } catch (err) {
        setIsInvalidUrl(true);
        return;
      }
    }

    setIsLoading(true);

    if (url != null && url.length > 0) {
      try {
        await buildUrlService.createBuildUrl({url});
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error(`Failed creating build url: ${code}`);
      }
    }

    openNewTab(url);
    setMode(MODE_PENDING);
    setIsLoading(false);
  };

  const {data: buildUrls, refetch: refreshBuildUrls} = useQuery({
    queryKey: 'build-urls',
    queryFn: buildUrlService.getBuildUrls,
  });

  const closeModal = () => {
    refParent.current?.closeAllTabs();
    setUrl('');
    onRequestClose();
  };

  const handleStopInAppEditing = async () => {
    setIsLoading(true);
    refParent?.current.closeAllTabs();
    setUrl('');
    setIsLoading(false);
    onRequestClose();
  };

  const handleDeleteUrl = async (urlId) => {
    try {
      await buildUrlService.deleteBuildUrl(urlId);
      toastSuccess('Url deleted', {toastId: 'url-detected'});
      refreshBuildUrls();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {actions});
    }
  };

  const getTitle = () => {
    const hasBackButton = mode === MODE_INSTALLATION;

    let text = title || 'Select in-app element';

    if (mode === MODE_INSTALLATION) {
      text = 'Install Jimo snippet';
    }
    if (mode === MODE_PENDING) {
      return null;
    }

    return (
      <>
        {hasBackButton && (
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {}}
          />
        )}
        <div className="text">{text}</div>
      </>
    );
  };

  const filteredUrl = buildUrls?.filter((bu) => bu.url.includes(url || ''));

  let content, footer;

  if (mode === MODE_INSTALLATION) {
    content = (
      <div className="modal-content installation">
        <InstallJimo
          mode={modeInstall}
          setMode={setModeInstall}
          onExtensionInstalledContinue={() => setMode(MODE_FORM)}
          onSkip={() => setMode(MODE_FORM)}
        />
      </div>
    );
  }

  if (mode === MODE_EDIT) {
    content = (
      <>
        <div className="modal-content">
          <div className="inputs-wrapper">
            <div className="section-title">Go to</div>
            <div className="tracker-url-wrapper">
              <div className="input-group-wrapper">
                <Input
                  autoComplete="off"
                  className="tracker-url"
                  placeholder="https://"
                  name="targetUrl"
                  type="url"
                  value={url}
                  iconRight={
                    isInvalidUrl ? 'icon-exclamation-circle' : 'icon-expand'
                  }
                  onChange={({target}) => setUrl(target.value)}
                  onFocus={() => setUrlInputFocused(true)}
                />
                {isInvalidUrl && (
                  <div className="body-4 r-400">
                    Please enter a valid URL, including the protocol (e.g.,
                    “https://”).
                  </div>
                )}
              </div>
              {filteredUrl?.length > 0 && (
                <Dropdown
                  className={classnames('dropdown-build-url-suggestions', {
                    'not-focused': urlInputFocused !== true,
                  })}
                  open={urlInputFocused === true}
                  onClose={() => setUrlInputFocused(false)}
                  offsetX={5}
                  offsetY={-8}>
                  {filteredUrl
                    .sort(
                      (a, b) =>
                        new Date(b.lastUsed).getTime() -
                        new Date(a.lastUsed).getTime()
                    )
                    ?.map((bu) => {
                      return (
                        <div
                          className="url-suggestion"
                          onClick={() => {
                            setUrl(bu.url);
                            setUrlInputFocused(false);
                          }}>
                          {bu.url}
                          {/* For some reasons, using a Button here make the input buggy */}
                          <div
                            className="btn-delete-url"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteUrl(bu.uid);
                            }}>
                            <i className="icon-trash"></i>
                          </div>
                        </div>
                      );
                    })}
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </>
    );

    footer = (
      <>
        <Button
          muted
          className="back-btn"
          type="button"
          onClick={() => {
            closeModal();
          }}>
          Cancel
        </Button>
        <Button
          primary
          disabled={hasBuilderAccess && !url}
          loading={isLoading}
          onClick={() => handleLaunchBuilder()}>
          Enter builder
        </Button>
      </>
    );
  }
  if (mode === MODE_PENDING) {
    content = (
      <>
        <div className="modal-content">
          <div className="recording-wrapper">
            <img src={Recording} alt="recording" />
          </div>
          <div className="pending-wrapper">
            <div className="pending-title">In-app editing in progress</div>
            <div className="pending-subtitle">
              You're currently in the in-app editing mode. Switch to the newly
              created tab to start editing. You can close this tab when you're
              done.
            </div>
          </div>
        </div>
      </>
    );

    footer = (
      <Button
        muted
        className="close-btn"
        onClick={() => {
          handleStopInAppEditing();
        }}
        loading={isLoading}>
        Exit in-app editing
      </Button>
    );
  }

  return (
    <Modal
      className={classnames('modal-open-in-app-builder', mode?.toLowerCase(), {
        'no-max-height': mode === MODE_INSTALLATION,
      })}
      title={getTitle()}
      isOpen={isOpen}
      {...rest}
      onRequestClose={closeModal}
      style={{
        overlay: {
          zIndex: 1003,
        },
      }}
      footer={footer}>
      <div className={classnames('content-wrapper fade-in-right')}>
        {content}
      </div>
    </Modal>
  );
};

ModalOpenInAppBuilder.propTypes = propTypes;

export default ModalOpenInAppBuilder;
