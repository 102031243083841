import Alert from 'components/Alert';
import Loader from 'components/Loader';
import {buildLogic} from 'components/SegmentAttributesEditor/utils';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {createContext, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {getRandomEmoji} from 'scenes/SuccessTracker/components/Event';
import {segmentService} from 'services';
import {F_SEGMENT_FROM_DATA_IMPORT} from 'services/segment';
import {Swaler} from 'swaler';
import './_Styles.scss';
import SegmentAttributesWrapper from './components/SegmentAttributesWrapper';
import SegmentDetails from './components/SegmentDetails';
import SegmentHeader from './components/SegmentHeader';
import SegmentUsersWrapper from './components/SegmentUsersWrapper';

const logger = new Swaler('Segment');

const defaultSegment = {
  name: '',
  description: '',
  icon: 'DEFAULT',
  attributes: [],
  logic: null,
};

export const SegmentContext = createContext();

const Segment = () => {
  const {segmentId} = useParams();

  const [segment, setSegment] = useState(defaultSegment);
  const [lookupData, setLookupData] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [logic, setLogic] = useState(buildLogic());
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [icon, setIcon] = useState(
    segmentId === 'new' ? getRandomEmoji() : null
  );

  const {isLoading, refetch: refetchSegment} = useQuery({
    queryKey: ['segment', segmentId],
    queryFn: async () => {
      const segment = await segmentService.getSegmentById(segmentId);
      setSegment(segment);
      setAttributes(segment.attributes);
      setName(segment.name);
      setDescription(segment.description);
      setIcon(segment.icon || 'DEFAULT');
      setLogic(JSON.parse(JSON.stringify(buildLogic(segment))));
      setLookupData({
        attributes: JSON.parse(JSON.stringify(segment.attributes)),
        logic: JSON.parse(JSON.stringify(buildLogic(segment))),
      });
      return segment;
    },
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching segment failed with error ', code);
      toastDanger([title, message], {actions});
    },
    refetchOnWindowFocus: false,
    enabled: segmentId != null && segmentId !== 'new',
  });

  const isLegacy = segment?.logic == null && segmentId !== 'new';

  const hasChanges = () =>
    JSON.stringify(lookupData?.attributes || segment?.attributes) !==
      JSON.stringify(attributes) ||
    JSON.stringify(lookupData?.logic || segment?.logic) !==
      JSON.stringify(logic);

  return (
    <SegmentContext.Provider
      value={{
        segment,
        lookupData,
        setLookupData,
        attributes,
        setAttributes,
        logic,
        setLogic,
        hasChanges,
        isLegacy,
        name,
        setName,
        description,
        setDescription,
        icon,
        setIcon,
        refetchSegment,
      }}>
      <div className="s-users-segment">
        {isLoading === true || segment == null ? (
          <div className="loader-wrapper">
            <Loader width="40px" />
          </div>
        ) : (
          <>
            <div className="segment-header-wrapper">
              <SegmentHeader onSave={refetchSegment} />
            </div>
            <div className="segment-content-wrapper">
              <SegmentDetails />
              {hasFlag(F_SEGMENT_FROM_DATA_IMPORT, segment.flags) === true ? (
                <Alert
                  className="alert-segment-from-import"
                  info
                  title="Segment created from an import">
                  This segment has been created using an import. Therefore, you
                  cannot set attributes. If you'd like to create a segment based
                  on specific attributes, please create a new segment.
                </Alert>
              ) : (
                <SegmentAttributesWrapper />
              )}
              <SegmentUsersWrapper />
            </div>
          </>
        )}
      </div>
    </SegmentContext.Provider>
  );
};

export default Segment;
