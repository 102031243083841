import {PlateElement} from '@udecode/plate-common';
import React, {forwardRef} from 'react';

export const ListElement = forwardRef(
  ({children, variant = 'ul', ...props}, ref) => {
    const Component = variant;

    return (
      <PlateElement asChild ref={ref} variant={variant} {...props}>
        <Component>{children}</Component>
      </PlateElement>
    );
  }
);
