import React, {useRef} from 'react';
import './_Styles.scss';
import {commentService, projectService} from 'services';
import {Swaler} from 'swaler';
import {errorHelpers} from 'helpers';
import {toastDanger} from 'components/Toaster';
import Avatar from 'components/Avatar';
import dayjs from 'dayjs';
import {useQuery} from 'react-query';
import DefaultLoader from 'components/Loader';
import {useDispatch, useSelector} from 'react-redux';
import {generalActions} from 'actions';
import {generalSelector} from 'selectors';
import Label from 'components/Label';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {buildLinkToEvolution} from 'helpers/utils';

const logger = new Swaler('RecentComments');

const RecentComments = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const lastNotificationOpenedAtRef = useRef(
    projectMember?.lastNotificationOpenedAt
  );

  const {data: comments = [], isLoading: isLoadingComments} = useQuery({
    queryKey: 'comments',
    queryFn: async () =>
      commentService.getCommentsForProject({
        limit: 30,
        relations: ['evolution', 'author'],
      }),
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('fetching containers failed with error ', code);
      toastDanger([title, message], {actions});
    },
    onSuccess: async () => {
      const lastNotificationOpenedAt = new Date();
      await projectService.updateLastNotificationOpenedAt({
        lastNotificationOpenedAt: lastNotificationOpenedAt,
      });
      dispatch(
        generalActions.uptProjectMember({
          lastNotificationOpenedAt: lastNotificationOpenedAt,
        })
      );
    },
  });

  return (
    <div className="recent-comments">
      <div className="title subtitle-4 n-800">Recent comments</div>
      <div className="comments-list">
        {isLoadingComments === true ? (
          <div className="loader-wrapper">
            <DefaultLoader />
          </div>
        ) : comments.length === 0 ? (
          <div className="empty-state-wrapper">
            <div className="icon-wrapper">
              <i className="isax isax-message-text-15 n-500" />
            </div>
            <div className="empty-state-title body-3 n-700">
              No comments for now
            </div>
          </div>
        ) : (
          <>
            {comments.map((c) => {
              const {jimer} = c;

              const username =
                jimer?.externalUsername != null &&
                jimer?.externalUsername.length !== 0
                  ? jimer?.externalUsername
                  : jimer?.username;

              const isUnread = dayjs(c.createdAt).isAfter(
                dayjs(lastNotificationOpenedAtRef.current)
              );

              return (
                <>
                  <div
                    key={c.uid}
                    className="comment"
                    onClick={() => {
                      history.push(
                        buildLinkToEvolution(c.evolution, {toComments: true})
                      );
                    }}>
                    <div className="comment-author-wrapper">
                      <Avatar jimer={c.jimer} />
                      <div className="message-badge-wrapper">
                        <i className="isax isax-message-text-15 b-400" />
                      </div>
                    </div>
                    <div className="comment-content-wrapper">
                      <div className="comment-title subtitle-4 n-800">{`${username} commented on ${c.evolution?.title}`}</div>
                      <div className="comment-content body-3 n-700">
                        {c.message}
                      </div>
                      <div className="comment-date body-4 n-500">
                        {dayjs(c.createdAt).fromNow()}
                        {isUnread && (
                          <Label size="x-small" type="negative" primary>
                            New
                          </Label>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default RecentComments;
