export const CATEGORY_ATTRIBUTES = 'Attributes';
export const CATEGORY_ACTIVITY = 'Activity';
export const CATEGORY_SEGMENTIO_FIELDS = 'Segment.io fields';
export const CATEGORY_SEGMENTIO_EVENTS = 'Segment.io events';
export const CATEGORY_TRACKED_EVENTS = 'Tracked events';

export const defaultBlocks = [
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Name',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-string" />,
    value: 'NAME',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Email',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-at" />,
    value: 'EMAIL',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Active',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-opinion" />,
    value: 'ACTIVE',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Last activity',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-calendar" />,
    value: 'LAST_ACTIVITY_AT',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Created',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-calendar" />,
    value: 'CREATED',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Sessions',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-play-rounded" />,
    value: 'SESSIONS',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Custom attributes',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-custom-filter" />,
    value: 'CUSTOM',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Engagement rate',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-vote-opinion" />,
    value: 'ENGAGEMENT_RATE',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Interacted',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-interaction" />,
    value: 'INTERACTED_WITH',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Not interacted',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-interaction" />,
    value: 'NINTERACTED_WITH',
  },
  // {
  //   category: CATEGORY_ACTIVITY,
  //   title: 'Answered',
  //   iconBackgroundColor: '#E6F5FD',
  //   icon: <i className="icon-interaction" />,
  //   value: 'VOTED',
  // },
  // {
  //   category: CATEGORY_ACTIVITY,
  //   title: 'Not answered',
  //   iconBackgroundColor: '#E6F5FD',
  //   icon: <i className="icon-interaction" />,
  //   value: 'NVOTED',
  // },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Saw',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-eye" />,
    value: 'SAW',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Not saw',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-eye" />,
    value: 'NSAW',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has exited survey',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-back" />,
    value: 'SURVEY_EXITED',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has completed survey',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-checkbox" />,
    value: 'SURVEY_COMPLETED',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has not completed survey',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-checkbox" />,
    value: 'SURVEY_NCOMPLETED',
  },
];
