import SegmentAttributesEditor from 'components/SegmentAttributesEditor';
import SegmentAttributesEditorLegacy from 'components/SegmentAttributesEditorLegacy';
import {useContext} from 'react';
import {SegmentContext} from '../..';
import './_Styles.scss';

const SegmentAttributesWrapper = () => {
  const {
    segment,
    setLookupData,
    lookupData,
    attributes,
    setAttributes,
    logic,
    setLogic,
    isLegacy,
  } = useContext(SegmentContext);

  return (
    <div className="segment-attributes-wrapper">
      <div className="segment-attributes-title">Conditions</div>

      {isLegacy === true ? (
        <>
          <div className="segment-legacy-warning">
            <div className="segment-legacy-warning-title">
              This segment was created using the deprecated version of the
              segment editor and is now non-editable.
            </div>
            <a
              href="https://help.usejimo.com/help-center/"
              target="_blank"
              rel="noopener noreferrer">
              <div className="segment-legacy-warning-description">
                Read our article to learn more
                <i className="icon-arrow-right" />
              </div>
            </a>
          </div>
          <SegmentAttributesEditorLegacy segment={segment} />
        </>
      ) : (
        <SegmentAttributesEditor
          dropdownProps={{
            className: 's-user-segment-attributes-editor',
          }}
          segment={segment}
          onLookup={(attributes, logic) => {
            setLookupData({
              attributes: JSON.parse(JSON.stringify(attributes)),
              logic: JSON.parse(JSON.stringify(logic)),
            });
          }}
          lookupData={lookupData}
          attributes={attributes}
          setAttributes={setAttributes}
          logic={logic}
          setLogic={setLogic}
        />
      )}
    </div>
  );
};

export default SegmentAttributesWrapper;
