import './_Styles.scss';
import RadioGroup from 'components/RadioGroup';

const horizontalAlignOptions = [
  {value: 'left', label: <i className="isax isax-align-left" />},
  {value: 'center', label: <i className="isax isax-align-vertically" />},
  {value: 'right', label: <i className="isax isax-align-right" />},
];

const verticalAlignOptions = [
  {value: 'top', label: <i className="isax isax-align-bottom" />},
  {value: 'center', label: <i className="isax isax-align-horizontally" />},
  {value: 'bottom', label: <i className="isax isax-align-bottom rotate-180" />},
];

const HintAlignment = ({style = null, onChange = () => {}}) => {
  const horizontalAlign = style?.horizontalAlign || 'left';
  const verticalAlign = style?.verticalAlign || 'top';

  const updateAlignment = (update) => {
    onChange({
      ...style,
      ...update,
    });
  };

  return (
    <div className="section-item hint-alignment">
      <div className="alignment-options">
        <RadioGroup
          value={horizontalAlign}
          options={horizontalAlignOptions}
          onSelect={(value) => {
            updateAlignment({horizontalAlign: value, offsetX: 0});
          }}
        />

        <RadioGroup
          value={verticalAlign}
          options={verticalAlignOptions}
          onSelect={(value) =>
            updateAlignment({verticalAlign: value, offsetY: 0})
          }
        />
      </div>
    </div>
  );
};

export default HintAlignment;
