import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {bool, func, object} from 'prop-types';
import {useState} from 'react';
import {themeService} from 'services';
import {Swaler} from 'swaler';
import isURL from 'validator/lib/isURL';
import {generateThemeFromPalette} from '../../utils';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  style: object.isRequired,
  onRequestClose: func,
  onThemeCreated: func.isRequired,
};

const defaultProps = {
  onRequestClose: () => {},
};

const logger = new Swaler('ModalCreateTheme');

const ModalCreateTheme = ({
  isOpen,
  style,
  onRequestClose,
  onThemeCreated,
  ...rest
}) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [creating, setCreating] = useState(false);

  const isSmart = style == null;

  const classNames = classnames('modal-create-theme');

  const handleCreateTheme = async () => {
    setCreating(true);
    try {
      if (isSmart) {
        const themeBase = await themeService.generatePalette({url});
        style = generateThemeFromPalette(themeBase);
      }
      const createdTheme = await themeService.createTheme({
        name: name || `Generated from ${url}`,
        style,
      });
      onThemeCreated(createdTheme);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Creating theme failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setCreating(false);
    }
  };

  return (
    <Modal
      className={classNames}
      title={isSmart ? 'Generate a smart theme' : 'Create a new theme'}
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <div className="inputs-wrapper">
        <Input
          legend="Name"
          className="input-name"
          name="inputName"
          placeholder={'Theme name'}
          value={name}
          required
          onChange={({target}) => setName(target.value)}
        />
        {isSmart && (
          <>
            <Input
              legend="Generate theme from"
              className="input-url"
              iconLeft="isax isax-link-2"
              name="urlName"
              placeholder="https://yoursite.com"
              value={url}
              required
              onChange={({target}) => setUrl(target.value)}
            />
          </>
        )}
      </div>
      <div className="actions">
        <Button onClick={onRequestClose} disabled={creating}>
          Cancel
        </Button>
        <Button
          primary
          onClick={handleCreateTheme}
          loading={creating}
          disabled={
            (isSmart && (!url || !isURL(url || ''))) || (!isSmart && !name)
          }>
          Create theme
        </Button>
      </div>
    </Modal>
  );
};

ModalCreateTheme.propTypes = propTypes;
ModalCreateTheme.defaultProps = defaultProps;

export default ModalCreateTheme;
