import {useEffect, useState} from 'react';
import {SketchPicker} from 'react-color';
import {hexToRgba, rgbaToHex} from './utils';
import {projectService} from 'services';
import {generalSelector} from 'selectors';
import {useDispatch, useSelector} from 'react-redux';
import {uptProject} from 'actions/general';

export const ColorPicker = (props) => {
  const {
    children,
    popoverStyle = {},
    onChange,
    color,
    disableAlpha = false,
    onlyPicker = false,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const updateProject = (project) => {
    dispatch(uptProject(project));
  };
  const project = useSelector((state) => generalSelector.getProject(state));

  const {recentColors = ''} = project;
  const presetColors = recentColors?.split(';').filter((c) => c) || [];

  const [showPicker, setShowPicker] = useState(false);
  const [colorToSave, setColorToSave] = useState(null);

  useEffect(() => {
    const saveColor = async () => {
      const updatedProject = await projectService.updateProject(project.uid, {
        recentColors: [
          colorToSave,
          ...(project.recentColors || '')
            .split(';')
            .filter((c) => c && c !== colorToSave)
            .slice(0, 15),
        ].join(';'),
      });
      updateProject(updatedProject);
    };
    if (showPicker === false) {
      if (colorToSave != null) {
        saveColor();
      }
    } else {
      setColorToSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPicker]);

  const rgba = hexToRgba(color);

  if (onlyPicker === true) {
    return (
      <SketchPicker
        color={rgba}
        disableAlpha={disableAlpha}
        onChange={(v) => {
          const hexWithAlpha = rgbaToHex(v.rgb.r, v.rgb.g, v.rgb.b, v.rgb.a);
          setColorToSave(hexWithAlpha);
          onChange({...v, hexWithAlpha});
        }}
        presetColors={presetColors}
        {...rest}
      />
    );
  }

  return (
    <>
      <div
        className="color-picker-wrapper"
        style={{display: 'contents'}}
        onClick={() => setShowPicker(true)}>
        {children}
      </div>
      {showPicker === true && (
        <div
          className="picker-popover"
          style={{
            position: 'absolute',
            zIndex: '3',
            ...popoverStyle,
          }}>
          <div
            className="cover"
            onClick={() => setShowPicker(false)}
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '-50px',
            }}></div>
          <SketchPicker
            color={rgba}
            disableAlpha={disableAlpha}
            onChange={(v) => {
              const hexWithAlpha = rgbaToHex(
                v.rgb.r,
                v.rgb.g,
                v.rgb.b,
                v.rgb.a
              );
              setColorToSave(hexWithAlpha);
              onChange({...v, hexWithAlpha});
            }}
            presetColors={presetColors}
            {...rest}
          />
        </div>
      )}
    </>
  );
};

export default ColorPicker;
