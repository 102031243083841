import {ModalConfirm} from 'components/Modal';
import {bool, func} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  isOpen: bool,
  onRequestClose: func,
  onConfirm: func,
  isLiveStep: bool,
};

const defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  onConfirm: () => {},
  isLiveStep: false,
};

const RemoveStepModal = ({isOpen, onRequestClose, onConfirm, isLiveStep}) => {
  let icon, title, content;

  if (isLiveStep) {
    icon = 'isax isax-info-circle';
    title = 'Remove Live Step?';
    content =
      "Since this step is live, it won't be deleted but hidden from the users instead, keeping all associated analytics and responses intact.";
  } else {
    icon = 'isax isax-trash';
    title = 'Remove Step?';
    content =
      'Are you sure you want to remove this step? This action cannot be undone.';
  }

  return (
    <ModalConfirm
      className="remove-step-modal"
      title={
        <>
          <div className="icon-wrapper">
            <i className={icon} />
          </div>
          <span className="title-4 n-800">{title}</span>
        </>
      }
      closable={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onConfirm={onConfirm}
      onCancel={onRequestClose}
      cancelText="Cancel"
      confirmText="Remove"
      cancelBtnProps={{
        cta: false,
      }}
      confirmBtnProps={{
        danger: true,
        primary: true,
        cta: false,
      }}>
      <div className="content n-700">{content}</div>
    </ModalConfirm>
  );
};

RemoveStepModal.propTypes = propTypes;
RemoveStepModal.defaultProps = defaultProps;

export default RemoveStepModal;
