import {
  BUILDER_SET_POKE,
  BUILDER_SET_IS_NAVIGATING,
  BUILDER_SET_SELECTED_TOUR_STEP_ID,
  BUILDER_SET_SELECTED_STEP_ID,
} from 'actions/builder';

const defaultBuilderState = {
  poke: null,
  tourStepId: null,
  stepId: null,
  isNavigating: false,
};

const builderReducer = (state = defaultBuilderState, action) => {
  switch (action.type) {
    case BUILDER_SET_POKE: {
      return {
        ...state,
        poke: action.poke,
      };
    }
    case BUILDER_SET_SELECTED_TOUR_STEP_ID: {
      return {
        ...state,
        tourStepId: action.tourStepId,
      };
    }
    case BUILDER_SET_SELECTED_STEP_ID: {
      return {
        ...state,
        stepId: action.stepId,
      };
    }
    case BUILDER_SET_IS_NAVIGATING: {
      return {
        ...state,
        isNavigating: action.isNavigating,
      };
    }
    default: {
      return state;
    }
  }
};

export default builderReducer;
