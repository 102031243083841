import React, {useContext, useState} from 'react';
import './_Styles.scss';
import {Modal} from 'components/Modal';
import InputGroup from 'components/Input';
import Button from 'components/Button';
import {AudienceContext} from 'scenes/PokeAudience';

const PageOpeningModal = ({isOpen, onRequestClose, ...rest}) => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const [delay, setDelay] = useState(evolution.boostedDelay);

  const handleSave = () => {
    setEvolution({
      ...evolution,
      boostedDelay: delay,
    });

    onRequestClose();
  };

  return (
    <Modal
      className="modal-page-opening"
      title={
        <>
          <i className="icon-light" />
          On page opening
        </>
      }
      footer={
        <>
          <Button secondary className="cancel-btn" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button primary onClick={handleSave}>
            Save
          </Button>
        </>
      }
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <div className="fade-in-right">
        <div className="content">
          <div className="delay-input-wrapper">
            This experience will appear after
            <InputGroup
              name="inputBoostedDelay"
              value={delay / 1000}
              type="number"
              required
              onChange={(e) => setDelay(e.target.value * 1000)}
            />
            seconds.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PageOpeningModal;
