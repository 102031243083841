import {object, array, func} from 'prop-types';
import './_Styles.scss';
import {stepsService} from 'services';
import {
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import {BLOCK_OPINION} from 'shared/front/components/Poke/constants/blocks';

const propTypes = {
  filters: object,
  resetFilters: func,
  steps: array,
};

const defaultProps = {
  filters: {},
  resetFilters: () => {},
  steps: [],
};

const FloatingReportFilters = ({filters, resetFilters, steps}) => {
  const stepId = Object.keys(filters)[0];
  const step = steps.find((s) => s.uid === stepId);
  const stepIndex = steps.findIndex((s) => s.uid === stepId);

  let {name, type} = step;
  name = name || `Step ${stepIndex + 1}`;
  let icon, typeName, filterText;

  const filterValue = filters[stepId];

  if (type === stepsService.STEP_TYPE_OPINION_SCALE) {
    icon = <i className="icon-vote-opinion" />;
    typeName = 'Opinion scale';
    const blockOpinion = step.blocks?.find((b) => b.type === BLOCK_OPINION);
    const opinionValue = parseOpinionValue(blockOpinion?.value || '');
    let opinions = [];
    if (['emoji', 'smiley'].includes(opinionValue.type)) {
      opinions = JSON.parse(
        JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
      );
    } else if (opinionValue.type === 'star') {
      opinions = new Array(opinionValue.scale).fill(0).map((_, i) => {
        return {
          value: i + 1,
          label: (
            <>
              {new Array(i + 1).fill(0).map(() => (
                <i className="icon-star" style={{color: '#FFC107'}} />
              ))}
            </>
          ),
        };
      });
    }
    filterText = opinions
      .filter((o) =>
        filterValue.map((f) => parseInt(f, 10)).includes(parseInt(o.value, 10))
      )
      .map((o, i) => (
        <span key={i}>
          {i > 0 ? ', ' : ''}
          {o.label}
        </span>
      ));
  } else if (
    type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT ||
    type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
  ) {
    icon = <i className="icon-vote-multi-choice" />;
    typeName = 'Multiple choice';
    filterText = step.options
      .filter((o) => filterValue.includes(o.uid))
      .map((o) => o.content)
      .join(', ');
  } else if (type === stepsService.STEP_TYPE_TEXT_LONG) {
    icon = <i className="icon-vote-open-question" />;
    typeName = 'Open question';
    filterText = step.filteredResponses.find(
      (r) => r.uid === filterValue[0]
    )?.value;
  } else if (type === stepsService.STEP_TYPE_SLIDER) {
    icon = <i className="icon-vote-slider" />;
    typeName = 'Slider';
  } else if (type === stepsService.STEP_TYPE_NPS) {
    icon = <i className="icon-vote-nps" />;
    typeName = 'Nps';
    filterText = filterValue.sort((a, b) => a - b).join(', ');
  } else if (type === stepsService.STEP_TYPE_CONCEPT_TEST) {
    icon = <i className="icon-filter-preview" />;
    typeName = 'Concept test';
  }

  return (
    <div className="floating-report-filters">
      <div className="content-wrapper">
        <div className="icon-wrapper">
          <i className="icon-filter" />
        </div>
        <div className="content">
          <span>Showing report by users on</span>
          <div className="step-info-wrapper" title={`${name} - ${typeName}`}>
            {icon}
            <div className="step-name">
              {name} &bull; {typeName}
            </div>
          </div>
          <span>that responded with</span>
          <div className="step-answer-wrapper" title={filterText}>
            {filterText}
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="clear-filters-btn" onClick={resetFilters}>
          Clear filters
        </div>
      </div>
    </div>
  );
};

FloatingReportFilters.propTypes = propTypes;
FloatingReportFilters.defaultProps = defaultProps;

export default FloatingReportFilters;
