// Types
export const GENERAL_SET_USER = 'GENERAL_SET_USER';
export const GENERAL_SET_PROJECT = 'GENERAL_SET_PROJECT';
export const GENERAL_SET_PROJECTS = 'GENERAL_SET_PROJECTS';
export const GENERAL_SET_SNOOZE_JIMER_LIMIT_UNTIL =
  'GENERAL_SET_SNOOZE_JIMER_LIMIT_UNTIL';
export const GENERAL_UPT_USER = 'GENERAL_UPT_USER';
export const GENERAL_UPT_PROJECT = 'GENERAL_UPT_PROJECT';
export const GENERAL_UPT_PROJECT_MEMBER = 'GENERAL_UPT_PROJECT_MEMBER';
export const GENERAL_UPT_PROJECT_SUBSCRIPTION =
  'GENERAL_UPT_PROJECT_SUBSCRIPTION';
export const GENERAL_UPT_PROJECT_MEMBERS = 'GENERAL_UPT_PROJECT_MEMBERS';
export const GENERAL_SET_BYPASS_ONBOARDING = 'GENERAL_SET_BYPASS_ONBOARDING';

// Methods
export const setUser = (user = null) => ({
  type: GENERAL_SET_USER,
  user,
});
export const setProject = (project = null) => ({
  type: GENERAL_SET_PROJECT,
  project,
});
export const setProjects = (projects = []) => ({
  type: GENERAL_SET_PROJECTS,
  projects,
});
export const setSnoozeJimerLimitUntil = (snoozeUntil) => ({
  type: GENERAL_SET_SNOOZE_JIMER_LIMIT_UNTIL,
  snoozeUntil,
});

export const uptUser = (data = {}) => ({
  type: GENERAL_UPT_USER,
  data,
});
export const uptProject = (data = {}, projectId) => ({
  type: GENERAL_UPT_PROJECT,
  data,
  projectId,
});
export const uptProjectMember = (data = {}) => ({
  type: GENERAL_UPT_PROJECT_MEMBER,
  data,
});
export const uptProjectSubscription = (data = {}) => ({
  type: GENERAL_UPT_PROJECT_SUBSCRIPTION,
  data,
});
export const setBypassOnboarding = (bypass) => ({
  type: GENERAL_SET_BYPASS_ONBOARDING,
  bypass,
});
