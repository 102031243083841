import classnames from 'classnames';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import levenshtein from 'js-levenshtein';
import React, {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {tagService} from 'services';
import {TAG_CONTEXT_REQUEST} from 'services/tag';
import {Swaler} from 'swaler';
import Button from '../Button';

const DROPDOWN_TAG_ITEM_CLASSNAME = 'dropdown-tag-item';
const DROPDOWN_TAG_LIMITATION_CLASSNAME = '';
const TagAddButton = ({compact, request, onTagAdd, onAssignTag}) => {
  const logger = new Swaler('TagAddButton');

  const [inputMode, setInputMode] = useState(false);
  const [inputTag, setInputTag] = useState('');

  const inputRef = useRef();

  const {data: tags = [], refetch} = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_REQUEST]}),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (inputMode === true && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [inputMode]);

  const handleClickOutside = (e) => {
    if (
      (inputRef && inputRef.current && inputRef.current.contains(e.target)) ||
      e.target.className === DROPDOWN_TAG_ITEM_CLASSNAME ||
      e.target.className === DROPDOWN_TAG_LIMITATION_CLASSNAME
    ) {
      return;
    } else {
      setInputMode(false);
    }
  };
  const handlePressEnter = async () => {
    const tag = tags.find((t) => t.name.trim() === inputTag.trim());

    if (inputTag.length === 0) {
      return;
    }
    if (tag == null) {
      try {
        const createdTag = await tagService.createTag({
          name: inputTag,
          requestId: request.uid,
          context: TAG_CONTEXT_REQUEST,
        });

        setInputTag('');
        setInputMode(false);
        refetch();
        return onTagAdd(createdTag);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error(`Creating tag failed with error ${code}`);
        return toastDanger([title, message], {actions});
      }
    } else {
      await handleAssignTag();
    }
  };
  const handleAssignTag = async (tagStr = inputTag) => {
    const tag = tags.find((t) => t.name.trim() === tagStr.trim());

    if (request.tags.find((t) => t.uid === tag.uid)) {
      setInputTag('');
      setInputMode(false);
      return;
    }
    try {
      const assignedTag = await tagService.assignTag({
        requestId: request.uid,
        tagId: tag.uid,
      });

      setInputTag('');
      setInputMode(false);
      return onAssignTag(assignedTag);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Assigning tag failed with error ${code}`);
      return toastDanger([title, message], {actions});
    }
  };

  const classNames = classnames('btn-tag is-thin is-interactive btn-add-tag', {
    'is-compact': compact === true,
  });

  if (inputMode === false) {
    return (
      <Button
        className={classNames}
        iconLeft={compact === true ? null : 'icon-plus'}
        onClick={() => {
          setInputMode(true);
        }}>
        {compact === true ? <i className="icon-plus"></i> : 'tag'}
      </Button>
    );
  }
  return (
    <Dropdown
      contentClassName="input-add-tag-dropdown"
      trigger={
        <InputGroup
          placeholder="Enter tag name"
          className="input-add-tag"
          name="inputTag"
          value={inputTag}
          onChange={({target}) => setInputTag(target.value)}
          ref={inputRef}
          onPressEnter={handlePressEnter}
        />
      }
      position="bottom left"
      open={inputMode}>
      <DropdownContent
        inputTag={inputTag}
        tags={tags}
        onItemClick={handleAssignTag}
      />
    </Dropdown>
  );
};

function DropdownContent(props) {
  const {tags, inputTag, onItemClick} = props;
  let filteredTags = tags
    .filter((t) => t.name.toUpperCase().includes(inputTag.toUpperCase()))
    .slice(0, 7)
    .map((t) => ({...t, levenshtein: levenshtein(inputTag, t.name)}))
    .sort((a, b) => a.levenshtein - b.levenshtein);

  return (
    <div className="input-add-tag-dropdown-content">
      <div className="top">
        <div className="tag-name">
          {inputTag}
          {inputTag.length === 0 && (
            <span className="placeholder">Start typing a tag name</span>
          )}
        </div>
        <div className={classnames('action-description')}>
          {inputTag.length !== 0 ? 'Tap enter to create' : null}
        </div>
      </div>
      {filteredTags.length !== 0 && (
        <div className="dropdown-tag-list">
          {filteredTags.map((t) => (
            <div
              key={t.uid}
              className={DROPDOWN_TAG_ITEM_CLASSNAME}
              onClick={() => onItemClick(t.name)}>
              {t.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TagAddButton;
