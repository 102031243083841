import Axios from 'axios';

export const ME_F_STATUS_PASSWORD_NOT_SETUP = 64;

export const ME_F_WDYD_OTHER = 1;
export const ME_F_WDYD_CE = 2;
export const ME_F_WDYD_PD = 4;
export const ME_F_WDYD_CS = 8;
export const ME_F_WDYD_PMM = 16;
export const ME_F_WDYD_PM = 32;

export const ME_F_ONBOARDING_COMPLETED = 1;
export const ME_F_ONBOARDING_DONE_STEP_1_1 = 2;
export const ME_F_ONBOARDING_DONE_STEP_1_2 = 4;
export const ME_F_ONBOARDING_DONE_STEP_2_1 = 8;
export const ME_F_ONBOARDING_DONE_STEP_2_2 = 16;
export const ME_F_ONBOARDING_DONE_STEP_2_3 = 32;
export const ME_F_ONBOARDING_DONE_STEP_3_1 = 64;
export const ME_F_ONBOARDING_DISMISSED_EXPERIENCE_BUILDER = 128;
export const ME_F_ONBOARDING_DISMISSED_EXPERIENCE_TEMPLATES = 256;
export const ME_F_ONBOARDING_DISMISSED_EXPERIENCE_CALL = 512;

// Endpoints
const EP_ME = '/me';
const EP_ME_PROJECTS = '/me/projects';
const EP_ME_UPDATE = '/me';
const EP_ME_DELETE = '/me';
const EP_ME_WELCOME = '/me/welcome';
const EP_ME_REQUEST_TECH_HELP = '/me/request-tech-help';
const EP_ME_AVATAR_UPLOAD = '/me/avatar';
const EP_ME_AVATAR_DELETE = '/me/avatar';
const EP_ME_EXTRA_INFOS_GET = '/me/extra-infos';
const EP_ME_POST_ONBOARDING_SCHEDULED = '/me/onboarding-scheduled';
const EP_ME_EMAIL_UPDATE = '/me/email';

export const getMe = () => {
  return Axios.get(EP_ME).then((response) => response.data);
};

export const getMyProjects = () => {
  return Axios.get(EP_ME_PROJECTS).then((response) => response.data);
};

export const updateMe = (data) => {
  const {
    username,
    firstName,
    lastName,
    wdydFlags,
    companyName,
    companyWebsite,
    companySize,
    fromWhere,
  } = data;

  return Axios.put(EP_ME_UPDATE, {
    username,
    firstName,
    lastName,
    wdydFlags,
    companyName,
    companyWebsite,
    companySize,
    fromWhere,
  }).then((response) => response.data);
};

export const updateEmail = (email, securityCode) => {
  return Axios.put(EP_ME_EMAIL_UPDATE, {
    email,
    securityCode,
  }).then((response) => response.data);
};

export const uploadAvatar = (data) => {
  const {file} = data;
  const formData = new FormData();

  formData.append('file', file);
  return Axios.post(EP_ME_AVATAR_UPLOAD, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  }).then((response) => response.data);
};

export const deleteMe = () => {
  return Axios.delete(EP_ME_DELETE).then((response) => response.data);
};
export const deleteAvatar = () => {
  return Axios.delete(EP_ME_AVATAR_DELETE).then((response) => response.data);
};

export const welcomeMe = (projectId) => {
  return Axios.post(EP_ME_WELCOME, {projectId}).then(
    (response) => response.data
  );
};

export const requestTechHelp = (projectId, email) => {
  return Axios.post(EP_ME_REQUEST_TECH_HELP, {projectId, email}).then(
    (response) => response.data
  );
};

export const getExtraInfos = () => {
  return Axios.get(EP_ME_EXTRA_INFOS_GET).then((response) => response.data);
};

export const onboardingScheduled = async () => {
  return Axios.post(EP_ME_POST_ONBOARDING_SCHEDULED).then(
    (response) => response.data
  );
};

export const updateOnboardingFlag = async (onboardingFlags) => {
  return Axios.put(EP_ME_UPDATE, {
    onboardingFlags,
  }).then((response) => response.data);
};
