import SegmentioIcon from './imgs/segmentio-icon-dark.svg';
import './_Styles.scss';

const SegmentIcon = () => {
  return (
    <div className="segmentio-icon">
      <img className="icon" src={SegmentioIcon} alt="segmentio-icon" />
    </div>
  );
};

export default SegmentIcon;
