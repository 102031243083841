import {object, func, string, bool} from 'prop-types';
import './_Styles.scss';
import {TAB_CHART, TAB_LIKES} from '../..';
import classNames from 'classnames';

const propTypes = {
  analytics: object,
  evolution: object,
  onTabClick: func,
  selectedTab: string,
  isSingleStepPoke: bool,
};

const defaultProps = {
  analytics: null,
  evolution: null,
  onTabClick: () => {},
  selectedTab: null,
  isSingleStepPoke: false,
};

const StatsRow = ({analytics, evolution, onTabClick, selectedTab}) => {
  const viewsCount = analytics?.viewsCount || 0;

  return (
    <>
      <div className="post-statistics-row">
        <div
          className={classNames('stat-wrapper', {
            selected: selectedTab === TAB_CHART,
          })}
          onClick={() => onTabClick(TAB_CHART)}>
          <div className="stat-icon views">
            <i className="isax isax-eye" />
          </div>
          <div className="stat-content">
            <div className="stat-value">{viewsCount} Views</div>
            <div className="stat-title">Post views</div>
          </div>
        </div>
        <div
          className={classNames('stat-wrapper', {
            selected: selectedTab === TAB_LIKES,
          })}
          onClick={() => onTabClick(TAB_LIKES)}>
          <div className="stat-icon likes">
            <i className="isax isax-heart" />
          </div>
          <div className="stat-content">
            <div className="stat-value">
              {evolution?.countVotes} Like
              {evolution?.countVotes > 1 ? 's' : ''}
            </div>
            <div className="stat-title">Post likes</div>
          </div>
        </div>
      </div>
    </>
  );
};

StatsRow.propTypes = propTypes;
StatsRow.defaultProps = defaultProps;

export default StatsRow;
