import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useInvoiceUpcoming');

export const useInvoiceUpcoming = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [invoice, setInvoice] = useState(null);

  async function fetch() {
    setIsFetching(true);
    try {
      const invoice = await subscriptionService.getUpcomingInvoice();

      setInvoice(invoice == null || invoice.length === 0 ? null : invoice);
      setIsFetching(false);
      return invoice;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsFetching(false);
      logger.error(`Fetch upcoming invoice failed with error : `, code);
      return toastDanger([title, message], {actions});
    }
  }

  return {
    isFetching,
    invoice,
    fetch,
  };
};
