import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import React from 'react';

export default class DevModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal1: false,
      showModal2: false,
    };
  }

  handleShowHide = (modal) => this.setState({[modal]: !this.state[modal]});

  render() {
    return (
      <div className="_dev-modal">
        <Modal
          isOpen={this.state.showModal1}
          small
          title="A simple modal"
          onRequestClose={() => this.handleShowHide('showModal1')}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Necessitatibus blanditiis, consequuntur mollitia, hic corrupti
          architecto quo voluptates assumenda labore eos dolorem sapiente,
          deserunt doloremque illo nemo ratione. Libero, nesciunt pariatur.
        </Modal>
        <Button onClick={() => this.handleShowHide('showModal1')}>
          Simple modal
        </Button>

        <Modal
          isOpen={this.state.showModal2}
          small
          title="An interactive modal"
          onRequestClose={() => this.handleShowHide('showModal2')}>
          <Input placeholder="my@email.com"></Input>
          <div className="actions">
            <Button muted onClick={() => this.handleShowHide('showModal2')}>
              Cancel
            </Button>
            <Button primary>Submit request</Button>
          </div>
        </Modal>
        <Button onClick={() => this.handleShowHide('showModal2')}>
          Advanced modal
        </Button>
      </div>
    );
  }
}
