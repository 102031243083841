import classNames from 'classnames';
import {func} from 'prop-types';
import React from 'react';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import './_Styles.scss';
import ActivationTracker from './imgs/activation-tracker.svg';
import FeatureUsage from './imgs/feature-usage.svg';

const options = [
  {
    className: 'activation-tracker',
    title: 'Activation tracker',
    description:
      'Track success accross multiple steps, from sign up to your users aha moments and see Jimo’s impact',
    image: <img src={ActivationTracker} alt="Activation tracker" />,
    type: TRACKER_TYPE_ACTIVATION_TRACKER,
  },
  {
    className: 'feature-usage',
    title: 'Feature usage',
    description: 'Track the usage of a specific feature and see Jimo’s impact.',
    image: <img src={FeatureUsage} alt="Feature usage" />,
    type: TRACKER_TYPE_FEATURE_USAGE,
  },
];

const TrackerTypeBlock = ({
  index,
  className,
  image,
  title,
  description,
  onClick,
}) => {
  return (
    <div
      key={index}
      className={classNames('tracker-type-block', className)}
      onClick={onClick}>
      <div className="type-header">
        <div className="img-wrapper">{image}</div>
      </div>
      <div className="type-infos">
        <div className="type-title">{title}</div>
        <div className="type-description body-3 n-700">{description}</div>
      </div>
    </div>
  );
};

const propTypes = {
  onTrackerTypeClick: func,
};

const defaultProps = {
  onTrackerTypeClick: () => {},
};

const PickTrackerType = ({onTrackerTypeClick}) => {
  return (
    <div className="pick-tracker-type">
      <div className="tracker-modal-content">
        <div className="tracker-types">
          {options.map((option, index) => (
            <TrackerTypeBlock
              index={index}
              {...option}
              onClick={() => onTrackerTypeClick(option.type)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

PickTrackerType.propTypes = propTypes;
PickTrackerType.defaultProps = defaultProps;

export default PickTrackerType;
