import {EVENT_NEW_SEGMENT_CREATED} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {array, bool, func, object, string} from 'prop-types';
import {useEffect, useState} from 'react';
import {useQueries} from 'react-query';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_USERS_SEGMENT_WITH_ID} from 'router/routes.const';
import ModalCreateSegment from 'scenes/Users/components/ModalCreateSegment';
import {generalSelector} from 'selectors';
import {eventService, segmentioService, segmentService} from 'services';
import {
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {Swaler} from 'swaler';
import {Attribute} from './attribute.class';
import {AttributeActive} from './attributes/active.attribute';
import {AttributeCreated} from './attributes/created.attribute';
import {AttributeCustom} from './attributes/custom.attribute';
import {AttributeEmail} from './attributes/email.attribute';
import {JimerActivityStatsAttribute} from './attributes/jimer-activity-stats.attribute';
import {JimerActivityAttribute} from './attributes/jimer-activity.attribute';
import {AttributeLastActivity} from './attributes/lastActivity.attribute';
import {AttributeName} from './attributes/name.attribute';
import {AttributeSegmentioEvent} from './attributes/segmentio-event.attribute';
import {AttributeSegmentioField} from './attributes/segmentio-field.attribute';
import {AttributeSessions} from './attributes/sessions.attribute';
import SelectAttribute, {
  ATTRIBUTES,
  JIMER_ACTIVITY_ATTRIBUTES,
} from './select-attribute';
import './_Styles.scss';

const logger = new Swaler('SegmentAttributesEditor');

const propTypes = {
  segment: object,
  dropdownProps: object,
  withSegments: array,
  onSave: func,
  onLookup: func,
  onChange: func,
  onRemoveOnTheFly: func,
  onSelectExistingSegment: func,
  withGlobalBg: bool,
  hideDefaultActions: bool,
  hideAttributes: bool,
  addButtonLabel: string,
};

const defaultProps = {
  dropdownProps: {},
  withSegments: [],
  segment: null,
  onSave: () => {},
  onLookup: () => {},
  onChange: null,
  onRemoveOnTheFly: null,
  onSelectExistingSegment: () => {},
  withGlobalBg: true,
  hideDefaultActions: false,
  hideAttributes: false,
  addButtonLabel: null,
};

const SegmentAttributesEditorLegacy = ({
  segment,
  withSegments,
  dropdownProps,
  hideDefaultActions,
  hideAttributes,
  onSave,
  onLookup,
  onChange,
  onRemoveOnTheFly,
  onSelectExistingSegment,
  addButtonLabel,
}) => {
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );
  const [queriesDone, setQueriesDone] = useState([
    false,
    project.segmentio == null ? true : false,
    project.segmentio == null ? true : false,
  ]); // [tracked events, segmentio fields, segmentio events]

  const [
    {data: segmentioFields = []},
    {data: segmentioEvents = []},
    {data: trackedEvents = []},
  ] = useQueries([
    {
      queryKey: 'segmentioFields',
      queryFn: () => segmentioService.getSegmentioFields(project.uid),
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setQueriesDone((queriesDone) => [queriesDone[0], true, queriesDone[2]]);
      },
    },
    {
      queryKey: 'segmentioEvents',
      queryFn: () => segmentioService.getSegmentioEvents(project.uid),
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setQueriesDone((queriesDone) => [queriesDone[0], queriesDone[1], true]);
      },
    },
    {
      queryKey: 'trackedEvents',
      queryFn: () => eventService.getEvents({onlyTracked: true}),
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setQueriesDone((queriesDone) => [true, queriesDone[1], queriesDone[2]]);
      },
    },
  ]);

  segment = JSON.parse(JSON.stringify(segment));

  const [attributes, setAttributes] = useState(segment?.attributes || []);
  const [loading, setLoading] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);

  useEffect(() => {
    if (segment == null && attributes.length <= 0) {
      onLookup([]);
    }
  }, [attributes]);

  useEffect(() => {});

  useEffect(() => {
    if (
      segment?.temporary === true &&
      JSON.stringify(attributes) !== JSON.stringify(segment?.attributes || [])
    ) {
      setAttributes(segment.attributes || []);
    }
  }, [segment]);

  const handleAddAttribute = (attr, opts = null) => {
    const attributesUpdated = attributes.concat(
      new Attribute(
        attr,
        null,
        null,
        opts?.eventId != null ? opts.eventId : null
      )
    );

    setAttributes(attributesUpdated);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };
  const handleDeleteAttribute = (uid) => {
    const attributesUpdated = attributes.filter((attr) => attr.uid !== uid);

    setAttributes(attributesUpdated);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };
  const handleChangeAttributeType = (uid, newType) => {
    const attributesUpdated = attributes.map((attr) =>
      attr.uid === uid ? new Attribute(newType) : attr
    );

    setAttributes(attributesUpdated);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };
  const handleChangeAttributeProperty = (uid, newProperty) => {
    const attributesUpdated = attributes.map((attr) => {
      if (attr.uid === uid) {
        attr.property = newProperty;
      }
      return attr;
    });

    setAttributes(attributesUpdated);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };
  const handleUpdateAttribute = (uid, attrUpdated) => {
    const attributesUpdated = attributes.map((attr) =>
      attr.uid === uid ? attrUpdated : attr
    );

    setAttributes(attributesUpdated);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };
  const handleSave = async (segmentParam = segment) => {
    setLoading(true);
    try {
      const updatedSegment = await segmentService.updateSegmentAttributes(
        segmentParam.uid,
        {attributes}
      );
      const savedAttributes = updatedSegment.attributes;

      onSave(JSON.parse(JSON.stringify(savedAttributes)));
      setAttributes(savedAttributes);
    } catch (err) {
      toastDanger([
        'Wups...',
        'We could not save attributes, please try again!',
      ]);
      logger.error('Saving attributes failed with error ', err);
    } finally {
      setLoading(false);
    }
  };

  const hasChanges = () =>
    JSON.stringify(segment?.attributes) !== JSON.stringify(attributes);

  const renderAttributeByType = (attr) => {
    const commonProps = {
      key: attr.uid,
      attribute: attr,
      onDelete: () => handleDeleteAttribute(attr.uid),
      onChangeType: (newType) => handleChangeAttributeType(attr.uid, newType),
      onChangeProperty: (newProperty) =>
        handleChangeAttributeProperty(attr.uid, newProperty),
      onUpdateOperator: (operator) => {
        attr.op = operator;
        handleUpdateAttribute(attr.uid, attr);
      },
      onUpdateValue: (index, value) => {
        attr.values[index] = value;
        handleUpdateAttribute(attr.uid, attr);
      },
      onUpdate: (attr) => handleUpdateAttribute(attr.uid, attr),
      onAddValue: (defaultValue = '') => {
        attr.values = attr.values.concat(defaultValue);

        handleUpdateAttribute(attr.uid, attr);
      },
      onDeleteValue: (indexToRemove) => {
        attr.values = attr.values.filter((v, index) => index !== indexToRemove);

        handleUpdateAttribute(attr.uid, attr);
      },
      onChangeEvolutions: (evolutions) => {
        attr.evolutions = evolutions;
        handleUpdateAttribute(attr.uid, attr);
      },
      segmentioFields,
      segmentioEvents,
      trackedEvents,
    };

    switch (attr.type) {
      case ATTRIBUTES.ATTR_NAME: {
        return <AttributeName {...commonProps} />;
      }
      case ATTRIBUTES.ATTR_EMAIL: {
        return <AttributeEmail {...commonProps} />;
      }
      case ATTRIBUTES.ATTR_ACTIVE: {
        return <AttributeActive {...commonProps} />;
      }
      case ATTRIBUTES.ATTR_LAST_ACTIVITY: {
        return <AttributeLastActivity {...commonProps} />;
      }
      case ATTRIBUTES.ATTR_CREATED: {
        return <AttributeCreated {...commonProps} />;
      }
      case ATTRIBUTES.ATTR_CUSTOM: {
        return <AttributeCustom {...commonProps} />;
      }
      case ATTRIBUTES.ATTR_SESSIONS: {
        return <AttributeSessions {...commonProps} />;
      }
      case JIMER_ACTIVITY_ATTRIBUTES.ENGAGEMENT_RATE:
      case JIMER_ACTIVITY_ATTRIBUTES.NUMBER_INTERACTED_PUSH: {
        return <JimerActivityStatsAttribute {...commonProps} />;
      }
      case JIMER_ACTIVITY_ATTRIBUTES.INTERACTED_WITH:
      case JIMER_ACTIVITY_ATTRIBUTES.NINTERACTED_WITH:
      case JIMER_ACTIVITY_ATTRIBUTES.VOTED:
      case JIMER_ACTIVITY_ATTRIBUTES.NVOTED:
      case JIMER_ACTIVITY_ATTRIBUTES.COMMENTED:
      case JIMER_ACTIVITY_ATTRIBUTES.NCOMMENTED:
      case JIMER_ACTIVITY_ATTRIBUTES.SAW:
      case JIMER_ACTIVITY_ATTRIBUTES.NSAW:
      case JIMER_ACTIVITY_ATTRIBUTES.SURVEY_EXITED:
      case JIMER_ACTIVITY_ATTRIBUTES.SURVEY_COMPLETED:
      case JIMER_ACTIVITY_ATTRIBUTES.SURVEY_NCOMPLETED: {
        return <JimerActivityAttribute {...commonProps} />;
      }
      case 'SEGMENTIO_FIELDS': {
        return <AttributeSegmentioField {...commonProps} />;
      }
      case 'SEGMENTIO_EVENTS': {
        return <AttributeSegmentioEvent {...commonProps} />;
      }
      case 'TRACKED_EVENTS': {
        return <AttributeSegmentioEvent {...commonProps} />;
      }
      default: {
        return null;
      }
    }
  };

  const classNames = classnames('segment-attributes-editor-legacy', {
    'is-empty': attributes.length === 0,
    'is-loading': queriesDone.some((q) => q === false),
  });

  if (queriesDone.some((q) => q === false)) {
    return (
      <div className={classNames}>
        <Loader width={12}></Loader>
      </div>
    );
  }
  return (
    <div className={classNames}>
      <div
        className={classnames('list-attributes', {
          'is-empty': attributes.length <= 0,
        })}>
        {attributes.map((attr, index) => renderAttributeByType(attr))}
      </div>
      {hideDefaultActions === false && (
        <div
          className={classnames('btns-wrapper', {
            'no-btns': onRemoveOnTheFly == null,
          })}>
          {onRemoveOnTheFly != null ? (
            <Button onClick={onRemoveOnTheFly} thin className="btn-remove">
              Remove on the fly segment
            </Button>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

SegmentAttributesEditorLegacy.propTypes = propTypes;
SegmentAttributesEditorLegacy.defaultProps = defaultProps;

export default SegmentAttributesEditorLegacy;
