import Alert from 'components/Alert';
import DefaultLoader from 'components/Loader';
import React from 'react';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('IntercomSettingsSetup');

const SettingsSetup = ({intercom, isIntercomLoading}) => {
  return (
    <div className="intercom-settings-setup">
      {isIntercomLoading === true ? (
        <DefaultLoader width="24px" />
      ) : (
        <Alert info title="Setting up Intercom" icon="isax isax-info-circle">
          Check your Intercom Messenger configuration to add Jimo
        </Alert>
      )}
    </div>
  );
};

export default SettingsSetup;
