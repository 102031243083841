import Axios from 'axios';
import {generalSelector} from 'selectors';

const EP_CRISP_WIDGET_SETUP = '/crisp-widget/setup';

export const setupCrispWidgetIntegration = async (
  enable = true,
  projectId = generalSelector.getProject().uid
) => {
  return Axios.post(
    EP_CRISP_WIDGET_SETUP,
    {enable},
    {params: {projectId}}
  ).then((response) => response.data);
};
