import Axios from 'axios';
import JwtDecode from 'jwt-decode';
import {authService} from 'services';
import {Environment} from './env';

Axios.defaults.baseURL = Environment.API_URL;

Axios.interceptors.response.use(
  (response) => {
    // 2xx responses
    return response;
  },
  (error) => {
    if (error.response) {
      // If 401, check if jwt token exists and expired
      if (error.response.status === 401) {
        let jwtToken = authService.getJwtToken();

        if (jwtToken) {
          jwtToken = JwtDecode(jwtToken);
          if (authService.hasExpiredJwtToken()) {
            return authService.signOut();
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

export const setBearerToken = (token) => {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
export const unsetBearerToken = () => {
  delete Axios.defaults.headers.common['Authorization'];
};
