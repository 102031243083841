import classnames from 'classnames';
import Select from 'components/Select';
import {useEffect, useState} from 'react';
import {segmentService} from 'services';

export const SelectCustomType = (props) => {
  let {className, value, onChange, ...rest} = props;
  const [types, setTypes] = useState([]);
  const [inputSearch, setInputSearch] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const fetchCustomTypes = async () => {
    const types = await segmentService.getSegmentCustomAttributes();

    setTypes(types.map((t) => ({label: t.name, value: t.uid})));
  };

  useEffect(() => {
    const triggerFetchCustomTypes = async () => {
      await fetchCustomTypes();
    };

    triggerFetchCustomTypes();
  }, []);

  const handleChange = (value) => {
    onChange(value.value);
    setMenuIsOpen(false);
  };
  const handlePressEnter = async () => {
    const type = Object.values(types).find((o) =>
      o.label.includes(inputSearch)
    );

    if (inputSearch != null && type == null) {
      const customAttribute = await segmentService.createCustomAttribute({
        attributeName: inputSearch,
      });
      const type = {
        label: customAttribute.name,
        value: customAttribute.uid,
      };

      setTypes(types.concat(type));
      handleChange(type);
    }
  };

  value = Object.values(types).find((o) => o.value === value);
  return (
    <Select
      menuIsOpen={menuIsOpen}
      options={types}
      placeholder="select custom type"
      value={value}
      className={classnames('select-custom-types', className)}
      noOptionsMessage={() => 'Press enter to create'}
      onChange={handleChange}
      onInputChange={(str) => setInputSearch(str)}
      onPressEnter={handlePressEnter}
      onMenuOpen={(value) => setMenuIsOpen(value)}
      {...rest}
      isDisabled></Select>
  );
};
