import Button from 'components/Button';
import React from 'react';
import SelectAttribute, {ATTRIBUTES} from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [OPTIONS.OP_IS_ACTIVE, OPTIONS.OP_NIS_ACTIVE];

export const AttributeActive = ({
  attribute,
  onDelete,
  onChangeType,
  onUpdateOperator,
  segmentioFields,
  segmentioEvents,
  trackedEvents,
}) => {
  return (
    <div className="segment-attribute attribute-active">
      <div className="header-row">
        <SelectAttribute
          value={ATTRIBUTES.ATTR_ACTIVE}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => onUpdateOperator(op)}
        />
      </div>
    </div>
  );
};
