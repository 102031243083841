import {EVENT_EDIT_EVOLUTION} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Button from 'components/Button';
import {JimoLoader} from 'components/Loader';
import Tabs from 'components/Tabs';
import {PermissionsPoke} from 'constants/permissions';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {removeTrailingSlash} from 'helpers/evolution';
import {hasPermissions} from 'helpers/permission';
import useUndercityBuilderMessenger from 'managers/useDalaranMessenger';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import ModalCreatePoke from 'scenes/Pushes/components/ModalCreatePoke';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
  F_OPTION_V2,
} from 'services/evolution';
import './_Styles.scss';
import ModalEditUrl from './components/EditUrlModal';
import TypePicker from './components/EditUrlModal/TypePicker';

const ExtensionBuilder = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [currentUrl, setCurrentUrl] = useState(null);
  const [showNewPushModal, setShowNewPushModal] = useState(false);
  const [showEditUrlModal, setShowEditUrlModal] = useState(false);
  const [pokeToEdit, setPokeToEdit] = useState(null);
  const [type, setType] = useState(null);

  const handleCurrentUrlChange = (data) => {
    setCurrentUrl(data.url);
  };

  const messenger = useUndercityBuilderMessenger({
    onCurrentUrlChange: handleCurrentUrlChange,
  });

  const {data: pokes = [], isLoading} = useQuery({
    queryKey: ['extension', 'pokes'],
    queryFn: async () => {
      const pokes = await evolutionService.getEvolutions({
        onlyPokes: true,
      });
      if (pokes.length <= 0) {
        setShowNewPushModal(true);
      }
      return pokes;
    },
  });

  useEffect(() => {
    messenger.sendProject({project: project});
    messenger.sendRequestCurrentUrl();
  }, []);

  const handlePokeClick = (poke) => {
    // const isUrlMatching = isUrlMatchingNavigation(poke, currentUrl);
    const isUrlMatching =
      removeTrailingSlash(poke.boostedActiveUrl) ===
      removeTrailingSlash(currentUrl);

    if (isUrlMatching !== true) {
      setPokeToEdit(poke);
      setShowEditUrlModal(true);
    } else {
      amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
        from: 'Extension',
      });
      messenger.sendPokeToExtension({
        pokeId: poke.uid,
        mode: 'EDIT',
      });
    }
  };

  pokes.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  const canEnterExtensionBuilder = hasPermissions([
    PermissionsPoke.ACCESS,
    PermissionsPoke.CREATE_EDIT,
  ]);

  if (canEnterExtensionBuilder === false) {
    return (
      <div className="extension-builder-wrapper">
        <Helmet>
          <body className={classnames('extension-embed access-denied')} />
        </Helmet>
        <div className="error-message">
          <h1>Insufficient permissions</h1>
          <p>
            Your current role doesn't allows you to build a poke.
            <br />
            Contact the administrator of the project.
          </p>
          <Button primary onClick={() => messenger.sendCloseEmbeddedBuilder()}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  if (showNewPushModal === true) {
    return (
      <div className={classnames('extension-builder')}>
        <Helmet>
          <body className={classnames('extension-embed', 'create-poke')} />
        </Helmet>
        <ModalCreatePoke
          isOpen={showNewPushModal}
          onRequestClose={() => setShowNewPushModal(false)}
          isExtension
          type={type}
          onCreatedPoke={(poke) => {
            messenger.sendPokeToExtension({
              pokeId: poke.uid,
              mode: 'EDIT',
            });
          }}
        />
      </div>
    );
  }

  if (showEditUrlModal === true) {
    return (
      <div className={classnames('extension-builder')}>
        <Helmet>
          <body className={classnames('extension-embed', 'create-poke')} />
        </Helmet>
        <ModalEditUrl
          isOpen={showEditUrlModal}
          onRequestClose={() => setShowEditUrlModal(false)}
          poke={pokeToEdit}
          currentUrl={currentUrl}
          onEdit={async (url) => {
            if (url !== pokeToEdit.boostedActiveUrl) {
              await evolutionService.updateEvolution(pokeToEdit.uid, {
                ...pokeToEdit,
                boostedActiveUrl: url,
              });
            }
            amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
              from: 'Extension',
            });
            messenger.sendPokeToExtension({
              pokeId: pokeToEdit.uid,
              mode: 'EDIT',
              url: url !== currentUrl ? url : null,
            });
          }}
        />
      </div>
    );
  }

  return (
    <div className="extension-builder-wrapper">
      <div className="btns-action-wrapper">
        <Button
          primary
          className="close-btn"
          iconLeft="icon-close"
          onClick={() => messenger.sendCloseEmbeddedBuilder()}>
          close in-app builder
        </Button>
      </div>

      <div
        className={classnames('extension-builder', {
          'has-type': type != null,
        })}>
        <Helmet>
          <body className={classnames('extension-embed', 'poke-list')} />
        </Helmet>
        {isLoading ? (
          <div className="loading-overlay">
            Wait while we're loading your in-app poke builder
            <JimoLoader />
          </div>
        ) : (
          <>
            <div className="pokes-list-wrapper">
              {type == null ? (
                <>
                  <div className="pokes-list-header">
                    <div className="title">Choose a type of experience</div>
                  </div>
                  <TypePicker setType={setType} />
                </>
              ) : (
                <>
                  <div className="pokes-list-header">
                    <Button
                      className="back-btn"
                      iconOnly
                      iconLeft="icon-chevron-left"
                      onClick={() => {
                        setType(null);
                      }}
                    />
                    <div className="title">
                      Choose from existing {type?.toLowerCase()}s
                    </div>
                  </div>
                  <div className="pokes-list">
                    {pokes
                      .filter((poke) => {
                        // filter out old pokes
                        if (hasFlag(F_OPTION_V2, poke.optionsFlags) !== true) {
                          return false;
                        }
                        return poke.type === type;
                      })
                      .map((poke) => {
                        const {title, boostFlags, createdAt} = poke;
                        const isTour = hasFlag(F_BOOST_SLOT_TOUR, boostFlags);
                        const isHotspot = hasFlag(F_BOOST_SLOT_DOT, boostFlags);
                        const isTooltip = hasFlag(
                          F_BOOST_SLOT_TOOLTIP,
                          boostFlags
                        );
                        const isBanner = hasFlag(
                          F_BOOST_SLOT_TOP_BAR,
                          boostFlags
                        );
                        const isSnippet = hasFlag(
                          F_BOOST_SLOT_SNIPPET,
                          boostFlags
                        );
                        const isPopIn = hasFlag(
                          F_BOOST_SLOT_POP_IN,
                          boostFlags
                        );

                        return (
                          <div
                            className="poke"
                            key={poke.uid}
                            onClick={() => {
                              handlePokeClick(poke);
                            }}>
                            <div className="poke-type-wrapper">
                              {isHotspot ? (
                                <div className="type-tag">
                                  <i className="icon-slot-dot" />
                                  Hotspot
                                </div>
                              ) : isTooltip ? (
                                <div className="type-tag">
                                  <i className="icon-slot-tooltip" />
                                  Tooltip
                                </div>
                              ) : isBanner ? (
                                <div className="type-tag">
                                  <i className="icon-slot-top-bar" />
                                  Banner
                                </div>
                              ) : isSnippet ? (
                                <div className="type-tag">
                                  <i className="icon-slot-snippet" />
                                  Snippet
                                </div>
                              ) : isPopIn ? (
                                <div className="type-tag">
                                  <i className="icon-slot-pop-in" />
                                  Pop-in
                                </div>
                              ) : isTour ? (
                                <div className="type-tag">
                                  <i className="icon-tour" />
                                  Tour
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="push-title-wrapper">
                              <div className="push-title">{title}</div>
                              <div className="created-at">
                                Created {dayjs(createdAt).fromNow()}
                              </div>
                            </div>
                            <div className="arrow">
                              <i className="icon-arrow-right" />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="footer">
                    <Button
                      cta
                      primary
                      className="create-new-poke-btn"
                      onClick={() => setShowNewPushModal(true)}>
                      Create a new {type?.toLowerCase()}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExtensionBuilder;
