import Button from 'components/Button';
import Select from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {trackingCode} from 'components/TrackingCode';
import {Environment} from 'conf/env';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {gtmService} from 'services';
import {Swaler} from 'swaler';
import logoGTM from '../../imgs/logo-gtm.svg';
import './_Styles.scss';

const google = window.google;
const SCOPES = [
  'https://www.googleapis.com/auth/tagmanager.manage.accounts',
  'https://www.googleapis.com/auth/tagmanager.edit.containers',
  'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
  'https://www.googleapis.com/auth/tagmanager.publish',
];

const logger = new Swaler('IntegrationGTM');

export const CardGTM = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [accessToken, setAccessToken] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const refClient = useRef(null);

  const fetchAccounts = async (token = accessToken) => {
    try {
      const accounts = await gtmService.getAccounts({accessToken: token});

      setAccounts(accounts);
    } catch (err) {
      logger.error('fetching accounts failed with error ', err.message);
    }
  };
  const fetchContainers = async (accountPath) => {
    accountPath = accountPath ?? selectedAccount.path;
    try {
      const containers = await gtmService.getContainers({
        accessToken,
        accountPath,
      });

      setContainers(containers);
    } catch (err) {
      logger.error('fetching containers failed with error ', err.message);
    }
  };
  const createTag = async (shouldPublish = true) => {
    const code = trackingCode(project.uid);

    setIsPublishing(true);
    try {
      await gtmService.publishTag({
        accessToken,
        containerPath: selectedContainer.value,
        projectId: project.uid,
        code,
        shouldPublish,
      });
      setIsPublishing(false);
      setIsPublished(true);
      if (shouldPublish === true) {
        toastSuccess(
          [
            'Tag published',
            `Jimo has been integrated to ${project.name} through GTM.`,
          ],
          {toastId: 'tag-published'}
        );
      } else {
        toastSuccess(
          [
            'Tag created',
            `A new workspace "Jimo GTM Integration" with our tag is ready to be published in your GTM account.`,
          ],
          {toastId: 'tag-created'}
        );
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsPublishing(false);
      logger.error('fetching containers failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  useEffect(() => {
    refClient.current = google.accounts.oauth2.initTokenClient({
      client_id: Environment.GOOGLE_GTM_CLI,
      scope: SCOPES.join(' '),
      ux_mode: 'popup',
      callback: (response) => {
        setAccessToken(response.access_token);
        fetchAccounts(response.access_token);
      },
    });
  }, []);

  return (
    <div className="settings-card card-gtm">
      <div className="card-header">
        <div className="logo-wrapper">
          <img src={logoGTM} alt="Google Tag Manager" />
        </div>
        <div className="left-wrapper">
          <span className="subtitle-3">
            Install Jimo with Google Tag Manager
            <span className="custom-tag body-4">Optional</span>
          </span>
          <span className="body-3 n-700">
            Alternative method for installing Jimo through connection Google Tag
            Manager
          </span>
        </div>
        {accessToken == null && (
          <div className="right-wrapper">
            <Button onClick={() => refClient.current.requestAccessToken()}>
              Create tag
            </Button>
          </div>
        )}
      </div>
      {accessToken != null && (
        <div className="card-body">
          <div className="gtm-setup-wrapper">
            <Select
              legend="Account"
              noOptionsMessage={() => "We couldn't find GTM account available"}
              placeholder="Select a GTM account"
              options={accounts.map((a) => ({label: a.name, value: a.path}))}
              value={selectedAccount}
              onChange={(value) => {
                setSelectedAccount(value);
                fetchContainers(value.value);
              }}></Select>
            <Select
              legend="Container"
              placeholder="Select a GTM container"
              options={containers.map((a) => ({
                label: a.name,
                value: a.path,
              }))}
              isDisabled={selectedAccount == null}
              value={selectedContainer}
              onChange={(value) => {
                setSelectedContainer(value);
              }}></Select>
          </div>
          {selectedContainer != null && isPublished === false && (
            <div className="gtm-actions">
              <Button
                thin
                loading={isPublishing}
                onClick={() => createTag(false)}>
                Create tag only
              </Button>
              <Button
                primary
                thin
                loading={isPublishing}
                onClick={() => createTag()}>
                Create & publish tag
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
