// Types
export const TAGS_SET = 'TAGS_SET';
export const TAGS_SET_FILTER = 'TAGS_SET_FILTER';
export const TAGS_ADD = 'TAGS_ADD';
export const TAGS_UPT = 'TAGS_UPT';

// Methods
export const setTags = (tags = []) => ({
  type: TAGS_SET,
  tags,
});
export const setTagsFilter = (tags = []) => ({
  type: TAGS_SET_FILTER,
  tags,
});

export const addTag = (tag = null) => ({
  type: TAGS_ADD,
  tag,
});
export const uptTag = (tagId = null, data = null) => ({
  type: TAGS_UPT,
  tagId,
  data,
});
