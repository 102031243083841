import Button from 'components/Button';
import Input from 'components/Input';
import SelectAttribute, {ATTRIBUTES} from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [
  OPTIONS.OP_IS,
  OPTIONS.OP_NIS,
  OPTIONS.OP_IS_GREATER,
  OPTIONS.OP_IS_GREATER_OR_EQUAL,
  OPTIONS.OP_IS_LESSER,
  OPTIONS.OP_IS_LESSER_OR_EQUAL,
  OPTIONS.OP_IS_BETWEEN,
  OPTIONS.OP_IS_NBETWEEN,
];

export const AttributeSessions = (props) => {
  const {
    attribute,
    onDelete,
    onChangeType,
    onUpdate,
    onUpdateValue,
    onAddValue,
    onDeleteValue,
  } = props;

  return (
    <div className="segment-attribute attribute-session">
      <div className="header-row">
        <SelectAttribute
          value={ATTRIBUTES.ATTR_SESSIONS}
          onChange={(value) => onChangeType(value)}
        />
      </div>
      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => {
            let values = attribute.values;

            attribute.op = op;
            if (
              op === OPTIONS.OP_IS_BETWEEN.value ||
              op === OPTIONS.OP_IS_NBETWEEN.value
            ) {
              values = attribute.values.length < 2 ? values.concat('') : values;
              onUpdate({...attribute, values});
            } else {
              if (values.length > 1) {
                values.pop();
              }
              onUpdate({...attribute, values});
            }
          }}
        />
        {attribute.values.map((v, index) => (
          <Input
            key={index}
            type="number"
            placeholder="enter value..."
            labelTextRight={
              attribute.values.length > 1 &&
              [
                OPTIONS.OP_IS_BETWEEN.value,
                OPTIONS.OP_IS_NBETWEEN.value,
              ].includes(attribute.op) === false ? (
                <i
                  className="icon-close"
                  onClick={() => onDeleteValue(index)}></i>
              ) : null
            }
            value={v}
            onChange={({target}) => onUpdateValue(index, target.value)}
            disabled
          />
        ))}
      </div>
    </div>
  );
};
