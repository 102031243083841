import Axios from 'axios';
import {generalSelector} from 'selectors';

export const VIEW_LAYOUT_ROW = 'ROW';
export const VIEW_LAYOUT_ROW_LARGE = 'ROW_LARGE';
export const VIEW_LAYOUT_GRID = 'GRID';

// Endpoints
const EP_VIEW_GET = '/evolution-list-view';
const EP_VIEW_CREATE = '/evolution-list-view';
const EP_VIEW_DELETE = (viewId) => `/evolution-list-view/${viewId}`;
const EP_VIEW_UPDATE = (viewId) => `/evolution-list-view/${viewId}`;
const EP_VIEW_NAME_UPDATE = (viewId) => `/evolution-list-view/${viewId}/name`;

// Methods
export const getListViews = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_VIEW_GET, {params: {projectId}}).then(
    (response) => response.data
  );
};

export const createListView = (data) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_VIEW_CREATE, {
    projectId,
    ...data,
  }).then((response) => response.data);
};

export const deleteListView = (viewId) => {
  return Axios.delete(EP_VIEW_DELETE(viewId)).then((response) => response.data);
};

export const updateListView = (viewId, data) => {
  return Axios.put(EP_VIEW_UPDATE(viewId), data).then(
    (response) => response.data
  );
};

export const updateListViewName = (viewId, name) => {
  return Axios.put(EP_VIEW_NAME_UPDATE(viewId), {name}).then(
    (response) => response.data
  );
};
