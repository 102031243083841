import classnames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Card from 'components/Card';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import ModalTagManager from 'components/ModalTagManager';
import {PermissionsRequest} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {hasUsernameGenerated} from 'helpers/utils';
import {array, func} from 'prop-types';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory, useParams} from 'react-router-dom';
import {ROUTE_DRAFT_WITH_ID} from 'router/routes.const';
import {tagService} from 'services';
import {TAG_CONTEXT_REQUEST} from 'services/tag';
import ModalAddOrEditRequest from '../ModalAddOrEditRequest';
import './_Styles.scss';

const propTypes = {
  requests: array.isRequired,
  refetchRequests: func.isRequired,
};

const defaultProps = {};

const RequestList = ({requests, refetchRequests}) => {
  const [search, setSearch] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const [showModalTagManager, setShowModalTagManager] = useState(false);
  const [showAddRequest, setShowAddRequest] = useState(false);

  const {data: tags = [], refetch} = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_REQUEST]}),
  });

  const filterRequests = requests
    .filter((r) => {
      if (filteredTags.length > 0) {
        if (
          r.tags
            .map((t) => t.uid)
            .some((tId) => filteredTags.map((t) => t.uid).includes(tId))
        ) {
          return true;
        }
        return false;
      }
      return search
        .toLowerCase()
        .split(' ')
        .every((word) =>
          `${r.title} ${r.content} ${r.jimer?.externalEmail} ${r.jimer?.identifyToken} ${r.jimer?.externalUid} ${r.jimer?.externalUsername} ${r.jimer?.username}`
            .toLowerCase()
            .includes(word)
        );
    })
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

  const totalCount = filterRequests.length;
  const unreadRequests = filterRequests.filter((r) => r.unread === true);
  const unreadCount = unreadRequests.length;

  return (
    <div className="draft-request-list">
      <div className="filter-header">
        <Input
          className="input-search"
          value={search}
          placeholder="Search"
          onChange={({target}) => setSearch(target.value)}
        />
        <Dropdown
          className="dropdown-request-manage-tags"
          position="bottom right"
          trigger={
            <Button className="filter-btn" thin iconLeft="icon-filter">
              Filter
            </Button>
          }>
          <div className="tags-wrapper">
            <div className="header">
              <span className="title">Tags</span>
              <span
                className="manage-tags-btn"
                onClick={() => setShowModalTagManager(true)}>
                manage tags
              </span>
            </div>
            <Divider />
            <div className="tags-list">
              {tags?.length > 0 ? (
                tags?.map((t) => {
                  const isTagSelected = filteredTags
                    ?.map((t) => t.uid)
                    .includes(t.uid);

                  return (
                    <div
                      className={classnames('tag-option', {
                        selected: isTagSelected,
                      })}
                      onClick={() => {
                        if (isTagSelected) {
                          setFilteredTags(
                            filteredTags.filter((tag) => tag.uid !== t.uid)
                          );
                        } else {
                          setFilteredTags([...filteredTags, t]);
                        }
                      }}>
                      {t.name}
                    </div>
                  );
                })
              ) : (
                <div>No tags</div>
              )}
            </div>
          </div>
        </Dropdown>
      </div>
      <Divider />
      <div className="filtered-tags-row">
        {filteredTags.map((t) => {
          return (
            <div className="filter-tag">
              {t.name}
              <i
                className="icon-close"
                onClick={() => {
                  setFilteredTags(
                    filteredTags.filter((tag) => tag.uid !== t.uid)
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="scroll-wrapper">
        <div className="list-wrapper">
          <div className="count-row">
            {unreadCount > 0 ? (
              <div className="requests-title">
                {unreadCount} Unread requests
              </div>
            ) : totalCount > 0 ? (
              <div className="requests-title">{totalCount} requests</div>
            ) : (
              <div className="requests-title">No request found</div>
            )}
            {hasPermissions(PermissionsRequest.CREATE_EDIT) && (
              <Button
                className="add-request-btn"
                iconOnly
                iconLeft="icon-plus"
                muted
                onClick={() => setShowAddRequest(true)}
              />
            )}
          </div>
          <div className="list">
            {filterRequests.map((r) => (
              <Request request={r} key={r.uid} />
            ))}
          </div>
        </div>
      </div>
      <ModalTagManager
        isOpen={showModalTagManager}
        context={TAG_CONTEXT_REQUEST}
        onRequestClose={() => {
          setShowModalTagManager(false);
          refetch();
        }}
      />
      <ModalAddOrEditRequest
        isOpen={showAddRequest}
        onClose={() => {
          setShowAddRequest(false);
        }}
        onAddSuccess={() => {
          refetchRequests();
          setShowAddRequest(false);
        }}
        onRequestClose={() => setShowAddRequest(false)}
      />
    </div>
  );
};

const Request = ({request, held, ...rest}) => {
  const history = useHistory();
  const {requestId} = useParams();

  const classNames = classnames('request', {
    'is-unread': request.unread,
    selected: requestId === request.uid,
  });
  const isFromJimer = request.user != null ? false : true;
  const from = isFromJimer === false ? request.user : request.jimer;
  const externalJimerInfo =
    isFromJimer === true
      ? from?.externalEmail != null
        ? from?.externalEmail
        : from?.identifyToken || from?.externalUid
      : null;
  const username =
    hasUsernameGenerated(from?.username) && from?.externalUsername != null
      ? from?.externalUsername
      : from?.username;

  return (
    <Card
      className={classNames}
      {...rest}
      onClick={() => history.push(ROUTE_DRAFT_WITH_ID(request.uid))}>
      <Avatar jimer={from} />
      <div className="infos">
        <div className="title">{request.title}</div>
        <div className="content">{request.content}</div>
        <div className="from">
          from{' '}
          {from != null ? (
            <span>
              {username}{' '}
              {externalJimerInfo != null && <span>({externalJimerInfo})</span>}
            </span>
          ) : (
            'Deleted user'
          )}{' '}
        </div>
      </div>
    </Card>
  );
};

RequestList.propTypes = propTypes;
RequestList.defaultProps = defaultProps;

export default RequestList;
