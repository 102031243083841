import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import Tabs from 'components/Tabs';
import {toastDanger, toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  isActive,
  isPaused,
  isRateLimited,
} from 'services/evolution';
import {Swaler} from 'swaler';
import imgEmptyState from './../../img/empty-state.svg';
import './_Styles.scss';

const logger = new Swaler('ModalIgnoreRateLimiting');

const ModalIgnoreRateLimiting = ({isOpen, onRequestClose}) => {
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({
    search: '',
    type: 'ALL',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: evolutions,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: 'evolutionsWithRateLimiting',
    queryFn: () =>
      evolutionService.getEvolutions({
        where: [isRateLimited, isActive, isPaused],
        onlyPokes: true,
        relations: ['segments'],
      }),
    refetchOnWindowFocus: false,
    initialData: [],
  });

  useEffect(() => {
    if (isOpen) {
      setSelected([]);
      refetch();
    }
  }, [isOpen]);

  const handleSetSelected = (evolution, removeMode) => {
    if (removeMode === true) {
      setSelected(selected.filter((e) => e.uid !== evolution.uid));
    } else {
      setSelected(selected.concat(evolution));
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(false);
    try {
      await evolutionService.updateEvolutionsRateLimiting({
        evolutionIds: selected.map((e) => e.uid),
      });
      toastSuccess(
        ['Done!', 'Experiences added to excluded list successfully!'],
        {
          autoClose: 3000,
          toastId: 'changes-saved',
        }
      );
      onRequestClose();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        'Ignoring rate limiting for experiences failed with error ',
        code
      );
      return toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredEvolutions = evolutions.filter((e) => {
    const result = [];

    if (filter.search.length > 0) {
      result.push(e.title.includes(filter.search));
    }
    if (filter.type != null && filter.type !== 'ALL') {
      result.push(e.type === filter.type);
    }
    return result.every((e) => e === true);
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-ignore-rate-limiting">
      <div className="modal-custom-header">
        <div className="subtitle-1">Choose your in-app experience</div>
        <div className="header-actions">
          {selected.length === 0 ? (
            <Button iconOnly thin onClick={() => onRequestClose()}>
              <i className="icon-close"></i>
            </Button>
          ) : (
            <>
              <Button
                thin
                onClick={() => onRequestClose()}
                disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                primary
                thin
                onClick={handleConfirm}
                loading={isSubmitting}>
                Confirm
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="filter-wrapper">
        <Input
          iconLeft="icon-search"
          placeholder="Search"
          value={filter.search}
          onChange={({target}) => setFilter({...filter, search: target.value})}
        />
        <Tabs
          onTabChange={(type) => {
            setFilter({
              ...filter,
              type,
            });
          }}>
          <div label="All" labelId="ALL" />
          <div label="Tours" labelId={EVOLUTION_TYPE_TOUR} />
          <div label="Surveys" labelId={EVOLUTION_TYPE_SURVEY} />
          <div label="Banners" labelId={EVOLUTION_TYPE_BANNER} />
        </Tabs>
      </div>
      <div
        className={classnames('experiences-wrapper', {
          empty: isLoading === false && filteredEvolutions.length === 0,
        })}>
        {isLoading ? (
          <DefaultLoader />
        ) : (
          <>
            {filteredEvolutions.length > 0 ? (
              filteredEvolutions
                .sort(
                  (a, b) =>
                    new Date(b.lastStepChangeAt).getTime() -
                    new Date(a.lastStepChangeAt).getTime()
                )
                .map((e) => {
                  const isSelected =
                    selected.find((se) => se.uid === e.uid) != null;
                  const isLive =
                    e.isDraft !== true &&
                    (e.state === EVOLUTION_STATE_LIVE || e.state == null);
                  const isPaused =
                    e.isDraft !== true && e.state === EVOLUTION_STATE_PAUSED;
                  const isExpired =
                    e.isDraft !== true &&
                    e.expiresAt != null &&
                    dayjs(e.expiresAt).isBefore(dayjs());
                  const audience =
                    e.onTheFlySegment != null
                      ? 'On the fly segmentation'
                      : e.segments?.length > 0
                      ? e.segments.map((s) => s.name)
                      : 'All';

                  return (
                    <div
                      className={classnames('item', {
                        'is-selected': isSelected,
                      })}
                      onClick={() => handleSetSelected(e, isSelected)}>
                      <div className="left-wrapper">
                        {isExpired ? (
                          <div className="status-tag tag-expired body-3">
                            Expired
                          </div>
                        ) : isLive ? (
                          <div className="status-tag tag-live body-3">Live</div>
                        ) : isPaused ? (
                          <div className="status-tag tag-paused body-3">
                            Paused
                          </div>
                        ) : (
                          <div className="status-tag tag-draft body-3">
                            Draft
                          </div>
                        )}
                      </div>
                      <div className="center-wrapper">
                        <div className="body-4 n-500">
                          created {dayjs(e.lastStepChangeAt).fromNow()}
                        </div>
                        <div className="subtitle-3">{e.title}</div>
                      </div>
                      <div className="right-wrapper">
                        <div className="labels-wrapper">
                          <div className="excluded-label">
                            {e.type === EVOLUTION_TYPE_TOUR ? (
                              <>
                                <i className="isax isax-routing-2" />
                                Tour
                              </>
                            ) : e.type === EVOLUTION_TYPE_SURVEY ? (
                              <>
                                <i className="isax isax-note-2" />
                                Survey
                              </>
                            ) : e.type === EVOLUTION_TYPE_BANNER ? (
                              <>
                                <i className="icon-slot-top-bar" />
                                Banner
                              </>
                            ) : e.type === EVOLUTION_TYPE_HINT ? (
                              <>
                                <i className="icon-slot-dot" />
                                Hint
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {audience === 'All' && (
                            <div className="excluded-label body-3">
                              <i className="isax isax-profile-2user"></i>All
                              user
                            </div>
                          )}
                          {audience === 'On the fly segmentation' && (
                            <div className="excluded-label body-3">
                              <i className="isax isax-profile-2user"></i>On the
                              fly segmentation segment
                            </div>
                          )}
                          {Array.isArray(audience) &&
                            audience.map((s) => (
                              <div className="excluded-label body-3">
                                <i className="isax isax-profile-2user"></i>
                                {s}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="empty-list">
                <img src={imgEmptyState} alt="Empty state" />
                <div className="subtitle-2">No Active In-App Expriences</div>
                <div className="body-2 n-500">
                  No experiences are currently live or paused to be excluded
                  from display rate limiting.
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalIgnoreRateLimiting;
