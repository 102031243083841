import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import Tabs from 'components/Tabs';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useContext, useState} from 'react';
import {PushesContext} from 'scenes/Pushes/context';
import {VIEW_ALL_ID} from 'scenes/Pushes/with_context';
import {evolutionListViewService} from 'services';
import {Swaler} from 'swaler';
import {ModalListViewRename} from '../ModalListViewRename';
import './_Styles.scss';

const logger = new Swaler('Pushes/ListView');

export const ListView = () => {
  const {views, viewId, viewEvolutionCount, refetchViews, setViewId} =
    useContext(PushesContext);

  const [listViewToDelete, setListViewToDelete] = useState(null);
  const [listViewCreating, setListViewCreating] = useState(false);
  const [listViewDeleting, setListViewDeleting] = useState(false);
  const [listViewToRename, setListViewToRename] = useState(null);

  const handleCreateListView = async () => {
    setListViewCreating(true);
    try {
      const view = await evolutionListViewService.createListView({
        name: `View ${views.length + 1}`,
      });
      await refetchViews();
      setViewId(view.uid);
      setListViewCreating(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Creating evolution list view failed with error : ', code);
      toastDanger([title, message], {actions});
    }
  };

  const handleDeleteListView = async (viewId) => {
    setListViewDeleting(true);
    try {
      await evolutionListViewService.deleteListView(viewId);
      setViewId(VIEW_ALL_ID);
      await refetchViews();
      setListViewDeleting(false);
      setListViewToDelete(null);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting evolution list view failed with error : ', code);
      toastDanger([title, message], {actions});
    }
  };

  return (
    <div className="pushes-list-view">
      <Tabs
        defaultTab={viewId}
        onTabChange={(viewId) => {
          setViewId(viewId);
        }}>
        {[<div className="tab-all" label="All"></div>].concat(
          views
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map((view) => (
              <div
                labelId={view.uid}
                label={view.name}
                extraLabel={
                  viewId === view.uid ? (
                    <div className="pokes-count">
                      <span className="count">{viewEvolutionCount}</span>
                      <Dropdown
                        position="bottom right"
                        menuIsOpen={true}
                        triggerClassName="evolution-view-menu"
                        className="evolution-view-menu-dropdown"
                        trigger={<i className="icon-horizontal-menu"></i>}>
                        <Menu>
                          <MenuItem
                            icon="icon-edit-outline"
                            iconPosition="left"
                            onClick={() => setListViewToRename(view)}>
                            Rename
                          </MenuItem>
                          <MenuItem
                            icon="icon-trash"
                            iconPosition="left"
                            onClick={() => setListViewToDelete(view.uid)}>
                            Remove
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                    </div>
                  ) : undefined
                }
              />
            ))
        )}
      </Tabs>
      <Button
        className="btn-list-view-add"
        loading={listViewCreating === true}
        iconOnly
        thin
        onClick={handleCreateListView}>
        <i className="icon-plus"></i>
      </Button>
      <ModalConfirm
        isOpen={listViewToDelete != null}
        isConfirming={listViewDeleting === true}
        title="Are you sure to delete this view?"
        onConfirm={() => handleDeleteListView(listViewToDelete)}
        onCancel={() => setListViewToDelete(null)}></ModalConfirm>
      <ModalListViewRename
        view={listViewToRename}
        isOpen={listViewToRename != null}
        onRequestClose={() => setListViewToRename(null)}
      />
    </div>
  );
};
