import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoints
const EP_REQUEST_GET = '/request';
const EP_REQUEST_CREATE = '/request';
const EP_REQUEST_SET_UNREAD = (requestId) => `/request/${requestId}/unread`;
const EP_REQUEST_UPDATE = (requestId) => `/request/${requestId}`;
const EP_REQUEST_DELETE = (requestId) => `/request/${requestId}`;
const EP_REQUEST_GET_BY_ID = (requestId) => `/request/${requestId}`;

export const getRequests = (filter, opts = {projectId: null}) => {
  const projectId = opts.projectId || generalSelector.getProject().uid;

  return Axios.get(EP_REQUEST_GET, {params: {...filter, projectId}}).then(
    (response) => response.data
  );
};

export const getUnreadRequests = (filter, opts) => {
  return getRequests({...filter, isUnread: true}, opts);
};

export const createRequest = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {title, content} = data;

  return Axios.post(EP_REQUEST_CREATE, {projectId, title, content}).then(
    (response) => response.data
  );
};

export const setRequestUnread = (requestId, unread = false) => {
  return Axios.patch(EP_REQUEST_SET_UNREAD(requestId), {unread}).then(
    (response) => response.data
  );
};

export const updateRequest = (requestId, data) => {
  const {title, content, tags} = data;

  return Axios.put(EP_REQUEST_UPDATE(requestId), {
    title,
    content,
    tagsId: tags?.filter((t) => t).map((t) => t.uid),
  }).then((response) => response.data);
};

export const deleteRequest = (requestId) => {
  return Axios.delete(EP_REQUEST_DELETE(requestId)).then(
    (response) => response.data
  );
};

export const getRequestById = (requestId, opts = {}) => {
  return Axios.get(EP_REQUEST_GET_BY_ID(requestId), {params: {...opts}}).then(
    (response) => response.data
  );
};
