import {dataActions, generalActions, miscActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {
  ROUTE_GET_STARTED,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
} from 'router/routes.const';
import {authService, meService, projectService, rolesService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

export class Join extends React.Component {
  logger = new Swaler('Onboarding/Join');

  constructor() {
    super();
    this.state = {
      invitation: null,
      isLoading: true,
      isJoining: true,
    };
  }

  fetchInvitation = async (
    invitationCode = this.props.match.params.invitationCode
  ) => {
    try {
      const invitation = await projectService.getProjectMemberInvitation({
        invitationCode,
      });

      this.setState({invitation});
    } catch (err) {
      const {title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {actions});
      throw err;
    }
  };

  handleUseInvitation = async () => {
    const {setInvitationCode} = this.props;
    const {invitation} = this.state;

    if (invitation == null) {
      return;
    }
    this.setState({isJoining: true});
    try {
      const member = await projectService.useProjectMemberInvitation({
        invitationId: invitation.uid,
      });

      setInvitationCode(null);
      this.setProjectAndGoToOverview(member.project.uid);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      this.logger.error('Joining project failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  setProjectAndGoToOverview = async (projectId) => {
    const {setProject, setProjects, setCustomRoles, setBuiltInRoles} =
      this.props;
    const projects = await meService.getMyProjects();
    const project = projects.find((p) => p.uid === projectId);
    const customRoles = await rolesService.getCustomRoles(project);
    const builtInRoles = await rolesService.getBuiltInRoles();

    setProjects(projects);
    setCustomRoles(customRoles);
    setBuiltInRoles(builtInRoles);
    setProject(project.uid);
    this.props.history.push(ROUTE_GET_STARTED);
  };

  render() {
    const {isJoining, isLoading, invitation} = this.state;
    const classNames = classnames('s-join', {
      'is-joining': isJoining,
      'is-loading': isLoading,
      'has-invitation-not-found': invitation == null,
    });

    if (isLoading) {
      return (
        <div className={classNames}>
          <Loader></Loader>
        </div>
      );
    }
    if (invitation == null) {
      return (
        <div className={classNames}>
          <div className="row">
            Oups... We could not find your invitation. Either you already use it
            or it has been canceled!
            <br />
            <Link to={ROUTE_LOGIN}>
              <Button light rounded={false} iconRight="icon-chevron-right" thin>
                Go back
              </Button>
            </Link>
          </div>
        </div>
      );
    }
    if (isJoining) {
      return (
        <div className={classNames}>
          <Loader></Loader>
          <div className="text">
            Linking your account to project{' '}
            <span class="highlight">{invitation.project.name}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={classNames}>
        <Redirect
          to={{
            pathname: ROUTE_REGISTER,
            state: {
              fromJoin: true,
            },
          }}></Redirect>
      </div>
    );
  }

  async componentDidMount() {
    const {setInvitationCode} = this.props;

    try {
      await this.fetchInvitation();
      if (authService.isAuthenticated() === true) {
        this.setState({isLoading: false});
        await this.handleUseInvitation();
      } else {
        setInvitationCode(this.props.match.params.invitationCode);
        this.setState({isLoading: false, isJoining: false});
      }
    } catch (err) {
      this.logger.error('Fail to load component with error ', err);
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  setProject: (project) => dispatch(generalActions.setProject(project)),
  setProjects: (projects) => dispatch(generalActions.setProjects(projects)),
  setInvitationCode: (code) => dispatch(miscActions.setInvitationCode(code)),
  setCustomRoles: (roles) => dispatch(dataActions.setCustomRoles(roles)),
  setBuiltInRoles: (roles) => dispatch(dataActions.setBuiltInRoles(roles)),
});

export default connect(null, mapDispatchToProps)(Join);
