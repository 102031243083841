import {generalActions} from 'actions';
import Button from 'components/Button';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_SEGMENT} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {segmentioService} from 'services';
import {Swaler} from 'swaler';
import Logo from '../imgs/segment.svg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsSegment');

export const SegmentLogo = () => {
  return <img className="segment-logo" src={Logo} alt="segment-logo" />;
};

const Segment = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());
  const {segmentio} = project;
  const segmentActive = !!segmentio?.apiKey;

  const [isLoading, setIsLoading] = useState(false);

  const handleConnectSegment = async () => {
    setIsLoading(true);
    segmentioService
      .generateApiKey(project.uid)
      .then((segmentio) => {
        dispatch(generalActions.uptProject({segmentio}));
        history.push(ROUTE_SETTINGS_INTEGRATIONS_SEGMENT);
      })
      .catch((err) => {
        logger.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="settings-card integration-segment">
      <div className="logo-action-wrapper">
        <img className="logo segment-logo" src={Logo} alt="segment-logo" />
        {!segmentActive ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleConnectSegment}>
            Connect
          </Button>
        ) : (
          <div className="actions-btn-group">
            <Button
              thin
              iconLeft="isax isax-setting-2"
              onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_SEGMENT)}>
              Configure
            </Button>
          </div>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Segment{' '}
          {segmentActive === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Connected
            </div>
          )}
        </div>
        <div className="body-3">Use segment to enrich your end-users data</div>
      </div>
    </div>
  );
};

export default Segment;
