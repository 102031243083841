import classnames from 'classnames';
import commaNumber from 'comma-number';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {DropdownMau} from 'components/DropdownMau';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import {toastWarning} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {useFetchProration} from 'hooks/useFetchProration';
import {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {
  ADDON_WHITE_LABEL_ID,
  displayPrice,
  F_MODE_ANNUAL_BILLING,
  getCurrentAddons,
} from 'services/subscription';
import './_styles.scss';

export const ModalAddonCheckout = () => {
  const {modalAddonCheckout, setModalAddonCheckout} = useContext(GlobalContext);
  const {addonId, onCheckout} = modalAddonCheckout;

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [isWorking, setIsWorking] = useState(false);

  const {
    isFetching: isFetchingProration,
    fetch: fetchProration,
    proration,
  } = useFetchProration({
    onError: () => {
      setModalAddonCheckout(null);
    },
  });

  function handleRequestClose() {
    setModalAddonCheckout(null);
  }
  async function handleCheckout() {
    setIsWorking(true);
    await onCheckout({addonId});
    setIsWorking(false);
  }

  const modeAnnualBilling = hasFlag(
    F_MODE_ANNUAL_BILLING,
    subscription.extraFlags
  );
  const currentAddons = subscriptionService.getCurrentAddons();
  const newAddons = currentAddons.concat(addonId);
  const prorationLine =
    proration != null && proration.lines.find((line) => line.uid === addonId);

  useEffect(() => {
    if (currentAddons.includes(addonId)) {
      toastWarning([
        'Addon already enabled',
        'It seems that the addon you are trying to enable, is already enabled on your project.',
      ]);
      return handleRequestClose();
    }
    fetchProration({
      plan: subscription.plan,
      mau: subscription.extraJimers,
      modeAnnual: modeAnnualBilling,
      addons: newAddons,
    });
  }, []);

  return (
    <Modal isOpen={true} className="modal-addon-checkout">
      <div className="modal-header">
        <div className="title-4">
          {addonId === ADDON_WHITE_LABEL_ID && 'Hide Jimo Label'}
        </div>
        <Button iconOnly onClick={handleRequestClose}>
          <i className="icon-close"></i>
        </Button>
      </div>
      <div className="modal-content">
        <div className="left-side">
          <div className="subscription-info">
            {/* Payment Information */}
            <div className="payment-information">
              <div className="body-3 n-500">Payment information</div>
              <div className="body-2">
                <span className="card-brand">
                  {subscription.paymentMethodBrand}
                </span>{' '}
                ending in {subscription.paymentMethodLast4}
              </div>
            </div>
            {/* Billing Information (disabled for now, require to fetch data from Stripe) */}
            {/* <div className="billing-information">
              <div className="body-3 n-500">Billing information</div>
              <div className="body-2">
                {subscription.paymentMethodName}, France, 44000
              </div>
            </div> */}
            {/* Current plan */}
            <div className="current-plan">
              <div className="body-3 n-500">Current plan</div>
              <div className="body-2">
                <span className="plan-name">
                  {subscription.plan.toLowerCase()} Plan
                </span>
                ,{' '}
                {modeAnnualBilling === true
                  ? 'billed yearly'
                  : 'billed monthly'}
              </div>
            </div>
          </div>
          <Alert success icon="isax isax-tick-circle5" title="Effective today">
            Add-on will be active immediately after checkout
          </Alert>
        </div>
        <div className="right-side">
          {/* Proration Resume */}
          <div
            className={classnames('proration-resume', {
              loading: proration == null,
            })}>
            {proration == null ? (
              <DefaultLoader width="12px" />
            ) : (
              <>
                <div className="item-line">
                  <div className="title-wrapper">
                    <div className="body-2">
                      {addonId === ADDON_WHITE_LABEL_ID && 'Hide Jimo Label'}
                    </div>
                    {prorationLine != null && (
                      <div className="body-3 n-500">
                        From{' '}
                        {dayjs(prorationLine.periodStart * 1000).format(
                          modeAnnualBilling === true ? 'DD MMM, YY' : 'DD MMM'
                        )}{' '}
                        to{' '}
                        {dayjs(prorationLine.periodEnd * 1000).format(
                          modeAnnualBilling === true ? 'DD MMM, YY' : 'DD MMM'
                        )}
                      </div>
                    )}
                    {proration.discount != null && (
                      <div className="body-3 g-500">
                        Discount applied (
                        {proration.discount.coupon.percent_off}%)
                      </div>
                    )}
                  </div>
                  <div className="subtitle-3">
                    {displayPrice(Math.round(prorationLine.amount / 100))}
                    {modeAnnualBilling === true ? ' / year' : ' / month'}
                  </div>
                </div>
                {proration != null && proration.tax > 0 && (
                  <>
                    <div className="item-line line-subtotal">
                      <div className="body-3">Subtotal</div>
                      <div className="body-3">
                        {displayPrice(Math.round(proration.subtotal / 100))}
                      </div>
                    </div>
                    <div className="item-line line-tax">
                      <div className="body-3">Tax</div>
                      <div className="body-3">
                        {displayPrice(Math.round(proration.tax / 100))}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {/* Total */}
          <div className="total-wrapper">
            <div className="subtitle-3">Total for today</div>
            <div className="subtitle-2">
              {proration == null ? (
                '-'
              ) : (
                <>{displayPrice(Math.round(proration.total / 100))}</>
              )}
            </div>
          </div>
          {/* Proration description */}
          <div className="proration-description body-4 n-500">
            Total price is adjusted based on your current billing cycle so, you
            only pay for this addon from now until your next billing day.
          </div>
          {/* Checkout button */}
          <Button
            className="btn-addon-checkout"
            primary
            loading={isWorking}
            onClick={handleCheckout}
            disabled={isFetchingProration}>
            Confirm and pay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ModalMauAddonCheckout = () => {
  const {modalAddonCheckout, setModalAddonCheckout} = useContext(GlobalContext);
  const {addonId, onCheckout} = modalAddonCheckout;

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [mau, setMau] = useState(subscription.extraJimers);
  const [isWorking, setIsWorking] = useState(false);

  const {
    isFetching: isFetchingProration,
    fetch: fetchProration,
    proration,
  } = useFetchProration({
    onError: () => {
      setModalAddonCheckout(null);
    },
  });

  function handleRequestClose() {
    setModalAddonCheckout(null);
  }
  async function handleCheckout() {
    setIsWorking(true);
    await onCheckout({addonId, mau});
    setIsWorking(false);
  }

  const modeAnnualBilling = hasFlag(
    F_MODE_ANNUAL_BILLING,
    subscription.extraFlags
  );
  const prorationLineCurrentMau =
    proration != null &&
    proration.lines.find(
      (line) =>
        line.uid === addonId && line.quantity === subscription.extraJimers
    );
  const prorationLineNextMau =
    proration != null &&
    proration.lines.find(
      (line) => line.uid === addonId && line.quantity === mau
    );

  useEffect(() => {
    if (mau !== subscription.extraJimers) {
      fetchProration({
        plan: subscription.plan,
        mau,
        modeAnnual: modeAnnualBilling,
        addons: getCurrentAddons(),
      });
    }
  }, [mau]);

  return (
    <Modal isOpen={true} className="modal-addon-checkout">
      <div className="modal-header">
        <div className="title-4">Change MAU limits</div>
        <Button iconOnly onClick={handleRequestClose}>
          <i className="icon-close"></i>
        </Button>
      </div>
      <div className="modal-content">
        <div className="left-side">
          {/* Mau selector */}
          <DropdownMau
            asSelector
            mau={mau}
            onSelect={(mau) => {
              setMau(mau);
            }}
          />
          <div className="subscription-info">
            {/* Payment Information */}
            <div className="payment-information">
              <div className="body-3 n-500">Payment information</div>
              <div className="body-2">
                <span className="card-brand">
                  {subscription.paymentMethodBrand}
                </span>{' '}
                ending in {subscription.paymentMethodLast4}
              </div>
            </div>
            {/* Billing Information (disabled for now, require to fetch data from Stripe) */}
            {/* <div className="billing-information">
              <div className="body-3 n-500">Billing information</div>
              <div className="body-2">
                {subscription.paymentMethodName}, France, 44000
              </div>
            </div> */}
            {/* Current plan */}
            <div className="current-plan">
              <div className="body-3 n-500">Current plan</div>
              <div className="body-2">
                <span className="plan-name">
                  {subscription.plan.toLowerCase()} Plan
                </span>
                ,{' '}
                {modeAnnualBilling === true
                  ? 'billed yearly'
                  : 'billed monthly'}
              </div>
            </div>
          </div>
        </div>
        <div className="right-side">
          {/* Proration Resume */}
          <div
            className={classnames('proration-resume', {
              loading: proration == null || isFetchingProration === true,
              centered: mau === subscription.extraJimers,
            })}>
            {mau === subscription.extraJimers ? (
              <p className="body-3 n-500">
                Choose a different MAU limit from the list
              </p>
            ) : proration == null || isFetchingProration === true ? (
              <DefaultLoader width="12px" />
            ) : (
              <>
                {prorationLineNextMau != null && (
                  <div className="item-line">
                    <div className="title-wrapper">
                      <div className="body-2">
                        {commaNumber(prorationLineNextMau.quantity)} MAU
                      </div>
                      <div className="body-3 n-500">
                        From{' '}
                        {dayjs(prorationLineNextMau.periodStart * 1000).format(
                          modeAnnualBilling === true ? 'DD MMM, YY' : 'DD MMM'
                        )}{' '}
                        to{' '}
                        {dayjs(prorationLineNextMau.periodEnd * 1000).format(
                          modeAnnualBilling === true ? 'DD MMM, YY' : 'DD MMM'
                        )}
                      </div>
                    </div>
                    <div className="subtitle-3">
                      {proration.isUpgrade === true && (
                        <>
                          {displayPrice(
                            Math.round(prorationLineNextMau.amount / 100)
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {prorationLineCurrentMau != null && (
                  <div className="item-line">
                    <div className="title-wrapper">
                      <div className="body-2">
                        Unused {commaNumber(prorationLineCurrentMau.quantity)}{' '}
                        MAU
                      </div>
                      <div className="body-3 n-500">
                        From{' '}
                        {dayjs(
                          prorationLineCurrentMau.periodStart * 1000
                        ).format(
                          modeAnnualBilling === true ? 'DD MMM, YY' : 'DD MMM'
                        )}{' '}
                        to{' '}
                        {dayjs(prorationLineCurrentMau.periodEnd * 1000).format(
                          modeAnnualBilling === true ? 'DD MMM, YY' : 'DD MMM'
                        )}
                      </div>
                    </div>
                    <div className="subtitle-3">
                      {proration.isUpgrade === true && (
                        <>
                          {displayPrice(
                            Math.round(prorationLineCurrentMau.amount / 100)
                          )}
                          {modeAnnualBilling === true ? ' / year' : ' / month'}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {proration != null &&
                  proration.tax > 0 &&
                  proration.isUpgrade === true && (
                    <>
                      <div className="item-line line-subtotal">
                        <div className="body-3">Subtotal</div>
                        <div className="body-3">
                          {displayPrice(Math.round(proration.subtotal / 100))}
                        </div>
                      </div>
                      <div className="item-line line-tax">
                        <div className="body-3">Tax</div>
                        <div className="body-3">
                          {displayPrice(Math.round(proration.tax / 100))}
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}
          </div>

          {/* Total */}
          <div className="total-wrapper">
            <div className="subtitle-3">Total for today</div>
            <div className="subtitle-2">
              {proration == null || proration.isUpgrade === false ? (
                '-'
              ) : (
                <>{displayPrice(Math.round(proration.total / 100))}</>
              )}
            </div>
          </div>
          {/* <div className="body-4 n-500">
            Then {displayPrice(getMauPrice(mau, modeAnnualBilling))} every{' '}
            {modeAnnualBilling === true ? 'year' : 'month'}
          </div> */}
          {/* Proration description */}
          <div className="proration-description body-4 n-500">
            Total price is adjusted based on your checkout date, covering the
            period from today until the next billing cycle, so you only pay for
            the time used for this checkout.
          </div>
          {/* Checkout button */}
          <Button
            className="btn-addon-checkout"
            primary
            loading={isWorking || isFetchingProration}
            onClick={handleCheckout}
            disabled={isFetchingProration || subscription.extraJimers === mau}>
            {proration != null && proration.isUpgrade === false
              ? 'Confirm and downgrade'
              : 'Confirm and pay'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
