import SelectGroup from 'components/Select';
import {fontFamiliesOptions} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Title/utils';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';

const ChangelogTextStyle = ({style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <div className="section-item">
        <div className="section-item-title">Title color</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Text color"
            value={style.titleFontColor}
            onChange={(value) =>
              updateStyle({
                ...style,
                titleFontColor: value,
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Title font family</div>
        <div className="section-item-content">
          <SelectGroup
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.titleFontFamily
            )}
            onChange={(option) =>
              updateStyle({
                ...style,
                titleFontFamily: option.value,
              })
            }
            menuPortalTarget={document.body}
          />
        </div>
      </div>

      <div className="section-item">
        <div className="section-item-title">Body color</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Text color"
            value={style.bodyFontColor}
            onChange={(value) =>
              updateStyle({
                ...style,
                bodyFontColor: value,
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Body font family</div>
        <div className="section-item-content">
          <SelectGroup
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.bodyFontFamily
            )}
            onChange={(option) =>
              updateStyle({
                ...style,
                bodyFontFamily: option.value,
              })
            }
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </>
  );
};

export default ChangelogTextStyle;
