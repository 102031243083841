import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_SEGMENTIO_GET_GENERATE_URL = '/segmentio/generate-url';
const EP_SEGMENTIO_GET_GENERATE_API_KEY = '/segmentio/generate-api-key';
const EP_SEGMENTIO_POST_EXCHANGE_CODE = '/segmentio/exchange-code';
const EP_SEGMENTIO_DELETE = (segmentId) => `/segmentio/${segmentId}`;
const EP_SEGMENTIO_GET_FIELDS = `/segmentio/event/fields`;
const EP_SEGMENTIO_GET_FIELD_VALUES = `/segmentio/event/field-values`;
const EP_SEGMENTIO_GET_EVENTS = `/segmentio/event/events`;

// Method
export const generateUrl = (projectId) => {
  return Axios.get(EP_SEGMENTIO_GET_GENERATE_URL, {
    params: {projectId},
  }).then((response) => response.data);
};

export const generateApiKey = (projectId) => {
  return Axios.get(EP_SEGMENTIO_GET_GENERATE_API_KEY, {
    params: {projectId},
  }).then((response) => response.data);
};

export const exchangeCodeForToken = (projectId, {code, state}) => {
  return Axios.post(
    EP_SEGMENTIO_POST_EXCHANGE_CODE,
    {
      code,
      state,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};

export const deleteSegment = (segmentId) =>
  Axios.delete(EP_SEGMENTIO_DELETE(segmentId)).then(
    (response) => response.data
  );

export const getSegmentioFields = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_SEGMENTIO_GET_FIELDS, {params: {projectId}}).then(
    (response) => response.data
  );
};

export const getSegmentioFieldValues = (
  fieldName,
  data,
  projectId = generalSelector.getProject().uid
) => {
  const {skip, take, search} = data;

  return Axios.get(EP_SEGMENTIO_GET_FIELD_VALUES, {
    params: {fieldName, projectId, skip, take, search},
  }).then((response) => response.data);
};

export const getSegmentioEvents = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_SEGMENTIO_GET_EVENTS, {params: {projectId}}).then(
    (response) => response.data
  );
};
