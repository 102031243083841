import Button from 'components/Button';
import {toastSuccess, toastWarning} from 'components/Toaster';
import {crispHelpers} from 'helpers';
import {useIsExtensionInstalled} from 'helpers/utils';
import {useCheckInstallation} from 'hooks/useCheckInstallation';
import Lottie from 'lottie-react';
import {func, string} from 'prop-types';
import React, {useEffect} from 'react';
import {
  Install,
  MODE_INSTALL,
  MODE_TRY,
} from 'scenes/Onboarding/scenes/Details/step-3-1';
import confettiAnimation from '../../../../../Onboarding/scenes/Details/animations/confetti.json';
import './_Styles.scss';

const propTypes = {
  mode: string,
  setMode: func,
  onExtensionInstalledContinue: func,
};

const defaultProps = {
  mode: null,
  setMode: () => {},
  onExtensionInstalledContinue: () => {},
  onSkip: () => {},
};

const InstallJimo = ({mode, setMode, onExtensionInstalledContinue, onSkip}) => {
  const {check} = useCheckInstallation({
    onInstalled: () => {
      toastSuccess(
        ['Project installed!', 'We have detected the snippet on your website!'],
        {toastId: 'project-installed'}
      );
      onSkip();
    },
    onNotInstalled: () => {
      toastWarning(
        [
          'Installation not detected yet',
          "We didn't detect your installation yet. Please try to access your website again and make sure Jimo is loaded. If the issue persist, let's chat!",
        ],
        {
          actions: [
            {
              text: 'Chat with us',
              props: {
                onClick: () =>
                  crispHelpers.startCrispThread(
                    `I'm having issues trying to install Jimo. Can you help me ? :-)`
                  ),
                iconLeft: 'icon-chat',
              },
            },
          ],
        }
      );
    },
  });

  const {isInstalled, check: checkExtension} = useIsExtensionInstalled();

  useEffect(() => {
    window.addEventListener('focus', checkExtension);
    return () => {
      window.removeEventListener('focus', checkExtension);
    };
  }, []);

  return (
    <div className="install-jimo">
      {mode == null && (
        <div className="choose-mode">
          <div className="mode-text">
            To go to the next step, please install Jimo on your app or our
            Chrome extension to quickly test and build in-product pokes.
          </div>
          <div className="list-modes">
            <div
              className="item-mode"
              onClick={() => {
                setMode(MODE_INSTALL);
              }}>
              <i className="icon-code"></i>
              <h3>Install Jimo</h3>
              <p>Install and deploy a javascript snippet in your product.</p>
              <Button cta secondary iconRight="icon-chevron-right">
                Install
              </Button>
            </div>
            <div
              className="item-mode"
              onClick={() => {
                setMode(MODE_TRY);
              }}>
              <i className="icon-brand-chrome"></i>
              <h3>Quick testing</h3>
              <p>Quickly try Jimo using our Chrome extension.</p>
              <Button cta secondary iconRight="icon-chevron-right">
                Try
              </Button>
            </div>
          </div>
          <div className="actions">
            <Button primary onClick={onSkip}>
              Skip for now
            </Button>
          </div>
        </div>
      )}
      {mode === MODE_INSTALL && (
        <>
          <div className="mode-text">
            To go to the next step, please install Jimo.
          </div>
          <Install />
          <div className="actions">
            <Button cta primary onClick={check}>
              Check installation
            </Button>
          </div>
        </>
      )}
      {mode === MODE_TRY && (
        <div className="install-mode-extension">
          {isInstalled === false ? (
            <div className="mode-text">
              To go to the next step, please install Jimo Chrome extension.
            </div>
          ) : (
            <>
              <h1>Well done! Everything is ready.</h1>
              <p>
                Quick reminder : You won't be able to display anything to your
                users until Jimo is fully installed.
              </p>
            </>
          )}
          {isInstalled === true ? (
            <>
              <div className="extension-installed">
                <div className="confetti-wrapper">
                  <Lottie
                    animationData={confettiAnimation}
                    loop={false}></Lottie>
                </div>
                <i className="icon-tick"></i> Chrome extension installed
              </div>
              <p>
                You can now build and test Jimo before installing it in your
                app.
              </p>
              <Button
                cta
                primary
                className="continue-btn"
                iconRight="icon-chevron-right"
                onClick={onExtensionInstalledContinue}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <a
                href="https://chrome.google.com/webstore/detail/jimo/koipflmbgiibbkcfccgpocdgifapofje?hl=en&authuser=0"
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  className="btn-install-extension"
                  iconLeft="icon-brand-chrome">
                  Install our Chrome extension
                </Button>
              </a>
              <div className="install-subtext">
                You won't be able to display anything to your users until Jimo
                is fully installed.
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

InstallJimo.propTypes = propTypes;
InstallJimo.defaultProps = defaultProps;

export default InstallJimo;
