import React, {useMemo} from 'react';
import {useQuery} from 'react-query';
import dayjs from 'dayjs';
import {Swaler} from 'swaler';
import {generateDatesArray} from '../..';
import {object, string} from 'prop-types';
import './_Styles.scss';
import BarChart, {BarChartType} from 'components/Charts/BarChart';
import {evolutionService} from 'services';

const propTypes = {
  evolution: object.isRequired,
  selectedSegment: string,
};

const defaultProps = {
  selectedSegment: null,
};

const logger = new Swaler('LikesChart');

const LikesChart = ({evolution, timeRange}) => {
  const [startDate, endDate] = timeRange;

  const {data: analytics = []} = useQuery({
    queryKey: [evolution.uid, 'analytics', 'votes'],
    queryFn: () => evolutionService.getEvolutionLikesWithJimers(evolution.uid),
    refetchOnWindowFocus: false,
    onError: (err) => {
      logger.error(
        'Could not fetch votes jimers, failed with err',
        err.message
      );
    },
  });

  const datesArr = generateDatesArray(startDate, endDate).filter((d) =>
    dayjs(d).isBefore(dayjs().endOf('day'))
  );

  // n * log(n)
  const data = useMemo(() => {
    const freqMap = analytics?.reduce((acc, item) => {
      const formattedDate = dayjs(item.createdAt).format('YYYY-MM-DD');
      if (!acc[formattedDate]) {
        acc[formattedDate] = 0;
      }
      acc[formattedDate]++;
      return acc;
    }, {});

    return datesArr.reduce((acc, cur) => {
      const date = cur.format('YYYY-MM-DD');
      const timestamp = cur.unix();

      acc.push({
        date,
        timestamp,
        value: date in freqMap ? freqMap[date] : 0,
        evolutionId: evolution.uid,
      });

      return acc;
    }, []);
  }, [datesArr, evolution, analytics]);

  return (
    <div className="likes-chart">
      <BarChart data={data} type={BarChartType.LIKE} evolution={evolution} />
    </div>
  );
};

LikesChart.propTypes = propTypes;
LikesChart.defaultProps = defaultProps;

export default LikesChart;
