import {default as classnames, default as classNames} from 'classnames';
import Button from 'components/Button';
import {crispHelpers} from 'helpers';
import {addFlag, hasFlag} from 'helpers/bitwise';
import {useEffect, useState} from 'react';
import {PopupModal, useCalendlyEventListener} from 'react-calendly';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {F_EXTRA_ONBOARDING_BOOKED} from 'services/project';
import Founders from './imgs/founders.png';
import JimoLogo from './imgs/jimo-logo.svg';
import GMeet from './imgs/meet.png';
import Founder from './imgs/thomas.png';
import './step-3-0.scss';

const calendlyUrl = 'https://calendly.com/thomas-747/30min';

export const OnboardingDetailsStep30 = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const hasBookedOnboarding = hasFlag(
    F_EXTRA_ONBOARDING_BOOKED,
    project.extraFlags
  );

  const [crispChatOpen, setCrispChatOpen] = useState(false);
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const [hasScheduledOnboarding, setHasScheduledOnboarding] =
    useState(hasBookedOnboarding);

  const handleJoinSessionNow = () => {
    crispHelpers.startCrispThread(`I'm ready to unlock my platform access.`);
  };

  const onCrispChatOpened = () => {
    setCrispChatOpen(true);
    window.$crisp.push([
      'set',
      'message:text',
      [].concat(`I'm ready to unlock my platform access.`),
    ]);
  };

  const onCrispChatClosed = () => {
    setCrispChatOpen(false);
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      setHasScheduledOnboarding(true);
      await projectService.updateProject(project.uid, {
        extraFlags: addFlag(F_EXTRA_ONBOARDING_BOOKED, project.extraFlags),
      });
    },
  });

  useEffect(() => {
    if (window.$crisp != null) {
      window.$crisp.push(['on', 'chat:opened', onCrispChatOpened]);
      window.$crisp.push(['on', 'chat:closed', onCrispChatClosed]);

      if (hasBookedOnboarding !== true) {
        window.$crisp.push(['do', 'chat:hide']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={classnames(
          's-onboarding-details-step s-onboarding-details-step-3-0'
        )}>
        {hasScheduledOnboarding === true ? (
          <div className="onboarding-booking-wrapper">
            <i className="calendar-icon isax isax-calendar-tick" />
            <div className="onboarding-booking-content">
              <h1 className="title">
                Onboarding confirmed!
                <br />
                See you at the next step
              </h1>
              <div className="subtitle body-1 n-600">
                We’re excited to guide you through your free trial! 🙌
              </div>
            </div>
            <div
              className="need-help subtitle-3 b-400"
              onClick={() =>
                crispHelpers.startCrispThread('I need help with my onboarding.')
              }>
              Need help? We're here!
            </div>
          </div>
        ) : (
          <div className="onboarding-booking-wrapper">
            <img className="jimo-logo" src={JimoLogo} alt="Jimo" />
            <div className="onboarding-booking-content">
              <h1 className="title">Book your personalized onboarding</h1>
              <div className="subtitle body-1 n-600">
                During the call, we'll personalize your experience and help you
                get set up with Jimo.
              </div>
            </div>
            <Button
              className="book-onboarding-btn"
              primary
              thin
              onClick={() => setShowCalendlyModal(true)}>
              Book onboarding
            </Button>
          </div>
        )}
      </div>
      <div
        className={classNames('join-session-now-wrapper', {
          'is-hidden': crispChatOpen || hasScheduledOnboarding,
        })}>
        <div className="join-session-now-card" onClick={handleJoinSessionNow}>
          <div className="join-session-now-header">
            <img className="meet" src={GMeet} alt="Google Meet" />
            <img className="founders" src={Founders} alt="Founders" />
          </div>
          <div className="body-2 n-800">
            Ready to unlock your platform access?
          </div>
          <div className="join-now-cta subtitle-4 b-400">
            Join session now
            <i className="icon-chevron-right" />
          </div>
        </div>
        <div className="img-wrapper">
          <img src={Founder} alt="Founder" />
        </div>
      </div>
      <PopupModal
        url={calendlyUrl}
        onModalClose={() => setShowCalendlyModal(false)}
        open={showCalendlyModal}
        rootElement={document.getElementById('root')}
      />
    </>
  );
};
