import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import {useStartTrial} from 'hooks/useStartTrial';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import planDetails from 'scenes/Settings/scenes/Plan/plan-details';
import {dataSelector} from 'selectors';
import {isEligibleToTrial} from 'services/project';
import {
  hasAnnualBilling,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import './_styles.scss';

export const ModalPlanPreview = () => {
  const {modalPlanPreview, setModalPlanPreview} = useContext(GlobalContext);

  const {start, isStarting} = useStartTrial({
    onSuccess: () => {
      handleRequestClose();
    },
  });
  const {update} = useUpdateSubscription();

  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );

  const {
    planId,
    mau,
    title,
    description,
    onClose = () => {},
  } = modalPlanPreview ?? {};

  const handleRequestClose = () => {
    setModalPlanPreview(null);
    onClose();
  };
  const handleSubmit = () => {
    if (canTry === true) {
      start({planId, mau});
    } else {
      update({
        planId,
        mau,
        modeAnnualBilling: hasAnnualBilling(),
        skipPreviewMode: true,
      });
      setTimeout(() => {
        handleRequestClose();
      }, 1000);
    }
  };

  const plan = plans.find((plan) => plan.uid === planId);
  const canTry = isEligibleToTrial(planId);

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleRequestClose}
      className={classnames(
        'modal-plan-preview',
        `plan-${planId.toLowerCase()}`
      )}>
      <Button
        className="btn-plan-preview-close"
        iconOnly
        thin
        onClick={handleRequestClose}>
        <i className="icon-close"></i>
      </Button>
      <div className="animation-wrapper">
        <iframe
          title={`animation-${plan.uid}`}
          src={
            plan.uid === PLAN_STARTUP_ID
              ? 'https://my.spline.design/untitledcopycopycopy-45b9f8232163067f6395e1b9e8e1a71c/'
              : plan.uid === PLAN_GROWTH_ID
              ? 'https://my.spline.design/untitledcopycopy-d37c17e6be965d0185debabd9badb6f9/'
              : 'https://my.spline.design/untitled-c8d25fa74e83ca49b2def591dd79a328/'
          }
          frameborder="0"></iframe>
      </div>
      <div className="modal-main">
        <div className={classnames('plan-title title-4')}>
          {title ?? `Upgrde to ${planId.toLowerCase()}`}
        </div>
        {description != null && (
          <div className="plan-description body-2 n-700">{description}</div>
        )}

        <div className="plan-features">
          {[
            ...(planId === PLAN_GROWTH_ID
              ? [
                  {
                    uid: 'include-previous-plan',
                    title: `Everything in Startup Plan`,
                  },
                ]
              : planId === PLAN_SCALE_ID
              ? [
                  {
                    uid: 'include-previous-plan',
                    title: `Everything in Growth Plan`,
                  },
                ]
              : []),
            {uid: 'seats', title: `${plan.seats} seats`},
            {
              uid: 'mau',
              title: `Up to ${commaNumber(mau)} Monthly Active Users`,
            },
          ]
            .concat(planDetails[planId])
            .map((feature) => {
              return (
                <div key={feature.uid} className="item-feature">
                  <i className="isax isax-tick-circle5"></i>
                  <div className="item-body">
                    <div className="body-3">{feature.title}</div>
                    {feature.description != null && (
                      <div className="body-3 n-500">{feature.description}</div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="modal-footer">
        <Button primary onClick={handleSubmit} loading={isStarting}>
          {canTry === true ? 'Start 14 days free trial' : 'Upgrade'}
        </Button>
        {canTry === true && (
          <div className="label-no-credit-card body-3 n-700">
            <i className="isax isax-tick-circle5"></i> No credit card required
          </div>
        )}
      </div>
    </Modal>
  );
};
