import Input from 'components/Input';
import {errorHelpers} from 'helpers';
import React, {useEffect, useRef, useState} from 'react';
import {segmentioService} from 'services';
import {Swaler} from 'swaler';
import SelectAttribute from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [OPTIONS.OP_IS, OPTIONS.OP_NIS];

const VALUES_PER_PAGE = 20;

const logger = new Swaler('AttributeSegmentioField');

export const AttributeSegmentioField = ({
  attribute,
  onDelete,
  onChangeType,
  onUpdateOperator,
  onUpdateValue,
  onAddValue,
  onDeleteValue,
  segmentioFields,
  segmentioEvents,
  trackedEvents,
}) => {
  const {name} = attribute;

  const [inputFocused, setInputFocused] = useState(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [values, setValues] = useState({
    data: [],
    skip: 0,
    take: VALUES_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isBoolean, setIsBoolean] = useState(true);

  const debounceTimeout = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (name == null) {
      return;
    }
    setSearch('');
    fetchValues('', 0, true);
  }, [name]);

  const fetchValues = async (search = '', pageParam = page, reset = false) => {
    const oldPage = page;

    setPage(pageParam);
    setIsLoading(true);
    try {
      const res = await segmentioService.getSegmentioFieldValues(name, {
        take: VALUES_PER_PAGE,
        skip: pageParam * VALUES_PER_PAGE,
        search,
      });

      const allValues = {
        ...res,
        ...(reset === false ? {data: [...values.data, ...res.data]} : {}),
      };
      if (
        allValues.data.length > 0 &&
        allValues.data.every((value) => typeof value === 'boolean')
      ) {
        setIsBoolean(true);
      } else {
        setIsBoolean(false);
      }
      setValues(allValues);
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      setIsLoading(false);
      logger.error('Fetching values failed with error ', code);
    }
  };

  const handleValueChange = (index, value) => {
    if (isBoolean === true) {
      return;
    }
    onUpdateValue(index, value);
    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(() => {
      setSearch(value);
      fetchValues(value, 0, true);
    }, 500);
  };

  let type = null;
  for (const d of values.data) {
    if (type === null) {
      type = typeof d;
    } else {
      if (type != typeof d) {
        type = 'mixed';
        break;
      }
    }
  }

  return (
    <div className="segment-attribute attribute-custom">
      <div className="header-row">
        <SelectAttribute
          value={`${attribute.type}-${attribute.name}`}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => onUpdateOperator(op)}
        />
        {attribute.values?.map((v, index) => {
          const suggestions =
            isBoolean === true
              ? [true, false]
              : values?.data?.filter((d) => {
                  if (type === 'string') {
                    return d?.toLowerCase().includes(v?.toLowerCase());
                  } else if (type === 'number') {
                    return d?.toString().includes(v?.toString());
                  } else if (type === 'boolean') {
                    return d?.toString().includes(v?.toString());
                  }
                  return true;
                });

          return (
            <div
              className="input-value-wrapper"
              ref={(r) => setDropdownWidth(r?.offsetWidth)}>
              <Input
                ref={inputRef}
                key={index}
                placeholder={
                  isBoolean === true ? 'select value' : 'enter value...'
                }
                value={v}
                labelTextRight={
                  attribute.values.length > 1 ? (
                    <i
                      className="icon-close"
                      onClick={() => onDeleteValue(index)}></i>
                  ) : null
                }
                onChange={({target}) => handleValueChange(index, target.value)}
                onFocus={() => setInputFocused(index)}
                disabled
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
