import {Menu, MenuItem} from 'components/Menu';
import React from 'react';
import './_Styles.scss';

export default function DevMenu() {
  return (
    <div className="_dev-menu">
      <div className="wrapper">
        <Menu>
          <MenuItem>Test</MenuItem>
          <MenuItem icon="icon-overview">Overview</MenuItem>
          <MenuItem badge={1}>Live for vote</MenuItem>
          <MenuItem>Feature preview</MenuItem>
          <MenuItem>Announcement</MenuItem>
          <MenuItem icon="icon-chevron-right" iconPosition="right">
            Sign out
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
