import Input from 'components/Input';
import {errorHelpers} from 'helpers';
import React, {useEffect, useRef, useState} from 'react';
import {customAttributeService} from 'services';
import {Swaler} from 'swaler';
import SelectAttribute, {ATTRIBUTES} from '../select-attribute';
import {SelectCustomType} from '../select-custom-type';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [
  OPTIONS.OP_IS,
  OPTIONS.OP_NIS,
  OPTIONS.OP_STARTS_WITH,
  OPTIONS.OP_CONTAINS,
];

const VALUES_PER_PAGE = 20;

const logger = new Swaler('AttributeCustom');

export const AttributeCustom = (props) => {
  const {
    attribute,
    onDelete,
    onChangeType,
    onUpdate,
    onUpdateOperator,
    onUpdateValue,
    onAddValue,
    onDeleteValue,
    segmentioFields,
    segmentioEvents,
    trackedEvents,
  } = props;

  const [inputFocused, setInputFocused] = useState(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [values, setValues] = useState({
    data: [],
    skip: 0,
    take: VALUES_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const debounceTimeout = useRef();

  const customTypeValue =
    attribute.customType != null
      ? attribute.customType
      : attribute.customAttribute != null
      ? attribute.customAttribute.uid
      : null;

  useEffect(() => {
    if (customTypeValue == null) {
      return;
    }
    setSearch('');
    fetchValues('', 0, true);
  }, [customTypeValue]);

  const fetchValues = async (search = '', pageParam = page, reset = false) => {
    const oldPage = page;

    setPage(pageParam);
    setIsLoading(true);
    try {
      const res = await customAttributeService.getCustomAttributeValues(
        customTypeValue,
        {
          take: VALUES_PER_PAGE,
          skip: pageParam * VALUES_PER_PAGE,
          search,
        }
      );

      setValues({
        ...res,
        ...(reset === false ? {data: [...values.data, ...res.data]} : {}),
      });
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      setIsLoading(false);
      logger.error('Fetching values failed with error ', code);
    }
  };

  const handleValueChange = (index, value) => {
    onUpdateValue(index, value);
    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(() => {
      setSearch(value);
      fetchValues(value, 0, true);
    }, 500);
  };

  return (
    <div className="segment-attribute attribute-custom">
      <div className="header-row">
        <SelectAttribute
          value={ATTRIBUTES.ATTR_CUSTOM}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <SelectCustomType
          value={customTypeValue}
          onChange={(customType) => {
            attribute.customType = customType;
            onUpdate(attribute);
          }}
        />
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => onUpdateOperator(op)}
        />
        {attribute.values.map((v, index) => {
          if (
            [OPTIONS.OP_STARTS_WITH, OPTIONS.OP_CONTAINS]
              .map((o) => o.value)
              .includes(attribute.op) === true &&
            index >= 1
          ) {
            return <></>;
          }

          return (
            <div
              className="input-value-wrapper"
              ref={(r) => setDropdownWidth(r?.offsetWidth)}>
              <Input
                key={index}
                placeholder="enter value..."
                value={v}
                labelTextRight={
                  attribute.values.length > 1 ? (
                    <i
                      className="icon-close"
                      onClick={() => onDeleteValue(index)}></i>
                  ) : null
                }
                onChange={({target}) => handleValueChange(index, target.value)}
                onFocus={() => {
                  setInputFocused(index);
                  setSearch(v);
                }}
                disabled
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
