import classNames from 'classnames';
import {func, string} from 'prop-types';
import './_Styles.scss';

export const animationsIn = [
  {
    name: 'None',
    icon: 'icon-close-circle',
    value: null,
  },
  {
    name: 'Fade',
    icon: 'icon-light',
    value: 'fade',
  },
  {
    name: 'Slide up',
    icon: 'icon-slide-up',
    value: 'slide-down',
  },
  {
    name: 'Slide down',
    icon: 'icon-slide-down',
    value: 'slide-up',
  },
  {
    name: 'Slide left',
    icon: 'icon-backward',
    value: 'slide-right',
  },
  {
    name: 'Slide right',
    icon: 'icon-forward',
    value: 'slide-left',
  },
];

export const animationsOut = [
  {
    name: 'None',
    icon: 'icon-close-circle',
    value: null,
  },
  {
    name: 'Fade',
    icon: 'icon-light',
    value: 'fade',
  },
  {
    name: 'Slide up',
    icon: 'icon-slide-up',
    value: 'slide-up',
  },
  {
    name: 'Slide down',
    icon: 'icon-slide-down',
    value: 'slide-down',
  },
  {
    name: 'Slide left',
    icon: 'icon-backward',
    value: 'slide-left',
  },
  {
    name: 'Slide right',
    icon: 'icon-forward',
    value: 'slide-right',
  },
];

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: null,
  onChange: () => {},
};

const GroupAnimations = ({value, onChange, type}) => {
  const animations = type === 'in' ? animationsIn : animationsOut;

  return (
    <div className="animations-list">
      {animations.map((animation) => (
        <div
          className={classNames('animation-item', {
            selected: value === animation.value,
          })}
          onClick={() => onChange(animation.value)}>
          <i className={animation.icon} />
          {animation.name}
        </div>
      ))}
    </div>
  );
};

GroupAnimations.propTypes = propTypes;
GroupAnimations.defaultProps = defaultProps;

export default GroupAnimations;
