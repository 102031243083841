import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {CATEGORY_SEGMENTIO_FIELDS} from 'services/segment';
import {AttributeContext} from '../..';
import {EVENTS_PROPERTIES} from '../AttributePropertySelect';
import './_Styles.scss';

export const OPERAND_OPTIONS = {
  OP_IS: {value: 'IS', label: 'is'},
  OP_NIS: {value: 'NIS', label: 'is not'},
  OP_IS_ACTIVE: {value: 'IS_ACTIVE', label: 'is active'},
  OP_NIS_ACTIVE: {value: 'NIS_ACTIVE', label: 'is not active'},
  OP_IS_GREATER: {value: 'IS_GREATER', label: 'is greater'},
  OP_IS_GREATER_OR_EQUAL: {
    value: 'IS_GREATER_OR_EQUAL',
    label: 'is greater or equal',
  },
  OP_IS_LESSER: {value: 'IS_LESSER', label: 'is less'},
  OP_IS_LESSER_OR_EQUAL: {
    value: 'IS_LESSER_OR_EQUAL',
    label: 'is less or equal',
  },
  OP_IS_BETWEEN: {value: 'IS_BETWEEN', label: 'is between'},
  OP_IS_NBETWEEN: {value: 'IS_NBETWEEN', label: 'is not between'},
  OP_IS_EMPTY: {value: 'IS_EMPTY', label: 'is empty'},
  OP_NIS_EMPTY: {value: 'NIS_EMPTY', label: 'is not empty'},
  OP_STARTS_WITH: {value: 'STARTS_WITH', label: 'starts with'},
  OP_CONTAINS: {value: 'CONTAINS', label: 'contains'},
  OP_NCONTAINS: {value: 'NCONTAINS', label: 'not contains'},

  // Date specific
  OP_DATE_RELATIVE_DAYS_IN_RANGE: {
    value: 'DATE_RELATIVE_DAYS_IN_RANGE',
    label: 'is within the last',
  },
  OP_DATE_RELATIVE_DAYS_NIN_RANGE: {
    value: 'DATE_RELATIVE_DAYS_NIN_RANGE',
    label: 'is not within the last',
  },

  // Event specific
  OP_EVENT_PREVIOUSLY: {
    value: 'EVENT_PREVIOUSLY',
    label: 'previously',
  },
  OP_EVENT_SINCE: {
    value: 'EVENT_SINCE',
    label: 'since',
  },
  OP_EVENT_IN_THE_LAST: {
    value: 'EVENT_IN_THE_LAST',
    label: 'in the last',
  },

  // Array specific
  OP_ARRAY_INCLUDE: {value: 'ARRAY_INCLUDE', label: 'include'},
  OP_ARRAY_NINCLUDE: {value: 'ARRAY_NINCLUDE', label: 'do not include'},
  OP_ARRAY_EMPTY: {value: 'ARRAY_EMPTY', label: 'empty'},
  OP_ARRAY_NEMPTY: {value: 'ARRAY_NEMPTY', label: 'is not empty'},
};

const ACTIVE_OPERANDS = [
  OPERAND_OPTIONS.OP_IS_ACTIVE,
  OPERAND_OPTIONS.OP_NIS_ACTIVE,
];
const DATE_OPERANDS = [
  OPERAND_OPTIONS.OP_IS,
  OPERAND_OPTIONS.OP_NIS,
  OPERAND_OPTIONS.OP_IS_GREATER,
  OPERAND_OPTIONS.OP_IS_GREATER_OR_EQUAL,
  OPERAND_OPTIONS.OP_IS_LESSER,
  OPERAND_OPTIONS.OP_IS_LESSER_OR_EQUAL,
  OPERAND_OPTIONS.OP_IS_BETWEEN,
  OPERAND_OPTIONS.OP_IS_NBETWEEN,
  OPERAND_OPTIONS.OP_DATE_RELATIVE_DAYS_IN_RANGE,
  OPERAND_OPTIONS.OP_DATE_RELATIVE_DAYS_NIN_RANGE,
];
const STRING_OPERANDS = [
  OPERAND_OPTIONS.OP_IS,
  OPERAND_OPTIONS.OP_NIS,
  OPERAND_OPTIONS.OP_IS_EMPTY,
  OPERAND_OPTIONS.OP_NIS_EMPTY,
  OPERAND_OPTIONS.OP_STARTS_WITH,
  OPERAND_OPTIONS.OP_CONTAINS,
  OPERAND_OPTIONS.OP_NCONTAINS,
];
const NUMBER_OPERANDS = [
  OPERAND_OPTIONS.OP_IS,
  OPERAND_OPTIONS.OP_NIS,
  OPERAND_OPTIONS.OP_IS_GREATER,
  OPERAND_OPTIONS.OP_IS_GREATER_OR_EQUAL,
  OPERAND_OPTIONS.OP_IS_LESSER,
  OPERAND_OPTIONS.OP_IS_LESSER_OR_EQUAL,
  OPERAND_OPTIONS.OP_IS_BETWEEN,
  OPERAND_OPTIONS.OP_IS_NBETWEEN,
];
const BOOLEAN_OPERANDS = [OPERAND_OPTIONS.OP_IS, OPERAND_OPTIONS.OP_NIS];
const EVENT_OPERANDS = [
  OPERAND_OPTIONS.OP_EVENT_PREVIOUSLY,
  OPERAND_OPTIONS.OP_EVENT_SINCE,
  OPERAND_OPTIONS.OP_EVENT_IN_THE_LAST,
];
const TAGS_OPERANDS = [
  OPERAND_OPTIONS.OP_IS_EMPTY,
  OPERAND_OPTIONS.OP_NIS_EMPTY,
  OPERAND_OPTIONS.OP_CONTAINS,
  OPERAND_OPTIONS.OP_NCONTAINS,
];
const ARRAY_OPERANDS = [
  OPERAND_OPTIONS.OP_ARRAY_INCLUDE,
  OPERAND_OPTIONS.OP_ARRAY_NINCLUDE,
  OPERAND_OPTIONS.OP_ARRAY_EMPTY,
  OPERAND_OPTIONS.OP_ARRAY_NEMPTY,
];

export const getTypeFromAttribute = (attribute, event = false) => {
  const {type, property: primaryProperty, eventProperty} = attribute;
  const property = event === true ? eventProperty : primaryProperty;

  if (type === 'TAGS') {
    return 'tag';
  } else if (['NAME', 'EMAIL', 'ID'].includes(type)) {
    return 'string';
  } else if (
    ['LAST_ACTIVITY_AT', 'CREATED'].includes(type) ||
    ['JIMO_BASIC_LAST_EVENT_DATE'].includes(property)
  ) {
    return 'date';
  } else if (['ACTIVE'].includes(type)) {
    return 'active';
  } else if (['SESSIONS'].includes(type)) {
    return 'number';
  } else if (
    [
      EVENTS_PROPERTIES.HAS_NOT_OCCURRED.value,
      EVENTS_PROPERTIES.HAS_OCCURRED.value,
      EVENTS_PROPERTIES.OCCURED_MORE_THAN.value,
      EVENTS_PROPERTIES.OCCURED_LESS_THAN.value,
    ].includes(property)
  ) {
    return 'event';
  } else {
    return 'mixed';
  }
};

const AttributeOperand = ({options, event = false}) => {
  const {attribute, updateAttribute} = useContext(AttributeContext);

  const {op, eventOp} = attribute;
  const attributeType =
    event === true ? 'event' : getTypeFromAttribute(attribute);

  const operand = event === true ? eventOp : op;

  const handleOperandChange = ({value}) => {
    updateAttribute({
      ...attribute,
      ...(event === true
        ? {
            eventOp: value,
            ...(attributeType !== 'event'
              ? {
                  eventValues: [],
                }
              : {}),
          }
        : {
            op: value,
            ...(attributeType !== 'event'
              ? {
                  values: [],
                }
              : {}),
          }),
    });
  };

  const filteredOptions = Object.values(OPERAND_OPTIONS).filter((o) => {
    if (options != null) {
      return options.includes(o);
    } else if (attributeType === 'string') {
      return STRING_OPERANDS.includes(o);
    } else if (attributeType === 'date') {
      return DATE_OPERANDS.includes(o);
    } else if (attributeType === 'active') {
      return ACTIVE_OPERANDS.includes(o);
    } else if (attributeType === 'number') {
      return NUMBER_OPERANDS.includes(o);
    } else if (attributeType === 'event') {
      return EVENT_OPERANDS.includes(o);
    } else if (attributeType === 'tag') {
      return TAGS_OPERANDS.includes(o);
    } else {
      return true;
    }
  });

  const groupedOptions = [
    {
      label: 'String',
      options: filteredOptions
        .filter((o) => STRING_OPERANDS.includes(o))
        .map((o) => ({...o, value: o.value + ';STRING'})),
    },
    {
      label: 'Date',
      options: filteredOptions
        .filter((o) => DATE_OPERANDS.includes(o))
        .map((o) => ({...o, value: o.value + ';DATE'})),
    },
    {
      label: 'Number',
      options: filteredOptions
        .filter((o) => NUMBER_OPERANDS.includes(o))
        .map((o) => ({...o, value: o.value + ';NUMBER'})),
    },
    {
      label: 'Boolean',
      options: filteredOptions
        .filter((o) => BOOLEAN_OPERANDS.includes(o))
        .map((o) => ({...o, value: o.value + ';BOOLEAN'})),
    },
    {
      label: 'Array',
      options: filteredOptions
        .filter((o) => ARRAY_OPERANDS.includes(o))
        .map((o) => ({...o, value: o.value + ';ARRAY'})),
    },
  ];

  const flatGroupedOptions = groupedOptions.map((g) => g.options).flat();

  const selectedOperand =
    (attributeType === 'mixed' && options == null
      ? flatGroupedOptions.find((o) => o.value === operand)
      : filteredOptions.find((o) => o.value === operand)) || null;

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      className="select-operand"
      options={
        attributeType === 'mixed' && options == null
          ? groupedOptions // Array operators only supported in segment.io traits for now
              .filter((group) =>
                attribute.type !== CATEGORY_SEGMENTIO_FIELDS
                  ? group.label !== 'Array'
                  : true
              )
          : filteredOptions
      }
      onChange={handleOperandChange}
      value={selectedOperand}
    />
  );
};

export default AttributeOperand;
