import Button from 'components/Button';
import Select from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {trackingCode} from 'components/TrackingCode';
import {Environment} from 'conf/env';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {gtmService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const google = window.google;
const SCOPES = [
  'https://www.googleapis.com/auth/tagmanager.manage.accounts',
  'https://www.googleapis.com/auth/tagmanager.edit.containers',
  'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
  'https://www.googleapis.com/auth/tagmanager.publish',
];

const logger = new Swaler('IntegrationGTM');

export const IntegrationGTM = ({}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [accessToken, setAccessToken] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const refClient = useRef(null);

  const fetchAccounts = async (token = accessToken) => {
    try {
      const accounts = await gtmService.getAccounts({accessToken: token});

      setAccounts(accounts);
    } catch (err) {
      logger.error('fetching accounts failed with error ', err.message);
    }
  };
  const fetchContainers = async (accountPath) => {
    accountPath = accountPath ?? selectedAccount.path;
    try {
      const containers = await gtmService.getContainers({
        accessToken,
        accountPath,
      });

      setContainers(containers);
    } catch (err) {
      logger.error('fetching containers failed with error ', err.message);
    }
  };
  const createTag = async (shouldPublish = true) => {
    const code = trackingCode(project.uid);

    setIsPublishing(true);
    try {
      await gtmService.publishTag({
        accessToken,
        containerPath: selectedContainer.value,
        projectId: project.uid,
        code,
        shouldPublish,
      });
      setIsPublishing(false);
      setIsPublished(true);
      if (shouldPublish === true) {
        toastSuccess(
          [
            'Tag published',
            `Jimo has been integrated to ${project.name} through GTM.`,
          ],
          {toastId: 'tag-published'}
        );
      } else {
        toastSuccess(
          [
            'Tag created',
            `A new workspace "Jimo GTM Integration" with our tag is ready to be published in your GTM account.`,
          ],
          {toastId: 'tag-created'}
        );
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsPublishing(false);
      logger.error('fetching containers failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  useEffect(() => {
    refClient.current = google.accounts.oauth2.initTokenClient({
      client_id: Environment.GOOGLE_GTM_CLI,
      scope: SCOPES.join(' '),
      ux_mode: 'popup',
      callback: (response) => {
        setAccessToken(response.access_token);
        fetchAccounts(response.access_token);
      },
    });
  }, []);

  return (
    <div className="integration-gtm">
      {accessToken == null ? (
        <div className="empty-access-token">
          <Button
            thin
            primary
            onClick={() => refClient.current.requestAccessToken()}>
            Connect to Google Tag Manager
          </Button>
        </div>
      ) : (
        <div className="integration-gtm-content">
          <Select
            noOptionsMessage={() => "We couldn't find GTM account available"}
            placeholder="Select a GTM account"
            options={accounts.map((a) => ({label: a.name, value: a.path}))}
            value={selectedAccount}
            onChange={(value) => {
              setSelectedAccount(value);
              fetchContainers(value.value);
            }}></Select>
          {selectedAccount != null && (
            <Select
              placeholder="Select a GTM container"
              options={containers.map((a) => ({label: a.name, value: a.path}))}
              value={selectedContainer}
              onChange={(value) => {
                setSelectedContainer(value);
              }}></Select>
          )}
          {selectedContainer != null && isPublished === false && (
            <div className="gtm-actions">
              <Button
                thin
                loading={isPublishing}
                onClick={() => createTag(false)}>
                Create tag, let me publish later
              </Button>
              <span>or</span>
              <Button thin loading={isPublishing} onClick={() => createTag()}>
                Create and publish tag for me now
              </Button>
            </div>
          )}
          {selectedContainer != null && isPublished === true && (
            <>Everything is ready, you can continue!</>
          )}
        </div>
      )}
    </div>
  );
};
