import React, {useContext} from 'react';
import {AttributeContext} from '../..';
import './_Styles.scss';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import SelectGroup from 'components/Select';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
} from 'services/steps';

const logger = new Swaler('AttributeEvolutionStepSelect');

const AttributeEvolutionStepSelect = () => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {evolutionId, stepId} = attribute;

  const {data: poke, isLoading} = useQuery({
    queryKey: ['evolution', evolutionId],
    queryFn: async () =>
      evolutionService.getEvolutionById(evolutionId, {
        relations: ['steps'],
      }),
    enabled: !!evolutionId,
    refetchOnWindowFocus: false,
    onError: (err) => {
      logger.error('Could not fetch evolution, failed with err', err.message);
    },
  });

  const steps = poke?.steps || [];

  const stepOptions = steps
    .filter((s) => selectableAnsweredStepsType.includes(s.type))
    .map((e) => ({
      label: e.name || `Unnamed step`,
      value: e.uid,
    }));

  if (evolutionId == null) {
    return <></>;
  }

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={stepOptions}
      closeMenuOnSelect={true}
      isLoading={isLoading}
      value={stepOptions.find((e) => e.value === stepId) || null}
      placeholder="Select step..."
      onChange={(e) => {
        updateAttribute({
          ...attribute,
          stepId: e.value,
          step: steps.find((s) => s.uid === e.value),
        });
      }}
    />
  );
};

export const selectableAnsweredStepsType = [
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_NPS,
];

export default AttributeEvolutionStepSelect;
