import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {ROUTE_SUCCESS_TRACKER} from 'router/routes.const';
import {TrackerContext} from 'scenes/Tracker';
import {jimerService, trackerService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import EditTrackerDrawer from './components/EditTrackerDrawer';
import EditSegmentationDrawer from './components/EditSegmentationDrawer';
import {PermissionsSuccessTracker} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';

const logger = new Swaler('TrackerHeader');

const TrackerHeader = ({scrolled}) => {
  const {tracker, refetch} = useContext(TrackerContext);

  const isActivationTracker = tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER;
  const isFeatureUsageTracker = tracker.type === TRACKER_TYPE_FEATURE_USAGE;

  const history = useHistory();

  const [trackerName, setTrackerName] = useState(tracker.name);
  const [showModalDeleteTracker, setShowModalDeleteTracker] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditTrackerDrawer, setShowEditTrackerDrawer] = useState(false);
  const [showEditAudienceDrawer, setShowEditAudienceDrawer] = useState(false);

  useEffect(() => {
    setTrackerName(tracker.name);
  }, [tracker.name]);

  const {data: jimers, isLoading} = useQuery({
    queryKey: ['jimers', tracker?.segments, tracker?.onTheFlySegment],
    queryFn: () =>
      jimerService.getJimers(
        {
          ...(tracker?.onTheFlySegment?.attributes?.length > 0 &&
          tracker?.onTheFlySegment?.logic != null
            ? {
                attributes: tracker.onTheFlySegment.attributes,
                logic: tracker.onTheFlySegment.logic,
              }
            : {segmentIds: tracker?.segments?.map((s) => s.uid)}),
          take: 1,
          skip: 0,
        },
        {}
      ),
  });

  const handleUpdateSegmentation = async (segments, onTheFlySegment) => {
    await trackerService.updateTracker(tracker.uid, {
      ...tracker,
      segments,
      onTheFlySegment,
    });
    refetch();
  };

  const handleDeleteEvolution = async () => {
    setIsDeleting(true);
    try {
      await trackerService.deleteTracker(tracker.uid);
      toastSuccess('Tracker deleted 👍', {toastId: 'tracker-deleted'});
      history.push(ROUTE_SUCCESS_TRACKER);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting tracker failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsDeleting(false);
      setShowModalDeleteTracker(false);
    }
  };

  const canEditTracker = hasPermissions(PermissionsSuccessTracker.CREATE_EDIT);
  const canDeleteTracker = hasPermissions(PermissionsSuccessTracker.DELETE);

  const {steps, events, onTheFlyEvent} = tracker;

  return (
    <div className={classNames('tracker-header', {scrolled: scrolled})}>
      <div className="infos">
        <Button
          className="back-btn"
          iconOnly
          iconLeft="icon-chevron-left"
          onClick={async () => {
            history.push(ROUTE_SUCCESS_TRACKER);
          }}
        />
        <InputGroup
          className="tracker-name-input"
          placeholder="Tracker name"
          value={trackerName}
          onChange={(e) => {
            setTrackerName(e.target.value);
          }}
          onBlur={async () => {
            await trackerService.updateTracker(tracker.uid, {
              ...tracker,
              name: trackerName,
            });
            refetch();
          }}
          disabled={!canEditTracker}
        />
      </div>
      <div className="actions">
        {canEditTracker && (
          <div className="edit-group">
            <div
              className="edit-group-item"
              onClick={() => setShowEditTrackerDrawer(true)}>
              <i className="isax isax-mouse-circle" />
              {isActivationTracker === true ? (
                <span>
                  {steps.length} step{steps.length > 1 ? 's' : ''}
                </span>
              ) : isFeatureUsageTracker === true ? (
                <span>
                  {onTheFlyEvent != null
                    ? 'On the fly event'
                    : events?.length > 0
                    ? events[0]?.name
                    : 'Select an event'}
                </span>
              ) : (
                <></>
              )}
            </div>
            <Divider vertical />
            <div
              className="edit-group-item"
              onClick={() => setShowEditAudienceDrawer(true)}>
              <i className="isax isax-profile-2user" />
              {isLoading
                ? 'audience'
                : jimers?.total > 0
                ? `${jimers?.total} user${jimers?.total > 1 ? 's' : ''}`
                : 'no users'}
            </div>
          </div>
        )}
        {canDeleteTracker && (
          <Dropdown
            className="dropdown-tracker-more-actions"
            triggerClassName="menu-dropdown-trigger"
            offsetY={8}
            trigger={
              <Button
                className="menu-btn"
                light
                thin
                iconOnly
                rounded
                style={{padding: '8px 16px'}}>
                <i className="icon-menu-vertical" />
              </Button>
            }
            position="bottom right">
            <Menu>
              <MenuItem
                onClick={() => {
                  setShowModalDeleteTracker(true);
                }}>
                <i className="icon-trash" />
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </div>
      <ModalConfirm
        isOpen={showModalDeleteTracker}
        isConfirming={isDeleting}
        confirmText="Yes, delete"
        onConfirm={handleDeleteEvolution}
        onCancel={() => setShowModalDeleteTracker(false)}
      />
      {showEditTrackerDrawer === true && (
        <EditTrackerDrawer
          isOpen={showEditTrackerDrawer}
          onRequestClose={() => {
            setShowEditTrackerDrawer(false);
            refetch();
          }}
          tracker={tracker}
        />
      )}
      {showEditAudienceDrawer === true && (
        <EditSegmentationDrawer
          title={'Setup of your audience'}
          isOpen={showEditAudienceDrawer}
          onRequestClose={() => setShowEditAudienceDrawer(false)}
          selectedSegments={tracker.segments}
          onTheFlySegment={tracker.onTheFlySegment}
          onUpdate={(segments, onTheFlySegment) => {
            handleUpdateSegmentation(segments, onTheFlySegment);
          }}
        />
      )}
    </div>
  );
};

export default TrackerHeader;
