import Button from 'components/Button';
import Input from 'components/Input';
import SelectAttribute from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [
  OPTIONS.OP_IS_GREATER,
  OPTIONS.OP_IS_GREATER_OR_EQUAL,
  OPTIONS.OP_IS_LESSER,
  OPTIONS.OP_IS_LESSER_OR_EQUAL,
  OPTIONS.OP_IS_BETWEEN,
];

export const JimerActivityStatsAttribute = (props) => {
  const {
    attribute,
    onDelete,
    onChangeType,
    onUpdate,
    onUpdateValue,
    onDeleteValue,
    segmentioFields,
    segmentioEvents,
    trackedEvents,
  } = props;

  return (
    <div className="segment-attribute jimer-activity-attribute">
      <div className="header-row">
        <SelectAttribute
          value={attribute.type}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => {
            let values = attribute.values;

            attribute.op = op;
            if (op === OPTIONS.OP_IS_BETWEEN.value) {
              values = attribute.values.length < 2 ? values.concat('') : values;
              onUpdate({...attribute, values});
            } else {
              if (values.length > 1) {
                values.pop();
              }
              onUpdate({...attribute, values});
            }
          }}
        />
        {attribute.values.map((v, index) => (
          <Input
            key={index}
            placeholder="enter value..."
            value={v}
            type="number"
            labelTextRight={
              attribute.values.length > 1 ? (
                <i
                  className="icon-close"
                  onClick={() => onDeleteValue(index)}></i>
              ) : null
            }
            onChange={({target}) => onUpdateValue(index, target.value)}
            disabled
          />
        ))}
      </div>
    </div>
  );
};
