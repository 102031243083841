import RadioGroup from 'components/RadioGroup';
import {func, object} from 'prop-types';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';

const propTypes = {
  style: object,
  setStyle: func,
  setCloseOnDocumentClick: func,
};

const defaultProps = {
  style: {},
  setStyle: () => {},
  setCloseOnDocumentClick: () => {},
};

const ChangelogGeneralStyle = ({style, setStyle, setCloseOnDocumentClick}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <div className="section-item">
        <RadioGroup
          options={[
            {label: 'Gradient', value: 'gradient'},
            {label: 'Solid color', value: 'solid'},
            {label: 'Shape', value: 'shape'},
          ]}
          value={style.background?.type}
          onSelect={(value) =>
            updateStyle({
              background: {
                ...style.background,
                type: value,
              },
            })
          }
        />
      </div>
      {style.background?.type === 'shape' && (
        <div className="section-item">
          <div className="section-item-title">Shape</div>
          <div className="section-item-content">
            <RadioGroup
              options={[
                {label: 'Round', value: 'circular'},
                {label: 'Rectangle', value: 'linear'},
              ]}
              value={style.background?.shape}
              onSelect={(value) =>
                updateStyle({
                  background: {
                    ...style.background,
                    shape: value,
                  },
                })
              }
            />
          </div>
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title">
          {['gradient', 'shape'].includes(style.background?.type)
            ? 'Color 1'
            : 'Color'}
        </div>
        <div className="section-item-content">
          <ColorPickerInput
            title={
              ['gradient', 'shape'].includes(style.background?.type)
                ? 'Color 1'
                : 'Color'
            }
            value={style.background?.secondaryColor}
            onChange={(v) =>
              updateStyle({
                background: {...style.background, secondaryColor: v},
              })
            }
            setParentCloseOnDocumentClick={setCloseOnDocumentClick}
          />
        </div>
      </div>
      {['gradient', 'shape'].includes(style.background?.type) && (
        <div className="section-item">
          <div className="section-item-title">Color 2</div>
          <div className="section-item-content">
            <ColorPickerInput
              title="Color 2"
              value={style.background?.primaryColor}
              onChange={(v) => {
                updateStyle({
                  background: {...style.background, primaryColor: v},
                });
              }}
              setParentCloseOnDocumentClick={setCloseOnDocumentClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

ChangelogGeneralStyle.propTypes = propTypes;
ChangelogGeneralStyle.defaultProps = defaultProps;

export default ChangelogGeneralStyle;
