import classnames from 'classnames';
import Button from 'components/Button';
import {toastSuccess} from 'components/Toaster';
import {trackingCodeWithoutStyle} from 'components/TrackingCode';
import copy from 'copy-to-clipboard';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import ReactHighlighter from 'react-syntax-highlighter';
import {tomorrowNightEighties} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {Swaler} from 'swaler';
import {SettingsInstallationHeader} from '../../components/SettingsInstallationHeader';
import './_Styles.scss';
import {CardGTM} from './card-gtm';

const logger = new Swaler('Settings/Installation');

const Installation = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [framework, setFramework] = useState('javascript');

  return (
    <SettingsBody className="s-settings-installation">
      <SettingsInstallationHeader />
      <div className="settings-card card-snippet">
        <div className="card-header">
          <div className="left-wrapper">
            <span className="subtitle-3">Install Jimo snippet</span>
            <div className="body-3 n-700">
              Paste this in the {`<head>`} tag of your website
            </div>
          </div>
          <div className="right-wrapper">
            <div className="custom-tabs-wrapper">
              <div
                className={classnames('custom-tab-item body-3', {
                  'is-active': framework === 'javascript',
                })}
                onClick={() => setFramework('javascript')}>
                Javascript
              </div>
              <div className="custom-tab-divider"></div>
              <div
                className={classnames('custom-tab-item body-3', {
                  'is-active': framework === 'react',
                })}
                onClick={() => setFramework('react')}>
                React
              </div>
              <div className="custom-tab-divider"></div>
              <div
                className={classnames('custom-tab-item body-3', {
                  'is-active': framework === 'vue',
                })}
                onClick={() => setFramework('vue')}>
                Vue
              </div>
              <div className="custom-tab-divider"></div>
              <div
                className={classnames('custom-tab-item body-3', {
                  'is-active': framework === 'angular',
                })}
                onClick={() => setFramework('angular')}>
                Angular
              </div>
            </div>
          </div>
        </div>
        <div className="snippet-wrapper">
          <Button
            className="btn-copy-snippet"
            iconLeft="isax isax-copy"
            thin
            onClick={() => {
              copy(trackingCodeWithoutStyle(framework, project.uid));
              toastSuccess('Code snippet copied', {toastId: 'snippet-copied'});
            }}>
            Copy
          </Button>
          <ReactHighlighter language="javascript" style={tomorrowNightEighties}>
            {trackingCodeWithoutStyle(framework, project.uid)}
          </ReactHighlighter>
        </div>
      </div>
      <CardGTM />
    </SettingsBody>
  );
};

export default Installation;
