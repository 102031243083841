import Axios from 'axios';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {generalSelector} from 'selectors';

// Endpoint
const EP_FILE_UPLOAD_PUBLIC = '/file/public';
const EP_FILE_DELETE_PUBLIC = (fileId) => `/file/public/${fileId}`;
const EP_FILE_UPLOAD_CSV = '/file/csv';
const EP_GET_FILES = '/file';
const EP_FILE_PNG_2_WEBP = '/file/png2webp';

// Methods
export const uploadPublicFile = async (data) => {
  const projectId = generalSelector.getProject().uid;

  let {file, blockType} = data;
  const formData = new FormData();

  if (file.type === 'image/png') {
    const webp = await png2webp(file);

    if (webp == null) return;
    file = new File(
      [webp],
      file.name.replace('.png', '.webp', {
        type: 'image/webp',
        lastModified: new Date().getTime(),
      })
    );
  }

  formData.append('file', file);
  formData.append('projectId', projectId);
  if (blockType != null) {
    formData.append('blockType', blockType);
  }
  return Axios.post(EP_FILE_UPLOAD_PUBLIC, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  }).then((response) => response.data);
};

export const deletePublicFile = (fileId) => {
  return Axios.post(EP_FILE_DELETE_PUBLIC(fileId)).then(
    (response) => response.data
  );
};
export const getFiles = ({type}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_GET_FILES, {
    params: {projectId, blockType: type},
  }).then((response) => response.data);
};

export const png2webp = async (file) => {
  const formData = new FormData();

  formData.append('file', file);

  return Axios.post(EP_FILE_PNG_2_WEBP, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
    responseType: 'blob',
  })
    .then((response) => response.data)
    .catch((err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {actions});
      return null;
    });
};

export const uploadCsvFile = async (data) => {
  const projectId = generalSelector.getProject().uid;

  let {file} = data;
  const formData = new FormData();

  formData.append('file', file);
  formData.append('projectId', projectId);

  return Axios.post(EP_FILE_UPLOAD_CSV, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  }).then((response) => response.data);
};
