import classNames from 'classnames';
import Button from 'components/Button';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {buildStormwindUrl} from 'helpers/utils';
import IframeResizer from 'iframe-resizer-react';
import React, {useContext, useEffect, useRef} from 'react';
import {ChangelogBuilderContext} from 'scenes/ChangelogBuilder';
import {
  HINT_ICON_BUILT_IN,
  triggerIconsList,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/IconPicker';
import {previewPost} from 'scenes/Settings/scenes/Themes/components/ChangelogThemePreview/utils';
import {projectService} from 'services';
import {
  F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE,
  F_PAGE_FEED,
  TRIGGER_BADGE,
  TRIGGER_CUSTOM,
  TRIGGER_DEFAULT,
  TRIGGER_SIDE_BADGE,
  WIDGET_POSITION_BOTTOM_LEFT,
  WIDGET_POSITION_BOTTOM_RIGHT,
  defaultChangelogStyle,
} from 'services/project';
import {Swaler} from 'swaler';
import {
  CHANGELOG_BLOCK_FEEDBACK,
  CHANGELOG_BLOCK_POST_FEED,
  CHANGELOG_BLOCK_TRIGGER,
} from '../ChangelogNavigator';
import './_Styles.scss';

const logger = new Swaler('PostPreview');

const ChangelogPreview = () => {
  const {selectedBlockType} = useContext(ChangelogBuilderContext);
  const {changelogTheme: theme, project} = useContext(BuilderContext);

  const iframeRef = useRef(null);
  const themeRef = useRef(theme);
  const iconWrapperRef = useRef(null);

  const sendPostPreviewUpdate = () => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'POST_PREVIEW_UPDATE',
        data: {evolution: previewPost},
      });
    }
  };

  const sendThemeUpdate = (updatedTheme = theme) => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'CHANGELOG_OVERRIDE_THEME',
        data: {theme: updatedTheme},
      });
    }
  };

  const sendProjectUpdate = () => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'PROJECT_UPDATE',
        data: {
          project,
        },
      });
    }
  };

  const sendViewUpdate = (view) => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'CHANGELOG_OVERRIDE_VIEW',
        data: {
          view,
        },
      });
    }
  };

  const handleIframeMessage = ({message}) => {
    try {
      const m = JSON.parse(message);

      if (m.action === 'POST_PREVIEW_REQUEST') {
        sendPostPreviewUpdate();
        sendThemeUpdate(themeRef.current);
      }
    } catch (err) {
      logger.error(`Couldn't parse message sent by widget: ${err}`);
    }
  };

  useEffect(() => {
    themeRef.current = theme;
  }, [theme]);

  useEffect(() => {
    sendThemeUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    sendProjectUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (
      [CHANGELOG_BLOCK_POST_FEED, CHANGELOG_BLOCK_FEEDBACK].includes(
        selectedBlockType
      )
    ) {
      sendViewUpdate(
        selectedBlockType === CHANGELOG_BLOCK_POST_FEED ? 'feed' : 'feedback'
      );
    }
  }, [selectedBlockType]);

  const {
    widgetPosition,
    triggerMode: widgetTriggerMode,
    widgetThemeColor,
    widgetTriggerMessage,
    widgetContentFlags,
    extraFlags,
  } = project;

  const widgetFeedEnabled = hasFlag(F_PAGE_FEED, widgetContentFlags);

  const url = buildStormwindUrl(
    projectService.getProjectPortalUrl(project),
    `/w/feed`,
    'preview=true'
  );

  const showTrigger = selectedBlockType === CHANGELOG_BLOCK_TRIGGER;

  const trigger = theme?.trigger || defaultChangelogStyle.trigger;
  const {
    backgroundColor,
    shadow,
    borderRadius,
    fontFamily,
    fontColor,
    iconSource,
    iconName,
    iconUrl,
    iconColor,
  } = trigger;
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  const triggerStyle = {
    boxShadow,
    borderRadius:
      widgetTriggerMode === TRIGGER_DEFAULT
        ? borderRadius
        : widgetPosition === WIDGET_POSITION_BOTTOM_LEFT
        ? `0 ${borderRadius}px ${borderRadius}px 0`
        : `${borderRadius}px 0 0 ${borderRadius}px`,
    backgroundColor,
    color: fontColor,
    fontFamily,
  };

  const selectedIcon = triggerIconsList.find(
    (i) => i.value === iconName || null
  );

  useEffect(() => {
    const svg = iconWrapperRef.current?.querySelector('svg');
    if (svg) {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.style.fill = iconColor || 'white';
      });
    }
  }, [iconColor, selectedIcon, iconWrapperRef.current]);

  return (
    <div className="changelog-preview">
      <div
        className={classNames('changelog-wrapper', {
          'is-right': widgetPosition === WIDGET_POSITION_BOTTOM_RIGHT,
          'is-iframe-hidden': showTrigger === true,
        })}>
        <IframeResizer
          forwardRef={iframeRef}
          title="changelog-preview-iframe"
          loading="lazy"
          src={url}
          className="changelog-iframe"
          allowFullScreen
          onMessage={handleIframeMessage}
        />
      </div>

      <div
        className={classNames('preview-widget-triggers', {
          'mode-custom': widgetTriggerMode === TRIGGER_CUSTOM,
          'mode-default': widgetTriggerMode === TRIGGER_DEFAULT,
          'mode-badge': widgetTriggerMode === TRIGGER_BADGE,
          'mode-side-badge': widgetTriggerMode === TRIGGER_SIDE_BADGE,
          'has-position-left fade-in-left':
            widgetPosition === WIDGET_POSITION_BOTTOM_LEFT,
          'has-position-right fade-in-right':
            widgetPosition === WIDGET_POSITION_BOTTOM_RIGHT,
          'is-hidden': showTrigger === false,
        })}
        style={{
          backgroundColor:
            widgetTriggerMode === TRIGGER_DEFAULT
              ? widgetThemeColor
              : undefined,
          ...(widgetTriggerMode === TRIGGER_DEFAULT ? triggerStyle : {}),
        }}>
        {widgetTriggerMode === TRIGGER_DEFAULT && (
          <>
            <div className="message-wrapper">
              {widgetTriggerMessage.length !== 0
                ? widgetTriggerMessage
                : widgetFeedEnabled === true
                ? `What's new on ${project.name}?`
                : `Help us improve ${project.name}`}
            </div>
            <div
              className="icon-wrapper"
              ref={iconWrapperRef}
              style={{color: iconColor}}>
              {iconSource === HINT_ICON_BUILT_IN ? (
                selectedIcon?.icon
              ) : iconUrl ? (
                <img src={iconUrl} alt="" />
              ) : (
                <>C</>
              )}
            </div>
          </>
        )}
        {widgetTriggerMode === TRIGGER_CUSTOM && (
          <Button rounded={false} primary>
            Your custom button
          </Button>
        )}
        {widgetTriggerMode === TRIGGER_BADGE && (
          <div className="preview-whats-new">
            What's new? <div className="whats-new-badge">2</div>
          </div>
        )}
        {widgetTriggerMode === TRIGGER_SIDE_BADGE && (
          <div className="preview-trigger-side-wrapper">
            <div
              className="preview-trigger-side"
              style={{
                backgroundColor: widgetThemeColor,
                ...triggerStyle,
              }}>
              <div className="message">
                {widgetTriggerMessage.length !== 0
                  ? widgetTriggerMessage
                  : widgetFeedEnabled === true
                  ? `What's new on ${project.name}?`
                  : `Help us improve ${project.name}`}
              </div>
              {hasFlag(F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE, extraFlags) ===
                false && (
                <div className="preview-trigger-side-notification">1</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangelogPreview;
