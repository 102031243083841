import {generalActions} from 'actions';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {projectService} from 'services';

export const useCheckInstallation = ({
  onInstalled = () => {},
  onNotInstalled = () => {},
} = {}) => {
  const dispatch = useDispatch();
  const {pushAttributes} = useJimoIdentify({forceIsIdentified: true});

  const project = useSelector((state) => generalSelector.getProject(state));

  const [isChecking, setIsChecking] = useState(false);

  async function check() {
    setIsChecking(true);

    const updatedProject = await projectService.getProject();

    setIsChecking(false);
    if (updatedProject.snippetInstalledAt == null) {
      return onNotInstalled();
    }
    dispatch(generalActions.uptProject(updatedProject));
    pushAttributes({
      hasInstalled: true,
    });
    return onInstalled();
  }

  return {
    check,
    isChecking,
    isInstalled: project.snippetInstalledAt != null,
  };
};
