import {ELEMENT_BLOCKQUOTE} from '@udecode/plate-block-quote';
import {
  collapseSelection,
  findNode,
  focusEditor,
  isBlock,
  isCollapsed,
  toggleNodeType,
  useEditorState,
} from '@udecode/plate-common';
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
} from '@udecode/plate-heading';
import {toggleList, unwrapList} from '@udecode/plate-list';
import {ELEMENT_PARAGRAPH} from '@udecode/plate-paragraph';
import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import React, {useState} from 'react';
import './_Styles.scss';
import Bullet from './images/bullet.svg';
import H1 from './images/H1.svg';
import H2 from './images/H2.svg';
import H3 from './images/H3.svg';
import H4 from './images/H4.svg';
import Numbered from './images/numbered.svg';
import P from './images/p.svg';
import Quote from './images/quote.svg';

const items = [
  {
    value: ELEMENT_PARAGRAPH,
    label: 'Paragraph',
    description: 'Create a simple paragraph',
    icon: <img src={P} alt="p" />,
  },
  {
    value: ELEMENT_H1,
    label: 'Heading 1',
    description: 'Huge section heading',
    icon: <img src={H1} alt="h1" />,
  },
  {
    value: ELEMENT_H2,
    label: 'Heading 2',
    description: 'Big section heading',
    icon: <img src={H2} alt="h2" />,
  },
  {
    value: ELEMENT_H3,
    label: 'Heading 3',
    description: 'Medium section heading',
    icon: <img src={H3} alt="h3" />,
  },
  {
    value: ELEMENT_H4,
    label: 'Heading 4',
    description: 'Small section heading',
    icon: <img src={H4} alt="h4" />,
  },
  {
    value: 'ul',
    label: 'Bulleted list',
    description: 'Bulleted list',
    icon: <img src={Bullet} alt="bullet" />,
  },
  {
    value: 'ol',
    label: 'Numbered list',
    description: 'Numbered list',
    icon: <img src={Numbered} alt="numbered" />,
  },
  {
    value: ELEMENT_BLOCKQUOTE,
    label: 'Quote',
    description: 'Quote (⌘+⇧+.)',
    icon: <img src={Quote} alt="quote" />,
  },
];

const defaultItem = items.find((item) => item.value === ELEMENT_PARAGRAPH);

export function TurnIntoDropdownMenu() {
  const editor = useEditorState();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  let value = ELEMENT_PARAGRAPH;
  if (isCollapsed(editor?.selection)) {
    const entry = findNode(editor, {
      match: (n) => isBlock(editor, n),
    });
    if (entry) {
      value =
        items.find((item) => item.value === entry[0].type)?.value ??
        ELEMENT_PARAGRAPH;
    }
  }

  const selectedItem =
    items.find((item) => item.value === value) ?? defaultItem;
  const {label: selectedItemLabel} = selectedItem;

  const handleChange = (type) => {
    if (type === 'ul' || type === 'ol') {
      toggleList(editor, {type});
    } else {
      unwrapList(editor);
      toggleNodeType(editor, {activeType: type});
    }

    collapseSelection(editor);
    focusEditor(editor);
  };
  return (
    <div className="turn-into-dropdown-wrapper">
      <Dropdown
        open={dropdownOpen}
        onOpen={() => {
          setDropdownOpen(true);
        }}
        onClose={() => {
          setDropdownOpen(false);
        }}
        className="turn-into-dropdown"
        position="bottom left"
        offsetY={4}
        repositionOnResize={false}
        trigger={
          <div
            className="turn-into-trigger"
            onClick={() => setDropdownOpen(true)}>
            {selectedItemLabel}
            <i className="icon-chevron-bottom" />
          </div>
        }>
        <div className="options-list">
          {items.map(({value: itemValue, label, description, icon}) => (
            <div
              key={itemValue}
              className={classNames('item')}
              onClick={() => {
                handleChange(itemValue);
                setDropdownOpen(false);
              }}>
              <div className="icon-wrapper">{icon}</div>
              <div className="content-wrapper">
                <div className="title">{label}</div>
                <div className="description">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
}
