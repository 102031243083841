import classnames from 'classnames';
import ReactToggle from 'react-toggle';
import './_Styles.scss';

export default function Toggle(props) {
  const {className, locked, disabled, innerRef, ...rest} = props;

  return (
    <ReactToggle
      ref={innerRef}
      className={classnames('jimo-toggle', className)}
      disabled={disabled === true || locked === true}
      icons={false}
      {...rest}
    />
  );
}
