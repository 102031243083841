import SelectGroup from 'components/Select';
import {components} from 'react-select';
import './_styles.scss';
import imgJimoIcon from './imgs/jimo.svg';

const Control = ({children, ...rest}) => {
  const {isJimo = false} = rest;

  return (
    <components.Control {...rest}>
      {isJimo === true && (
        <img src={imgJimoIcon} width={26} height={26} alt="jimo-icon" />
      )}
      {isJimo === false && <i className="isax isax-document-upload"></i>}
      {children}
    </components.Control>
  );
};

export const IdentifyField = ({
  value = null,
  placeholder = null,

  isDisabled = false,
  isJimo = false,

  options = [],
  previewItems = [],

  onChange = () => {},
}) => {
  return (
    <div className="import-csv-identify-field">
      <div className="field-header">
        <SelectGroup
          value={value == null ? null : options.find((o) => o.value === value)}
          placeholder={placeholder}
          onChange={onChange}
          isDisabled={isDisabled}
          options={options}
          components={{
            Control: (props) => (
              <Control
                className="import-csv-identify-field-control"
                {...props}
                isJimo={isJimo}
              />
            ),
          }}
        />
      </div>
      <div className="field-preview">
        {previewItems.length === 0 && (
          <div className="preview-empty">No preview available</div>
        )}
        {previewItems.map((item, i) => (
          <div key={i} className="preview-item body-2">
            <span>{i + 1}</span>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
