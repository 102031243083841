import React, {useEffect, useMemo} from 'react';
import {useQuery} from 'react-query';
import {evolutionAnalyticsService} from 'services';
import dayjs from 'dayjs';
import {Swaler} from 'swaler';
import {generateDatesArray} from '../..';
import {object, string} from 'prop-types';
import './_Styles.scss';
import BarChart from 'components/Charts/BarChart';

const propTypes = {
  evolution: object.isRequired,
  selectedSegment: string,
};

const defaultProps = {
  selectedSegment: null,
};

const logger = new Swaler('SubmissionsChart');

const SubmissionsChart = ({evolution, timeRange}) => {
  const [startDate, endDate] = timeRange;

  const {
    data: analyticsHourly,
    isLoading: isLoadingAnalyticsHourly,
    refetch: refetchAnalyticsHourly,
  } = useQuery({
    queryKey: ['analyticsHourly', evolution.uid, startDate, endDate],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourly({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        evolutionIds: [evolution.uid],
      });
    },
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  useEffect(() => {
    refetchAnalyticsHourly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);

  const datesArr = generateDatesArray(startDate, endDate).filter((d) =>
    dayjs(d).isBefore(dayjs().endOf('day'))
  );

  // potential bottleneck
  // n = end-start
  const data = useMemo(() => {
    // n
    return datesArr.reduce((acc, cur) => {
      const date = cur.format('YYYY-MM-DD');
      const timestamp = cur.unix();

      // m
      const foundData = analyticsHourly?.filter((d) => {
        return dayjs.unix(d.createdAt / 1000).isSame(cur, 'day');
      });

      let dataValue = 0;
      if (foundData?.length > 0) {
        dataValue = foundData.reduce(
          (acc, cur) => (acc += parseInt(cur.sum_survey_completed_count, 10)),
          0
        );
      }
      acc.push({
        date,
        timestamp,
        value: dataValue,
        evolutionId: evolution.uid,
      });

      return acc;
    }, []);
  }, [datesArr, evolution, analyticsHourly]);

  return (
    <div className="submissions-chart">
      <BarChart data={data} evolution={evolution} />
    </div>
  );
};

SubmissionsChart.propTypes = propTypes;
SubmissionsChart.defaultProps = defaultProps;

export default SubmissionsChart;
