import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import DefaultLoader from 'components/Loader';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import ModalTagManager from 'components/ModalTagManager';
import {TagAddButton, TagButton} from 'components/TagButton';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {UserAvatar} from 'components/UserAvatar';
import {PermissionsRequest} from 'constants/permissions';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {arraysEqual, hasUsernameGenerated} from 'helpers/utils';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {ROUTE_DRAFT} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {requestService, tagService} from 'services';
import {TAG_CONTEXT_REQUEST} from 'services/tag';
import {Swaler} from 'swaler';
import './_Styles.scss';

dayjs.extend(relativeTime);

const RequestDetails = ({refetchParent}) => {
  const logger = new Swaler('RequestDetail');

  const history = useHistory();
  const {requestId} = useParams();

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const [showModalTagManager, setShowModalTagManager] = useState(false);
  const [requestTags, setRequestTags] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isDeletingRequest, setIsDeletingRequest] = useState(false);

  const {data: tags = [], refetch} = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_REQUEST]}),
    refetchOnWindowFocus: false,
  });

  const {data: request, isFetching} = useQuery({
    queryKey: ['request', requestId],
    queryFn: async () => {
      if (requestId != null) {
        const request = await requestService.getRequestById(requestId, {
          relations: ['tags', 'author'],
        });
        if (request.unread === true) {
          await requestService.setRequestUnread(request.uid, false);
          refetchParent();
        }
        return request;
      }
      return null;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!request?.tags) {
      return;
    }
    setRequestTags(request.tags);
  }, [request?.tags]);

  useEffect(() => {
    if (!request?.tags) {
      return;
    }
    if (!arraysEqual(request?.tags, requestTags)) {
      handleTagsChange(requestTags);
    }
  }, [requestTags]);

  const handleAddTag = (tag) => {
    setRequestTags([...requestTags, tag]);
  };
  const handleUnassignTag = (tagId) => {
    setRequestTags(requestTags.filter((tag) => tag.uid !== tagId));
  };
  const handleAssignTag = (tag) => {
    setRequestTags([...requestTags, tag]);
  };

  const handleDeleteRequest = async () => {
    try {
      setIsDeletingRequest(true);
      await requestService.deleteRequest(requestId);
      toastSuccess('Request deleted 👍', {toastId: 'request-deleted'});
      setIsDeletingRequest(false);
      setShowModalDelete(false);
      refetchParent();
      history.push(ROUTE_DRAFT);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting evolution failed with error ', code);
      toastDanger([title, message], {actions});
      setIsDeletingRequest(false);
      setShowModalDelete(false);
    }
  };

  const handleTagsChange = async (tags) => {
    try {
      await requestService.updateRequest(requestId, {tags});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating request tags failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  const classNames = classnames('draft-request-detail fade-in-bottom');
  const dropdownContent = (
    <Menu>
      {/* <MenuItem iconPosition="left" icon="icon-edit" onClick={() => {}}>
        Edit
      </MenuItem> */}
      <MenuItem
        iconPosition="left"
        icon="icon-trash"
        onClick={() => setShowModalDelete(true)}>
        Delete
      </MenuItem>
    </Menu>
  );
  let from = null;
  let isFromJimer = null;
  let externalJimerInfo = null;

  if (requestId == null) {
    return (
      <div className={classNames}>
        <div className="main">Select a request to see details</div>
      </div>
    );
  }
  if (isFetching) {
    return (
      <div className={classNames}>
        <div className="main">
          <DefaultLoader />
        </div>
      </div>
    );
  }
  isFromJimer = request.user != null ? false : true;
  from = isFromJimer === false ? request.user : request.jimer;
  externalJimerInfo =
    isFromJimer === true
      ? from?.personalEmail != null
        ? from?.personalEmail
        : from?.externalEmail != null
        ? from?.externalEmail
        : from?.externalUsername != null
        ? from?.externalUsername
        : from?.username
      : null;
  const username =
    hasUsernameGenerated(from?.username) && from?.externalUsername != null
      ? from?.externalUsername
      : from?.username;

  return (
    <div key={request.uid} className={classNames}>
      <div className="header">
        <div className="infos-row">
          <div className="infos">
            <UserAvatar
              user={isFromJimer === false ? request.user : undefined}
              jimer={isFromJimer === true ? request.jimer : undefined}
              onlyAvatar
              showBadgeTeam={isFromJimer === false}></UserAvatar>
            <span className="from">
              {from == null ? 'Deleted user' : username}
              {externalJimerInfo != null && (
                <span className="muted"> ({externalJimerInfo})</span>
              )}
            </span>
          </div>
          {hasPermissions(PermissionsRequest.DELETE) && (
            <div className="actions">
              <Dropdown
                trigger={
                  <Button muted iconOnly>
                    <i className="icon-menu-vertical"></i>
                  </Button>
                }
                position="bottom right"
                keepTooltipInside={false}>
                {dropdownContent}
              </Dropdown>
            </div>
          )}
        </div>

        <div className="tags-row">
          {requestTags.map((t) => (
            <TagButton
              key={t.uid}
              tag={t}
              request={request}
              interactive={false}
              onUnassignTag={handleUnassignTag}
            />
          ))}
          <TagAddButton
            iconLeft="icon-plus"
            compact={request.tags.length !== 0}
            request={request}
            onTagAdd={handleAddTag}
            onAssignTag={handleAssignTag}>
            tag
          </TagAddButton>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="title">{request.title}</div>
        {request.content != null && request.content.length !== 0 ? (
          <div className="content">{request.content}</div>
        ) : (
          <em className="content__empty">No additional details added!</em>
        )}

        <div
          className="time"
          title={dayjs(request.createdAt).format('HH:mm - DD/MM/YYYY')}>
          {dayjs(request.createdAt).fromNow()}
        </div>
      </div>
      <Divider />
      <ModalTagManager
        isOpen={showModalTagManager}
        context={TAG_CONTEXT_REQUEST}
        enableAdd
        enableEditColorPicker
        enableEditTagPreview
        onRequestClose={() => {
          setShowModalTagManager(false);
          refetch();
        }}
      />
      <ModalConfirm
        isOpen={showModalDelete}
        onCancel={() => setShowModalDelete(false)}
        onConfirm={() => handleDeleteRequest()}
        isConfirming={isDeletingRequest}
      />
    </div>
  );
};

export default RequestDetails;
