import React from 'react';
import {string, node} from 'prop-types';
import './_Styles.scss';
import classNames from 'classnames';
import Divider from 'components/Divider';

const propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: '',
};

const PokeBlock = ({className, title, children}) => {
  return (
    <div className={classNames('poke-block', className)}>
      {title && (
        <>
          <div className="block-header">
            <div className="block-title">{title}</div>
          </div>
          <Divider />
        </>
      )}
      <div className="block-content">{children}</div>
    </div>
  );
};

PokeBlock.propTypes = propTypes;
PokeBlock.defaultProps = defaultProps;
export default PokeBlock;
