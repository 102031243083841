import React, {forwardRef} from 'react';

import {PlateElement, withHOC} from '@udecode/plate-common';
import {Image, useMediaState} from '@udecode/plate-media';
import {ResizableProvider} from '@udecode/plate-resizable';
import classNames from 'classnames';
import './_Styles.scss';

const ImageElement = withHOC(
  ResizableProvider,
  forwardRef(({children, className, nodeProps, ...props}, ref) => {
    const {focused, selected} = useMediaState();

    return (
      <PlateElement
        className={classNames(className, 'plate-image-element')}
        ref={ref}
        {...props}>
        <figure className="image-wrapper" contentEditable={false}>
          <Image
            alt=""
            className={classNames('image', {
              selected: focused && selected,
            })}
            {...nodeProps}
          />
        </figure>
        {children}
      </PlateElement>
    );
  })
);

export default ImageElement;
