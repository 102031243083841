import {generalActions} from 'actions';
import Button from 'components/Button';
import {toastInfo} from 'components/Toaster';
import React, {useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_SEGMENT,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {segmentioService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';
import SettingsSetup from './components/SettingsSetup';

const logger = new Swaler('SegmentioSettings');

const SegmentioSettings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject());

  const [isLoading, setIsLoading] = useState(false);

  const {segmentio} = project;

  const {mutate: deleteSegment, isLoading: isLoadingSegmentDelete} =
    useMutation(() => segmentioService.deleteSegment(segmentio?.uid), {
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: () => {
        toastInfo([
          'Integration disconnected',
          'Your integration with Segment has been disconnected!',
        ]);
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        dispatch(generalActions.uptProject({segmentio: null}));
      },
    });

  useEffect(() => {
    if (location.pathname === ROUTE_SETTINGS_INTEGRATIONS_SEGMENT) {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      if (code !== null && state !== null) {
        setIsLoading(true);
        segmentioService
          .exchangeCodeForToken(project.uid, {
            code,
            state,
          })
          .then((segmentio) => {
            dispatch(generalActions.uptProject({segmentio}));
          })
          .catch((err) => {
            logger.error(err);
          })
          .finally(() => {
            params.delete('code');
            params.delete('state');
            history.replace({
              pathname: ROUTE_SETTINGS_INTEGRATIONS,
              search: params.toString(),
            });
            setIsLoading(false);
          });
      }
    }
  }, [location]);

  useEffect(() => {
    if (segmentio == null) {
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
      toastInfo([
        'Integration not connected',
        "Segment integration isn't connected yet. Please connect the integration first.",
      ]);
    }
  }, [segmentio]);

  return (
    <SettingsBody className="segmentio-settings">
      <div className="segmentio-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={async () => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Segment Integration</div>
        </div>
        <div className="right-side"></div>
      </div>
      <div className="segmentio-settings-content">
        <div className="left-side">
          <SettingsSetup segmentio={segmentio} />
        </div>
        <div className="right-side">
          <HelpSection
            isDisconnecting={isLoadingSegmentDelete}
            onDisconnect={() => {
              deleteSegment();
            }}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default SegmentioSettings;
