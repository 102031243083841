import classNames from 'classnames';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {bool, func, object} from 'prop-types';
import {useState} from 'react';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_SURVEY_RESPONSE,
  EVENT_CONDITION_TYPE_TOUR_SEEN,
  EVENT_CONDITION_TYPE_URL,
} from 'services/event';
import './_Styles.scss';
import SettingsDelay from './components/SettingsDelay';
import SettingsElement from './components/SettingsElement';
import SettingsInput from './components/SettingsInput';
import SettingsSurveyResponse from './components/SettingsSurveyResponse';
import SettingsTourSeen from './components/SettingsTourSeen';
import SettingsUrl from './components/SettingsUrl';

const propTypes = {
  condition: object.isRequired,
  setCondition: func.isRequired,
  isOpen: bool,
  onClose: func,
};

const EditDropdown = ({
  condition,
  setCondition,
  isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  let icon = '',
    iconClassName = '',
    title = '',
    content = '';

  const [preventClose, setPreventClose] = useState(false);

  const isElement = condition.type === EVENT_CONDITION_TYPE_ELEMENT;
  const isInput = condition.type === EVENT_CONDITION_TYPE_INPUT;
  const isUrl = condition.type === EVENT_CONDITION_TYPE_URL;
  const isDelay = condition.type === EVENT_CONDITION_TYPE_DELAY;
  const isTourSeen = condition.type === EVENT_CONDITION_TYPE_TOUR_SEEN;
  const isSurveyResponse =
    condition.type === EVENT_CONDITION_TYPE_SURVEY_RESPONSE;

  const {querySelector, querySelectorManual} = condition;

  if (isElement) {
    icon = 'isax isax-mouse-circle';
    iconClassName = 'element';
    if (querySelector || querySelectorManual) {
      title = 'Adjust element';
    } else {
      title = 'Pick an element in-app';
    }
    content = (
      <SettingsElement
        condition={condition}
        setCondition={setCondition}
        setPreventClose={setPreventClose}
      />
    );
  } else if (isUrl) {
    icon = 'isax isax-receipt-search';
    iconClassName = 'url';
    title = 'Current page';
    content = (
      <SettingsUrl
        condition={condition}
        setCondition={setCondition}
        setPreventClose={setPreventClose}
      />
    );
  } else if (isInput) {
    icon = 'isax isax-keyboard';
    iconClassName = 'input';
    if (querySelector) {
      title = 'Adjust input';
    } else {
      title = 'Pick an input in-app';
    }
    content = (
      <SettingsInput
        condition={condition}
        setCondition={setCondition}
        setPreventClose={setPreventClose}
      />
    );
  } else if (isDelay) {
    icon = 'isax isax-clock';
    iconClassName = 'delay';
    title = 'Delay';
    content = (
      <SettingsDelay condition={condition} setCondition={setCondition} />
    );
  } else if (isTourSeen) {
    icon = 'isax isax-routing-2';
    iconClassName = 'tour-seen';
    title = 'Experience';
    content = (
      <SettingsTourSeen condition={condition} setCondition={setCondition} />
    );
  } else if (isSurveyResponse) {
    icon = 'isax isax-message-text';
    iconClassName = 'survey-response';
    title = 'Response';
    content = (
      <SettingsSurveyResponse
        condition={condition}
        setCondition={setCondition}
        setPreventClose={setPreventClose}
      />
    );
  }

  return (
    <>
      <Dropdown
        key={condition.uid}
        className={classNames('condition-settings')}
        position={['center right', 'center left']}
        offsetX={0}
        open={isOpen}
        closeOnDocumentClick={isOpen && !preventClose}
        onClose={onClose}
        contentStyle={{zIndex: 1002}}
        {...rest}>
        <div className="condition-settings-wrapper">
          <div className="condition-settings-header">
            <div className="condition-settings-title">
              <div className={classNames('icon-wrapper', iconClassName)}>
                <i className={icon} />
              </div>
              {title}
            </div>
            <i className="icon-close" onClick={onClose} />
          </div>
          <Divider />
          <div className="condition-settings-items">{content}</div>
        </div>
      </Dropdown>
    </>
  );
};

EditDropdown.propTypes = propTypes;

export default EditDropdown;
