import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {couponService} from 'services';
import {Swaler} from 'swaler';

const logger = new Swaler('useFetchCoupon');

export const useFetchCoupon = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [coupon, setCoupon] = useState(null);

  async function fetch(code) {
    setIsFetching(true);

    try {
      const coupon = await couponService.fetchCheckoutCoupon(code);

      if (coupon === -1) {
        setCoupon(undefined);
      } else {
        setCoupon(coupon);
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetch checkout coupon data failed with error ', code);
      toastDanger([title, message], {actions, toastId: 'fetch-coupon-failed'});
      setCoupon(undefined);
    } finally {
      setIsFetching(false);
    }
  }

  function reset() {
    setCoupon(null);
  }

  return {
    fetch,
    reset,
    coupon,
    isFetching,
  };
};
