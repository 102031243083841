import Axios from 'axios';
import {generalSelector} from 'selectors';

export const TAG_CONTEXT_REQUEST = 'REQUEST';
export const TAG_CONTEXT_EVOLUTION = 'EVOLUTION';
export const TAG_CONTEXT_VIEW = 'VIEW';
export const TAG_CONTEXT_USER = 'USER';

// Endpoint
const EP_TAG_GET = '/tag';
const EP_TAG_CREATE = '/tag';
const EP_TAG_UNASSIGN = '/tag/unassign';
const EP_TAG_ASSIGN = '/tag/assign';
const EP_TAG_UPDATE = (tagId) => `/tag/${tagId}`;
const EP_TAG_UPDATE_INDEX_ORDER = (tagId) => `/tag/${tagId}/index-order`;
const EP_TAG_DELETE = (tagId) => `/tag/${tagId}`;

// Method
export const getTags = (filter = {}) => {
  const {relations, contexts} = filter;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_TAG_GET, {
    params: {relations, contexts, projectId},
  }).then((response) => response.data);
};

export const createTag = (data) => {
  const {name, color, requestId, texts, context} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_TAG_CREATE, {
    name,
    color,
    requestId,
    projectId,
    texts,
    context,
  }).then((response) => response.data);
};

export const unAssignTag = (data) => {
  const {requestId, evolutionId, tagId} = data;

  return Axios.post(EP_TAG_UNASSIGN, {requestId, evolutionId, tagId}).then(
    (response) => response.data
  );
};

export const assignTag = (data) => {
  const {requestId, evolutionId, tagId} = data;

  return Axios.post(EP_TAG_ASSIGN, {requestId, evolutionId, tagId}).then(
    (response) => response.data
  );
};

export const updateTag = (tagId, data) => {
  const {name, color, showInRoadmap, texts} = data;

  return Axios.put(EP_TAG_UPDATE(tagId), {
    name,
    color,
    showInRoadmap,
    texts,
  }).then((response) => response.data);
};

export const updateTagIndexOrder = (tagId, data) => {
  const {indexOrder, projectId} = data;

  return Axios.put(EP_TAG_UPDATE_INDEX_ORDER(tagId), {
    indexOrder,
    projectId,
  }).then((response) => response.data);
};

export const deleteTag = (tagId) => {
  return Axios.delete(EP_TAG_DELETE(tagId)).then((response) => response.data);
};
