import classNames from 'classnames';
import Avatar from 'components/Avatar';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import PokeBlock from 'components/PokeBlock';
import {toastDanger} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasUsernameGenerated} from 'helpers/utils';
import React, {useEffect, useRef, useState} from 'react';
import PreviewTemplate from 'scenes/Pushes/components/ModalCreatePoke/components/PreviewTemplate';
import ModalJimerProfile from 'scenes/Users/components/ModalJimerProfile';
import {Pagination} from 'scenes/Users/components/Pagination';
import {issueService} from 'services';
import {EVOLUTION_STATE_LIVE} from 'services/evolution';
import {
  ISSUE_TYPE_ELEMENT_NOT_FOUND,
  ISSUE_TYPE_ELEMENT_NOT_VISIBLE,
  ISSUE_TYPE_MULTIPLE_ELEMENTS_FOUND,
} from 'services/issue';
import {Swaler} from 'swaler';
import './_Styles.scss';

const ISSUES_PER_PAGE = 8;

const logger = new Swaler('IssueReporting');

const IssueReporting = ({evolution, refetch}) => {
  const [previewedStepId, setPreviewedStepId] = useState(null);
  const [shownJimerId, setShownJimerId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [issues, setIssues] = useState({
    data: [],
    skip: 0,
    take: ISSUES_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);

  const cancelCtrl = useRef();

  useEffect(() => {
    fetchIssues(0);
  }, []);

  const fetchIssues = async (pageParam = page) => {
    const oldPage = page;

    setIsLoading(true);
    setPage(pageParam);
    cancelCtrl.current = new AbortController();
    try {
      const issues = await issueService.getIssues(
        {
          evolutionId: evolution.uid,
          take: ISSUES_PER_PAGE,
          skip: pageParam * ISSUES_PER_PAGE,
        },
        {signal: cancelCtrl.current.signal}
      );

      if (evolution.countIssues > 0) {
        refetch();
      }

      setIssues(issues);
      setIsLoading(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching issues failed with error ', code);
      setIsLoading(false);
      setPage(oldPage);
      toastDanger([title, message], {actions});
    }
  };

  const isDraft = evolution.isDraft;
  const isLive =
    isDraft !== true &&
    (evolution?.state === EVOLUTION_STATE_LIVE || evolution?.state == null);

  if (isLoading === true) {
    return (
      <div className="loader-wrapper">
        <DefaultLoader width={24} />
      </div>
    );
  }
  if (issues.data.length === 0) {
    return (
      <EmptyStateBlock
        img={EmptyStateImgs.EmptyIssues}
        title="No Issues Detected"
        description={
          isLive
            ? "Your experience is doing great! If anything pops up, we'll inform you here."
            : "Your experience is not live. We'll inform you here if anything pops up once it's live."
        }
      />
    );
  }
  return (
    <PokeBlock className="issue-reporting" title="Issue reporting">
      <div className={classNames('table-issues', {'is-loading': false})}>
        <div className="header">
          <div>User</div>
          <div>Step</div>
          <div>Issue</div>
          <div>URL</div>
          <div>Date</div>
        </div>

        {isLoading === false && (
          <div className="list">
            {issues.data?.map((issue) => {
              const {step, url, createdAt, type} = issue;
              console.log(issue.jimer);
              const username =
                issue.jimer == null
                  ? null
                  : issue.jimer.externalUsername != null &&
                    issue.jimer.externalUsername.length !== 0
                  ? issue.jimer.externalUsername
                  : issue.jimer.username;
              const isAnonymous =
                issue.jimer == null
                  ? true
                  : hasUsernameGenerated(username) === true &&
                    !issue.jimer.identifyToken &&
                    !issue.jimer.externalUid;

              return (
                <div key={issue.uid} className="row-issue">
                  {issue.jimer != null ? (
                    <div
                      class="issue-jimer"
                      onClick={() => {
                        setShownJimerId(issue.jimer.uid);
                      }}>
                      <Avatar jimer={issue.jimer} />
                      <div className="username-wrapper">
                        <div className="username">
                          {isAnonymous ? (
                            'Anonymous'
                          ) : !!username ? (
                            username
                          ) : (
                            <em>username</em>
                          )}
                        </div>
                        {isAnonymous === true ? (
                          <div className="anonymous-name">{username}</div>
                        ) : issue.jimer.identifyToken ||
                          issue.jimer.externalUid ? (
                          <div className="anonymous-name">
                            {issue.jimer.identifyToken ||
                              issue.jimer.externalUid}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="body-3 n-700">Deleted user</div>
                  )}
                  <div
                    className={classNames('step-name', {
                      'is-clickable': step?.uid != null,
                    })}
                    onClick={() => {
                      if (step?.uid != null) {
                        setPreviewedStepId(step?.uid);
                      }
                    }}>
                    {step != null ? step?.name || 'Unnamed step' : 'N/A'}
                  </div>
                  <div className="description">
                    {type === ISSUE_TYPE_ELEMENT_NOT_FOUND ? (
                      <span>
                        Could not find element with selector{' '}
                        <b>{issue.querySelector}</b>
                      </span>
                    ) : type === ISSUE_TYPE_ELEMENT_NOT_VISIBLE ? (
                      <span>
                        Element with selector <b>{issue.querySelector}</b> was
                        found but not visible
                      </span>
                    ) : type === ISSUE_TYPE_MULTIPLE_ELEMENTS_FOUND ? (
                      <span>
                        Multiple elements were found with selector{' '}
                        <b>{issue.querySelector}</b>
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <Tooltip
                    className="tooltip-issue-url"
                    trigger={<div className="url">{url}</div>}
                    offsetY={4}
                    offsetX={-40}>
                    {url}
                  </Tooltip>
                  <Tooltip
                    className="tooltip-issue-date"
                    trigger={
                      <div className="url">{dayjs(createdAt).fromNow()}</div>
                    }
                    offsetY={4}
                    offsetX={-40}>
                    <div className="date">
                      {dayjs(createdAt).format('DD MMMM YYYY - HH:mm')}
                    </div>
                  </Tooltip>
                </div>
              );
            })}
            {issues.total > ISSUES_PER_PAGE && (
              <Pagination
                page={page}
                take={issues.take}
                total={issues.total}
                onChange={(page) => fetchIssues(page)}
                isLoading={isLoading}
                label="issues"
              />
            )}
          </div>
        )}
      </div>

      {shownJimerId != null && (
        <ModalJimerProfile
          isOpen={shownJimerId != null}
          onRequestClose={() => setShownJimerId(null)}
          jimerId={shownJimerId}
        />
      )}

      {previewedStepId != null && (
        <Modal
          title={<div className="text">{evolution.title}</div>}
          className="modal-preview-poke"
          isOpen={evolution != null}
          onRequestClose={() => setPreviewedStepId(null)}>
          <PreviewTemplate template={evolution} stepId={previewedStepId} />
        </Modal>
      )}
    </PokeBlock>
  );
};

export default IssueReporting;
