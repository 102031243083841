import React from 'react';
import './_Styles.scss';
import {Modal} from 'components/Modal';
import Button from 'components/Button';
import classNames from 'classnames';

const ListElem = ({type, title, subtitle}) => {
  let icon = '';

  if (type === 'safe') {
    icon = 'isax isax-tick-circle5';
  } else if (type === 'warning') {
    icon = 'isax isax-info-circle5';
  }

  return (
    <div className="list-elem">
      <div className={classNames('list-elem-icon', type)}>
        <i className={icon} />
      </div>
      <div className="list-elem-content">
        <div className="list-elem-title subtitle-4 n-800">{title}</div>
        <div className="list-elem-subtitle body-4 n-700">{subtitle}</div>
      </div>
    </div>
  );
};

const LiveEditWarningModal = ({isOpen, onRequestClose, closable = false}) => {
  return (
    <Modal
      className="live-edit-warning-modal"
      isOpen={isOpen}
      onRequestClose={closable === true ? onRequestClose : () => {}}
      title={
        closable
          ? 'Editing Steps in a live Experience'
          : 'What’s safe to edit in a live Experience?'
      }
      content="You have unsaved changes. Are you sure you want to leave?"
      closable={closable}
      footer={
        closable !== true ? (
          <Button primary onClick={onRequestClose}>
            I understand
          </Button>
        ) : null
      }
    >
      <div className="content">
        <div className="body-3 n-700">
          Certain changes you need to be aware of and how they will affect your
          experience analytics:
        </div>
        <ListElem
          type="safe"
          title="Fixing typos or small grammar mistakes"
          subtitle="Doesn’t affect your analytics."
        />
        <ListElem
          type="safe"
          title="Small changes in step styles"
          subtitle="Doesn’t affect your analytics."
        />
        <ListElem
          type="warning"
          title="Reordering & adding new steps"
          subtitle="Can affect the current funnel data shown."
        />
        <ListElem
          type="warning"
          title="Changing step conditions and logic"
          subtitle="Can affect the current funnel data shown."
        />
        <ListElem
          type="warning"
          title="Editing step settings"
          subtitle="Can modify how users interact with your experience."
        />
        <ListElem
          type="warning"
          title="Editing CTA actions"
          subtitle="Can impact current analytics for button clicks."
        />
        <ListElem
          type="warning"
          title="Editing Snooze Actions"
          subtitle="Only affects new users. Previous users who have snoozed will retain their last snooze action settings."
        />
      </div>
    </Modal>
  );
};

export default LiveEditWarningModal;
