import Button from 'components/Button';
import React from 'react';
import './_styles.scss';

export default class DevButton extends React.Component {
  render() {
    return (
      <div className="_dev-button">
        <main>
          <Button primary iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button primary disabled iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button disabled iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button tertiary iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button tertiary disabled iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button iconOnly>
            <i className="icon-plus"></i>
          </Button>
          <Button iconOnly disabled>
            <i className="icon-plus"></i>
          </Button>

          <Button primary thin iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            primary
            disabled
            thin
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button thin iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button disabled thin iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button tertiary thin iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            tertiary
            thin
            disabled
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button iconOnly thin>
            <i className="icon-plus"></i>
          </Button>
          <Button iconOnly thin disabled>
            <i className="icon-plus"></i>
          </Button>

          <Button primary danger iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            primary
            disabled
            danger
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button danger iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button disabled danger iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button tertiary danger iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            tertiary
            danger
            disabled
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button iconOnly danger>
            <i className="icon-plus"></i>
          </Button>
          <Button iconOnly danger disabled>
            <i className="icon-plus"></i>
          </Button>

          <Button
            primary
            danger
            thin
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            primary
            disabled
            danger
            thin
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button danger thin iconLeft="icon-plus" iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            disabled
            danger
            thin
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            tertiary
            danger
            thin
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button
            tertiary
            danger
            thin
            disabled
            iconLeft="icon-plus"
            iconRight="icon-plus">
            Create Poke
          </Button>
          <Button iconOnly thin danger>
            <i className="icon-plus"></i>
          </Button>
          <Button iconOnly thin danger disabled>
            <i className="icon-plus"></i>
          </Button>
        </main>
      </div>
    );
  }
}
