import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useSelector} from 'react-redux';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {generalSelector} from 'selectors';
import {
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_TITLE,
  F_STEP_CHECKLIST_STEP_EXPAND_TASK_ON_CLICK,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import './_Styles.scss';

const expandTaskOptions = [
  {label: 'Hover', value: false},
  {label: 'Click', value: true},
];

const ChecklistList = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {
    selectedStep: step,
    updateStep,
    setSelectedBlockType,
    setSelectedChecklistItemId,
  } = useContext(BuilderContext);

  const [isEditingTitle, setIsEditingTitle] = useState(null);

  const inputRef = useRef();

  useEffect(() => {
    if (isEditingTitle != null && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [inputRef, isEditingTitle]);

  if (step == null) {
    return <></>;
  }

  const {blocks = []} = step;

  const taskItems = step?.blocks.filter(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM
  );

  const sortedTaskItems = taskItems.sort((a, b) => {
    const valueA = a.value;
    const valueB = b.value;

    const [_, indexA] = valueA.split('|-|');
    const [__, indexB] = valueB.split('|-|');

    return indexA - indexB;
  });

  const onDragStart = ({source, type}) => {};

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: {index: sourceIndex},
      destination: {index: destinationIndex},
    } = result;

    const newTaskItems = [...sortedTaskItems];
    const [removed] = newTaskItems.splice(sourceIndex, 1);
    newTaskItems.splice(destinationIndex, 0, removed);

    const newTaskItemsWithIndex = newTaskItems.map((item, index) => {
      const [value, _] = item.value.split('|-|');

      return {
        ...item,
        value: `${value}|-|${index}`,
      };
    });

    updateStep(step.uid, {
      blocks: step.blocks.map((block) => {
        if (block.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM) {
          const newBlock = newTaskItemsWithIndex.find(
            (b) => b.uid === block.uid
          );

          return newBlock;
        }

        return block;
      }),
    });
  };

  const updateTaskTitle = (value) => {
    updateStep(step.uid, {
      blocks: step.blocks.map((block) => {
        if (block.uid === isEditingTitle) {
          const [_, index] = block.value.split('|-|');

          return {
            ...block,
            value: `${value}|-|${index}`,
          };
        }

        return block;
      }),
    });
  };

  const handleAddTask = () => {
    const newTaskId = uuidv4();

    const existingTaskItemBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM
    );
    const existingCheckboxBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX
    );
    const existingTitleBlock = blocks.find((b) => b.type === BLOCK_TYPE_TITLE);
    const existingPrimaryCtaBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_PRIMARY_CTA
    );

    const newBlocks = [
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM,
          project.defaultTheme
        ),
        ...(existingTaskItemBlock != null && {
          style: existingTaskItemBlock.style,
        }),
        uid: newTaskId,
        value: `Task #${taskItems.length + 1}|-|${taskItems.length}`,
      },
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
          project.defaultTheme
        ),
        ...(existingCheckboxBlock != null && {
          style: existingCheckboxBlock.style,
        }),
        parentBlockId: newTaskId,
      },
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
          project.defaultTheme
        ),
        ...(existingTitleBlock != null && {
          style: existingTitleBlock.style,
        }),
        parentBlockId: newTaskId,
      },
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
          project.defaultTheme
        ),
        ...(existingPrimaryCtaBlock != null && {
          style: existingPrimaryCtaBlock.style,
        }),
        parentBlockId: newTaskId,
        value: 'Go',
      },
    ];

    updateStep(step.uid, {
      blocks: [...blocks, ...newBlocks],
    });

    setSelectedBlockType(BLOCK_TYPE_CHECKLIST_TASK_ITEM);
    setSelectedChecklistItemId(newTaskId);
  };

  const handleDeleteTask = (taskId) => {
    const newBlocks = blocks.filter(
      (b) => b.uid !== taskId && b.parentBlockId !== taskId
    );

    updateStep(step.uid, {
      blocks: newBlocks,
    });
  };

  const isOpenedOnClick = hasFlag(
    F_STEP_CHECKLIST_STEP_EXPAND_TASK_ON_CLICK,
    step.stepFlags
  );

  return (
    <div className="block-settings checking-list">
      <div className="section">
        <div className="section-title">Behavior</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Expand task</div>
            <div className="section-item-content">
              <RadioGroup
                options={expandTaskOptions}
                value={isOpenedOnClick}
                onSelect={(value) => {
                  updateStep(step.uid, {
                    stepFlags: value
                      ? addFlag(
                          F_STEP_CHECKLIST_STEP_EXPAND_TASK_ON_CLICK,
                          step.stepFlags
                        )
                      : removeFlag(
                          F_STEP_CHECKLIST_STEP_EXPAND_TASK_ON_CLICK,
                          step.stepFlags
                        ),
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Task List</div>
        <div className="section-content">
          <div className="section-item">
            <DragDropContext
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
              style={{overflow: 'auto'}}>
              <Droppable
                droppableId="blocks"
                type="blocks"
                direction="vertical">
                {(dropProvided) => (
                  <div
                    className="task-list-wrapper"
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}>
                    {taskItems?.map((item, index) => {
                      const [name] = item.value.split('|-|');

                      return (
                        <Draggable
                          key={item.uid}
                          draggableId={item.uid}
                          index={index}>
                          {(dragProvided, snapshot) => (
                            <div
                              className={classNames('item-wrapper', {
                                'is-dragging': snapshot.isDragging,
                                'is-last': index === taskItems.length - 1,
                                'is-editing': isEditingTitle === item.uid,
                              })}
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}>
                              <div
                                className="dragger"
                                isDragging={snapshot.isDragging}
                                {...dragProvided.dragHandleProps}>
                                <i className="icon-menu-vertical"></i>
                                <i className="icon-menu-vertical"></i>
                              </div>
                              <div className="item-content">
                                {isEditingTitle === item.uid ? (
                                  <InputGroup
                                    ref={inputRef}
                                    className="task-title-input"
                                    value={name}
                                    onChange={(e) => {
                                      updateTaskTitle(e.target.value);
                                    }}
                                    onBlur={() => setIsEditingTitle(null)}
                                    onPressEnter={() => setIsEditingTitle(null)}
                                  />
                                ) : (
                                  name
                                )}

                                <div className="actions">
                                  <div
                                    className="action-icon-wrapper"
                                    onClick={() => setIsEditingTitle(item.uid)}>
                                    <i className="isax isax-edit-2" />
                                  </div>
                                  <div
                                    className="action-icon-wrapper"
                                    onClick={() => handleDeleteTask(item.uid)}>
                                    <i className="isax isax-trash r-400" />
                                  </div>
                                </div>
                              </div>
                              {dragProvided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="section-item">
            <Button
              thin
              secondary
              className="add-task-btn"
              onClick={handleAddTask}
              iconLeft="icon-plus">
              Add task
            </Button>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ChecklistList;
