import {generalActions} from 'actions';
import {useDispatch} from 'react-redux';
import {projectService} from 'services';

export const useUpdateAddons = () => {
  const dispatch = useDispatch();

  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  async function update() {
    // Addon : White Label
    // - Done by refetching the project
    // Addon : Extra mau
    // - Done by refetching the project (should include the subscription also)

    const updatedProject = await projectService.getProject();

    uptProject({
      ...updatedProject,
    });
  }

  return {
    update,
  };
};
