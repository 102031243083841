import Axios from 'axios';

// Endpoints
const EP_ACCOUNTS_GET = '/gtm/accounts';
const EP_CONTAINERS_GET = '/gtm/containers';
const EP_TAG_PUBLISH = '/gtm/publish';

// Methods
export const getAccounts = ({accessToken}) => {
  return Axios.post(EP_ACCOUNTS_GET, {accessToken}).then(
    (response) => response.data
  );
};
export const getContainers = ({accessToken, accountPath}) => {
  return Axios.post(EP_CONTAINERS_GET, {accessToken, accountPath}).then(
    (response) => response.data
  );
};
export const publishTag = ({
  accessToken,
  containerPath,
  projectId,
  code,
  shouldPublish,
}) => {
  return Axios.post(EP_TAG_PUBLISH, {
    accessToken,
    containerPath,
    projectId,
    code,
    shouldPublish,
  }).then((response) => response.data);
};
