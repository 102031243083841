import Store from 'store/configure';

export const getUser = (store = Store().getState()) => {
  return store.general.user;
};
export const getProject = (store = Store().getState()) => {
  return store.general.projects.find((p) => p.uid === store.general.project);
};
export const getProjects = (store = Store().getState()) => {
  return store.general.projects;
};
export const getProjectMember = (store = Store().getState()) => {
  return getProject(store)?.member;
};
export const getSnoozeJimerLimitUntil = (store = Store().getState()) => {
  return store.general.snoozeJimerLimitUntil;
};
export const getProjectSubscription = (store = Store().getState()) => {
  return getProject(store).subscription;
};
