import {dataActions, generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {Environment} from 'conf/env';
import {hasFlag} from 'helpers/bitwise';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import querystring from 'query-string';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_HOME} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {projectService, rolesService, segmentService} from 'services';
import {isEligibleToTrial, isPaying, isTrying} from 'services/project';
import {F_IS_TRIALING, hasSaasMantraPlan} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('Switch');

const Switch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {projectId} = useRouteMatch().params;
  const {pushAttributes} = useJimoIdentify({forceIsIdentified: true});

  const projects = useSelector(generalSelector.getProjects);

  const [loading, setLoading] = useState(true);

  const project = projects.find((p) => p.uid === projectId);
  const classNames = classnames('s-switch fade-in-right', {
    'has-project-not-found': project == null,
  });

  const fetchProject = async () => {
    if (!projectId) return;
    try {
      const project = await projectService.getProject(projectId);

      dispatch(generalActions.uptProject(project, project.uid));
      dispatch(generalActions.setProject(project.uid));
      await initializeData(project);
    } catch (err) {
      logger.error(
        `Fetching project failed when switching (projectId=${projectId})`,
        err
      );
      setLoading(false);
    }
  };

  const initializeData = async (project) => {
    const segments = await segmentService.getSegments({
      projectId: project.uid,
    });
    const customAttributes = await segmentService.getSegmentCustomAttributes(
      project.uid
    );
    const customRoles = await rolesService.getCustomRoles(project);
    const builtInRoles = await rolesService.getBuiltInRoles();

    if (window.$crisp) {
      window.$crisp.push([
        'set',
        'session:data',
        [
          [
            ['project-id', project.uid],
            ['project-name', project.name],
          ],
        ],
      ]);
    }

    dispatch(dataActions.setSegments(segments));
    dispatch(dataActions.setCustomAttributes(customAttributes));
    dispatch(dataActions.setCustomRoles(customRoles));
    dispatch(dataActions.setBuiltInRoles(builtInRoles));

    if (Environment.NODE_ENV === 'production' && project != null) {
      if (project != null && project.subscription != null) {
        pushAttributes({
          plan: project.subscription.plan,
          isTrialing: hasFlag(F_IS_TRIALING, project.subscription.extraFlags),
          isSaasMantra: hasSaasMantraPlan(project),
          hasReachedMau: project.mau >= project.thresholdJimers,
          hasWhiteLabeling: project.whiteLabeling,
          isPaying: isPaying(project.subscription),
          hasTrialEnded:
            isEligibleToTrial() === false && isTrying(project.subscription),
          hasInstalled: project.snippetInstalledAt != null,
        });
      }
    }
  };

  useEffect(() => {
    const qs = querystring.parse(location.search);
    const {redirectTo = ROUTE_HOME} = qs;

    fetchProject().then(() => {
      setTimeout(() => history.push(redirectTo), 700);
    });
  }, []);

  if (loading) {
    return (
      <div className={classNames}>
        <Loader width="24px" />
        <div>
          Loading <span className="highlight">{project?.name}</span>...
        </div>
      </div>
    );
  }

  return (
    <div className={classNames}>
      Ups... we could not find the project you are trying to switch to!
      <Button
        light
        iconRight="icon-chevron-right"
        rounded={false}
        thin
        onClick={() => history.push(ROUTE_HOME)}>
        Go back to safety
      </Button>
    </div>
  );
};

export default Switch;
