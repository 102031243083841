/* eslint-disable jsx-a11y/anchor-is-valid */
import {PlateElement, useElement} from '@udecode/plate-common';
import {useLink} from '@udecode/plate-link';
import classNames from 'classnames';
import React, {forwardRef} from 'react';
import './_Styles.scss';

export const LinkElement = forwardRef(
  ({children, className, ...props}, ref) => {
    const element = useElement();
    const {props: linkProps} = useLink({element});

    return (
      <PlateElement
        asChild
        className={classNames('link-element', className)}
        ref={ref}
        {...linkProps}
        {...props}>
        <a>{children}</a>
      </PlateElement>
    );
  }
);
