import {useGoogleLogin} from '@react-oauth/google';
import Button from 'components/Button';
import logoGoogle from './logo-google.svg';
import './_Styles.scss';

export default function ButtonGoogle(props) {
  const {loading, onClick, onSuccess, onFailure, disabled, children, ...rest} =
    props;
  const doLogin = useGoogleLogin({
    onSuccess,
    onError: onFailure,
  });

  return (
    <Button
      className="btn-google-login"
      onClick={() => {
        doLogin();
      }}
      disabled={disabled}
      loading={loading}
      iconRight="icon-chevron-right"
      {...rest}>
      <>
        {loading === false && <img src={logoGoogle} width={16} alt="" />}
        {children}
      </>
    </Button>
  );
}
