import classnames from 'classnames';
import Button from 'components/Button';
import {bool, func} from 'prop-types';
import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import './_Styles.scss';

const propTypes = {
  onSave: func,
  onCancel: func,
  isOpen: bool,
  isSaving: bool,
};

const defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  isOpen: false,
  isSaving: false,
};

const Saver = ({onSave, onCancel, isOpen, isSaving}) => {
  const history = useHistory();

  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (isOpen === true) {
      history.block(() => {
        if (shake === true) return;

        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 1000);
        return false;
      });
    } else {
      history.block(() => true);
    }
  }, [isOpen]);

  if (isOpen === false) {
    return <></>;
  }
  return (
    <div className={classnames('settings-appearance-saver sw-4', {shake})}>
      {shake === true && (
        <Helmet>
          <body data-shaking="true" />
        </Helmet>
      )}
      <div className="content-wrapper">
        <span className="subtitle-3">You have unsaved changes</span>
        <span className="body-3 n-700">
          Save your changes to update your data
        </span>
      </div>
      <div className="buttons-wrapper">
        {onCancel != null && (
          <Button cta disabled={isSaving} onClick={onCancel}>
            Reset
          </Button>
        )}
        <Button cta primary loading={isSaving} onClick={onSave}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

Saver.propTypes = propTypes;
Saver.defaultProps = defaultProps;

export default Saver;
