import React, {useState} from 'react';
import './_Styles.scss';
import {Modal} from 'components/Modal';
import {bool, func} from 'prop-types';
import TrackEventEmptyState from './components/TrackEventEmptyState';
import ImportFromSegmentSetup from './components/ImportFromSegmentSetup';
import EventsList from './components/EventsList';
import classNames from 'classnames';
import {useQuery} from 'react-query';
import {eventService, segmentioService} from 'services';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import DefaultLoader from 'components/Loader';
import {EVENT_SOURCE_SEGMENT} from 'services/event';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  onEventTypeClick: func.isRequired,
};

const defaultProps = {};

const MODE_IMPORT = 'IMPORT';

const MODE_DEFAULT = 'DEFAULT';

const ModalAddStepEvent = ({
  isOpen,
  onRequestClose,
  onCreateOnTheFlyEvent,
  onSelectEvent,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  let content = null;

  let {data: segmentioEvents = [], isLoading: isLoadingSegmentioEvents} =
    useQuery({
      queryKey: 'segmentioEvents',
      queryFn: () => segmentioService.getSegmentioEvents(project.uid),
      refetchOnWindowFocus: false,
    });

  let {data: events = [], isLoading: isLoadingEvents} = useQuery({
    queryKey: 'trackedEvents',
    queryFn: () =>
      eventService.getEvents({
        relations: ['conditions'],
      }),
    refetchOnWindowFocus: false,
  });

  const isLoading = isLoadingSegmentioEvents || isLoadingEvents;
  const hasEvents = segmentioEvents.length > 0 || events.length > 0;

  if (isLoadingSegmentioEvents || isLoadingEvents) {
    content = (
      <div className="loader-wrapper">
        <DefaultLoader />
      </div>
    );
  } else {
    content = (
      <EventsList
        segmentioEvents={segmentioEvents}
        events={events.filter((event) => event.source !== EVENT_SOURCE_SEGMENT)}
        onCreateOnTheFlyEvent={onCreateOnTheFlyEvent}
        onSelectEvent={onSelectEvent}
      />
    );
  }

  return (
    <>
      <Modal
        className={classNames('modal-add-step-event', {
          'mode-empty-state': hasEvents === false && isLoading === false,
        })}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={
          <>
            <div className="icon-wrapper">
              <i className="icon-plus-rounded" />
            </div>
            Track a new event
          </>
        }>
        {content}
      </Modal>
    </>
  );
};

ModalAddStepEvent.propTypes = propTypes;
ModalAddStepEvent.defaultProps = defaultProps;

export default ModalAddStepEvent;
