import {
  useMarkToolbarButton,
  useMarkToolbarButtonState,
} from '@udecode/plate-common';
import classNames from 'classnames';
import React, {forwardRef} from 'react';
import './_Styles.scss';

const ToolbarButton = forwardRef(
  (
    {children, className, isDropdown, pressed, size, variant, ...props},
    ref
  ) => {
    if (typeof pressed === 'boolean') {
      return (
        <div
          className={classNames('toolbar-button', {
            pressed: pressed,
          })}
          disabled={props.disabled}
          type="single"
          value="single">
          <div
            className={className}
            ref={ref}
            value={pressed ? 'single' : ''}
            {...props}>
            {children}
          </div>
        </div>
      );
    }

    return (
      <div ref={ref} className="toolbar-button" {...props}>
        {children}
      </div>
    );
  }
);

ToolbarButton.displayName = 'ToolbarButton';

const MarkToolbarButton = forwardRef(({clear, nodeType, ...rest}, ref) => {
  const state = useMarkToolbarButtonState({clear, nodeType});
  const {props} = useMarkToolbarButton(state);

  return <ToolbarButton ref={ref} {...props} {...rest} />;
});

export default MarkToolbarButton;
