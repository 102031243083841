import {generalActions} from 'actions';
import Button from 'components/Button';
import Input from 'components/Input';
import {ModalConfirm, ModalConfirmV2} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_LOGOUT} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import ModalChangeEmail from 'scenes/Settings/components/ModalChangeEmail';
import {generalSelector} from 'selectors';
import {meService} from 'services';
import {Swaler} from 'swaler';
import ModalChangePassword from '../../components/ModalChangePassword';
import Saver from '../../components/Saver';
import './_Styles.scss';

const logger = new Swaler('Settings/Profile');

export default function SettingsProfile() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => generalSelector.getUser(state));

  const setUser = (user) => dispatch(generalActions.setUser(user));
  const uptUser = (user) => dispatch(generalActions.uptUser(user));

  const [inputUsername, setInputUsername] = useState(user.username);
  const [inputFirstName, setInputFirstName] = useState(user.firstName);
  const [inputLastName, setInputLastName] = useState(user.lastName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [deletingAvatar, setDeletingAvatar] = useState(false);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [showModalConfirmDeleteAvatar, setShowModalConfirmDeleteAvatar] =
    useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalChangeEmail, setShowModalChangeEmail] = useState(false);

  const refInputFile = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const userUpdated = await meService.updateMe({
        username: inputUsername,
        firstName: inputFirstName,
        lastName: inputLastName,
      });

      setIsSubmitting(false);
      toastSuccess('User updated!', {toastId: 'user-updated'});
      setUser(userUpdated);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting project failed with error ', code);
      setIsSubmitting(false);
      return toastDanger([title, message], {actions});
    }
  };
  const handleInputFileChange = async (e) => {
    const file = e.target.files.length !== 0 ? e.target.files[0] : null;

    if (!file) return;
    setUploadingAvatar(true);
    try {
      const uploadedFile = await meService.uploadAvatar({file});
      setUploadingAvatar(false);
      uptUser({avatarUrl: uploadedFile.publicUrl});
      toastSuccess(['Avatar uploaded', 'Your avatar has been updated!'], {
        toastId: 'avatar-uploaded',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setUploadingAvatar(false);
      logger.error('Upload user avatar failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleDeleteAvatar = async () => {
    setDeletingAvatar(true);
    try {
      await meService.deleteAvatar();
      setDeletingAvatar(false);
      uptUser({avatarUrl: null});
      setShowModalConfirmDeleteAvatar(false);
      toastSuccess(['Avatar deleted', 'Your avatar has been deleted!'], {
        toastId: 'avatar-deleted',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setDeletingAvatar(false);
      setShowModalConfirmDeleteAvatar(false);
      logger.error('Deleting user avatar failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };
  const handleDelete = async () => {
    setIsDeleting(true);
    setShowModalConfirmDelete(false);

    try {
      await meService.deleteMe();
      toastSuccess('Account deleted! See you later 😢', {
        toastId: 'account-deleted',
      });
      history.push(ROUTE_LOGOUT);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsDeleting(false);
      logger.error('Deleting account failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };
  const hasChanges = () => {
    return (
      user.username !== inputUsername ||
      user.firstName !== inputFirstName ||
      user.lastName !== inputLastName
    );
  };

  return (
    <SettingsBody className="s-settings-profile">
      <div className="title-3">My account</div>
      <div className="settings-card card-infos">
        <div className="profile-user-avatar">
          <div className="left-wrapper">
            <div
              className="user-avatar"
              style={
                user.avatarUrl != null
                  ? {
                      backgroundImage: `url(${user.avatarUrl})`,
                    }
                  : {
                      backgroundColor: '#85B1FF',
                    }
              }>
              {user.avatarUrl == null && user.username[0].toUpperCase()}
            </div>
            <div className="label-wrapper">
              <span className="subtitle-3">Avatar</span>
              <small className="body-3 n-700">
                PNG or JPEG format under 500kb (56x56px)
              </small>
            </div>
          </div>
          <div className="right-wrapper">
            <input
              style={{display: 'none'}}
              type="file"
              ref={refInputFile}
              onChange={handleInputFileChange}
            />
            <Button
              iconLeft="isax isax-document-upload"
              thin
              loading={uploadingAvatar}
              disabled={deletingAvatar}
              onClick={() => refInputFile.current.click()}>
              Upload image
            </Button>
            {user.avatarUrl != null && (
              <Button
                danger
                thin
                loading={deletingAvatar}
                disabled={uploadingAvatar}
                onClick={() => {
                  setShowModalConfirmDeleteAvatar(true);
                }}>
                Remove image
              </Button>
            )}
          </div>
        </div>
        <Input
          legend="Username"
          placeholder="Username"
          onChange={({target}) => setInputUsername(target.value)}
          name="inputUsername"
          value={inputUsername}></Input>
        <Input
          legend="First name"
          placeholder="John"
          onChange={({target}) => setInputFirstName(target.value)}
          name="inputFirstName"
          value={inputFirstName}></Input>
        <Input
          legend="Last name"
          placeholder="Doe"
          onChange={({target}) => setInputLastName(target.value)}
          name="inputLastName"
          value={inputLastName}></Input>
      </div>
      <div className="settings-card card-email-password">
        <div className="profile-email">
          <div className="left-wrapper">
            <span className="subtitle-3">Email</span>
            <span className="body-3 n-700">{user.email}</span>
          </div>
          <div className="right-wrapper">
            <Button thin onClick={() => setShowModalChangeEmail(true)}>
              Change email
            </Button>
          </div>
        </div>
        <div className="profile-password">
          <div className="left-wrapper">
            <span className="subtitle-3">Password</span>
            <span className="body-3 n-700">
              {'password'
                .split('')
                .map(() => '*')
                .join('')}
            </span>
          </div>
          <div className="right-wrapper">
            <Button thin onClick={() => setShowModalChangePassword(true)}>
              Change password
            </Button>
          </div>
        </div>
      </div>
      <div className="settings-card card-delete">
        <div className="left-wrapper">
          <span className="subtitle-3 r-400">Delete account</span>
          <span className="body-3 n-700">
            Delete your Jimo account and all your projects
          </span>
        </div>
        <div className="right-wrapper">
          <Button
            primary
            thin
            danger
            loading={isDeleting}
            onClick={() => setShowModalConfirmDelete(true)}>
            Delete account
          </Button>
        </div>
      </div>
      <ModalConfirmV2
        isConfirming={deletingAvatar}
        cancelText="Cancel"
        confirmText="Remove avatar"
        title="Remove Profile Avatar?"
        className="modal-delete-avatar"
        onConfirm={handleDeleteAvatar}
        onCancel={() => setShowModalConfirmDeleteAvatar(false)}
        isOpen={showModalConfirmDeleteAvatar}
        confirmBtnProps={{danger: true}}>
        <div className="body-2 n-500">
          Proceeding will permanently remove your avatar. This action cannot be
          undone.
        </div>
      </ModalConfirmV2>
      <ModalConfirm
        className="modal-delete-account"
        onConfirm={handleDelete}
        onCancel={() => setShowModalConfirmDelete(false)}
        isOpen={showModalConfirmDelete}
        confirmBtnProps={{danger: true}}>
        <p className="subtitle-4">
          You won't be able to login and any project linked to your account will
          be deleted! <br />
          <br />
          <span className="r-400">This action is irreversible!</span>
        </p>
      </ModalConfirm>
      <ModalChangeEmail
        isOpen={showModalChangeEmail}
        title="Change email"
        small
        onRequestClose={() =>
          setShowModalChangeEmail(false)
        }></ModalChangeEmail>
      <ModalChangePassword
        isOpen={showModalChangePassword}
        title="Change password"
        small
        onRequestClose={() =>
          setShowModalChangePassword(false)
        }></ModalChangePassword>
      <Saver
        onSave={handleSubmit}
        isOpen={hasChanges()}
        isSaving={isSubmitting}></Saver>
    </SettingsBody>
  );
}
