/* eslint-disable import/no-anonymous-default-export */
import {
  GENERAL_SET_BYPASS_ONBOARDING,
  GENERAL_SET_PROJECT,
  GENERAL_SET_PROJECTS,
  GENERAL_SET_SNOOZE_JIMER_LIMIT_UNTIL,
  GENERAL_SET_USER,
  GENERAL_UPT_PROJECT,
  GENERAL_UPT_PROJECT_MEMBER,
  GENERAL_UPT_PROJECT_SUBSCRIPTION,
  GENERAL_UPT_USER,
} from 'actions/general';
import amplitude from 'amplitude-js';
import {sentryHelpers} from 'helpers';

const defaultGeneralState = {
  user: null,
  project: null,
  projects: [],
  snoozeJimerLimitUntil: null, // Date
  bypassOnboarding: false,
};

export default (state = defaultGeneralState, action) => {
  switch (action.type) {
    case GENERAL_SET_USER: {
      if (action.user != null) {
        sentryHelpers.setUserContext(action.user);
        amplitude.setUserId(action.user.uid);
        amplitude.getInstance().setUserProperties(action.user);
      }
      return {
        ...state,
        user: action.user,
      };
    }
    case GENERAL_SET_PROJECT: {
      amplitude.getInstance().setUserProperties({
        project: state.projects?.find((p) => p.uid === action.project),
      });
      return {
        ...state,
        project: action.project,
      };
    }
    case GENERAL_SET_PROJECTS: {
      return {
        ...state,
        projects: action.projects,
      };
    }
    case GENERAL_SET_SNOOZE_JIMER_LIMIT_UNTIL: {
      return {
        ...state,
        snoozeJimerLimitUntil: action.snoozeUntil,
      };
    }
    case GENERAL_UPT_USER: {
      amplitude.getInstance().setUserProperties(action.data);
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        },
      };
    }
    case GENERAL_UPT_PROJECT: {
      amplitude.getInstance().setUserProperties(action.data);
      const projectId = action.projectId || state.project;
      const projects = state.projects.map((p) =>
        p.uid === projectId ? {...p, ...action.data} : p
      );

      return {
        ...state,
        projects,
      };
    }
    case GENERAL_UPT_PROJECT_MEMBER: {
      const projects = state.projects.map((p) =>
        p.uid === state.project
          ? {...p, member: {...p.member, ...action.data}}
          : p
      );

      return {
        ...state,
        projects,
      };
    }
    case GENERAL_UPT_PROJECT_SUBSCRIPTION: {
      amplitude.getInstance().setUserProperties(action.data);

      const projects = state.projects.map((p) =>
        p.uid === state.project
          ? {...p, subscription: {...p.subscription, ...action.data}}
          : p
      );

      return {
        ...state,
        projects,
      };
    }
    case GENERAL_SET_BYPASS_ONBOARDING: {
      return {
        ...state,
        bypassOnboarding: action.bypass,
      };
    }
    default:
      return state;
  }
};
