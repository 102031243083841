import classnames from 'classnames';
import React from 'react';
import './_styles.scss';

export const BACKGROUND_TYPE_COLOR = 'color';
export const BACKGROUND_TYPE_GRADIENT = 'gradient';

export const BlockGradient = ({poke, gradientStyleOverwrite}) => {
  const {style} = poke;
  const {background} = style ?? {};
  const {type, animated, primaryColor, secondaryColor} = background ?? {};
  const gradient = gradientStyleOverwrite ?? [primaryColor, secondaryColor];

  if (type !== 'gradient') {
    return <></>;
  }

  return animated === true ? (
    <>
      <div
        className={classnames(
          'poke-block-gradient gradient-big is-animated',
          {}
        )}
        style={{
          backgroundImage: `linear-gradient(${
            animated === true ? '213.36deg' : '180deg'
          }, ${gradient[0]}, transparent)`,
          backgroundColor: gradient[1],
        }}></div>
    </>
  ) : (
    <></>
  );
};
