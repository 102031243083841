import classnames from 'classnames';
import Button from 'components/Button';
import React from 'react';
import {cssTransition, toast} from 'react-toastify';
import './_Styles.scss';

const TRANSITIONS = {
  'top-left': cssTransition({
    enter: 'fade-in-left',
    exit: 'fade-out-left',
    duration: 250,
  }),
  'top-center': cssTransition({
    enter: 'fade-in-top',
    exit: 'fade-out-top',
    duration: 250,
  }),
  'top-right': cssTransition({
    enter: 'fade-in-right',
    exit: 'fade-out-right',
    duration: 250,
  }),
  'bottom-left': cssTransition({
    enter: 'fade-in-left',
    exit: 'fade-out-left',
    duration: 250,
  }),
  'bottom-center': cssTransition({
    enter: 'fade-in-bottom',
    exit: 'fade-out-bottom',
    duration: 250,
  }),
  'bottom-right': cssTransition({
    enter: 'fade-in-right',
    exit: 'fade-out-right',
    duration: 250,
  }),
};
const DEFAULT_POSITION = toast.POSITION.BOTTOM_CENTER;

class Toast extends React.Component {
  _getClassName = (className) => {
    return classnames('toast-content', className);
  };
  getCenterElementFromChildren = () => {
    const {children} = this.props;
    const [centerElement] = children;

    if (typeof children === 'string') return children;
    return centerElement;
  };
  getSecondLineFromChildren = () => {
    const {children} = this.props;
    const [, ...secondline] = children;

    if (typeof children === 'string') return null;
    return secondline;
  };
  render() {
    const {
      className,
      leftElement,
      actions = [],
      hideCloseBtn = false,
      onDismiss,
    } = this.props;
    const centerElement = this.getCenterElementFromChildren();
    const secondLine = this.getSecondLineFromChildren();

    return (
      <div className={this._getClassName(className)}>
        <div
          className={classnames('main-wrapper', {
            'is-multi-line': secondLine != null,
          })}>
          <div className="left-element">{leftElement}</div>
          <div className="center-element">
            <div className="text subtitle-3">
              {centerElement}
              {secondLine != null && secondLine.length > 0 && (
                <div className="second-line body-3 n-700">{secondLine}</div>
              )}
            </div>
          </div>
          {hideCloseBtn === false &&
            actions.some((action) => action.shouldCloseToast === true) ===
              false && (
              <div className="right-element">
                <i className="icon-close" onClick={onDismiss}></i>
              </div>
            )}
        </div>
        {actions.length > 0 && (
          <div
            className={classnames('actions', {
              'has-multiple-actions': actions.length > 1,
            })}>
            {actions.map((action) => (
              <Button
                className="toast-action"
                {...action.props}
                onClick={
                  action.shouldCloseToast === true
                    ? onDismiss
                    : action.props.onClick
                }
                light>
                {action.text}
              </Button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export const toastInfo = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['bottom-center'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-info-wrapper">
        <i className="isax isax-info-circle5"></i>
      </div>
    );
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  if (options.toastId != null) {
    toast.dismiss(options.toastId);
  }
  toastId = toast(
    <Toast
      className={options.className}
      leftElement={leftElement()}
      actions={options.actions}
      hideCloseBtn={options.hideCloseBtn}
      onDismiss={dismiss}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--info',
      transition,
    }
  );
};
export const toastSuccess = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['bottom-center'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-tick-wrapper">
        <i className="isax isax-tick-circle5"></i>
      </div>
    );
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  if (options.toastId != null) {
    toast.dismiss(options.toastId);
  }
  toastId = toast(
    <Toast
      className={options.className}
      leftElement={leftElement()}
      actions={options.actions}
      hideCloseBtn={options.hideCloseBtn}
      onDismiss={dismiss}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--success',
      transition,
    }
  );
};
export const toastDanger = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['bottom-center'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-exclamation-wrapper">
        <i className="isax isax-danger5"></i>
      </div>
    );
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  if (options.toastId != null) {
    toast.dismiss(options.toastId);
  }
  toastId = toast(
    <Toast
      className={options.className}
      leftElement={leftElement()}
      actions={options.actions}
      hideCloseBtn={options.hideCloseBtn}
      onDismiss={dismiss}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--danger',
      transition,
    }
  );
};
export const toastWarning = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['bottom-center'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-exclamation-wrapper">
        <i className="isax isax-info-circle5"></i>
      </div>
    );
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  if (options.toastId != null) {
    toast.dismiss(options.toastId);
  }
  toastId = toast(
    <Toast
      className={options.className}
      leftElement={leftElement()}
      actions={options.actions}
      hideCloseBtn={options.hideCloseBtn}
      onDismiss={dismiss}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--warning',
      transition,
    }
  );
};
