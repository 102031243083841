import {projectService} from 'services';

export class IframeMessenger {
  constructor(iframeRef, project) {
    this.refIframe = iframeRef;
    this.targetOrigin = projectService.getProjectPortalUrl(project);
  }

  sendProjectUpdate(data) {
    if (!this.refIframe.current) {
      return;
    }
    this.refIframe.current.contentWindow.postMessage(
      {action: 'PREVIEW_UPDATE_PROJECT', data, fromJimo: true},
      '*'
    );
  }
}
