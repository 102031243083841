import {generalActions} from 'actions';
import Axios from 'axios';
import {setBearerToken, unsetBearerToken} from 'conf/axios';
import {Environment} from 'conf/env';
import cookies from 'js-cookie';
import JwtDecode from 'jwt-decode';
import {generalSelector} from 'selectors';
import Store from 'store/configure';
import {Swaler} from 'swaler';

export const AUTH_TYPE_GOOGLE = 'google';
export const AUTH_TYPE_EMAIL = 'email';

export const F_USER_STATUS_ACTIVE = 1;
export const F_USER_STATUS_WAITING_FOR_APPROVAL = 2;
export const F_USER_STATUS_NOT_APPROVED = 4;
export const F_USER_STATUS_ONBOARDING_BOOKED = 16;

export const COOKIE_JIMO_TOKEN = 'jimo.token';

const logger = new Swaler('auth.service');

// Endpoints
const EP_AUTH_JOIN_WITH_BASIC = '/auth/join/basic';
const EP_AUTH_JOIN_WITH_GOOGLE = '/auth/join/google';
const EP_AUTH_SIGN_UP_BASIC = '/auth/signup/basic';
const EP_AUTH_SIGN_UP_GOOGLE = '/auth/signup/google';
const EP_AUTH_SIGN_IN_BASIC = '/auth/signin/basic';
const EP_AUTH_SIGN_IN_GOOGLE = '/auth/signin/google';
const EP_AUTH_SIGN_IN_LOGIN_CODE = '/auth/signin/logincode';
const EP_AUTH_CREATE_SECURITY_CODE = '/auth/securitycode';
const EP_AUTH_UPDATE_PASSWORD = '/auth/password';
const EP_AUTH_FORGOT_PASSWORD = '/auth/forgotpassword';
const EP_AUTH_CHECK_EMAIL = '/auth/check-email';
const EP_AUTH_VERIFY_EMAIL = '/auth/verify-email';

// LocalStorage Keys
const LS_KEY_JWT_TOKEN = 'token';

export const setAuthTokens = (token) => {
  setJwtToken(token);
  setCookieToken(token);
};
export const setJwtToken = (token) => {
  localStorage.setItem(LS_KEY_JWT_TOKEN, token);
  setBearerToken(token);
};
// Add token to cookie so that it can be used in Crisp Widget
export const setCookieToken = (token) => {
  const jwtToken = JwtDecode(token);

  cookies.set(COOKIE_JIMO_TOKEN, token, {
    expires: new Date(jwtToken.exp * 1000),
    sameSite: 'None',
    secure: true,
    ...(Environment.NODE_ENV === 'production' && {domain: '.usejimo.com'}),
  });
};

export const getJwtToken = () => {
  return localStorage.getItem(LS_KEY_JWT_TOKEN);
};
export const getCookieToken = () => {
  return cookies.get(COOKIE_JIMO_TOKEN);
};

export const hasJwtToken = () => {
  return getJwtToken() != null;
};
export const hasExpiredJwtToken = (jwtToken = getJwtToken()) => {
  const timeInSeconds = Math.floor(Date.now() / 1000);

  if (!jwtToken) {
    return true;
  }
  jwtToken = JwtDecode(jwtToken);
  return timeInSeconds > jwtToken.exp;
};

export const isAuthenticated = () => {
  if (
    hasJwtToken() === true &&
    hasExpiredJwtToken() === false &&
    generalSelector.getUser() != null
  ) {
    return true;
  }
  return false;
};

export const isAuthenticatedForCrispWidget = () => {
  const token = cookies.get(COOKIE_JIMO_TOKEN);

  return token != null && hasExpiredJwtToken(token) === false;
};

export const joinWithEmailPassword = async (data) => {
  const {email, password, locale} = data;

  try {
    const result = await Axios.post(EP_AUTH_JOIN_WITH_BASIC, {
      email,
      password,
      locale,
    }).then((response) => response.data);

    setAuthTokens(result.token);
    logger.debug('signUpWithEmailPassword successfully!');
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const joinWithGoogle = async (googleTokenId, data) => {
  const {locale} = data;

  try {
    const result = await Axios.post(EP_AUTH_JOIN_WITH_GOOGLE, {
      googleTokenId,
      locale,
    }).then((response) => response.data);

    setAuthTokens(result.token);
    logger.debug('signUpWithGoogle successfully!');
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const signUpWithEmailPassword = async (data) => {
  const {email, locale} = data;

  try {
    await Axios.post(EP_AUTH_SIGN_UP_BASIC, {
      email,
      locale,
    }).then((response) => response.data);

    logger.debug('signUpWithEmailPassword successfully!');
  } catch (err) {
    return Promise.reject(err);
  }
};
export const signUpWithGoogle = async (googleAccessToken, data) => {
  const {locale} = data;

  return Axios.post(EP_AUTH_SIGN_UP_GOOGLE, {
    googleAccessToken,
    locale,
  }).then((response) => {
    setAuthTokens(response.data.token);
    logger.debug('signUpWithGoogle successfully!');
    return response.data;
  });
};
export const signInWithBasic = async (data) => {
  const {email, password} = data;

  try {
    const result = await Axios.post(EP_AUTH_SIGN_IN_BASIC, {
      email,
      password,
    }).then((response) => response.data);

    setAuthTokens(result.token);
    logger.debug('signInWithBasic successfully!');
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};
export const signInWithGoogle = async (googleAccessToken) => {
  try {
    const result = await Axios.post(EP_AUTH_SIGN_IN_GOOGLE, {
      googleAccessToken,
    }).then((response) => response.data);

    setAuthTokens(result.token);
    logger.debug('signInWithGoogle successfully!');
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};
export const signInWithLoginCode = async (code) => {
  try {
    const result = await Axios.post(EP_AUTH_SIGN_IN_LOGIN_CODE, {
      code,
    }).then((response) => response.data);

    setAuthTokens(result.token);
    logger.debug('signInLoginCode successfully!');
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendForgotPassword = (email) => {
  return Axios.post(EP_AUTH_FORGOT_PASSWORD, {email}).then(
    (response) => response.data
  );
};

export const askForSecurityCode = async () => {
  return Axios.post(EP_AUTH_CREATE_SECURITY_CODE);
};

export const updatePassword = async (password, securityCode) => {
  return Axios.put(EP_AUTH_UPDATE_PASSWORD, {password, securityCode}).then(
    (response) => response.data
  );
};

export const signOut = () => {
  Store().get().store.dispatch(generalActions.setUser());
  Store().get().store.dispatch(generalActions.setProject(null));
  localStorage.removeItem(LS_KEY_JWT_TOKEN);
  unsetBearerToken();
  cookies.remove(COOKIE_JIMO_TOKEN);
};

export const checkEmail = (email) => {
  return Axios.post(EP_AUTH_CHECK_EMAIL, {email});
};

export const verifyEmail = (code) => {
  return Axios.post(EP_AUTH_VERIFY_EMAIL, {}, {params: {c: code}}).then(
    (response) => response.data
  );
};
