import classnames from 'classnames';
import Select from 'components/Select';

export const OPTIONS = {
  OP_IS: {value: 'IS', label: 'is'},
  OP_NIS: {value: 'NIS', label: 'is not'},
  OP_IS_ACTIVE: {value: 'IS_ACTIVE', label: 'is active'},
  OP_NIS_ACTIVE: {value: 'NIS_ACTIVE', label: 'is not active'},
  OP_IS_GREATER: {value: 'IS_GREATER', label: 'is greater'},
  OP_IS_GREATER_OR_EQUAL: {
    value: 'IS_GREATER_OR_EQUAL',
    label: 'is greater or equal',
  },
  OP_IS_LESSER: {value: 'IS_LESSER', label: 'is less'},
  OP_IS_LESSER_OR_EQUAL: {
    value: 'IS_LESSER_OR_EQUAL',
    label: 'is less or equal',
  },
  OP_IS_BETWEEN: {value: 'IS_BETWEEN', label: 'is between'},
  OP_IS_NBETWEEN: {value: 'IS_NBETWEEN', label: 'is not between'},
  OP_IS_EMPTY: {value: 'IS_EMPTY', label: 'is empty'},
  OP_NIS_EMPTY: {value: 'NIS_EMPTY', label: 'is not empty'},
  OP_STARTS_WITH: {value: 'STARTS_WITH', label: 'starts with'},
  OP_CONTAINS: {value: 'CONTAINS', label: 'contains'},
  OP_NCONTAINS: {value: 'NCONTAINS', label: 'not contains'},

  // Date specific
  OP_DATE_RELATIVE_DAYS_IN_RANGE: {
    value: 'DATE_RELATIVE_DAYS_IN_RANGE',
    label: 'is withing the last ... days',
  },
  OP_DATE_RELATIVE_DAYS_NIN_RANGE: {
    value: 'DATE_RELATIVE_DAYS_NIN_RANGE',
    label: 'is not withing the last ... days',
  },
};
export const SelectOperand = (props) => {
  let {className, onChange, value, ...rest} = props;

  const handleChange = (value) => {
    onChange(value.value);
  };

  value = Object.values(OPTIONS).find((o) => o.value === value);
  return (
    <Select
      options={Object.values(OPTIONS)}
      onChange={handleChange}
      className={classnames('select-operand', className)}
      value={value}
      isDisabled
      {...rest}></Select>
  );
};
