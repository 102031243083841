import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useEffect, useRef, useState} from 'react';
import sanitizeHtml from 'sanitize-html';
import './_Styles.scss';

export const MarkdownContent = React.memo(({content, enableReadMore}) => {
  const sanitizedContent = sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      'img',
      'iframe',
      'ins',
      'del',
    ]),
    allowedIframeHostnames: [
      'www.youtube.com',
      'player.vimeo.com',
      'www.loom.com',
    ],
    allowedAttributes: {
      iframe: ['src'],
      img: ['src', 'alt'],
      a: ['href', 'target'],
    },
  });

  const [shouldShowReadMore, setShouldShowReadMore] = useState(false);

  const refMd = useRef(null);

  useEffect(() => {
    if (refMd.current == null) {
      return;
    }
    if (
      refMd.current.scrollHeight > refMd.current.clientHeight &&
      enableReadMore === true
    ) {
      setShouldShowReadMore(true);
    }
  }, [refMd.current]);

  return (
    <div
      className={classnames('md-renderer-wrapper', {
        'has-content-truncated': enableReadMore === true,
        'has-read-more': shouldShowReadMore === true,
      })}
      ref={refMd}>
      <Markdown
        className="markdown-content"
        options={{
          overrides: {
            img: {
              component: ({src, alt}) => {
                return (
                  <div className="img-wrapper">
                    <img src={src} alt={alt} />
                  </div>
                );
              },
            },
            iframe: {
              component: ({src, title}) => {
                return (
                  <div className="iframe-wrapper">
                    <iframe src={src} title={title} allowFullScreen />
                  </div>
                );
              },
            },
          },
        }}>
        {sanitizedContent}
      </Markdown>
      {shouldShowReadMore === true && (
        <div className="md-read-more">Read more</div>
      )}
    </div>
  );
});
