import classNames from 'classnames';
import {func, number} from 'prop-types';
import React, {useEffect, useState} from 'react';
import './_Styles.scss';

const propTypes = {
  zoom: number,
  setZoom: func,
};

const defaultProps = {
  zoom: 1,
  updateZoom: () => {},
};

const ZoomManager = ({zoom, setZoom}) => {
  const [showZoomValue, setShowZoomValue] = useState(false);

  useEffect(() => {
    setShowZoomValue(true);
    const timeout = setTimeout(() => {
      setShowZoomValue(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [zoom]);

  const handleZoomChange = (value) => {
    let newZoom = zoom + value;
    if (newZoom < 0.5) {
      newZoom = 0.5;
    }
    if (newZoom > 2) {
      newZoom = 2;
    }
    setZoom(newZoom);
  };

  return (
    <div className="zoom-manager">
      <div className={classNames('zoom-manager-value', {show: showZoomValue})}>
        {parseInt(zoom * 100)}%
      </div>
      <div
        className="zoom-manager-item"
        onClick={() => handleZoomChange(-0.25)}>
        <i className="icon-minus" />
      </div>
      <div className="zoom-manager-item" onClick={() => handleZoomChange(0.25)}>
        <i className="icon-plus" />
      </div>
    </div>
  );
};

ZoomManager.propTypes = propTypes;
ZoomManager.defaultProps = defaultProps;

export default ZoomManager;
