import Axios from 'axios';

export const F_CONFIG_EVOLUTION_CREATION = 1;
export const F_CONFIG_EVOLUTION_STATUS_CHANGE = 2;

// Endpoint
const EP_SLACK_GET_GENERATE_URL = '/slack/generate-url';
const EP_SLACK_GET_TEAM_INFO = '/slack/team-info';

const EP_SLACK_POST_EXCHANGE_CODE = '/slack/exchange-code';
const EP_SLACK_GET_CHANNELS = '/slack/channels';
const EP_SLACK_UPDATE = (slackId) => `/slack/${slackId}`;
const EP_SLACK_REVOKE_TOKEN = (slackId) => `/slack/revoke/${slackId}`;

// Method
export const getTeamInfo = (slackId) => {
  return Axios.get(EP_SLACK_GET_TEAM_INFO, {params: {slackId}}).then(
    (response) => response.data
  );
};

export const generateUrl = ({withoutWebhook}, projectId) => {
  return Axios.post(
    EP_SLACK_GET_GENERATE_URL,
    {withoutWebhook},
    {params: {projectId}}
  ).then((response) => response.data);
};

export const exchangeCodeForToken = (projectId, {code, state}) => {
  return Axios.post(
    EP_SLACK_POST_EXCHANGE_CODE,
    {
      code,
      state,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};

export const getChannels = (slackId) => {
  return Axios.get(EP_SLACK_GET_CHANNELS, {params: {slackId}}).then(
    (response) => response.data
  );
};

export const updateSlack = (slackId, data) =>
  Axios.put(EP_SLACK_UPDATE(slackId), data).then((response) => response.data);

export const revokeToken = (slackId) =>
  Axios.post(EP_SLACK_REVOKE_TOKEN(slackId)).then((response) => response.data);
