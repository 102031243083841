import classnames from 'classnames';
import {CircularProgress} from 'components/Progress';
import './_styles.scss';

export const GetStartedStepItem = ({
  value,
  progress,
  title,
  children,
  opacityOnHover = false,
}) => {
  return (
    <div
      className={classnames('gs-step-item', {
        'opacity-on-hover': opacityOnHover === true,
      })}>
      {value != null && (
        <div className="step-top">
          <div
            className={classnames('step-number-and-progress', {
              'is-one': value === 1,
            })}>
            <div className="step-progress-bg"></div>
            <CircularProgress
              value={progress}
              strokeWidth={2}
              size={36}></CircularProgress>
            <span>{value}</span>
          </div>
          <div className="step-title">{title}</div>
        </div>
      )}
      <div className="step-body">{children}</div>
    </div>
  );
};
