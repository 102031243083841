import React, {useContext} from 'react';
import './_Styles.scss';
import classNames from 'classnames';
import {
  RECURRENCY_DAY_BASED,
  RECURRENCY_EVERY_TIME,
  RECURRENCY_SINGLE_TIME,
} from 'services/evolution';
import InputGroup from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {AudienceContext} from 'scenes/PokeAudience';

const recurrenceOptions = [
  {label: 'Once', value: RECURRENCY_SINGLE_TIME},
  {label: 'Every time', value: RECURRENCY_EVERY_TIME},
  {label: 'Custom time', value: RECURRENCY_DAY_BASED},
];

const RecurrencySelection = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const selectedRecurrence = recurrenceOptions.find(
    (o) => o.value === evolution.recurrencyType
  );
  const selectedRecurrenceLabel =
    selectedRecurrence?.value === RECURRENCY_DAY_BASED ? (
      <div className={classNames('recurrence-option custom-input-label')}>
        Once every
        <InputGroup
          value={evolution.recurrencyValue}
          type="number"
          required
          onClick={(e) => e.stopPropagation()}
          onChange={(e) =>
            setEvolution({
              ...evolution,
              recurrencyValue: parseInt(e.target.value),
            })
          }
        />
        days
      </div>
    ) : (
      selectedRecurrence?.label
    );

  return (
    <div className="poke-recurrence-options">
      <Dropdown
        className="recurrence-selection"
        position="bottom right"
        repositionOnResize={false}
        trigger={
          <Button thin iconLeft="icon-replay" iconRight="icon-chevron-bottom">
            {selectedRecurrenceLabel}
          </Button>
        }>
        <div className="recurrence-list">
          {recurrenceOptions.map((option) => {
            return (
              <div
                className={classNames('date-item', {
                  'is-set': evolution.recurrencyType === option.value,
                })}
                onClick={() => {
                  setEvolution({
                    ...evolution,
                    recurrencyType: option.value,
                  });
                }}>
                {option.label}
              </div>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

export default RecurrencySelection;
