import classnames from 'classnames';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export default class BtnCopy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleClick = (e) => {
    const {onClick} = this.props;

    this.setState({copied: true});
    onClick();
    setTimeout(() => this.setState({copied: false}), 1000);
  };

  render() {
    const {
      iconLeft = 'icon-duplicate',
      text,
      onClick,
      textToCopy,
      className,
      ...rest
    } = this.props;
    const {copied} = this.state;
    const classNames = classnames('btn-copy', className);

    if (copied)
      return (
        <CopyToClipboard text={textToCopy} onCopy={this.handleClick}>
          <Button
            text="copied!"
            iconLeft="icon-tick"
            primary
            onClick={this.handleClick}
            {...rest}
            className={classNames}
          />
        </CopyToClipboard>
      );
    return (
      <CopyToClipboard text={textToCopy} onCopy={this.handleClick}>
        <Button
          text={text}
          iconLeft={iconLeft}
          onClick={this.handleClick}
          {...rest}
          className={classNames}
        />
      </CopyToClipboard>
    );
  }
}

BtnCopy.propTypes = {
  textToCopy: PropTypes.any,
  onClick: PropTypes.func,
};

BtnCopy.defaultProps = {
  onClick: () => {},
};
