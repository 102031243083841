import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastSuccess} from 'components/Toaster';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {meService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalSendInstructions');

export default function ModalSendInstructions(props) {
  const project = useSelector((state) => generalSelector.getProject(state));
  const user = useSelector((state) => generalSelector.getUser(state));

  const [inputEmail, setInputEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await meService.requestTechHelp(project.uid, inputEmail).then(() =>
      toastSuccess(['Sent!', 'Your tech team is now aware of what to do!'], {
        toastId: 'request-tech-help-sent',
      })
    );
    setIsSubmitting(false);
    props.onRequestClose();
  };

  return (
    <Modal className="modal-send-instructions" {...props}>
      <form onSubmit={handleSubmit}>
        <Input
          legend="Teammate email"
          type="email"
          name="inputEmail"
          placeholder={`mate@${user.email.split('@')[1]}`}
          value={inputEmail}
          onChange={({target}) => setInputEmail(target.value)}
          iconLeft="isax isax-sms"
          required
        />
        <div className="actions">
          <Button
            type="button"
            muted
            onClick={() => {
              props.onRequestClose();
              setInputEmail('');
            }}>
            Cancel
          </Button>
          <Button primary loading={isSubmitting}>
            Send
          </Button>
        </div>
      </form>
    </Modal>
  );
}
