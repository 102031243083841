import {dataActions, generalActions} from 'actions';
import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Input from 'components/Input';
import {Environment} from 'conf/env';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {useIsExtensionInstalled} from 'helpers/utils';
import {posthog} from 'posthog-js';
import querystring from 'query-string';
import {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useDispatch} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  ROUTE_GET_STARTED,
  ROUTE_RECOVERY_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_TOURS,
} from 'router/routes.const';
import ButtonGoogle from 'scenes/Onboarding/components/ButtonGoogle';
import {authService, meService, projectService, rolesService} from 'services';
import {
  F_EXTRA_FIRST_POKE_ADOPTION,
  F_EXTRA_FIRST_POKE_DISCOVERY,
  F_EXTRA_FIRST_POST_PORTAL,
} from 'services/project';
import {F_IS_TRIALING} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';
import logo from './imgs/logo.svg';

const logger = new Swaler('Login');

export const OnboardingLogin = ({
  playAnimationOut,
  triggerOnboardingEnd,
  triggerAnimationOut,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {identify} = useJimoIdentify();

  const dispatch = useDispatch();

  const setUser = (user) => dispatch(generalActions.setUser(user));
  const setProject = (project) => dispatch(generalActions.setProject(project));
  const setProjects = (projects) =>
    dispatch(generalActions.setProjects(projects));
  const setCustomRoles = (roles) => dispatch(dataActions.setCustomRoles(roles));
  const setBuiltInRoles = (roles) =>
    dispatch(dataActions.setBuiltInRoles(roles));

  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [isLogging, setIsLogging] = useState(null);
  const [errorLogging, setErrorLogging] = useState(null);
  const [isGoogleDisabled, setIsGoogleDisabled] = useState(false);

  const {isInstalled} = useIsExtensionInstalled();

  const isExtension =
    location.state?.from === '/lightweight-builder/extension' ||
    location.state?.from?.startsWith('/lightweight-builder/poke');

  /** Basic Login */
  const handleSubmitLogin = async (e) => {
    let signInResult = null;

    e.preventDefault();
    setIsLogging('basic');
    setErrorLogging(null);
    try {
      signInResult = await authService.signInWithBasic({
        email: inputEmail,
        password: inputPassword,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorLogging(err.response.data.message);
        setIsLogging(null);
      } else {
        setErrorLogging(err.message);
        setIsLogging(null);
      }
      return logger.error('Fail to sign in with Basic', err);
    }
    triggerSignIn(signInResult);
  };

  /** Google Login */
  const handleGoogleLoginSuccess = async (response) => {
    const {access_token} = response;
    let signInResult = null;

    try {
      signInResult = await authService.signInWithGoogle(access_token);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorLogging(err.response.data.message);
        setIsLogging(null);
      } else {
        setErrorLogging(err.message);
        setIsLogging(null);
      }
      return logger.error('Fail to sign in with Google', err);
    }
    triggerSignIn(signInResult);
  };
  const handleGoogleLoginFailure = async (response) => {
    logger.error(`Google Login Failure`, response);
    setIsLogging(null);
    if (response.error === 'idpiframe_initialization_failed') {
      setIsGoogleDisabled(true);
      setErrorLogging(
        `Google login is not available because of the following error : ${response?.details}`
      );
      return;
    }
    setErrorLogging('We could not sign in with Google!');
  };

  const triggerSignIn = async (signInResult) => {
    const {user} = signInResult;
    const qs = querystring.parse(location.search);

    if (qs.invitationId != null) {
      try {
        await projectService.useProjectMemberInvitation({
          invitationId: qs.invitationId,
        });
        logger.debug('Invitation used!');
      } catch (err) {
        logger.error('Use invitation failed with error ', err);
      }
    }
    try {
      const projects = await meService.getMyProjects();

      identify(user);
      if (projects.length !== 0) {
        const project = projects.find(
          (p) =>
            p.uid ===
            (qs.goToProject != null ? qs.goToProject : projects[0].uid)
        );

        setProjects(projects);
        setProject(project.uid);

        const customRoles = await rolesService.getCustomRoles(project);
        const builtInRoles = await rolesService.getBuiltInRoles();

        setCustomRoles(customRoles);
        setBuiltInRoles(builtInRoles);
      }
      setUser(user);
      if (Environment.NODE_ENV === 'production') {
        posthog.identify(user.uid);
      }
      if (location.state?.from != null) {
        return history.push(location.state.from);
      }
      const project = qs.goToProject != null ? qs.goToProject : projects[0];
      const gsTryDone = [
        hasFlags(
          [
            F_EXTRA_FIRST_POKE_ADOPTION,
            F_EXTRA_FIRST_POKE_DISCOVERY,
            F_EXTRA_FIRST_POST_PORTAL,
          ],
          project?.extraFlags,
          true
        ),
        isInstalled === true,
      ];
      const gsInstallDone = [project.snippetInstalledAt != null];
      const getStartedPercentage = Math.round(
        ([
          gsTryDone.some((t) => t === true),
          gsInstallDone.every((t) => t === true),
        ].filter((t) => t === true).length /
          2) *
          100
      );
      if (
        hasFlag(F_IS_TRIALING, project.subscription.extraFlags) ||
        getStartedPercentage < 100
      ) {
        history.push(ROUTE_GET_STARTED);
      } else {
        history.push(ROUTE_TOURS);
      }
    } catch (err) {
      logger.error(
        `Could not fetch my projects, failed with error ${err.message}`,
        err
      );
      return;
    }
  };

  return (
    <div
      className={classnames('s-onboarding-login', {
        'is-exiting': playAnimationOut === true,
      })}>
      <img className="jimo-logo" src={logo} alt="logo jimo" height={44} />
      <h1>
        Nice to see you again! <br />
        <small>Please login to access your account</small>
      </h1>
      <AnimateHeight height={errorLogging !== null ? 'auto' : 0}>
        <LoginError error={errorLogging}></LoginError>
      </AnimateHeight>
      <ButtonGoogle
        fullwidth
        iconRight="icon-chevron-right"
        rounded={false}
        loading={isLogging === 'google'}
        disabled={isLogging != null || isGoogleDisabled === true}
        onSuccess={handleGoogleLoginSuccess}
        onFailure={handleGoogleLoginFailure}
        onClick={() => {
          setIsLogging('google');
          setErrorLogging(null);
        }}
        type="button">
        Continue with Google
      </ButtonGoogle>
      <div className="divider-wrapper">
        <Divider dark>OR</Divider>
      </div>
      <form onSubmit={handleSubmitLogin}>
        <div className="email-label">Email</div>
        <Input
          name="inputEmail"
          className="input-onboarding"
          placeholder="my@email.com"
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
          required
        />
        <div className="email-label">Password</div>
        <Input
          type="password"
          name="inputPassword"
          className="input-onboarding input-password"
          placeholder="•••••••••••"
          value={inputPassword}
          onChange={(e) => setInputPassword(e.target.value)}
          required
        />
        <Link
          to={ROUTE_RECOVERY_PASSWORD}
          target={isExtension ? '_blank' : '_self'}
          rel={isExtension ? 'noopener noreferrer' : null}
          className="forgot-label">
          forgot?
        </Link>
        <Button
          text="Sign in to my account"
          className="btn-submit"
          fullwidth
          loading={isLogging === 'basic'}
          disabled={isLogging != null}
          primary
          rounded={false}
          iconRight="icon-chevron-right"></Button>
      </form>
      <Link
        to={ROUTE_REGISTER}
        target={isExtension ? '_blank' : '_self'}
        rel={isExtension ? 'noopener noreferrer' : null}>
        I don't have an account yet
      </Link>
    </div>
  );
};

function LoginError(props) {
  const {error} = props;
  let alertBody = null;

  if (Array.isArray(error)) {
    alertBody = (
      <ul>
        {error.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    );
  }
  if (typeof error === 'string') {
    switch (error) {
      case 'INVALID_CREDENTIAL': {
        alertBody = <div>Wrong email or password!</div>;
        break;
      }
      case 'AUTH_PROVIDER_MISSMATCH': {
        alertBody = (
          <div>
            It seems that you have created your account with another provider
            than the one you just used!
          </div>
        );
        break;
      }
      case 'ACCOUNT_NOT_FOUND_USING_GOOGLE': {
        alertBody = (
          <div>
            We could not found an account associated with your Google account,
            please <Link to={ROUTE_REGISTER}>create an account first</Link>!
          </div>
        );
        break;
      }
      default:
        alertBody = error;
    }
  }
  return <Alert danger>{alertBody}</Alert>;
}
