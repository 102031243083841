import classNames from 'classnames';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import querystring from 'query-string';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BANNER_WITH_ID,
  ROUTE_CHECKLIST_WITH_ID,
  ROUTE_HINT_WITH_ID,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_TOUR_WITH_ID,
} from 'router/routes.const';
import {Poke} from 'scenes/Poke';
import {
  environmentService,
  evolutionListViewService,
  segmentService,
  tagService,
} from 'services';
import {TAG_CONTEXT_VIEW} from 'services/tag';
import {Swaler} from 'swaler';
import {PushesContext} from './context';
import PushesWithoutContext from './index';

const logger = new Swaler('Pushes(with_context)');

export const VIEW_ALL_ID = 'All';

export const Pushes = (props) => {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  const qs = querystring.parse(location.search);

  const [viewId, setViewId] = useState(qs.viewId ?? VIEW_ALL_ID);
  const [view, setView] = useState({
    segments: [],
    tags: [],
    environments: [],
  });
  const [viewEvolutionCount, setViewEvolutionCount] = useState(0);
  const [viewChanges, setViewChanges] = useState({
    ...(qs.contexts != null ? {contexts: qs.contexts?.split(',')} : {}),
    ...(qs.states != null ? {states: qs.states?.split(',')} : {}),
    ...(qs.segments != null ? {segments: qs.segments?.split(',')} : {}),
    ...(qs.tags != null ? {tags: qs.tags?.split(',')} : {}),
    ...(qs.environments != null
      ? {environments: qs.environments?.split(',')}
      : {}),
    ...(qs.layout != null ? {layout: qs.layout} : {}),
  });
  const [pokeToRefresh, setPokeToRefresh] = useState(null);

  // Fetch views
  let {
    data: views = [],
    refetch: refetchViews,
    isRefetching: isRefetchingViews,
    isLoading: isLoadingViews,
  } = useQuery({
    queryKey: ['views'],
    queryFn: () => evolutionListViewService.getListViews(),
    refetchOnWindowFocus: false,
    // Init view
    onSuccess: (data) => {
      const initView = data.find((v) => v.uid === viewId);

      setView({
        ...(initView != null
          ? {
              ...initView,
              // We have to store segments and tags as uid since we when there are pass by URL we only have the ID
              // and we can't map them since here we can't know if segments and tags are fetched
              segments: initView.segments.map((s) => s.uid),
              tags: initView.tags.map((t) => t.uid),
              environments: initView.environments.map((e) => e.uid),
            }
          : {}),
        ...viewChanges,
      });
    },
  });
  // Fetch segments
  const {
    data: segments = [],
    refetch: refetchSegments,
    isRefetching: isRefetchingSegments,
    isLoading: isLoadingSegments,
  } = useQuery({
    queryKey: ['segments'],
    queryFn: () => segmentService.getSegments(),
    refetchOnWindowFocus: false,
  });

  // Fetch tags
  const {
    data: tags = [],
    refetch: refetchTags,
    isRefetching: isRefetchingTags,
    isLoading: isLoadingTags,
  } = useQuery({
    queryKey: ['tags'],
    queryFn: () => tagService.getTags({contexts: [TAG_CONTEXT_VIEW]}),
    refetchOnWindowFocus: false,
  });

  // Fetch environments
  const {
    data: environments = [],
    refetch: refetchEnvironments,
    isRefetching: isRefetchingEnvironments,
    isLoading: isLoadingEnvironments,
  } = useQuery({
    queryKey: ['environments'],
    queryFn: () => environmentService.getEnvironments(),
    refetchOnWindowFocus: false,
  });

  const isInitialLoading =
    (isLoadingViews === true && isRefetchingViews === false) ||
    (isLoadingSegments === true && isRefetchingSegments === false) ||
    (isLoadingTags === true && isRefetchingTags === false) ||
    (isLoadingEnvironments === true && isRefetchingEnvironments === false);

  // Reset URL when switching view
  useEffect(() => {
    const viewToSwitchTo = views.find((v) => v.uid === viewId);

    if (view == null) {
      return;
    }
    if (view != null) {
      history.replace({
        pathname: location.pathname,
        ...(viewId !== VIEW_ALL_ID
          ? {search: new URLSearchParams({viewId}).toString()}
          : {search: null}),
      });
    }
    if (viewToSwitchTo != null) {
      setView({
        ...viewToSwitchTo,
        segments: viewToSwitchTo.segments.map((s) => s.uid),
        tags: viewToSwitchTo.tags.map((t) => t.uid),
        environments: viewToSwitchTo.environments.map((e) => e.uid),
      });
    } else {
      setView({});
      setViewChanges({});
    }
  }, [viewId]);

  // Pass changes to the URL to make sure we can navigate or share the view with someone
  useEffect(() => {
    const viewUpdated = {
      ...viewChanges,
    };

    if (view == null) {
      return;
    }
    history.push({
      pathname: location.pathname,
      search: new URLSearchParams({
        ...(viewId !== VIEW_ALL_ID ? {viewId} : {}),
        ...(viewUpdated.contexts != null
          ? {contexts: viewUpdated.contexts}
          : {}),
        ...(viewUpdated.states != null ? {states: viewUpdated.states} : {}),
        ...(viewUpdated.tags != null ? {tags: viewUpdated.tags} : {}),
        ...(viewUpdated.environments != null
          ? {environments: viewUpdated.environments}
          : {}),
        ...(viewUpdated.segments != null
          ? {segments: viewUpdated.segments}
          : {}),
        ...(viewUpdated.layout != null ? {layout: viewUpdated.layout} : {}),
      }).toString(),
    });
    setView({
      ...(viewId != null ? view : {}),
      ...viewChanges,
    });
  }, [viewChanges]);

  const applyChanges = (data) => {
    setViewChanges((viewChanges) => ({
      ...viewChanges,
      ...data,
    }));
  };
  const resetChanges = () => {
    const originalView =
      viewId === VIEW_ALL_ID ? {} : views.find((v) => v.uid === viewId);

    setViewChanges(() => ({}));
    setView(() => ({
      ...originalView,
      segments: originalView?.segments?.map((s) => s.uid) ?? [],
      tags: originalView?.tags?.map((t) => t.uid) ?? [],
      environments: originalView?.environments?.map((e) => e.uid) ?? [],
    }));
  };
  const hasChanges = () => {
    const originalView =
      viewId === VIEW_ALL_ID ? {} : views.find((v) => v.uid === viewId);

    if (originalView == null) {
      return false;
    }

    const currentView = {
      ...originalView,
      segments: originalView.segments?.map((s) => s.uid),
      tags: originalView.tags?.map((t) => t.uid),
      environments: originalView.environments?.map((e) => e.uid),
    };

    const cleanedCurrentView = currentView
      ? Object.keys(currentView).reduce((acc, key) => {
          if (currentView[key]?.length > 0) {
            acc[key] = currentView[key];
          }
          return acc;
        }, {})
      : {};

    const cleanedView = view
      ? Object.keys(view).reduce((acc, key) => {
          if (view[key]?.length > 0) {
            acc[key] = view[key];
          }
          return acc;
        }, {})
      : {};

    return JSON.stringify(cleanedCurrentView) !== JSON.stringify(cleanedView);
  };

  const handleSave = async () => {
    const originalView =
      viewId === VIEW_ALL_ID ? {} : views.find((v) => v.uid === viewId);
    const updatedView = {
      name: originalView?.name ?? `View ${views.length + 1}`,
      contexts: viewChanges.contexts ?? originalView?.contexts,
      states: viewChanges.states ?? originalView?.states,
      layout: viewChanges.layout ?? originalView?.layout,
      segments:
        viewChanges?.segments?.map((segmentId) =>
          segments.find((s) => s.uid === segmentId)
        ) ??
        originalView?.segments ??
        [],
      tags:
        viewChanges?.tags?.map((tagId) => tags.find((t) => t.uid === tagId)) ??
        originalView?.tags ??
        [],
      environments:
        viewChanges?.environments?.map((envId) =>
          environments.find((e) => e.uid === envId)
        ) ??
        originalView?.environments ??
        [],
    };

    try {
      let view = null;

      if (Object.keys(originalView).length === 0) {
        view = await evolutionListViewService.createListView(updatedView);
        views = views.concat(view);
        toastSuccess('View created!', {toastId: 'view-created'});
      } else {
        view = await evolutionListViewService.updateListView(
          originalView.uid,
          updatedView
        );
        views = views.map((v) => (v.uid === view.uid ? view : v));
        toastSuccess('View saved!', {toastId: 'view-saved'});
      }
      setViewChanges({});
      await refetchViews();
      setViewId(view.uid);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating evolution list view failed with error : ', code);
      toastDanger([title, message], {actions});
    }
  };

  const isPokePage = [
    ROUTE_TOUR_WITH_ID(),
    ROUTE_BANNER_WITH_ID(),
    ROUTE_SURVEY_WITH_ID(),
    ROUTE_HINT_WITH_ID(),
    ROUTE_CHECKLIST_WITH_ID(),
  ].includes(match.path);
  const viewedPoke = isPokePage ? match.params.evolutionId : null;

  return (
    <PushesContext.Provider
      value={{
        views,
        viewId,
        viewEvolutionCount,
        view,
        segments,
        tags,
        environments,
        viewedPoke,
        pokeToRefresh,

        setViewId,
        setViewChanges,
        setViewEvolutionCount,
        setPokeToRefresh,
        refetchViews,
        refetchSegments,
        refetchTags,
        refetchEnvironments,

        applyChanges,
        resetChanges,
        hasChanges,

        handleSave,
      }}>
      {isInitialLoading === true || view == null ? (
        <div className="s-pushes-init-loading">
          <div className="skeleton-header">
            <div className="skeleton-title"></div>
            <div className="skeleton-btn-create"></div>
          </div>
          <div className="skeleton-views">
            <div className="skeleton-view-item"></div>
            <div className="skeleton-view-item"></div>
            <div className="skeleton-view-item"></div>
          </div>
          <div className="skeleton-view-editor">
            <div className="skeleton-setting-item"></div>
            <div className="skeleton-setting-item"></div>
            <div className="skeleton-setting-item"></div>
            <div className="skeleton-setting-item"></div>
          </div>
        </div>
      ) : (
        <>
          {viewedPoke != null && <Poke evolutionId={viewedPoke} />}
          <div
            className={classNames('poke-list-wrapper', {
              hidden: viewedPoke != null,
            })}>
            <PushesWithoutContext {...props} />
          </div>
        </>
      )}
    </PushesContext.Provider>
  );
};
