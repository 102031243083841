import Loader from 'components/Loader';
import React from 'react';
import './_Styles.scss';

export default function Loading(props) {
  return (
    <div className="app-loading fade-in-top">
      <div className="box">
        <i className="icon-jimo"></i>
      </div>
      <div className="box">
        <Loader width="14px"></Loader>
      </div>
      <div className="box"></div>
    </div>
  );
}
