import classNames from 'classnames';
import Alert from 'components/Alert';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import SegmentAttributesEditor from 'components/SegmentAttributesEditor';
import {buildLogic} from 'components/SegmentAttributesEditor/utils';
import SegmentAttributesEditorLegacy from 'components/SegmentAttributesEditorLegacy';
import {errorHelpers} from 'helpers';
import {hasUsernameGenerated} from 'helpers/utils';
import {bool, func, object} from 'prop-types';
import {useEffect, useState} from 'react';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import {useQuery} from 'react-query';
import {jimerService, segmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  evolution: object,
  setEvolution: func,
};

const defaultProps = {
  evolution: null,
  setEvolution: () => {},
};

const JIMERS_PER_PAGE = 20;

const logger = new Swaler('ModalSegmentation');

const ModalSegmentation = ({
  isOpen,
  onRequestClose,
  evolution: originalEvolution,
  setEvolution: setOriginalEvolution,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [evolution, setEvolution] = useState(originalEvolution);
  const [lookupData, setLookupData] = useState(null);

  const {data: segments = []} = useQuery({
    queryKey: 'segments',
    queryFn: segmentService.getSegments,
  });

  const fetchJimers = async (pageParam = page) => {
    setIsLoading(true);

    const oldPage = page;
    setPage(pageParam);

    try {
      const res = await jimerService.getJimers(
        {
          ...(lookupData?.attributes?.length > 0 && lookupData?.logic != null
            ? {attributes: lookupData.attributes, logic: lookupData.logic}
            : {segmentIds: evolution?.segments?.map((s) => s.uid)}),
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
        },
        {}
      );

      setJimers((prev) => ({
        ...res,
        data: [...prev.data, ...res.data],
      }));
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching values failed with error ', code);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      setEvolution(originalEvolution);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setJimers({
      data: [],
      skip: 0,
      take: JIMERS_PER_PAGE,
      total: 0,
    });
    fetchJimers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evolution?.segments, lookupData?.attributes, lookupData?.logic]);

  const onTheFlySegment = evolution?.onTheFlySegment ?? {
    temporary: true,
    attributes: [],
    logic: buildLogic(),
  };

  const closeModal = () => {
    onRequestClose();
  };

  const handleDeleteOnTheFlySegment = async () => {
    if (onTheFlySegment.uid != null) {
      await segmentService.deleteSegment(onTheFlySegment.uid);
    }
    setEvolution({...evolution, onTheFlySegment: null});
  };

  const handleRefresh = () => {
    setLookupData({
      attributes: JSON.parse(JSON.stringify(onTheFlySegment?.attributes)),
      logic: JSON.parse(JSON.stringify(onTheFlySegment?.logic)),
    });
  };

  const handleSave = () => {
    setOriginalEvolution(evolution);
    closeModal();
  };

  const hasSetupSegmentation =
    evolution.segments?.length !== 0 || lookupData?.attributes?.length > 0;

  const hasChanges = () =>
    onTheFlySegment?.attributes?.length > 0 &&
    (JSON.stringify(lookupData?.attributes) !==
      JSON.stringify(onTheFlySegment?.attributes) ||
      JSON.stringify(lookupData?.logic) !==
        JSON.stringify(onTheFlySegment?.logic));

  const isSaveDisabled = hasChanges() === true;

  return (
    <Modal
      className="modal-segmentation"
      overlayClassName="modal-segmentation-overlay"
      title="Target users"
      isOpen={isOpen}
      {...rest}
      onRequestClose={() => {
        setEvolution(originalEvolution);
        closeModal();
      }}>
      <div className="content-wrapper fade-in-right">
        <div className="poke-segment-editing-wrapper">
          <div className="section-wrapper">
            <div className="section-title">Select an existing segment</div>
            {onTheFlySegment?.attributes?.length > 0 && (
              <Alert warning>
                If you want to use existing segments, you have to remove all
                attributes used in your segment on the fly.
              </Alert>
            )}
            {segments?.length > 0 ? (
              <div className="list-segment">
                {segments?.map((s) => {
                  const isSelected = evolution.segments
                    ?.map((es) => es.uid)
                    .includes(s.uid);

                  return (
                    <Button
                      key={s.uid}
                      className={classNames('item-segment', {
                        selected: isSelected === true,
                      })}
                      rounded={false}
                      iconLeft="icon-duplicate"
                      disabled={onTheFlySegment?.attributes?.length > 0}
                      onClick={() => {
                        setEvolution({
                          ...evolution,
                          segments:
                            isSelected === true
                              ? evolution.segments.filter(
                                  (seg) => s.uid !== seg.uid
                                )
                              : [...evolution.segments, s],
                        });
                      }}>
                      {s.name}
                    </Button>
                  );
                })}
              </div>
            ) : (
              "You don't have any existing segment"
            )}
          </div>
          <div className="section-wrapper">
            <div className="section-title">Or create one on the fly</div>
            {onTheFlySegment?.logic == null ? (
              <SegmentAttributesEditorLegacy
                segment={onTheFlySegment}
                onRemoveOnTheFly={() => handleDeleteOnTheFlySegment()}
              />
            ) : (
              <SegmentAttributesEditor
                dropdownProps={{
                  offsetY: 6,
                  className: 'in-builder',
                  segmentOnTheFlyDisabled: evolution.segments?.length > 0,
                }}
                hideDefaultActions={true}
                onSelectExistingSegment={(s) =>
                  setEvolution({
                    ...evolution,
                    segments: evolution.segments.concat(s),
                  })
                }
                onRemoveOnTheFly={
                  onTheFlySegment?.attributes?.length > 0
                    ? handleDeleteOnTheFlySegment
                    : null
                }
                attributes={onTheFlySegment?.attributes}
                setAttributes={(attributes) => {
                  setEvolution((evolution) => ({
                    ...evolution,
                    onTheFlySegment:
                      attributes?.length === 0
                        ? null
                        : {
                            ...evolution.onTheFlySegment,
                            attributes,
                          },
                  }));
                }}
                logic={onTheFlySegment?.logic}
                setLogic={(logic) => {
                  setEvolution((evolution) => ({
                    ...evolution,
                    onTheFlySegment:
                      logic == null
                        ? null
                        : {
                            ...evolution.onTheFlySegment,
                            logic,
                          },
                  }));
                }}
              />
            )}
          </div>
        </div>
        <div
          className={classNames('poke-segment-users-wrapper', {
            'need-refresh': hasChanges() === true,
          })}>
          <div className="poke-segment-users-content">
            {hasSetupSegmentation !== true ? (
              <div className="empty-segmentation">
                <Avatar />
                Select a segment or add attributes to preview your targeted
                users list
              </div>
            ) : isLoading === true && jimers.total === 0 ? (
              <div className="loader-wrapper">
                <DefaultLoader />
              </div>
            ) : (
              <>
                <div className="count-users">
                  <i className="icon-users" />
                  {jimers?.total} users found
                </div>
                <BottomScrollListener
                  triggerOnNoScroll
                  offset={30}
                  onBottom={() => {
                    if (
                      jimers.data.length < jimers.total &&
                      isLoading !== true
                    ) {
                      fetchJimers(page + 1);
                    }
                  }}>
                  {(scrollRef) => (
                    <div className="list-users" ref={scrollRef}>
                      {jimers?.data?.map((jimer) => {
                        const email =
                          jimer.personalEmail != null
                            ? jimer.personalEmail
                            : jimer.externalEmail;
                        const username =
                          hasUsernameGenerated(jimer.username) &&
                          jimer.externalUsername != null
                            ? jimer.externalUsername
                            : jimer.username;

                        return (
                          <div className="jimer">
                            <Avatar jimer={jimer} />
                            <div className="infos">
                              <div className="name">{username}</div>
                              <div className="email">{email}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </BottomScrollListener>
              </>
            )}
          </div>
          {hasChanges() === true && (
            <div className="refresh-wrapper">
              <Button onClick={handleRefresh}>Refresh</Button>
            </div>
          )}
        </div>
      </div>
      <div className="actions">
        <Button
          muted
          className="cancel-btn"
          onClick={() => {
            closeModal();
          }}>
          Cancel
        </Button>
        <Button reverted primary disabled={isSaveDisabled} onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

ModalSegmentation.propTypes = propTypes;
ModalSegmentation.defaultProps = defaultProps;

export default ModalSegmentation;
