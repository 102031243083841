import Axios from 'axios';

// Endpoint
const EP_ZAPIER_GET_GENERATE_API_KEY = '/zapier/generate-api-key';
const EP_ZAPIER_DELETE = (zapierId) => `/zapier/${zapierId}`;

// Method
export const generateApiKey = (projectId) => {
  return Axios.get(EP_ZAPIER_GET_GENERATE_API_KEY, {
    params: {projectId},
  }).then((response) => response.data);
};

export const deleteZapier = (zapierId) =>
  Axios.delete(EP_ZAPIER_DELETE(zapierId)).then((response) => response.data);
