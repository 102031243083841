import React, {forwardRef, useContext} from 'react';
import {AttributeContext} from '../..';
import InputGroup from 'components/Input';
import AttributeOperand, {
  OPERAND_OPTIONS,
  getTypeFromAttribute,
} from '../AttributeOperand';
import './_Styles.scss';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
} from 'services/steps';
import SelectGroup from 'components/Select';
import {
  BLOCK_CHOICE,
  BLOCK_OPINION,
} from 'shared/front/components/Poke/constants/blocks';
import {
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import {hasFlag} from 'helpers/bitwise';
import {CHOICE_CAN_MULTIPLE} from 'scenes/PokeBuilder/components/BlockManager/utils';

const NumberAttributeValue = ({min, max, label}) => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {values} = attribute;

  return (
    <>
      <InputGroup
        placeholder={label ? '' : 'enter value...'}
        value={values[0]}
        type="number"
        min={min}
        max={max}
        onChange={({target}) =>
          // only update the first value
          updateAttribute({
            ...attribute,
            values: [target.value, values[1]],
          })
        }
        labelTextRight={label}
      />
    </>
  );
};

const OpinionScaleAttributeValue = () => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {step, values} = attribute;
  const block = step.blocks.find((b) => b.type === BLOCK_OPINION);
  const opinionValue = parseOpinionValue(block?.value || '');

  const emojiOptions = opinionTypes[opinionValue.type][opinionValue.scale];

  const starOptions = Array.from({length: opinionValue?.scale}).map(
    (_, index) => ({
      label: Array.from({length: index + 1}).map((_, i) => (
        <i className="icon-star" key={i} />
      )),
      value: index + 1,
    })
  );

  const options = ['emoji', 'smiley'].includes(opinionValue.type)
    ? emojiOptions
    : starOptions;

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={options}
      closeMenuOnSelect={true}
      value={options.find((e) => e?.value === values[0]) || null}
      placeholder="Select value..."
      onChange={(e) => {
        updateAttribute({
          ...attribute,
          values: [e.value],
        });
      }}
    />
  );
};

const ChoiceAttributeValue = () => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {step, values, op} = attribute;
  const block = step.blocks.find((b) => b.type === BLOCK_CHOICE);

  const options = block?.options
    .sort((a, b) => a.indexOrder - b.indexOrder)
    .map((o) => ({
      label: o.content,
      value: o.uid,
    }));

  const isChoiceMulti = hasFlag(CHOICE_CAN_MULTIPLE, block?.flags);
  const isMulti =
    isChoiceMulti === true &&
    [OPERAND_OPTIONS.OP_IS.value, OPERAND_OPTIONS.OP_NIS.value].includes(op);

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={options}
      closeMenuOnSelect={true}
      isMulti={isMulti}
      value={
        (isMulti
          ? options.filter((o) => values.includes(o.value))
          : options.find((e) => e?.value === values[0])) || null
      }
      placeholder="Select value..."
      onChange={(e) => {
        updateAttribute({
          ...attribute,
          values: isMulti ? e?.map((o) => o.value) : [e.value],
        });
      }}
    />
  );
};

const AttributeEvolutionStepValue = () => {
  const {attribute} = useContext(AttributeContext);
  const {step} = attribute;

  if (step == null) {
    return <></>;
  }

  let content;

  if (step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
    content = <ChoiceAttributeValue />;
  } else if ([STEP_TYPE_NPS, STEP_TYPE_SLIDER].includes(step?.type)) {
    content = (
      <NumberAttributeValue
        min={0}
        max={step?.type === STEP_TYPE_NPS ? 10 : 100}
        label={step?.type === STEP_TYPE_SLIDER ? '%' : null}
      />
    );
  } else if (step?.type === STEP_TYPE_OPINION_SCALE) {
    content = <OpinionScaleAttributeValue />;
  }

  const choiceBlock = step?.blocks.find((b) => b.type === BLOCK_CHOICE);
  const isChoiceMulti = hasFlag(CHOICE_CAN_MULTIPLE, choiceBlock?.flags);

  const operandOptions =
    step?.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
      ? [
          OPERAND_OPTIONS.OP_IS,
          OPERAND_OPTIONS.OP_NIS,
          ...(isChoiceMulti === true
            ? [OPERAND_OPTIONS.OP_CONTAINS, OPERAND_OPTIONS.OP_NCONTAINS]
            : []),
        ]
      : [STEP_TYPE_NPS, STEP_TYPE_SLIDER, STEP_TYPE_OPINION_SCALE].includes(
          step?.type
        )
      ? [
          OPERAND_OPTIONS.OP_IS,
          OPERAND_OPTIONS.OP_NIS,
          OPERAND_OPTIONS.OP_IS_GREATER,
          OPERAND_OPTIONS.OP_IS_GREATER_OR_EQUAL,
          OPERAND_OPTIONS.OP_IS_LESSER,
          OPERAND_OPTIONS.OP_IS_LESSER_OR_EQUAL,
        ]
      : [];

  return (
    <div className="attribute-value">
      <AttributeOperand options={operandOptions} />
      {content}
    </div>
  );
};

export default AttributeEvolutionStepValue;
