import {generalActions} from 'actions';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {Environment} from 'conf/env';
import {errorHelpers} from 'helpers';
import Lottie from 'lottie-react';
import {posthog} from 'posthog-js';
import querystring from 'query-string';
import {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {ROUTE_REGISTER_SETUP_PASSWORD} from 'router/routes.const';
import {authService} from 'services';
import {Swaler} from 'swaler';
import './_email-sent.scss';
import animation from './animation.json';

const logger = new Swaler('EmailSent');

export const OnboardingRegisterEmailSent = () => {
  const history = useHistory();
  const qs = querystring.parse(useLocation().search);

  const dispatch = useDispatch();

  const setUser = (user) => dispatch(generalActions.setUser(user));

  const animRef = useRef(null);

  useEffect(() => {
    if (animRef.current != null) {
      setTimeout(() => {
        animRef.current.play();
      }, 200);
    }
  }, []);

  useEffect(() => {
    const checkCode = async () => {
      try {
        const result = await authService.verifyEmail(qs.c);

        authService.setAuthTokens(result.token);
        history.push(ROUTE_REGISTER_SETUP_PASSWORD, {c: qs.c});
        setUser(result.user); // Sign In
        if (Environment.NODE_ENV === 'production') {
          posthog.identify(result.user.uid);
        }
        window.jimo.push(['set', 'user:email', [result.user.email]]);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        toastDanger([title, message], {actions});
        return logger.error(
          "couldn't verify email with code, failed with code ",
          code
        );
      }
    };

    if (qs.c != null) {
      checkCode();
    }
  }, []);

  return (
    <div className="s-onboarding-register-email-sent">
      {qs.c == null ? (
        <>
          <Lottie
            style={{
              height: '99px',
            }}
            lottieRef={animRef}
            animationData={animation}
            initialSegment={[0, 45]}
            loop={false}
            autoplay={false}></Lottie>
          <h1>
            Alright! <br />
            Checkout your inbox.
          </h1>
          <p>
            You’re almost there, please check your inbox, we’ve sent you an
            email to confirm your sign up.
          </p>
          <div className="info-spam">
            <i className="icon-info-circle-o"></i>
            If your email is not in your inbox, please check your spam box.
          </div>
        </>
      ) : (
        <>
          <Loader></Loader>
        </>
      )}
    </div>
  );
};
