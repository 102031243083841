import React, {useContext} from 'react';
import {AttributeContext} from '../..';
import {OPERAND_OPTIONS} from '../AttributeOperand';
import './_Styles.scss';
import SelectGroup from 'components/Select';
import {useQuery} from 'react-query';
import {tagService} from 'services';
import {TAG_CONTEXT_USER} from 'services/tag';
import {Swaler} from 'swaler';

const logger = new Swaler('AttributeTagsValue');

const AttributeTagsValue = () => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {values, op} = attribute;

  const {data: listTags = [], isLoading} = useQuery({
    queryKey: ['tags', 'users'],
    queryFn: async () =>
      tagService.getTags({
        contexts: [TAG_CONTEXT_USER],
      }),
    refetchOnWindowFocus: false,
    onError: (err) => {
      logger.error(
        'Could not fetch evolutions list, failed with err',
        err.message
      );
    },
  });

  const options = listTags
    .sort((a, b) => a.name - b.name)
    .map((o) => ({
      label: o.name,
      value: o.uid,
    }));

  const isMulti = [
    OPERAND_OPTIONS.OP_IS.value,
    OPERAND_OPTIONS.OP_NIS.value,
  ].includes(op);

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={options}
      closeMenuOnSelect={true}
      isMulti={isMulti}
      isLoading={isLoading}
      value={
        (isMulti
          ? options.filter((o) => values.includes(o.value))
          : options.find((e) => e?.value === values[0])) || null
      }
      placeholder={isMulti === true ? 'Select tags...' : 'Select tag...'}
      onChange={(e) => {
        updateAttribute({
          ...attribute,
          values: isMulti ? e?.map((o) => o.value) : [e.value],
        });
      }}
    />
  );
};

export default AttributeTagsValue;
