import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {environmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalEnvironment');

const ModalEnvironment = ({
  isOpen,
  onRequestClose,
  environment,
  onEdit,
  onDelete,
}) => {
  const [isDeleting, setIsDeleting] = useState(null);

  const {name, color, icon, domains, description} = environment || {};

  const domainsArr = domains.split(';').filter((domain) => domain.length > 0);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await environmentService.deleteEnvironment(environment.uid);

      toastSuccess('Environment deleted successfully');
      onDelete();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to delete environment with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-environment">
      <div className="modal-environment-header">
        <div className="icon-wrapper" style={{backgroundColor: color}}>
          {icon}
        </div>
        <div className="info-wrapper">
          <div className="subtitle-2 n-800">{name}</div>
          <div className="body-3 n-700">{domainsArr.length} domains</div>
        </div>
        <div className="actions-wrapper">
          <Dropdown
            className="dropdown-more-actions"
            triggerClassName="menu-dropdown-trigger"
            trigger={
              <Button thin className="menu-btn" iconOnly disabled={isDeleting}>
                <i className="icon-menu-vertical" />
              </Button>
            }
            position="bottom right"
            offsetY={8}
            contentStyle={{zIndex: 1002}}>
            <Menu>
              <MenuItem onClick={onEdit}>
                <i className="isax isax-edit-2" />
                Edit
              </MenuItem>
              <MenuItem className="r-400" onClick={handleDelete}>
                <i className="isax isax-trash" />
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </div>
      <div className="modal-environment-content">
        <div className="section">
          <div className="label body-3 n-600">Description</div>
          <div className="description body-2 n-800">
            {description || 'No description'}
          </div>
        </div>
        <div className="section">
          <div className="label body-3 n-600">Domains</div>
          <div className="domains-wrapper">
            {domainsArr.map((domain) => (
              <div key={domain} className="domain body-2 n-800">
                <i className="isax isax-global n-700" />
                {domain}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEnvironment;
