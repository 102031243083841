import {loadStripe} from '@stripe/stripe-js';
import {Environment} from './env';

let stripePromised = null;

const initStripe = () => {
  stripePromised = loadStripe(Environment.STRIPE_PK);
};

export {initStripe, stripePromised};
