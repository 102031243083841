import './_Styles.scss';
import EmptyCta from './imgs/empty_cta.svg';
import EmptyGoal from './imgs/empty_goal.svg';
import EmptyIssues from './imgs/empty_issues.svg';
import EmptyPosts from './imgs/empty_posts.svg';
import EmptyRequests from './imgs/empty_requests.svg';
import EmptyResponses from './imgs/empty_responses.svg';
import EmptyResults from './imgs/empty_results.svg';
import EmptyTriggers from './imgs/empty_triggers.svg';
import EmptyUsersReached from './imgs/empty_users_reached.svg';
import EmptyComments from './imgs/empty_comments.svg';

export const EmptyStateImgs = {
  EmptyUsersReached,
  EmptyResults,
  EmptyIssues,
  EmptyResponses,
  EmptyPosts,
  EmptyCta,
  EmptyRequests,
  EmptyGoal,
  EmptyTriggers,
  EmptyComments,
};

export const EmptyStateBlock = ({img, title, description}) => (
  <div className="empty-state-block">
    <div className="img-wrapper">
      <img src={img} alt="empty state" />
    </div>
    <div className="empty-state-content">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  </div>
);
