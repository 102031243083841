import Axios from 'axios';
import {generalSelector} from 'selectors';

export const CIO_EVENTS_PROPERTIES = [
  {uid: 'a85a2b0a-1e3a-4a52-be1a-683125ca2650', name: 'triggerId'},
  {uid: '841bf3a5-f2ab-4ff5-9793-4334074e52dc', name: 'deliveryId'},
  {uid: 'c2b1f64d-1e94-47a3-81c3-ed830af2bd2a', name: 'broadcastId'},
  {uid: '03703d7e-9110-4865-ae6a-7414f63875d7', name: 'journeyId'},
  {uid: 'deb41f38-a6cb-4cb7-9a45-207091429525', name: 'transactionalMessageId'},
  {uid: 'c7ec27d1-75ab-4e1a-897c-64f5540f2e60', name: 'newsletterId'},
  {uid: '0c3d09ce-e528-4b90-8267-0722436c0861', name: 'actionId'},
  {uid: '874ccf1c-b3c2-447d-9d97-c0dd1fde2b6a', name: 'parentActionId'},
];

// Endpoint
const EP_CUSTOMERIO_CREATE = '/integrations/cio';
const EP_CUSTOMERIO_UPDATE = (projectId) => `/integrations/cio/${projectId}`;
const EP_CUSTOMERIO_DELETE = (projectId) => `/integrations/cio/${projectId}`;
const EP_CUSTOMERIO_CREATE_REPORTING_WEBHOOK = (cioId) =>
  `/integrations/cio/${cioId}/reporting-webhook`;
const EP_CUSTOMERIO_DELETE_REPORTING_WEBHOOK = (cioId) =>
  `/integrations/cio/${cioId}/reporting-webhook`;
const EP_CUSTOMERIO_GET_REPORTING_WEBHOOK = (cioId) =>
  `/integrations/cio/${cioId}/reporting-webhook`;
const EP_CIO_GET_EVENTS = `/integrations/cio/events`;

// Method
export const createCioIntegration = (data) => {
  const {trackSiteId, trackApiKey, appApiKey, identifyField} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_CUSTOMERIO_CREATE, {
    projectId,
    trackSiteId,
    trackApiKey,
    appApiKey,
    identifyField,
  }).then((response) => response.data);
};

export const updateCioIntegration = (data) => {
  const {identifyField} = data;
  const cioId = generalSelector.getProject().cio?.uid;

  return Axios.put(EP_CUSTOMERIO_UPDATE(cioId), {identifyField}).then(
    (response) => response.data
  );
};

export const deleteCioIntegration = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.delete(EP_CUSTOMERIO_DELETE(projectId)).then(
    (response) => response.data
  );
};

export const createReportingWebhook = () => {
  const cioId = generalSelector.getProject().cio?.uid;

  return Axios.post(EP_CUSTOMERIO_CREATE_REPORTING_WEBHOOK(cioId)).then(
    (response) => response.data
  );
};

export const deleteReportingWebhook = () => {
  const cioId = generalSelector.getProject().cio?.uid;

  return Axios.delete(EP_CUSTOMERIO_DELETE_REPORTING_WEBHOOK(cioId)).then(
    (response) => response.data
  );
};

export const getCioReportingWebhook = () => {
  const cioId = generalSelector.getProject().cio?.uid;

  return Axios.get(EP_CUSTOMERIO_GET_REPORTING_WEBHOOK(cioId)).then(
    (response) => response.data
  );
};

export const getCioEvents = (projectId = generalSelector.getProject().uid) => {
  return Axios.get(EP_CIO_GET_EVENTS, {params: {projectId}}).then(
    (response) => response.data
  );
};
