import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_BUILD_URL_GET = '/build-url';
const EP_BUILD_URL_CREATE = '/build-url';
const EP_BUILD_URL_DELETE = (urlId) => `/build-url/${urlId}`;

// Method
export const getBuildUrls = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_BUILD_URL_GET, {
    params: {projectId},
  }).then((response) => response.data);
};

export const createBuildUrl = (data) => {
  const {url} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_BUILD_URL_CREATE, {
    url,
    projectId,
  }).then((response) => response.data);
};

export const deleteBuildUrl = (urlId) => {
  return Axios.delete(EP_BUILD_URL_DELETE(urlId), {}).then(
    (response) => response.data
  );
};
