import React from 'react';
import './_Styles.scss';
import TrackerBlock from '../TrackerBlock';
import LastActivity from './components/LastActivity';

const propTypes = {};

const defaultProps = {};

const TrackerUsers = () => {
  return (
    <TrackerBlock
      className="tracker-users"
      header={
        <div className="title">
          <i className="isax isax-profile-2user" />
          Last activity
        </div>
      }>
      <div className="tracker-users-content-wrapper">
        <LastActivity />
      </div>
    </TrackerBlock>
  );
};

TrackerUsers.propTypes = propTypes;
TrackerUsers.defaultProps = defaultProps;

export default React.memo(TrackerUsers);
