import {miscActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PushesContext} from 'scenes/Pushes/context';
import {VIEW_ALL_ID} from 'scenes/Pushes/with_context';
import {miscSelector} from 'selectors';
import {
  EVOLUTION_STATE_DRAFT,
  EVOLUTION_STATE_EXPIRED,
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  EVOLUTION_STATE_SCHEDULED,
} from 'services/evolution';
import {
  VIEW_LAYOUT_GRID,
  VIEW_LAYOUT_ROW,
  VIEW_LAYOUT_ROW_LARGE,
} from 'services/evolution-list-view';
import './_Styles.scss';

export const ViewEditor = () => {
  const {
    viewId,
    view,
    segments,
    tags,
    environments,
    applyChanges,
    resetChanges,
    hasChanges,
    handleSave,
  } = useContext(PushesContext);

  const dispatch = useDispatch();

  const defaultViewLayout = useSelector((state) =>
    miscSelector.getDefaultViewLayout(state)
  );

  const setDefaultViewLayout = (layout) =>
    dispatch(miscActions.setDefaultViewLayout(layout));

  const viewSegments =
    view.segments?.map((segmentId) =>
      segments.find((s) => s.uid === segmentId)
    ) ?? [];
  const viewTags =
    view.tags?.map((tagId) => tags.find((s) => s.uid === tagId)) ?? [];
  const viewEnvironments =
    view.environments?.map((environmentId) =>
      environments.find((e) => e.uid === environmentId)
    ) ?? [];
  const viewLayout = view?.layout ?? defaultViewLayout;

  const handleUpdateStates = (value) => {
    const hasState = view.states != null && view.states.indexOf(value) > -1;
    const updatedStates =
      hasState === true
        ? view.states.filter((c) => c !== value)
        : view.states == null
        ? [value]
        : view.states.concat(value);

    applyChanges({
      states:
        updatedStates.length !== 0
          ? updatedStates
          : viewId === VIEW_ALL_ID
          ? undefined
          : [], // On a specific view, an empty means that no contexts filter should be pass
    });
  };
  const handleUpdateSegments = (value) => {
    const hasSegment =
      viewSegments != null &&
      viewSegments.findIndex((s) => s.uid === value.uid) > -1;
    const updatedSegments =
      hasSegment === true
        ? viewSegments.filter((s) => s.uid !== value.uid)
        : viewSegments == null
        ? [value]
        : viewSegments.concat(value);

    applyChanges(
      updatedSegments.length !== 0
        ? {
            segments: updatedSegments.map((s) => s.uid),
          }
        : {
            segments: viewId === VIEW_ALL_ID ? undefined : [], // On a specific view, an empty means that no contexts filter should be pass
          }
    );
  };
  const handleUpdateTags = (value) => {
    const hasTag =
      viewTags != null && viewTags.findIndex((t) => t.uid === value.uid) > -1;
    const updatedTags =
      hasTag === true
        ? viewTags.filter((t) => t.uid !== value.uid)
        : viewTags == null
        ? [value]
        : viewTags.concat(value);

    applyChanges(
      updatedTags.length !== 0
        ? {
            tags: updatedTags.map((t) => t.uid),
          }
        : {
            tags: viewId === VIEW_ALL_ID ? undefined : [], // On a specific view, an empty means that no contexts filter should be pass
          }
    );
  };
  const handleUpdateEnvironments = (value) => {
    const hasEnvironment =
      viewEnvironments != null &&
      viewEnvironments.findIndex((e) => e.uid === value.uid) > -1;
    const updatedEnvironments =
      hasEnvironment === true
        ? viewEnvironments.filter((e) => e.uid !== value.uid)
        : viewEnvironments == null
        ? [value]
        : viewEnvironments.concat(value);

    applyChanges(
      updatedEnvironments.length !== 0
        ? {
            environments: updatedEnvironments.map((t) => t.uid),
          }
        : {
            environments: viewId === VIEW_ALL_ID ? undefined : [], // On a specific view, an empty means that no contexts filter should be pass
          }
    );
  };
  const handleUpdateLayout = (value) => {
    if (viewId === VIEW_ALL_ID) {
      setDefaultViewLayout(value);
    } else {
      applyChanges({
        layout: value,
      });
    }
  };

  return (
    <div className="pushes-view-editor">
      <div className="view-settings">
        <Dropdown
          className="view-setting view-status"
          position="bottom left"
          triggerClassName={classnames({
            'is-set': view?.states != null && view?.states?.length > 0,
          })}
          repositionOnResize={false}
          trigger={
            <Button
              className="view-setting-btn body-3"
              thin
              iconLeft="isax isax-tick-circle"
              iconRight="icon-chevron-bottom">
              {view?.states == null || view?.states?.length === 0
                ? 'Status'
                : view?.states?.length === 1
                ? `${view?.states[0]?.toLowerCase()}`
                : `${view?.states[0]?.toLowerCase()} +${
                    view?.states?.length - 1
                  }`}
            </Button>
          }>
          <div className="setting-list">
            <div
              className={classnames('setting-item', {
                'is-set': view?.states?.includes(EVOLUTION_STATE_LIVE),
              })}
              onClick={() => handleUpdateStates(EVOLUTION_STATE_LIVE)}>
              {view?.states?.includes(EVOLUTION_STATE_LIVE) === true ? (
                <i className="icon-checkbox"></i>
              ) : (
                <i className="icon-checkbox-o"></i>
              )}
              Live
            </div>
            <div
              className={classnames('setting-item', {
                'is-set': view?.states?.includes(EVOLUTION_STATE_PAUSED),
              })}
              onClick={() => handleUpdateStates(EVOLUTION_STATE_PAUSED)}>
              {view?.states?.includes(EVOLUTION_STATE_PAUSED) === true ? (
                <i className="icon-checkbox"></i>
              ) : (
                <i className="icon-checkbox-o"></i>
              )}{' '}
              Paused
            </div>
            <div
              className={classnames('setting-item', {
                'is-set': view?.states?.includes(EVOLUTION_STATE_SCHEDULED),
              })}
              onClick={() => handleUpdateStates(EVOLUTION_STATE_SCHEDULED)}>
              {view?.states?.includes(EVOLUTION_STATE_SCHEDULED) === true ? (
                <i className="icon-checkbox"></i>
              ) : (
                <i className="icon-checkbox-o"></i>
              )}
              Scheduled
            </div>
            <div
              className={classnames('setting-item', {
                'is-set': view?.states?.includes(EVOLUTION_STATE_EXPIRED),
              })}
              onClick={() => handleUpdateStates(EVOLUTION_STATE_EXPIRED)}>
              {view?.states?.includes(EVOLUTION_STATE_EXPIRED) === true ? (
                <i className="icon-checkbox"></i>
              ) : (
                <i className="icon-checkbox-o"></i>
              )}{' '}
              Expired
            </div>
            <div
              className={classnames('setting-item', {
                'is-set': view?.states?.includes(EVOLUTION_STATE_DRAFT),
              })}
              onClick={() => handleUpdateStates(EVOLUTION_STATE_DRAFT)}>
              {view?.states?.includes(EVOLUTION_STATE_DRAFT) === true ? (
                <i className="icon-checkbox"></i>
              ) : (
                <i className="icon-checkbox-o"></i>
              )}{' '}
              Draft
            </div>
          </div>
        </Dropdown>
        <Dropdown
          className="view-setting view-status"
          position="bottom left"
          triggerClassName={classnames({
            'is-set': viewSegments != null && viewSegments?.length > 0,
          })}
          repositionOnResize={false}
          trigger={
            <Button
              className="view-setting-btn body-3"
              thin
              iconLeft="isax isax-profile-circle"
              iconRight="icon-chevron-bottom">
              {viewSegments == null || viewSegments?.length === 0
                ? 'Segments'
                : viewSegments?.length === 1
                ? `${viewSegments[0]?.name?.toLowerCase()}`
                : `${viewSegments[0]?.name?.toLowerCase()} +${
                    viewSegments?.length - 1
                  }`}
            </Button>
          }>
          <div className="setting-list">
            {segments.length === 0 && (
              <div className="list-empty-state">No segment created yet</div>
            )}
            {segments.map((s) => {
              return (
                <div
                  className={classnames('setting-item', {
                    'is-set':
                      viewSegments?.findIndex((vs) => vs.uid === s.uid) > -1,
                  })}
                  onClick={() => handleUpdateSegments(s)}>
                  {viewSegments?.findIndex((vs) => vs.uid === s.uid) > -1 ? (
                    <i className="icon-checkbox"></i>
                  ) : (
                    <i className="icon-checkbox-o"></i>
                  )}{' '}
                  {s.name}
                </div>
              );
            })}
          </div>
        </Dropdown>
        <Dropdown
          className="view-setting view-tags"
          position="bottom left"
          triggerClassName={classnames({
            'is-set': viewTags != null && viewTags?.length > 0,
          })}
          repositionOnResize={false}
          trigger={
            <Button
              className="view-setting-btn body-3"
              thin
              iconLeft="isax isax-tag"
              iconRight="icon-chevron-bottom">
              {viewTags == null || viewTags?.length === 0
                ? 'Tags'
                : viewTags?.length === 1
                ? `${viewTags[0]?.name?.toLowerCase()}`
                : `${viewTags[0]?.name?.toLowerCase()} +${
                    viewTags?.length - 1
                  }`}
            </Button>
          }>
          <div className="setting-list">
            {tags.length === 0 && (
              <div className="list-empty-state">No tags created yet</div>
            )}
            {tags.map((t) => {
              return (
                <div
                  className={classnames('setting-item', {
                    'is-set':
                      viewTags?.findIndex((vt) => vt.uid === t.uid) > -1,
                  })}
                  style={{
                    border: `1px solid ${t.color}`,
                    backgroundColor: `${t.color}20`,
                    color: t.color,
                  }}
                  onClick={() => handleUpdateTags(t)}>
                  {viewTags?.findIndex((vt) => vt.uid === t.uid) > -1 ? (
                    <i className="icon-checkbox"></i>
                  ) : (
                    <i className="icon-checkbox-o"></i>
                  )}{' '}
                  {t.name}
                </div>
              );
            })}
          </div>
        </Dropdown>
        <Dropdown
          className="view-setting view-environments"
          position="bottom left"
          triggerClassName={classnames({
            'is-set': viewEnvironments != null && viewEnvironments?.length > 0,
          })}
          repositionOnResize={false}
          trigger={
            <Button
              className="view-setting-btn body-3"
              thin
              iconLeft="isax isax-global"
              iconRight="icon-chevron-bottom">
              {viewEnvironments == null || viewEnvironments?.length === 0
                ? 'Environments'
                : viewEnvironments?.length === 1
                ? `${viewEnvironments[0]?.name?.toLowerCase()}`
                : `${viewEnvironments[0]?.name?.toLowerCase()} +${
                    viewEnvironments?.length - 1
                  }`}
            </Button>
          }>
          <div className="setting-list">
            {environments.length === 0 && (
              <div className="list-empty-state">
                No environments created yet
              </div>
            )}
            {environments.map((e) => {
              return (
                <div
                  className={classnames('setting-item', {
                    'is-set':
                      viewEnvironments?.findIndex((ve) => ve.uid === e.uid) >
                      -1,
                  })}
                  onClick={() => handleUpdateEnvironments(e)}>
                  {viewEnvironments?.findIndex((ve) => ve.uid === e.uid) >
                  -1 ? (
                    <i className="icon-checkbox" />
                  ) : (
                    <i className="icon-checkbox-o" />
                  )}{' '}
                  <div
                    className="icon-wrapper"
                    style={{backgroundColor: e.color}}>
                    {e.icon}
                  </div>
                  {e.name}
                </div>
              );
            })}
          </div>
        </Dropdown>
        <Divider vertical />
        <div className="view-layouts">
          <Button
            iconOnly
            className={classnames({
              'is-set':
                viewLayout === VIEW_LAYOUT_ROW_LARGE || viewLayout == null,
            })}
            onClick={() => handleUpdateLayout(VIEW_LAYOUT_ROW_LARGE)}>
            <i className="icon-view-row"></i>
          </Button>
          <Button
            iconOnly
            className={classnames({
              'is-set': viewLayout === VIEW_LAYOUT_GRID,
            })}
            onClick={() => handleUpdateLayout(VIEW_LAYOUT_GRID)}>
            <i className="icon-view-grid"></i>
          </Button>
          <Button
            className={classnames({
              'is-set': viewLayout === VIEW_LAYOUT_ROW,
            })}
            iconOnly
            onClick={() => handleUpdateLayout(VIEW_LAYOUT_ROW)}>
            <i className="icon-view-row-compact"></i>
          </Button>
        </div>
      </div>
      <div className="view-actions">
        {hasChanges() === true && (
          <>
            <Button secondary thin onClick={() => resetChanges()}>
              Reset
            </Button>
            <>
              {view?.uid != null ? (
                <Button secondary thin onClick={handleSave}>
                  Save view
                </Button>
              ) : (
                <>
                  <Button secondary thin onClick={handleSave}>
                    Save as new view
                  </Button>
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
};
