import classnames from 'classnames';
import PropTypes from 'prop-types';
import './_Styles.scss';

const TextArea = (props) => {
  const _getClassName = () =>
    classnames('text-area', props.className, props.intent, {
      disabled: props.disabled,
      large: props.large,
      small: props.small,
      round: props.round,
    });
  const {
    name,
    id,
    placeholder,
    style,
    required,
    onChange,
    value,
    rows,
    innerRef,
    innerComponentRef,
    disabled,

    legend,
    legendClassName,

    className,
    intent,
    large,
    small,
    round,
    ...rest
  } = props;

  return (
    <div className={_getClassName()} ref={innerComponentRef}>
      {legend != null && (
        <div
          className={classnames(
            'textarea-legend',
            legendClassName != null ? legendClassName : 'body-3'
          )}>
          {legend}
        </div>
      )}
      <textarea
        name={name}
        id={id}
        className="input"
        ref={innerRef}
        style={style}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        rows={rows}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};

TextArea.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  round: PropTypes.bool,
  style: PropTypes.object,
  inputRef: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  round: true,
  required: false,
  onChange: () => {},
  disabled: false,
};

export default TextArea;
