// Types
export const BUILDER_SET_POKE = 'BUILDER_SET_POKE';
export const BUILDER_SET_SELECTED_TOUR_STEP_ID =
  'BUILDER_SET_SELECTED_TOUR_STEP_ID';
export const BUILDER_SET_SELECTED_STEP_ID = 'BUILDER_SET_SELECTED_STEP_ID';
export const BUILDER_SET_IS_NAVIGATING = 'BUILDER_SET_IS_NAVIGATING';

// Methods
export const setPoke = (poke) => ({
  type: BUILDER_SET_POKE,
  poke,
});

export const setSelectedTourStepId = (tourStepId) => ({
  type: BUILDER_SET_SELECTED_TOUR_STEP_ID,
  tourStepId,
});

export const setSelectedStepId = (stepId) => ({
  type: BUILDER_SET_SELECTED_STEP_ID,
  stepId,
});

export const setIsNavigating = (isNavigating = false) => ({
  type: BUILDER_SET_IS_NAVIGATING,
  isNavigating,
});
