import {uptProject} from 'actions/general';
import ColorPicker from 'components/ColorPicker';
import {bool, func, object, string} from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import PopupSetting from '../../PopupSetting';
import './_Styles.scss';
import ClickableInput from '../ClickableInput';

const propTypes = {
  value: string,
  onChange: func,
  erasable: bool,
  disableAlpha: bool,
  inputProps: object,
  setParentCloseOnDocumentClick: func,
};
const defaultProps = {
  value: '#1557DB',
  onChange: () => {},
  erasable: false,
  disableAlpha: false,
  inputProps: {},
  setParentCloseOnDocumentClick: () => {},
};

const defaultValue = '#1557DB';

const ColorPickerInput = ({
  title,
  value,
  onChange,
  erasable,
  disableAlpha,
  inputProps,
  setParentCloseOnDocumentClick,
}) => {
  const dispatch = useDispatch();
  const updateProject = (project) => {
    dispatch(uptProject(project));
  };
  const project = useSelector((state) => generalSelector.getProject(state));

  const [showPicker, setShowPicker] = useState(false);
  const [colorToSave, setColorToSave] = useState(null);

  useEffect(() => {
    const saveColor = async () => {
      const updatedProject = await projectService.updateProject(project.uid, {
        recentColors: [
          colorToSave,
          ...(project.recentColors || '')
            .split(';')
            .filter((c) => c && c !== colorToSave)
            .slice(0, 15),
        ].join(';'),
      });
      updateProject(updatedProject);
    };
    if (showPicker === false) {
      if (colorToSave != null) {
        saveColor();
      }
    } else {
      setColorToSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPicker]);

  return (
    <div className="color-picker-input">
      <PopupSetting
        className="color-picker-popup"
        trigger={
          <ClickableInput
            className="input-color"
            value={value || ''}
            leftLabel={
              <span
                className="preview-color"
                style={{backgroundColor: value}}
              />
            }
            erasable={erasable}
            onErase={() => onChange(null)}
            inputProps={{
              ...inputProps,
            }}
          />
        }
        title={title}
        content={
          <ColorPicker
            color={value || defaultValue}
            disableAlpha={disableAlpha}
            onChange={(color) => {
              setColorToSave(color?.hexWithAlpha);
              onChange(color?.hexWithAlpha);
            }}
            onlyPicker={true}
          />
        }
        dropdownProps={{
          onOpen: () => {
            setParentCloseOnDocumentClick(false);
            setShowPicker(true);
          },
          onClose: () => {
            setParentCloseOnDocumentClick(true);
            setShowPicker(false);
          },
        }}
      />
    </div>
  );
};

ColorPickerInput.propTypes = propTypes;
ColorPickerInput.defaultProps = defaultProps;

export default ColorPickerInput;
