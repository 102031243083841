import Button from 'components/Button';
import {Modal} from 'components/Modal';
import React from 'react';
import Lottie from 'react-lottie';
import './_Styles.scss';
import confettiData from './confetti.animation.json';

const defaultLottieOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
  animationData: confettiData,
};

const ModalPublishSuccessWithPost = ({isOpen, onRequestClose, evolution}) => {
  return (
    <Modal
      title="🥳 Experience & Post are live!"
      isOpen={isOpen}
      onClose={onRequestClose}
      className="modal-publish-success-with-post">
      <div className="confetti-wrapper">
        <Lottie
          options={defaultLottieOptions}
          width="100%"
          height="100%"></Lottie>
      </div>
      <div className="content">
        Congrats, your experience "{evolution.title}" along with your post "
        {evolution.isBoostOf?.title}" have been published!
      </div>
      <div className="actions">
        <Button
          reverted
          primary
          className="btn-publish"
          onClick={onRequestClose}>
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export default ModalPublishSuccessWithPost;
