import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_CRISP} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {crispWidgetService} from 'services';
import {F_EXTRA_HAS_CRISP_WIDGET_SETUP} from 'services/project';
import {Swaler} from 'swaler';
import CrispLogo from '../imgs/crisp.jpeg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsCrisp');

const Crisp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSetupCrisp = async () => {
    setIsLoading(true);
    try {
      await crispWidgetService.setupCrispWidgetIntegration(!hasCrispSetup);

      if (hasCrispSetup === true) {
        updateProject({
          ...project,
          extraFlags: removeFlag(
            F_EXTRA_HAS_CRISP_WIDGET_SETUP,
            project.extraFlags
          ),
        });
      } else {
        updateProject({
          ...project,
          extraFlags: addFlag(
            F_EXTRA_HAS_CRISP_WIDGET_SETUP,
            project.extraFlags
          ),
        });
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup crisp integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const hasCrispSetup = hasFlag(
    F_EXTRA_HAS_CRISP_WIDGET_SETUP,
    project.extraFlags
  );

  return (
    <div className="settings-card integration-crisp">
      <div className="logo-action-wrapper">
        <img className="logo crisp-logo" src={CrispLogo} alt="crisp-logo" />
        {hasCrispSetup === false ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleSetupCrisp}>
            Connect
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_CRISP)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Crisp
          {hasCrispSetup === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Connected
            </div>
          )}
        </div>
        <div className="body-3">
          Crisp is a live chat software to engage with your customers in
          real-time.
        </div>
      </div>
    </div>
  );
};

export default Crisp;
