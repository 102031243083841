import axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoints
const EP_ANALYTICS_GET = '/evolution/analytics';
const EP_ANALYTICS_HOURLY_GET = '/evolution/analytics/hourly';
const EP_ANALYTICS_HOURLY_BY_SEGMENT_GET =
  '/evolution/analytics/hourly-by-segment';
const EP_ANALYTICS_HOURLY_BY_STEP_GET = '/evolution/analytics/hourly-by-step';
const EP_ANALYTICS_HOURLY_BY_STEP_BY_SEGMENT_GET =
  '/evolution/analytics/hourly-by-step-by-segment';
const EP_ANALYTICS_HOURLY_BY_BLOCK_GET = '/evolution/analytics/hourly-by-block';

// Methods
export const getAnalytics = ({startDate, endDate}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_GET, {params: {projectId}})
    .then((response) => response.data);
};
export const getAnalyticsHourly = ({startDate, endDate, evolutionIds}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_HOURLY_GET, {
      params: {projectId, evolutionIds, startDate, endDate},
    })
    .then((response) => response.data);
};

export const getAnalyticsHourlyBySegment = ({
  startDate,
  endDate,
  evolutionIds,
}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_HOURLY_BY_SEGMENT_GET, {
      params: {projectId, evolutionIds, startDate, endDate},
    })
    .then((response) => response.data);
};

export const getAnalyticsHourlyByStep = ({
  startDate,
  endDate,
  evolutionIds,
}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_HOURLY_BY_STEP_GET, {
      params: {projectId, evolutionIds, startDate, endDate},
    })
    .then((response) => response.data);
};

export const getAnalyticsHourlyByStepBySegment = ({
  startDate,
  endDate,
  evolutionIds,
}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_HOURLY_BY_STEP_BY_SEGMENT_GET, {
      params: {projectId, evolutionIds, startDate, endDate},
    })
    .then((response) => response.data);
};

export const getAnalyticsHourlyByBlock = ({startDate, endDate, blockIds}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_HOURLY_BY_BLOCK_GET, {
      params: {projectId, blockIds, startDate, endDate},
    })
    .then((response) => response.data);
};
