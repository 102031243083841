import Axios from 'axios';

// Endpoints
const EP_FILE_VIEWER_PROTOTYPE_FILE = (prototypeFileId) =>
  `/fileviewer/prototypefile/${prototypeFileId}`;

export const getPrototypeFileData = (prototypeFileId) => {
  return Axios.get(EP_FILE_VIEWER_PROTOTYPE_FILE(prototypeFileId), {}).then(
    (response) =>
      `data:${response.data.mime};base64,${Buffer.from(
        response.data.data,
        'binary'
      )}`
  );
};
