import {ModalConfirm} from 'components/Modal';
import {bool, func} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  isOpen: bool,
  onRequestClose: func,
  onConfirm: func,
};

const defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  onConfirm: () => {},
};

const PublishChangesModal = ({isOpen, onRequestClose, onConfirm}) => {
  return (
    <ModalConfirm
      className="publish-changes-modal"
      title={
        <>
          <div className="icon-wrapper">
            <i className="isax isax-info-circle" />
          </div>
          <span className="title-4 n-800">Publish Changes</span>
        </>
      }
      closable={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onConfirm={onConfirm}
      onCancel={onRequestClose}
      cancelText="Cancel"
      confirmText="Publish"
      cancelBtnProps={{
        cta: false,
      }}
      confirmBtnProps={{
        primary: true,
        cta: false,
      }}>
      <div className="content n-700">
        After publishing, analytics will adjust to new step orders and data.
        Previous responses and funnels are safe.
      </div>
    </ModalConfirm>
  );
};

PublishChangesModal.propTypes = propTypes;
PublishChangesModal.defaultProps = defaultProps;

export default PublishChangesModal;
