// import {htmlToText} from 'html-to-text';
import BasicEditor from 'components/MarkdownEditor/BasicEditor';
import {htmlToText} from 'html-to-text';
import {useEffect, useState} from 'react';

const ParagraphBlockInput = ({selectedLanguage, block, onChange}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [markdownEditorKey, setMarkdownEditorKey] = useState(null);

  const {value, rawValue, uid} = block;

  const inputValue =
    selectedLanguage != null
      ? block?.translations?.find((t) => t.language === selectedLanguage)
          ?.value || ''
      : value;
  const rawInputValue =
    selectedLanguage != null
      ? block?.translations?.find((t) => t.language === selectedLanguage)
          ?.rawValue || ''
      : rawValue;

  useEffect(() => {
    if (isEditing === false) {
      setMarkdownEditorKey(JSON.stringify(rawInputValue));
    }
  }, [rawInputValue]);

  const handleChange = ({value, rawValue}) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.translations?.find((t) => t.language === selectedLanguage) !=
        null;
      onChange({
        translations: hasLanguage
          ? block.translations.map((t) => {
              if (t.language === selectedLanguage) {
                return {
                  ...t,
                  value,
                  rawValue,
                };
              } else {
                return t;
              }
            })
          : [
              ...(block?.translations || []),
              {language: selectedLanguage, value, rawValue},
            ],
      });
    } else {
      onChange({value, rawValue});
    }
  };

  return (
    <BasicEditor
      key={`${selectedLanguage}-${markdownEditorKey}-${uid}`}
      value={inputValue}
      rawValue={rawInputValue}
      onChange={({value, rawValue}) => {
        handleChange({value, rawValue});
      }}
      placeholder={htmlToText(value || '')}
      className="paragraph-content-input"
      onFocus={() => setIsEditing(true)}
      onBlur={() => setIsEditing(false)}
    />
  );
};

export default ParagraphBlockInput;
