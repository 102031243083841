import React, {useContext, useState} from 'react';
import './_Styles.scss';
import TrackerBlock from '../TrackerBlock';
import TrackerStepsActivity from './components/TrackerStepsActivity';
import DateSelectionDropdown from 'components/DateSelectionDropdown';
import dayjs from 'dayjs';
import {TrackerContext} from 'scenes/Tracker';

const propTypes = {};

const defaultProps = {};

export const CHART_TYPE_STACKED = 'STACKED';
export const CHART_TYPE_COHORT = 'COHORT';

const MilestoneOverview = () => {
  const {tracker} = useContext(TrackerContext);

  const [timeRange, setTimeRange] = useState([
    // take bigger date between 1 month ago and evolution creation date
    dayjs(tracker?.createdAt).subtract(1, 'day').toDate() >
    dayjs().subtract(1, 'month').toDate()
      ? dayjs(tracker?.createdAt).subtract(1, 'day').toDate()
      : dayjs().subtract(1, 'month').toDate(),
    dayjs().toDate(),
  ]);
  const [startDate, endDate] = timeRange;

  return (
    <TrackerBlock
      className="tracker-milestone-overview"
      header={
        <>
          <div className="title">
            <i className="isax isax-chart-3" />
            Milestone overview
          </div>
          <div className="actions">
            <DateSelectionDropdown
              minDate={dayjs(tracker.createdAt).subtract(1, 'day').toDate()}
              startDate={startDate}
              endDate={endDate}
              setTimeRange={setTimeRange}
            />
          </div>
        </>
      }>
      <div className="tracker-steps-activity-wrapper">
        <TrackerStepsActivity timeRange={timeRange} />
      </div>
    </TrackerBlock>
  );
};

MilestoneOverview.propTypes = propTypes;
MilestoneOverview.defaultProps = defaultProps;

export default React.memo(MilestoneOverview);
