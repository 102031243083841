const {default: TextArea} = require('components/TextArea');

const OpenQuestionBlockInput = ({selectedLanguage, block, onChange}) => {
  const {value} = block;

  const inputValue =
    selectedLanguage != null
      ? block?.translations?.find((t) => t.language === selectedLanguage)
          ?.value || ''
      : value;

  const handleChange = (value) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.translations?.find((t) => t.language === selectedLanguage) !=
        null;
      onChange({
        translations: hasLanguage
          ? block.translations.map((t) => {
              if (t.language === selectedLanguage) {
                return {
                  ...t,
                  value,
                };
              } else {
                return t;
              }
            })
          : [
              ...(block?.translations || []),
              {language: selectedLanguage, value},
            ],
      });
    } else {
      onChange({
        value,
      });
    }
  };

  return (
    <TextArea
      value={inputValue}
      onChange={({target}) => handleChange(target.value)}
      placeholder={value}
      className="title-content-input"
    />
  );
};

export default OpenQuestionBlockInput;
