import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {authService, meService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalChangeEmail');

export default function ModalChangeEmail(props) {
  const user = useSelector((state) => generalSelector.getUser(state));

  const [inputEmail, setInputEmail] = useState('');
  const [inputSecurityCode, setInputSecurityCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasToEnterSecurityCode, setHasToEnterSecurityCode] = useState(false);

  const handleInputChange = useCallback(({target}) => {
    if (target.name === 'inputEmail') {
      setInputEmail(target.value);
    } else if (target.name === 'inputSecurityCode') {
      setInputSecurityCode(target.value);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!hasToEnterSecurityCode) {
      try {
        await authService.askForSecurityCode();
        setIsSubmitting(false);
        setHasToEnterSecurityCode(true);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Asking for security code failed with error ', code);
        setIsSubmitting(false);
        return toastDanger([title, message], {actions});
      }
    } else {
      try {
        await meService.updateEmail(inputEmail, inputSecurityCode);
        toastSuccess('Email updated!', {toastId: 'email-updated'});
        setInputEmail('');
        setInputSecurityCode('');
        setHasToEnterSecurityCode(false);
        setIsSubmitting(false);
        props.onRequestClose();
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Updating email failed with error ', code);
        setIsSubmitting(false);
        return toastDanger([title, message], {actions});
      }
    }
  };

  const emailSplit = user.email.split('@');

  return (
    <Modal className="modal-change-email" {...props}>
      <form onSubmit={handleSubmit}>
        <Input
          legend="New work mail"
          type="email"
          name="inputEmail"
          placeholder="you@company.com"
          value={inputEmail}
          onChange={handleInputChange}
          disabled={hasToEnterSecurityCode}
          required
        />
        {hasToEnterSecurityCode && (
          <>
            <Alert
              title="We've sent you a security code to your current email"
              icon="isax isax-sms">
              <span className="body-4 n-700">
                We've just sent a security code to{' '}
                {emailSplit[0]
                  .split('')
                  .map((letter, index) => (index === 0 ? letter : '*'))
                  .join('')
                  .concat('@')
                  .concat(emailSplit[1])}
                . Please check your inbox and input the code to confirm your
                changes. If you don't see the email, kindly check your spam
                folder.
              </span>
            </Alert>
            <Input
              legend="Security code"
              name="inputSecurityCode"
              placeholder="Security code"
              value={inputSecurityCode}
              onChange={handleInputChange}
            />
          </>
        )}
        <div className="actions">
          <Button
            type="button"
            muted
            onClick={() => {
              props.onRequestClose();
              setInputSecurityCode('');
              setHasToEnterSecurityCode(false);
              setInputEmail('');
            }}>
            Cancel
          </Button>
          <Button primary loading={isSubmitting}>
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
}
