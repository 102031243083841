import Button from 'components/Button';
import {Modal} from 'components/Modal';
import React from 'react';
import Lottie from 'react-lottie';
import './_Styles.scss';
import confettiData from './confetti.animation.json';

const defaultLottieOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
  animationData: confettiData,
};

const ModalPublishSuccess = ({isOpen, onRequestClose, evolution}) => {
  return (
    <Modal
      title="🥳 Congrats: Experience published!"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-publish-success">
      <div className="confetti-wrapper">
        <Lottie
          options={defaultLottieOptions}
          width="100%"
          height="100%"></Lottie>
      </div>
      <div className="content">
        Congrats, your experience "{evolution.title}" has been published! Now,
        feel free to access your experience page, to see results and get
        insights.
      </div>
      <div className="actions">
        <Button
          reverted
          primary
          className="btn-publish"
          onClick={onRequestClose}>
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export default ModalPublishSuccess;
