import {
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
} from '@styled-icons/material-rounded';
import {
  ELEMENT_OL,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate';
import Divider from 'components/Divider';
import React from 'react';
import EmojiDropdownMenu from '../EmojiToolbarDropdown';
import ImageToolbarButton from '../ImageToolbarButton';
import {LinkToolbarButton} from '../LinkToolbarButton';
import {ListToolbarButton} from '../ListToolbarButton';
import MarkToolbarButton from '../MarkToolbarButton';
import MediaToolbarButton from '../MediaToolbarButton';
import {TurnIntoDropdownMenu} from '../TurnIntoDropdownMenu';
import './_Styles.scss';

const AdvancedEditorToolbar = () => {
  return (
    <div className="advanced-editor-toolbar">
      <TurnIntoDropdownMenu />
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Bold'}
        nodeType={MARK_BOLD}>
        <FormatBold />
      </MarkToolbarButton>
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Italic'}
        nodeType={MARK_ITALIC}>
        <FormatItalic />
      </MarkToolbarButton>
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Underline'}
        nodeType={MARK_UNDERLINE}>
        <FormatUnderlined />
      </MarkToolbarButton>
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Strikethrough'}
        nodeType={MARK_STRIKETHROUGH}>
        <FormatStrikethrough />
      </MarkToolbarButton>
      <Divider vertical />
      <ListToolbarButton nodeType={ELEMENT_UL} />
      <ListToolbarButton nodeType={ELEMENT_OL} />
      <Divider vertical />
      <LinkToolbarButton />
      <ImageToolbarButton />
      <MediaToolbarButton />
      <EmojiDropdownMenu />
    </div>
  );
};

export default AdvancedEditorToolbar;
