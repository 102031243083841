import Axios from 'axios';
import {generalSelector} from 'selectors';

export const ISSUE_TYPE_ELEMENT_NOT_FOUND = 'ELEMENT_NOT_FOUND';
export const ISSUE_TYPE_ELEMENT_NOT_VISIBLE = 'ELEMENT_NOT_VISIBLE';
export const ISSUE_TYPE_MULTIPLE_ELEMENTS_FOUND = 'MULTIPLE_ELEMENTS_FOUND';

// Endpoint
const EP_ISSUE_GET = '/issue';

// Method
export const getIssues = (data, {signal = null}) => {
  const {evolutionId, take, skip} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_ISSUE_GET, {
    signal: signal,
    params: {evolutionId, take, skip, projectId},
  }).then((response) => response.data);
};
