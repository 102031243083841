import Alert from 'components/Alert';
import {highlight, languages} from 'prismjs/components/prism-core';
// comment line to prevent linter from reordering the imports
import 'prism-themes/themes/prism-one-light.css'; // Import the theme
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import React from 'react';
import Editor from 'react-simple-code-editor';
import './_Styles.scss';

const SettingsRunJSCode = ({action, setAction}) => {
  return (
    <div className="settings-run-js-code">
      <div className="settings-run-js-code-content">
        <div className="settings-run-js-code-title body-3">Code</div>
        <div className="editor-wrapper">
          <Editor
            value={action.value || ''}
            onValueChange={(code) => setAction({...action, value: code})}
            highlight={(code) => highlight(code, languages.js, 'javascript')}
            padding={10}
            style={{
              fontFamily: 'monospace',
              fontSize: 12,
            }}
          />
        </div>
      </div>
      <Alert
        className="settings-run-js-code-alert"
        icon="isax isax-info-circle5 n-700"
        title="Validate your JavaScript code"
        children="Your JavaScript code is not validated and incorrect code may cause issues."
      />
    </div>
  );
};

export default SettingsRunJSCode;
