import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import {addFlag, hasFlag} from 'helpers/bitwise';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_HOME,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
  ROUTE_SWITCH_PROJECT,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {meService, projectService} from 'services';
import {ME_F_ONBOARDING_COMPLETED} from 'services/me';
import {F_GET_STARTED_COMPLETED} from 'services/project';
import {Swaler} from 'swaler';
import './join.scss';

const logger = new Swaler('Onboarding/Join');

export const OnboardingDetailsJoin = ({
  triggerSquareAnimationOut,
  triggerOnboardingEnd,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => generalSelector.getUser(state));

  const uptUser = (data) => dispatch(generalActions.uptUser(data));
  const reduxSetProjects = (projects) =>
    dispatch(generalActions.setProjects(projects));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [join, setJoin] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState([]);

  const fetchProjects = async () => {
    setLoading(true);
    const projects = await projectService.getProjectsToJoin();

    setLoading(false);
    setProjects(projects);
    setJoin(
      projects.reduce((members, project) => {
        return members.concat(
          project.members.filter((m) => m.user.uid === user.uid)
        );
      }, [])
    );
  };
  const handleJoinProject = async (projectId) => {
    setProcessing(processing.concat(projectId));
    try {
      const member = await projectService.joinProject(projectId);
      setJoin((join) => join.concat(member));
      setProjects((projects) =>
        projects.map((p) =>
          p.uid === projectId ? {...p, members: p.members.concat(member)} : p
        )
      );
      return setProcessing((processing) =>
        processing.filter((p) => p !== projectId)
      );
    } catch (err) {
      logger.error(`Join project failed with error `, err.message);
      return setProcessing((processing) =>
        processing.filter((p) => p !== projectId)
      );
    }
  };
  const handleLeaveProject = async (memberId, projectId) => {
    setProcessing(processing.concat(memberId));
    try {
      await projectService.deleteProjectMember({memberId});
      setJoin((join) => join.filter((m) => m.uid !== memberId));
      setProjects((projects) =>
        projects.map((p) =>
          p.uid === projectId
            ? {...p, members: p.members.filter((m) => m.uid !== memberId)}
            : p
        )
      );
      return setProcessing((processing) =>
        processing.filter((p) => p !== memberId)
      );
    } catch (err) {
      logger.error(`Join project failed with error `, err.message);
      return setProcessing((processing) =>
        processing.filter((p) => p !== memberId)
      );
    }
  };
  const handleContinue = async () => {
    const onboardingFlags = addFlag(
      ME_F_ONBOARDING_COMPLETED,
      user.onboardingFlags
    );
    const projects = await meService.getMyProjects();

    await meService.updateOnboardingFlag(onboardingFlags);
    reduxSetProjects(projects);
    uptUser({
      onboardingFlags,
    });
    setPlayAnimationOut(true);
    triggerSquareAnimationOut(true);
    triggerOnboardingEnd(() => {
      history.push(ROUTE_SWITCH_PROJECT(join[0].project.uid));
    });
  };

  useEffect(() => {
    // Prevent from accessing this page if they already completed the onboarding (for security reasons like updating his email with another company domain and try to join existing projects)
    if (hasFlag(F_GET_STARTED_COMPLETED, user.onboardingFlags) === true) {
      return history.push(ROUTE_HOME);
    }
    fetchProjects();
  }, []);

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-join',
        {
          'is-exiting': playAnimationOut === true,
        }
      )}>
      <h1>Join your team</h1>
      <p>Your team is already here! Join them or create your workspace.</p>
      <div className="project-list">
        {projects.map((p) => {
          const member = join.find(
            (m) => m.user.uid === user.uid && m.project.uid === p.uid
          );

          return (
            <div className="item-project">
              {p.portalLogoUrl != null ? (
                <div
                  className="project-icon"
                  style={{backgroundImage: `url(${p.portalLogoUrl})`}}></div>
              ) : (
                <div className="project-icon">{p.name[0]}</div>
              )}
              <div className="project-infos">
                <div className="project-name">{p.name}</div>
                <div className="project-members">
                  {p.members.length} members
                </div>
              </div>
              <div className="project-members-list">
                {p.members.map((m) =>
                  m.user.avatarUrl != null ? (
                    <div
                      className="item-member with-avatar"
                      style={{
                        backgroundImage: `url(${m.user.avatarUrl})`,
                      }}></div>
                  ) : (
                    <div className="item-member">{m.user.firstName[0]}</div>
                  )
                )}
              </div>
              {p.members.length < p.thresholdSeats ? (
                member != null ? (
                  <Button
                    thin
                    danger
                    className="btn-join"
                    loading={processing.includes(member.uid)}
                    onClick={() => handleLeaveProject(member.uid, p.uid)}>
                    Leave
                  </Button>
                ) : (
                  <Button
                    thin
                    className="btn-join"
                    loading={processing.includes(p.uid)}
                    onClick={() => handleJoinProject(p.uid)}>
                    Join
                  </Button>
                )
              ) : (
                <Button thin className="btn-join" disabled>
                  Limit of members reached
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <div className="actions-wrapper">
        <Button
          cta
          secondary
          disabled={join.length === 0 || processing.length > 0}
          iconRight="icon-chevron-right"
          onClick={handleContinue}>
          Continue
        </Button>
        <Button
          cta
          light
          disabled={join.length > 0 || processing.length > 0}
          iconRight="icon-chevron-right"
          onClick={() => history.push(ROUTE_ONBOARDING_DETAILS_STEP_2_1)}>
          Start a new workspace
        </Button>
      </div>
    </div>
  );
};
