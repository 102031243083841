import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import './_Styles.scss';

export const TrackerPreviewTooltip = ({title, content}) => {
  return (
    <div className="tracker-preview-tooltip-wrapper">
      <div className="tooltip">
        <div className="title">{title}</div>
        <div className="groups">
          <div className="group">{content}</div>
        </div>
      </div>
    </div>
  );
};

const PreviewStackedLineChart = ({data}) => {
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [areaHovered, setAreaHovered] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);

  const tooltipHoveredRef = useRef();
  const areaHoveredRef = useRef();

  useEffect(() => {
    tooltipHoveredRef.current = tooltipHovered;
  }, [tooltipHovered]);

  useEffect(() => {
    areaHoveredRef.current = areaHovered;
  }, [areaHovered]);

  return (
    <div className="preview-stacked-line-chart">
      {tooltipPosition != null && (
        <div
          style={{
            zIndex: '1',
            position: 'absolute',
            left: tooltipPosition.x + 10,
            top: tooltipPosition.y,
          }}
          onMouseEnter={() => {
            setTooltipHovered(true);
          }}
          onMouseLeave={() => {
            setTooltipHovered(false);

            setTimeout(() => {
              if (areaHoveredRef.current === true) {
                return;
              }
              setTooltipPosition(null);
            }, 200);
          }}>
          <TrackerPreviewTooltip
            title={dayjs(tooltipData?.timestamp * 1000).format('ddd, DD MMM')}
            content={
              <>
                <div className="color-indication" />
                <div className="data">
                  <div className="group-value">
                    {tooltipData?.count} user{tooltipData?.count > 1 ? 's' : ''}
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          onMouseEnter={() => setAreaHovered(true)}
          onMouseMove={(e) => {
            setTooltipPosition(e.activeCoordinate);
            setTooltipData({
              timestamp: e.activePayload?.[0].payload.timestamp,
              count: e.activePayload?.[0].payload.count,
            });
          }}
          onMouseLeave={() => {
            setAreaHovered(false);
            setTimeout(() => {
              if (
                tooltipHoveredRef.current === true ||
                areaHoveredRef.current === true
              ) {
                return;
              }
              setTooltipPosition(null);
            }, 200);
          }}
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}>
          <CartesianGrid horizontal vertical={false} stroke="#0000001a" />
          <XAxis
            dataKey="timestamp"
            domain={['dataMin', 'dataMax']}
            axisLine={false}
            tickLine={false}
            tickCount={3}
            name="Date"
            tickFormatter={(unixTime) => {
              return dayjs(unixTime * 1000).format('MMM DD');
            }}
            type="number"
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            domain={['auto', 'auto']}
            type="number"
            allowDecimals={false}
            tickCount={3}
            tickFormatter={() => ''}
            width={30}
          />
          <Tooltip content={() => <></>} />
          <Area
            type="monotone"
            dataKey="count"
            stackId="1"
            stroke="#89B6FF"
            fill="#E5EFFF"
            onMouseEnter={(e) => {
              setTooltipData(e.payload);
              setAreaHovered(true);
              setTooltipPosition(e.tooltipPosition);
            }}
            onMouseOut={() => {
              setAreaHovered(false);
              setTimeout(() => {
                if (
                  tooltipHoveredRef.current === true ||
                  areaHoveredRef.current === true
                ) {
                  return;
                }
                setTooltipPosition(null);
              }, 200);
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PreviewStackedLineChart;
