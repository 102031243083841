import {useQuery} from 'react-query';
import {trackerService} from 'services';
import DefaultLoader from 'components/Loader';
import {useRouteMatch} from 'react-router-dom';
import './_Styles.scss';
import MilestoneOverview from './components/MilestoneOverview';
import {createContext, useRef, useState} from 'react';
import TrackerUsers from './components/TrackerUsers';
import CompletionRate from './components/CompletionRate';
import TrackerHeader from './components/TrackerHeader';
import useScrollDetector from 'hooks/UseScrollDetector';
import FeatureUsageStats from './components/FeatureUsageStats';
import FeatureUsageChart from './components/FeatureUsageChart';
import FeatureUsageChartUsers from './components/FeatureUsageChartUsers';
import ActivationStats from './components/ActivationStats';
import classNames from 'classnames';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';

export const TrackerContext = createContext();

const ActivationTracker = () => {
  return (
    <>
      <ActivationStats />
      <MilestoneOverview />
      <CompletionRate />
      <TrackerUsers />
    </>
  );
};

const FeatureUsageTracker = () => {
  return (
    <>
      <FeatureUsageStats />
      <FeatureUsageChart />
      <FeatureUsageChartUsers />
      <TrackerUsers />
    </>
  );
};

const Tracker = () => {
  const match = useRouteMatch();
  const trackerId = match.params.trackerId;

  const contentRef = useRef();

  const {
    isLoading,
    data: tracker,
    refetch,
  } = useQuery({
    queryKey: ['tracker', trackerId],
    queryFn: () => trackerService.getTracker({trackerId}),
    refetchOnWindowFocus: false,
  });

  const isScrolled = useScrollDetector(contentRef);

  const [segments, setSegments] = useState([]);
  const [onTheFlySegment, setOnTheFlySegment] = useState(null);

  return (
    <TrackerContext.Provider
      value={{
        tracker,
        segments,
        setSegments,
        onTheFlySegment,
        setOnTheFlySegment,
        refetch,
      }}>
      <div
        className={classNames('s-tracker', 'fade-in', {
          'is-loading': isLoading === true,
        })}>
        {isLoading === true || tracker == null ? null : (
          <TrackerHeader scrolled={isScrolled} />
        )}
        <div className="tracker-content-wrapper" ref={contentRef}>
          <div className="tracker-content">
            {tracker == null && isLoading !== true ? (
              <div className="tracker-not-found">Tracker not found</div>
            ) : isLoading === true ? (
              <div className="loader-wrapper">
                <DefaultLoader />
              </div>
            ) : (
              <>
                {tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER && (
                  <ActivationTracker />
                )}
                {tracker.type === TRACKER_TYPE_FEATURE_USAGE && (
                  <FeatureUsageTracker />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </TrackerContext.Provider>
  );
};

export default Tracker;
