import classNames from 'classnames';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {isSegmentationValid} from 'components/SegmentAttributesEditor/utils';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useState} from 'react';
import {ListUsers} from 'scenes/Users/components/ListUsers';
import {Pagination} from 'scenes/Users/components/Pagination';
import {jimerService, segmentService} from 'services';
import {F_SEGMENT_FROM_DATA_IMPORT} from 'services/segment';
import {Swaler} from 'swaler';
import {SegmentContext} from '../..';
import './_Styles.scss';

const JIMERS_PER_PAGE = 8;

const logger = new Swaler('SegmentUsersWrapper');

const SegmentUsersWrapper = () => {
  const {
    segment,
    lookupData,
    setLookupData,
    hasChanges,
    attributes: attributesProp,
    logic: logicProp,
    isLegacy,
  } = useContext(SegmentContext);
  const {attributes, logic} = lookupData || {};

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingLookup, setIsLoadingLookup] = useState(false);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);

  const cancelCtrl = new AbortController();

  const segmentId = segment?.uid;

  useEffect(() => {
    const fetchSetup = async () => {
      await fetchSegmentUsers(0);
    };

    if (segmentId != null && segmentId !== 'new') {
      fetchSetup();
    } else {
      setIsLoadingUsers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentId]);

  useEffect(() => {
    if (attributes != null && isLoadingUsers === false) {
      fetchLookupUsers(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes, logic]);

  const fetchSegmentUsers = async (pageParam = page) => {
    const oldPage = page;

    setIsLoadingUsers(true);
    setPage(pageParam);

    try {
      const jimers = await segmentService.getSegmentJimers(
        segmentId,
        {
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
        },
        {signal: cancelCtrl.signal}
      );

      setJimers(jimers);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error("Fetching segment's jimers failed with error ", code);
      toastDanger([title, message], {actions});
      setPage(oldPage);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchLookupUsers = async (pageParam = page) => {
    const oldPage = page;

    setIsLoadingLookup(true);
    setPage(pageParam);
    cancelCtrl.current = new AbortController();
    try {
      const jimers = await jimerService.getJimers(
        {
          attributes,
          logic,
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
        },
        {signal: cancelCtrl.current.signal}
      );

      setJimers(jimers);
      setIsLoadingLookup(false);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      setIsLoadingLookup(false);
      setPage(oldPage);

      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching users failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  const {isValid} = isSegmentationValid(attributesProp, logicProp);

  return (
    <div
      className={classNames('segment-users-wrapper', {
        'requires-lookup': hasChanges() && isLegacy !== true,
      })}>
      <div className="segment-users-content">
        {isLoadingUsers === true || isLoadingLookup === true ? (
          <div className="segment-loader">
            <DefaultLoader width="24px" />
            Looking for jimers in the segment
          </div>
        ) : (
          <>
            <div className="segment-users-title">
              {jimers.total} user{jimers.total > 1 ? 's' : ''} found
            </div>
            <ListUsers
              jimers={jimers.data}
              isLoading={isLoadingUsers || isLoadingLookup}>
              <Pagination
                page={page}
                take={jimers.take}
                total={jimers.total}
                onChange={(page) => {
                  if (
                    attributes != null &&
                    hasFlag(F_SEGMENT_FROM_DATA_IMPORT, segment.flags) === false
                  ) {
                    fetchLookupUsers(page);
                  } else {
                    fetchSegmentUsers(page);
                  }
                }}
                isLoading={isLoadingUsers}
              />
            </ListUsers>
          </>
        )}
      </div>
      <Button
        className="segment-users-refresh"
        onClick={() => {
          setLookupData({
            attributes: JSON.parse(JSON.stringify(attributesProp)),
            logic: JSON.parse(JSON.stringify(logicProp)),
          });
        }}
        disabled={isValid !== true}>
        Apply to update the list
      </Button>
    </div>
  );
};

export default SegmentUsersWrapper;
