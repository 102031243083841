import classnames from 'classnames';
import Button from 'components/Button';
import {ModalConfirm} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {PermissionsEvent} from 'constants/permissions';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTE_EVENTS} from 'router/routes.const';
import ModalOpenInAppBuilder from 'scenes/SuccessTracker/components/ModalOpenInAppBuilder';
import {eventService} from 'services';
import {Swaler} from 'swaler';
import {EventContext} from '../..';
import {hasConditionInLogic} from '../EventConditions/utils';
import './_Styles.scss';

const logger = new Swaler('EventHeader');

const EventHeader = () => {
  const {
    event,
    refetchEvent,
    name,
    icon,
    codeName,
    description,
    conditions,
    logic,
    isValid,
    isCodeNameAlreadyUsed,
  } = useContext(EventContext);

  const history = useHistory();

  const [saving, setSaving] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);

  const hasUnsavedChanges = () => {
    return (
      name !== event.name ||
      icon !== event.icon ||
      description !== event.description ||
      codeName !== event.codeName ||
      JSON.stringify(conditions) !== JSON.stringify(event?.conditions) ||
      JSON.stringify(logic) !== JSON.stringify(event?.logic)
    );
  };

  const handleBack = async () => {
    if (hasUnsavedChanges()) {
      setShowExitModal(true);
      return;
    }
    history.push(ROUTE_EVENTS);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      if (event.uid == null) {
        // create event
        await eventService.createEvent({
          name,
          codeName,
          description,
          icon,
          conditions: conditions.filter((c) =>
            hasConditionInLogic(logic, c.uid)
          ),
          logic,
        });
      } else {
        await eventService.updateEvent(event.uid, {
          name,
          codeName,
          description,
          icon,
          conditions: conditions.filter((c) =>
            hasConditionInLogic(logic, c.uid)
          ),
          logic,
        });
        await refetchEvent();
        toastSuccess('Changes saved!', {toastId: 'event-saved'});
      }
      history.push(ROUTE_EVENTS);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Saving event failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setSaving(false);
    }
  };

  const classNames = classnames('event-header');

  const canEditEvent = hasPermissions(PermissionsEvent.CREATE_EDIT);

  return (
    <div className={classNames}>
      <Button className="back-btn" iconOnly onClick={handleBack}>
        <i className="icon-chevron-left" />
      </Button>
      {canEditEvent && (
        <>
          <div className="btns-wrapper">
            <Button
              className="btn-test"
              primary={isValid !== true}
              iconLeft="isax isax-mouse-square"
              disabled={isValid !== true}
              onClick={() => setShowTestModal(true)}>
              Test event
            </Button>
            <Button
              className="btn-save"
              primary
              disabled={
                hasUnsavedChanges() !== true ||
                saving ||
                isValid !== true ||
                !name ||
                !codeName ||
                isCodeNameAlreadyUsed
              }
              loading={saving}
              onClick={handleSave}>
              Save
            </Button>
          </div>
          <ModalConfirm
            className="exit-modal"
            title="Exit without saving?"
            isOpen={showExitModal}
            onConfirm={() => {
              setShowExitModal(false);
              history.push(ROUTE_EVENTS);
            }}
            onCancel={() => setShowExitModal(false)}
            cancelText="Cancel"
            confirmText="Exit"
            cancelBtnProps={{
              cta: false,
            }}
            confirmBtnProps={{
              danger: true,
              primary: false,
              cta: false,
            }}>
            <div className="content">
              You have unsaved changes, are you sure you want to exit without
              saving?
            </div>
          </ModalConfirm>

          {showTestModal && (
            <ModalOpenInAppBuilder
              isOpen={showTestModal}
              onRequestClose={() => setShowTestModal(false)}
              title="Test your event"
              handshakeData={{
                type: 'SET_MODE',
                data: {
                  mode: 'EVENT_TESTER',
                  event: {
                    name,
                    icon,
                    codeName,
                    description,
                    conditions,
                    logic,
                  },
                },
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EventHeader;
