import classnames from 'classnames';
import Lottie from 'react-lottie';
import './_Styles.scss';

export default function EmptyState(props) {
  const {
    className = {},
    icon = null,
    title = null,
    children,
    actions = null,
  } = props;
  const classNames = classnames('empty-state', className);

  return (
    <div className={classNames}>
      {icon != null && <div className="icon-wrapper">{icon}</div>}
      {title != null && <div className="title-wrapper">{title}</div>}
      {children != null && <div className="content-wrapper">{children}</div>}
      {actions != null && <div className="actions-wrapper">{actions}</div>}
    </div>
  );
}

export function EmptyStateOnboarding(props) {
  const {
    className = {},
    actions,
    title,
    image,
    animation,
    lottieOptions,
    children,
  } = props;
  const classNames = classnames('empty-state-onboarding', className);
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...lottieOptions,
    animationData: animation,
  };

  return (
    <div className={classNames}>
      <div className="content">
        <div className="title">{title}</div>
        {children}
        <div className="actions">{actions}</div>
      </div>
      <div className="image">
        {animation == null ? (
          <img src={image} alt="" />
        ) : (
          <Lottie options={defaultLottieOptions}></Lottie>
        )}
      </div>
    </div>
  );
}
