import classNames from 'classnames';
import React, {useContext} from 'react';
import {ChangelogBuilderContext} from 'scenes/ChangelogBuilder';
import ChangelogNavigator from '../ChangelogNavigator';
import './_Styles.scss';

export const MODE_ADD_BLOCK = 'MODE_ADD_BLOCK';
export const MODE_NAVIGATOR = 'MODE_NAVIGATOR';
export const MODE_TRANSLATIONS = 'MODE_TRANSLATIONS';
export const MODE_LOGIC = 'MODE_LOGIC';
export const MODE_THEME = 'MODE_THEME';

const propTypes = {};

const defaultProps = {};

const ChangelogBuilderSidebar = () => {
  const {mode, setMode} = useContext(ChangelogBuilderContext);

  return (
    <div className={classNames('changelog-builder-sidebar-wrapper')}>
      {mode === MODE_NAVIGATOR && <ChangelogNavigator />}
    </div>
  );
};

ChangelogBuilderSidebar.propTypes = propTypes;
ChangelogBuilderSidebar.defaultProps = defaultProps;

export default ChangelogBuilderSidebar;
