import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import SegmentAttributesEditor from 'components/SegmentAttributesEditor';
import {
  buildLogic,
  isSegmentationValid,
} from 'components/SegmentAttributesEditor/utils';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import {ListUsers} from '../ListUsers';
import {Pagination} from '../Pagination';
import './_Styles.scss';

const JIMERS_PER_PAGE = 8;

const logger = new Swaler('Users');

const AllPeople = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [lookupData, setLookupData] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [logic, setLogic] = useState(buildLogic());

  const cancelCtrl = useRef();

  useEffect(() => {
    if (attributes.length <= 0) {
      setLookupData({
        attributes: [],
        logic: buildLogic(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  useEffect(() => {
    // Prevent fetching jimer until lookupData is actually initialized
    if (lookupData == null) return;
    fetchUsers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupData?.attributes, lookupData?.logic]);

  const handleLookup = (attributes, logic) => {
    setLookupData({
      attributes: JSON.parse(JSON.stringify(attributes)),
      logic: JSON.parse(JSON.stringify(logic)),
    });
  };

  const fetchUsers = async (pageParam = page) => {
    const oldPage = page;

    setIsLoading(true);
    setPage(pageParam);
    cancelCtrl.current = new AbortController();
    try {
      const jimers = await jimerService.getJimers(
        {
          attributes: lookupData?.attributes,
          logic: lookupData?.logic,
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
        },
        {signal: cancelCtrl.current.signal}
      );

      setJimers(jimers);
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching users failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const hasChanges = () =>
    attributes.length > 0 &&
    (JSON.stringify(lookupData?.attributes) !== JSON.stringify(attributes) ||
      JSON.stringify(lookupData?.logic) !== JSON.stringify(logic));

  const {isValid} = isSegmentationValid(attributes, logic);

  return (
    <div className="all-people">
      <div className="segment-attributes-editor-wrapper">
        <SegmentAttributesEditor
          dropdownProps={{
            className: 's-user-attributes-editor',
          }}
          onLookup={handleLookup}
          attributes={attributes}
          setAttributes={setAttributes}
          logic={logic}
          setLogic={setLogic}
          lookupData={lookupData}
        />
      </div>
      <Divider />
      <div
        className={classNames('list-users-wrapper', {
          'requires-lookup': hasChanges(),
        })}>
        <div className="list-users-content">
          <div className="list-users-title">
            {jimers.total} user{jimers.total > 1 ? 's' : ''} found
          </div>
          <ListUsers
            jimers={jimers.data}
            isLoading={isLoading}
            onDelete={() => fetchUsers(page)}>
            <Pagination
              page={page}
              take={jimers.take}
              total={jimers.total}
              onChange={(page) => fetchUsers(page)}
              isLoading={isLoading}
            />
          </ListUsers>
        </div>
        <Button
          className="list-users-refresh"
          disabled={isValid !== true}
          onClick={() => {
            setLookupData({
              attributes: JSON.parse(JSON.stringify(attributes)),
              logic: JSON.parse(JSON.stringify(logic)),
            });
          }}>
          Apply to update the list
        </Button>
      </div>
    </div>
  );
};

export default AllPeople;
