import React from 'react';
import './_Styles.scss';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';

const SettingsDelay = ({condition, setCondition}) => {
  const {value} = condition;

  return (
    <div className="settings-delay">
      <div className="section-item">
        <div className="section-item-title body-3">Delay</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={value || 0}
            label="Sec"
            min={0}
            max={120}
            onChange={(value) => {
              setCondition({
                ...condition,
                value: value?.toString() || '0',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsDelay;
