const {default: TextArea} = require('components/TextArea');

const ButtonBlockInput = ({selectedLanguage, block, onChange}) => {
  const {value} = block;
  const [text, action, optAction, urlOrPostId] = value.split(';');

  const inputValue =
    selectedLanguage != null
      ? block?.translations?.find((t) => t.language === selectedLanguage)
          ?.value || ''
      : text;

  const handleChange = (value) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.translations?.find((t) => t.language === selectedLanguage) !=
        null;
      onChange({
        translations: hasLanguage
          ? block.translations.map((t) => {
              if (t.language === selectedLanguage) {
                return {
                  ...t,
                  value,
                };
              } else {
                return t;
              }
            })
          : [
              ...(block?.translations || []),
              {language: selectedLanguage, value},
            ],
      });
    } else {
      onChange({
        value: `${value};${action};${optAction};${urlOrPostId}`,
      });
    }
  };

  return (
    <TextArea
      value={inputValue}
      onChange={({target}) => handleChange(target.value)}
      placeholder={text}
      className="title-content-input"
    />
  );
};

export default ButtonBlockInput;
