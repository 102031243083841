import SelectGroup from 'components/Select';
import {errorHelpers} from 'helpers';
import React from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {EventConditionOperand} from 'services/event';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SettingsTourSeen');

const SettingsTourSeen = ({condition, setCondition}) => {
  const {value, operand} = condition;

  const {data: experiences = [], isLoading} = useQuery({
    queryKey: ['experiences', 'settings-tour-seen'],
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
      }
    },
  });

  const experiencesOptions = experiences?.map((e) => ({
    label: e.title,
    value: e.uid,
    evolution: e,
  }));

  const groupedOptions = [
    {
      label: 'Tours & Modals',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_TOUR
      ),
    },
    {
      label: 'Surveys',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_SURVEY
      ),
    },
  ];

  return (
    <div className="settings-tour-seen">
      <div className="section-item">
        <div className="settings-launch-experience-title body-3 n-800">
          Experience
        </div>
        <div className="section-item-content">
          <SelectGroup
            options={groupedOptions}
            value={experiencesOptions?.find((e) => e.value === value)}
            onChange={(selected) =>
              setCondition({...condition, value: selected.value})
            }
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3 n-800">Condition</div>
        <div className="section-item-content">
          <div
            className="radio-options-wrapper"
            onChange={(e) =>
              setCondition({
                ...condition,
                operand: e.target.value,
              })
            }>
            <div className="option">
              <input
                type="radio"
                id="seen"
                name="event"
                checked={operand === EventConditionOperand.SEEN}
                value={EventConditionOperand.SEEN}
              />
              Seen
            </div>
            <div className="option">
              <input
                type="radio"
                id="completed"
                name="event"
                checked={operand === EventConditionOperand.COMPLETED}
                value={EventConditionOperand.COMPLETED}
              />
              Completed
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsTourSeen;
