import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_THEME_GET = '/theme';
const EP_THEME_GET_BY_ID = (themeId) => `/theme/${themeId}`;
const EP_THEME_POST_CREATE = '/theme';
const EP_THEME_PUT_UPDATE = (themeId) => `/theme/${themeId}`;
const EP_THEME_DELETE = (themeId) => `/theme/${themeId}`;
const EP_THEME_POST_GENERATE_PALETTE = '/theme/generate-palette';
const EP_THEME_POST_SET_DEFAULT = '/theme/default';
const EP_THEME_POST_DUPLICATE = (themeId) => `/theme/duplicate/${themeId}`;

// Method
export const getThemes = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_THEME_GET, {
    params: {projectId},
  }).then((response) => response.data);
};

export const getTheme = (data) => {
  const {themeId} = data;

  return Axios.get(EP_THEME_GET_BY_ID(themeId)).then(
    (response) => response.data
  );
};

export const createTheme = (data) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_THEME_POST_CREATE, {...data, projectId}).then(
    (response) => response.data
  );
};

export const updateTheme = (themeId, data) => {
  return Axios.put(EP_THEME_PUT_UPDATE(themeId), data).then(
    (response) => response.data
  );
};

export const generatePalette = (data) => {
  const {url} = data;
  return Axios.post(EP_THEME_POST_GENERATE_PALETTE, {url}).then(
    (response) => response.data
  );
};

export const deleteTheme = (themeId) => {
  return Axios.delete(EP_THEME_DELETE(themeId)).then(
    (response) => response.data
  );
};

export const duplicateTheme = (themeId) => {
  return Axios.post(EP_THEME_POST_DUPLICATE(themeId)).then(
    (response) => response.data
  );
};

export const setDefaultTheme = (themeId) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_THEME_POST_SET_DEFAULT, {projectId, themeId}).then(
    (response) => response.data
  );
};
