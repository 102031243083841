import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import Background from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Background';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';

const StepStyle = ({poke, style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  const isBanner = poke.boostFlags === F_BOOST_SLOT_TOP_BAR;

  const backgroundColor =
    style.background?.type === 'gradient'
      ? `linear-gradient(213.36deg, ${style.background.primaryColor}, ${style.background.secondaryColor})`
      : style.background?.primaryColor || '#FFFFFF';

  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Shadow</div>
        <div className="section-item-content shadow-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={style.shadow?.color}
                leftLabel={
                  <span
                    className="preview-shadow-color"
                    style={{background: style.shadow?.color || '#FFFFFF'}}
                  />
                }
              />
            }
            title="Shadow"
            content={
              <Shadow
                value={style.shadow}
                onChange={(value) => updateStyle({shadow: value})}
              />
            }
          />
        </div>
      </div>
      {isBanner === true && (
        <div className="section-item">
          <div className="section-item-title body-3">Margin</div>
          <div className="section-item-content">
            <PixelPicker
              small
              value={style.margin}
              min={0}
              max={24}
              onChange={(value) => updateStyle({margin: value})}
            />
          </div>
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={50}
            onChange={(value) => updateStyle({borderRadius: value})}
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content background-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={
                  style.background?.type === 'gradient'
                    ? 'Gradient'
                    : style.background?.primaryColor
                }
                leftLabel={
                  <span
                    className="preview-background-color"
                    style={{
                      background: backgroundColor,
                      ...(style.background?.type === 'gradient'
                        ? {border: 0}
                        : {}),
                    }}
                  />
                }
              />
            }
            title="Background"
            content={
              <Background
                value={style.background}
                onChange={(value) => updateStyle({background: value})}
              />
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Border color"
            value={style.borderColor || null}
            onChange={(value) => updateStyle({borderColor: value})}
            erasable
          />
        </div>
      </div>
      {isBanner !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Overlay</div>
          <div className="section-item-content">
            <ColorPickerInput
              inputProps={{small: true}}
              title="Overlay"
              value={style.overlay}
              onChange={(value) => updateStyle({overlay: value})}
              erasable
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StepStyle;
