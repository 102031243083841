import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {bool, func} from 'prop-types';
import {useState} from 'react';
import {segmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  onSegmentCreated: func.isRequired,
};

const defaultProps = {
  onRequestClose: () => {},
};

const logger = new Swaler('ModalCreateSegment');

const ModalCreateSegment = ({
  isOpen,
  onRequestClose,
  onSegmentCreated,
  ...rest
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [creating, setCreating] = useState(false);

  const classNames = classnames('modal-create-segment');

  const handleCreateSegment = async () => {
    setCreating(true);
    try {
      const createdSegment = await segmentService.createSegment({
        name,
        description,
      });
      onSegmentCreated(createdSegment);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Creating segment failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setCreating(false);
    }
  };

  return (
    <Modal
      className={classNames}
      title="Create a new segment"
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <div className="fade-in-right">
        <div className="inputs-wrapper">
          <div className="section-title">Name</div>
          <Input
            className="input-name"
            name="inputName"
            placeholder="Segment name"
            value={name}
            required
            onChange={({target}) => setName(target.value)}
          />
          <div className="section-title">Description</div>
          <Input
            className="input-description"
            name="inputDescription"
            placeholder="Segment description"
            value={description}
            required
            onChange={({target}) => setDescription(target.value)}
          />
        </div>
        <div className="actions">
          <Button onClick={onRequestClose} disabled={creating}>
            Cancel
          </Button>
          <Button onClick={handleCreateSegment} loading={creating}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalCreateSegment.propTypes = propTypes;
ModalCreateSegment.defaultProps = defaultProps;

export default ModalCreateSegment;
