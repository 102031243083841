import Button from 'components/Button';
import './_Styles.scss';
import BtnCopy from 'components/ButtonCopy';
import {useEffect, useState} from 'react';
import {segmentioService} from 'services';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {generalActions} from 'actions';
import {Swaler} from 'swaler';
import DefaultLoader from 'components/Loader';
import Divider from 'components/Divider';

const logger = new Swaler('ImportFromSegmentSetup');

const ImportFromSegmentSetup = ({onBack}) => {
  const dispatch = useDispatch();

  const project = useSelector(() => generalSelector.getProject());
  const {segmentio} = project;

  const segmentActive = !!segmentio?.apiKey;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (segmentActive === true) {
      return;
    }

    setIsLoading(true);
    segmentioService
      .generateApiKey(project.uid)
      .then((segmentio) => {
        dispatch(generalActions.uptProject({segmentio}));
      })
      .catch((err) => {
        logger.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="import-from-segment-setup">
      <div className="content">
        {isLoading === true ? (
          <div className="loader-wrapper">
            <DefaultLoader />
          </div>
        ) : (
          <>
            <div className="copywriting">
              <div className="title">API key</div>
              <div className="subtitle">
                Use the provided key to integrate Jimo as a Connection into a
                Source within your Segment Workspace. This integration will
                deploy Jimo on all pages where the Source is installed and
                transmit relevant user data to Jimo. Learn more
              </div>
            </div>
            <div className="api-key">{segmentio?.apiKey}</div>
          </>
        )}
      </div>
      <Divider />
      <div className="footer">
        <Button
          className="back-btn"
          iconLeft="icon-chevron-left"
          onClick={() => onBack()}>
          Back
        </Button>
        <BtnCopy
          reverted
          primary
          textToCopy={segmentio?.apiKey}
          disabled={segmentActive !== true}>
          Copy API key
        </BtnCopy>
      </div>
    </div>
  );
};

export default ImportFromSegmentSetup;
