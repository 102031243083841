/** Reducers imports */
import builderReducer from 'reducers/builder';
import dataReducer from 'reducers/data';
import generalReducer from 'reducers/general';
import miscReducer from 'reducers/misc';
import pushesReducer from 'reducers/pushes';
import tagsReducer from 'reducers/tags';
import {combineReducers, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  stateReconciler: autoMergeLevel1,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    general: persistReducer(
      {
        key: 'general',
        whitelist: ['project', 'snoozeJimerLimitUntil'],
        storage,
      },
      generalReducer
    ),
    misc: persistReducer(
      {
        key: 'misc',
        whitelist: [
          'hideMobileOverlay',
          'hideTrialEndedModal',
          'collapseTrackerOnboarding',
          'invitationCode',
          'defaultViewLayout',
        ],
        storage,
      },
      miscReducer
    ),
    tags: tagsReducer,
    pushes: pushesReducer,
    data: dataReducer,
    builder: persistReducer(
      {
        key: 'builder',
        storage: sessionStorage,
      },
      builderReducer
    ),
  })
);
let store, persistor;

export default () => {
  return {
    create: () => {
      store = createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          process.env.NODE_ENV !== 'production' &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      );
      persistor = persistStore(store);
      return {store, persistor};
    },
    get: () => ({store, persistor}),
    getState: () => store.getState(),
  };
};
