import * as Data from './data';
import * as General from './general';
import * as Misc from './misc';
import * as Tags from './tags';
import * as Pushes from './pushes';

export const generalActions = General;
export const miscActions = Misc;
export const tagsAction = Tags;
export const dataActions = Data;

export const pushesActions = Pushes;
