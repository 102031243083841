import {CircularProgress, LineProgress} from 'components/Progress';
import React from 'react';

export default () => {
  return (
    <div className="_dev-progress">
      <LineProgress value={50}></LineProgress>
      <br />
      <br />
      <CircularProgress value={50}></CircularProgress>
      <CircularProgress value={90}></CircularProgress>
      <CircularProgress value={10}></CircularProgress>
    </div>
  );
};
