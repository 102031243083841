import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useState} from 'react';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {BLOCK_TYPE_CHECKLIST_HEADER, STEP_TYPE_CHECKLIST} from 'services/steps';
import BasicEditor from '../../../../../../components/MarkdownEditor/BasicEditor';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const alignOptions = [
  {label: <i className="icon-text-align-l" />, value: 'left'},
  {label: <i className="icon-text-align-c" />, value: 'center'},
  {label: <i className="icon-text-align-r" />, value: 'right'},
];

const Paragraph = () => {
  const {
    controlledEvolution: evolution,
    selectedStep: step,
    selectedBlockType,
    updateBlock: uptBlock,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  const [isEditing, setIsEditing] = useState(false);
  const [markdownEditorKey, setMarkdownEditorKey] = useState(null);

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((isChecklistStep !== true && b.parentBlockId == null) ||
        (selectedChecklistItemId == null &&
          [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedChecklistItemId === b.parentBlockId)
  );

  const {value, rawValue, style} = block || {};

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  useEffect(() => {
    if (isEditing === false) {
      setMarkdownEditorKey(JSON.stringify(rawValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawValue]);

  if (step == null || block == null) {
    return <></>;
  }

  return (
    <div className="block-settings paragraph">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <BasicEditor
            key={markdownEditorKey}
            value={value}
            rawValue={rawValue}
            onChange={({value, rawValue}) => updateBlock({value, rawValue})}
            onFocus={() => setIsEditing(true)}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">
          {isChecklistStep && selectedChecklistItemId != null ? (
            <>
              <i className="isax isax-main-component5" />
              Style
              <div className="section-title-description">
                <div className="body-4 n-700">For every</div>
                <div className="block-group subtitle-4 n-800">
                  <div className="icon-wrapper">
                    <i className="icon-text" />
                  </div>
                  Description
                </div>
              </div>
            </>
          ) : (
            'Style'
          )}
        </div>
        <div className="section-content">
          <ParagraphStyle
            style={style}
            updateStyle={(value) =>
              updateBlock(
                value,
                isChecklistStep === true && selectedChecklistItemId != null
              )
            }
            hideAlign={isBannerStep || isChecklistStep}
          />
        </div>
      </div>
    </div>
  );
};

export const ParagraphStyle = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Color"
            value={style.fontColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) => {
              updateStyle({
                style: {...style, fontSize: value},
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      {hideAlign !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Align</div>
          <div className="section-item-content">
            <RadioGroup
              value={style.align}
              options={alignOptions}
              onSelect={(value) =>
                updateStyle({
                  style: {
                    ...style,
                    align: value,
                  },
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Paragraph;
