import Button from 'components/Button';
import LivePreviewNew from 'components/LivePreviewNew';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import * as dayjs from 'dayjs';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import SummaryInfos from 'scenes/PokeAudience/sections/Summary/components/SummaryInfos';
import {PokeStatus} from 'scenes/Pushes/components/PushesTable';
import {evolutionService} from 'services';
import {EVOLUTION_STATE_LIVE, EVOLUTION_STATE_PAUSED} from 'services/evolution';
import './_Styles.scss';

const ModalCopySettingsFrom = ({
  isOpen,
  onRequestClose,
  onApplySettings,
  evolution: evolutionProp,
  availableSections,
}) => {
  const [evolution, setEvolution] = useState(evolutionProp);

  const {isLoading} = useQuery({
    queryKey: ['evolution', 'copy-settings-preview', evolution.uid],
    queryFn: async () => {
      const fullEvolution = await evolutionService.getEvolutionById(
        evolution.uid,
        {
          relations: [
            'texts',
            'segments',
            'steps',
            'boostedPaths',
            'steps.thumbnails',
            'event',
            'tourSteps',
            'event',
            'environments',
          ],
        }
      );

      setEvolution(fullEvolution);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const previewPoke =
    evolution?.tourSteps?.filter((ts) =>
      ts.steps?.some((s) => s.removed !== true)
    )[0] || evolution;

  const {isDraft, state, lastStepChangeAt, expiresAt} = evolution;

  const isLive =
    isDraft !== true && (state === EVOLUTION_STATE_LIVE || state == null);
  const isPaused = isDraft !== true && state === EVOLUTION_STATE_PAUSED;
  const isScheduled =
    isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
  const isExpired =
    isDraft !== true && expiresAt != null && dayjs(expiresAt).isBefore(dayjs());

  return (
    <Modal
      className="modal-copy-settings-from"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={
        evolution?.title != null
          ? `Preview ${evolution?.title} settings`
          : 'Preview settings'
      }
      footer={
        <>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button onClick={() => onApplySettings(evolution)} primary>
            Apply settings
          </Button>
        </>
      }>
      <div className="copy-settings-preview">
        <SummaryInfos evolution={evolution} sections={availableSections} />
        <div className="experience-preview">
          <div className="preview">
            <PokeStatus
              isExpired={isExpired}
              isScheduled={isScheduled}
              isLive={isLive}
              isPaused={isPaused}
            />
            {isLoading === true ? (
              <div className="loader-wrapper">
                <DefaultLoader />
              </div>
            ) : (
              <LivePreviewNew
                evolution={evolution}
                poke={previewPoke}
                isScaledDownPreview
                scale={0.9}
                selectedStepId={previewPoke.steps[0]?.uid}
              />
            )}
          </div>
          <div className="infos">
            <div className="push-title subtitle-3 n-800">{evolution.title}</div>
            <div className="created-at body-3 n-700">
              Created {dayjs(evolution.createdAt).fromNow()}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCopySettingsFrom;
