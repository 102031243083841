import classNames from 'classnames';
import {func, string} from 'prop-types';
import React from 'react';
import './_Styles.scss';

export const ACTION_NONE = 'none';
export const ACTION_URL_OPEN = 'url-open';
export const ACTION_POST_OPEN = 'post-open';
export const ACTION_BOOK_INTERVIEW = 'open-booking-url';
export const ACTION_LAUNCH_EXPERIENCE = 'launch-experience';

export const optionalActions = [
  {
    name: 'None',
    icon: 'icon-close-circle',
    value: ACTION_NONE,
  },
  {
    name: 'Launch experience',
    icon: 'isax isax-routing-2',
    value: ACTION_LAUNCH_EXPERIENCE,
  },
  {
    name: 'Open url',
    icon: 'isax isax-link-square',
    value: ACTION_URL_OPEN,
  },
  {
    name: 'Open post',
    icon: 'isax isax-slider-vertical-1',
    value: ACTION_POST_OPEN,
  },
  {
    name: 'Open calendar',
    icon: 'icon-calendar',
    value: ACTION_BOOK_INTERVIEW,
  },
];

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: null,
  onChange: () => {},
};

const Action = ({value, omit, onChange}) => {
  return (
    <div className="action-list">
      {optionalActions
        .filter((a) => omit.includes(a.value) === false)
        .map((action) => (
          <div
            className={classNames('action-item', {
              selected: value === action.value,
            })}
            onClick={() => onChange(action.value)}>
            <i className={action.icon} />
            {action.name}
          </div>
        ))}
    </div>
  );
};

Action.propTypes = propTypes;
Action.defaultProps = defaultProps;

export default Action;
