import Button from 'components/Button';
import Divider from 'components/Divider';
import Loader from 'components/Loader';
import Select from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {isArrayEqualMultiset} from 'helpers/utils.js';
import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {components} from 'react-select';
import {ROUTE_SETTINGS_INTEGRATIONS_SLACK} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {
  evolutionService,
  evolutionSlackChannelsService,
  slackService,
} from 'services';
import {F_EVOLUTION_SLACK_CHANNEL_EACH_STEP_REPORT} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';
import SlackLogo from './imgs/slack.svg';

const logger = new Swaler('SlackConnect');

const SlackConnect = ({evolution, refetchEvolution}) => {
  const project = useSelector(() => generalSelector.getProject());
  const {slack} = project;
  const isSlackEnabled = !!slack?.access_token;

  const [selectedChannels, setSelectedChannels] = useState([]); // selected channels
  const [evolutionSlackChannelsCache, setEvolutionSlackChannelsCache] =
    useState([]); // submitted channels (reflects database)

  const getInitialSelectedChannels = async (slackId) => {
    const res = await slackService.getChannels(slackId);
    const channelIdMapping = Object.fromEntries(res.map((c) => [c.id, c]));
    const evolutionSlackChannels =
      await evolutionSlackChannelsService.getSlackChannels(evolution.uid);
    const tempChannels = evolutionSlackChannels
      .filter((sc) => sc.channelId in channelIdMapping) // edge case where bot invited to private channel, disable slack auth, then enable
      .map((sc) => channelIdMapping[sc.channelId]);
    setSelectedChannels(tempChannels);
    setEvolutionSlackChannelsCache(tempChannels);

    return res;
  };

  const {data: channels = [], isFetching: isChannelsFetching} = useQuery({
    queryKey: ['getChannels', slack?.uid],
    queryFn: () => getInitialSelectedChannels(slack?.uid),
    refetchOnWindowFocus: false,
    enabled: isSlackEnabled,
  });

  const {
    isLoading: isUpdateEvolutionSlackChannelsLoading,
    mutate: updateEvolutionSlackChannels,
  } = useMutation({
    mutationKey: (channelIds) => [evolution.uid, channelIds],
    mutationFn: (channelIds) => handleUpdateEvolutionSlackChannels(channelIds),
    enabled: isSlackEnabled,
  });
  const handleUpdateEvolutionSlackChannels = async (channels) => {
    try {
      await evolutionSlackChannelsService.updateSlackChannels(
        evolution.uid,
        channels.map((c) => c.id)
      );
      setEvolutionSlackChannelsCache(selectedChannels);
      toastSuccess([
        'Slack Channels Confirmed',
        'Your selected channels will now receive survey responses.',
      ]);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        `Failed to update evolution slack channels, evolutionId: ${evolution.uid}, ${code}`
      );
      toastDanger([title, message], {actions});
    }
  };

  const [slackChannelFlags, setSlackChannelFlags] = useState(
    evolution.slackChannelFlags
  );
  const {
    data: updateSlackChannelFlagsData,
    isFetching: isUpdateSlackChannelFlagsLoading,
    mutate: updateSlackChannelFlags,
  } = useMutation({
    mutationFn: () => handleToggleOnchange(),
    enabled: isSlackEnabled,
  });
  const handleToggleOnchange = async () => {
    try {
      let newFlag = slackChannelFlags;
      if (hasFlag(F_EVOLUTION_SLACK_CHANNEL_EACH_STEP_REPORT, newFlag)) {
        newFlag = removeFlag(
          F_EVOLUTION_SLACK_CHANNEL_EACH_STEP_REPORT,
          newFlag
        );
      } else {
        newFlag = addFlag(F_EVOLUTION_SLACK_CHANNEL_EACH_STEP_REPORT, newFlag);
      }
      await evolutionService.updateEvolutionSlackChannelFlags(
        evolution.uid,
        newFlag
      );
      setSlackChannelFlags(newFlag);
      refetchEvolution();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        `Failed to update evolution slackChannelFlags, evolutionId: ${evolution.uid}, ${code}`
      );
      toastDanger([title, message], {actions});
    }
  };

  const {isLoading: isSlackAuthenticateLoading, mutate: slackAuthenticate} =
    useMutation({
      mutationFn: () => handleConnectSlack(),
      enabled: isSlackEnabled,
    });
  const handleConnectSlack = async () => {
    const url = await slackService.generateUrl({}, project.uid);
    window.open(url, '_self', 'noopener,noreferrer');
  };

  return (
    <div className="slack-connect">
      <div className="slack-connect-header">
        <div className="left-side">
          <div className="left-side-logo">
            <img src={SlackLogo} alt="slack-logo" />
          </div>
          <div className="left-side-text">
            <div className="header">
              <div className="subtitle-3">Slack</div>
              {isSlackEnabled && (
                <div className="label-connected body-4">
                  <i className="isax isax-tick-circle5"></i> Connected
                </div>
              )}
            </div>
            <div className="body-3 n-700">
              Automatically send survey responses to Slack channels.{' '}
              <a
                href="https://help.usejimo.com/help-center/v/using-jimo/integrations/slack" // TODO set this page up
                target="_blank"
                rel="noreferrer">
                Learn more <i className="isax isax-export-3" />
              </a>
            </div>
          </div>
        </div>
        <div className="right-side">
          {isSlackEnabled &&
            !isArrayEqualMultiset(
              selectedChannels.map((c) => c.id),
              evolutionSlackChannelsCache.map((c) => c.id)
            ) && (
              <div className="action-button-group">
                <Button
                  disabled={isChannelsFetching}
                  onClick={() => {
                    setSelectedChannels(evolutionSlackChannelsCache);
                  }}>
                  Cancel
                </Button>
                <Button
                  disabled={isChannelsFetching}
                  loading={isUpdateEvolutionSlackChannelsLoading}
                  primary
                  onClick={() => {
                    updateEvolutionSlackChannels(selectedChannels);
                  }}>
                  Save selection
                </Button>
              </div>
            )}
          {!isSlackEnabled && (
            <Button
              primary
              thin
              loading={isSlackAuthenticateLoading}
              onClick={() => slackAuthenticate()}>
              Connect
            </Button>
          )}
        </div>
      </div>
      {isSlackEnabled &&
        (isChannelsFetching ? (
          <div className="slack-connect-loading-container">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="slack-connect-card">
              <Select
                options={channels.map((c) => ({
                  value: c.id,
                  label: c.name,
                  detail: c,
                }))}
                placeholder="Select channels to send responses"
                onChange={(sl) => setSelectedChannels(sl.map((s) => s.detail))}
                isMulti
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={selectedChannels.map((c) => ({
                  value: c.id,
                  label: c.name,
                  detail: c,
                }))}
                components={{
                  MultiValue,
                  Option: Option,
                }}
              />
              <Link to={ROUTE_SETTINGS_INTEGRATIONS_SLACK}>
                <Button tertiary thin iconLeft="isax isax-book">
                  How to add private channels to selection
                </Button>
              </Link>
              <Divider />
              <div className="card-complete">
                <div className="left-side">
                  <div className="subtitle-3">
                    Receive Slack Notifications for Each Answers
                  </div>
                  <div className="body-3 n-700">
                    Get notifications on Slack for each answer to all survey
                    questions, not just when submissions are completed
                  </div>
                </div>
                <div className="right-side">
                  <Toggle
                    checked={hasFlag(
                      F_EVOLUTION_SLACK_CHANNEL_EACH_STEP_REPORT,
                      slackChannelFlags
                    )}
                    onChange={updateSlackChannelFlags}
                    disabled={isUpdateSlackChannelFlagsLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const MoreSelectedBadge = ({items}) => {
  const style = {
    color: 'black',
  };

  const label =
    items.length === 1 ? items[0] : `${items.length} channels selected`;
  return <div style={style}>{label}</div>;
};

const MultiValue = ({index, getValue}) => {
  const maxToShow = 0;
  const items = getValue().map((x) => x.label);

  return index === maxToShow ? <MoreSelectedBadge items={items} /> : null;
};

const Option = ({data, isSelected, ...props}) => {
  const style = {
    display: 'flex',
    gap: '8px',
    'align-items': 'center',
  };
  return (
    <components.Option {...props}>
      <div style={style}>
        {isSelected ? (
          <i className="icon-checkbox-a" />
        ) : (
          <i className="icon-checkbox-o"></i>
        )}
        {data.detail?.is_private === true && <i className="isax isax-lock" />}
        {'#'}
        {data.label}
      </div>
    </components.Option>
  );
};

export default SlackConnect;
