/* eslint-disable import/no-anonymous-default-export */
import {
  DATA_SET_BUILT_IN_ROLES,
  DATA_SET_CUSTOM_ATTRIBUTES,
  DATA_SET_CUSTOM_ROLES,
  DATA_SET_REQUESTS,
  DATA_SET_SEGMENTS,
  DATA_SET_SUBSCRIPTION_ADDONS,
  DATA_SET_SUBSCRIPTION_PLANS,
  DATA_UPT_REQUEST,
  DATA_UPT_SEGMENT,
} from 'actions/data';

const defaultDataState = {
  requests: [],
  subscriptionPlans: [],
  subscriptionAddons: [],
  segments: [],
  customAttributes: [],
  customRoles: [],
  builtInRoles: [],
};

export default (state = defaultDataState, action) => {
  switch (action.type) {
    case DATA_SET_REQUESTS: {
      return {
        ...state,
        requests: action.requests,
      };
    }
    case DATA_SET_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        subscriptionPlans: action.plans,
      };
    }
    case DATA_SET_SUBSCRIPTION_ADDONS: {
      return {
        ...state,
        subscriptionAddons: action.addons,
      };
    }
    case DATA_UPT_REQUEST: {
      return {
        ...state,
        requests: state.requests.map((r) =>
          r.uid === action.requestId ? {...r, ...action.data} : r
        ),
      };
    }
    case DATA_SET_SEGMENTS: {
      return {
        ...state,
        segments: action.segments,
      };
    }
    case DATA_SET_CUSTOM_ATTRIBUTES: {
      return {
        ...state,
        customAttributes: action.attributes,
      };
    }
    case DATA_UPT_SEGMENT: {
      return {
        ...state,
        segments: state.segments.map((s) =>
          s.uid === action.segmentId ? {...s, ...action.data} : s
        ),
      };
    }
    case DATA_SET_CUSTOM_ROLES: {
      return {
        ...state,
        customRoles: action.roles,
      };
    }
    case DATA_SET_BUILT_IN_ROLES: {
      return {
        ...state,
        builtInRoles: action.roles,
      };
    }
    default:
      return state;
  }
};
