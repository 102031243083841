import React from 'react';
import './_Styles.scss';
import classNames from 'classnames';

export const CHART_TIME_FRAME_14_DAYS = '14';
export const CHART_TIME_FRAME_1_MONTH = '30';
export const CHART_TIME_FRAME_3_MONTHS = '90';
export const CHART_TIME_FRAME_6_MONTHS = '180';
export const CHART_TIME_FRAME_1_YEAR = '365';

const timeFrameOptions = [
  {label: '14d', value: CHART_TIME_FRAME_14_DAYS},
  {label: '1m', value: CHART_TIME_FRAME_1_MONTH},
  {label: '3m', value: CHART_TIME_FRAME_3_MONTHS},
  {label: '6m', value: CHART_TIME_FRAME_6_MONTHS},
  {label: '1y', value: CHART_TIME_FRAME_1_YEAR},
];

const TimeFrameSelector = ({timeFrame, setTimeFrame, omit = []}) => {
  return (
    <div className="chart-time-frame-wrapper">
      {timeFrameOptions
        .filter((option) => !omit.includes(option.value))
        .map((option) => {
          return (
            <div
              className={classNames('time-frame-item', {
                selected: timeFrame === option.value,
              })}
              onClick={() => {
                setTimeFrame(option.value);
              }}>
              {option.label}
            </div>
          );
        })}
    </div>
  );
};

export default TimeFrameSelector;
