import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {SegmentAttributesEditorContext} from 'components/SegmentAttributesEditor';
import SelectAttribute from 'components/SegmentAttributesEditor/select-attribute';
import {object} from 'prop-types';
import React, {useContext, useState} from 'react';
import Attribute from '../Attribute';
import './_Styles.scss';

const propTypes = {
  logicGroup: object.isRequired,
};

const defaultProps = {};

const Logic = ({logicGroup}) => {
  const {uid, operator, children} = logicGroup;

  const {
    attributes,
    dropdownProps,
    segmentioFields,
    segmentioEvents,
    trackedEvents,
    withSegments,
    hideAttributes,
    addButtonLabel,
    onSelectExistingSegment,
    handleAddAttribute,
    handleAddLogicGroup,
    handleUpdateLogicOperator,
    handleDeleteLogicGroupFromLogic,
  } = useContext(SegmentAttributesEditorContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [preventDropdownClose, setPreventDropdownClose] = useState(false);

  const handleToggleOperator = () => {
    const newOperator = operator === 'and' ? 'or' : 'and';
    handleUpdateLogicOperator(uid, newOperator);
  };

  return (
    <div className="logic-group">
      <div className="content">
        {children.length >= 1 && (
          <div className={classNames('logic-trait-wrapper', operator)}>
            <div className="logic-trait" />
            <div className="logic-operator-wrapper">
              <div
                className={classNames('logic-operator', 'and', {
                  selected: operator === 'and',
                })}
                onClick={handleToggleOperator}>
                and
              </div>
              <div
                className={classNames('logic-operator', 'or', {
                  selected: operator === 'or',
                })}
                onClick={handleToggleOperator}>
                or
              </div>
            </div>
          </div>
        )}
        <div className="logic-conditions">
          {children.map((item) => {
            const {type, attributeId} = item;
            if (type === 'logicGroup') {
              return <Logic logicGroup={item} />;
            }
            const attribute = attributes.find(
              (attr) => attr.uid === attributeId
            );
            if (attribute == null) {
              return null;
            }
            return (
              <div className="logic-condition">
                <Attribute attribute={attribute} />
              </div>
            );
          })}
          <div className="logic-actions">
            <Dropdown
              open={showDropdown}
              className="add-segment-element"
              position="bottom left"
              repositionOnResize={true}
              closeOnDocumentClick={preventDropdownClose === false}
              offsetY={-64}
              offsetX={8}
              trigger={
                <Button
                  className="add-segment-element-btn"
                  onClick={() => setShowDropdown(true)}
                  thin>
                  Add...
                </Button>
              }>
              <div className="add-segment-element-list">
                <SelectAttribute
                  hasNullValue
                  dropdownProps={{
                    ...dropdownProps,
                    trigger: (
                      <div className="add-segment-element-item">
                        <i className="icon-filter" />
                        <div>Add attribute</div>
                      </div>
                    ),
                    onOpen: () => {
                      setPreventDropdownClose(true);
                    },
                    onClose: () => {
                      setPreventDropdownClose(false);
                      setShowDropdown(false);
                    },
                  }}
                  className="global-add-attribute"
                  onChange={(value, opts) => {
                    handleAddAttribute(value, opts, uid);
                    setShowDropdown(false);
                    setPreventDropdownClose(false);
                  }}
                  onSelectExistingSegment={onSelectExistingSegment}
                  segmentioFields={segmentioFields}
                  segmentioEvents={segmentioEvents}
                  trackedEvents={trackedEvents}
                  withSegments={withSegments}
                  hideAttributes={hideAttributes}
                  addButtonLabel={addButtonLabel}
                />
                <div
                  className="add-segment-element-item"
                  onClick={() => {
                    handleAddLogicGroup(uid);
                    setShowDropdown(false);
                  }}>
                  <i className="icon-merge" />
                  New logic group
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <Button
        className="delete-btn"
        iconLeft="icon-trash"
        iconOnly
        danger
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          handleDeleteLogicGroupFromLogic(uid);
        }}
      />
    </div>
  );
};

Logic.propTypes = propTypes;
Logic.defaultProps = defaultProps;

export default Logic;
