import React from 'react';
import './_Styles.scss';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import classNames from 'classnames';

export const TIME_FREQUENCY_DAILY = 'day';
export const TIME_FREQUENCY_WEEKLY = 'week';
export const TIME_FREQUENCY_MONTHLY = 'month';

const timeFrequencyOptions = [
  {label: 'Daily', value: TIME_FREQUENCY_DAILY},
  {label: 'Weekly', value: TIME_FREQUENCY_WEEKLY},
  {label: 'Monthly', value: TIME_FREQUENCY_MONTHLY},
];

const FrequencySelectionDropdown = ({frequency, setFrequency}) => {
  const selectedFrequency = timeFrequencyOptions.find(
    (o) => o.value === frequency
  );
  const selectedTimeFrameLabel = selectedFrequency?.label;

  return (
    <div className="frequency-selection-dropdown-wrapper">
      <Dropdown
        className="frequency-selection"
        position="bottom right"
        repositionOnResize={false}
        trigger={
          <Button thin iconRight="icon-chevron-bottom">
            {selectedTimeFrameLabel}
          </Button>
        }>
        <div className="frequency-list">
          {timeFrequencyOptions.map((option) => {
            return (
              <div
                className={classNames('frequency-item', {
                  'is-set': frequency === option.value,
                })}
                onClick={() => {
                  setFrequency(option.value);
                }}>
                {option.label}
              </div>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

export default FrequencySelectionDropdown;
