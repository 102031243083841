import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import dayjs from 'dayjs';
import {crispHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useStartTrial} from 'hooks/useStartTrial';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {isEligibleToTrial, isTrying} from 'services/project';
import {
  F_PRICING_V0,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
  displayPrice,
  getMauPrice,
  getPlanMonthlyPrice,
  getPlanYearlyPrice,
  isUpgrade,
} from 'services/subscription';
import planDetails from '../../plan-details';
import './_styles.scss';

export const ItemPlan = ({
  plan,
  mau,
  modeAnnualBilling,
  preventPeriodChanges,
}) => {
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {update, isUpdating} = useUpdateSubscription();
  const {start, isStarting} = useStartTrial();

  const canTry = isEligibleToTrial(plan.uid);
  const isCurrentPlan = subscription.plan === plan.uid;
  const isTryingPlan = isCurrentPlan === true && isTrying();
  const yearlyPrice =
    Math.round(getPlanYearlyPrice(plan) / 12) +
    Math.round(getMauPrice(mau, true) / 12);
  const monthlyPrice = getPlanMonthlyPrice(plan) + getMauPrice(mau);
  const trialDaysLeft =
    14 - Math.round(dayjs().diff(dayjs(subscription.trialStartedAt), 'days'));

  return (
    <div
      className={classnames(
        'settings-card item-plan',
        `plan-${plan.uid.toLowerCase()}`
      )}>
      <div className="plan-spline-container">
        <iframe
          title={`animation-${plan.uid}`}
          src={
            plan.uid === PLAN_STARTUP_ID
              ? 'https://my.spline.design/untitledcopycopycopy-45b9f8232163067f6395e1b9e8e1a71c/'
              : plan.uid === PLAN_GROWTH_ID
              ? 'https://my.spline.design/untitledcopycopy-d37c17e6be965d0185debabd9badb6f9/'
              : 'https://my.spline.design/untitled-c8d25fa74e83ca49b2def591dd79a328/'
          }
          frameborder="0"></iframe>
      </div>
      {plan.uid === PLAN_GROWTH_ID && (
        <div className="plan-most-popular subtitle-4">
          <i className="isax isax-star-15"></i>
          Most Popular
        </div>
      )}
      <div className="plan-section section-header">
        <div className="plan-title">
          <div
            className={classnames('subtitle-2', {
              'g-400': PLAN_STARTUP_ID === plan.uid,
              'o-500': PLAN_GROWTH_ID === plan.uid,
              'b-400': PLAN_SCALE_ID === plan.uid,
            })}>
            {plan.title}
          </div>
          <small className="body-3 n-700">
            {plan.uid === PLAN_STARTUP_ID
              ? 'Best for small teams'
              : plan.uid === PLAN_GROWTH_ID
              ? 'Best for growing businesses'
              : 'Best for large organizations'}
          </small>
        </div>
      </div>
      <div className="plan-section section-price">
        <div className="plan-price title-2">
          {plan.uid === PLAN_SCALE_ID ? (
            'Custom'
          ) : mau <= 50000 ? (
            <>
              {displayPrice(
                modeAnnualBilling === true ? yearlyPrice : monthlyPrice
              )}
              {modeAnnualBilling === true && (
                <div className="subtitle-1 n-500">
                  {displayPrice(monthlyPrice)}
                </div>
              )}
            </>
          ) : (
            'Custom'
          )}
        </div>
        <small className="body-3 n-600">
          {modeAnnualBilling === true
            ? '/month, billed yearly'
            : '/month, billed monthly'}
        </small>
        <small className="body-3 n-600">
          Up to {mau <= 50000 ? commaNumber(mau) : `>50,000`} MAU
        </small>
      </div>
      {preventPeriodChanges === false &&
        isCurrentPlan === false &&
        canTry === true &&
        mau <= 50000 && (
          <Button
            primary
            onClick={() => start({planId: plan.uid, mau})}
            loading={isStarting}
            className="btn-upgrade">
            Start 14 days free trial
          </Button>
        )}
      {preventPeriodChanges === false &&
        (isCurrentPlan === false ||
          (isCurrentPlan === true && isTrying() === true)) &&
        canTry === false &&
        plan.uid !== PLAN_SCALE_ID &&
        mau <= 50000 && (
          <Button
            primary
            className="btn-upgrade"
            loading={isUpdating}
            onClick={() => {
              if (
                isTrying() === false &&
                hasFlag(F_PRICING_V0, subscription.extraFlags) === true
              ) {
                crispHelpers.startCrispThread(
                  isUpgrade(plan.uid) === true
                    ? `I'd like to upgrade to the ${plan.title} plan. Can you help me? :-)`
                    : `I'd like to downgrade to the ${plan.title} plan. Can you help me? :-)`
                );
                return;
              }
              update({
                planId: plan.uid,
                mau,
                modeAnnualBilling,
                skipPreviewMode: true,
              });
            }}>
            {isTrying() === true ? (
              isCurrentPlan ? (
                'Subscribe plan'
              ) : (
                'Upgrade plan'
              )
            ) : hasFlag(F_PRICING_V0, subscription.extraFlags) === true ? (
              <>
                {isUpgrade(plan.uid) === true
                  ? 'Contact sales to upgrade'
                  : `Contact sales to downgrade`}
              </>
            ) : (
              <>
                {isUpgrade(plan.uid) === true
                  ? 'Upgrade'
                  : `Downgrade to ${plan.title}`}
              </>
            )}
          </Button>
        )}
      {preventPeriodChanges === false &&
        isCurrentPlan === true &&
        isTrying() === false && (
          <Button primary disabled className="btn-upgrade">
            Current Plan
          </Button>
        )}
      {preventPeriodChanges === false &&
        isCurrentPlan === false &&
        (plan.uid === PLAN_SCALE_ID || mau > 50000) && (
          <Button
            primary
            className="btn-upgrade"
            onClick={() => {
              crispHelpers.startCrispThread(
                plan.uid === PLAN_SCALE_ID
                  ? "I'd like to go for an Enterprise plan!"
                  : "I'd like to go for more than 50,000 MAU!"
              );
            }}>
            Contact sales
          </Button>
        )}
      {preventPeriodChanges === true && (
        <Button
          className="btn-upgrade"
          onClick={() =>
            crispHelpers.startCrispThread(
              isCurrentPlan === false
                ? `I'd like to switch to a ${plan.title} plan and change my billing period. Can you help me? :-)`
                : "I'd like to change my billing period. Can you help me ? :-)"
            )
          }>
          Contact support
        </Button>
      )}
      {isCurrentPlan && isTryingPlan && (
        <div className="plan-current-trial subtitle-4">
          <i className="isax isax-timer5"></i> In Trial ({trialDaysLeft} days
          left)
        </div>
      )}
      <div className="plan-section section-features">
        {[
          ...(plan.uid === PLAN_GROWTH_ID
            ? [
                {
                  uid: 'include-previous-plan',
                  title: `Everything in Startup Plan`,
                },
              ]
            : plan.uid === PLAN_SCALE_ID
            ? [
                {
                  uid: 'include-previous-plan',
                  title: `Everything in Growth Plan`,
                },
              ]
            : []),
          {uid: 'seats', title: `${plan.seats} seats`},
        ]
          .concat(planDetails[plan.uid])
          .map((feature) => {
            return (
              <div key={feature.uid} className="item-feature">
                <i className="isax isax-tick-circle5"></i>
                <div className="item-body">
                  <div className="body-3">{feature.title}</div>
                  {feature.description != null && (
                    <div className="body-3 n-500">{feature.description}</div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
