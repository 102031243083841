export const PermissionsPoke = {
  ACCESS: 'poke.access',
  CREATE_EDIT: 'poke.create-edit',
  DELETE: 'poke.delete',
  PUBLISH: 'poke.publish',
  ANALYTICS: 'poke.analytics',
};

export const PermissionsPost = {
  ACCESS: 'post.access',
  CREATE_EDIT: 'post.create-edit',
  DELETE: 'post.delete',
  PUBLISH: 'post.publish',
  ANALYTICS: 'post.analytics',
};

export const PermissionsPeople = {
  ACCESS: 'people.access',
  USER_EDIT: 'people.user.edit',
  USER_DELETE: 'people.user.delete',
  SEGMENT_CREATE_EDIT: 'people.segment.create-edit',
  SEGMENT_DELETE: 'people.segment.delete',
};

export const PermissionsRequest = {
  ACCESS: 'request.access',
  CREATE_EDIT: 'request.create-edit',
  DELETE: 'request.delete',
};

export const PermissionsSettings = {
  ACCESS: 'settings.access',
  GENERAL_ACCESS: 'settings.general.access',
  CHANGELOG_ACCESS: 'settings.changelog.access',
  THEME_ACCESS: 'settings.theme.access',
  INSTALLATION_ACCESS: 'settings.installation.access',
  POKE_CONTROL_ACCESS: 'settings.pokeControl.access',
  TEAM_ACCESS: 'settings.team.access',
  TEAM_MEMBER_INVITE: 'settings.team.invite-member',
  TEAM_MEMBER_DELETE: 'settings.team.delete-member',
  TEAM_MEMBER_ROLE_CHANGE: 'settings.team.change-member-role',
  TEAM_UPDATE_ROLE: 'settings.team.update-role',
  BILLING_ACCESS: 'settings.billing.access',
  INTEGRATION_ACCESS: 'settings.integration.access',
  ENVIRONMENTS_ACCESS: 'settings.environments.access',
};

export const PermissionsSuccessTracker = {
  ACCESS: 'success-tracker.access',
  CREATE_EDIT: 'success-tracker.create-edit',
  DELETE: 'success-tracker.delete',
};

export const PermissionsEvent = {
  ACCESS: 'event.access',
  CREATE_EDIT: 'event.create-edit',
  DELETE: 'event.delete',
};
