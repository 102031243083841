import React from 'react';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SlackSettingsSetup');

const SettingsSetup = ({slack, isSlackLoading}) => {
  return (
    <div className="slack-settings-setup">
      <div className="settings-card">
        <div className="card-header">
          <div className="subtitle-4">How it works</div>
          <div classname="body-3 n-700">
            • Send survey data to slack when a response is received
          </div>
          <div classname="body-3 n-700">
            • Easily collaborate and share survey response data in slack to
            leverage user insights across product development
          </div>
        </div>
        <div className="card-private-channels">
          <div className="subtitle-3">How to add private channels</div>
          <div className="step-item">
            <div className="item-left subtitle-4"> 1 </div>
            <div className="item-right body-3"> In Slack, go to the private channel(s) where you want to set up Jimo notifications. </div>
          </div>
          <div className="step-item">
            <div className="item-left subtitle-4"> 2 </div>
            <div className="item-right"> 
              <text className="body-3">
                Type 
                  <text className="item-command b-400">
                  /invite @Jimo 
                  </text>
                in the message field and send it to add Jimo to the channel. 
              </text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSetup;
