import React, {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {trackerAnalyticsService} from 'services';
import {object} from 'prop-types';
import './_Styles.scss';
import {Swaler} from 'swaler';
import dayjs from 'dayjs';
import {TrackerContext} from 'scenes/Tracker';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import TrackerListUsersDrawer from '../../../TrackerListUsersDrawer';
import classNames from 'classnames';

const StepActivityItem = ({tracker, index, step, percentage, count}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div className="step-activity-item">
      <div className="step-stats">
        <div className="percentage">{percentage}%</div>
        <div className="users-count">
          {count || 0} user{count > 0 ? 's' : ''}
        </div>
      </div>

      <Tooltip
        offsetX={100}
        offsetY={-100}
        className="tooltip-tracker-step-details"
        position="center center"
        disabled={!(percentage > 0)}
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}
        trigger={
          <div
            className={classNames('chart-wrapper', {
              hovered: isTooltipOpen,
            })}>
            {percentage > 0 ? (
              <>
                <div
                  className="chart"
                  style={{
                    height: `${Math.min(100, percentage)}%`,
                  }}></div>
              </>
            ) : (
              <></>
            )}
          </div>
        }>
        <div className="tracker-step-details-content">
          <div className="title">
            {percentage}% converted to {step.name}
          </div>
          <div className="groups">
            <div className="group">
              <div className="color-indication with-jimo" />
              <div className="data">
                <div className="group-pct">{percentage}%</div>
                <div className="group-value">
                  {count} user
                  {count > 1 ? 's' : ''}
                </div>
              </div>
            </div>
          </div>
          <Button
            className="see-breakdown-btn"
            primary
            reverted
            thin
            onClick={() => {
              setIsDrawerOpen(true);
            }}>
            See breakdown
          </Button>
        </div>
      </Tooltip>
      <div className="step-info">
        <div className="step-index">{index + 1}</div>
        <div className="step-name">{step.name}</div>
      </div>

      {isDrawerOpen === true && (
        <TrackerListUsersDrawer
          isOpen={isDrawerOpen}
          onRequestClose={() => setIsDrawerOpen(false)}
          tracker={tracker}
          selectedTrackerStep={step}
        />
      )}
    </div>
  );
};

const propTypes = {
  tracker: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const defaultProps = {
  tracker: null,
};

const logger = new Swaler('TrackerStepsActivity');

const TrackerStepsActivity = ({timeRange}) => {
  const {tracker} = useContext(TrackerContext);

  const [analytics, setAnalytics] = useState([]);

  const [startDate, endDate] = timeRange;

  const steps = tracker?.steps;

  const {isLoading} = useQuery({
    queryKey: ['analyticsDaily', 'tracker', tracker.uid, startDate, endDate],
    queryFn: async () => {
      const analytics = await trackerAnalyticsService.getAnalyticsDaily({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        trackerId: tracker.uid,
      });
      setAnalytics(analytics);
    },
    onError: (err) => {
      logger.error(
        'Could not fetch steps analytics, failed with err',
        err.message
      );
    },
  });

  const stepsWithAnalytics = analytics?.reduce((acc, cur) => {
    const index = acc.map((step) => step.uid).indexOf(cur.trackerStepId);
    if (index >= 0) {
      if (acc[index].count == null) {
        acc[index].count = parseInt(cur.count, 10);
      } else {
        acc[index].count = acc[index].count + parseInt(cur.count, 10);
      }
    }
    return acc;
  }, JSON.parse(JSON.stringify(steps || [])));

  const denominator = Math.max(
    ...stepsWithAnalytics?.map((step) => step.count || 0)
  );

  stepsWithAnalytics?.sort((a, b) => a.indexOrder - b.indexOrder);

  return (
    <div className="tracker-steps-activity">
      {stepsWithAnalytics?.map((step, index) => {
        const percentage =
          denominator > 0 && step.count > 0
            ? Math.round((step.count * 100) / denominator)
            : 0;

        return (
          <StepActivityItem
            tracker={tracker}
            index={index}
            step={step}
            percentage={percentage}
            count={step.count}
          />
        );
      })}
    </div>
  );
};

TrackerStepsActivity.propTypes = propTypes;
TrackerStepsActivity.defaultProps = defaultProps;

export default TrackerStepsActivity;
