import classNames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import CustomSlider from 'components/CustomSlider';
import Dropdown from 'components/Dropdown';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import {Modal} from 'components/Modal';
import dayjs from 'dayjs';
import {hasUsernameGenerated} from 'helpers/utils';
import {bool, func} from 'prop-types';
import {useState} from 'react';
import {
  JimerProfile,
  TAB_SURVEY_RESPONSE,
} from 'scenes/Users/components/ModalJimerProfile';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import {
  BLOCK_CHOICE,
  BLOCK_OPINION,
} from 'shared/front/components/Poke/constants/blocks';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const defaultProps = {};

const CATEGORY_PROMOTER = 'promoter';
const CATEGORY_PASSIVE = 'passive';
const CATEGORY_DETRACTOR = 'detractor';

const npsOptions = [
  {
    value: CATEGORY_PROMOTER,
    label: 'Promoters',
    additional: 'Responded with 9 to 10',
  },
  {
    value: CATEGORY_PASSIVE,
    label: 'Passives',
    additional: 'Responded with 7 to 8',
  },
  {
    value: CATEGORY_DETRACTOR,
    label: 'Detractors',
    additional: 'Responded with 0 to 6',
  },
];

const sliderOptions = [
  {
    value: '1',
    label: '0% - 25%',
  },
  {
    value: '2',
    label: '25% - 50%',
  },
  {
    value: '3',
    label: '50% - 75%',
  },
  {
    value: '4',
    label: '75% - 100%',
  },
];

const ResponsesDrawer = ({
  isOpen,
  onRequestClose,
  responses = [],
  selectedValue = null,
  step,
  stepHeader,
  ...rest
}) => {
  let options = [];
  if (step.type === STEP_TYPE_NPS) {
    options = npsOptions;
  } else if (step.type === STEP_TYPE_OPINION_SCALE) {
    const blockOpinion = step.blocks?.find((b) => b.type === BLOCK_OPINION);
    const opinionValue = parseOpinionValue(blockOpinion?.value || '');
    let opinions = [];
    if (['emoji', 'smiley'].includes(opinionValue.type)) {
      opinions = JSON.parse(
        JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
      );
    } else if (opinionValue.type === 'star') {
      opinions = new Array(opinionValue.scale).fill(0).map((_, i) => {
        return {
          value: i + 1,
          label: (
            <>
              {new Array(i + 1).fill(0).map(() => (
                <i className="icon-star" style={{color: '#FFC107'}} />
              ))}
            </>
          ),
        };
      });
    }
    options = opinions;
  } else if (step.type === STEP_TYPE_SLIDER) {
    options = sliderOptions;
  } else if (step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
    const block = step.blocks?.find((b) => b.type === BLOCK_CHOICE);
    options = block?.options.map((o) => {
      return {
        value: o.uid,
        label: o.content,
      };
    });
  }

  const [jimerProfileData, setJimerProfileData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    options.filter(
      (o) =>
        o.value === selectedValue || o.value === parseInt(selectedValue, 10)
    )
  );

  const nonEmptyResponses = responses.filter(
    (r) =>
      (r.value != null && r.value.length > 0) || r.selectedOptions?.length > 0
  );
  const filteredResponses = nonEmptyResponses.filter((response) => {
    if (selectedOptions.length === 0) {
      return true;
    } else if (step.type === STEP_TYPE_NPS) {
      const {value} = response;
      const category =
        value <= 6
          ? CATEGORY_DETRACTOR
          : value > 6 && value <= 8
          ? CATEGORY_PASSIVE
          : CATEGORY_PROMOTER;
      return selectedOptions.find((o) => o.value === category) != null;
    } else if (step.type === STEP_TYPE_OPINION_SCALE) {
      const {value} = response;
      return (
        selectedOptions.find(
          (o) => parseInt(o.value, 10) === parseInt(value, 10)
        ) != null
      );
    } else if (step.type === STEP_TYPE_SLIDER) {
      const {value} = response;
      const category =
        value <= 250
          ? '1'
          : value > 250 && value <= 500
          ? '2'
          : value > 500 && value <= 750
          ? '3'
          : '4';
      return selectedOptions.find((o) => o.value === category) != null;
    } else if (step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT) {
      const {selectedOptions: value} = response;
      return selectedOptions.some((o) =>
        value.map((v) => v.uid).includes(o.value)
      );
    }
    return false;
  });

  return (
    <Modal
      className="users-responses-drawer fade-in-right"
      title={jimerProfileData == null ? stepHeader : null}
      isOpen={isOpen}
      {...rest}
      onRequestClose={() => {
        setJimerProfileData(null);
        onRequestClose();
      }}>
      {jimerProfileData != null ? (
        <JimerProfile
          onBack={() => setJimerProfileData(null)}
          jimerId={jimerProfileData?.jimerId}
          defaultTab={TAB_SURVEY_RESPONSE}
          defaultSurveyId={jimerProfileData?.surveyId}
        />
      ) : (
        <div className="content-wrapper">
          <div className={classNames('users-table-wrapper')}>
            <div className="users-table">
              {options?.length > 0 && (
                <div className="users-header">
                  <Dropdown
                    className="users-responses-dropdown"
                    position="bottom left"
                    triggerClassName={classNames({
                      'is-set': selectedOptions.length > 0,
                    })}
                    contentStyle={{zIndex: 1001}}
                    repositionOnResize={false}
                    trigger={
                      <Button
                        thin
                        iconLeft="icon-comment-o"
                        iconRight="icon-chevron-bottom">
                        {selectedOptions.length > 0
                          ? selectedOptions.map((o, i) => (
                              <span key={i}>
                                {i > 0 ? ', ' : ''}
                                {o.label}
                              </span>
                            ))
                          : 'All responses'}
                      </Button>
                    }>
                    <div className="setting-list">
                      {options?.map((option) => {
                        const isSelected =
                          selectedOptions.find(
                            (s) => s.value === option.value
                          ) != null;
                        return (
                          <div
                            className={classNames('setting-item', {
                              'is-set': isSelected,
                            })}
                            onClick={() => {
                              if (isSelected) {
                                setSelectedOptions(
                                  selectedOptions.filter(
                                    (o) => o.value !== option.value
                                  )
                                );
                              } else {
                                setSelectedOptions([
                                  ...selectedOptions,
                                  option,
                                ]);
                              }
                            }}>
                            {isSelected ? (
                              <i className="icon-checkbox"></i>
                            ) : (
                              <i className="icon-checkbox-o"></i>
                            )}
                            <div className="option-info">
                              <div className="option-label">{option.label}</div>
                              <div className="option-additional">
                                {option.additional}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Dropdown>
                </div>
              )}
              {filteredResponses.length === 0 ? (
                selectedOptions.length > 0 ? (
                  <EmptyStateBlock
                    img={EmptyStateImgs.EmptyResults}
                    title={'No results found'}
                    description="Refine your filters to find the users you're looking for"
                  />
                ) : (
                  <EmptyStateBlock
                    img={EmptyStateImgs.EmptyUsersReached}
                    title={'No users reached yet'}
                    description="Once this experience reach your users, you'll see them here"
                  />
                )
              ) : step.type === STEP_TYPE_TEXT_LONG ? (
                <div className="list-open-question-wrapper">
                  {filteredResponses
                    .filter((r) => r.value)
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((r) => {
                      const jimer = r.jimer;
                      const username =
                        jimer?.externalUsername != null &&
                        jimer?.externalUsername.length !== 0
                          ? jimer?.externalUsername
                          : jimer?.username;
                      const isAnonymous = hasUsernameGenerated(username);

                      return (
                        <div
                          key={r.uid}
                          className={classNames('response')}
                          onClick={() => {
                            setJimerProfileData({
                              jimerId: jimer.uid,
                              surveyId: r.surveyId,
                            });
                          }}>
                          <div className="content">
                            <div className="value">“{r.value}“</div>
                            <div className="info">
                              <div className="info-content">
                                <div className="user-info">
                                  <i className="icon-users" />
                                  <div className="username">
                                    {isAnonymous === true
                                      ? 'Anonymous'
                                      : username}
                                  </div>
                                  {isAnonymous === true && (
                                    <div className="anonymous-username">
                                      {username}
                                    </div>
                                  )}
                                  {jimer?.externalEmail && (
                                    <div className="email-info">
                                      &bull; {jimer?.externalEmail}
                                    </div>
                                  )}
                                </div>
                                <div className="time-info">
                                  <i className="icon-calendar" />
                                  <div className="time">
                                    {dayjs(r.createdAt).format(
                                      'DD MMM YYYY, HH:mm'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <>
                  <div className="users-list">
                    <div className="top">
                      <div></div>
                      <div>Name</div>
                      <div>Response</div>
                      <div>Answered at</div>
                    </div>

                    <div className="list">
                      {filteredResponses
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((response) => {
                          const {jimer, surveyId} = response;

                          const username =
                            jimer.externalUsername != null &&
                            jimer.externalUsername.length !== 0
                              ? jimer.externalUsername
                              : jimer.username;
                          const isAnonymous =
                            hasUsernameGenerated(username) &&
                            !jimer.identifyToken &&
                            !jimer.externalUid;

                          return (
                            <div
                              key={jimer.uid}
                              className={classNames('card-user')}
                              onClick={() => {
                                setJimerProfileData({
                                  jimerId: jimer.uid,
                                  surveyId,
                                });
                              }}>
                              <div className="user-avatar-wrapper">
                                <Avatar jimer={jimer} />
                              </div>
                              <div className="username-wrapper">
                                <div className="username">
                                  {isAnonymous ? (
                                    'Anonymous'
                                  ) : !!username ? (
                                    username
                                  ) : (
                                    <em>username</em>
                                  )}
                                </div>
                                {isAnonymous === true ? (
                                  <div className="anonymous-name">
                                    {username}
                                  </div>
                                ) : jimer.identifyToken || jimer.externalUid ? (
                                  <div className="anonymous-name">
                                    {jimer.identifyToken || jimer.externalUid}
                                  </div>
                                ) : null}
                                {jimer?.externalEmail && (
                                  <div className="email-info">
                                    {jimer?.externalEmail}
                                  </div>
                                )}
                              </div>
                              <div className="user-response">
                                {step.type === STEP_TYPE_NPS ? (
                                  <div className="nps-wrapper">
                                    <div
                                      className={classNames('nps', {
                                        'detractor-1': response.value <= 3,
                                        'detractor-2':
                                          response.value > 3 &&
                                          response.value <= 6,
                                        passive:
                                          response.value > 6 &&
                                          response.value <= 8,
                                        promoter: response.value > 8,
                                      })}>
                                      {response.value}
                                    </div>
                                  </div>
                                ) : step.type === STEP_TYPE_SLIDER ? (
                                  <div className="slider-wrapper">
                                    <CustomSlider
                                      defaultHandle
                                      value={response.value}
                                    />
                                  </div>
                                ) : step.type === STEP_TYPE_OPINION_SCALE ? (
                                  <div className="opinion-wrapper">
                                    {
                                      options.find(
                                        (o) =>
                                          parseInt(o.value, 10) ===
                                          parseInt(response.value, 10)
                                      )?.label
                                    }
                                  </div>
                                ) : step.type ===
                                  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ? (
                                  <div className="multiple-choice-wrapper">
                                    {response.selectedOptions.map((o) => (
                                      <div className="option">{o.content}</div>
                                    ))}
                                  </div>
                                ) : (
                                  response.value
                                )}
                              </div>
                              <div className={classNames('user-response-date')}>
                                <div className="content">
                                  <div className="event-date">
                                    {dayjs(response.createdAt).format(
                                      'DD MMM YYYY, HH:mm'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

ResponsesDrawer.propTypes = propTypes;
ResponsesDrawer.defaultProps = defaultProps;

export default ResponsesDrawer;
