import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

// Horizontal Tip
export function TipH(props) {
  const {className, bgColor, image, children, actions, ...rest} = props;
  const classNames = classnames('tip tip-horz', className);

  return (
    <div className={classNames} style={{backgroundColor: bgColor}} {...rest}>
      <div className="image-wrapper">
        <div style={{backgroundImage: `url(${image})`}}></div>
      </div>
      <div className="content-wrapper">{children}</div>
      <div className="actions-wrapper">{actions}</div>
    </div>
  );
}

// Vertical Tip
export function TipV(props) {
  const {className, bgColor, title, children, actions, ...rest} = props;
  const classNames = classnames('tip tip-vert', className);

  return (
    <div className={classNames} style={{backgroundColor: bgColor}} {...rest}>
      <div className="main-wrapper">
        <div className="title-wrapper">{title}</div>
        <div className="content-wrapper">{children}</div>
      </div>
      <div className="actions-wrapper">{actions}</div>
    </div>
  );
}
