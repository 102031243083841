import Store from 'store/configure';

export const getRequests = (store = Store().getState()) => {
  return store.data.requests;
};
export const getSubscriptionsPlans = (store = Store().getState()) => {
  return store.data.subscriptionPlans;
};
export const getSubscriptionsAddons = (store = Store().getState()) => {
  return store.data.subscriptionAddons;
};
export const getSegments = (store = Store().getState()) => {
  return store.data.segments;
};
export const getCustomAttributes = (store = Store().getState()) => {
  return store.data.customAttributes;
};
export const getSegmentById = (store = Store().getState(), segmentId) => {
  return store.data.segments.find((s) => s.uid === segmentId);
};
export const getCustomRoles = (store = Store().getState()) => {
  return store.data.customRoles;
};
export const getBuiltInRoles = (store = Store().getState()) => {
  return store.data.builtInRoles;
};
