import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Alert from 'components/Alert';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import {useContext} from 'react';
import {SEGMENT_ICONS} from 'services/segment';
import {SegmentContext} from '../..';
import './_Styles.scss';

const SegmentDetails = () => {
  const {name, setName, description, setDescription, icon, setIcon, segment} =
    useContext(SegmentContext);

  return (
    <>
      {segment.loading === true && (
        <Alert
          className="segment-loading"
          icon="isax isax-refresh"
          title="We are populating your segment"
          info>
          Depending on the number of jimers found, the population process can
          take a few seconds to finish. <br />
          <strong>Refresh the page</strong> to make sure it's done.
        </Alert>
      )}
      <div className="segment-details">
        <div className="segment-details-title">Segment details</div>
        <div className="input-section">
          <div className="input-label">Name</div>
          <Input
            labelTextLeft={
              <Dropdown
                className="emoji-picker-dropdown"
                position="bottom left"
                offsetY={8}
                trigger={
                  <div className="icon-wrapper" style={{background: '#FFFFFF'}}>
                    {SEGMENT_ICONS.map((i) => i.value).includes(icon)
                      ? null
                      : icon}
                  </div>
                }>
                <Picker
                  data={data}
                  onEmojiSelect={(emoji) => setIcon(emoji.native)}
                />
              </Dropdown>
            }
            className="input-name"
            value={name}
            onChange={({target}) => setName(target.value)}
            placeholder="Segment name"
          />
        </div>
        <div className="input-section">
          <div className="input-label">Description</div>
          <TextArea
            className="input-description"
            value={description}
            onChange={({target}) => setDescription(target.value)}
            placeholder="Description"
          />
        </div>
      </div>
    </>
  );
};

export default SegmentDetails;
