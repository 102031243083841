import React from 'react';
import './_Styles.scss';
import classNames from 'classnames';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';

const Type = ({className, icon, title, onClick}) => {
  return (
    <div className={classNames('type-wrapper', className)} onClick={onClick}>
      <div className="icon-wrapper">{icon}</div>
      <div className="title">{title}</div>
    </div>
  );
};

const TypePicker = ({setType}) => {
  return (
    <div className="type-picker">
      <Type
        className="tours"
        icon={<i className="icon-tour" />}
        title="Tours & Modals"
        onClick={() => setType(EVOLUTION_TYPE_TOUR)}
      />
      <Type
        className="surveys"
        icon={<i className="icon-user-satisfaction" />}
        title="Surveys"
        onClick={() => setType(EVOLUTION_TYPE_SURVEY)}
      />
      <Type
        className="banners"
        icon={<i className="icon-slot-top-bar" />}
        title="Banners"
        onClick={() => setType(EVOLUTION_TYPE_BANNER)}
      />
      <Type
        className="hints"
        icon={<i className="icon-slot-dot" />}
        title="Hints"
        onClick={() => setType(EVOLUTION_TYPE_HINT)}
      />
    </div>
  );
};

export default TypePicker;
