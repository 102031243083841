import Axios from 'axios';
import {generalSelector} from 'selectors';

export const DATA_IMPORT_STATUS_DRAFT = 'DRAFT';
export const DATA_IMPORT_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const DATA_IMPORT_STATUS_DONE = 'DONE';
export const DATA_IMPORT_STATUS_ERROR = 'ERROR';

export const DATA_IMPORT_TYPE_USER_CSV = 'USER_CSV';

const EP_DATA_IMPORT_USERS_CSV_CREATE = `/data-import/users-csv`;
const EP_DATA_IMPORT_USERS_CSV_GET_ALL = `/data-import/users-csv`;
const EP_DATA_IMPORT_USERS_CSV_GET = (importId) =>
  `/data-import/users-csv/${importId}`;
const EP_DATA_IMPORT_USERS_CSV_PUT = (importId) =>
  `/data-import/users-csv/${importId}`;
const EP_DATA_IMPORT_USERS_CSV_DELETE = (importId) =>
  `/data-import/users-csv/${importId}`;
const EP_DATA_IMPORT_USERS_CSV_LAUNCH = (importId) =>
  `/data-import/users-csv/${importId}/launch`;

export const createImportUsersCsv = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {name, description, fileId} = data;

  return Axios.post(EP_DATA_IMPORT_USERS_CSV_CREATE, {
    name,
    description,
    type: DATA_IMPORT_TYPE_USER_CSV,
    fileId,
    projectId,
  }).then((response) => response.data);
};

export const updateImportUsersCsv = (importId, data) => {
  const {
    name,
    description,
    enableAddUser,
    enableUpdateUser,
    jimoIdentifier,
    csvIdentifier,
    properties,
    createSegment,
  } = data;

  return Axios.put(EP_DATA_IMPORT_USERS_CSV_PUT(importId), {
    name,
    description,
    enableAddUser,
    enableUpdateUser,
    jimoIdentifier,
    csvIdentifier,
    properties,
    createSegment,
  }).then((response) => response.data);
};

export const deleteImportUsersCsv = (importId) => {
  return Axios.delete(EP_DATA_IMPORT_USERS_CSV_DELETE(importId)).then(
    (response) => response.data
  );
};

export const fetchImportCsvUsers = (importId) => {
  return Axios.get(EP_DATA_IMPORT_USERS_CSV_GET(importId)).then(
    (response) => response.data
  );
};
export const fetchAllImportCsvUsers = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_DATA_IMPORT_USERS_CSV_GET_ALL, {
    params: {projectId},
  }).then((response) => response.data);
};

export const launchImportCsvUsers = (importId) => {
  return Axios.post(EP_DATA_IMPORT_USERS_CSV_LAUNCH(importId)).then(
    (response) => response.data
  );
};
