import classnames from 'classnames';
import './_styles.scss';

export const SettingSubNav = ({tabs = [], onChangeTab = () => {}}) => {
  return (
    <div className="setting-sub-nav">
      <ol class="tab-list">
        {tabs.map((t) => (
          <li
            key={t.tabId}
            class={classnames('tab-list-item', {
              'tab-list-active': t.active === true,
              'has-count': t.count != null,
            })}
            onClick={() => onChangeTab(t.tabId)}>
            {t.content != null ? (
              <>
                <div class="label-wrapper">{t.content}</div>
                <div className="item-count">
                  {t.count != null && <div className="count">{t.count}</div>}
                </div>
              </>
            ) : (
              <div class="label-wrapper">{t}</div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
