import classnames from 'classnames';
import React, {useState} from 'react';
import './_Styles.scss';
import BlockEditor from './components/ChangelogBlockEditor';
import ChangelogBuilderSidebar, {
  MODE_NAVIGATOR,
} from './components/ChangelogBuilderSidebar';
import ChangelogPreview from './components/ChangelogPreview';

export const ChangelogBuilderContext = React.createContext();

const ChangelogBuilder = () => {
  const [mode, setMode] = useState(MODE_NAVIGATOR);
  const [selectedBlockType, setSelectedBlockType] = useState(null);

  return (
    <ChangelogBuilderContext.Provider
      value={{
        selectedBlockType,
        setSelectedBlockType,
        mode,
        setMode,
      }}>
      <div className={classnames('changelog-builder', 'grid-a-b-a')}>
        <ChangelogBuilderSidebar />
        <div className="changelog-preview-wrapper">
          <ChangelogPreview />
        </div>
        <BlockEditor />
      </div>
    </ChangelogBuilderContext.Provider>
  );
};

export default ChangelogBuilder;
