import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoints
const EP_COMMENT_GET = '/comment';
const EP_COMMENT_CREATE = '/comment';
const EP_COMMENT_UPDATE = (commentId) => `/comment/${commentId}`;
const EP_COMMENT_DELETE = (commentId) => `/comment/${commentId}`;
const EP_COMMENT_LIKE_CREATE = (commentId) => `/comment/${commentId}/like`;
const EP_COMMENT_LIKE_DELETE = (commentId, commentLikeId) =>
  `/comment/${commentId}/like/${commentLikeId}`;

const getComments = (filter) => {
  return Axios.get(EP_COMMENT_GET, {params: filter}).then(
    (response) => response.data
  );
};

export const getCommentsForProject = (
  filter,
  projectId = generalSelector.getProject().uid
) => {
  return getComments({...filter, projectId});
};

export const createComment = (data) => {
  const {message, evolutionId, prototypeId, replyToId} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(
    EP_COMMENT_CREATE,
    {message, evolutionId, prototypeId, replyToId},
    {params: {projectId}}
  ).then((response) => response.data);
};

export const updateComment = (commentId, data) => {
  const {message} = data;

  return Axios.put(EP_COMMENT_UPDATE(commentId), {message}).then(
    (response) => response.data
  );
};

export const deleteComment = (commentId) => {
  return Axios.delete(EP_COMMENT_DELETE(commentId)).then(
    (response) => response.data
  );
};

export const createCommentLike = (commentId) => {
  return Axios.post(EP_COMMENT_LIKE_CREATE(commentId)).then(
    (response) => response.data
  );
};

export const deleteCommentLike = (commentId, commentLikeId) => {
  return Axios.delete(EP_COMMENT_LIKE_DELETE(commentId, commentLikeId));
};
