import classnames from 'classnames';
import Lottie from 'react-lottie';
import './_Styles.scss';
import data from './logo.json';

export default function DefaultLoader(props) {
  const {width = '40px', dark, className} = props;
  const style = {
    width,
    height: width,
  };
  const classNames = classnames('loader', className, {dark: dark === true});

  return <div className={classNames} style={style}></div>;
}

export function JimoLoader(props) {
  const {size = 'small', lottieOptions = {}, eventListeners = []} = props;
  const size2widthHeight = {
    tiny: [15, 15],
    small: [40, 40],
    medium: [100, 100],
    big: [120, 120],
    large: [180, 180],
  };
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...lottieOptions,
    animationData: data,
  };
  const classNames = classnames('jimo-loader-wrapper', props.className, {
    'is-tiny': size === 'tiny',
    'is-small': size === 'small',
    'is-medium': size === 'medium',
    'is-large': size === 'large',
  });
  const [width, height] = size2widthHeight[size];

  return (
    <div className={classNames}>
      <Lottie
        options={defaultLottieOptions}
        width={width}
        height={height}
        eventListeners={eventListeners}></Lottie>
    </div>
  );
}
