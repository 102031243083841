import {PlateElement, withRef} from '@udecode/plate-common';
import classNames from 'classnames';
import {MarkdownEditorContext} from 'components/MarkdownEditor/utils';
import {useContext, useEffect, useRef} from 'react';
import {HubspotLogo} from 'scenes/Settings/scenes/Integrations/Hubspot';
import {SegmentLogo} from 'scenes/Settings/scenes/Integrations/Segment';
import {
  CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT,
  CUSTOM_ATTRIBUTE_SOURCE_SEGMENT,
} from 'services/segment';
import {ReactEditor, useFocused, useSelected} from 'slate-react';
import './_Styles.scss';

const VariableElement = withRef(
  ({className = '', children, prefix, renderLabel, ...props}, ref) => {
    const {currentVariable, setCurrentVariable, newVariable, setNewVariable} =
      useContext(MarkdownEditorContext);

    const {element, editor} = props;

    const elementId = element.uid;

    const plateRef = useRef();

    useEffect(() => {
      if (newVariable && newVariable.uid === elementId && plateRef.current) {
        plateRef.current.click();
      }
    }, [newVariable, setCurrentVariable, setNewVariable, elementId, plateRef]);

    const selected = useSelected();
    const focused = useFocused();

    const handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const path = ReactEditor.findPath(editor, element);
      setNewVariable(null);
      setCurrentVariable({...element, path});
    };

    return (
      <PlateElement
        className={classNames('variable-element', className, {
          selected:
            currentVariable?.uid === element.uid || (selected && focused),
        })}
        contentEditable={false}
        onClick={handleClick}
        {...props}
        ref={plateRef}
        tabIndex={0}>
        <div className="variable-element-content">
          {element.attributeSource === CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT ? (
            <HubspotLogo />
          ) : element.attributeSource === CUSTOM_ATTRIBUTE_SOURCE_SEGMENT ? (
            <SegmentLogo />
          ) : (
            <i className="isax isax-profile-tick5" />
          )}
          {element.attribute || ''}
        </div>
        {children}
      </PlateElement>
    );
  }
);

export default VariableElement;
