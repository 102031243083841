import classnames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {ModalConfirm} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {PermissionsPeople} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {hasUsernameGenerated} from 'helpers/utils';
import {useContext, useState} from 'react';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ModalJimerProfile from 'scenes/Users/components/ModalJimerProfile';
import UserTagSelector from 'scenes/Users/components/UserTagSelector';
import {CircularProgressbar} from 'react-circular-progressbar';
import {TrackerContext} from 'scenes/Tracker';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';

const logger = new Swaler('ListUsers');

export const ListUsers = ({
  jimers,
  isLoading,
  children,
  onDelete = () => {},
}) => {
  const {tracker} = useContext(TrackerContext);
  const {steps} = tracker;

  const isActivationTracker = tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER;
  const isFeatureUsageTracker = tracker.type === TRACKER_TYPE_FEATURE_USAGE;

  const [jimerId, setJimerId] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const classNames = classnames('tracker-users-list-users', {
    'is-loading': isLoading,
  });

  const handleDeleteJimer = async (jimerId) => {
    setDeleting(true);
    try {
      await jimerService.deleteJimer(jimerId);
      setDeleting(false);
      setShowDeleteConfirm(null);
      onDelete(jimerId);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setDeleting(false);
      logger.error('Deleting jimer failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };

  jimers.sort((a, b) => {
    return new Date(b.latestEventCreatedAt) - new Date(a.latestEventCreatedAt);
  });

  return (
    <>
      <div className={classNames}>
        <div className="top">
          <div></div>
          <div>Name</div>
          <div>Email / Id</div>
          <div>Last activity</div>
          <div>Tags</div>
        </div>
        {isLoading === true && (
          <div className="loader-wrapper">
            <Loader width={24} />
          </div>
        )}
        {isLoading === false && (
          <div className="list">
            {jimers.map((jimer) => {
              const username =
                jimer.externalUsername != null &&
                jimer.externalUsername.length !== 0
                  ? jimer.externalUsername
                  : jimer.username;
              const email =
                jimer.personalEmail != null
                  ? jimer.personalEmail
                  : jimer.externalEmail;
              const isAnonymous = hasUsernameGenerated(username);
              const emailAndId = [email, jimer.externalUid]
                .filter((v) => v != null && v.length !== 0)
                .map((v) => <div>{v}</div>);
              let lastRecordedEvent = null;
              let completedAllSteps = false;

              if (isActivationTracker === true) {
                lastRecordedEvent = jimer.trackerEvents.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })[0];

                completedAllSteps =
                  jimer.trackerEvents?.length === steps.length;
              }

              return (
                <div
                  key={jimer.uid}
                  className={classnames('card-user', {
                    completed: completedAllSteps,
                  })}
                  onClick={() => {
                    setJimerId(jimer.uid);
                    setShowProfile(true);
                  }}>
                  <div className="user-avatar-wrapper">
                    <Avatar jimer={jimer} />
                  </div>
                  <div className="username-wrapper">
                    <div className="username">
                      {isAnonymous ? (
                        'Anonymous'
                      ) : !!username ? (
                        username
                      ) : (
                        <em>username</em>
                      )}
                    </div>
                    {isAnonymous === true && (
                      <div className="anonymous-name">{username}</div>
                    )}
                  </div>
                  <div className="user-email">
                    <span>
                      {emailAndId?.length > 0 ? emailAndId : 'no email'}
                    </span>
                  </div>

                  <div className={classnames('user-last-activity')}>
                    {isActivationTracker === true && (
                      <>
                        <div className="circular-progress-wrapper">
                          <CircularProgressbar
                            value={
                              jimer?.trackerEvents?.length ??
                              0 / steps.length ??
                              1
                            }
                            text={<i className="icon-eye"></i>}
                            maxValue={steps.length}
                            strokeWidth={10}
                            styles={{
                              path: {
                                stroke: completedAllSteps
                                  ? '#167322'
                                  : '#1260EB',
                                transition: 'stroke-dashoffset 0.5s ease 0s',
                              },
                              trail: {
                                stroke: 'rgba(33, 93, 137, 0.06)',
                              },
                            }}
                          />
                        </div>
                        <div className="content">
                          <div className="step-name">
                            {completedAllSteps === true
                              ? 'Completed all steps 🎉'
                              : jimer?.trackerEvents?.[0].trackerStep?.name ??
                                'Unknown step'}
                          </div>
                          <div className="event-date">
                            {dayjs(jimer.latestEventCreatedAt).fromNow()}
                          </div>
                        </div>
                      </>
                    )}
                    {isFeatureUsageTracker === true && (
                      <>
                        <div className="icon-wrapper">
                          <i className="isax isax-mouse-circle" />
                        </div>
                        <div className="content">
                          <div className="step-name">
                            Used {jimer.eventCount} time
                            {jimer.eventCount > 1 ? 's' : ''}
                          </div>
                          <div className="event-date">
                            {dayjs(jimer.latestEventCreatedAt).fromNow()}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="user-tags">
                    <UserTagSelector
                      jimer={jimer}
                      maxTags={2}
                      readOnly={
                        hasPermissions(PermissionsPeople.USER_EDIT) === false
                      }
                    />
                  </div>
                  {hasPermissions(PermissionsPeople.USER_DELETE) && (
                    <div className="user-actions">
                      <Button
                        iconOnly
                        thin
                        rounded={false}
                        danger
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowDeleteConfirm(jimer.uid);
                        }}
                        disabled={showDeleteConfirm != null}>
                        <i className="icon-trash"></i>{' '}
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
            {children}
          </div>
        )}
      </div>
      <ModalConfirm
        className="modal-confirm-delete-jimer"
        isOpen={showDeleteConfirm != null}
        onConfirm={() => handleDeleteJimer(showDeleteConfirm)}
        onCancel={() => setShowDeleteConfirm(null)}>
        <div className="content">
          All data related to this jimer including activity and surveys will be
          forever deleted.
        </div>
      </ModalConfirm>
      <ModalJimerProfile
        isOpen={showProfile}
        onRequestClose={() => setShowProfile(false)}
        jimerId={jimerId}
      />
    </>
  );
};
