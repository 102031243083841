import classnames from 'classnames';
import React from 'react';
import {node, string, bool} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  children: node,
  className: string,
  width: string,
  dark: bool,
  vertical: bool,
};

const defaultProps = {
  children: null,
  className: '',
  width: null,
  dark: true,
  vertical: false,
};

const Divider = ({children = null, className, width, dark, vertical}) => {
  const classNames = classnames('divider', className, {
    'has-content': children != null,
    'is-dark': dark === true,
    'is-vertical': vertical === true,
  });

  return (
    <div className={classNames} style={{width}}>
      {children != null && [
        <div key={1} className="left-divider"></div>,
        <div key={2} className="content">
          {children}
        </div>,
        <div key={3} className="right-divider"></div>,
      ]}
    </div>
  );
};

Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
