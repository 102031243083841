import Input from 'components/Input';
import Select from 'components/Select';
import React, {forwardRef} from 'react';
import Datepicker from 'react-datepicker';
import SelectAttribute from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [
  OPTIONS.OP_IS,
  OPTIONS.OP_NIS,
  OPTIONS.OP_IS_GREATER,
  OPTIONS.OP_IS_GREATER_OR_EQUAL,
  OPTIONS.OP_IS_LESSER,
  OPTIONS.OP_IS_LESSER_OR_EQUAL,
  OPTIONS.OP_IS_BETWEEN,
  OPTIONS.OP_IS_NBETWEEN,
];

const string2date = (str) => {
  if (typeof str === 'string') {
    return new Date(str);
  }
  return str;
};

export const SEGMENTIO_EVENTS_PROPERTIES = {
  NUM_EVENTS: {value: 'JIMO_BASIC_COUNT', label: 'Number of events'},
  DATE_LAST_EVENT: {
    value: 'JIMO_BASIC_LAST_EVENT_DATE',
    label: 'Date of last event',
  },
};

const isDate = (v) => new Date(v) instanceof Date && !isNaN(new Date(v));

export const AttributeSegmentioEvent = ({
  attribute,
  onDelete,
  onChangeType,
  onChangeProperty,
  onUpdate,
  onUpdateOperator,
  onUpdateValue,
  onAddValue,
  onDeleteValue,
  segmentioFields,
  segmentioEvents,
  trackedEvents,
}) => {
  const DatepickerCustomInput = forwardRef(({value, onClick, index}, ref) => (
    <Input ref={ref} value={value} onClick={onClick} />
  ));
  const pickerProps = [
    OPTIONS.OP_IS_BETWEEN.value,
    OPTIONS.OP_IS_NBETWEEN.value,
  ].includes(attribute.op)
    ? {
        selectsRange: true,
        startDate: attribute.values[0]
          ? string2date(attribute.values[0])
          : null,
        endDate: attribute.values[1] ? string2date(attribute.values[1]) : null,
      }
    : {
        selected: attribute.values[0] ? string2date(attribute.values[0]) : null,
      };

  const handlePropertyChange = (property) => {
    onChangeProperty(property.value);
    if (property.value === 'JIMO_BASIC_LAST_EVENT_DATE') {
      onUpdateValue(0, new Date());
    } else if (isDate(attribute.values[0])) {
      onUpdateValue(0, '');
    }
  };

  const customTypeValue =
    attribute.customType != null
      ? attribute.customType
      : attribute.customAttribute != null
      ? attribute.customAttribute.uid
      : null;

  const event = segmentioEvents.find((e) => e.event === attribute.name);

  const propertiesOptions = [
    {label: 'Basic', options: Object.values(SEGMENTIO_EVENTS_PROPERTIES)},
    {
      label: 'Properties',
      options: event?.properties?.map((p) => ({label: p, value: p})) || [],
    },
  ];

  const selectedProperty = propertiesOptions
    .map((o) => o.options)
    .flat()
    .find((p) => p.value === attribute.property);

  return (
    <div className="segment-attribute attribute-custom">
      <div className="header-row">
        <SelectAttribute
          value={`${attribute.type}-${attribute.name}`}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>
      <div className="value-row">
        <Select
          options={propertiesOptions}
          placeholder="Select a property"
          onChange={handlePropertyChange}
          className="select-add-attribute"
          value={selectedProperty}
          isDisabled
        />
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => onUpdateOperator(op)}
        />
        {attribute.property === 'JIMO_BASIC_LAST_EVENT_DATE' ? (
          <Datepicker
            {...pickerProps}
            onChange={(date) => {
              if (Array.isArray(date)) {
                onUpdateValue(0, date[0]);
                onUpdateValue(1, date[1]);
              } else {
                onUpdateValue(0, date);
              }
            }}
            customInput={<DatepickerCustomInput index={0} />}
            disabled
          />
        ) : (
          <>
            {attribute.values.map((v, index) => (
              <Input
                key={index}
                placeholder="enter value..."
                value={v}
                labelTextRight={
                  attribute.values.length > 1 ? (
                    <i
                      className="icon-close"
                      onClick={() => onDeleteValue(index)}></i>
                  ) : null
                }
                onChange={({target}) => onUpdateValue(index, target.value)}
                disabled
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
