import Button from 'components/Button';
import Label from 'components/Label';
import {toastWarning} from 'components/Toaster';
import {useCheckInstallation} from 'hooks/useCheckInstallation';
import {useState} from 'react';
import {InstallationTabs} from 'scenes/Settings/components/Tabs';
import ModalSendInstructions from '../../scenes/Install/modal-send-instructions';

export const SettingsInstallationHeader = () => {
  const {isInstalled, check, isChecking} = useCheckInstallation({
    onNotInstalled: () => toastWarning('Your project is not installed yet'),
  });

  const [showModalSendInstruction, setShowModalSendInstruction] =
    useState(false);

  return (
    <>
      <div className="settings-header">
        <div className="title-3">Installation</div>
        <div className="header-actions">
          {isInstalled === true && (
            <Label
              size="regular"
              type="positive"
              secondary
              iconLeft="isax isax-tick-circle5"
              className="label-hidden">
              Your project is installed
            </Label>
          )}
          <Button
            thin
            iconLeft="isax isax-sms"
            onClick={() => setShowModalSendInstruction(true)}>
            Share to teammate
          </Button>
          {isInstalled === false && (
            <Button
              thin
              iconLeft="isax isax-tick-circle"
              primary
              loading={isChecking}
              onClick={check}>
              Check installation
            </Button>
          )}
        </div>
      </div>
      <InstallationTabs />
      <ModalSendInstructions
        title="Share installation instruction to developer"
        isOpen={showModalSendInstruction}
        onRequestClose={() => setShowModalSendInstruction(false)}
      />
    </>
  );
};
