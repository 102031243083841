import React from 'react';
import InputGroup from 'components/Input';
import './_Styles.scss';

const SettingsBookInterview = ({action, setAction}) => {
  return (
    <div className="settings-book-interview">
      <div className="settings-open-post-title body-3">Calendar URL:</div>
      <InputGroup
        value={action.value}
        onChange={({target}) => {
          setAction({...action, value: target.value});
        }}
      />
    </div>
  );
};

export default SettingsBookInterview;
