import {func, string} from 'prop-types';
import React from 'react';
import RadioGroup from '../../../../../../../components/RadioGroup';
import ColorPickerInput from '../../items/ColorPickerInput';

export const BACKGROUND_TYPE_COLOR = 'color';
export const BACKGROUND_TYPE_GRADIENT = 'gradient';

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
};

const backgroundColorOptions = [
  {label: 'Gradient', value: BACKGROUND_TYPE_GRADIENT},
  {label: 'Color', value: BACKGROUND_TYPE_COLOR},
];

const backgroundAnimationOptions = [
  {label: 'Animated', value: true},
  {label: 'Classic', value: false},
];

const Background = ({value, onChange, setCloseOnDocumentClick}) => {
  const {type, animated, primaryColor, secondaryColor} = value;

  return (
    <>
      <div className="section-item">
        <RadioGroup
          value={type}
          options={backgroundColorOptions}
          onSelect={(v) => onChange({...value, type: v})}
        />
      </div>
      {type === 'gradient' && (
        <div className="section-item">
          <RadioGroup
            value={animated}
            options={backgroundAnimationOptions}
            onSelect={(v) => onChange({...value, animated: v})}
          />
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title">
          {type === 'gradient' ? 'Color 1' : 'Color'}
        </div>
        <div className="section-item-content">
          <ColorPickerInput
            title={type === 'gradient' ? 'Color 1' : 'Color'}
            value={primaryColor}
            onChange={(v) => {
              onChange({...value, primaryColor: v});
            }}
            setParentCloseOnDocumentClick={setCloseOnDocumentClick}
          />
        </div>
      </div>
      {type === 'gradient' && (
        <div className="section-item">
          <div className="section-item-title">Color 2</div>
          <div className="section-item-content">
            <ColorPickerInput
              title="Color 2"
              value={secondaryColor}
              onChange={(v) => onChange({...value, secondaryColor: v})}
              setParentCloseOnDocumentClick={setCloseOnDocumentClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
