import classNames from 'classnames';
import {GlobalContext} from 'contextes/Global';
import {hasFlag} from 'helpers/bitwise';
import {bool, number, object, string} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {HINT_TYPE_BUTTON} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hint';
import {positionsArr} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Position';
import {Dot, Hint} from 'scenes/PokeBuilder/components/PokeRender';
import {BlockGradient} from 'scenes/PokeBuilder/components/PokeRender/components/BlockGradient';
import {getPokeWrapperStyle} from 'scenes/PokeBuilder/components/PokeRender/utils';
import {stylePreviewPoke} from 'scenes/Settings/scenes/Themes/utils';
import {generalSelector} from 'selectors';
import {
  EVOLUTION_TYPE_CHECKLIST,
  F_BOOST_SLOT_HINT,
  F_OPTION_POKE_CARD_WITH_POINTER,
} from 'services/evolution';
import {BLOCK_TYPE_HINT, STEP_TYPE_CHECKLIST} from 'services/steps';
import Checklist from 'shared/front/components/Checklist';
import {Poke} from 'shared/front/components/Poke';
import {BlockArrow} from 'shared/front/components/Poke/components/BlockArrow';
import {
  F_SLOT_DOT,
  F_SLOT_TOP_BAR,
} from 'shared/front/components/Poke/constants/poke';
import {PokeContext} from 'shared/front/components/Poke/context';
import './_Styles.scss';

const propTypes = {
  poke: object,
  isScaledDownPreview: bool,
  selectedStepId: string,
  useDefaultTheme: bool,
  scale: number,
  isTemplatePreview: bool,
};

const defaultProps = {
  poke: null,
  isScaledDownPreview: false,
  selectedStepId: null,
  useDefaultTheme: false,
  scale: null,
  isTemplatePreview: false,
};

const LivePreviewNew = ({
  poke,
  isScaledDownPreview,
  selectedStepId,
  selectedConceptStepId,
  useDefaultTheme,
  scale: scaleProp,
  isTemplatePreview,
  evolution,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {addFontFamily} = useContext(GlobalContext);

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });
  const [style, setStyle] = useState({});
  const [parentHeight, setParentHeight] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const [previewedPoke, setPreviewedPoke] = useState(null);

  const parentHeightRef = useRef();
  const parentWidthRef = useRef();
  const [hintDimension, setHintDimension] = useState({width: 0, height: 0});

  useEffect(() => {
    let foundPoke = null;
    if (selectedStepId != null) {
      foundPoke = poke.steps?.map((s) => s.uid).includes(selectedStepId)
        ? poke
        : poke?.tourSteps?.find((tourStep) =>
            tourStep.steps?.map((s) => s.uid)?.includes(selectedStepId)
          );
    } else {
      foundPoke = poke;
    }
    if (foundPoke) {
      const [_, stepsBefore, stepsAfter] = (
        foundPoke?.tourStepInfo ?? '0;0;0'
      ).split(';');

      if (useDefaultTheme === true) {
        const styledPreviewPoke = stylePreviewPoke(
          project.defaultTheme,
          foundPoke
        );

        setPreviewedPoke({
          ...styledPreviewPoke,
          stepsBefore: parseInt(stepsBefore, 10),
          stepsAfter: parseInt(stepsAfter, 10),
        });
      } else {
        setPreviewedPoke({
          ...foundPoke,
          stepsBefore: parseInt(stepsBefore, 10),
          stepsAfter: parseInt(stepsAfter, 10),
        });
      }
    }
  }, [poke, selectedStepId]);

  useEffect(() => {
    parentHeightRef.current = parentHeight;
    parentWidthRef.current = parentWidth;
  }, [parentHeight, parentWidth]);

  let stepsBefore = null;
  let stepsAfter = null;

  if (evolution != null) {
    const [indexTourStep] = poke?.tourStepInfo?.split(';') ?? [0];

    const stepsBeforeAndAfter = evolution?.tourSteps?.reduce(
      (acc, cur) => {
        if (cur.uid === poke.uid) {
          return acc;
        }

        const [index] = cur.tourStepInfo?.split(';') ?? [0];
        const indexToInt = parseInt(index, 10);

        if (indexToInt < indexTourStep) {
          acc.stepsBefore =
            acc.stepsBefore +
            cur.steps.filter((s) => s.removed !== true).length;
        } else if (indexToInt > indexTourStep) {
          acc.stepsAfter =
            acc.stepsAfter + cur.steps.filter((s) => s.removed !== true).length;
        }
        return acc;
      },
      {stepsBefore: 0, stepsAfter: 0}
    );
    stepsBefore = stepsBeforeAndAfter?.stepsBefore ?? 0;
    stepsAfter = stepsBeforeAndAfter?.stepsAfter ?? 0;
  }

  if (previewedPoke == null) {
    return <></>;
  }

  previewedPoke.steps.sort((a, b) => a.indexOrder - b.indexOrder);

  const currentStepIndex = previewedPoke.steps.findIndex(
    (s) => s.uid === selectedStepId
  );
  const currentConceptStepIndex =
    selectedConceptStepId != null
      ? previewedPoke.steps[currentStepIndex].prototypes[0].steps.findIndex(
          (s) => s.uid === selectedConceptStepId
        )
      : 0;
  const step = previewedPoke.steps[currentStepIndex];

  const isBanner = hasFlag(F_SLOT_TOP_BAR, previewedPoke.boostFlags);
  const isHotspot = hasFlag(F_SLOT_DOT, previewedPoke.boostFlags);
  const isHint = hasFlag(F_BOOST_SLOT_HINT, poke.boostFlags);
  const isChecklist = poke?.type === EVOLUTION_TYPE_CHECKLIST;
  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const hintBlock = step?.blocks?.find((b) => b.type === BLOCK_TYPE_HINT);
  const isHintButton = hintBlock?.style?.type === HINT_TYPE_BUTTON;

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    poke.optionsFlags
  );

  let scale = 1;

  if (isScaledDownPreview === true) {
    if (scaleProp != null) {
      if (isBanner === true) {
        scale = 1;
      } else {
        if (isHint === true) {
          scaleProp = 0.6;
        }
        scale = Math.min(
          (parentHeight / dimension.height) * scaleProp,
          dimension.width !== -1
            ? (parentWidth / dimension.width) * scaleProp
            : Number.MAX_VALUE
        );
      }
    } else if (isBanner === true) {
      scale = 0.4;
      dimension.width = parentWidth / scale;
    } else {
      scale = Math.min(
        (parentHeight / dimension.height) * 0.7,
        dimension.width !== -1
          ? (parentWidth / dimension.width) * 0.45
          : Number.MAX_VALUE
      );
    }
  }

  const positionItem = positionsArr.find(
    (p) => p.value === poke.boostedPositionFlags
  );
  const position = positionItem?.position;

  const pokeWrapperStyle = isChecklist
    ? {}
    : getPokeWrapperStyle(previewedPoke, isChecklistStep);
  const borderRadius = poke?.style?.borderRadius;

  let forcedOffsetX = null,
    forcedOffsetY = null,
    pointerPosition = 'top';
  const pointerOffset = 16.970562748477143 / 2;
  if (position != null) {
    const [primaryPosition, secondaryPosition] = position.split('-');
    if (secondaryPosition === 'left') {
      forcedOffsetX =
        -dimension.width / 2 +
        hintDimension.width / 2 +
        borderRadius +
        pointerOffset;
    } else if (secondaryPosition === 'right') {
      forcedOffsetX =
        dimension.width / 2 -
        hintDimension.width / 2 -
        borderRadius -
        pointerOffset;
    } else if (secondaryPosition === 'top') {
      forcedOffsetY =
        dimension.height / 2 -
        hintDimension.height / 2 -
        borderRadius -
        pointerOffset;
    } else if (secondaryPosition === 'bottom') {
      forcedOffsetY =
        -dimension.height / 2 +
        hintDimension.height / 2 +
        borderRadius +
        pointerOffset;
    }

    if (primaryPosition === 'right') {
      pointerPosition = 'left';
    } else if (primaryPosition === 'bottom') {
      pointerPosition = 'top';
    } else if (primaryPosition === 'left') {
      pointerPosition = 'right';
    } else if (primaryPosition === 'top') {
      pointerPosition = 'bottom';
    }
  }

  return (
    <PokeContext.Provider
      value={{
        poke: {
          ...previewedPoke,
          ...(stepsBefore != null ? {stepsBefore} : {}),
          ...(stepsAfter != null ? {stepsAfter} : {}),
        },
        currentStepIndex,
        currentConceptStepIndex,
      }}>
      <div
        className="poke-render-preview-wrapper"
        ref={(r) => {
          setParentHeight(r?.clientHeight);
          setParentWidth(r?.clientWidth);
        }}>
        <div
          className={classNames('poke-render-preview', {
            'scaled-down-preview': isScaledDownPreview,
            'is-checklist': isChecklist,
            'is-banner': isBanner,
          })}
          style={{
            ...style,
            width: 'auto',
            ...(isScaledDownPreview
              ? {
                  transform: `translate(-50%, -50%) scale(${Math.min(
                    scale,
                    1
                  )})`,
                  margin: 0,
                  ...(isBanner === true
                    ? {
                        width: (100 / scale) * 0.9 + '%',
                      }
                    : {}),
                }
              : {}),
            ...(isBanner === true &&
            (isScaledDownPreview !== true || isTemplatePreview === true)
              ? {
                  transform: 'none',
                  width: '100%',
                  top: 0,
                  left: 0,
                }
              : {}),
            ...(selectedConceptStepId != null
              ? {
                  transform: ``,
                  width: '100%',
                  height: '100%',
                }
              : {}),
          }}>
          {isHintButton !== true && (
            <div className="poke-render-wrapper">
              <div
                className="poke-render-preview-content"
                style={{
                  position: 'relative',
                  ...pokeWrapperStyle,
                }}>
                {isChecklist === true ? (
                  <Checklist
                    inBuilder
                    onDimensionChange={(data) => {
                      setDimension({
                        width: data[0],
                        height: data[1],
                      });
                    }}
                    addFontFamily={addFontFamily}
                  />
                ) : (
                  <>
                    <Poke
                      inConcept={selectedConceptStepId != null}
                      disableAnimations
                      onDimensionChange={(data) => {
                        setDimension({
                          width: data[0],
                          height: data[1],
                        });
                      }}
                      onContainerStyleChange={(style) => {
                        setStyle(style);
                      }}
                      addFontFamily={addFontFamily}
                    />
                    <BlockGradient poke={previewedPoke} />
                  </>
                )}
              </div>
              {hasPointer === true && (
                <BlockArrow
                  poke={previewedPoke}
                  direction={pointerPosition}
                  pokeSize={{height: dimension.height, width: dimension.width}}
                  borderRadius={poke.style?.borderRadius}
                  forcedOffsetX={forcedOffsetX}
                  forcedOffsetY={forcedOffsetY}
                />
              )}
            </div>
          )}
          {isHint === true && (
            <Hint
              poke={poke}
              dimension={dimension}
              hintDimension={hintDimension}
              setHintDimension={setHintDimension}
            />
          )}
        </div>

        {isHotspot === true && isScaledDownPreview !== true && (
          <Dot poke={poke} dimension={dimension} />
        )}
        {isTemplatePreview === true && (
          <div
            className="poke-render-preview-overlay"
            style={{
              backgroundColor: poke?.style?.overlay,
            }}
          />
        )}
      </div>
    </PokeContext.Provider>
  );
};

LivePreviewNew.propTypes = propTypes;
LivePreviewNew.defaultProps = defaultProps;

export default LivePreviewNew;
