import classnames from 'classnames';
import {bool, func, string} from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import './_Styles.scss';

const propTypes = {
  value: string.isRequired,
  onChange: func,
  showPercentage: bool,
  defaultHandle: bool,
};

const defaultProps = {
  onChange: null,
  showPercentage: false,
  defaultHandle: false,
};

const CustomSlider = ({
  handle,
  value,
  onChange,
  showPercentage,
  defaultHandle,
}) => {
  const props = {
    ...(defaultHandle === true
      ? {}
      : {
          handleRender: ({props}) => {
            return (
              <div className="slider-custom-emoji-wrapper">
                <div
                  {...props}
                  className={classnames(
                    'slider-custom-emoji',
                    props.className
                  )}>
                  {showPercentage === true && (
                    <div className="percentage">{Math.round(value / 10)} %</div>
                  )}
                  <div className="emoji">{handle}</div>
                </div>
              </div>
            );
          },
        }),
    onChange: onChange,
    value: value,
  };

  return (
    <div
      className={classnames('custom-slider', {
        'with-percentage': showPercentage === true,
        'default-handle': defaultHandle === true,
      })}>
      <Slider min={0} max={1000} {...props} />
    </div>
  );
};

CustomSlider.propTypes = propTypes;
CustomSlider.defaultProps = defaultProps;

export default CustomSlider;
