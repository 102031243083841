import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ChangelogCtaStyle from 'scenes/Settings/scenes/Themes/components/ChangelogThemeEditor/components/Cta';
import ChangelogGeneralStyle from 'scenes/Settings/scenes/Themes/components/ChangelogThemeEditor/components/General';
import {
  F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
  WIDGET_POSITION_BOTTOM_LEFT,
  WIDGET_POSITION_BOTTOM_RIGHT,
} from 'services/project';
import './_Styles.scss';

const dismissOptions = [
  {label: 'Click the cross', value: false},
  {label: 'Click outside or the cross', value: true},
];

const selectLanguageOptions = [
  {
    label: [<i className="icon-detect"></i>, 'Detect from user country'],
    value: 'AUTO',
  },
  {
    label: [<span className="flag-icon flag-icon-fr"></span>, 'Français'],
    value: 'FR',
  },
  {
    label: [<span className="flag-icon flag-icon-gb"></span>, 'English'],
    value: 'EN',
  },
  {
    label: [<span className="flag-icon flag-icon-es"></span>, 'Español'],
    value: 'ES',
  },
  {
    label: [<span className="flag-icon flag-icon-pt"></span>, 'Português'],
    value: 'PT',
  },
  {
    label: [<span className="flag-icon flag-icon-it"></span>, 'Italiano'],
    value: 'IT',
  },
  {
    label: [<span className="flag-icon flag-icon-de"></span>, 'Deutsch'],
    value: 'DE',
  },
  {
    label: [<span className="flag-icon flag-icon-jp"></span>, '日本'],
    value: 'JP',
  },
  {
    label: [<span className="flag-icon flag-icon-cn"></span>, '中國人'],
    value: 'ZH',
  },
  {
    label: [<span className="flag-icon flag-icon-sk"></span>, 'Slovenský'],
    value: 'SK',
  },
  {
    label: [<span className="flag-icon flag-icon-se"></span>, 'Svenska'],
    value: 'SV',
  },
  {
    label: [<span className="flag-icon flag-icon-nl"></span>, 'Dutch'],
    value: 'NL',
  },
  {
    label: [<span className="flag-icon flag-icon-fi"></span>, 'Finnish'],
    value: 'FI',
  },
  {
    label: [<span className="flag-icon flag-icon-ru"></span>, 'Russian'],
    value: 'RU',
  },
  {
    label: [<span className="flag-icon flag-icon-cz"></span>, 'Czech'],
    value: 'CS',
  },
  {
    label: [<span className="flag-icon flag-icon-hu"></span>, 'Hungarian'],
    value: 'HU',
  },
  {
    label: [<span className="flag-icon flag-icon-pl"></span>, 'Polish'],
    value: 'PL',
  },
  {
    label: [<span className="flag-icon flag-icon-dk"></span>, 'Danish'],
    value: 'DA',
  },
  {
    label: [<span className="flag-icon flag-icon-no"></span>, 'Norwegian'],
    value: 'NO',
  },
  {
    label: [<span className="flag-icon flag-icon-ro"></span>, 'Romanian'],
    value: 'RO',
  },
  {
    label: [<span className="flag-icon flag-icon-al"></span>, 'Albanian'],
    value: 'SQ',
  },
  {
    label: [<span className="flag-icon flag-icon-sa"></span>, 'Arabic'],
    value: 'AR',
  },
  {
    label: [<span className="flag-icon flag-icon-id"></span>, 'Indonesian'],
    value: 'ID',
  },
  {
    label: [<span className="flag-icon flag-icon-la"></span>, 'Laotian'],
    value: 'LO',
  },
  {
    label: [<span className="flag-icon flag-icon-sr"></span>, 'Serbian'],
    value: 'SR',
  },
  {
    label: [<span className="flag-icon flag-icon-ua"></span>, 'Ukrainian'],
    value: 'UK',
  },
  {
    label: [<span className="flag-icon flag-icon-th"></span>, 'Thai'],
    value: 'TH',
  },
  {
    label: [<span className="flag-icon flag-icon-tr"></span>, 'Turkish'],
    value: 'TR',
  },
  {
    label: [<span className="flag-icon flag-icon-vi"></span>, 'Vietnamese'],
    value: 'VI',
  },
];

const Changelog = () => {
  const {
    changelogTheme: theme,
    setChangelogTheme: setTheme,
    project,
    setProject,
  } = useContext(BuilderContext);

  const updateStyle = (blockType, style) => {
    const newTheme = {
      ...theme,
      [blockType]: {
        ...theme?.[blockType],
        ...style,
      },
    };
    setTheme(newTheme);
  };

  return (
    <div className="block-settings general">
      <div className="section">
        <div className="section-title subtitle-3">Behavior</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title body-3">To dismiss</div>
            <div className="section-item-content">
              <SelectGroup
                className="dismiss-option-select"
                classNamePrefix="dismiss-option-select-component"
                options={dismissOptions}
                value={(() => {
                  const hasOutsideClickDismiss = hasFlag(
                    F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
                    project.extraFlags
                  );
                  return dismissOptions.find(
                    (o) => o.value === hasOutsideClickDismiss
                  );
                })()}
                onChange={(option) => {
                  const newExtraFlags =
                    option.value === true
                      ? addFlag(
                          F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
                          project.extraFlags
                        )
                      : removeFlag(
                          F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK,
                          project.extraFlags
                        );
                  setProject({
                    ...project,
                    extraFlags: newExtraFlags,
                  });
                }}
                styles={{
                  menu: () => ({
                    position: 'absolute',
                    right: 0,
                    top: 'calc(100% + 8px)',
                    'overflow-x': 'hidden',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title subtitle-3">General</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title body-3">Background</div>
            <div className="section-item-content shadow-picker">
              <PopupSetting
                trigger={
                  <ClickableInput
                    inputProps={{small: true}}
                    value={theme?.general.background?.secondaryColor}
                    leftLabel={
                      <span
                        className="preview-shadow-color"
                        style={{
                          background:
                            theme?.general.background?.secondaryColor ||
                            '#FFFFFF',
                        }}
                      />
                    }
                  />
                }
                title="Background"
                content={
                  <ChangelogGeneralStyle
                    style={theme?.general}
                    setStyle={(style) => {
                      updateStyle('general', style);
                    }}
                  />
                }
                large
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title body-3">Position</div>
            <div className="section-item-content">
              <RadioGroup
                options={[
                  {label: 'Left', value: WIDGET_POSITION_BOTTOM_LEFT},
                  {label: 'Right', value: WIDGET_POSITION_BOTTOM_RIGHT},
                ]}
                value={project.widgetPosition}
                onSelect={(value) =>
                  setProject({
                    ...project,
                    widgetPosition: value,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title body-3">Language</div>
            <div className="section-item-content">
              <SelectGroup
                className="changelog-language-select"
                options={[]
                  .concat(selectLanguageOptions[0])
                  .concat(
                    selectLanguageOptions
                      .slice(1, selectLanguageOptions.length)
                      .sort((a, b) => a.label[1].localeCompare(b.label[1]))
                  )}
                value={selectLanguageOptions.find(
                  (o) => o.value === project.widgetLanguage
                )}
                onChange={(option) =>
                  setProject({
                    ...project,
                    widgetLanguage: option.value,
                  })
                }
                styles={{
                  menu: () => ({
                    position: 'absolute',
                    right: 0,
                    top: 'calc(100% + 8px)',
                    'overflow-x': 'hidden',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title subtitle-3">Call to action</div>
        <div className="section-content">
          <ChangelogCtaStyle
            style={theme?.callToAction}
            setStyle={(style) => {
              updateStyle('callToAction', style);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Changelog;
