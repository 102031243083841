import Input from 'components/Input';
import {toastSuccess} from 'components/Toaster';
import copy from 'copy-to-clipboard';
import React from 'react';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SegmentioSettingsSetup');

const SettingsSetup = ({segmentio}) => {
  return (
    <div className="segmentio-settings-setup">
      <div className="settings-card api-key-card">
        <div className="card-header">
          <div className="subtitle-3">API Key</div>
          <div className="body-3 n-700">
            Look for Jimo in the Segment Destination Catalog and copy/paste your
            api key
          </div>
        </div>
        <div className="card-body">
          <Input
            readOnly
            className="apikey-input"
            value={segmentio?.apiKey}
            iconRight="isax isax-copy"
            iconRightProps={{
              onClick: () => {
                copy(segmentio.apiKey);
                toastSuccess([
                  'Key copied',
                  'Your API key has been copied to your clipboard',
                ]);
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsSetup;
