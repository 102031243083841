import Axios from 'axios';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {ACTIVE_OPERATOR_EVERYWHERE} from 'scenes/EmbeddedElementSelectorBuilder';
import {HOTSPOT_SHAPE_DEFAULT} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hotspot';
import {generalSelector} from 'selectors';
import {F_EXTRA_COMMENTS_DISABLE, F_EXTRA_LIKES_DISABLE} from './project';

export const F_DEVICE_DESKTOP = 1;
export const F_DEVICE_TABLET = 2;
export const F_DEVICE_MOBILE = 4;
export const F_OPTION_COMMENTS_ENABLED = 1;
export const F_OPTION_SHOW_ON_WIDGET = 2;
export const F_OPTION_SHOW_ON_PORTAL = 4;
export const F_OPTION_LIKE_ENABLED = 8;
export const F_OPTION_BROADCAST_ON_SLACK = 16;
export const F_OPTION_CTA_IN_NEW_TAB = 32;
export const F_OPTION_PORTAL_DISPLAY_FEED = 64;
export const F_OPTION_PORTAL_DISPLAY_ROADMAP = 128;
export const F_OPTION_PORTAL_DISPLAY_FEEDBACK = 256;
export const F_OPTION_BUILDER_PRIORITIZE_IN_APP = 512;
export const F_OPTION_OPEN_WIDGET_ON_CLICK = 1024;
export const F_OPTION_DISMISS_ON_OUTSIDE_CLICK = 2048;
export const F_OPTION_IGNORE_RATE_LIMITING = 8192;
export const F_OPTION_PROGRESS_ON_TARGET_CLICK = 16384;
export const F_OPTION_TOUR_STEP_SKIPPABLE = 32768;
export const F_OPTION_TOUR_STEP_SKIP_IF_ELEMENT_NOT_FOUND = 65536;
export const F_OPTION_TOP_BAR_NOT_PUSHING_FIXED_CONTENT = 1048576;
export const F_OPTION_DISMISS_ON_CROSS_CLICK = 2097152; // legacy
export const F_OPTION_DOT_SHOW_ON_HOVER = 4096;
export const F_OPTION_POKE_CARD_WITH_POINTER = 131072;
export const F_OPTION_V2 = 262144;
export const F_OPTION_HOTSPOT_PERSISTENT = 524288;
export const F_OPTION_DISMISS_ON_OUTSIDE_HOVER = 4194304;
export const F_OPTION_SHOW_ON_TARGET_HOVER = 8388608;
export const F_OPTION_CHECKLIST_EXPAND_ON_TASK_COMPLETION_DISABLED = 16777216;

export const F_BOOST_SLOT_TOP_BAR = 1;
export const F_BOOST_SLOT_POP_IN = 2;
export const F_BOOST_SLOT_SNIPPET = 4;
export const F_BOOST_SLOT_DOT = 8;
export const F_BOOST_SLOT_TOUR = 16;
export const F_BOOST_SLOT_TOOLTIP = 32;
export const F_BOOST_SLOT_NAVIGATION = 64;
export const F_BOOST_SLOT_HINT = 128;

export const F_EVOLUTION_SLACK_CHANNEL_EACH_STEP_REPORT = 1;

export const TRIGGER_TYPE_DEFAULT = 'DEFAULT';
export const TRIGGER_TYPE_EVENT = 'EVENT';
export const TRIGGER_TYPE_MANUAL = 'MANUAL';
export const TRIGGER_TYPE_LEAVE_PAGE = 'LEAVE_PAGE';
export const TRIGGER_TYPE_URL = 'URL';
export const TRIGGER_TYPE_NONE = 'NONE';

export const isDraft = 'isDraft';
export const isScheduled = 'isScheduled';
export const isExpired = 'isExpired';
export const isActive = 'isActive';
export const isPaused = 'isPaused';
export const isTour = 'isTour';
export const isHotspot = 'isHotspot';
export const isTooltip = 'isTooltip';
export const isBanner = 'isBanner';
export const isSnippet = 'isSnippet';
export const isModal = 'isModal';
export const isRateLimited = 'isRateLimited';
export const isNotRateLimited = 'isNotRateLimited';

export const EVOLUTION_STATE_LIVE = 'LIVE';
export const EVOLUTION_STATE_PAUSED = 'PAUSED';
export const EVOLUTION_STATE_DRAFT = 'DRAFT'; // adding this for EvolutionListView but we actually still use the isDraft property at the moment
export const EVOLUTION_STATE_SCHEDULED = 'SCHEDULED';
export const EVOLUTION_STATE_EXPIRED = 'EXPIRED';

export const EVOLUTION_TYPE_TOUR = 'TOUR';
export const EVOLUTION_TYPE_SURVEY = 'SURVEY';
export const EVOLUTION_TYPE_BANNER = 'BANNER';
export const EVOLUTION_TYPE_HINT = 'HINT';
export const EVOLUTION_TYPE_CHECKLIST = 'CHECKLIST';
export const EVOLUTION_TYPE_POST = 'POST';

export const DEFAULT_BOOST_FLAGS = 0;
export const DEFAULT_POSITION_FLAGS = 64;
export const DEFAULT_OPTIONS_FLAGS = addFlag(
  [F_OPTION_COMMENTS_ENABLED, F_OPTION_LIKE_ENABLED],
  0
);

export const RECURRENCY_SINGLE_TIME = 'SINGLE_TIME';
export const RECURRENCY_EVERY_TIME = 'EVERY_TIME';
export const RECURRENCY_DAY_BASED = 'DAY_BASED';

export const BOOSTED_ANIMATION_NONE = 'none';
export const BOOSTED_ANIMATION_FADE = 'fade';
export const BOOSTED_ANIMATION_SLIDE = 'slide';
export const BOOSTED_ANIMATION_GROW = 'grow';
export const BOOSTED_ANIMATION_ORIENTATION_LEFT = 'left';
export const BOOSTED_ANIMATION_ORIENTATION_RIGHT = 'right';
export const BOOSTED_ANIMATION_ORIENTATION_BOTTOM = 'bottom';
export const BOOSTED_ANIMATION_ORIENTATION_TOP = 'top';
export const EVOLUTION_CONTEXT_ADOPTION = 'ADOPTION';
export const EVOLUTION_CONTEXT_DISCOVERY = 'DISCOVERY';
export const EVOLUTION_CONTEXT_PORTAL = 'PORTAL';

export const defaultCustomization = {
  boostedPrimaryColor: '#ffffff',
  boostedSecondaryColor: '#1260EB',
  boostedTextsColors: '#071331;#071331',
  boostedRoundness: '8',
  boostedTitleFontSize: '18',
  boostedContentFontSize: '14',
  boostedDotStyle: `#1260EB;22;${HOTSPOT_SHAPE_DEFAULT}`,
  boostedSize: null,
  boostedLightbox: 'SOFT',
  boostedAnimations: 'slide;slide;left;left',
  boostedZIndex: null,
};

// Endpoints
const EP_EVOLUTION_GET = '/evolution';
const EP_EVOLUTION_CREATE = '/evolution';
const EP_EVOLUTION_DUPLICATE = (evolutionId) =>
  `/evolution/duplicate/${evolutionId}`;
const EP_EVOLUTION_GET_BY_ID = (evolutionId) => `/evolution/${evolutionId}`;
const EP_EVOLUTION_SURVEYS = (evolutionId) =>
  `/evolution/${evolutionId}/surveys`;
const EP_EVOLUTION_UPDATE = (evolutionId) => `/evolution/${evolutionId}`;
const EP_EVOLUTION_TITLE_UPDATE = (evolutionId) =>
  `/evolution/${evolutionId}/title`;
const EP_EVOLUTION_STATE_UPDATE = (evolutionId) =>
  `/evolution/${evolutionId}/state`;
const EP_EVOLUTION_SECTION_INDEX_ORDER_UPDATE = (evolutionId) =>
  `/evolution/${evolutionId}/section-index-order`;
const EP_EVOLUTION_UPDATE_STEP = (evolutionId) =>
  `/evolution/${evolutionId}/step`;
const EP_EVOLUTION_DELETE = (evolutionId) => `/evolution/${evolutionId}`;
const EP_EVOLUTION_COMMENTS_READ = (evolutionId) =>
  `/evolution/${evolutionId}/comments/read`;
const EP_EVOLUTION_ENGAGEMENT_GET = (evolutionId) =>
  `/evolution/${evolutionId}/engagements`;
const EP_EVOLUTION_VIEWS_GET = (evolutionId) =>
  `/evolution/${evolutionId}/views`;
const EP_EVOLUTION_LIKES_GET = (evolutionId) =>
  `/evolution/${evolutionId}/likes`;
const EP_EVOLUTION_LATEST_LIKES_GET = (evolutionId) =>
  `/evolution/${evolutionId}/likes-latest`;
const EP_EVOLUTION_TOUR_STARTED_GET = (evolutionId) =>
  `/evolution/${evolutionId}/tour-started`;
const EP_EVOLUTION_TOUR_COMPLETED_GET = (evolutionId) =>
  `/evolution/${evolutionId}/tour-completed`;
const EP_EVOLUTION_UPDATE_RATE_LIMITING = `/evolution/ignore-rate-limiting`;
const EP_EVOLUTION_PUBLISH = (evolutionId) =>
  `/evolution/${evolutionId}/publish`;
const EP_EVOLUTION_EXPORT_DISCOVERY_RESPONSES_GET = (evolutionId) =>
  `evolution/${evolutionId}/export-discovery-responses`;
const EP_EVOLUTION_EXPORT_ADOPTION_RESPONSES_GET = (evolutionId) =>
  `evolution/${evolutionId}/export-adoption-responses`;
const EP_EVOLUTION_SLACK_CHANNEL_FLAGS_UPDATE = (evolutionId) =>
  `evolution/${evolutionId}/update-evolution-slack-channel-flags`;
const EP_EVOLUTION_ENVIRONMENTS_UPDATE = (evolutionId) =>
  `/evolution/${evolutionId}/environments`;

export const getEvolutions = (
  filter,
  opts = {projectId: null, signal: null}
) => {
  const projectId = opts.projectId || generalSelector.getProject().uid;

  return Axios.get(EP_EVOLUTION_GET, {
    params: {...filter, projectId},
    signal: opts.signal,
  }).then((response) => response.data);
};

export const createEvolution = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {
    title,
    fromRequest,
    texts,
    tags,
    optionsFlags,
    segments,
    onTheFlySegment,
    boostFlags,
    boostedPaths,
    boostedPathOperator,
    boostedQueryselector,
    boostedDomainFilter,
    boostedDelay,
    boostedPositionFlags,
    boostedPositionOffsets,
    boostedTextsColors,
    boostedPrimaryColor,
    boostedSecondaryColor,
    boostedRoundness,
    boostedTitleFontSize,
    boostedContentFontSize,
    boostedDotStyle,
    boostedSize,
    boostedLightbox,
    typeformFormId,
    steps,
    lastStepChangeAt,
    expiresAt,
    isDraft,
    icon,
    eventId,
    boostedActiveUrl,
    boostedActiveOperator,
    triggerType,
    onTheFlyEvent,
    sectionId,
    recurrencyType,
    recurrencyValue,
    tourSteps,
    boostedZIndex,
    type,
    themeId,
    style,
    deviceFlags,
    priority,
    isBoostOf,
    tracker,
  } = data;

  return Axios.post(
    EP_EVOLUTION_CREATE,
    {
      title,
      fromRequest: fromRequest != null ? fromRequest.uid : null,
      tagsId: tags?.filter((t) => t).map((t) => t.uid),
      segmentsId: segments?.map((s) => s.uid),
      onTheFlySegment,
      texts,
      optionsFlags,
      boostFlags,
      boostedPaths,
      boostedPathOperator,
      boostedQueryselector,
      boostedDomainFilter,
      boostedDelay,
      boostedPositionFlags,
      boostedPositionOffsets,
      boostedTextsColors,
      boostedPrimaryColor,
      boostedSecondaryColor,
      boostedRoundness,
      boostedTitleFontSize,
      boostedContentFontSize,
      boostedDotStyle,
      boostedSize,
      boostedLightbox,
      typeformFormId,
      steps,
      lastStepChangeAt,
      expiresAt,
      isDraft,
      icon,
      eventId,
      boostedActiveUrl,
      boostedActiveOperator,
      triggerType,
      onTheFlyEvent,
      sectionId,
      recurrencyType,
      recurrencyValue,
      tourSteps,
      boostedZIndex,
      type,
      themeId,
      style,
      deviceFlags,
      priority,
      isBoostOf,
      tracker,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};

export const getEvolutionById = (evolutionId, opts = {}) => {
  return Axios.get(EP_EVOLUTION_GET_BY_ID(evolutionId), {
    params: {...opts},
  }).then((response) => {
    const evolution = response.data;
    evolution.steps = evolution.steps?.sort(
      (a, b) => a.indexOrder - b.indexOrder
    );
    evolution.steps?.forEach((s) => {
      delete s.indexOrder;
    });
    return evolution;
  });
};

export const updateEvolution = (evolutionId, data) => {
  const {
    title,
    boostedPrimaryColor,
    boostedSecondaryColor,
    boostedRoundness,
    boostedTitleFontSize,
    boostedContentFontSize,
    boostedDotStyle,
    boostedQueryselector,
    boostedDelay,
    boostedPathOperator,
    boostedPaths,
    boostedDomainFilter,
    boostFlags,
    boostedPositionFlags,
    boostedPositionOffsets,
    boostedTextsColors,
    boostedSize,
    boostedLightbox,
    boostedAnimations,
    optionsFlags,
    onTheFlySegment,
    lastStepChangeAt,
    expiresAt,
    segments,
    steps,
    tags,
    isDraft,
    icon,
    event,
    eventId = event != null ? event.uid : undefined,
    boostedActiveUrl,
    boostedActiveOperator,
    triggerType,
    state,
    onTheFlyEvent,
    sectionId,
    recurrencyType,
    recurrencyValue,
    tourSteps,
    boostedZIndex,
    themeId,
    style,
    section,
    deviceFlags,
    priority,
    isBoostOf,
    tracker,
    environments,
  } = data;
  return Axios.put(EP_EVOLUTION_UPDATE(evolutionId), {
    title,
    boostedPrimaryColor,
    boostedSecondaryColor,
    boostedTextsColors,
    boostedRoundness,
    boostedTitleFontSize,
    boostedContentFontSize,
    boostedDotStyle,
    boostedQueryselector,
    boostedDelay,
    boostedPathOperator,
    boostedPaths,
    boostedDomainFilter,
    boostFlags,
    boostedPositionFlags,
    boostedPositionOffsets,
    boostedSize,
    boostedLightbox,
    boostedAnimations,
    optionsFlags,
    onTheFlySegment: triggerType === TRIGGER_TYPE_URL ? null : onTheFlySegment,
    lastStepChangeAt,
    expiresAt,
    isDraft,
    steps,
    segmentsId:
      triggerType === TRIGGER_TYPE_URL
        ? []
        : segments != null
        ? segments.map((s) => s.uid)
        : undefined,
    tagsId: tags?.filter((t) => t).map((t) => t.uid),
    icon,
    eventId,
    boostedActiveUrl,
    boostedActiveOperator,
    triggerType,
    state,
    onTheFlyEvent,
    sectionId: sectionId || section?.uid,
    recurrencyType,
    recurrencyValue,
    tourSteps,
    boostedZIndex,
    themeId,
    style,
    deviceFlags,
    priority,
    isBoostOf,
    tracker,
    environments,
  }).then((response) => response.data);
};

export const updateEvolutionTitle = (evolutionId, title) => {
  return Axios.put(EP_EVOLUTION_TITLE_UPDATE(evolutionId), {title}).then(
    (response) => response.data
  );
};

export const updateEvolutionState = (evolutionId, state) => {
  return Axios.put(EP_EVOLUTION_STATE_UPDATE(evolutionId), {state}).then(
    (response) => response.data
  );
};

export const duplicateEvolution = (evolutionId) => {
  const projectId = generalSelector.getProject().uid;
  return Axios.post(
    EP_EVOLUTION_DUPLICATE(evolutionId),
    {evolutionId},
    {
      params: {projectId},
    }
  ).then((response) => response.data);
};

export const updateEvolutionsRateLimiting = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {evolutionIds} = data;

  return Axios.post(EP_EVOLUTION_UPDATE_RATE_LIMITING, {
    evolutionIds,
    projectId,
  }).then((response) => response.data);
};

export const updateEvolutionStep = (evolutionId, step) => {
  return Axios.patch(EP_EVOLUTION_UPDATE_STEP(evolutionId), {step}).then(
    (response) => response.data
  );
};

export const updateEvolutionSectionIndexOrder = (evolutionId, data) => {
  const {indexOrder} = data;

  return Axios.put(EP_EVOLUTION_SECTION_INDEX_ORDER_UPDATE(evolutionId), {
    indexOrder,
  }).then((response) => response.data);
};

export const deleteEvolution = (evolutionId) => {
  return Axios.delete(EP_EVOLUTION_DELETE(evolutionId)).then(
    (response) => response.data
  );
};

export const setEvolutionCommentsToRead = (evolutionId) => {
  return Axios.put(EP_EVOLUTION_COMMENTS_READ(evolutionId)).then(
    (response) => response.data
  );
};

export const getEvolutionSurveys = (evolutionId) => {
  return Axios.get(EP_EVOLUTION_SURVEYS(evolutionId)).then(
    (response) => response.data
  );
};

export const getEvolutionEngagementWithJimers = (evolutionId) => {
  return Axios.get(EP_EVOLUTION_ENGAGEMENT_GET(evolutionId)).then(
    (response) => response.data
  );
};

export const getEvolutionViewsWithJimers = (evolutionId, data) => {
  const {skip, take} = data;

  return Axios.get(EP_EVOLUTION_VIEWS_GET(evolutionId), {
    params: {skip, take},
  }).then((response) => response.data);
};

export const getEvolutionLikesWithJimers = (evolutionId) => {
  return Axios.get(EP_EVOLUTION_LIKES_GET(evolutionId)).then(
    (response) => response.data
  );
};
export const getEvolutionTourStartedWithJimers = (evolutionId) => {
  return Axios.get(EP_EVOLUTION_TOUR_STARTED_GET(evolutionId)).then(
    (response) => response.data
  );
};
export const getEvolutionTourCompletedWithJimers = (evolutionId) => {
  return Axios.get(EP_EVOLUTION_TOUR_COMPLETED_GET(evolutionId)).then(
    (response) => response.data
  );
};

export const getDefaultOptionsFlags = (
  project = generalSelector.getProject()
) => {
  let optionsFlags = DEFAULT_OPTIONS_FLAGS;

  if (hasFlag(F_EXTRA_COMMENTS_DISABLE, project.extraFlags) === true) {
    optionsFlags = removeFlag(F_OPTION_COMMENTS_ENABLED, optionsFlags);
  } else {
    optionsFlags = addFlag(F_OPTION_COMMENTS_ENABLED, optionsFlags);
  }
  if (hasFlag(F_EXTRA_LIKES_DISABLE, project.extraFlags) === true) {
    optionsFlags = removeFlag(F_OPTION_LIKE_ENABLED, optionsFlags);
  } else {
    optionsFlags = addFlag(F_OPTION_LIKE_ENABLED, optionsFlags);
  }
  return optionsFlags;
};

export const getDefaultEvolution = (props = {}) => {
  return {
    title: 'New poke',
    texts: [],
    tags: [],
    segments: [],
    onTheFlySegment: null,
    optionsFlags: getDefaultOptionsFlags(),
    typeformFormId: null,
    mazeUrl: '',
    steps: [],
    boostFlags: DEFAULT_BOOST_FLAGS,
    boostedPaths: [],
    boostedQueryselector: null,
    boostedDelay: 0,
    boostedPositionFlags: DEFAULT_POSITION_FLAGS,
    boostedPositionOffsets: '0;0',
    ...defaultCustomization,
    lastStepChangeAt: null,
    expiresAt: null,
    isDraft: true,
    isLaunched: false,
    draft: null,
    icon: 'DEFAULT',
    eventId: null,
    boostedActiveUrl: '',
    boostedActiveOperator: ACTIVE_OPERATOR_EVERYWHERE,
    triggerType: TRIGGER_TYPE_DEFAULT,
    onTheFlyEvent: null,
    recurrencyType: RECURRENCY_SINGLE_TIME,
    recurrencyValue: 1,
    tourSteps: [],
    boostedZIndex: null,
    ...props,
  };
};

export const publish = (evolutionId) => {
  return Axios.post(EP_EVOLUTION_PUBLISH(evolutionId)).then(
    (response) => response.data
  );
};

export const exportDiscoveryResponses = (evolutionId, type) => {
  return Axios.get(EP_EVOLUTION_EXPORT_DISCOVERY_RESPONSES_GET(evolutionId), {
    params: {type},
  }).then((response) => response.data);
};

export const exportAdoptionResponses = (evolutionId, type) => {
  return Axios.get(EP_EVOLUTION_EXPORT_ADOPTION_RESPONSES_GET(evolutionId), {
    params: {type},
  }).then((response) => response.data);
};

export const updateEvolutionSlackChannelFlags = (
  evolutionId,
  evolutionSlackChannelFlags
) => {
  return Axios.put(EP_EVOLUTION_SLACK_CHANNEL_FLAGS_UPDATE(evolutionId), {
    evolutionSlackChannelFlags,
  }).then((response) => response.data);
};

export const getEvolutionLatestLikes = (evolutionId, data = {}) => {
  const {take, skip} = data;
  return Axios.get(EP_EVOLUTION_LATEST_LIKES_GET(evolutionId), {
    params: {take, skip},
  }).then((response) => response.data);
};

export const updateEvolutionEnvironments = (evolutionId, environments) => {
  return Axios.put(EP_EVOLUTION_ENVIRONMENTS_UPDATE(evolutionId), {
    environments,
  }).then((response) => response.data);
};
