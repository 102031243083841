import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {
  MarkdownEditorContext,
  useMyPlateEditorRef,
} from 'components/MarkdownEditor/utils';
import SelectGroup from 'components/Select';
import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {components} from 'react-select';
import {HubspotLogo} from 'scenes/Settings/scenes/Integrations/Hubspot';
import {SegmentLogo} from 'scenes/Settings/scenes/Integrations/Segment';
import {generalSelector} from 'selectors';
import {segmentService} from 'services';
import {
  CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT,
  CUSTOM_ATTRIBUTE_SOURCE_JIMO,
  CUSTOM_ATTRIBUTE_SOURCE_SEGMENT,
} from 'services/segment';
import {Transforms} from 'slate';
import './_Styles.scss';

const Option = (props) => {
  const {data} = props;

  return (
    <components.Option className="variable-config-option" {...props}>
      {data?.source === CUSTOM_ATTRIBUTE_SOURCE_JIMO ? (
        <i className="isax isax-profile-tick5" />
      ) : data?.source === CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT ? (
        <HubspotLogo />
      ) : data?.source === CUSTOM_ATTRIBUTE_SOURCE_SEGMENT ? (
        <SegmentLogo />
      ) : (
        <></>
      )}
      {props.data.label}
    </components.Option>
  );
};

const SingleValue = ({children, ...props}) => {
  const {data} = props;

  return (
    <components.SingleValue className="variable-config-single-value" {...props}>
      {data?.source === CUSTOM_ATTRIBUTE_SOURCE_JIMO ? (
        <i className="isax isax-profile-tick5" />
      ) : data?.source === CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT ? (
        <HubspotLogo />
      ) : data?.source === CUSTOM_ATTRIBUTE_SOURCE_SEGMENT ? (
        <SegmentLogo />
      ) : (
        <></>
      )}
      <div className="label">{children}</div>
    </components.SingleValue>
  );
};

const VariableConfigModal = ({trigger}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {currentVariable, setCurrentVariable} = useContext(
    MarkdownEditorContext
  );

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const isOpen = currentVariable != null;

  useEffect(() => {
    setIsSelectOpen(false);
  }, [isOpen]);

  const editor = useMyPlateEditorRef();

  const {data: customAttributes} = useQuery({
    queryKey: 'customAttributes',
    queryFn: () => segmentService.getSegmentCustomAttributes(project.uid),
    refetchOnWindowFocus: false,
  });

  const onVariableChange = (updatedVariable) => {
    const {path, ...newProperties} = updatedVariable;

    if (path) {
      Transforms.setNodes(editor, newProperties, {at: path});
    }

    setCurrentVariable(updatedVariable);
  };

  const customAttributesOptions =
    customAttributes?.map((attr) => ({
      value: attr.name,
      label: attr.name,
      source: attr.source,
    })) || [];

  const groupedOptions = [
    {
      label: 'Jimo',
      options: customAttributesOptions.filter(
        (option) => option.source === CUSTOM_ATTRIBUTE_SOURCE_JIMO
      ),
    },
    {
      label: 'Hubspot',
      options: customAttributesOptions.filter(
        (option) => option.source === CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT
      ),
    },
    {
      label: 'Segment',
      options: customAttributesOptions.filter(
        (option) => option.source === CUSTOM_ATTRIBUTE_SOURCE_SEGMENT
      ),
    },
  ].filter((group) => group.options.length > 0);

  return (
    <Dropdown
      key={currentVariable?.uid}
      open={isOpen}
      disabled={currentVariable == null}
      className="variable-config-modal"
      position="left top"
      offsetX={8}
      trigger={trigger}
      contentStyle={{
        zIndex: 1002,
      }}
      onClose={() => {
        setIsSelectOpen(false);
        setCurrentVariable(null);
      }}
      closeOnDocumentClick={isSelectOpen !== true}>
      <div className="variable-config-modal-header">
        <span className="subtitle-3">User attribute</span>
        <i
          className="icon-close n-600"
          onClick={() => {
            setCurrentVariable(null);
          }}
        />
      </div>

      <div className="item">
        <div className="item-label">Attribute</div>
        <div className="item-content">
          <SelectGroup
            autoFocus={false}
            menuPortalTarget={document.body}
            options={groupedOptions}
            value={customAttributesOptions.find(
              (option) => option.value === currentVariable?.attribute
            )}
            onChange={(option) =>
              onVariableChange({
                ...currentVariable,
                attribute: option.value,
                attributeSource: option.source,
              })
            }
            style={{
              width: '100%',
              marginBottom: '16px',
            }}
            styles={{
              menuPortal: (base) => ({...base, zIndex: 9999}),
            }}
            onFocus={() => setIsSelectOpen(true)}
            onBlur={() => setIsSelectOpen(false)}
            components={{
              Option: Option,
              SingleValue: SingleValue,
            }}
          />
        </div>
      </div>
      <div className="item">
        <div className="item-label">Fallback</div>
        <div className="item-content">
          <InputGroup
            placeholder="No fallback"
            value={currentVariable?.fallbackValue}
            onChange={(e) =>
              onVariableChange({
                ...currentVariable,
                fallbackValue: e.target.value,
              })
            }
          />
        </div>
      </div>

      <a
        href="https://help.usejimo.com/help-center/v/using-jimo/users-and-segments/segments#attribute-types"
        target="_blank"
        rel="noreferrer noopener">
        <div className="footer b-400 body-3">
          <i className="isax isax-book-1" />
          Learn how to insert user attributes
        </div>
      </a>
    </Dropdown>
  );
};

export default VariableConfigModal;
