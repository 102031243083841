import React from 'react';
import './_Styles.scss';
import {Modal} from 'components/Modal';
import {bool, func, string} from 'prop-types';

const propTypes = {
  url: string.isRequired,
  isOpen: bool,
  onRequestClose: func,
};

const defaultProps = {
  url: 'https://testing.examples.usejimo.com/example1/?jimo_poke=ee4528e3-ba0d-447d-b277-1b9ec6f983c3&jimo_force_init=true',
  isOpen: false,
  onRequestClose: () => {},
};

const IframePreview = ({url, isOpen, onRequestClose}) => {
  return (
    <Modal
      className="template-iframe-preview"
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <iframe src={url} title="template-iframe-preview" />
    </Modal>
  );
};

IframePreview.propTypes = propTypes;
IframePreview.defaultProps = defaultProps;

export default IframePreview;
