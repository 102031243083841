import React, {useContext} from 'react';
import {TrackerContext} from 'scenes/Tracker';
import './_Styles.scss';

export const trackerStepsColors = [
  '#5791F7',
  '#EBBB12',
  '#A44EE9',
  '#32CC1D',
  '#3EDAFC',
  '#F7A357',
];

const CompletionRateLegend = () => {
  const {tracker} = useContext(TrackerContext);
  const {steps} = tracker;

  steps.sort((a, b) => a.indexOrder - b.indexOrder);

  return (
    <div className="tracker-completion-legend">
      {steps.map((step, index) => (
        <div className="legend-item">
          <div
            className="legend-color"
            style={{background: trackerStepsColors[index]}}
          />
          <div className="legend-label">{step.name}</div>
        </div>
      ))}
    </div>
  );
};

export default CompletionRateLegend;
