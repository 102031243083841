import classnames from 'classnames';
import SelectGroup from 'components/Select';
import {components} from 'react-select';
import './_styles.scss';

const Control = ({children, ...rest}) => {
  return (
    <components.Control {...rest}>
      <i className="icon-jimo"></i>
      {children}
    </components.Control>
  );
};

export const MapField = ({
  name = null,
  value = null,
  rowsFound = 0,

  isDisabled = false,
  isJimo = false,
  isMapped = true,

  options = [],
  previewItems = [],

  onSelect = () => {},
  onChangeMapping = () => {},
}) => {
  return (
    <div
      className={classnames('import-csv-map-field', {
        'is-mapped': isMapped,
        'is-disabled': isDisabled,
      })}>
      <div
        className="field-header"
        onClick={() => {
          if (isDisabled === true) return;
          onSelect(!isMapped);
        }}>
        <div className="name-wrapper">
          <i className="isax isax-document-upload"></i>
          <span className="subtitle-4 import-name">{name}</span>
          <span className="row-count body-4">{rowsFound} rows found</span>
        </div>
        {isDisabled === false && (
          <>
            {isMapped === true ? (
              <i className="icon-checkbox-a"></i>
            ) : (
              <i className="icon-checkbox-o"></i>
            )}
          </>
        )}
      </div>
      <div className="field-preview">
        {previewItems.length === 0 && (
          <div className="preview-empty">No item to preview</div>
        )}
        {previewItems.map((item, i) => (
          <div key={i} className="preview-item body-3">
            <span>{i + 1}</span>
            {item}
          </div>
        ))}
      </div>
      <div className="field-footer">
        <div className="body-4">Map to attribute</div>
        <SelectGroup
          value={options.find((o) => o.value === value)}
          onChange={onChangeMapping}
          isDisabled={isDisabled}
          options={options}
          components={{
            Control: (props) => (
              <Control
                className="import-csv-map-field-control"
                {...props}
                isJimo={isJimo}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};
