import './_styles.scss';

export const GetStartedStepContainer = ({children}) => {
  return (
    <div className="gs-step-container">
      <div className="gs-step-trait"></div>
      {children}
    </div>
  );
};
