import Loader from 'components/Loader';
import React from 'react';
import './_Styles.scss';

export default function DevLoader() {
  return (
    <div className="_dev-loader">
      <Loader></Loader>
      <Loader width="250px"></Loader>
      <Loader width="15px"></Loader>
    </div>
  );
}
