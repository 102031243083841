import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {toastSuccess, toastWarning} from 'components/Toaster';
import {crispHelpers} from 'helpers';
import {useCheckInstallation} from 'hooks/useCheckInstallation';
import React from 'react';
import {Install} from 'scenes/Onboarding/scenes/Details/step-3-1';
import './_Styles.scss';

const ModalInstall = ({
  title = 'Install Jimo in your app',
  isOpen,
  onRequestClose,
  subtitle = null,
  withCloseButton = false,
}) => {
  const {check} = useCheckInstallation({
    onInstalled: () => {
      toastSuccess(
        ['Project installed!', 'We have detected the snippet on your website!'],
        {toastId: 'project-installed'}
      );
      onRequestClose();
    },
    onNotInstalled: () => {
      toastWarning("We didn't detect a valid snippet installation yet", {
        toastId: 'project-not-installed',
        actions: [
          {
            text: 'Chat with us',
            props: {
              onClick: () =>
                crispHelpers.startCrispThread(
                  `I'm having issue installing the snippet. Can you help me ? :-)`
                ),
              iconLeft: 'icon-chat',
            },
          },
        ],
      });
    },
  });

  return (
    <Modal
      className="modal-install"
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      large>
      <div className="content">
        {subtitle != null && <div className="subtitle">{subtitle}</div>}
        <Install withoutAnimation={true} defaultOpen={null} />
      </div>

      <div className="actions">
        <Button onClick={onRequestClose}>Close</Button>
        <Button primary reverted onClick={check}>
          Check installation
        </Button>
      </div>
    </Modal>
  );
};

export default ModalInstall;
