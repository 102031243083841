import Button from 'components/Button';
import {TipH, TipV} from 'components/Tip';
import React from 'react';
import './_Styles.scss';
import tipImage1 from './imgs/tip-img1.png';
import tipImage2 from './imgs/tip-img2.png';

export default function DevTip() {
  return (
    <div className="_dev-tip">
      <div className="wrapper">
        <TipH image={tipImage1} actions={<Button muted>See</Button>}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur
          omnis pariatur corporis et quam? Natus, quod perferendis voluptatibus
          provident reprehenderit nam! Deserunt natus iste veritatis facere
          laborum cum qui eius.
        </TipH>
        <TipH image={tipImage2} bgColor="#409AF8">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur
          omnis pariatur corporis et quam? Natus, quod perferendis voluptatibus
          provident reprehenderit nam! Deserunt natus iste veritatis facere
          laborum cum qui eius.
        </TipH>
        <div className="tips-vert-wrapper">
          <TipV
            id="tipv_with_image"
            title="A vertical tip"
            bgColor="#409AF8"
            actions={
              <Button primary iconLeft="icon-copy">
                copy
              </Button>
            }></TipV>
          <TipV
            title="Populate your request board with your own insights"
            bgColor="#3430DE"
            actions={
              <Button muted iconOnly>
                <i className="icon-plus"></i>
              </Button>
            }></TipV>
          <TipV
            title="A vertical tip"
            bgColor="#F1A052"
            actions={
              <Button muted iconOnly>
                <i className="icon-plus"></i>
              </Button>
            }></TipV>
        </div>
      </div>
    </div>
  );
}
