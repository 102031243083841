import Button from 'components/Button';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useContext, useEffect, useState} from 'react';
import {PushesContext} from 'scenes/Pushes/context';
import {evolutionListViewService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalListViewRename');

export const ModalListViewRename = ({view, ...rest}) => {
  const {refetchViews} = useContext(PushesContext);
  const [name, setName] = useState(view?.name);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setName(view?.name ?? '');
  }, [rest.isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await evolutionListViewService.updateListViewName(view.uid, name);
      refetchViews();
      setSubmitting(false);
      rest.onRequestClose();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating name of view failed with error : ', code);
      toastDanger([title, message], {actions});
      setSubmitting(false);
    }
  };

  return (
    <Modal
      {...rest}
      className="modal-list-view-rename"
      title="Rename view"
      {...rest}>
      <>
        <div className="modal-content">
          <Input
            placeholder="Name"
            value={name}
            onChange={({target}) => setName(target.value)}></Input>
        </div>
        <div className="actions">
          <Button
            type="button"
            muted
            onClick={rest.onRequestClose}
            disabled={submitting}>
            Cancel
          </Button>
          <Button primary reverted loading={submitting} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </>
    </Modal>
  );
};
