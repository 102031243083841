import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useContext, useRef, useState} from 'react';
import {trackerAnalyticsService} from 'services';
import {Swaler} from 'swaler';
import {ListUsers} from '../ListUsers';
import './_Styles.scss';
import {TrackerContext} from 'scenes/Tracker';
import {useQuery} from 'react-query';
import Button from 'components/Button';
import TrackerListUsersDrawer from 'scenes/Tracker/components/TrackerListUsersDrawer';
import JimoGrey from 'components/JimoGrey';

const JIMERS_PER_PAGE = 8;

const logger = new Swaler('LastActivity');

const LastActivity = () => {
  const {tracker} = useContext(TrackerContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });

  const cancelCtrl = useRef();

  const {isLoading, refetch} = useQuery({
    queryKey: ['lastActivity', tracker.uid],
    queryFn: async () => {
      cancelCtrl.current = new AbortController();

      const jimers = await trackerAnalyticsService.getLastActivity(
        {trackerId: tracker.uid},
        {signal: cancelCtrl.current.signal}
      );

      setJimers(jimers);
    },
    onError: (err) => {
      if (err.message === 'canceled') {
        return;
      }
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching users failed with error ', code);
      toastDanger([title, message], {actions});
    },
  });

  return (
    <div className="list-users-wrapper">
      {isLoading === false && jimers.data.length === 0 ? (
        <div className="no-users">
          <JimoGrey />
          <div className="no-users-text">No users yet</div>
        </div>
      ) : (
        <>
          <ListUsers
            jimers={jimers.data}
            isLoading={isLoading}
            onDelete={() => refetch()}
          />
          <Button
            className="sell-all-users-btn"
            onClick={() => setIsDrawerOpen(true)}
            disabled={jimers.data.length === 0}>
            see all users
          </Button>
        </>
      )}

      {isDrawerOpen === true && (
        <TrackerListUsersDrawer
          isOpen={isDrawerOpen}
          onRequestClose={() => setIsDrawerOpen(false)}
          tracker={tracker}
        />
      )}
    </div>
  );
};

export default LastActivity;
