import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {validateTrigger} from 'helpers/step';
import React, {createContext, useContext, useEffect, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import EventConditions, {
  AddConditionDropdown,
} from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_SURVEY_RESPONSE,
  EVENT_CONDITION_TYPE_URL,
} from 'services/event';
import TriggerActions from '../TriggerActions';
import './_Styles.scss';

export const TriggerContext = createContext();

const ConditionIcon = ({type}) => {
  let icon, iconClassName;

  switch (type) {
    case EVENT_CONDITION_TYPE_ELEMENT:
      icon = 'isax isax-mouse-circle';
      iconClassName = 'element';
      break;
    case EVENT_CONDITION_TYPE_INPUT:
      icon = 'isax isax-keyboard';
      iconClassName = 'input';
      break;
    case EVENT_CONDITION_TYPE_URL:
      icon = 'isax isax-receipt-search';
      iconClassName = 'url';
      break;
    case EVENT_CONDITION_TYPE_DELAY:
      icon = 'isax isax-clock';
      iconClassName = 'delay';
      break;
    case EVENT_CONDITION_TYPE_SURVEY_RESPONSE:
      icon = 'isax isax-message-text';
      iconClassName = 'survey-response';
      break;
    default:
      break;
  }

  return (
    <div className={classNames('condition-icon', iconClassName)}>
      <i className={icon} />
    </div>
  );
};

export const CustomIndicatorLabel = ({className, text}) => {
  return (
    <div className={classNames('custom-indicator-label', className)}>
      <div className="label">{text}</div>
      <div className="arrow-right" />
    </div>
  );
};

const Trigger = ({
  trigger,
  onChange,
  isExpanded,
  onToggleExpand,
  onDelete,
  isChecklist = false,
  isSurvey = false,
}) => {
  const {
    isInApp,
    messenger,
    setSelectingElementTriggerConditionId,
    evolution,
    selectedStep,
  } = useContext(BuilderContext);

  const {conditions: conditionsProp, logic: logicProp, actions = []} = trigger;

  const [conditions, setConditions] = useState(conditionsProp || []);
  const [logic, setLogic] = useState(logicProp || buildLogic(trigger));

  const handleChange = (updatedTrigger) => {
    onChange({
      ...trigger,
      ...updatedTrigger,
    });
    onToggleExpand(trigger.uid);
  };

  useEffect(() => {
    handleChange({
      ...trigger,
      conditions,
      logic,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions, logic]);

  const steps = isSurvey
    ? evolution.steps.filter((s) => s.removed !== true)
    : evolution.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStepIndex: parseInt(ts.tourStepInfo.split(';')[0], 10),
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);
  steps.sort((a, b) => {
    // first sort on tourStepIndex and then on indexOrder
    const tourStepIndexA = a.tourStepIndex;
    const tourStepIndexB = b.tourStepIndex;
    const indexOrderA = a.indexOrder;
    const indexOrderB = b.indexOrder;

    if (tourStepIndexA < tourStepIndexB) {
      return -1;
    }
    if (tourStepIndexA > tourStepIndexB) {
      return 1;
    }
    if (indexOrderA < indexOrderB) {
      return -1;
    }
    if (indexOrderA > indexOrderB) {
      return 1;
    }
    return 0;
  });

  const currentStepIndex = steps.findIndex((s) => s.uid === selectedStep.uid);
  const nextStep = steps[currentStepIndex + 1];

  const issues = validateTrigger(trigger, {
    hasNoPrevStep: currentStepIndex === 0,
  });

  return (
    <TriggerContext.Provider
      value={{
        trigger,
        setTrigger: (update) => {
          handleChange({
            ...trigger,
            ...update,
          });
        },
        isExpanded,
        preventAddAction: isChecklist,
      }}>
      <div
        className={classNames('trigger', {
          'is-expanded': isExpanded,
          'is-invalid': issues.length > 0,
        })}>
        <div
          className="trigger-header"
          onClick={() => {
            if (isExpanded !== true) {
              onToggleExpand(trigger.uid);
            } else {
              onToggleExpand(null);
            }
          }}>
          <div className="trigger-header-content">
            <div className="body-3 n-600">
              {issues.length > 0 ? (
                issues.join(', ')
              ) : conditions.length > 0 ? (
                <>
                  {conditions.length} condition
                  {conditions.length > 1 ? 's' : ''}
                </>
              ) : (
                'No conditions set yet'
              )}
            </div>
            {isExpanded !== true && conditions.length > 0 && (
              <div className="condition-icons">
                {conditions.map((condition, index) => (
                  <ConditionIcon key={index} type={condition.type} />
                ))}
              </div>
            )}
          </div>
          <i className="icon-chevron-bottom" />
        </div>
        <AnimateHeight
          className="collapsable-content-wrapper"
          height={isExpanded ? 'auto' : '0'}>
          <Divider />
          <div
            className={classNames('trigger-conditions', {
              'has-single-condition': isSurvey,
            })}>
            {conditions.length > 0 && (
              <CustomIndicatorLabel text="If" className="if" />
            )}
            <EventConditions
              conditions={conditions}
              setConditions={setConditions}
              logic={logic}
              setLogic={setLogic}
              borderless
              compact
              settingsPositioning="right center"
              CustomEmptyState={(props) => (
                <AddConditionDropdown
                  trigger={
                    <div className="custom-condition-empty-state">
                      <CustomIndicatorLabel text="If" className="if" />
                      <Button
                        className="add-condition-btn"
                        thin
                        tertiary
                        iconLeft="icon-plus">
                        Add condition
                      </Button>
                    </div>
                  }
                  {...props}
                  omitTypes={['logicGroup']}
                />
              )}
              onSelectElement={
                isInApp
                  ? (conditionId, opts) => {
                      messenger?.sendSelectTargetElement(opts);
                      setSelectingElementTriggerConditionId(conditionId);
                    }
                  : null
              }
              singleCondition={isSurvey}
            />
          </div>
        </AnimateHeight>
        <Divider />
        <div
          className={classNames('trigger-actions-wrapper', {
            'is-expanded': isExpanded,
            'is-empty': actions.length === 0,
            'is-survey': isSurvey,
          })}>
          {isExpanded && <CustomIndicatorLabel className="then" text="Then" />}
          <div className="trigger-actions-content">
            {isExpanded && isSurvey !== true && (
              <>
                {actions.length > 0 && (
                  <div className="trigger-icon-wrapper">
                    <i className="isax isax-play-circle5 b-400" />
                  </div>
                )}
              </>
            )}
            <TriggerActions nextStep={nextStep} />
          </div>
        </div>
        {isExpanded && (
          <>
            <Divider />
            <div className="trigger-delete-wrapper" onClick={onDelete}>
              <div className="icon-wrapper">
                <i className="isax isax-trash r-400" />
              </div>
              <div className="r-400">
                {isSurvey ? 'Delete condition' : 'Delete trigger'}
              </div>
            </div>
          </>
        )}
      </div>
    </TriggerContext.Provider>
  );
};

export default Trigger;
