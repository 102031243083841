import {generalActions} from 'actions';
import Button from 'components/Button';
import {toastInfo} from 'components/Toaster';
import React, {useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom/cjs/react-router-dom';
import {
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_INTERCOM,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {intercomService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';
import SettingsSetup from './components/SettingsSetup';

const logger = new Swaler('IntercomSettings');

const IntercomSettings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject());

  const [isIntercomLoading, setIsIntercomLoading] = useState(false);

  const {intercom} = project;
  const intercomActive = !!intercom?.access_token;

  const {mutate: deleteIntercom, isLoading: isLoadingIntercomDelete} =
    useMutation(() => intercomService.deleteIntercom(intercom?.uid), {
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: () => {
        toastInfo([
          'Integration disconnected',
          'Your integration with Intercom has been disconnected!',
        ]);
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        dispatch(generalActions.uptProject({intercom: null}));
      },
    });

  useEffect(() => {
    if (location.pathname === ROUTE_SETTINGS_INTEGRATIONS_INTERCOM) {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      if (code !== null && state !== null) {
        setIsIntercomLoading(true);
        intercomService
          .exchangeCodeForToken(project.uid, {
            code,
            state,
          })
          .then((intercom) => {
            dispatch(generalActions.uptProject({intercom}));
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            params.delete('code');
            params.delete('state');
            history.replace({
              pathname: ROUTE_SETTINGS_INTEGRATIONS_INTERCOM,
              search: params.toString(),
            });
            setIsIntercomLoading(false);
          });
      }
    }
  }, [location]);

  useEffect(() => {
    if (intercom == null) {
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
      toastInfo([
        'Integration not connected',
        "Intercom integration isn't connected yet. Please connect the integration first.",
      ]);
    }
  }, [intercom]);

  return (
    <SettingsBody className="intercom-settings">
      <div className="intercom-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={async () => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Segment Integration</div>
        </div>
        <div className="right-side"></div>
      </div>
      <div className="intercom-settings-content">
        <div className="left-side">
          <SettingsSetup
            intercom={intercom}
            isIntercomLoading={isIntercomLoading}
          />
        </div>
        <div className="right-side">
          <HelpSection
            isDisconnecting={isLoadingIntercomDelete}
            onDisconnect={() => {
              deleteIntercom();
            }}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default IntercomSettings;
