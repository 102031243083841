import React, {useEffect, useMemo} from 'react';
import {useQuery} from 'react-query';
import {evolutionAnalyticsService} from 'services';
import dayjs from 'dayjs';
import classNames from 'classnames';
import LineChart from 'components/Charts/LineChart';
import {Swaler} from 'swaler';
import {generateDatesArray} from '../..';
import {object, string} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  evolution: object.isRequired,
  selectedSegment: string,
};

const defaultProps = {
  selectedSegment: null,
};

const logger = new Swaler('CompletionChart');

const CompletionChart = ({evolution, timeRange}) => {
  const [startDate, endDate] = timeRange;

  const {
    data: analyticsHourly,
    isLoading: isLoadingAnalyticsHourly,
    refetch: refetchAnalyticsHourly,
  } = useQuery({
    queryKey: ['analyticsHourly', evolution.uid, startDate, endDate],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourly({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        evolutionIds: [evolution.uid],
      });
    },
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  useEffect(() => {
    refetchAnalyticsHourly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);

  const datesArr = generateDatesArray(startDate, endDate).filter((d) =>
    dayjs(d).isBefore(dayjs().endOf('day'))
  );

  // use this if we ultimately want to be able to filter the series on segments
  // we will have to be careful of performance here probably
  const series = useMemo(() => {
    return datesArr.reduce((acc, cur) => {
      const date = cur.format('YYYY-MM-DD');

      const foundData = analyticsHourly?.filter((d) => {
        return dayjs.unix(d.createdAt / 1000).isSame(cur, 'day');
      });

      if (acc[0] == null) {
        acc[0] = [];
      }
      let dataValue = 0;
      if (foundData?.length > 0) {
        const {surveyCount, surveyCompletedCount} = foundData.reduce(
          (acc, cur) => {
            acc.surveyCount += parseInt(cur.sum_survey_count, 10);
            acc.surveyCompletedCount += parseInt(
              cur.sum_survey_completed_count,
              10
            );
            return acc;
          },
          {surveyCount: 0, surveyCompletedCount: 0}
        );
        dataValue =
          surveyCount > 0
            ? parseInt((surveyCompletedCount * 100) / surveyCount)
            : 0;
      }
      acc[0].push({
        date,
        value: dataValue,
        evolutionId: evolution.uid,
        label: '',
      });

      return acc;
    }, []);
  }, [datesArr, evolution, analyticsHourly]);

  return (
    <div className="completion-chart-wrapper">
      <div
        className={classNames('completion-chart', {
          empty: series[0] == null,
        })}>
        {series[0] != null ? (
          <LineChart data={series.flat()} series={series} yUnit="%" />
        ) : (
          <div className="no-data">No data to show</div>
        )}
      </div>
    </div>
  );
};

CompletionChart.propTypes = propTypes;
CompletionChart.defaultProps = defaultProps;

export default CompletionChart;
