import Button from 'components/Button';
import {func} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  onSelectElementClick: func.isRequired,
  onNavigateClick: func.isRequired,
};

const TargetNotFoundWarning = ({
  type,
  onSelectElementClick,
  onNavigateClick,
}) => {
  return (
    <div className="target-not-found">
      <div className="icon-wrapper">
        <i className="icon-exclamation-triangle-o" />
      </div>
      Your {type} is not found on this page
      <Button thin iconLeft="icon-target" onClick={onSelectElementClick}>
        Reposition {type}
      </Button>
      <Button thin primary iconLeft="icon-pointer" onClick={onNavigateClick}>
        Navigate to it
      </Button>
    </div>
  );
};

TargetNotFoundWarning.propTypes = propTypes;

export default TargetNotFoundWarning;
