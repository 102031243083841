import {func} from 'prop-types';
import {Swaler} from 'swaler';

// Builder actions
const LOADED = 'loaded';
const CLOSE = 'close';
const SKIP = 'skip';
const OPEN_WIDGET = 'open-widget';
const SHOW_IMAGE = 'show-image';
const TOGGLE_PREVIEW = 'toggle-preview';
const TOGGLE_BOOKING = 'toggle-booking';
const DOT_TO_SNIPPET = 'dot-to-snippet';
const REQUEST_POKE = 'REQUEST_POKE';
const CURRENT_URL_REQUEST = 'CURRENT_URL_REQUEST';

const logger = new Swaler('useStormwindPokeMessenger');

const propTypes = {
  onLoaded: func.isRequired,
  onClose: func.isRequired,
  onOpenWidget: func,
  onShowImage: func.isRequired,
  onTogglePreview: func.isRequired,
  onToggleBooking: func.isRequired,
  onDotToSnippet: func.isRequired,
  onRequestPoke: func,
};

const defaultProps = {
  onOpenWidget: () => {},
  onRequestPoke: () => {},
};

const useStormwindPokeMessenger = ({
  onLoaded,
  onClose,
  onOpenWidget,
  onShowImage,
  onTogglePreview,
  onToggleBooking,
  onDotToSnippet,
  onRequestPoke,
  onRequestCurrentUrl,
  onSkip,
}) => {
  const handleMessage = ({message}) => {
    try {
      const m = JSON.parse(message);

      if (m.action === LOADED) {
        onLoaded(m.evolutionId);
      }
      if (m.action === CLOSE) {
        onClose(m.evolutionId);
      }
      if (m.action === SKIP) {
        onSkip();
      }
      if (m.action === OPEN_WIDGET) {
        onOpenWidget(m.evolutionId);
      }
      if (m.action === SHOW_IMAGE) {
        onShowImage(m.src);
      }
      if (m.action === TOGGLE_PREVIEW) {
        onTogglePreview(m.value, m.evolutionId);
      }
      if (m.action === TOGGLE_BOOKING) {
        onToggleBooking(m.link);
      }
      if (m.action === DOT_TO_SNIPPET) {
        onDotToSnippet(m.evolutionId);
      }
      if (m.action === REQUEST_POKE) {
        onRequestPoke(m.evolutionId);
      }
      if (m.action === CURRENT_URL_REQUEST) {
        onRequestCurrentUrl();
      }
    } catch (err) {
      logger.error(`Couldn't parse message sent by widget: ${err}`);
    }
  };

  return {handleMessage};
};

useStormwindPokeMessenger.propTypes = propTypes;
useStormwindPokeMessenger.defaultProps = defaultProps;

export default useStormwindPokeMessenger;
