export const EVENT_CREATE_PUSH = 'CREATE_PUSH';
export const EVENT_ADD_BLOCK = 'ADD_BLOCK';
export const EVENT_SELECT_CHANNEL = 'SELECT_CHANNEL';
export const EVENT_LAUNCH_PUSH = 'LAUNCH_PUSH';
export const EVENT_NEW_SEGMENT_CLICK = 'NEW_SEGMENT_CLICK';
export const EVENT_NEW_SEGMENT_CREATED = 'NEW_SEGMENT_CREATED';
export const EVENT_VIEW_PUSH_INSIGHTS = 'VIEW_PUSH_INSIGHTS';
export const EVENT_VIEW_PUSH_ANALYTICS = 'VIEW_PUSH_ANALYTICS';
export const EVENT_VIEW_WIDGET_INSTALLATION = 'VIEW_WIDGET_INSTALLATION';
export const EVENT_PAUSE_POKE = 'PAUSE_POKE';
export const EVENT_PLAY_POKE = 'PLAY_POKE';
export const EVENT_DUPLICATE_EVOLUTION = 'DUPLICATE_EVOLUTION';
export const EVENT_DELETE_EVOLUTION = 'DELETE_EVOLUTION';
export const EVENT_EDIT_EVOLUTION = 'EDIT_EVOLUTION';

// Not used yet
export const EVENT_CUSTOMIZE_APPEARANCE = 'CUSTOMIZE_APPEARANCE';

// Used to verify data integrity

export const EVENT_DELETE_PROJECT = 'DELETE_PROJECT';

export const EVENT_CLICK_TEMPLATE = 'CLICK_TEMPLATE';
export const EVENT_CREATE_EXPERIENCE = 'CREATE_EXPERIENCE';
