import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, reverseFlag} from 'helpers/bitwise';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_ONBOARDING_DETAILS_STEP_1_2} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {meService} from 'services';
import {
  ME_F_ONBOARDING_DONE_STEP_1_1,
  ME_F_WDYD_CE,
  ME_F_WDYD_CS,
  ME_F_WDYD_OTHER,
  ME_F_WDYD_PD,
  ME_F_WDYD_PM,
  ME_F_WDYD_PMM,
} from 'services/me';
import {Swaler} from 'swaler';
import './step-1-1.scss';

const WDYDItems = [
  {
    flag: ME_F_WDYD_PM,
    label: 'Product management',
    icon: 'icon-usage-portal',
    color: '#E6FDE8',
  },
  {
    flag: ME_F_WDYD_CE,
    label: 'Customer experience',
    icon: 'icon-emoji',
    color: '#FDEFE6',
  },
  {
    flag: ME_F_WDYD_PD,
    label: 'Product design',
    icon: 'icon-filter-preview',
    color: '#fde6e6',
  },
  {
    flag: ME_F_WDYD_PMM,
    label: 'Product marketing',
    icon: 'icon-filter-announcement',
    color: '#ECE6FD',
  },
  {
    flag: ME_F_WDYD_CS,
    label: 'Customer support',
    icon: 'icon-user-interview',
    color: '#c9d9ff',
  },
  {
    flag: ME_F_WDYD_OTHER,
    label: 'Other',
    icon: 'icon-radiobox-o',
    color: '#E6FDE8',
  },
];

const fromOptions = [
  {label: 'Outreach (Email, Linkedin)', value: 'email'},
  {label: 'Search Engine (e.g., Google, Bing)', value: 'search_engine'},
  {label: 'Linkedin', value: 'linkedin'},
  {label: 'Slack group', value: 'slack_group'},
  {label: 'Online Ads', value: 'online_ads'},
  {label: 'Friend or Colleague', value: 'friend_or_colleague'},
  {label: 'Event/Conference', value: 'event_or_conference'},
  {label: 'Blog/Article', value: 'blog_or_article'},
  {label: 'Review Site (G2 & others)', value: 'review_site'},
  {label: 'Other (Please specify)', value: 'other'},
];

const logger = new Swaler('Onboarding/1-1');

export const OnboardingDetailsStep11 = ({triggerSquareAnimationOut}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {identify} = useJimoIdentify();

  const uptUser = (updatedUser) =>
    dispatch(generalActions.uptUser(updatedUser));

  const user = useSelector((state) => generalSelector.getUser(state));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [wdyd, setWDYD] = useState(user.wdydFlags);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState('');
  const [fromOption, setFromOption] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const onboardingFlags = addFlag(
        ME_F_ONBOARDING_DONE_STEP_1_1,
        user.onboardingFlags
      );
      const updatedUser = await meService.updateMe({
        wdydFlags: wdyd,
        firstName,
        lastName,
        fromWhere: fromOption === 'other' ? from : fromOption,
      });
      await meService.updateOnboardingFlag(onboardingFlags);
      uptUser({...updatedUser, onboardingFlags});
      setPlayAnimationOut(true);
      triggerSquareAnimationOut();
      setTimeout(() => {
        history.push(ROUTE_ONBOARDING_DETAILS_STEP_1_2);
      }, 1200);
      identify(updatedUser);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating user failed with error ', code);
      toastDanger([title, message], {actions});
      setIsSubmitting(false);
    }
  };

  const canContinue = firstName.length > 0 && lastName.length > 0 && wdyd > 0;

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-step-1-1',
        {
          'is-exiting': playAnimationOut === true,
        }
      )}>
      <form onSubmit={handleSubmit}>
        <h1>
          Welcome! <br />
          Tell us a little about you
        </h1>
        <p>Your teammates will recognize you in Jimo</p>
        <div className="form-label">What's your name?</div>
        <div className="inputs-wrapper">
          <Input
            placeholder="John"
            required
            value={firstName}
            onChange={({target}) => setFirstName(target.value)}></Input>
          <Input
            placeholder="Doe"
            required
            value={lastName}
            onChange={({target}) => setLastName(target.value)}></Input>
        </div>
        <div className="form-label">How did you find us?</div>
        <SelectGroup
          className="source-select"
          options={fromOptions}
          value={fromOptions.find((o) => o.value === fromOption)}
          onChange={(option) => setFromOption(option.value)}
        />
        {fromOption === 'other' && (
          <Input
            placeholder="In a few words"
            required
            value={from}
            onChange={({target}) => setFrom(target.value)}
          />
        )}
        <div className="form-label">What do you do?</div>
        <div className="usages-wrapper">
          {WDYDItems.map((item) => {
            const isActive = hasFlag(item.flag, wdyd);

            return (
              <div
                key={item.flag}
                className={classnames('usage-item', {
                  active: isActive,
                })}
                onClick={() => {
                  setWDYD(reverseFlag(item.flag, wdyd));
                }}>
                <div
                  className="usage-icon-wrapper"
                  style={isActive ? {backgroundColor: item.color} : {}}>
                  <i className={item.icon}></i>
                </div>
                {item.label}
              </div>
            );
          })}
        </div>
        <Button
          loading={isSubmitting}
          disabled={canContinue === false}
          cta
          primary
          secondary
          iconRight="icon-chevron-right">
          Continue
        </Button>
      </form>
    </div>
  );
};
