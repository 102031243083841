import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  PersonAdd,
} from '@styled-icons/material-rounded';
import {MARK_BOLD, MARK_ITALIC, MARK_UNDERLINE} from '@udecode/plate';
import {getBlockAbove, isEndPoint, moveSelection} from '@udecode/plate-common';
import Divider from 'components/Divider';
import Label from 'components/Label';
import {useMyPlateEditorRef} from 'components/MarkdownEditor/utils';
import React from 'react';
import {insertText, Transforms} from 'slate';
import {v4 as uuidv4} from 'uuid';
import {ELEMENT_VARIABLE} from '../../plugins/VariablePlugin';
import EmojiDropdownMenu from '../EmojiToolbarDropdown';
import {LinkToolbarButton} from '../LinkToolbarButton';
import MarkToolbarButton from '../MarkToolbarButton';
import './_Styles.scss';

const Icon = ({icon}) => {
  return (
    <div className="icon-wrapper">
      <i className={icon} />
    </div>
  );
};

const VariableButton = () => {
  const editor = useMyPlateEditorRef();

  const insertVariable = () => {
    const variable = {
      uid: uuidv4(),
      type: ELEMENT_VARIABLE,
      children: [{text: ''}],
      attribute: null,
      attributeSource: null,
    };
    const textNode = {text: ' '};

    Transforms.insertNodes(editor, [variable, textNode]);

    moveSelection(editor, {unit: 'offset'});

    const pathAbove = getBlockAbove(editor)?.[1];

    const isBlockEnd =
      editor.selection &&
      pathAbove &&
      isEndPoint(editor, editor.selection.anchor, pathAbove);

    if (isBlockEnd) {
      insertText(editor, ' ');
    }
  };

  return (
    <MarkToolbarButton
      className="slate-toolbar-button variable-button"
      onClick={(event) => {
        event.preventDefault();
        insertVariable();
      }}
      nodeType={ELEMENT_VARIABLE}>
      <PersonAdd />
      <Label secondary size="x-small" type="negative">
        New
      </Label>
    </MarkToolbarButton>
  );
};

const BasicEditorToolbar = () => {
  return (
    <div className="basic-mark-toolbar">
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Bold'}
        nodeType={MARK_BOLD}>
        <FormatBold />
      </MarkToolbarButton>
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Italic'}
        nodeType={MARK_ITALIC}>
        <FormatItalic />
      </MarkToolbarButton>
      <MarkToolbarButton
        prefixClassNames="toolbar-button"
        tooltip={'Underline'}
        nodeType={MARK_UNDERLINE}>
        <FormatUnderlined />
      </MarkToolbarButton>
      <Divider vertical />
      <LinkToolbarButton />
      <EmojiDropdownMenu />
      <Divider vertical />
      <VariableButton />
    </div>
  );
};

export default BasicEditorToolbar;
