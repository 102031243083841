import Axios from 'axios';
import {generalSelector} from 'selectors';

export const TRACKER_TYPE_ACTIVATION_TRACKER = 'ACTIVATION_TRACKER';
export const TRACKER_TYPE_FEATURE_USAGE = 'FEATURE_USAGE';
export const TRACKER_TYPE_GOAL = 'GOAL';

// Endpoint
const EP_TRACKER_GET = '/tracker';
const EP_TRACKER_GET_BY_ID = (trackerId) => `/tracker/${trackerId}`;
const EP_TRACKER_POST_CREATE = '/tracker';
const EP_TRACKER_PUT_UPDATE = (trackerId) => `/tracker/${trackerId}`;
const EP_TRACKER_DELETE = (trackerId) => `/tracker/${trackerId}`;

// Method
export const getTrackers = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_TRACKER_GET, {
    params: {projectId},
  }).then((response) => response.data);
};

export const getTracker = (data) => {
  const {trackerId} = data;

  return Axios.get(EP_TRACKER_GET_BY_ID(trackerId)).then(
    (response) => response.data
  );
};

export const createTracker = (data) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_TRACKER_POST_CREATE, {
    ...data,
    projectId,
  }).then((response) => response.data);
};

export const updateTracker = (trackerId, data) => {
  return Axios.put(EP_TRACKER_PUT_UPDATE(trackerId), data).then(
    (response) => response.data
  );
};

export const deleteTracker = (trackerId) => {
  return Axios.delete(EP_TRACKER_DELETE(trackerId)).then(
    (response) => response.data
  );
};
