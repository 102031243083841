import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

export function Menu(props) {
  const {className, children, ...rest} = props;
  const classNames = classnames('menu', className);

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
}

export function MenuItem(props) {
  let {
    className,
    children,
    icon,
    iconPosition = icon != null ? 'left' : null,
    badge,
    active,
    interactive = true,
    isExternalLink = false,
    ...rest
  } = props;
  const classNames = classnames('menu-item', className, {
    'has-icon-left': iconPosition === 'left',
    'has-icon-right': iconPosition === 'right',
    'is-active': active,
    'is-interactive': interactive,
  });

  if (icon != null) {
    icon =
      typeof icon === 'string' ? (
        <div className="icon-wrapper">
          <i className={icon} />
        </div>
      ) : (
        icon
      );
  }
  return (
    <div className={classNames} {...rest}>
      {iconPosition != null && iconPosition === 'left' && icon}
      {children}
      {iconPosition != null && iconPosition === 'right' && icon}
      {badge != null && <div className="badge">{badge}</div>}
      {isExternalLink === true && <i className="isax isax-export-3"></i>}
    </div>
  );
}

export function MenuSection(props) {
  const {children, className} = props;
  const classNames = classnames('menu-section', className);

  return <div className={classNames}>{children}</div>;
}
