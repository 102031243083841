import Divider from 'components/Divider';
import {Menu, MenuItem, MenuSection} from 'components/Menu';
import {crispHelpers} from 'helpers';
import {func, string} from 'prop-types';
import React from 'react';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {templates as templatesFn} from '../../templates';
import './_Styles.scss';

const propTypes = {
  type: string,
  useCase: string,
  onChange: func,
};

const defaultProps = {
  type: null,
  useCase: null,
  onChange: () => {},
};

const Sidebar = ({type, useCase, onChange}) => {
  const templates = templatesFn();

  const tags = templates.reduce((acc, template) => {
    const {tags = []} = template;
    tags.forEach((tag) => {
      if (!acc.includes(tag)) {
        acc.push(tag);
      }
    });
    return acc;
  }, []);

  return (
    <div className="templates-sidebar">
      <div className="templates-sidebar-content">
        <div className="menu-wrapper">
          <Menu>
            <MenuItem
              className="body-3 n-700"
              active={type == null && useCase == null}
              onClick={() => onChange({type: null, useCase: null})}>
              All templates
              <div className="count">{templates.length}</div>
            </MenuItem>
            <Divider />
            <MenuSection>Type</MenuSection>
            <div className="section-content">
              <MenuItem
                className="body-3 n-700"
                icon={<i className="isax isax-routing-2" />}
                active={type === EVOLUTION_TYPE_TOUR}
                onClick={() => onChange({type: EVOLUTION_TYPE_TOUR})}>
                Tours
                <div className="count">
                  {
                    templates.filter(
                      (t) => t.experienceType === EVOLUTION_TYPE_TOUR
                    ).length
                  }
                </div>
              </MenuItem>
              <MenuItem
                className="body-3 n-700"
                icon={<i className="isax isax-note-2" />}
                active={type === EVOLUTION_TYPE_SURVEY}
                onClick={() => onChange({type: EVOLUTION_TYPE_SURVEY})}>
                Surveys
                <div className="count">
                  {
                    templates.filter(
                      (t) => t.experienceType === EVOLUTION_TYPE_SURVEY
                    ).length
                  }
                </div>
              </MenuItem>
              <MenuItem
                className="body-3 n-700"
                icon={<i className="icon-slot-top-bar" />}
                active={type === EVOLUTION_TYPE_BANNER}
                onClick={() => onChange({type: EVOLUTION_TYPE_BANNER})}>
                Banners
                <div className="count">
                  {
                    templates.filter(
                      (t) => t.experienceType === EVOLUTION_TYPE_BANNER
                    ).length
                  }
                </div>
              </MenuItem>
              <MenuItem
                className="body-3 n-700"
                icon={<i className="icon-slot-dot" />}
                active={type === EVOLUTION_TYPE_HINT}
                onClick={() => onChange({type: EVOLUTION_TYPE_HINT})}>
                Hints
                <div className="count">
                  {
                    templates.filter(
                      (t) => t.experienceType === EVOLUTION_TYPE_HINT
                    ).length
                  }
                </div>
              </MenuItem>
            </div>
            <Divider />
            <MenuSection>Use Cases</MenuSection>

            <div className="section-content">
              {tags.map((tag) => (
                <MenuItem
                  className="body-3 n-700"
                  active={useCase === tag}
                  onClick={() => onChange({useCase: tag})}>
                  {tag}
                  <div className="count">
                    {templates.filter((t) => t.tags.includes(tag)).length}
                  </div>
                </MenuItem>
              ))}
            </div>
          </Menu>
        </div>
      </div>
      <div className="templates-sidebar-footer">
        <Menu>
          <MenuItem
            className="body-3 n-700"
            icon={<i className="icon-question-circle-o" />}
            onClick={() => {
              crispHelpers.startCrispThread(
                "Hey, i'd like to suggest a new template. :-)"
              );
            }}>
            Request a template
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
