export const hasFlag = (flagToFind, flags) => {
  return (flags & flagToFind) !== 0;
};
export const hasFlags = (flagsToFind, flags, atLeastOne = false) => {
  if (atLeastOne === true) {
    return flagsToFind.some((f) => hasFlag(f, flags) === true);
  }
  return flagsToFind.every((f) => hasFlag(f, flags) === true);
};
export const addFlag = (flagToAdd, flags) => {
  [].concat(flagToAdd).forEach((f) => (flags |= f));

  return flags;
};
export const removeFlag = (flagToRemove, flags) => {
  [].concat(flagToRemove).forEach((f) => (flags &= ~f));

  return flags;
};
export const reverseFlag = (flagToReverse, flags) => {
  return hasFlag(flagToReverse, flags) === true
    ? removeFlag(flagToReverse, flags)
    : addFlag(flagToReverse, flags);
};
