import classNames from 'classnames';
import Divider from 'components/Divider';
import {func, object, string} from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {ButtonStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Button';
import {ChecklistDismissStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/ChecklistDismiss';
import {ChecklistHeaderStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/ChecklistHeader';
import {ChecklistItemCheckboxStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/ChecklistItemCheckbox';
import {ChecklistTaskItemStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/ChecklistListItem';
import {ChecklistProgressStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/ChecklistProgress';
import {ConceptStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Concept';
import {DismissCrossStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/DismissCross';
import {
  HOTSPOT_SHAPE_DEFAULT,
  HotspotStyleSection,
} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hotspot';
import {InterviewStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Interview';
import {MediaStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Media';
import {MultipleChoiceStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/MultipleChoice';
import {NpsStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Nps';
import {OpenQuestionStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/OpenQuestion';
import {OpinionScaleStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/OpinionScale';
import {ParagraphStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Paragraph';
import {SliderStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Slider';
import {StepperStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Stepper';
import {TagStyle} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Tag';
import {TitleStyleSection} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Title';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
} from 'services/steps';
import ChecklistStyle from '../ChecklistStyle';
import StepStyle from '../StepStyle';
import './_Styles.scss';

export const defaultHotspotStyle = `#1260EB;22;${HOTSPOT_SHAPE_DEFAULT}`;

const propTypes = {
  poke: object.isRequired,
  theme: object.isRequired,
  setTheme: func,
  selectedBlockType: string,
  setSelectedBlockType: func,
  onGoBack: func,
};

const defaultProps = {
  setTheme: () => {},
  selectedBlockType: null,
  setSelectedBlockType: () => {},
  onGoBack: () => {},
};

const ThemeEditor = ({
  poke,
  theme,
  setTheme,
  selectedBlockType,
  setSelectedBlockType,
  onGoBack,
}) => {
  const onThemeChange = (blockType, style) => {
    const newTheme = {
      ...theme,
      style: {
        ...theme.style,
        ...(blockType == null
          ? {
              stepStyle: style,
            }
          : {
              blocksStyle: {
                ...theme.style.blocksStyle,
                [blockType]: {
                  ...theme.style.blocksStyle?.[blockType],
                  style,
                },
              },
            }),
      },
    };
    setTheme(newTheme);
  };

  const {style} = theme ?? {};
  const {stepStyle, blocksStyle} = style ?? {
    stepStyle: null,
    blocksStyle: null,
  };

  const blocksObj = [
    {
      type: BLOCK_TYPE_DISMISS_CROSS,
      name: 'Dismiss',
      styleComponent: (
        <DismissCrossStyle
          style={
            blocksStyle?.[BLOCK_TYPE_DISMISS_CROSS]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_DISMISS_CROSS, style)
          }
        />
      ),
    },
    {
      type: 'HOTSPOT',
      name: 'Hotspot',
      styleComponent: (
        <HotspotStyleSection
          style={blocksStyle?.['HOTSPOT']?.style ?? defaultHotspotStyle}
          updateStyle={(style) => onThemeChange('HOTSPOT', style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_TITLE,
      name: 'Heading',
      styleComponent: (
        <TitleStyleSection
          style={
            blocksStyle?.[BLOCK_TYPE_TITLE]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_TITLE).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_TITLE, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_BODY,
      name: 'Paragraph',
      styleComponent: (
        <ParagraphStyle
          style={
            blocksStyle?.[BLOCK_TYPE_BODY]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_BODY).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_BODY, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_LABEL,
      name: 'Label',
      styleComponent: (
        <TagStyle
          style={
            blocksStyle?.[BLOCK_TYPE_LABEL]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_LABEL).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_LABEL, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_MEDIA,
      name: 'Image/Video',
      styleComponent: (
        <MediaStyle
          style={
            blocksStyle?.[BLOCK_TYPE_MEDIA]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_MEDIA).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_MEDIA, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_PRIMARY_CTA,
      name: 'Primary CTA',
      styleComponent: (
        <ButtonStyle
          style={
            blocksStyle?.[BLOCK_TYPE_PRIMARY_CTA]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_PRIMARY_CTA, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_SECONDARY_CTA,
      name: 'Secondary CTA',
      styleComponent: (
        <ButtonStyle
          style={
            blocksStyle?.[BLOCK_TYPE_SECONDARY_CTA]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_SECONDARY_CTA).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_SECONDARY_CTA, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_STEPPER,
      name: 'Stepper',
      styleComponent: (
        <StepperStyle
          style={
            blocksStyle?.[BLOCK_TYPE_STEPPER]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_STEPPER).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_STEPPER, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHOICE,
      name: 'Multiple Choice',
      styleComponent: (
        <MultipleChoiceStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHOICE]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHOICE).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_CHOICE, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_SLIDER,
      name: 'Slider',
      styleComponent: (
        <SliderStyle
          style={
            blocksStyle?.[BLOCK_TYPE_SLIDER]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_SLIDER).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_SLIDER, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_NPS,
      name: 'NPS',
      styleComponent: (
        <NpsStyle
          style={
            blocksStyle?.[BLOCK_TYPE_NPS]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_NPS).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_NPS, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_OPINION,
      name: 'Opinion Scale',
      styleComponent: (
        <OpinionScaleStyle
          style={
            blocksStyle?.[BLOCK_TYPE_OPINION]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_OPINION).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_OPINION, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_OPEN_QUESTION,
      name: 'Open Question',
      styleComponent: (
        <OpenQuestionStyle
          style={
            blocksStyle?.[BLOCK_TYPE_OPEN_QUESTION]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_OPEN_QUESTION).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_OPEN_QUESTION, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_CONCEPT,
      name: 'Concept Test',
      styleComponent: (
        <ConceptStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CONCEPT]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CONCEPT).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_CONCEPT, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_INTERVIEW,
      name: 'Interview',
      styleComponent: (
        <InterviewStyle
          style={
            blocksStyle?.[BLOCK_TYPE_INTERVIEW]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_INTERVIEW).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_INTERVIEW, style)}
        />
      ),
    },
  ];

  const checklistBlocksObj = [
    {
      type: BLOCK_TYPE_CHECKLIST,
      name: 'Base',
      styleComponent: (
        <ChecklistStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST).style
          }
          updateStyle={({style}) => onThemeChange(BLOCK_TYPE_CHECKLIST, style)}
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_HEADER,
      name: 'Header',
      styleComponent: (
        <ChecklistHeaderStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_HEADER]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_HEADER).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_HEADER, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
      name: 'Title',
      styleComponent: (
        <TitleStyleSection
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_HEADER_TITLE]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_HEADER_TITLE).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_HEADER_TITLE, style)
          }
          hideAlign
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
      name: 'Description',
      styleComponent: (
        <ParagraphStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION)
              .style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION, style)
          }
          hideAlign
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
      name: 'Progress',
      styleComponent: (
        <ChecklistProgressStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_DISMISS,
      name: 'Dismiss',
      styleComponent: (
        <ChecklistDismissStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_DISMISS]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_DISMISS).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_DISMISS, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM,
      name: 'Task Item',
      styleComponent: (
        <ChecklistTaskItemStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_TASK_ITEM).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
      name: 'Checkbox',
      styleComponent: (
        <ChecklistItemCheckboxStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX)
              .style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX, style)
          }
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
      name: 'Task Title',
      styleComponent: (
        <TitleStyleSection
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE, style)
          }
          hideAlign
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
      name: 'Task Description',
      styleComponent: (
        <ParagraphStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION)
              .style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION, style)
          }
          hideAlign
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
      name: 'Task Media',
      styleComponent: (
        <MediaStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA, style)
          }
          hidePosition
          hidePadding
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
      name: 'Primary CTA',
      styleComponent: (
        <ButtonStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA]?.style ??
            getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA)
              .style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA, style)
          }
          hideAlign
        />
      ),
    },
    {
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
      name: 'Secondary CTA',
      styleComponent: (
        <ButtonStyle
          style={
            blocksStyle?.[BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA]
              ?.style ??
            getDefaultBlockFromType(
              BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA
            ).style
          }
          updateStyle={({style}) =>
            onThemeChange(BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA, style)
          }
          hideAlign
        />
      ),
    },
  ];

  return (
    <div className="theme-editor">
      <div className="style-groups">
        <CollapsibleStyleGroup
          name="Step"
          content={
            <StepStyle
              poke={poke}
              style={stepStyle}
              setStyle={(style) => onThemeChange(null, style)}
            />
          }
          expanded={selectedBlockType === null}
          setExpanded={() => {
            if (selectedBlockType === 'step') {
              setSelectedBlockType(null);
              return;
            }
            setSelectedBlockType(null);
          }}
        />
        {blocksObj.map((blockObj) => {
          return (
            <CollapsibleStyleGroup
              key={blockObj.type}
              name={blockObj.name}
              content={blockObj.styleComponent}
              expanded={selectedBlockType === blockObj.type}
              setExpanded={() => {
                if (selectedBlockType === blockObj.type) {
                  setSelectedBlockType(null);
                  return;
                }
                setSelectedBlockType(blockObj.type);
              }}
            />
          );
        })}
        <Divider />
        <div className="style-section-title body-3 n-700">Checklist</div>
        {checklistBlocksObj.map((blockObj) => {
          return (
            <CollapsibleStyleGroup
              key={blockObj.type}
              name={blockObj.name}
              content={blockObj.styleComponent}
              expanded={selectedBlockType === blockObj.type}
              setExpanded={() => {
                if (selectedBlockType === blockObj.type) {
                  setSelectedBlockType(null);
                  return;
                }
                setSelectedBlockType(blockObj.type);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

ThemeEditor.propTypes = propTypes;
ThemeEditor.defaultProps = defaultProps;

export const CollapsibleStyleGroup = ({
  name,
  content,
  expanded,
  setExpanded,
}) => {
  return (
    <div
      className={classNames('collapsible-style-group', {
        'is-expanded': expanded === true,
      })}
    >
      <div className="collapsible-style-group-header" onClick={setExpanded}>
        <div className="collapsible-style-group-title n-700">{name}</div>
        <i className={classNames('icon', 'icon-chevron-right')} />
      </div>
      <AnimateHeight height={expanded === true ? 'auto' : 0} duration={300}>
        <div className="item-body">
          <div className="collapsible-style-group-content">
            <div className="block-settings group">
              <div className="section">
                <div className="section-content">{content}</div>
              </div>
            </div>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default ThemeEditor;
