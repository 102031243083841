import classnames from 'classnames';
import {useEffect, useState} from 'react';
import avatarSeazon from './imgs/avatar-seazon.webp';
import avatarTTD from './imgs/avatar-toktokdoc.webp';
import avatarWaalaxy from './imgs/avatar-waalaxy.webp';
import logoSeazon from './imgs/logo-seazon.webp';
import logoTTD from './imgs/logo-toktokdoc.webp';
import logoWaalaxy from './imgs/logo-waalaxy.webp';
import './_testimonials.scss';

const TESTIMONIALS = [
  {
    logo: logoWaalaxy,
    content:
      'We love Jimo at Waalaxy. It’s a simple and effective way to keep your users informed and include them in the development of your product. Our users are really happy to see the progress of Waalaxy.What I like the most is using the feed + notification, which allows me to contact my users without being too aggressive.',
    avatar: avatarWaalaxy,
    name: 'Chloé Dalger',
    role: 'Product manager',
  },
  {
    logo: logoTTD,
    content:
      'Jimo makes it easy to create engaging communications for our users but also to keep internal teams informed (sales, customer success). It thus makes it possible to highlight the work of the entire Product-IT team and to create/strengthen the link with our users.',
    avatar: avatarTTD,
    name: 'Anaïs Arredondo',
    role: 'CPO',
  },
  {
    logo: logoSeazon,
    content:
      'Jimo allowed us to set up in less than 10min an engaging solution to communicate the regular evolutions of our functionalities to our Customers, via a hyper-contextual channel, directly integrated into the product and its use.',
    avatar: avatarSeazon,
    name: 'Laurent Partouche',
    role: 'CPO',
  },
];

export const RegisterTestimonials = ({playAnimationOut}) => {
  const [playAnimation, setPlayAnimation] = useState(null);
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  useEffect(() => {
    if (playAnimationOut != null) {
      setPlayAnimation(playAnimationOut);
    }
  }, [playAnimationOut]);

  const handleNext = () => {
    if (canGoNext === false || playAnimation != null) {
      return;
    }
    setPlayAnimation('next-out');
    setTimeout(() => {
      setTestimonialIndex(testimonialIndex + 1);
      setPlayAnimation('next-in');
      setTimeout(() => {
        setPlayAnimation(null);
      }, 500);
    }, 1000);
  };
  const handlePrev = () => {
    if (canGoPrev === false || playAnimation != null) {
      return;
    }
    setPlayAnimation('prev-out');
    setTimeout(() => {
      setTestimonialIndex(testimonialIndex - 1);
      setPlayAnimation('prev-in');
      setTimeout(() => {
        setPlayAnimation(null);
      }, 500);
    }, 1000);
  };

  const canGoNext = testimonialIndex < TESTIMONIALS.length - 1;
  const canGoPrev = testimonialIndex > 0;
  const testimonial = TESTIMONIALS[testimonialIndex];

  return (
    <div className="onboarding-register-testimonials">
      <div className="testimonials-list">
        <div
          className={classnames('item-testimonial', {
            [`anim-${playAnimation}`]: playAnimation != null,
          })}>
          <div className="logo-wrapper">
            <img src={testimonial.logo} alt="logo" height={24} />
          </div>
          <div className="content-wrapper">{testimonial.content}</div>
          <div className="person-wrapper">
            {/* <div className="avatar-wrapper"> */}
            <div
              className="testimonial-avatar"
              style={{backgroundImage: `url(${testimonial.avatar})`}}></div>
            {/* </div> */}
            <div className="person-name-role">
              {testimonial.name} <small>{testimonial.role}</small>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-navigation-wrapper">
        <i
          className={classnames('icon-arrow-left', {
            disabled: canGoPrev === false,
          })}
          onClick={handlePrev}></i>
        <i
          className={classnames('icon-arrow-right', {
            disabled: canGoNext === false,
          })}
          onClick={handleNext}></i>
      </div>
      <div className="testimonials-check-list">
        <div className="item-check">
          <i className="icon-tick active"></i>
          <p>Drives feature adoption with in-app tours feature announcement</p>
        </div>
        <div className="item-check">
          <i className="icon-tick"></i>
          <p>
            Uncover new product opportunities by asking the right questions with
            the best survey experience
          </p>
        </div>
        <div className="item-check">
          <i className="icon-tick"></i>
          <p>Keep users-in the loop with a collaborative changelog</p>
        </div>
      </div>
    </div>
  );
};
