import React, {useContext, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {trackerAnalyticsService} from 'services';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import classNames from 'classnames';
import {Swaler} from 'swaler';
import {TrackerContext} from '../..';
import './_Styles.scss';
import {generateDatesArray} from 'scenes/Poke/component/Statistics';
import TrackerBlock from '../TrackerBlock';
import StackedLineChart from './components/StackedLineChart';
import FrequencySelectionDropdown, {
  TIME_FREQUENCY_DAILY,
  TIME_FREQUENCY_MONTHLY,
  TIME_FREQUENCY_WEEKLY,
} from './components/FrequencySelectionDropdown';
import TimeFrameSelector, {
  CHART_TIME_FRAME_14_DAYS,
  CHART_TIME_FRAME_1_MONTH,
  CHART_TIME_FRAME_1_YEAR,
  CHART_TIME_FRAME_3_MONTHS,
  CHART_TIME_FRAME_6_MONTHS,
} from './components/TimeFrameSelector';

dayjs.extend(isoWeek);

const logger = new Swaler('FeatureUsageChart');

const FeatureUsageChart = () => {
  const {tracker} = useContext(TrackerContext);

  const [frequency, setFrequency] = useState(TIME_FREQUENCY_DAILY);
  const [timeFrame, setTimeFrame] = useState(CHART_TIME_FRAME_14_DAYS);

  const endDate = dayjs().endOf(frequency);
  const startDate = dayjs(endDate).subtract(timeFrame, 'day').toDate();

  const {data: analytics} = useQuery({
    queryKey: [
      'featureUsageStatsByDate',
      'tracker',
      tracker.uid,
      startDate,
      endDate,
    ],
    queryFn: async () => {
      const analytics = await trackerAnalyticsService.getAnalyticsDaily({
        trackerId: tracker.uid,
        startDate,
        endDate: dayjs(endDate).add(1, frequency).toDate(),
      });
      return analytics;
    },
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  const datesArr = generateDatesArray(startDate, endDate, frequency).filter(
    (d) => !d.startOf(frequency).isAfter(dayjs().startOf(frequency))
  );

  // use this if we ultimately want to be able to filter the series on segments
  // we will have to be careful of performance here probably
  const series = useMemo(() => {
    return datesArr.reduce((acc, cur) => {
      const date = cur.format('YYYY-MM-DD');
      const timestamp = cur.unix();

      const foundData = analytics?.filter((d) => {
        const analyticsDate = dayjs(parseInt(d.createdAt, 10));

        if (frequency === TIME_FREQUENCY_DAILY) {
          return analyticsDate.isSame(cur, 'day');
        } else if (frequency === TIME_FREQUENCY_WEEKLY) {
          return analyticsDate.isSame(cur, 'week');
        } else if (frequency === TIME_FREQUENCY_MONTHLY) {
          return analyticsDate.isSame(cur, 'month');
        }
        return false;
      });

      let count = 0;
      if (foundData?.length > 0) {
        count = foundData.reduce(
          (acc, cur) => (acc += parseInt(cur.count, 10)),
          0
        );
      }
      acc.push({
        date,
        timestamp,
        count,
      });

      return acc;
    }, []);
  }, [datesArr, analytics, frequency]);

  return (
    <TrackerBlock
      className="feature-usage-chart"
      header={
        <>
          <div className="title">
            <i className="isax isax-chart-1" />
            Daily usage
          </div>
          <div className="actions">
            <FrequencySelectionDropdown
              frequency={frequency}
              setFrequency={(fq) => {
                if (
                  fq === TIME_FREQUENCY_DAILY &&
                  [
                    CHART_TIME_FRAME_3_MONTHS,
                    CHART_TIME_FRAME_6_MONTHS,
                    CHART_TIME_FRAME_1_YEAR,
                  ].includes(timeFrame)
                ) {
                  setTimeFrame(CHART_TIME_FRAME_14_DAYS);
                } else if (
                  fq === TIME_FREQUENCY_WEEKLY &&
                  [CHART_TIME_FRAME_14_DAYS, CHART_TIME_FRAME_1_YEAR].includes(
                    timeFrame
                  )
                ) {
                  setTimeFrame(CHART_TIME_FRAME_1_MONTH);
                } else if (
                  fq === TIME_FREQUENCY_MONTHLY &&
                  [CHART_TIME_FRAME_14_DAYS, CHART_TIME_FRAME_1_MONTH].includes(
                    timeFrame
                  )
                ) {
                  setTimeFrame(CHART_TIME_FRAME_3_MONTHS);
                }
                setFrequency(fq);
              }}
            />
            <TimeFrameSelector
              timeFrame={timeFrame}
              setTimeFrame={setTimeFrame}
              omit={
                frequency === TIME_FREQUENCY_MONTHLY
                  ? [CHART_TIME_FRAME_14_DAYS, CHART_TIME_FRAME_1_MONTH]
                  : frequency === TIME_FREQUENCY_WEEKLY
                  ? [CHART_TIME_FRAME_14_DAYS, CHART_TIME_FRAME_1_YEAR]
                  : [
                      CHART_TIME_FRAME_3_MONTHS,
                      CHART_TIME_FRAME_6_MONTHS,
                      CHART_TIME_FRAME_1_YEAR,
                    ]
              }
            />
          </div>
        </>
      }>
      <div className="primary-chart-wrapper">
        <div
          className={classNames('primary-chart', {
            empty: series[0] == null,
          })}>
          <StackedLineChart
            tracker={tracker}
            data={series}
            frequency={frequency}
          />
        </div>
      </div>
    </TrackerBlock>
  );
};

export default FeatureUsageChart;
