import {useEffect, useRef, useState} from 'react';
import {ROUTE_FEED_EVOLUTION} from 'router/routes.const';
import {Swaler} from 'swaler';

const logger = new Swaler('Helpers/utils');

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export function getFirstImageLinkFromContent(content) {
  const dom = document.implementation.createHTMLDocument();
  let images = [];

  dom.body.innerHTML = content;
  images = dom.querySelectorAll('img');
  if (images.length === 0) {
    return null;
  }
  return images[0]?.getAttribute('src');
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const hasUsernameGenerated = (username = '') => {
  const nameSplit = username.split(' ');

  if (nameSplit.length !== 2) {
    return false;
  }
  if (nameSplit[0] !== 'Jimer') {
    return false;
  }
  if (nameSplit[1].length !== 4) {
    return false;
  }
  return true;
};

export const buildLinkToEvolution = (evolution, params = {}) => {
  const {toComments = false} = params;
  let link = ROUTE_FEED_EVOLUTION(evolution.uid);

  if (toComments === true) {
    link = `${link}#comments`;
  }
  return link;
};

export const hasSomeParentTheClass = (element, classname) => {
  if (element.classList?.contains(classname)) return true;
  return (
    element.parentNode && hasSomeParentTheClass(element.parentNode, classname)
  );
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const isActiveOnPath = (
  globalOperator = 'OR',
  pathRules,
  currentPath
) => {
  if (pathRules.length === 0) {
    return true;
  }
  if (globalOperator === 'OR') {
    for (const rule of pathRules) {
      switch (rule.operator) {
        case 'STARTS_WITH': {
          if (currentPath.startsWith(rule.path)) {
            return true;
          }
          break;
        }
        case 'DOESNT_START_WITH': {
          if (!currentPath.startsWith(rule.path)) {
            return true;
          }
          break;
        }
        case 'ENDS_WITH': {
          if (currentPath.endsWith(rule.path)) {
            return true;
          }
          break;
        }
        case 'DOESNT_END_WITH': {
          if (!currentPath.endsWith(rule.path)) {
            return true;
          }
          break;
        }
        case 'CONTAINS': {
          if (currentPath.includes(rule.path)) {
            return true;
          }
          break;
        }
        case 'DOESNT_CONTAIN': {
          if (!currentPath.includes(rule.path)) {
            return true;
          }
          break;
        }
        case 'EQUALS': {
          if (currentPath === rule.path) {
            return true;
          }
          break;
        }
        case 'DOESNT_EQUAL': {
          if (currentPath !== rule.path) {
            return true;
          }
          break;
        }
        case 'MATCHES_REGEX': {
          const re = new RegExp(rule.path);

          if (re.test(currentPath)) {
            return true;
          }
          break;
        }
        default: {
          break;
        }
      }
    }
    return false;
  } else if (globalOperator === 'AND') {
    for (const rule of pathRules) {
      switch (rule.operator) {
        case 'STARTS_WITH': {
          if (!currentPath.startsWith(rule.path)) {
            return false;
          }
          break;
        }
        case 'DOESNT_START_WITH': {
          if (currentPath.startsWith(rule.path)) {
            return false;
          }
          break;
        }
        case 'ENDS_WITH': {
          if (!currentPath.endsWith(rule.path)) {
            return false;
          }
          break;
        }
        case 'DOESNT_END_WITH': {
          if (currentPath.endsWith(rule.path)) {
            return false;
          }
          break;
        }
        case 'CONTAINS': {
          if (!currentPath.includes(rule.path)) {
            return false;
          }
          break;
        }
        case 'DOESNT_CONTAIN': {
          if (currentPath.includes(rule.path)) {
            return false;
          }
          break;
        }
        case 'EQUALS': {
          if (currentPath !== rule.path) {
            return false;
          }
          break;
        }
        case 'DOESNT_EQUAL': {
          if (currentPath === rule.path) {
            return false;
          }
          break;
        }
        case 'MATCHES_REGEX': {
          const re = new RegExp(rule.path);

          if (!re.test(currentPath)) {
            return false;
          }
          break;
        }
        default: {
          break;
        }
      }
    }
    return true;
  }
};

export const isActiveOnDomain = (domainFilter, currentDomain) => {
  let error = null;

  if (domainFilter == null) {
    return {isActive: true, error};
  }

  const isDomain = domainFilter === currentDomain;
  const isInDomains = domainFilter.split(';').includes(currentDomain);
  let isMatchingRegex = false;
  try {
    isMatchingRegex = new RegExp(domainFilter, 'g').test(currentDomain);
  } catch (err) {
    logger.error('Trying to match regex failed with error ', err);
    error = err;
  }

  return {isActive: isDomain || isInDomains || isMatchingRegex, error};
};

const extensionId = 'koipflmbgiibbkcfccgpocdgifapofje';
// const extensionId = 'nfpldnkogdcnhcephaaeloecohppelni'; // Dev

export const useIsExtensionInstalled = () => {
  const [isInstalled, setIsInstalled] = useState(null);

  const check = async () => {
    if (window.chrome?.runtime?.sendMessage == null) {
      return setIsInstalled(false);
    }
    window.chrome.runtime.sendMessage(
      extensionId,
      {type: 'lookup_extension'},
      function (response) {
        if (response === 'jimo-extension-installed') {
          return setIsInstalled(true);
        } else {
          return setIsInstalled(false);
        }
      }
    );
  };

  useEffect(() => {
    check();
  }, []);

  return {isInstalled, check};
};

export const sendUrlToExtension = (url) => {
  window.chrome.runtime.sendMessage(
    extensionId,
    {type: 'load_extension', url},
    function (response) {}
  );
};

// Thanks you : https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome
export const isChrome = () => {
  var isChromium = window.chrome;
  var winNav = window.navigator;
  var vendorName = winNav.vendor;
  var isOpera = typeof window.opr !== 'undefined';
  var isIEedge = winNav.userAgent.indexOf('Edg') > -1;
  var isIOSChrome = winNav.userAgent.match('CriOS');

  if (isIOSChrome) {
    // is Google Chrome on IOS
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  } else {
    return false;
  }
};

export function isOpera() {
  const isChromium = window.chrome;
  const isOpera =
    window.navigator.userAgent.indexOf('OPR') > -1 ||
    window.navigator.userAgent.indexOf('Opera') > -1;

  if (isChromium !== null && isOpera === true) {
    return true;
  } else {
    return false;
  }
}

export const buildStormwindUrl = (baseUrl, path, query) => {
  const baseQuery = query?.length > 0 ? ''.concat('&').concat(query) : '';
  return `${baseUrl}${path}?${baseQuery}`;
};

export const randomInt = (minimum, maximum) => {
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
};

export const debounce = (func, delay) => {
  let debounceTimer;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const isArrayEqualMultiset = (A, B) => {
  if (A.length !== B.length) {
    return false;
  }

  const freq = {};
  for (const x of A) {
    if (freq[x] === undefined) {
      freq[x] = 0;
    }
    freq[x] += 1;
  }
  for (const x of B) {
    if (freq[x] === undefined || freq[x] < 0) {
      return false;
    }
    freq[x] -= 1;
  }

  for (const [_, v] of Object.entries(freq)) {
    if (v !== 0) {
      return false;
    }
  }
  return true;
};
