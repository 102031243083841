import {hasFlag} from 'helpers/bitwise';
import {F_BOOST_SLOT_DOT, F_BOOST_SLOT_TOOLTIP} from 'services/evolution';

const ANIMATION_FADE = 'fade';
const ANIMATION_SLIDE = 'slide';
const ANIMATION_GROW = 'grow';

export const getStyle = ({
  positionFlag,
  targetElementPosition,
  iframeDimensions,
  isHotspotMode = false,
  positionOffsets = '0;0',
  boostFlags,
}) => {
  if (window?.jimoDebug?.getStyle === false) {
    return;
  }
  const style = {
    position: targetElementPosition != null ? 'absolute' : 'fixed',
    width: `${iframeDimensions.width}px`,
  };

  if (targetElementPosition == null) {
    return style;
  }

  if (hasFlag(F_BOOST_SLOT_DOT, boostFlags)) {
    setHotspotPositioning(
      style,
      targetElementPosition,
      iframeDimensions,
      positionFlag,
      positionOffsets,
      isHotspotMode
    );
  }

  if (hasFlag(F_BOOST_SLOT_TOOLTIP, boostFlags)) {
    setTooltipPositioning(
      style,
      targetElementPosition,
      iframeDimensions,
      positionFlag,
      positionOffsets
    );
  }

  return style;
};

const setHotspotPositioning = (
  style,
  targetElementPosition,
  iframeDimensions,
  positionFlag,
  positionOffsets,
  isHotspotMode
) => {
  const defaultOffset = isHotspotMode ? iframeDimensions.width / 2 : 30;

  if (positionFlag === 1) {
    style['left'] =
      targetElementPosition?.left - iframeDimensions.width + defaultOffset;
    style['top'] =
      targetElementPosition?.top - iframeDimensions.height + defaultOffset;
  } else if (positionFlag === 2) {
    style['left'] =
      (targetElementPosition?.left + targetElementPosition?.right) / 2 -
      iframeDimensions.width / 2;
    style['top'] =
      targetElementPosition?.top - iframeDimensions.height + defaultOffset;
  } else if (positionFlag === 4) {
    style['left'] = targetElementPosition?.right - defaultOffset;
    style['top'] =
      targetElementPosition?.top - iframeDimensions.height + defaultOffset;
  } else if (positionFlag === 8) {
    style['left'] = targetElementPosition?.right - defaultOffset;
    style['top'] =
      (targetElementPosition?.top + targetElementPosition?.bottom) / 2 -
      iframeDimensions.height / 2;
  } else if (positionFlag === 16) {
    style['left'] = targetElementPosition?.right - defaultOffset;
    style['top'] = targetElementPosition?.bottom - defaultOffset;
  } else if (positionFlag === 32) {
    style['left'] =
      (targetElementPosition?.left + targetElementPosition?.right) / 2 -
      iframeDimensions.width / 2;
    style['top'] = targetElementPosition?.bottom - defaultOffset;
  } else if (positionFlag === 64) {
    style['left'] =
      targetElementPosition?.left - iframeDimensions.width + defaultOffset;
    style['top'] = targetElementPosition?.bottom - defaultOffset;
  } else if (positionFlag === 128) {
    style['left'] =
      targetElementPosition?.left - iframeDimensions.width + defaultOffset;
    style['top'] =
      (targetElementPosition?.top + targetElementPosition?.bottom) / 2 -
      iframeDimensions.height / 2;
  } else if (positionFlag === 256) {
    const [offsetHotspotX, offsetHotspotY, offsetCardX, offsetCardY] =
      positionOffsets.split(';');
    const offsetX = isHotspotMode === true ? offsetHotspotX : offsetCardX || 0;
    const offsetY = isHotspotMode === true ? offsetHotspotY : offsetCardY || 0;
    style['left'] =
      (targetElementPosition?.left + targetElementPosition?.right) / 2 -
      iframeDimensions.width / 2 +
      parseInt(offsetX, 10);
    style['top'] =
      (targetElementPosition?.top + targetElementPosition?.bottom) / 2 -
      iframeDimensions.height / 2 +
      parseInt(offsetY, 10);
  }
  if (style.top != null) {
    if (isHotspotMode === false) {
      // prevent from going out of screen on the top
      style.top = Math.max(style.top, 10);
      // prevent from going out of screen on the bottom
      style.top = Math.min(
        style.top,
        window.innerHeight - parseInt(iframeDimensions.height, 10) - 10
      );
    }
  }
  if (style.left != null) {
    if (isHotspotMode === false) {
      // prevent from going out of screen on the left
      style.left = Math.max(style.left, 10);
      // prevent from going out of screen on the right
      style.left = Math.min(
        style.left,
        window.innerWidth - parseInt(iframeDimensions.width, 10) - 10
      );
    }
  }

  return style;
};

const setTooltipPositioning = (
  style,
  targetElementPosition,
  iframeDimensions,
  positionFlag,
  positionOffsets
) => {
  if (positionFlag === 0) {
    const height = window.innerHeight;
    const width = window.innerWidth;

    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    // compute space available on all sides of targeted element
    const leftSpace = targetElementPosition.left - scrollX;
    const rightSpace =
      width -
      (targetElementPosition.left +
        parseInt(targetElementPosition.width, 10) -
        scrollX);
    const topSpace = targetElementPosition.top - scrollY;
    const bottomSpace =
      height -
      (targetElementPosition.top +
        parseInt(targetElementPosition.height, 10) -
        scrollY);

    const leftRatio =
      leftSpace > iframeDimensions.width
        ? leftSpace / iframeDimensions.width
        : -10000;
    const rightRatio =
      rightSpace > iframeDimensions.width
        ? rightSpace / iframeDimensions.width
        : -10000;
    const topRatio =
      topSpace > iframeDimensions.height
        ? topSpace / iframeDimensions.height
        : -10000;
    const bottomRatio =
      bottomSpace > iframeDimensions.height
        ? bottomSpace / iframeDimensions.height
        : -10000;

    if (leftRatio >= Math.max(rightRatio, topRatio, bottomRatio)) {
      style['left'] =
        targetElementPosition.left - parseInt(iframeDimensions.width, 10) - 10;
      if (topRatio < bottomRatio) {
        style['top'] = targetElementPosition.top;
      } else {
        style['top'] =
          targetElementPosition.top +
          (targetElementPosition.height -
            parseInt(iframeDimensions.height, 10));
      }
    } else if (rightRatio >= Math.max(leftRatio, topRatio, bottomRatio)) {
      style['left'] = targetElementPosition.right + 10;
      if (topRatio < bottomRatio) {
        style['top'] = targetElementPosition.top;
      } else {
        style['top'] =
          targetElementPosition.top +
          (targetElementPosition.height -
            parseInt(iframeDimensions.height, 10));
      }
    } else if (topRatio >= Math.max(rightRatio, leftRatio, bottomRatio)) {
      style['top'] =
        targetElementPosition.top - parseInt(iframeDimensions.height, 10) - 10;
      if (leftRatio < rightRatio) {
        style['left'] = targetElementPosition.left;
      } else {
        style['left'] =
          targetElementPosition.left +
          (targetElementPosition.width - parseInt(iframeDimensions.width, 10));
      }
    } else if (bottomRatio >= Math.max(rightRatio, topRatio, leftRatio)) {
      style['top'] = targetElementPosition.bottom + 10;
      if (leftRatio < rightRatio) {
        style['left'] = targetElementPosition.left;
      } else {
        style['left'] =
          targetElementPosition.left +
          (targetElementPosition.width - parseInt(iframeDimensions.width, 10));
      }
    }
  } else if (positionFlag === 256) {
    const [offsetX, offsetY] = (positionOffsets || '0;0').split(';');
    style['left'] =
      (targetElementPosition?.left + targetElementPosition?.right) / 2 -
      iframeDimensions.width / 2 +
      parseInt(offsetX, 10);
    style['top'] =
      (targetElementPosition?.top + targetElementPosition?.bottom) / 2 -
      iframeDimensions.height / 2 +
      parseInt(offsetY, 10);
  } else {
    // top-left
    if (positionFlag === 1) {
      style['top'] =
        targetElementPosition.top - parseInt(iframeDimensions.height, 10) - 10;
      style['left'] = targetElementPosition.left;
    }
    // top
    else if (positionFlag === 2) {
      style['top'] =
        targetElementPosition.top - parseInt(iframeDimensions.height, 10) - 10;
      style['left'] =
        (targetElementPosition?.left + targetElementPosition?.right) / 2 -
        iframeDimensions.width / 2;
    }
    // top-right
    else if (positionFlag === 4) {
      style['top'] =
        targetElementPosition.top - parseInt(iframeDimensions.height, 10) - 10;
      style['left'] =
        targetElementPosition.left +
        (targetElementPosition.width - parseInt(iframeDimensions.width, 10));
    }
    // right
    else if (positionFlag === 8) {
      style['left'] = targetElementPosition.right + 10;
      style['top'] =
        (targetElementPosition?.top + targetElementPosition?.bottom) / 2 -
        iframeDimensions.height / 2;
    }
    // bottom-right
    else if (positionFlag === 16) {
      style['top'] = targetElementPosition.bottom + 10;
      style['left'] =
        targetElementPosition.left +
        (targetElementPosition.width - parseInt(iframeDimensions.width, 10));
    }
    // bottom
    else if (positionFlag === 32) {
      style['top'] = targetElementPosition.bottom + 10;
      style['left'] =
        (targetElementPosition?.left + targetElementPosition?.right) / 2 -
        iframeDimensions.width / 2;
    }
    // bottom-left
    else if (positionFlag === 64) {
      style['top'] = targetElementPosition.bottom + 10;
      style['left'] = targetElementPosition.left;
    }
    // left
    else if (positionFlag === 128) {
      style['top'] =
        (targetElementPosition?.top + targetElementPosition?.bottom) / 2 -
        iframeDimensions.height / 2;
      style['left'] =
        targetElementPosition.left - parseInt(iframeDimensions.width, 10) - 10;
    }
    // right-top
    else if (positionFlag === 512) {
      style['left'] = targetElementPosition.right + 10;
      style['top'] = targetElementPosition.top;
    }
    // right-bottom
    else if (positionFlag === 1024) {
      style['left'] = targetElementPosition.right + 10;
      style['top'] =
        targetElementPosition.top +
        (targetElementPosition.height - parseInt(iframeDimensions.height, 10));
    }
    // left-bottom
    else if (positionFlag === 2048) {
      style['left'] =
        targetElementPosition.left - parseInt(iframeDimensions.width, 10) - 10;
      style['top'] =
        targetElementPosition.top +
        (targetElementPosition.height - parseInt(iframeDimensions.height, 10));
    }
    //left-top
    else if (positionFlag === 4096) {
      style['left'] =
        targetElementPosition.left - parseInt(iframeDimensions.width, 10) - 10;
      style['top'] = targetElementPosition.top;
    }
  }

  return style;
};

export const animationStringToObject = (boostedAnimations = '') => {
  const [entry, leave, orientationEntry, orientationLeave] =
    boostedAnimations.split(';');
  const results = {
    entry: '',
    leave: '',
  };

  switch (entry) {
    case ANIMATION_SLIDE:
      results.entry = `poke-${ANIMATION_SLIDE}-${orientationEntry}-entry`;
      break;
    case ANIMATION_FADE:
    case ANIMATION_GROW:
      results.entry = `poke-${entry}-entry`;
      break;
    default:
      break;
  }
  switch (leave) {
    case ANIMATION_SLIDE:
      results.leave = `poke-${ANIMATION_SLIDE}-${orientationLeave}-leave`;
      break;
    case ANIMATION_FADE:
    case ANIMATION_GROW:
      results.leave = `poke-${leave}-leave`;
      break;
    default:
      break;
  }
  return results;
};
