import Axios from 'axios';

export const COUPON_TYPE_DEFAULT = 'DEFAULT';
export const COUPON_TYPE_SAAS_MANTRA_OFFER_1 = 'SAAS_MANTRA_OFFER_1';

// Endpoints
const EP_COUPON_ACTIVATE = '/coupon/activate';
const EP_COUPON_CHECKOUT_GET = (code) => `/coupon/checkout/${code}`;

// Methods
export const activate = (data) => {
  const {projectId, code} = data;

  return Axios.post(EP_COUPON_ACTIVATE, {projectId, code}).then(
    (response) => response.data
  );
};

export const fetchCheckoutCoupon = (code) => {
  return Axios.get(EP_COUPON_CHECKOUT_GET(code)).then((response) =>
    response.data.length != null && response.data.length === 0
      ? null
      : response.data
  );
};
