import './_Styles.scss';
import PickTrackerType from '../ModalCreateTracker/components/PickTrackerType';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_BUILDER_TRACKER} from 'router/routes.const';

export const hasQueryParams = (url) => {
  return url.includes('?');
};

const propTypes = {};

const defaultProps = {};

const SuccessTrackerEmptyState = () => {
  const history = useHistory();

  return (
    <div className="success-tracker-empty-state">
      <div className="content-wrapper">
        <div className="content">
          <div className="title">Create your first tracker</div>
          <div className="subtitle">
            Track your users' success and see how they interact with your
            product
          </div>
        </div>
        <PickTrackerType
          onTrackerTypeClick={(trackerType) => {
            if (trackerType === TRACKER_TYPE_ACTIVATION_TRACKER) {
              history.push(ROUTE_BUILDER_TRACKER('new-activation-tracker'));
            } else if (trackerType === TRACKER_TYPE_FEATURE_USAGE) {
              history.push(ROUTE_BUILDER_TRACKER('new-feature-usage-tracker'));
            }
          }}
        />
      </div>
    </div>
  );
};

SuccessTrackerEmptyState.propTypes = propTypes;
SuccessTrackerEmptyState.defaultProps = defaultProps;

export default SuccessTrackerEmptyState;
