import React from 'react';
import './_Styles.scss';
import SelectGroup from 'components/Select';
import {EventConditionOperand} from 'services/event';
import InputGroup from 'components/Input';

const urlOperandOptions = [
  {label: 'Is', value: EventConditionOperand.IS},
  {label: 'Is not', value: EventConditionOperand.NIS},
  {label: 'Contains', value: EventConditionOperand.CONTAINS},
  {label: 'Does not contain', value: EventConditionOperand.NCONTAINS},
  {label: 'Starts with', value: EventConditionOperand.STARTS_WITH},
  {label: 'Ends with', value: EventConditionOperand.ENDS_WITH},
  {
    label: 'Matches regular expression',
    value: EventConditionOperand.MATCHES_REGEX,
  },
  {
    label: 'Does not match regular expression',
    value: EventConditionOperand.NMATCHES_REGEX,
  },
];

const SettingsUrl = ({condition, setCondition}) => {
  const {operand, value} = condition;

  return (
    <div className="settings-url">
      <div className="section-item">
        <SelectGroup
          isSearchable={false}
          menuPlacement="auto"
          label="Contains"
          options={urlOperandOptions}
          value={urlOperandOptions.find((o) => o.value === operand)}
          onChange={(option) => {
            setCondition({
              ...condition,
              operand: option.value,
            });
          }}
        />
      </div>
      <div className="section-item">
        <InputGroup
          iconLeft="isax isax-link-2"
          value={value}
          onChange={({target}) => {
            setCondition({
              ...condition,
              value: target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SettingsUrl;
