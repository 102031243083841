import Button from 'components/Button';
import Input from 'components/Input';
import {Modal, ModalConfirm} from 'components/Modal';
import TextArea from 'components/TextArea';
import {toastDanger} from 'components/Toaster';
import {UserAvatar} from 'components/UserAvatar';
import {errorHelpers} from 'helpers';
import {func, object} from 'prop-types';
import React, {useState} from 'react';
import {generalSelector} from 'selectors';
import {commentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Comment from './comment';

const propTypes = {
  evolution: object.isRequired,
  onReplySubmit: func.isRequired,
  onCommentUpdate: func.isRequired,
  onCommentDelete: func.isRequired,
  onCommentLiked: func.isRequired,
  onCommentUnLiked: func.isRequired,
};

const defaultProps = {};

const logger = new Swaler('CommentList');

const CommentList = ({
  evolution,
  onReplySubmit,
  onCommentLiked,
  onCommentUnLiked,
  onCommentUpdate,
  onCommentDelete,
}) => {
  const [inputEditComment, setInputEditComment] = useState('');
  const [inputReply, setInputReply] = useState('');
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [commentToReply, setCommentToReply] = useState(null);
  const [isEditingComment, setIsEditingComment] = useState(null);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const [isSubmittingReply, setIsSubmittingReply] = useState(false);

  const handleEditComment = async () => {
    setIsEditingComment(true);

    try {
      const comment = await commentService.updateComment(commentToEdit.uid, {
        message: inputEditComment,
      });

      setIsEditingComment(false);
      setCommentToEdit(null);
      setCommentToReply(null);

      onCommentUpdate(comment, commentToReply);
    } catch (err) {
      setIsEditingComment(false);
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating comment failed with error ', code);
      return toastDanger([title, message], {
        actions,
      });
    }
  };

  const handleDeleteComment = async () => {
    setIsDeletingComment(true);
    try {
      await commentService.deleteComment(commentToDelete.uid);
      onCommentDelete(commentToDelete, commentToReply);
      setIsDeletingComment(false);
      setCommentToDelete(null);
      setCommentToReply(null);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);
      setIsDeletingComment(false);
      setCommentToDelete(null);
      setCommentToReply(null);

      logger.error('Deleting comment failed with error', code);
      return toastDanger([title, message], {
        actions,
      });
    }
  };

  const handleSubmitReply = async (commentToReply) => {
    if (inputReply.length === 0) {
      return;
    }
    setIsSubmittingReply(true);
    try {
      const comment = await commentService.createComment({
        evolutionId: evolution.uid,
        message: inputReply,
        replyToId: commentToReply.uid,
      });

      onReplySubmit(comment, commentToReply);
      setIsSubmittingReply(false);
      setInputReply('');
      setCommentToReply(null);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Creating reply failed with error ', code);
      toastDanger([title, message], {actions});
      setIsSubmittingReply(false);
      setInputReply('');
      setCommentToReply(null);
    }
  };

  const handleCancelReply = () => {
    setInputReply('');
    setCommentToReply(null);
  };

  const showHideModalEditComment = (commentToEdit, commentToReply) => {
    if (commentToEdit == null) {
      setCommentToEdit(null);
      setCommentToReply(null);
      setInputEditComment('');
    } else {
      setCommentToEdit(commentToEdit);
      setCommentToReply(commentToReply);
      setInputEditComment(commentToEdit.message);
    }
  };
  const showHideModalConfirmDelete = (commentToDelete, commentToReply) => {
    setCommentToDelete(commentToDelete);
    setCommentToReply(commentToReply);
  };

  const hasUsernameGenerated = (username) => {
    const nameSplit = username.split(' ');

    if (nameSplit.length !== 2) {
      return false;
    }
    if (nameSplit[0] !== 'Jimer') {
      return false;
    }
    if (nameSplit[1].length !== 4) {
      return false;
    }
    return true;
  };

  const me = generalSelector.getUser();

  return (
    <div className="comment-list">
      {evolution.comments
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
        .map((c) => (
          <Comment
            key={c.uid}
            comment={c}
            onCommentEdit={() => showHideModalEditComment(c)}
            onCommentLiked={onCommentLiked}
            onCommentUnLiked={onCommentUnLiked}
            onCommentDelete={() => showHideModalConfirmDelete(c)}
            onCommentReply={() => setCommentToReply(c)}
          >
            {c.replies.length > 0 && (
              <div className="replies">
                {c.replies
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime()
                  )
                  .map((r) => (
                    <Comment
                      key={r.uid}
                      comment={r}
                      onCommentEdit={() => showHideModalEditComment(r, c)}
                      onCommentLiked={(like) => onCommentLiked(like, c)}
                      onCommentUnLiked={(commentId, likeId) =>
                        onCommentUnLiked(commentId, likeId, c)
                      }
                      onCommentDelete={() => showHideModalConfirmDelete(r, c)}
                      onCommentReply={() => setCommentToReply(c)}
                    />
                  ))}
              </div>
            )}
            {commentToReply != null && commentToReply.uid === c.uid && (
              <div className="input-comment-reply-wrapper">
                <UserAvatar user={me} showBadgeTeam />
                <div className="right-side">
                  <Input
                    className="input-comment-reply"
                    name="inputReply"
                    placeholder="Your comment"
                    value={inputReply}
                    onChange={({target}) => setInputReply(target.value)}
                    onPressEnter={() => handleSubmitReply(c)}
                    disabled={isSubmittingReply}
                  />
                  {inputReply !== '' && (
                    <div className="button-group-actions">
                      <Button thin onClick={handleCancelReply}>
                        Cancel
                      </Button>
                      <Button primary thin onClick={() => handleSubmitReply(c)}>
                        Reply
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Comment>
        ))}
      <Modal
        className="modal-edit-comment"
        title="Edit comment"
        isOpen={commentToEdit != null}
        onRequestClose={() => showHideModalEditComment(null)}
        onCancel={() => showHideModalEditComment(null)}
        footer={
          <div className="actions">
            <Button muted onClick={() => showHideModalEditComment(null)}>
              Cancel
            </Button>
            <Button
              primary
              loading={isEditingComment}
              onClick={handleEditComment}
            >
              Save
            </Button>
          </div>
        }
        animationOnOpen="scale-in-center"
      >
        <div className="content-wrapper">
          <TextArea
            name="inputEditComment"
            value={inputEditComment}
            onChange={({target}) => setInputEditComment(target.value)}
          />
        </div>
      </Modal>

      <ModalConfirm
        isOpen={commentToDelete != null}
        onCancel={() => showHideModalConfirmDelete(null)}
        onConfirm={() => handleDeleteComment()}
        isConfirming={isDeletingComment}
      />
    </div>
  );
};

CommentList.propTypes = propTypes;
CommentList.defaultProps = defaultProps;

export default CommentList;
