import Button from 'components/Button';
import Input from 'components/Input';
import SelectAttribute, {ATTRIBUTES} from '../select-attribute';
import {OPTIONS, SelectOperand} from '../select-operand';

const OPERANDS = [
  OPTIONS.OP_IS,
  OPTIONS.OP_NIS,
  OPTIONS.OP_IS_EMPTY,
  OPTIONS.OP_NIS_EMPTY,
  OPTIONS.OP_CONTAINS,
  OPTIONS.OP_NCONTAINS,
];

export const AttributeEmail = (props) => {
  const {
    attribute,
    onDelete,
    onChangeType,
    onUpdateOperator,
    onUpdateValue,
    onAddValue,
    onDeleteValue,
    segmentioFields,
    segmentioEvents,
    trackedEvents,
  } = props;

  return (
    <div className="segment-attribute attribute-email">
      <div className="header-row">
        <SelectAttribute
          value={ATTRIBUTES.ATTR_EMAIL}
          onChange={onChangeType}
          segmentioFields={segmentioFields}
          segmentioEvents={segmentioEvents}
          trackedEvents={trackedEvents}
        />
      </div>

      <div className="value-row">
        <SelectOperand
          options={OPERANDS}
          value={attribute.op}
          onChange={(op) => onUpdateOperator(op)}
        />
        {[OPTIONS.OP_IS_EMPTY.value, OPTIONS.OP_NIS_EMPTY.value].includes(
          attribute.op
        ) ? (
          <></>
        ) : (
          <>
            {attribute.values.map((v, index) => (
              <Input
                key={index}
                placeholder="enter value..."
                value={v}
                labelTextRight={
                  attribute.values.length > 1 ? (
                    <i
                      className="icon-close"
                      onClick={() => onDeleteValue(index)}></i>
                  ) : null
                }
                onChange={({target}) => onUpdateValue(index, target.value)}
                disabled
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
