import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {any, func, string} from 'prop-types';
import React, {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {fileService} from 'services';
import {BLOCK_TYPE_USER} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ProfileLink');

const propTypes = {
  fileUrl: string,
  altText: string,
  file: any,
  onChange: func,
};

const defaultProps = {
  fileUrl: null,
  altText: null,
  file: null,
  onChange: () => {},
};

const ProfileLink = ({fileUrl, altText, file, onChange}) => {
  const [isUploading, setIsUploading] = useState(false);

  const refInputFile = useRef();

  const {data: recentFiles} = useQuery({
    queryKey: ['recentFiles', BLOCK_TYPE_USER],
    queryFn: () => fileService.getFiles({type: BLOCK_TYPE_USER}),
    refetchOnWindowFocus: false,
  });

  const handleSelectRecentFile = (file) => {
    onChange({
      value: `${file.publicUrl};${altText}`,
      file,
    });
  };

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      onChange({
        value: `${file.publicUrl};${altText}`,
        file,
      });
    }
  };

  const handleDeleteFile = async () => {
    onChange({
      value: `;${altText}`,
      file: null,
    });
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
        blockType: BLOCK_TYPE_USER,
      });

      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {
        actions,
      });
      return null;
    }
  };

  const recentFilesSized = Array(12).fill(null);
  for (let i = 0; i < recentFilesSized?.length; i++) {
    recentFilesSized[i] = recentFiles?.[i];
  }

  return (
    <div className="profile-link">
      <div className="section-item profile-media">
        <div className="image-input-wrapper">
          <input
            ref={refInputFile}
            type="file"
            style={{display: 'none'}}
            onChange={handleFileChange}
          />
          {fileUrl != null && file != null ? (
            <div className="file-wrapper">
              <div className="file-preview-name-wrapper">
                <img src={fileUrl} alt="Media" width={60} height={60} />
                {file.originalName.length > 10
                  ? file.originalName.slice(0, 10) + '...'
                  : file.originalName}
              </div>
              <Button
                iconRight="icon-trash"
                className="btn-delete"
                onClick={handleDeleteFile}
                danger
                thin>
                Delete
              </Button>
            </div>
          ) : (
            <div
              className={classNames('btn-file', {
                'is-uploading': isUploading === true,
              })}
              onClick={() => refInputFile.current.click()}>
              {isUploading === true ? (
                <>
                  <DefaultLoader width={14} /> uploading your image...
                </>
              ) : (
                <>
                  <i className="icon-upload-file"></i> Upload
                </>
              )}
            </div>
          )}
        </div>
        <div className="profile-size-information">50x50px (recommended)</div>
      </div>
      <Divider />
      <div className="recent-uploads-wrapper">
        <div className="recent-label">Or select from your last uploads</div>
        <div className="recent-uploads-list">
          {recentFilesSized?.map((file) => {
            if (file == null) {
              return (
                <div className="recent-upload placeholder">
                  <div className="recent-upload-placeholder"></div>
                </div>
              );
            }

            const isSelected = fileUrl === file.publicUrl;

            return (
              <div
                className={classNames('recent-upload', {
                  selected: isSelected,
                })}
                onClick={() => handleSelectRecentFile(file)}>
                <img src={file.publicUrl} alt="Media" />
                <div className="icon-wrapper">
                  {isSelected ? (
                    <i className="icon-checkbox" />
                  ) : (
                    <i className="icon-checkbox-o" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ProfileLink.propTypes = propTypes;
ProfileLink.defaultProps = defaultProps;

export default ProfileLink;
