import {EVOLUTION_SURVEY} from 'constants/index';

export const getDescriptionFromContent = (content = '') =>
  content.slice(0, 254);

export const getViewsCount = (evolution) => {
  if (evolution.analyticAggregations == null) {
    return 0;
  }
  return evolution.analyticAggregations.reduce(
    (sum, aggregation) => sum + aggregation.viewsCount,
    0
  );
};

export const getEvolutionIssue = (evolution) => {
  if (!evolution.title) {
    return 'Title is missing';
  }
  if (
    evolution.step !== EVOLUTION_SURVEY &&
    !evolution.content.replace(/\r?\n|\r/g, '')
  ) {
    return 'Content is missing';
  }
  return null;
};

export const removeTrailingSlash = (str) => {
  return str?.replace(/\/+$/, '');
};
