export const TEMPLATE_1 = 1;
export const TEMPLATE_2 = 2;
export const TEMPLATE_3 = 3;
export const TEMPLATE_4 = 4;
export const TEMPLATE_5 = 5;

export const TYPE_SCRATCH = 'SCRATCH';
export const TYPE_ANNOUNCEMENT = 'ANNOUNCEMENT';
export const TYPE_IDEATION = 'IDEATION';
export const TYPE_INTERVIEW = 'INTERVIEW';
export const TYPE_DESIGN = 'DESIGN';
export const TYPE_SATISFACTION = 'SATISFACTION';
export const TYPE_SURVEY = 'SURVEY';
export const TYPE_ONBOARDING = 'ONBOARDING';
