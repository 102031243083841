import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';

const ChangelogCardsStyle = ({style, setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      ...style,
      ...newStyle,
    });
  };

  return (
    <>
      <div className="section-item">
        <div className="section-item-title">
          {['gradient', 'shape'].includes(style.background?.type)
            ? 'Color 1'
            : 'Color'}
        </div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Background color"
            value={style.backgroundColor}
            onChange={(v) => {
              updateStyle({
                backgroundColor: v,
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Shadow</div>
        <div className="section-item-content shadow-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                value={style.shadow?.color}
                leftLabel={
                  <span
                    className="preview-shadow-color"
                    style={{background: style.shadow?.color || '#FFFFFF'}}
                  />
                }
              />
            }
            title="Shadow"
            content={
              <Shadow
                value={style.shadow}
                onChange={(value) => updateStyle({shadow: value})}
              />
            }
          />
        </div>
      </div>

      <div className="section-item">
        <div className="section-item-title">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.borderRadius}
            min={0}
            max={50}
            onChange={(value) => updateStyle({borderRadius: value})}
            radius
          />
        </div>
      </div>
    </>
  );
};

export default ChangelogCardsStyle;
