import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import dayjs from 'dayjs';
import {object} from 'prop-types';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import PokeBlock from 'components/PokeBlock';
import './_Styles.scss';
import DateSelectionDropdown from 'components/DateSelectionDropdown';
import LikesChart from './components/LikesChart';
import StatsRow from './components/StatsRow';
import ViewsChart from './components/ViewsChart';

const logger = new Swaler('Statistics');

export const generateDatesArray = (startDate, endDate, frequency = 'day') => {
  const arr = [dayjs(startDate).startOf(frequency)];
  do {
    arr.push(dayjs(arr[arr.length - 1]).add(1, frequency));
  } while (dayjs(arr[arr.length - 1]).isBefore(dayjs(endDate)));
  return arr;
};

const propTypes = {
  evolution: object.isRequired,
};

const defaultProps = {};

export const TAB_CHART = 'CHART';
export const TAB_LIKES = 'LIKES';

export const PokeStatisticsContext = React.createContext();

const Statistics = ({evolution}) => {
  const isDraft = evolution.isDraft === true;

  const [activeTab, setActiveTab] = useState(TAB_CHART);
  const [timeRange, setTimeRange] = useState([
    dayjs(evolution.createdAt).subtract(1, 'day').toDate(),
    dayjs().toDate(),
  ]);
  const [timeFrame, setTimeFrame] = useState(null);
  const [customDate, setCustomDate] = useState(null);

  const [startDate, endDate] = timeRange;

  const {data: evolutionWithAnalytics, isLoading: isLoadingAnalytics} =
    useQuery({
      queryKey: ['analytics', 'withSteps', evolution.uid],
      queryFn: () =>
        evolutionService.getEvolutionById(evolution.uid, {
          relations: ['analytics', 'tourSteps', 'steps'],
        }),
      onError: (err) => {
        logger.error('Could not fetch analytics, failed with err', err.message);
      },
    });

  const isLoading = isLoadingAnalytics;

  if (isLoading) {
    return (
      <div className="post-statistics">
        <DefaultLoader />
      </div>
    );
  }

  const {analytics} = evolutionWithAnalytics;

  return (
    <PokeStatisticsContext.Provider
      value={{
        evolution,
        timeRange,
        timeFrame,
        customDate,
      }}>
      <PokeBlock
        className="post-statistics"
        title={
          <>
            Posts statistics
            {isDraft !== true && (
              <div className="chart-date-picker">
                <DateSelectionDropdown
                  minDate={evolution.createdAt}
                  startDate={startDate}
                  endDate={endDate}
                  setTimeRange={setTimeRange}
                  setTimeFrame={setTimeFrame}
                  setCustomDate={setCustomDate}
                />
              </div>
            )}
          </>
        }>
        {isDraft === true ? (
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyResponses}
            title="No statistics yet"
            description="As your post gets deployed live, you'll find the data right here."
          />
        ) : (
          <div className="left-statistics-wrapper">
            <StatsRow
              analytics={analytics}
              evolution={evolution}
              onTabClick={setActiveTab}
              selectedTab={activeTab}
            />
            <div className="wrapper1">
              <div className="analytics-wrapper">
                <div className="main-wrapper">
                  {activeTab === TAB_CHART && (
                    <ViewsChart
                      evolution={evolutionWithAnalytics}
                      timeRange={timeRange}
                    />
                  )}
                  {activeTab === TAB_LIKES && (
                    <LikesChart evolution={evolution} timeRange={timeRange} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </PokeBlock>
    </PokeStatisticsContext.Provider>
  );
};

Statistics.propTypes = propTypes;
Statistics.defaultProps = defaultProps;

export default React.memo(Statistics);
