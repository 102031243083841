import classnames from 'classnames';
import Popup from 'reactjs-popup';
import './_Styles.scss';

const Tooltip = (props) => {
  const {
    animationOnOpen = 'fade-in',
    className,
    contentClassName,
    children = null,
    dark = false,
    ...rest
  } = props;
  const classNames = classnames('tooltip', className, {
    'is-hidden': children == null,
    dark,
  });
  const contentClassNames = classnames(
    'tooltip-children',
    animationOnOpen,
    contentClassName
  );

  return (
    <Popup
      className={classNames}
      arrow={false}
      on={['hover', 'focus']}
      {...rest}>
      {children != null && <div className={contentClassNames}>{children}</div>}
    </Popup>
  );
};

export default Tooltip;
