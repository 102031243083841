import {PUSHES_SET_FILTERS} from 'actions/pushes';

const defaultFilters = {states: [], segments: []};

const defaultPushesState = {
  filters: defaultFilters,
};

const pushesReducer = (state = defaultPushesState, action) => {
  switch (action.type) {
    case PUSHES_SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    default: {
      return state;
    }
  }
};

export default pushesReducer;
