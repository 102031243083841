import classNames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DateSelectionDropdown from 'components/DateSelectionDropdown';
import Dropdown from 'components/Dropdown';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {hasUsernameGenerated} from 'helpers/utils';
import {bool, func} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import {JimerProfile} from 'scenes/Users/components/ModalJimerProfile';
import {jimerService} from 'services';
import {EVOLUTION_TYPE_HINT, F_BOOST_SLOT_NAVIGATION} from 'services/evolution';
import {Swaler} from 'swaler';
import {PokeStatisticsContext} from '../..';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
};

const defaultProps = {};

const JIMERS_PER_PAGE = 20;

const logger = new Swaler('UsersReachedDrawer');

const UsersReachedDrawer = ({
  title = null,
  isOpen,
  onRequestClose,
  evolution,
  step = null,
  block,
  ...rest
}) => {
  const {
    timeRange: defaultTimeRange,
    timeFrame,
    customDate,
  } = useContext(PokeStatisticsContext) || {};

  const [selectedStep, setSelectedStep] = useState(step);
  const [page, setPage] = useState(0);
  const [jimers, setJimers] = useState({
    data: [],
    skip: 0,
    take: JIMERS_PER_PAGE,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [jimerId, setJimerId] = useState(null);
  const [timeRange, setTimeRange] = useState(
    defaultTimeRange || [
      dayjs(evolution.createdAt).subtract(1, 'day').toDate(),
      dayjs().toDate(),
    ]
  );
  const [isExporting, setIsExporting] = useState(false);

  const [startDate, endDate] = timeRange;

  const cancelCtrl = useRef();

  const fetchJimers = async (pageParam = page) => {
    setIsLoading(true);

    const oldPage = page;
    setPage(pageParam);

    cancelCtrl.current = new AbortController();

    try {
      const res = await jimerService.getJimers(
        {
          take: JIMERS_PER_PAGE,
          skip: pageParam * JIMERS_PER_PAGE,
          startDate,
          endDate,
          trackerId: evolution.tracker?.uid,
          ...(block != null
            ? {
                blockId: block.uid,
              }
            : selectedStep != null
            ? {
                stepId: selectedStep.uid,
              }
            : {
                evolutionId: evolution.uid,
              }),
        },
        {}
      );

      setJimers((prev) => ({
        ...res,
        data: [...prev.data, ...res.data],
      }));
    } catch (err) {
      if (err.message === 'canceled') {
        return;
      }
      const {code} = errorHelpers.parseError(err);

      setPage(oldPage);
      logger.error('Fetching values failed with error ', code);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setJimers({
      data: [],
      skip: 0,
      take: JIMERS_PER_PAGE,
      total: 0,
    });
    fetchJimers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep, startDate, endDate]);

  const closeModal = () => {
    onRequestClose();
  };

  const handleExportUsers = async () => {
    setIsExporting(true);
    const jimers = await jimerService
      .getJimers(
        {
          ...(block != null
            ? {
                blockId: block.uid,
              }
            : selectedStep != null
            ? {
                stepId: selectedStep.uid,
              }
            : {
                evolutionId: evolution.uid,
              }),
          exportCsv: true,
        },
        {}
      )
      .finally(() => setIsExporting(false));

    const fileName = `users-${
      block != null
        ? block?.uid
        : selectedStep != null
        ? selectedStep?.uid
        : evolution?.uid
    }.csv`;
    const data = new Blob([jimers], {type: 'text/csv'});
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  const steps =
    evolution?.tourSteps?.length > 0
      ? evolution?.tourSteps
          .filter(
            (t) => hasFlag(F_BOOST_SLOT_NAVIGATION, t.boostFlags) !== true
          )
          .map((t) =>
            t.steps.map((s) => {
              const tourStepIndex = (t.tourStepInfo || '0;0;0').split(';')[0];
              return {...s, _tourStepIndex: tourStepIndex};
            })
          )
          .flat()
          .sort((a, b) => {
            if (a._tourStepIndex === b._tourStepIndex) {
              return a.indexOrder - b.indexOrder;
            }
            return a._tourStepIndex - b._tourStepIndex;
          })
      : evolution?.steps;

  const isHint = evolution.type === EVOLUTION_TYPE_HINT;

  return (
    <Modal
      className="poke-list-users-drawer fade-in-right"
      title={jimerId == null ? title || 'Users reached' : null}
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeable
      onCancel={closeModal}
      {...rest}>
      {jimerId != null ? (
        <JimerProfile onBack={() => setJimerId(null)} jimerId={jimerId} />
      ) : (
        <div className="content-wrapper">
          <div className={classNames('users-table-wrapper')}>
            <div className="users-table">
              <div className="users-header">
                {block == null && steps?.length > 0 && (
                  <Dropdown
                    className="users-reached-step-dropdown"
                    position="bottom left"
                    triggerClassName={classNames({
                      'is-set': selectedStep != null,
                    })}
                    contentStyle={{zIndex: 1002}}
                    repositionOnResize={false}
                    trigger={
                      <Button
                        thin
                        iconLeft="icon-layers"
                        iconRight="icon-chevron-bottom">
                        {selectedStep != null
                          ? selectedStep.name
                          : isHint
                          ? 'All hints'
                          : 'All steps'}
                      </Button>
                    }>
                    <div className="setting-list">
                      <div
                        className={classNames('setting-item', {
                          'is-set': selectedStep == null,
                        })}
                        onClick={() => setSelectedStep(null)}>
                        {selectedStep == null ? (
                          <i className="icon-checkbox"></i>
                        ) : (
                          <i className="icon-checkbox-o"></i>
                        )}
                        <div className="step-info">
                          <div className="step-index">
                            {isHint ? 'All hints' : 'All steps'}
                          </div>
                        </div>
                      </div>
                      {steps?.map((step, stepIndex) => (
                        <div
                          className={classNames('setting-item', {
                            'is-set': step.uid === selectedStep?.uid,
                          })}
                          onClick={() => setSelectedStep(step)}>
                          {step.uid === selectedStep?.uid ? (
                            <i className="icon-checkbox"></i>
                          ) : (
                            <i className="icon-checkbox-o"></i>
                          )}
                          <div className="step-info">
                            <div className="step-index">
                              {isHint ? 'Hint' : 'Step'} {stepIndex + 1}
                            </div>
                            <div className="step-name">{step.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Dropdown>
                )}
                <DateSelectionDropdown
                  evolution={evolution}
                  minDate={evolution?.createdAt}
                  startDate={startDate}
                  endDate={endDate}
                  setTimeRange={setTimeRange}
                  defaultTimeFrame={timeFrame}
                  defaultCustomDate={customDate}
                />
                <div className="users-actions">
                  <Button
                    disabled={isExporting}
                    thin
                    onClick={() => handleExportUsers()}>
                    {isExporting === true ? (
                      <>
                        Exporting... this might take up to a minute{' '}
                        <DefaultLoader width="8px" />
                      </>
                    ) : (
                      'Export as CSV'
                    )}
                  </Button>
                </div>
              </div>
              {isLoading === true && jimers.total === 0 ? (
                <div className="loader-wrapper">
                  <DefaultLoader />
                </div>
              ) : jimers.total === 0 ? (
                selectedStep != null ? (
                  <EmptyStateBlock
                    img={EmptyStateImgs.EmptyResults}
                    title={'No results found'}
                    description="Refine your filters to find the users you're looking for"
                  />
                ) : (
                  <EmptyStateBlock
                    img={EmptyStateImgs.EmptyUsersReached}
                    title={'No users reached yet'}
                    description="Once this experience reach your users, you'll see them here"
                  />
                )
              ) : (
                <>
                  <div className="users-list">
                    <div className="top">
                      <div></div>
                      <div>Name / Id</div>
                      <div>Email</div>
                      <div>Last reached</div>
                    </div>
                    {isLoading === true && jimers.data.length <= 0 ? (
                      <div className="loader-wrapper">
                        <DefaultLoader width={24} />
                      </div>
                    ) : (
                      <BottomScrollListener
                        triggerOnNoScroll
                        offset={30}
                        onBottom={() => {
                          if (
                            jimers.data.length < jimers.total &&
                            isLoading !== true
                          ) {
                            fetchJimers(page + 1);
                          }
                        }}>
                        {(scrollRef) => (
                          <div className="list" ref={scrollRef}>
                            {jimers.data.map((jimer) => {
                              const date = jimer.latestEventCreatedAt;

                              const username =
                                jimer.externalUsername != null &&
                                jimer.externalUsername.length !== 0
                                  ? jimer.externalUsername
                                  : jimer.username;
                              const email =
                                jimer.personalEmail != null
                                  ? jimer.personalEmail
                                  : jimer.externalEmail;
                              const isAnonymous =
                                hasUsernameGenerated(username) &&
                                jimer.identifyToken == null;
                              const hasCompletedGoal =
                                jimer.trackerEvents?.length > 0;

                              return (
                                <div
                                  key={jimer.uid}
                                  className={classNames('card-user', {
                                    completed: hasCompletedGoal,
                                  })}
                                  onClick={() => {
                                    setJimerId(jimer.uid);
                                  }}>
                                  <div className="user-avatar-wrapper">
                                    <Avatar jimer={jimer} />
                                  </div>
                                  <div className="username-wrapper">
                                    <div className="username">
                                      {isAnonymous ? (
                                        'Anonymous'
                                      ) : !!username ? (
                                        username
                                      ) : (
                                        <em>username</em>
                                      )}
                                    </div>
                                    {isAnonymous === true ? (
                                      <div className="anonymous-name">
                                        {username}
                                      </div>
                                    ) : jimer.identifyToken ||
                                      jimer.externalUid ? (
                                      <div className="anonymous-name">
                                        {jimer.identifyToken ||
                                          jimer.externalUid}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="user-email">
                                    <span>{email ? email : 'none'}</span>
                                  </div>

                                  <div
                                    className={classNames(
                                      'user-last-activity'
                                    )}>
                                    <div className="content">
                                      <div className="event-date">
                                        {dayjs(date).fromNow()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </BottomScrollListener>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

UsersReachedDrawer.propTypes = propTypes;
UsersReachedDrawer.defaultProps = defaultProps;

export default UsersReachedDrawer;
