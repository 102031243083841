import classnames from 'classnames';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import {useContext, useState} from 'react';
import './_styles.scss';

export const ModalAddonCancel = () => {
  const {modalAddonCancel} = useContext(GlobalContext);
  const {addonId, onSubmit, onCancel} = modalAddonCancel;

  const [isCanceling, setIsCanceling] = useState(false);

  function handleCancel() {
    setIsCanceling(true);
    onSubmit();
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      className={classnames('modal-addon-cancel')}>
      <div className="modal-main">
        <div className="cancel-title title-3 n-800">Cancel this addon?</div>
        <div className="cancel-description body-3 n-700">
          {addonId &&
            `
            After canceling this addon, Jimo label will be back on all your experiences.
            `}
          You will not be refunded for the unused time.
        </div>
        <div className="actions-wrapper">
          <Button onClick={handleCancel} loading={isCanceling}>
            Yes, cancel
          </Button>
          <Button primary onClick={onCancel} disabled={isCanceling}>
            No, keep
          </Button>
        </div>
      </div>
    </Modal>
  );
};
