import Button from 'components/Button';
import {Modal} from 'components/Modal';
import Select from 'components/Select';
import {addFlag} from 'helpers/bitwise';
import React, {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {AudienceContext} from 'scenes/PokeAudience';
import {segmentioService} from 'services';
import {
  F_OPTION_IGNORE_RATE_LIMITING,
  TRIGGER_TYPE_EVENT,
} from 'services/evolution';
import './_Styles.scss';

const SegmentioEventModal = ({isOpen, onRequestClose, eventType, ...rest}) => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const [showModalCreateEvent, setShowModalCreateEvent] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(
    evolution?.onTheFlyEvent?.type === eventType
      ? evolution?.onTheFlyEvent
      : null
  );
  const [eventId, setEventId] = useState(
    evolution?.eventId ?? evolution?.event?.uid
  );

  const {data: events, isLoading} = useQuery({
    queryKey: ['segmentioEvents'],
    queryFn: () => segmentioService.getSegmentioEvents(),
  });

  const eventOptions = events?.map((e) => ({
    label: e.name,
    value: e.uid,
  }));

  const handleSave = () => {
    setEvolution({
      ...evolution,
      event: eventId != null ? events?.find((e) => e.uid === eventId) : null,
      eventId: eventId,
      triggerType: TRIGGER_TYPE_EVENT,
      optionsFlags: addFlag(
        F_OPTION_IGNORE_RATE_LIMITING,
        evolution.optionsFlags
      ),
    });

    onRequestClose();
  };

  let content = (
    <div className="fade-in-right">
      <div className="content">
        {eventToEdit != null ? (
          <div className="on-the-fly-event-btn-wrapper">
            <Button primary onClick={() => setShowModalCreateEvent(true)}>
              <div className="icon-wrapper">
                <i className="icon-target" />
              </div>
              {eventToEdit?.querySelector}
              <div className="icon-close-wrapper">
                <i
                  className="icon-close"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setEventToEdit(null);
                  }}
                />
              </div>
            </Button>
          </div>
        ) : (
          <>
            <div className="existing-event-text">Select a Segment.io event</div>
            <div className="builder-audience-event-selector">
              <Select
                options={eventOptions}
                placeholder="Select an event"
                onChange={(e) => setEventId(e?.value)}
                value={eventOptions?.find((e) => e.value === eventId)}
                isClearable
                isLoading={isLoading}
                menuPortalTarget={document.body}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      className="modal-event"
      title={
        <>
          <i className="icon-pointer" />
          After a track event
        </>
      }
      footer={
        showModalCreateEvent ? null : (
          <>
            <Button muted className="cancel-btn" onClick={onRequestClose}>
              Cancel
            </Button>
            <Button reverted primary onClick={handleSave}>
              Save
            </Button>
          </>
        )
      }
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      {content}
    </Modal>
  );
};

export default SegmentioEventModal;
