import {LineProgress} from 'components/Progress';
import dayjs from 'dayjs';
import {useTrialRemaining} from 'hooks/useTrialRemaining';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import './_styles.scss';

export const BillingCardTrial = () => {
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {daysRemaining, percentage} = useTrialRemaining();

  return (
    <div className="settings-card billing-card-trial">
      <div className="content-wrapper"></div>
      <div className="title-4 o-500">Trial ending in {daysRemaining} days</div>
      <div className="card-description body-2 n-700">
        Your trial will end on{' '}
        <b>
          {dayjs(subscription.stripeCurrentPeriodEnd * 1000).format(
            'dddd, D MMMM YYYY'
          )}
        </b>
      </div>
      <LineProgress lineHeight={8} value={percentage} />
    </div>
  );
};
