import {generalActions} from 'actions';
import {bool, func} from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {hubspotService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

import Button from 'components/Button';

import {toastDanger, toastInfo} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {useHistory, useLocation} from 'react-router-dom';
import {
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import FullSyncSection from './components/FullSyncSection';
import HelpSection from './components/HelpSection';
import SettingsSetup from './components/SettingsSetup';

const propTypes = {
  isOpen: bool,
  onRequestClose: func,
};

const defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
};

const logger = new Swaler('HubspotSettings');

const HubspotSettings = ({isOpen, onRequestClose}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject());
  const {hubspotId} = project;

  const [hubspot, setHubspot] = useState(null);
  const [isHubspotLoading, setIsHubspotLoading] = useState(false);
  const [isHubspotSyncing, setIsHubspotSyncing] = useState(false);
  const [lastSavedHubspot, setLastSavedHubspot] = useState(null);
  const [preventRefetch, setPreventRefetch] = useState(false);

  useEffect(() => {
    if (hubspotId == null) {
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT) {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      if (code !== null && state !== null) {
        setIsHubspotLoading(true);
        hubspotService
          .exchangeCodeForToken(project.uid, {
            code,
            state,
          })
          .then((hubspot) => {
            dispatch(generalActions.uptProject({hubspot}));
          })
          .catch((err) => {
            logger.error(err);
          })
          .finally(() => {
            params.delete('code');
            params.delete('state');
            history.replace({
              pathname: ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT,
              search: params.toString(),
            });
            setIsHubspotLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // need to refetch hubspot every 3 seconds while syncing
  useEffect(() => {
    if (isHubspotSyncing === true) {
      const interval = setInterval(() => {
        refetch();
      }, 3000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHubspotSyncing]);

  const {isLoading, refetch} = useQuery({
    queryKey: ['hubspot', hubspotId],
    queryFn: () => hubspotService.getHubspot(hubspotId),
    enabled: !!hubspotId,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onError: (err) => {
      logger.error(err);
    },
    onSuccess: (hubspot) => {
      if (hubspot == null) {
        return history.push(ROUTE_SETTINGS_INTEGRATIONS);
      }
      if (hubspot.fullSyncData?.status === 'RUNNING') {
        setIsHubspotSyncing(true);
      } else {
        setIsHubspotSyncing(false);
      }
      setHubspot(hubspot);
      if (lastSavedHubspot == null) {
        setLastSavedHubspot(hubspot);
      }
    },
  });

  const {mutate: updateSettings, isLoading: isLoadingUpdateSettings} =
    useMutation(
      (update) => {
        const {syncedFields} = update;
        return hubspotService.updateHubspot(hubspot?.uid, {
          ...update,
          syncedFields: syncedFields?.filter(
            (field) =>
              field.hubspotObject && field.hubspotField && field.jimoField
          ),
        });
      },
      {
        onError: (err) => {
          logger.error(err);
          setPreventRefetch(false);
        },
        onSuccess: (hubspot) => {
          setLastSavedHubspot(hubspot);
          setPreventRefetch(false);
        },
      }
    );

  const {mutate: revokeHubspotToken, isLoading: isLoadingHubspotRevoke} =
    useMutation(() => hubspotService.revokeToken(hubspotId), {
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: () => {
        dispatch(generalActions.uptProject({hubspotId: null}));
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        toastInfo([
          'Hubspot disconnected',
          'Your integration with Hubspot has been disconnected!',
        ]);
      },
    });

  // need to refetch hubspot every 3 seconds while syncing
  useEffect(() => {
    if (isHubspotSyncing === true) {
      const interval = setInterval(() => {
        if (preventRefetch === false) refetch();
      }, 3000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHubspotSyncing, preventRefetch]);

  const handleFullSyncStart = async () => {
    setIsHubspotSyncing(true);

    try {
      await updateSettings(hubspot);
      await hubspotService.runFullSync(hubspot.uid);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Syncing hubspot failed with error ${code}`);
      toastDanger([title, message], {
        actions,
      });
      setIsHubspotSyncing(false);
    }
  };

  if (isLoading) {
    return null;
  }

  const isFullSyncDisabled =
    hubspot.status === 'RUNNING' ||
    isHubspotSyncing ||
    (hubspot.endedAt != null &&
      dayjs().diff(dayjs(hubspot.endedAt), 'minute') < 10);

  return (
    <SettingsBody className="hubspot-settings">
      <div className="hubspot-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={async () => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Hubspot Integration</div>
        </div>
        <div className="right-side">
          {hubspot.fullSyncData != null &&
            hubspot.fullSyncData.status === 'RUNNING' && (
              <span className="body-4">
                Sync started {dayjs(hubspot.fullSyncData.startedAt).from()}
              </span>
            )}
          {hubspot.fullSyncData != null &&
            ['SUCCESS', 'FAILED'].includes(hubspot.fullSyncData.status) &&
            hubspot.fullSyncData.endedAt != null && (
              <span className="body-4">
                Sync ended {dayjs(hubspot.fullSyncData.endedAt).from()}
              </span>
            )}
          <Button
            thin
            primary={hubspot.fullSyncData == null}
            iconLeft="isax isax-refresh-circle"
            onClick={handleFullSyncStart}
            disabled={isFullSyncDisabled}>
            {hubspot.fullSyncData != null ? 'Resync' : 'Synchronize'}
          </Button>
        </div>
      </div>
      <div className="hubspot-settings-content">
        <div className="left-side">
          <SettingsSetup
            hubspot={hubspot}
            setHubspot={(hubspot) => {
              setHubspot(hubspot);
              const {syncedFields} = hubspot;
              const hubspotToSave = {
                ...hubspot,
                syncedFields: syncedFields?.filter(
                  (field) =>
                    field.hubspotObject && field.hubspotField && field.jimoField
                ),
              };
              const shouldPreventRefetch =
                JSON.stringify(hubspot) !== JSON.stringify(lastSavedHubspot);

              setPreventRefetch(shouldPreventRefetch);
              if (
                JSON.stringify(hubspotToSave) !==
                JSON.stringify(lastSavedHubspot)
              ) {
                updateSettings(hubspot);
              }
            }}
          />
        </div>
        <div className="right-side">
          <FullSyncSection
            hubspot={hubspot}
            isHubspotSyncing={isHubspotSyncing}
          />
          <HelpSection
            isDisconnecting={isLoadingHubspotRevoke}
            onDisconnect={() => revokeHubspotToken()}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

HubspotSettings.propTypes = propTypes;
HubspotSettings.defaultProps = defaultProps;

export default HubspotSettings;
