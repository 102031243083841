import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

export default function Card(props) {
  const {
    className,
    children,
    interactive = false,
    active = false,
    ...rest
  } = props;
  const classNames = classnames('card', className, {
    'is-interactive': interactive,
    'is-active': active,
  });

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
}

export const SelectiveCard = (props) => {
  const classNames = classnames('card-selective', props.className);

  return (
    <Card interactive {...props} className={classNames}>
      <div className="btn-select"></div>
      <div className="content">{props.children}</div>
    </Card>
  );
};
