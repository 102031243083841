import classnames from 'classnames';
import DefaultLoader from 'components/Loader';
import {toastWarning} from 'components/Toaster';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {buildStormwindUrl} from 'helpers/utils';
import IframeResizer from 'iframe-resizer-react';
import {bool, func, object} from 'prop-types';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
} from 'services/evolution';
import BookingModal from './components/BookingModal';
import ModalImage from './components/ModalImage';
import useStormwindPokeMessenger from './hooks/UseStormwindPokeMessenger';
import {animationStringToObject, getStyle} from './utils';
import './_Styles.scss';

const propTypes = {
  boostedEvolution: object.isRequired,
  isInteractivePreview: bool,
  hidden: bool,
  previewRef: object,
  isScaledDownPreview: bool,
  resetPreview: func,
};

const defaultProps = {
  isInteractivePreview: false,
  hidden: false,
  previewRef: null,
  isScaledDownPreview: false,
  resetPreview: () => {},
};

const LivePreview = ({
  boostedEvolution,
  isInteractivePreview = false,
  hidden,
  previewRef,
  isScaledDownPreview = false,
  resetPreview,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const url = buildStormwindUrl(
    projectService.getProjectPortalUrl(project),
    `/b/evolution/${boostedEvolution.uid}`,
    `boosted_preview=true${
      isInteractivePreview === true ? '&interactive_preview=true' : ''
    }`
  );
  const animations = animationStringToObject(
    boostedEvolution.boostedAnimations
  );

  const [iframeDimensions, setIframeDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [style, setStyle] = useState({});
  const [zIndex, setZIndex] = useState(boostedEvolution.boostedZIndex || null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [conceptTestMode, setConceptTestMode] = useState(false);
  const [bookingLink, setBookingLink] = useState(null);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [dotToSnippet, setDotToSnippet] = useState(false);
  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [parentHeight, setParentHeight] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const [previewClosed, setPreviewClosed] = useState(false);

  const wrapperRef = useRef(null);
  const iframeRef = useRef();
  const parentHeightRef = useRef();
  const parentWidthRef = useRef();

  useEffect(() => {
    if (previewClosed === true) {
      setPlayAnimationOut(false);
      setIsLoaded(false);
      setIsVisible(false);
      resetPreview();
      setTimeout(() => {
        setPreviewClosed(false);
      }, 1000);
    }
  }, [previewClosed]);

  useEffect(() => {
    parentHeightRef.current = parentHeight;
    parentWidthRef.current = parentWidth;
  }, [parentHeight, parentWidth]);

  useEffect(() => {
    if (previewRef != null) {
      previewRef.current = iframeRef.current;
    }
  }, [iframeRef.current]);

  useEffect(() => {
    if (hidden !== true && isLoaded === true) {
      return setIsVisible(true);
    }
    setIsVisible(false);
  }, [hidden, isLoaded, boostedEvolution.boostFlags]);

  useEffect(() => {
    setZIndex(boostedEvolution.boostedZIndex);
  }, [boostedEvolution.boostedZIndex]);

  const sendPoke = useCallback(() => {
    iframeRef.current?.sendMessage({
      action: 'SEND_POKE',
      evolution: boostedEvolution,
      ...(isScaledDownPreview !== true && {
        height: parentHeightRef.current,
        width: parentWidthRef.current,
      }),
      fromJimo: true,
    });
  }, [iframeRef?.current, boostedEvolution]);

  useEffect(() => {
    sendPoke();
  }, [boostedEvolution]);

  const sendVisibility = useCallback(() => {
    iframeRef.current?.sendMessage({
      action: 'SEND_VISIBILITY',
      visible: isVisible,
      fromJimo: true,
    });
  }, [iframeRef?.current, isVisible]);

  useEffect(() => {
    sendVisibility();
  }, [isVisible]);

  const sendSize = useCallback(() => {
    if (isScaledDownPreview !== true) {
      iframeRef.current?.sendMessage({
        action: 'SEND_SIZE',
        height: parentHeightRef.current,
        width: parentWidthRef.current,
        fromJimo: true,
      });
    }
  }, [iframeRef?.current, isScaledDownPreview]);

  useEffect(() => {
    sendSize();
  }, [parentHeight, parentWidth]);

  // eslint-disable-next-line no-unused-vars
  const handleResize = ({_, height, width}) => {
    setIframeDimensions({width, height});
  };

  // On animation out, trigger viewed 1s later to let the animation happens
  useEffect(() => {
    if (playAnimationOut === true) {
      setTimeout(() => {
        setPreviewClosed(true);
      }, 1200); // Set it to 1.2 to make sure animation is fully done
    }
  }, [playAnimationOut]);

  // Handle style of iframe
  useEffect(() => {
    let isHotspotMode;

    if (isInteractivePreview !== true) {
      isHotspotMode = false;
    } else {
      isHotspotMode = dotToSnippet === true ? false : true;
    }
    const style = getStyle({
      positionFlag: boostedEvolution?.boostedPositionFlags,
      targetElementPosition: {},
      iframeDimensions,
      isHotspotMode,
      positionOffsets: boostedEvolution?.boostedPositionOffsets,
      boostFlags: boostedEvolution?.boostFlags,
    });
    setStyle(style);
  }, [
    boostedEvolution?.boostedPositionFlags,
    iframeDimensions,
    boostedEvolution?.boostedPositionOffsets,
  ]);

  const {handleMessage: onMessage} = useStormwindPokeMessenger({
    onLoaded: () => {
      setIsLoaded(true);
    },
    onClose: () => {
      if (
        hasFlags([F_BOOST_SLOT_SNIPPET], boostedEvolution.boostFlags) &&
        !!animations?.leave
      ) {
        setPlayAnimationOut(true);
      } else {
        setIsVisible(false);
        setPreviewClosed(true);
      }
    },
    onSkip: () => {
      setIsVisible(false);
      setPreviewClosed(true);
    },
    onOpenWidget: () => {
      setPreviewClosed(true);
    },
    onShowImage: (src) => setModalImageUrl(src),
    onTogglePreview: (value) => {
      if (value === false) {
        setConceptTestMode(false);
      } else {
        setConceptTestMode(true);
      }
    },
    onToggleBooking: (link) => {
      if (link) {
        setBookingLink(link);
      } else {
        toastWarning('No valid booking link set');
      }
    },
    onDotToSnippet: () => {
      setDotToSnippet(true);
    },
    onRequestPoke: () => sendPoke(),
  });

  let poke;

  const boostFlags =
    boostedEvolution.boostFlags != null
      ? boostedEvolution.boostFlags
      : getBoostFlags(boostedEvolution.type);

  const scale =
    isScaledDownPreview === true
      ? Math.min(
          (parentHeight / iframeDimensions.height) * 0.7,
          (parentWidth / iframeDimensions.width) * 0.45
        )
      : 1;

  if (previewClosed === true) {
    return <></>;
  }

  let nothingToShow = false;

  // Slot Top bar
  if (hasFlag(F_BOOST_SLOT_TOP_BAR, boostFlags)) {
    poke = (
      <IframeResizer
        key={boostedEvolution.uid}
        forwardRef={iframeRef}
        className={classnames('jimo-topbar-iframe', {
          'hidden-loading': isVisible !== true,
          'preview-mode': conceptTestMode === true,
        })}
        src={url}
        onMessage={onMessage}
        onResized={handleResize}
        widthCalculationMethod="bodyOffset"
        checkOrigin={false}
      />
    );
  }
  // Slot Pop in
  else if (hasFlag(F_BOOST_SLOT_POP_IN, boostFlags)) {
    poke = (
      <div
        className={classnames('jimo-pop-in-wrapper', {
          'hidden-loading': isVisible !== true,
        })}>
        <IframeResizer
          key={boostedEvolution.uid}
          forwardRef={iframeRef}
          className={classnames('jimo-popin-iframe', {
            'concept-test-mode': conceptTestMode === true,
          })}
          style={{
            borderRadius: `${boostedEvolution.boostedRoundness}px`,
          }}
          src={url}
          onMessage={onMessage}
          onResized={handleResize}
          scrolling
          widthCalculationMethod="bodyOffset"
          sizeWidth
          allowFullScreen
          checkOrigin={false}
        />
        {isScaledDownPreview !== true && (
          <div
            className={classnames('jimo-modal-overlay jimo-modal-boosted', {
              'hidden-loading': isLoaded === false,
              soft:
                boostedEvolution.boostedLightbox === 'SOFT' ||
                boostedEvolution.boostedLightbox == null,
              hard: boostedEvolution.boostedLightbox === 'HARD',
            })}
            onClick={() => {
              setIsVisible(false);
              setPreviewClosed(true);
            }}
          />
        )}
      </div>
    );
  }
  // Slot Snippet
  else if (hasFlag(F_BOOST_SLOT_SNIPPET, boostFlags)) {
    poke = (
      <IframeResizer
        key={boostedEvolution.uid}
        forwardRef={iframeRef}
        style={{
          ...style,
          borderRadius: `${boostedEvolution.boostedRoundness}px`,
          animationName:
            isVisible !== true
              ? ''
              : playAnimationOut === true
              ? animations.leave
              : animations.entry,
        }}
        className={classnames('jimo-snippet-iframe', 'poke-animated', {
          'hidden-loading': isVisible !== true,
          'concept-test-mode': conceptTestMode === true,
        })}
        src={url}
        onMessage={onMessage}
        onResized={handleResize}
        widthCalculationMethod="bodyOffset"
        sizeWidth
        allowFullScreen
        checkOrigin={false}
      />
    );
  }
  // Slot Hotspot
  else if (hasFlag(F_BOOST_SLOT_DOT, boostFlags)) {
    const {boostedDotStyle} = boostedEvolution;
    const [backgroundColor, size] = boostedDotStyle.split(';');

    poke = (
      <div ref={wrapperRef}>
        <div
          className="dot"
          style={{
            backgroundColor,
            width: `${size}px`,
            height: `${size}px`,
            color: backgroundColor,
            transform: `translate(-50%, -50%) translate(-${
              iframeDimensions.width / 2 + 20
            }px, -${iframeDimensions.height / 2 + 20}px)`,
          }}
        />
        <IframeResizer
          key={boostedEvolution.uid}
          forwardRef={iframeRef}
          style={{
            ...style,
            ...(zIndex >= 0 ? {zIndex: zIndex + 1} : {}),
            borderRadius: `${boostedEvolution.boostedRoundness}px`,
          }}
          className={classnames('jimo-dot-iframe', {
            'hidden-loading': isVisible !== true,
            'concept-test-mode': conceptTestMode === true,
            'is-snippet': dotToSnippet,
          })}
          src={url}
          onMessage={onMessage}
          onResized={handleResize}
          widthCalculationMethod="bodyOffset"
          sizeWidth
          allowFullScreen
          checkOrigin={false}
        />
      </div>
    );
  }
  // Slot Tooltip
  else if (hasFlag(F_BOOST_SLOT_TOOLTIP, boostFlags)) {
    poke = (
      <div
        ref={wrapperRef}
        className={classnames('jimo-tooltip-wrapper', {
          'hidden-loading': isVisible !== true,
        })}>
        <IframeResizer
          key={boostedEvolution.uid}
          forwardRef={iframeRef}
          style={{
            ...style,
            ...(zIndex >= 0 ? {zIndex: zIndex + 2} : {}),
            borderRadius: `${boostedEvolution.boostedRoundness}px`,
          }}
          className={classnames('jimo-tooltip-iframe', {
            'concept-test-mode': conceptTestMode === true,
          })}
          src={url}
          onMessage={onMessage}
          onResized={handleResize}
          widthCalculationMethod="bodyOffset"
          sizeWidth
          allowFullScreen
          checkOrigin={false}
        />
      </div>
    );
  } else {
    nothingToShow = true;
  }

  if (nothingToShow) {
    return <></>;
  }

  return (
    <>
      {isLoaded === false && hidden !== true && (
        <div className="jimo-loading-wrapper">
          <DefaultLoader width="24px" />
        </div>
      )}
      <div
        className={classnames('live-preview', {
          'is-scaled-down': isScaledDownPreview,
          'hidden-loading': isVisible !== true,
        })}
        ref={(r) => {
          setParentHeight(r?.clientHeight);
          setParentWidth(r?.clientWidth);
        }}
        style={{
          ...(isScaledDownPreview
            ? {transform: `scale(${Math.min(scale, 1)})`}
            : {}),
        }}>
        {poke}
        <ModalImage
          isOpen={modalImageUrl != null}
          onRequestClose={() => setModalImageUrl(null)}>
          {modalImageUrl != null && <img src={modalImageUrl} alt="alt" />}
        </ModalImage>
        <BookingModal
          isOpen={bookingLink != null}
          onRequestClose={() => setBookingLink(null)}
          link={bookingLink}
        />
      </div>
    </>
  );
};

LivePreview.propTypes = propTypes;
LivePreview.defaultProps = defaultProps;

export default LivePreview;
