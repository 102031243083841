import {useContext} from 'react';
import './_Styles.scss';
import Changelog from './blocks/Changelog';
import {
  CHANGELOG_BLOCK_FEEDBACK,
  CHANGELOG_BLOCK_NAVBAR,
  CHANGELOG_BLOCK_POST_FEED,
  CHANGELOG_BLOCK_TRIGGER,
} from '../ChangelogNavigator';
import Feed from './blocks/Feed';
import Navbar from './blocks/Navbar';
import Feedback from './blocks/Feedback';
import Trigger from './blocks/Trigger';
import {ChangelogBuilderContext} from 'scenes/ChangelogBuilder';

const propTypes = {};
const defaultProps = {};

const ChangelogBlockEditor = () => {
  const {selectedBlockType} = useContext(ChangelogBuilderContext);

  return (
    <div className="changelog-block-editor-wrapper">
      {selectedBlockType == null && <Changelog />}
      {selectedBlockType === CHANGELOG_BLOCK_NAVBAR && <Navbar />}
      {selectedBlockType === CHANGELOG_BLOCK_POST_FEED && <Feed />}
      {selectedBlockType === CHANGELOG_BLOCK_FEEDBACK && <Feedback />}
      {selectedBlockType === CHANGELOG_BLOCK_TRIGGER && <Trigger />}
    </div>
  );
};

ChangelogBlockEditor.propTypes = propTypes;
ChangelogBlockEditor.defaultProps = defaultProps;

export default ChangelogBlockEditor;
