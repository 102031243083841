import classnames from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {ModalConfirm} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {PermissionsPeople} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {hasUsernameGenerated} from 'helpers/utils';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import ModalJimerProfile from '../ModalJimerProfile';
import UserTagSelector from '../UserTagSelector';
import './_Styles.scss';

const logger = new Swaler('ListUsers');

export const ListUsers = ({
  jimers,
  isLoading,
  children,
  onDelete = () => {},
  onUpdate = () => {},
}) => {
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const [jimerId, setJimerId] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const classNames = classnames('users-list-users', {'is-loading': isLoading});

  const handleDeleteJimer = async (jimerId) => {
    setDeleting(true);
    try {
      await jimerService.deleteJimer(jimerId);
      setDeleting(false);
      setShowDeleteConfirm(null);
      onDelete(jimerId);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setDeleting(false);
      logger.error('Deleting jimer failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };

  return (
    <>
      <div className={classNames}>
        <div className="top">
          <div />
          <div>Name / Id</div>
          <div>Email</div>
          <div>Last activity</div>
          <div>Tags</div>
        </div>
        {isLoading === true && (
          <div className="loader-wrapper">
            <Loader width={24}></Loader>
          </div>
        )}
        {isLoading === false && (
          <div className="list">
            {jimers.map((jimer) => {
              const username =
                jimer.externalUsername != null &&
                jimer.externalUsername.length !== 0
                  ? jimer.externalUsername
                  : jimer.username;
              const email =
                jimer.personalEmail != null
                  ? jimer.personalEmail
                  : jimer.externalEmail;
              const isAnonymous =
                hasUsernameGenerated(username) === true &&
                !jimer.identifyToken &&
                !jimer.externalUid;

              return (
                <div
                  key={jimer.uid}
                  className="card-user"
                  onClick={() => {
                    setJimerId(jimer.uid);
                    setShowProfile(true);
                  }}>
                  <div className="user-avatar-wrapper">
                    <Avatar jimer={jimer} />
                  </div>
                  <div className="username-wrapper">
                    <div className="username">
                      {isAnonymous ? (
                        'Anonymous'
                      ) : !!username ? (
                        username
                      ) : (
                        <em>username</em>
                      )}
                    </div>
                    {isAnonymous === true ? (
                      <div className="anonymous-name">{username}</div>
                    ) : jimer.identifyToken || jimer.externalUid ? (
                      <div className="anonymous-name">
                        {jimer.identifyToken || jimer.externalUid}
                      </div>
                    ) : null}
                  </div>
                  <div className="user-email">
                    <span>{email ? email : 'none'}</span>
                  </div>

                  <div>
                    {dayjs(
                      jimer.lastActivityAt != null
                        ? jimer.lastActivityAt
                        : jimer.createdAt
                    ).fromNow()}
                  </div>
                  <div className="user-tags">
                    <UserTagSelector
                      jimer={jimer}
                      maxTags={2}
                      readOnly={
                        hasPermissions(PermissionsPeople.USER_EDIT) === false
                      }
                    />
                  </div>
                  {hasPermissions(PermissionsPeople.USER_DELETE) && (
                    <div className="user-actions">
                      <Button
                        iconOnly
                        thin
                        rounded={false}
                        danger
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowDeleteConfirm(jimer.uid);
                        }}
                        disabled={showDeleteConfirm != null}>
                        <i className="icon-trash"></i>{' '}
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
            {children}
          </div>
        )}
      </div>
      <ModalConfirm
        className="modal-confirm-delete-jimer"
        isOpen={showDeleteConfirm != null}
        onConfirm={() => handleDeleteJimer(showDeleteConfirm)}
        onCancel={() => setShowDeleteConfirm(null)}>
        <div className="content">
          All data related to this jimer including activity and surveys will be
          forever deleted.
        </div>
      </ModalConfirm>
      <ModalJimerProfile
        isOpen={showProfile}
        onRequestClose={() => setShowProfile(false)}
        jimerId={jimerId}
      />
    </>
  );
};
