import classnames from 'classnames';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useState} from 'react';
import {tagService} from 'services';
import {Swaler} from 'swaler';
import './_Tag-editor.scss';

const logger = new Swaler('TagEditor');

const TagEditor = ({tag, onEdit, onDelete}) => {
  const [deleteMode, setDeleteMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteTag = async () => {
    setIsDeleting(true);
    try {
      await tagService.deleteTag(tag.uid);
      onDelete();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting tag failed with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsDeleting(false);
    }
  };

  const classNames = classnames('tag-editor', {
    'is-delete-mode': deleteMode === true,
  });

  if (deleteMode === true) {
    return (
      <div className={classNames}>
        <div className="name">{tag.name}</div>
        <div className="editor-actions">
          <Button muted onClick={() => setDeleteMode(false)}>
            cancel
          </Button>
          <Button danger onClick={handleDeleteTag} loading={isDeleting}>
            delete
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={classNames}>
      <div className="content-wrapper">
        {tag.color != null && (
          <div className="color" style={{backgroundColor: tag.color}}></div>
        )}
        <div className="name">{tag.name}</div>
      </div>
      <div className="editor-actions">
        <Button iconOnly light rounded={false} onClick={onEdit}>
          <i className="icon-edit" />
        </Button>
        <Button
          iconOnly
          light
          rounded={false}
          onClick={() => setDeleteMode(true)}>
          <i className="icon-trash" />
        </Button>
      </div>
    </div>
  );
};

export default TagEditor;
