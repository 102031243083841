import Axios from 'axios';

// Endpoint
const EP_CUSTOM_ATTRIBUTE_VALUES_GET = (customAttributeId) =>
  `/custom-attribute/${customAttributeId}/values`;

// Methods
export const getCustomAttributeValues = (customAttributeId, data) => {
  const {skip, take, search} = data;

  return Axios.get(EP_CUSTOM_ATTRIBUTE_VALUES_GET(customAttributeId), {
    params: {skip, take, search},
  }).then((response) => response.data);
};
