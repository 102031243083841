import classNames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import BtnCopy from 'components/ButtonCopy';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {Modal} from 'components/Modal';
import {bool, func, object} from 'prop-types';
import React, {useState} from 'react';
import {isUrlMatchingNavigation} from 'scenes/PokeBuilder/components/AdoptionStepsManager';
import isURL from 'validator/lib/isURL';
import './_Styles.scss';

const propTypes = {
  isOpen: bool,
  onRequestClose: func,
  evolution: object.isRequired,
  isPreview: bool,
};

const defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  isPreview: false,
};

const SharableLinkModal = ({isOpen, onRequestClose, evolution, isPreview}) => {
  const [url, setUrl] = useState('');
  const [urlInputFocused, setUrlInputFocused] = useState(false);

  const isUrl = isURL(url || '', {
    require_tld: false, // to allow localhost
  });

  let isUrlValid = false;
  try {
    isUrlValid =
      isPreview === true
        ? isUrl && isUrlMatchingNavigation(evolution, url)
        : isUrl;
  } catch (e) {}

  const separator = url.includes('?') ? '&' : '?';
  const parameterName = isPreview === true ? 'jimo_poke_preview' : 'jimo_poke';
  const parameterToAppend = `${separator}${encodeURIComponent(
    parameterName
  )}=${encodeURIComponent(evolution.uid)}`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="sharable-link-modal"
      title={
        <>
          <i className="isax isax-link-2" />
          {isPreview === true
            ? 'Test your experience'
            : 'Share your experience'}
        </>
      }>
      <div className="fade-in-right">
        <div className="inputs-wrapper">
          <div className="section-title">
            Please add the URL your experience should show/start on.
          </div>
          <InputGroup
            autoComplete="off"
            className="event-url"
            placeholder="https://"
            name="targetUrl"
            value={url}
            onChange={({target}) => setUrl(target.value)}
            onFocus={() => setUrlInputFocused(true)}
            additional={parameterToAppend}
          />
          {evolution?.boostedActiveUrl != null && (
            <Dropdown
              className={classNames('dropdown-share-url-suggestions', {
                'not-focused': urlInputFocused !== true,
              })}
              open={urlInputFocused === true}
              onClose={() => setUrlInputFocused(false)}
              offsetY={-12}
              position="bottom left">
              <div
                className="url-suggestion"
                onClick={() => {
                  setUrl(evolution.boostedActiveUrl);
                  setUrlInputFocused(false);
                }}>
                <span>Default URL</span> - {evolution.boostedActiveUrl}
              </div>
            </Dropdown>
          )}
          {isUrl === true && isUrlValid !== true && (
            <Alert warning>
              The URL you entered is not matching the URL rules you defined in
              the "Where" section.
            </Alert>
          )}
        </div>
        <div className="actions">
          <Button muted className="cancel-btn" onClick={onRequestClose}>
            Cancel
          </Button>
          <BtnCopy
            textToCopy={`${url}${parameterToAppend}`}
            thin
            disabled={isUrlValid !== true}>
            Copy url
          </BtnCopy>
        </div>
      </div>
    </Modal>
  );
};

SharableLinkModal.propTypes = propTypes;
SharableLinkModal.defaultProps = defaultProps;

export default SharableLinkModal;
