import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import React, {useContext} from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SettingsLaunchExperience');

const SettingsLaunchExperience = ({action, setAction}) => {
  const {evolution} = useContext(BuilderContext);

  const {data: experiences = [], isLoading} = useQuery({
    queryKey: 'experiences',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
        toastDanger([title, message], {actions});
      }
    },
  });

  const experiencesOptions = experiences
    ?.map((e) => ({
      label: e.title,
      value: e.uid,
      evolution: e,
    }))
    .filter((e) => e.value !== evolution.uid);

  const groupedOptions = [
    {
      label: 'Tours & Modals',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_TOUR
      ),
    },
    {
      label: 'Surveys',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_SURVEY
      ),
    },
    {
      label: 'Checklists',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_CHECKLIST
      ),
    },
  ];

  return (
    <div className="settings-launch-experience">
      <div className="settings-launch-experience-title body-3">
        Start experience:
      </div>
      <SelectGroup
        options={groupedOptions}
        value={experiencesOptions?.find((e) => e.value === action.value)}
        onChange={(selected) => setAction({...action, value: selected.value})}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SettingsLaunchExperience;
