import InputGroup from 'components/Input';

const NpsBlockInput = ({selectedLanguage, block, onChange}) => {
  const {value} = block;
  const [labelLeft, labelRight] = value.split(';');

  const translation = block?.translations?.find(
    (t) => t.language === selectedLanguage
  );
  let translatedLabelLeft, translatedLabelRight;

  if (translation) {
    [translatedLabelLeft, translatedLabelRight] = translation.value?.split(';');
  }

  const inputLabelLeft =
    selectedLanguage != null ? translatedLabelLeft || '' : labelLeft;

  const inputLabelRight =
    selectedLanguage != null ? translatedLabelRight || '' : labelRight;

  const handleChange = (value) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.translations?.find((t) => t.language === selectedLanguage) !=
        null;
      onChange({
        translations: hasLanguage
          ? block.translations.map((t) => {
              if (t.language === selectedLanguage) {
                return {
                  ...t,
                  value,
                };
              } else {
                return t;
              }
            })
          : [
              ...(block?.translations || []),
              {language: selectedLanguage, value},
            ],
      });
    } else {
      onChange({
        value: value,
      });
    }
  };

  return (
    <div className="slider-block-inputs">
      <InputGroup
        className="slider-left-label-input"
        value={inputLabelLeft}
        placeholder={labelLeft}
        onChange={({target}) =>
          handleChange(`${target.value};${inputLabelRight}`)
        }
      />
      <InputGroup
        className="slider-right-label-input"
        value={inputLabelRight}
        placeholder={labelRight}
        onChange={({target}) =>
          handleChange(`${inputLabelLeft};${target.value}`)
        }
      />
    </div>
  );
};

export default NpsBlockInput;
